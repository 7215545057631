import React from 'react';
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive';
import { searchIcon } from '../../shared/icons/icons';

const CardContainerCompare = ({
    selectedCompareItems,
    onChangeSelectedCompareItem
}) => {

    const isTablet = useMediaQuery({ maxWidth: 920 });
    const isMobile = useMediaQuery({ maxWidth: 820 });

    const compareOptions = [
        { color: '#d62728', type: 'index', value: 'GSPC', label: '(GSPC) S&P 500' },
        { color: '#e377c2', type: 'index', value: 'IXIC', label: '(IXIC) NASDAQ Composite' },
        { color: '#7f7f7f', type: 'commodity', value: 'RB', label: '(RB) RBOB Gasoline' },
        { color: '#bcbd22', type: 'commodity', value: 'GC', label: '(RC) Gold' },
        // { color: '#1f77b4', type: 'sector', value: 'Technology', label: 'Technology' },
        // { color: '#2ca02c', type: 'sector', value: 'Healthcare', label: 'Healthcare' },
        { color: '#9467bd', type: 'company', value: 'AAPL', label: '(AAPL) Apple Inc.' },
        { color: '#8c564b', type: 'company', value: 'NVDA', label: '(NVDA) NVIDIA Corporation' },
        // { color: '#17becf', type: 'crypto', value: 'BTC', label: '(BTC) Bitcoin' },
        // { color: '#ff7f0e', type: 'crypto', value: 'ETH', label: '(ETH) Ethereum' }
    ];

    const handleChange = (selectedOption) => {
        onChangeSelectedCompareItem(selectedOption);
    };

    const CustomDropdownIndicator = () => {
        return <span className='header-search-custom-dropdown-indicator'>
            {searchIcon}
        </span>;
    };

    const searchWidth = isMobile ? '250px' : isTablet ? '400px' : '350px';

    const costumeStyles = {
        // dropdownIndicator: () => ({ color: '#484848' }),
        // dropdownIndicator: {loginIcon},
        menu: (base) => ({
            ...base,
            width: isMobile ? '250px' : '350px',
            marginTop: '10px',
            left: '49%',
            transform: 'translateX(-50%)',
            borderRadius: '8px',
            zIndex: 100,
        }),
        option: (base, state) => ({
            ...base,
            width: '100%',
            textAlign: 'start',
            padding: '10px',
            backgroundColor: '#ffffff',
            color: state.isSelected ? '#326fb5' : '#484848',
            fontWeight: state.isSelected ? '600' : '500',
            borderBottom: state.isFocused && !state.isLast ? '2px solid #EEEEEE' : 'none',
            '&:not(:last-child)': {
                borderBottom: '2px solid #EEEEEE',
            },
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#f1f6fc'
            },
        }),
        groupHeading: (provided) => ({
            ...provided,
            color: '#484848',
            marginBottom: '2px',
            backgroundColor: '#ffffff',
            fontWeight: '600'
        }),
        group: (provided) => ({
            ...provided,
            background: '#ffffff'
        }),
        input: (baseStyles) => ({
            ...baseStyles,
            color: '#484848',
            paddingLeft: '10px'
        }),
        control: (baseStyles) => ({
            ...baseStyles,
            width: searchWidth,
            margin: 'auto',
            borderRadius: '40px',
            border: '1px solid #484848',
            color: '#484848',
            fontSize: '1rem',
            backgroundColor: '#eeeeee',
            boxShadow: 'none',
            marginBottom: '20px',
            // height: 30,
            // minHeight: 30,
            // height: 40,
            minHeight: 40,
            '&:hover': {
                borderColor: '#484848',
            },
            '&:focus': {
                borderColor: '#484848',
                outline: 'none',
            },
        }),
        singleValue: (base, state) => ({
            ...base,
            color: '#484848',
            paddingLeft: '10px'
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            color: '#484848',
            paddingLeft: '10px'
        }),
    };

    const CustomMultiValue = ({ data, removeProps }) => (
        <div className="custom-multi-value">
            {data.value}
            <span {...removeProps}>×</span>
        </div>
    );


    return (
        <div className="card-container-compare portfolio-compare-input">
            <Select
                options={compareOptions}
                value={selectedCompareItems}
                onChange={handleChange}
                isMulti
                placeholder={'Select to compare'}
                components={{
                    MultiValue: CustomMultiValue,
                  }}

                styles={costumeStyles }

            />
        </div>
    );

    // return (
    //     <></>
    // );
}

export default CardContainerCompare;