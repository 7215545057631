import React, { useRef, useEffect } from 'react';
import { Chart } from 'chart.js/auto';
import 'chartjs-plugin-datalabels';

const ComparisonBarchartsChart = ({ mediumScreen, chartTitle, chartData, onChangeCanvasHeight }) => {
    const chartContainerRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const chartDivRef = useRef(null);

    useEffect(() => {
        if (chartData && chartContainerRef.current) {
            // Destroy the existing chart if it exists
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }

            const ctx = chartContainerRef.current.getContext('2d');
            chartInstanceRef.current = new Chart(ctx, {
                type: 'bar',
                data: chartData,
                options: {                
                    indexAxis: 'y',
                    elements: {
                        bar: {
                            borderWidth: 2,
                            barThickness: 2,
                            borderRadius: 8
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        tooltip: {
                            displayColors: false,
                            backgroundColor: '#ffffff',
                            titleColor: '#484848',
                            borderWidth: '1',
                            borderColor: '#484848',
                            bodyColor: '#484848',
                            cornerRadius: '20',
                            padding: '10',
                        },
                        legend: {
                            display: false,
                        },
                        datalabels: {
                            align: 'end',
                            anchor: 'end',
                            color: chartData.datasets[0].data.map((value) => (value >= 0 ? '#5c9ead' : '#e39774')),
                            formatter: (value) => value,
                        },
                    },
                    scales: {
                        x: {
                            display: false,
                        },
                        y: {
                            display: false,
                        },
                    },
                    onResize: (chart) => {
                        const divHeight = chartDivRef.current?.offsetHeight || 0;
                        onChangeCanvasHeight(divHeight);
                    },
                },
            });
        }
    }, [chartData]);

    const containerStyle = {
        width: '30%',
        height: '100%',
        // height: mediumScreen ? '90px' : '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    };

    return (
        <div className='portfolio-comparison-barcharts-item-chart' style={containerStyle}>
            <div className='portfolio-comparison-barcharts-item-title'>
                <h4 className='portfolio-chart-title comparison-chart-title'>{chartTitle}</h4>
            </div>
            <div ref={chartDivRef} style={{height: '80%'}}>
            <canvas ref={chartContainerRef} />
            </div>
        </div>
    );
};

export default ComparisonBarchartsChart;


