import React from 'react';
import { useMediaQuery } from 'react-responsive';

const AchievementsContent = ({
    quest,
    isMyProfile,
    userAchievements,
    emptyStarIcon,
    fullStarIcon
}) => {

    const isSmallScreen = useMediaQuery({ maxWidth: 600 });
    const userAchievementData = userAchievements[quest.id] || {};
    const { id, title, tasks, status } = quest;
    const userCompletedStars = userAchievementData.completed || 0;

    const renderStars = () => {
        const stars = [];

        for (let i = 0; i < 3; i++) {
            if (i < userCompletedStars) {
                stars.push(
                    <div
                        key={i}
                        className='quest-star quest-star-completed flex align-center'>
                        {fullStarIcon}
                    </div>
                );
            } else {
                stars.push(
                    <div
                        key={i}
                        className='quest-star flex align-center'>
                        {emptyStarIcon}
                    </div>
                );
            }
        }
        return stars;
    };


    if (isSmallScreen) {
        return (
            <div className="quest-container card-container flex column align-center">
                <div className="quest-item flex column align-center">
                    <div className="quest-stars flex" style={{marginRight: '0', marginBottom: '5px'}}>
                        {renderStars()}
                    </div>
                    <div className="flex column" style={{ textAlign: 'center' }}>
                        <h4 style={{marginBottom: '5px'}}>{title}</h4>
                        <p className='mobile-profile-challenge-p' style={{marginBottom: '5px'}}>{userAchievementData.tasks ? userAchievementData.tasks[userCompletedStars] : tasks[userCompletedStars]}</p>
                    </div>
                </div>
                <div className="quest-item flex align-center">
                    <p>{userAchievementData.status || status}</p>
                </div>
            </div>
        );
    };

    return (
        <div className="quest-container card-container flex space-between">
            <div className="quest-item flex align-center">
                <div className="quest-stars flex">
                    {renderStars()}
                </div>
                <div className="flex column">
                    <h4>{title}</h4>
                    <p>{userAchievementData.tasks ? userAchievementData.tasks[userCompletedStars] : tasks[userCompletedStars]}</p>
                </div>
            </div>
            <div className="quest-item flex align-center">
                <p>{userAchievementData.status || status}</p>
            </div>
        </div>
    );
}

export default AchievementsContent;
