import React from "react";
import ChangePassword from "./change-password";
import './user-pool.scss';

const AccountSettings = () => {

    return (
        <>
            <div className="card-container mb20">
                <h2 className="user-pool-title">Account Settings</h2>
                <div className="flex">
                    <ChangePassword />
                </div>
            </div>
        </>
    )
}

export default AccountSettings;