import ActiveMatchweek from "./active-matchweek/active-matchweek";
import ManageFundMatchweek from "./manage-fund-matchweek/manage-fund-matchweek";
import MatchweekStats from "./matchweek-stats/matchweek-stats";
import Matchweeks from "./matchweeks/matchweeks";

const FundMatchweek = ({
    loggedInUser,
    fund,
    fundManager,
    fundMatchweek,
    fundMembers,
    handleChangeFundMatchweeks,
    handleOrdersListChange,
    todayDate,
    addStocks,
    companiesPrices
}) => {

    // HARDCODED loggedInUser
    // const loggedInUser = "@";
    // const loggedInUser = "user1";

    const myFund = fundMatchweek ? fundMatchweek.myFund : {};

    const selectedFund = fundMatchweek?.away?.name === myFund ? fundMatchweek.away : fundMatchweek?.home;

    const loggedInUserData = selectedFund?.members?.find(member => member.username === loggedInUser);

    const memberPortfolioData = fundMatchweek && loggedInUserData ? {
        matchweekType: fundMatchweek.matchweekType,
        searchDate: fundMatchweek.searchDate,
        matchweekLen: fundMatchweek.matchweekLen,
        id: fundMatchweek.id,
        fundName: fundMatchweek.myFund,
        memberData: {
            ...loggedInUserData,
            png: selectedFund.png,
            level: selectedFund.level,
            pngUrl: selectedFund.pngUrl
        }
    } : null;

    // console.log('loggedInUser:',loggedInUser);
    // console.log('fundManager:',fundManager);
    // console.log('fundMatchweek:',fundMatchweek);
    // console.log('memberPortfolioData',memberPortfolioData);

    // console.log(loggedInUser, fundManager, fundMatchweek);
    // console.log('memberPortfolioData:',memberPortfolioData);

    return (
        <div>
            {
                loggedInUser === fundManager && !fundMatchweek ? (
                    <Matchweeks fund={fund} fundMembers={fundMembers} handleChangeFundMatchweeks={handleChangeFundMatchweeks} />
                ) : (
                    fundMatchweek && memberPortfolioData && (
                        <>
                            <ActiveMatchweek fundMatchweek={fundMatchweek} />
                            <ManageFundMatchweek
                                fundMatchweek={fundMatchweek}
                                handleOrdersListChange={handleOrdersListChange}
                                memberPortfolioData={memberPortfolioData}
                                todayDate={todayDate}
                                addStocks={addStocks}
                                companiesPrices={companiesPrices}
                            />
                            <MatchweekStats memberPortfolioData={memberPortfolioData} />
                        </>
                    )
                )
            }
        </div>
    );
};

export default FundMatchweek;
