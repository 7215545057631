import React, { useContext, useEffect, useState, useRef } from "react";
import { AccountContext } from "./account";
import { useHistory } from 'react-router-dom';
import { calendarIcon, cryptoIcon, homeIcon, loginIcon, logoutIcon, macroIcon, menuIcon, moreIcon, portfolioIcon, signupIcon, userIcon } from "../shared/icons/icons";
import { useMediaQuery } from 'react-responsive';
import './user-pool.scss';

const Status = ({ loggedInUser, setLoggedInUser, setUserFollowing, onLogout }) => {

    const isMobile = useMediaQuery({ maxWidth: 820 });
    const history = useHistory();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const { logout } = useContext(AccountContext);

    const navigateLogin = () => {
        setIsMobileMenuOpen(false);
        history.push('/login');
    };

    const navigateSignup = () => {
        setIsMobileMenuOpen(false);
        history.push('/signup');
    };

    const navigateSettings = () => {
        setIsMobileMenuOpen(false);
        history.push(`/profile/portfolios/${loggedInUser}`);
    };

    const navigateAboutUs = () => {
        setIsMobileMenuOpen(false);
        history.push('/about');
    };

    const handleLogout = () => {
        setIsMobileMenuOpen(false);
        logout();
        onLogout();
    };

    const navigateHome = () => {
        setIsMobileMenuOpen(false);
        history.push('/');
    };
    const navigatePortfolio = () => {
        setIsMobileMenuOpen(false);
        history.push('/portfolio/companies');
    };
    const navigateMacro = () => {
        setIsMobileMenuOpen(false);
        history.push('/macro');
    };
    const navigateCrypto = () => {
        setIsMobileMenuOpen(false);
        history.push('/crypto');
    };
    const navigateCalendar = () => {
        setIsMobileMenuOpen(false);
        history.push('/calendar');
    };
    const navigateTerms = () => {
        setIsMobileMenuOpen(false);
        history.push('terms-of-service');
    };
    const navigatePrivacy = () => {
        setIsMobileMenuOpen(false);
        history.push('privacy-notice');
    };
    const statusMenu = (
        <div className="mobile-menu">
            {
                loggedInUser ? (
                    <div className="mobile-menu-item-container flex column">

                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigateSettings}>
                            <div className="mobile-menu-icon flex align-center">{userIcon}</div>
                            <div >{loggedInUser}</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigateHome}>
                            <div className="mobile-menu-icon flex align-center">{homeIcon}</div>
                            <div>Home</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigatePortfolio}>
                            <div className="mobile-menu-icon flex align-center">{portfolioIcon}</div>
                            <div>Portfolio</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigateMacro}>
                            <div className="mobile-menu-icon flex align-center">{macroIcon}</div>
                            <div>Macro</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigateCrypto}>
                            <div className="mobile-menu-icon flex align-center">{cryptoIcon}</div>
                            <div>Crypto</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigateCalendar}>
                            <div className="mobile-menu-icon flex align-center">{calendarIcon}</div>
                            <div>Calendar</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigateAboutUs}>
                            <div className="mobile-menu-icon flex align-center">{moreIcon}</div>
                            <div>About us</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigateTerms}>
                            <div className="mobile-menu-icon flex align-center">{moreIcon}</div>
                            <div>Terms of service</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigatePrivacy}>
                            <div className="mobile-menu-icon flex align-center">{moreIcon}</div>
                            <div>Privacy Notice</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={handleLogout}>
                            <div className="mobile-menu-icon flex align-center">{logoutIcon}</div>
                            <div>Logout</div>
                        </div>
                    </div>
                ) : (
                    <div className="mobile-menu-item-container flex column">

                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigateLogin}>
                            <div className="mobile-menu-icon flex align-center">{loginIcon}</div>
                            <div>Login</div>
                        </div>

                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigateHome}>
                            <div className="mobile-menu-icon flex align-center">{homeIcon}</div>
                            <div>Home</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigatePortfolio}>
                            <div className="mobile-menu-icon flex align-center">{portfolioIcon}</div>
                            <div>Portfolio</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigateMacro}>
                            <div className="mobile-menu-icon flex align-center">{macroIcon}</div>
                            <div>Macro</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigateCrypto}>
                            <div className="mobile-menu-icon flex align-center">{cryptoIcon}</div>
                            <div>Crypto</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigateCalendar}>
                            <div className="mobile-menu-icon flex align-center">{calendarIcon}</div>
                            <div>Calendar</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigateSignup}>
                            <div className="mobile-menu-icon flex align-center">{signupIcon}</div>
                            <div>Sign up</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigateAboutUs}>
                            <div className="mobile-menu-icon flex align-center">{moreIcon}</div>
                            <div>About us</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigateTerms}>
                            <div className="mobile-menu-icon flex align-center">{moreIcon}</div>
                            <div>Terms of service</div>
                        </div>
                        <div
                            className="mobile-menu-item flex align-center"
                            onClick={navigatePrivacy}>
                            <div className="mobile-menu-icon flex align-center">{moreIcon}</div>
                            <div>Privacy Notice</div>
                        </div>
                    </div>
                )
            }
        </div>
    );

    const statusMenuBtn = (
        <div className="mobile-menu-btn-container flex flex-end align-center">
            <div
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="mobile-menu-btn flex center-center"
                style={{fontSize: isMobile ? '2.5rem' : '3rem' }}
            >
                {menuIcon}
            </div>
        </div>
    );


    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMobileMenuOpen(false);
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="flex align-center" ref={menuRef}>
                {statusMenuBtn}
                {isMobileMenuOpen && statusMenu}
        </div>
    );

}

export default Status;