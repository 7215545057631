import React, { useState, useEffect } from 'react';
import { closeIcon } from '../../shared/icons/icons';
import { useMediaQuery } from 'react-responsive';
import './info-popup.scss';

const InfoPopup = ({ type, setShowInfoModal }) => {

    const isMobile = useMediaQuery({ maxWidth: 920 });
    const [popupToDisplay, setPopupToDisplay] = useState(null);

    useEffect(() => {
        setPopupToDisplay(infoData[type]);
    }, []);

    const infoData = {
        //shared
        return: {
            title: 'Return Ratio',
            content: 'Profit or loss an investor earns on an investment, expressed as a percentage of the initial investment.'
        }, sharpe: {
            title: 'Sharpe Ratio',
            content: 'measure of the risk-adjusted return of an investment, calculated by dividing the excess return of the investment over the risk-free rate by the investment\'s standard deviation. The higher the Sharpe ratio, the better the risk-adjusted return.'
        },
        priceOverTime: {
            title: 'Price Over Time',
            content: 'Displays the historical price movement of an asset, with time on the horizontal axis and the asset\'s price on the vertical axis. The continuous line connects data points, offering a quick overview of price trends and fluctuations over the chosen time frame.'
        },
        lifeCycle: {
            title: 'Life Cycle Analysis',
            content: 'Versatile framework for evaluating growth potential, identifying rapid-growth opportunities, understanding industry dynamics, and assessing a company\'s market standing and potential for growth or decline. It guides strategic decisions such as timing entry and exit points, gauging dividend policy, and evaluating long-term growth potential.'
        },
        exchangeRatesvsDollarOverTime: {
            title: 'Exchange Rates vs. Dollar Over Time',
            content: 'observe how the values of multiple exchange rates have fluctuated over time in relation to the U.S. Dollar. Analyzing the trends can provide insights into the relative strength or weakness of different currencies against the Dollar'
        },
        competitiveAnalysis: {
            title: 'Competitive Analysis',
            content: 'The position of each quarter indicates a company\'s performance within its sector: (1) Top Right (High Growth, High Share): Rapid growth and a substantial share suggest strong performance. (2) Top Left (High Growth, Low Share): High growth but a small market share may indicate potential for expansion. (3) Bottom Right (Low Growth, High Share): A large market share with slow growth may signify stability. (4) Bottom Left (Low Growth, Low Share): Low growth and a small share could indicate challenges in competitiveness.'
        },
        //fundamental
        currentRatio: {
            title: 'Current Ratio',
            content: 'Gauges a company\'s short-term debt-paying ability by comparing current assets to liabilities. A higher ratio signals better financial strength, while a lower one may indicate liquidity concerns. Investors use this metric to assess working capital management and overall financial health.'
        },
        quickRatio: {
            title: 'Quick Ratio',
            content: 'Assesses a company\'s short-term liquidity with cash, securities, and receivables, excluding inventory. A higher ratio signals robust liquidity, while a lower ratio may suggest increased liquidity risk. Investors use it to evaluate a company\'s ability to meet short-term obligations and assess financial health and risk.'
        },
        daysSalesOutstanding: {
            title: 'Days Sales Outstanding (DSO)',
            content: 'Measures how fast a company collects payments after a sale. A lower DSO means efficient cash flow, while a higher DSO signals potential cash flow challenges and bad debts. Investors use DSO to assess financial health and risk.'
        },
        debtToAssets: {
            title: 'Debt to Assets',
            content: 'Percentage of a company\'s assets funded by debt. A higher ratio implies greater financial risk, while a lower ratio suggests lower financial leverage and increased resilience. Investors use this metric to assess a company\'s financial health and risk.'
        },
        shareholderEquityRatio: {
            title: 'Shareholder Equity Ratio',
            content: 'Indicates the percentage of assets funded by shareholder equity. A higher ratio signifies financial strength, while a lower ratio suggests vulnerability to economic downturns due to heavy debt reliance. Investors use it to assess risk and financial strength.'
        },
        debtToEquityRatio: {
            title: 'Debt to Equity Ratio',
            content: 'Indicates how much a company relies on debt compared to shareholder equity. A higher ratio signals higher default risk, while a lower ratio indicates greater financial stability. Investors use this to assess financial leverage and risk.'
        },
        grossProfitMargin: {
            title: 'Gross Profit Margin',
            content: 'Percentage of revenue left after subtracting the cost of goods sold. A higher margin signals strong financial performance, while a lower one may indicate challenges like high production costs or tough competition. Investors use it to assess overall financial health.'
        },
        percentPureProfit: {
            title: 'Percent Pure Profit',
            content: 'Reveals the percentage of profit after deducting all expenses from revenue. A higher margin signals strong profitability, while a lower one may suggest challenges such as high expenses or low sales volume. Investors use it to gauge overall financial health and risk.'
        },
        returnOnAssets: {
            title: 'Return on Assets (ROA)',
            content: 'Percentage of net income generated from assets. A higher ROA signals efficient asset use and positive financial health, while a lower one may suggest challenges like low sales or high expenses. Investors use it to assess a company\'s profit generation and overall financial health.'
        },
        returnOnEquity: {
            title: 'Return on Equity (ROE)',
            content: 'Percentage of net income from shareholder equity. A higher ROE signals efficient use of equity and positive financial health, while a lower one may suggest challenges like inefficient equity use or lower profitability. Investors use it to assess a company\'s profit generation and overall financial health.'
        },
        efficiencyRatio: {
            title: 'Efficiency Ratio',
            content: 'Measures how effectively a company turns operating expenses into revenue. A lower ratio signals efficient revenue generation and positive financial health, while a higher ratio may suggest inefficiency or challenges. Investors use it to assess overall financial well-being.'
        },
        assetsTurnover: {
            title: 'Assets Turnover',
            content: 'Measures how efficiently a company generates sales from total assets. A higher ratio indicates effective asset use, while a lower ratio may suggest challenges in sales generation. Investors use this metric to assess asset efficiency in revenue generation.'
        },
        inventoryTurnover: {
            title: 'Inventory Turnover',
            content: 'Gauges how efficiently a company sells and replenishes its inventory. A higher ratio signals effective management, while a lower ratio may suggest slower turnover or excess inventory. Investors use'
        },
        percentOperatingIncome: {
            title: 'Percentage Operating Income',
            content: 'Gauges operational efficiency by showing the percentage of operating income from each dollar of sales revenue. A higher percentage signals efficiency and profitability, while a lower one may suggest challenges like high expenses. Investors use it to assess financial health.'
        },
        assetCoverage: {
            title: 'Asset Coverage Ratio',
            content: 'Measures a company\'s debt-paying ability by comparing key assets to total debt. A higher ratio signals strong financial health and better debt coverage, while a lower ratio may indicate a higher risk of default. Investors use it to gauge overall financial strength.'
        },
        debtServiceCoverage: {
            title: 'Debt Service Coverage Ratio (DSCR)',
            content: 'Measures a company\'s ability to meet debt payments. A higher DSCR signals strong debt-paying capability, while a lower ratio may suggest a higher risk of default. Investors use it to assess overall financial health.'
        },
        interestCoverage: {
            title: 'Interest Coverage Ratio',
            content: 'Gauges a company\'s ability to cover interest expenses. A higher ratio indicates better capacity to meet interest costs, while a lower ratio suggests challenges and higher financial risk. Investors use it to assess debt servicing ability and overall financial health.'
        },
        dividendPayoutRatio: {
            title: 'Dividend Payout Ratio',
            content: 'Gauges the percentage of earnings paid as dividends. A higher ratio may attract income-seeking investors but could limit growth. A lower ratio suggests more earnings for potential long-term growth. Investors use it to assess dividend policy and growth potential.'
        },
        dividendPerShare: {
            title: 'Dividend Per Share (DPS)',
            content: 'Investors use it to evaluate a stock\'s income potential and compare dividends. Companies adjust DPS based on financial performance in their dividend policy. Investors and analysts rely on DPS to assess a company\'s dividend history, financial health, and growth potential.'
        },
        earningsPerShare: {
            title: 'Earnings Per Share (EPS) Basic',
            content: 'Indicator of a company\'s profitability and growth. Higher EPS is favorable to investors, used for industry-wide profitability comparisons. Investors and analysts rely on EPS to assess financial performance and estimate share value.'
        },
        //macro currencies
        countryRating: {
            title: 'Country Ratings',
            content: 'Country rating is an assessment of a nation\'s financial health, evaluating factors like economic stability and political environment. Investors use these ratings to gauge risk and make investment decisions. Higher ratings indicate lower risk, impacting borrowing costs and international trade. Governments use ratings to guide economic policies. Ratings are dynamic and subject to change based on economic and geopolitical conditions.'
        },
        moodys: {
            title: 'Moody’s',
            content: '(Aaa) Obligations rated Aaa are judged to be of the highest quality, with minimal risk. (Aa) Obligations rated Aa are judged to be of high quality and are subject to very low credit risk (A) Obligations rated A are considered upper medium- grade and are subject to low credit risk. (Baa) Obligations rated Baa are subject to moderate credit risk.They are considered medium- grade and as such may possess speculative characteristics. (Ba) Obligations rated Ba are judged to have speculative elements and are subject to substantial credit risk. (B) Obligations rated B are considered speculative and are subject to high credit risk. (Caa) Obligations rated Caa are judged to be of poor standing and are subject to very high credit risk. (Ca) Obligations rated Ca are highly speculative and are likely in, or very near, default, with some prospect of recovery in principal and interest. (C) Obligations rated C are the lowest rated class of bonds and are typically in default, with little prospect for recovery of principal and interest.'
        },
        sp: {
            title: 'S&P',
            content: '(AAA) An obligation rated \'AAA\' has the highest rating assigned by S&amp;P Global Ratings. The obligor\'s capacity to meet its financial commitment on the obligation is extremely strong. (AA) An obligation rated \'AA\' differs from the highest-rated obligations only to a small degree. The obligor\'s capacity to meet its financial commitment on the obligation is very strong. (A) An obligation rated \'A\' is somewhat more susceptible to the adverse effects of changes in circumstances and economic conditions than obligations in higher-rated categories. However, the obligor\'s capacity to meet its financial commitment on the obligation is still strong. (BBB) An obligation rated \'BBB\' exhibits adequate protection parameters. However, adverse economic conditions or changing circumstances are more likely to lead to a weakened capacity of the obligor to meet its financial commitment on the obligation. (BB) An obligation rated \'BB\' is less vulnerable to nonpayment than other speculative issues. However, it faces major ongoing uncertainties or exposure to adverse business, financial, or economic conditions which could lead to the obligor\'s inadequate capacity to meet its financial commitment on the obligation. (B) An obligation rated \'B\' is more vulnerable to nonpayment than obligations rated \'BB\', but the obligor currently has the capacity to meet its financial commitment on the obligation. Adverse business, financial, or economic conditions will likely impair the obligor\'s capacity or willingness to meet its financial commitment on the obligation.'
        },
        fitch: {
            title: 'Fitch',
            content: '(AAA) Ratings denote the lowest expectation of default risk. They are assigned only in cases of exceptionally strong capacity for payment of financial commitments. This capacity is highly unlikely to be adversely affected by foreseeable events. (AA) Ratings denote expectations of very low default risk. They indicate very strong capacity for payment of financial commitments. This capacity is not significantly vulnerable to foreseeable events. (A) Ratings denote expectations of low default risk. The capacity for payment of financial commitments is considered strong. This capacity may, nevertheless, be more vulnerable to adverse business or economic conditions than is the case for higher ratings. (BBB) Ratings indicate that expectations of default risk are currently low. The capacity for payment of financial commitments is considered adequate, but adverse business or economic conditions are more likely to impair this capacity. (BB) Ratings indicate an elevated vulnerability to default risk, particularly in the event of adverse changes in business or economic conditions over time; however, business or financial flexibility exists that supports the servicing of financial commitments. (B) Ratings indicate that material default risk is present, but a limited margin of safety remains. Financial commitments are currently being met; however, capacity for continued payment is vulnerable to deterioration in the business and economic environment.'
        },
        kernelDensity: {
            title: 'kernel Density Estimation',
            content: 'Method used to estimate the probability density function of a dataset. It identifies outliers as data points with low probability density.'
        },
        correlation: {
            title: 'Correlation',
            content: 'Relationship between investments returns, with a higher number indicating a stronger relationship. Positive correlation means they move in the same direction; negative correlation means they move in opposite directions. Diversifying across less correlated assets can reduce portfolio risk.'
        },
        monteCarlo: {
            title: 'Monte Carlo Simulation',
            content: 'Used to model the future performance of a portfolio. Based on given data it calculates range of possible scenarios. Helps investors understanding potential risks and rewards of different portfolio strategies for a given time period and make more informed decisions about how to manage investments.'
        },
        regressionAnalysis: {
            title: 'Regression Analysis',
            content: 'Used to identify the strength of the relationship between the open price, volume, and close price, which can help investors make more informed decisions about buying or selling stocks. Additionally, it can help in identifying any potential outliers or anomalies in the data that may affect the analysis. For example, if the coefficient for the open price is high, it suggests that the open price has a strong impact on the close price, while a high coefficient for volume suggests that volume has a strong impact.'
        },
        markovChains: {
            title: 'Markov Chains',
            content: 'Used to model the probabilities of different market conditions based on historical data. It involves calculating the transition probabilities between different states and then using those probabilities to simulate potential future market conditions. This can help investors to make informed decisions about when to buy or sell stocks based on their expected future performance.'
        },

        commoditiesOverTime: {
            title: 'Commodities Over Time',
            content: 'The chart illustrates commodity values for energy, metals, agriculture, and livestock over time. It provides insights into market trends, helping investors and analysts understand supply-demand dynamics and economic conditions impacting commodity prices.'
        },

        indicesOverTime: {
            title: 'Indices Over Time',
            content: 'The graph displays indices performance over time, allowing users to filter by continents and countries. By tracking renowned indices like NASDAQ or S&P, investors gain insights into global market trends, aiding in informed investment decisions and understanding economic dynamics.'
        }


        // return: {
        //     title: 'Return Ratio',
        //     content: ''
        // }, 
    };

    return (
        <>
            {
                popupToDisplay &&
                <div className="info-popup-container" style={{ width: isMobile ? '90vw !important' : '', maxWidth: isMobile ? '90vw !important' : '', maxHeight: isMobile ? '60vh' : '' }}>
                    <div className="flex space-between">
                        <div>
                            <h2 className='info-popup-title'>{popupToDisplay.title}</h2>
                        </div>
                        <div
                            className="card-container-info-btn btn flex center-center"
                            style={{ height: 'fit-content' }}
                            onClick={() => setShowInfoModal(false)}
                        >{closeIcon}</div>
                    </div>
                    <p className='info-popup-content'>{popupToDisplay.content}</p>
                </div>
            }
        </>
    );
}

export default InfoPopup;