import React, { useState, useEffect } from "react";
import CardContainer from "../../../cmps/card-container/card-container";
import LineChart from "../../../shared/charts/line-chart/line-chart";
import PeriodDropdownSelect from "../../../shared/dropdown-select/period-dropdown-select";
import { getRandomColor, hexToRGBA } from '../../../shared/global-color-functions';
import LabelColorContainer from "../../../shared/label-color-container/label-color-container";
import { useMediaQuery } from 'react-responsive';
import './macro-inflation.scss';
import MovigHeader from "../../../shared/moving-header/moving-header";
import { Helmet } from "react-helmet";
import { InflationGuide } from "../../../cmps/page-guide";

const MacroInflation = ({ inflationData, periodFilter, onPeriodChange, periodOptionsToDisplay, clickData, setPeriodFilter }) => {

    const [interestData, setInterestData] = useState(null);
    const [inflationtData, setInflationData] = useState(null);
    const [inflationColors, setInflationColors] = useState(null);
    const [interestColors, setInterestColors] = useState(null);
    const [kpiCards, setKpiCards] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const isMobile = useMediaQuery({ maxWidth: 920 });

    useEffect(() => {
        if (!inflationData.kpi || !inflationData.kpi.length) return;
        const mergedArray = [];
        inflationData.kpi.forEach(kpiItem => {
            const inflationMatch = inflationData.inflationOverTime[periodFilter].find(item => item.key === kpiItem.title);
            if (inflationMatch) {
                const newData = inflationMatch.data.map(entry => ({
                    x: entry.date,
                    y: entry.x
                }));
                const newItem = { ...kpiItem, data: newData };
                mergedArray.push(newItem);
            } else {
                const interestMatch = inflationData.interestOverTime[periodFilter].find(item => item.key === kpiItem.title);
                if (interestMatch) {
                    const newData = interestMatch.data.map(entry => ({
                        x: entry.date,
                        y: entry.x
                    }));
                    const newItem = { ...kpiItem, data: newData };
                    mergedArray.push(newItem);
                }
            }
        });
        setKpiCards(mergedArray);
    }, [inflationData.kpi, periodFilter]);

    useEffect(() => {
        if (!inflationData.inflationOverTime[periodFilter]) {
            setPeriodFilter('sixMonth');
            return;
        };
        const infColorPalette = ['#d68d3a', '#849cb2', '#5c7c59', '#ba7d8c'];
        const infColors = inflationData.inflationOverTime[periodFilter].map((item, idx) => {
            return {
                key: item.key,
                label: item.key,
                color: infColorPalette[idx % infColorPalette.length]
            };
        });
        setInflationColors(infColors);
        const intColorPalette = ['#d68d3a', '#849cb2', '#ba7d8c'];
        const intColors = inflationData.interestOverTime[periodFilter].map((item, idx) => {
            return {
                key: item.key,
                label: item.key,
                color: intColorPalette[idx]
            };
        });
        setInterestColors(intColors);
        setInterestData(getCompaniesLineChartData(inflationData.interestOverTime, intColors));
        setInflationData(getCompaniesLineChartData(inflationData.inflationOverTime, infColors));
    }, [inflationData, periodFilter, selectedItem, periodOptionsToDisplay]);

    const onChangeSelectedItem = (item) => {
        setSelectedItem(item);
    };

    useEffect(() => {
        setSelectedItem(null);
    }, [clickData]);

    const getCompaniesLineChartData = (graphData, colorData) => {
        const filteredData = graphData[periodFilter];
        const fixedData = [];
        filteredData.forEach((item) => {
            const color = colorData.find(colorItem => colorItem.key === item.key)?.color;
            const backgroundOpacity = selectedItem !== null && selectedItem !== undefined ? 0.4 : 1;
            const borderWidth = selectedItem === item.key ? 5 : 3;

            fixedData.push({
                label: item.key,
                data: item.data.map(d => ({ x: d.date, y: d.x })),
                backgroundColor: selectedItem !== null && selectedItem !== undefined
                    ? selectedItem !== item.key
                        ? hexToRGBA(color || getRandomColor(), backgroundOpacity)
                        : color || getRandomColor()
                    : color || getRandomColor(),
                borderColor: selectedItem !== null && selectedItem !== undefined
                    ? selectedItem !== item.key
                        ? hexToRGBA(color || getRandomColor(), backgroundOpacity)
                        : color || getRandomColor()
                    : color || getRandomColor(),
                borderWidth: borderWidth,
                fill: false
            });
        });
        return {
            datasets: fixedData
        };
    };

    const inflationLineChart = (
        <>
            {
                inflationtData &&
                inflationColors &&
                <div style={{ width: '99.9%', height: '90%' }}>
                    <LineChart
                        chartData={inflationtData}
                        type='percent-decimal'
                        onChangeSelectedItem={onChangeSelectedItem}
                    />
                </div>
            }
        </>
    );

    const inflationLineChartTitle = (
        <>
            <h4 className='portfolio-chart-title'>Inflation Over Time</h4>
        </>
    );

    const interestLineChart = (
        <>
            {
                interestColors &&
                interestData &&
                <div style={{ width: '99.9%', height: '90%' }}>
                    <LineChart
                        chartData={interestData}
                        type='percent-decimal'
                        onChangeSelectedItem={onChangeSelectedItem}
                    />
                </div>
            }
        </>
    );

    const interestLineChartTitle = (
        <>
            <h4 className='portfolio-chart-title'>Interest Over Time</h4>
        </>
    );

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow - Macro Inflation</title>
                <meta name="description" content="unlock the potential of macroeconomic analysis in shaping your investment strategies! A comprehensive overview of key macroeconomic indicators, currency exchange rates, stock market indices, and commodity prices." />
                <link rel="canonical" href="https://www.portfollow.com/macro/inflation" />
            </Helmet>

            {
                kpiCards && kpiCards.length &&
                <MovigHeader movingHeaderData={kpiCards} type={'macro-inflation'} />
            }
            <div className="noheader-media dropdown-select-filter-container flex center-center">
                <PeriodDropdownSelect
                    currFilter={periodFilter}
                    onChangeFilter={onPeriodChange}
                    optionsToDisplay={periodOptionsToDisplay}
                />
            </div>
            {/* <div className='findatasheets-container'> */}
            <div className='macro-container-height'>


                <div className={`findatasheets-container-item mb20 flex ${isMobile ? 'column' : ''}`}>

                    {
                        interestColors &&
                        <LabelColorContainer
                            labels={interestColors}
                            height={'100%'}
                            type={null}
                            display={'column'}
                            onChangeSelectedItem={() => { }}
                        />
                    }
                    <CardContainer
                        title={interestLineChartTitle}
                        innerContent={interestLineChart}
                        className={'macro-graph'}
                    />

                </div>

                <div className={`findatasheets-container-item flex ${isMobile ? 'column' : ''}`}>

                    {
                        inflationColors &&
                        <LabelColorContainer
                            labels={inflationColors}
                            height={'100%'}
                            type={null}
                            display={'column'}
                            onChangeSelectedItem={() => { }}
                        />
                    }
                    <CardContainer
                        title={inflationLineChartTitle}
                        innerContent={inflationLineChart}
                        className={`macro-graph ${isMobile ? 'mb20' : ''}`}
                    />
                </div>

            </div>
            <div style={{ margin: '20px 0' }}>
                <InflationGuide />
            </div>
        </>
    );
}

export default MacroInflation;