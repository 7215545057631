import './moving-header-item.scss';
import { Line } from 'react-chartjs-2';

const MovingHeaderItem = ({ item }) => {

    const getChartData = (data) => {
        const chartData = {
            labels: [],
            datasets: [],
        };
        const chartDataset = {
            label: '',
            data: [],
            borderColor: [],
            backgroundColor: [],
            borderWidth: 2,
            pointRadius: 0,
            fill: {
                target: 'origin',
                below: '#e39774',
                above: '#5c9ead'
            },
            borderWidth: 0,
            pointRadius: 0
        };
        data.forEach((item) => {
            chartDataset.data.push({ x: item.x, y: +item.y });
            chartDataset.backgroundColor.push(item.y >= 0 ? "#5c9ead" : "#e39774");
            chartDataset.borderColor.push(item.y >= 0 ? "#5c9ead" : "#e39774");
        });
        chartData.datasets.push(chartDataset);
        return chartData;
    };

    const areaChartOptions = {
        responsive: true, // Make the chart responsive
        maintainAspectRatio: false,
        aspectRatio: 2,
        plugins: {
            legend: {
                display: true,
                labels: {
                    boxWidth: 0,
                },
            },
            filler: {
                propagate: true
            }
        },
        scales: {
            x: {
                display: false,
                grid: {
                    display: false
                }
            },
            y: {
                display: false,
                min: item.minValue,
                max: item.maxValue,
                grid: {
                    display: false
                },
                ticks: {
                    callback: function (value, index, values) {
                        return value == 0 ? "" : value;
                    }
                },
                gridLines: {
                    borderDash: [2],
                    zeroLineWidth: 2,
                    zeroLineColor: 'red',
                    drawBorder: false,
                    drawOnChartArea: false,
                    drawTicks: false
                }
            },
        }
    };

    const lastPriceChange = parseFloat(item.lastPriceChange);
    const textColor = lastPriceChange > 0 ? '#5c9ead' : '#e39774';

    return (
        <div className='moving-header-slide homepage-moving-header'>
            <div className='moving-header-slide-item'>
                <div className='moving-header-item-info moving-header-item-title'>{item.title}</div>
                <div className="moving-header-item flex space-between">
                    <div className="moving-header-item-info-container flex column justify-center">
                        <div className='moving-header-item-info moving-header-item-price'>${item.price}</div>
                        <div className='moving-header-item-info moving-header-item-info-pricechange flex' style={{ color: textColor }}>
                            {item.lastPriceChange} <span className='moving-header-item-info-changepercent'>({item.lastPricePercentChange})</span>
                        </div>
                    </div>
                    <div className='moving-header-item-img homepage-moving-header-linechart'>
                        <Line data={getChartData(item.data)} options={areaChartOptions} />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default MovingHeaderItem;