const CryptoSlide = () => {
    return (
        <>
            <h2 className="app-tutorial-slide-title">Demo 4 - Tracking Cryptocurrencies:</h2>

            <p className="app-tutorial-p">
                Navigate to the Crypto Page and select a cryptocurrency to analyze. <br />
                View price charts, market data, and news articles related to the selected cryptocurrency.
            </p>

            <div className="flex justify-center">
                <img src="/img/tutorial/crypto.png" alt="Crypto Currencies img "  className="tutorial-page-img"/>
            </div>

            <p className="app-tutorial-p">
                The Crypto Page provides valuable insights into the performance, return, and risk-adjusted returns of various cryptocurrencies. <br />
                Whether you're a seasoned investor or just starting in the cryptomarket, our interactive charts and filters empower you to make informed decisions and stay ahead in the market. <br />
                Start exploring now and unlock the potential of cryptocurrency investments!
            </p>
        </>
    );
}

export default CryptoSlide;