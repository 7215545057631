import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fameIcon, followIcon, followUserOutline, followUserSharp, followersIcon, unfollowIcon, userImg } from "../../../shared/icons/icons";
import SimpleFollowBtn from "../../../shared/simple-follow-btn/simple-follow-btn";
import ProfileAbout from "../profile-about";
import { setFame, userUploadProfileImg } from "../../../shared/actions/profile-actions";
import axios from "axios";
import { incrementUserFame } from "../../../store/user/user-actions";

const ProfileHeader = ({
    loggedInUser,
    username,
    isMyProfile,
    toggleFollowing,
    userFollowing,
    about,
    onChangeAbout,
    visitedProfile,
    fetchUserData
}) => {

    const [profile, setProfile] = useState(null);
    const dispatch = useDispatch();

    const userSelector = useSelector((state) => state.user);

    // console.log('visited:', visitedProfile);
    // console.log('me:', userSelector);

    const [isFollowing, setIsFollowing] = useState(false);
    const [isFamed, setIsFamed] = useState(false);
    const [showErrMsg, setErrMsg] = useState(false);

    const [userFollowers, setUserFollowers] = useState([]);

    const [imageError, setImageError] = useState(false);

    // set the followers of the user.
    // useEffect(() => {
    //     if (visitedProfile) {
    //         setUserFollowers();
    //     } else {
    //         setUserFollowers();
    //     }
    // }, [visitedProfile]);

    const defaultImg = '/img/defaults/default-user-img.png';

    const handleFollowClick = () => {
        const type = 'users';
        const symbol = username;
        if (loggedInUser) {
            setIsFollowing(!isFollowing);
            toggleFollowing(type, symbol);
        } else {
            setErrMsg(true);
            setTimeout(() => {
                setErrMsg(false);
            }, 5000);
        };
    };

    // profile
    const handleFameClick = async () => {
        if (loggedInUser) {
            try {
                dispatch(incrementUserFame());
                await setFame(loggedInUser, username);
            } catch (error) {
                console.error('Error setting fame:', error);
            }
        }
    };

    useEffect(() => {
        if (isMyProfile) {
            // console.log('user:', userSelector);
            setProfile(userSelector);
        } else {
            // console.log('user:', visitedProfile);
            setProfile(visitedProfile);
        }
    }, [isMyProfile, userSelector, visitedProfile]);

    const followEl = (
        <>
            <div className='flex align-center' style={{ marginRight: '5px' }}>{followUserOutline}</div>
            {/* <div>{userSelector.followers ? userSelector.followers : 0}</div> */}
        </>
    );

    const followingEl = (
        <>
            <div className='flex align-center' style={{ marginRight: '5px' }}>{followUserSharp}</div>
            {/* <div>{userSelector.followers ? (userSelector.followers + 1) : 1}</div> */}
        </>
    );

    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        handleUpload();
    }, [selectedFile])


    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (ev) => {
        const file = ev.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            console.log('No file selected');
            return;
        };
        try {
            const presignedUrl = await userUploadProfileImg(profile.userid, selectedFile);
            try {
                await axios.put(presignedUrl, selectedFile, {
                    headers: {
                        'Content-Type': selectedFile.type,
                    },
                });
                console.log('Image uploaded successfully');
                fetchUserData();
                window.location.reload();
            } catch (error) {
                console.error('Error uploading image', error);
            };
        } catch (error) {
            console.error('File upload failed', error);
        };
    };

    return (

        <>
            <div className="pos-rel">
                <div className="flex center-center">
                    {

                        profile &&
                        profile.fund &&
                        profile.fund.png &&
                        !imageError &&
                        <img
                            className="profile-header-fundimg"
                            src={profile.fund.png}
                            alt="fund-img"
                            onError={() => setImageError(true)}
                        />
                    }
                </div>

                <div>
                    <div className="user-profile-img-container flex align-center" onClick={handleImageClick}>
                        {
                            profile &&
                                profile.fund &&
                                profile.fund.png &&
                                profile.pngUrl ? (
                                <img
                                    className="user-profile-img"
                                    src={profile.pngUrl}
                                    alt="Profile img"
                                    onError={(e) => { e.target.src = defaultImg; }}
                                />
                            ) : null
                        }
                        {
                            isMyProfile && profile && (profile.fund && profile.fund.png) && !profile.pngUrl && !imageError && (
                                <div
                                    className="btn"
                                    style={{ backgroundColor: '#ffffff' }}
                                    title="Upload profile image"
                                >
                                    Upload Image
                                </div>
                            )
                        }
                    </div>

                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        accept="image/*"
                    />
                </div>
            </div>

            <div className="flex space-between mb20">
                <div className="flex align-center">
                    {
                        (
                            !profile ||
                            !profile.fund ||
                            !profile.fund.png
                        ) &&
                        profile &&
                        profile.pngUrl &&
                        <img
                            className="user-profile-img user-profile-img-no-fund mr20"
                            src={profile.pngUrl}
                            alt="Profile img"
                            onError={(e) => { e.target.src = defaultImg; }}
                        />
                    }
                    <h2>{username}</h2>
                </div>


                {
                    isMyProfile ? (
                        // <div className="flex">
                        //     <div
                        //         className="profile-user-topdata flex align-center"
                        //         title={'Fame'}
                        //         style={{ marginRight: '10px' }}
                        //     >
                        //         <div className="flex align-center" style={{ marginRight: '5px' }}>{fameIcon}</div>
                        //         {/* <div className="flex align-center">{userSelector.followers ? (userSelector.followers + 1) : 1}</div> */}
                        //     </div>
                        //     <div
                        //         className="profile-user-topdata flex align-center"
                        //         title={'Followers'}
                        //         style={{ marginRight: 0 }}
                        //     >
                        //         <div className="flex align-center">{isFollowing ? followingEl : followEl}</div>
                        //     </div>
                        // </div>
                        <></>
                    ) : (
                        <div className="flex align-center">
                            <div
                                className="profile-user-topdata flex align-center btn btn-border"
                                title={'Fame'}
                                style={{ marginRight: '10px' }}
                                onClick={handleFameClick}
                            >
                                <div className="flex align-center" style={{ marginRight: '5px' }}>{fameIcon}</div>
                                <div className="flex align-center">{userSelector.fame ? userSelector.fame : 0}</div>
                            </div>
                            <div
                                className="profile-user-topdata flex align-center btn btn-border"
                                title={'Followers'}
                                style={{ marginRight: 0 }}
                                onClick={handleFollowClick}
                            >
                                <div className="flex align-center">{isFollowing ? followingEl : followEl}</div>
                            </div>
                        </div>
                    )
                }

            </div>

            {
                isMyProfile && profile && (!profile.fund || !profile.fund.png) && !profile.pngUrl && (

                    <div
                        className="btn btn-border mb20"
                        onClick={handleImageClick}
                        title="Upload profile image"
                        style={{ width: 'fit-content', backgroundColor: '#ffffff' }}>
                        Upload Image
                    </div>
                )
            }

            {
                ((isMyProfile) || (!isMyProfile && about)) &&
                <ProfileAbout
                    isMyProfile={isMyProfile}
                    about={about}
                    onChangeAbout={onChangeAbout}
                />
            }
        </>
    );
}

export default ProfileHeader;