import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { homeIcon, userIcon, cryptoIcon, macroIcon, portfolioIcon, logoutIcon, loginIcon, signupIcon, moreIcon, calendarIcon } from '../../../shared/icons/icons';
import { AccountContext } from '../../../user-pool/account';
import './header-pages.scss';

const HeaderPages = ({ loggedInUser, isSideMenuOpen, handleSideMenuChange, onLogout }) => {

    const { logout } = useContext(AccountContext);
    const history = useHistory();
    const loadingSelector = useSelector((state) => state.loading.isLoading);
    const isDisplayLeftNavBarOpen = useSelector((state) => state.loading.isDisplayLeftNavBarOpen);
    const [activeLink, setActiveLink] = useState('');
    const location = useLocation();

    const [displayClass, setDisplayClass] = useState("display-block");
    const [moreDisplayClass, setMoreDisplayClass] = useState("display-none");

    useEffect(() => {
        if (displayClass !== 'display-none') return;
        setIsMoreHovered(false);
        setMoreDisplayClass("display-none");
    }, [displayClass]);

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location]);

    const handleNavigateAbout = () => {
        history.push('/about');
    };

    const handleNavigateTerms = () => {
        history.push('/terms-of-service');
    };

    const handleNavigatePrivacy = () => {
        history.push('/privacy-notice');
    };

    const capitalFirstLetter = (str) => {
        if (!str) return;
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const onCloseModalHover = () => {
        setTimeout(() => {
            setDisplayClass("display-block");
        }, 100);
    };

    const onOpanModalHover = () => {
        setDisplayClass("display-none");
    };

    const [isMoreHovered, setIsMoreHovered] = useState(false);

    const handleMoreHover = () => {
        setIsMoreHovered(true);
        setTimeout(() => {
            setMoreDisplayClass("display-block");
        }, 100);
    };

    const handleMouseLeaveMore = () => {
        setIsMoreHovered(false);
        setMoreDisplayClass("display-none");
    };

    const NavLink = ({ icon, label, to, exact }) => (

        <Link
            // className={`sidemenu-nav ${activeLink === to ? 'active-sidemenu-nav' : ''}`}
            // className={`sidemenu-nav ${activeLink === to ? 'active-sidemenu-nav' : ''}`}
            className={`sidemenu-nav ${activeLink === to || (to.length > 1 && activeLink.startsWith(to)) ? 'active-sidemenu-nav' : ''}`}
            to={to}
            onClick={(e) => {
                // if (loadingSelector) {
                // e.preventDefault();
                // } else {
                handleSideMenuChange(false);
                setDisplayClass(false);
                setActiveLink(to);
                // }
            }}
        // disabled={loadingSelector} // Added disabled prop
        >
            <div className="flex align-center">
                <div className='sidemenu-icon-item'> <div>{icon}</div> </div>
                {/* { label && <div className={displayClass}>{label}</div> } */}
                {label && <div className={'sidemenu-icon-item-label'}>{label}</div>}
            </div>
        </Link>
    );

    const closedModal = (
        <div className="nav-bar navbar-item flex column justify-center"
            onMouseEnter={() => onCloseModalHover(true)}
        >

            {
                loggedInUser &&
                <div className='main-nav-item navbar-page-btn flex center-center' title='Profile' >
                    <NavLink icon={userIcon} label={`${loggedInUser}`} to={`/profile/portfolios/${loggedInUser}`} />
                </div>
            }

            {
                !loggedInUser &&
                <div className='main-nav-item navbar-page-btn flex center-center' title='Login'>
                    <NavLink icon={loginIcon} label={'Login'} to='/login' />
                </div>
            }

            {
                !loggedInUser &&
                <div className='main-nav-item navbar-page-btn flex center-center' title='Signup'>
                    <NavLink icon={signupIcon} label={'Signup'} to='/signup' />
                </div>
            }


            <div className='main-nav-item navbar-page-btn flex center-center' title='Home' id='home-navigation'>
                <NavLink icon={homeIcon} label={'Home'} to='/' exact={true} />
            </div>
            
            <div className='main-nav-item navbar-page-btn flex center-center' title='Portfolio' id='portfolio-navigation'>
                <NavLink icon={portfolioIcon} label={'Portfolio'} to='/portfolio/companies' />
            </div>
            <div className='main-nav-item navbar-page-btn flex center-center' title='Macro' id='macro-navigation'>
                <NavLink icon={macroIcon} label={'Macro'} to='/macro/inflation' />
            </div>
            <div className='main-nav-item navbar-page-btn flex center-center' title='Crypto'>
                <NavLink icon={cryptoIcon} label={'Crypto'} to='/crypto/currencies' />
            </div>
            <div className='main-nav-item navbar-page-btn flex center-center' title='Calendar'>
                <NavLink icon={calendarIcon} label={'Calendar'} to='/calendar/companies' />
            </div>
            {/* <div className='main-nav-item navbar-page-btn flex center-center'>
                <NavLink icon={moreIcon} to='/' />
            </div> */}
            {
                loggedInUser &&
                <div className='main-nav-item navbar-page-btn flex center-center' title='Logout'>
                    <NavLink icon={logoutIcon} label={'Logout'} to='/login' />
                </div>
            }
        </div>
    );

    const openModal = (
        <div className="nav-bar navbar-item flex column justify-center"
            onMouseLeave={() => onOpanModalHover(false)}
        >
            {
                loggedInUser &&
                <div className='main-nav-item navbar-page-btn flex center-center'>
                    <NavLink icon={userIcon} label={capitalFirstLetter(loggedInUser)} to={`/profile/portfolios/${loggedInUser}`} />
                </div>
            }

            {
                !loggedInUser &&
                <div className='main-nav-item navbar-page-btn flex center-center'>
                    <NavLink icon={loginIcon} label={'Login'} to={`/login`} />
                </div>
            }

            {
                !loggedInUser &&
                <div className='main-nav-item navbar-page-btn flex center-center'>
                    <NavLink icon={signupIcon} label={'Signup'} to={`/signup`} />
                </div>
            }

            <div className='main-nav-item navbar-page-btn flex center-center'>
                <NavLink icon={homeIcon} label={'Home'} to='/' exact={true} />
            </div>
            <div className='main-nav-item navbar-page-btn flex center-center'>
                <NavLink icon={portfolioIcon} label='Portfolio' to='/portfolio/companies' />
            </div>
            <div className='main-nav-item navbar-page-btn flex center-center'>
                <NavLink icon={macroIcon} label='Macro' to='/macro/inflation' />
            </div>
            <div className='main-nav-item navbar-page-btn flex center-center'>
                <NavLink icon={cryptoIcon} label='Crypto' to='/crypto/currencies' />
            </div>
            <div className='main-nav-item navbar-page-btn flex center-center'>
                <NavLink icon={calendarIcon} label='Calendar' to='/calendar/companies' />
            </div>

            {/* <div
                className='main-nav-item navbar-page-btn flex center-center position-relative'
                onMouseEnter={handleMoreHover}
                onMouseLeave={handleMouseLeaveMore}
            >
                <NavLink icon={moreIcon} label='More' to='/' />

                {isMoreHovered && (
                    <div className={`header-pages-more-open ${moredisplayClass} flex column`}>


                        <div
                            className='main-nav-item navbar-page-btn'
                            onClick={handleNavigateAbout}
                        >
                            About Us
                        </div>
                        <div
                            className='main-nav-item navbar-page-btn'
                            onClick={handleNavigateTerms}
                        >
                            Terms of Service
                        </div>
                        <div
                            className='main-nav-item navbar-page-btn'
                            onClick={handleNavigatePrivacy}
                        >
                            Privacy Policy
                        </div>


                    </div>
                )}
            </div> */}

            <div className='' onMouseLeave={handleMouseLeaveMore}>
                {/* <div
                    className='position-relative pos-rel main-nav-item navbar-page-btn flex center-center'
                    onMouseEnter={handleMoreHover}

                >
                    <NavLink icon={moreIcon} label='More' to='/' />
                </div> */}

                {/* {isMoreHovered && (
                    <div
                        className={`portfolio-menu-options header-pages-more-open ${moreDisplayClass} flex column justify-center`}
                        onMouseEnter={handleMoreHover}
                    >
                        <div
                            className='portfolio-menu-item'
                            style={{ paddingLeft: '10px' }}
                            onClick={handleNavigateAbout}
                        >
                            About Us
                        </div>
                        <div
                            className='portfolio-menu-item'
                            style={{ paddingLeft: '10px' }}
                            onClick={handleNavigateTerms}
                        >
                            Terms of Service
                        </div>
                        <div
                            className='portfolio-menu-item'
                            style={{ paddingLeft: '10px' }}
                            onClick={handleNavigatePrivacy}
                        >
                            Privacy Policy
                        </div>
                    </div>
                )} */}
            </div>




            {
                loggedInUser &&
                <div className='main-nav-item navbar-page-btn flex center-center' onClick={() => handleLogout()}>
                    <NavLink icon={logoutIcon} label='Logout' to='/login' />
                </div>
            }



        </div>
    );

    // log in
    // sign up


    const handleLogout = () => {
        logout();
        onLogout();
    };

    return (
        <div className={`nav-items-container ${!isDisplayLeftNavBarOpen ||
            location.pathname === '/' ||
            location.pathname === '/login' ||
            location.pathname === '/signup' ?
            'display-none !important' : ''}`
        }
            onMouseEnter={() => handleSideMenuChange(true)}
            onMouseLeave={() => handleSideMenuChange(false)}
        >
            {
                isSideMenuOpen ? openModal : closedModal
            }
        </div>
    );
}

export default HeaderPages;