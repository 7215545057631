import IconBtn from '../../../cmps/icon-btn/icon-btn';
import { loginIcon, signupIcon } from '../../../shared/icons/icons';
import { useHistory } from "react-router-dom";

const HomepageWelcomeUser = () => {

    const history = useHistory();

    const handleNavigateLogin = () => {
        history.push('/login');
    };

    const handleNavigateSignup = () => {
        history.push('/signup');
    };

    return (
        <div className="card-container mb10" style={{ fontSize: '1.2rem' }}>
            <h2 className='mb10'>Welcome</h2>
            <p className='mb20' >Unlock your full potential with free portfolio tools and share your insights.</p>
            <div className="flex space-around">
                <IconBtn
                    icon={loginIcon}
                    iconSize={'1.8rem'}
                    text={'Login'}
                    handleClickEv={handleNavigateLogin}
                />
                <IconBtn
                    icon={signupIcon}
                    iconSize={'1.8rem'}
                    text={'Signup'}
                    handleClickEv={handleNavigateSignup}
                />
            </div>
        </div>
    );
}

export default HomepageWelcomeUser;