import React, { useState } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "./user-pool";
import { useHistory } from 'react-router-dom';
import './user-pool.scss';

const EmailVerification = ({ setLoggedInUser, handleNewUser }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const history = useHistory();

    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const handleVerificationCode = async (e) => {
        e.preventDefault();

        const userData = {
            Username: username,
            Pool,
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmRegistration(verificationCode, true, (err, data) => {
            if (err) {
                console.error(err);
                setIsError(true);

                // Handle error cases...

            } else {
                // Authenticate the user to fetch user attributes
                cognitoUser.authenticateUser(new AuthenticationDetails({ Username: username, Password: password }), {
                    onSuccess: (session) => {
                        // Fetch user attributes to get the user ID
                        cognitoUser.getUserAttributes((attributesErr, attributesData) => {
                            if (attributesErr) {
                                console.error('Error fetching user attributes:', attributesErr);
                                setIsError(true);
                            } else {
                                const subAttribute = attributesData.find((attribute) => attribute.getName() === 'sub');
                                const cognitoUserId = subAttribute?.getValue();
                                // handleNewUser(username, cognitoUserId);
                                handleNewUser(username);
                                attemptAuthentication(cognitoUser);
                            }
                        });
                    },
                    onFailure: (authError) => {
                        console.error('Authentication error:', authError);
                        setIsError(true);

                        // Handle authentication error cases...
                    },
                });
            }
        });
    };

    const attemptAuthentication = (cognitoUser) => {
        const authenticationData = {
            Username: username,
            Password: password,
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (session) => {
                console.log('Authentication successful'); // Log for debugging
                // setLoggedInUser(username);
                history.push('/');
            },
            onFailure: (authError) => {
                console.error(authError); // Log the authentication error for debugging
                setIsError(true);

                if (authError.code === 'UserNotConfirmedException') {
                    setErrorMsg(
                        <div>
                            User is not confirmed. Please check your email for a confirmation code.{' '}
                            <button className="btn" onClick={() => handleResendVerificationCode(cognitoUser)}>Send New Code</button>
                        </div>
                    );
                } else if (authError.code === 'NotAuthorizedException') {
                    // User is already confirmed, redirect to login page
                    history.push('/login');
                } else if (authError.code === 'NetworkingError') {
                    setErrorMsg('A network error occurred. Please check your internet connection and try again.');
                } else {
                    setErrorMsg(
                        <div>
                            An error occurred during authentication. Please try again later.{' '}
                            <button className="btn" onClick={() => handleResendVerificationCode(cognitoUser)}>Send New Code</button>
                        </div>
                    );
                }
            },
        });
    };

    const handleResendVerificationCode = (cognitoUser) => {
        cognitoUser.resendConfirmationCode((err, result) => {
            if (err) {
                console.error(err); // Log the error for debugging
                setIsError(true);
                if (err.message === 'LimitExceededException') {
                    setErrorMsg('Too many attempts. Please try again later.');
                } else if (err.message === 'NetworkingError') {
                    setErrorMsg('A network error occurred. Please check your internet connection and try again.');
                } else {
                    setErrorMsg(
                        <div>
                            Error resending the verification code. Please try again later.{' '}
                            <button className="btn" onClick={() => handleResendVerificationCode(cognitoUser)}>Send New Code</button>
                        </div>
                    );
                }
            } else {
                console.log('Verification code resent successfully'); // Log for debugging
                // Optionally provide user feedback, e.g., a success message
            }
        });
    };

    return (
        <div className="user-pool-main-container flex center-center" >
            <div className="user-pool-container card-container flex column" style={{padding: '60px 100px', height: '60vh'}}>
                {/* <PortFollowLogo /> */}
                <div className="flex column center-center">
                    <h2 className="user-pool-title email-verification-title">Email Verification</h2>
                    <img src="/img/portfollow-logo/PortFollow-icon3.png" alt="PortFollow logo" className='findatasheets-img findatasheets-logo-auth-img' />
                    <form onSubmit={handleVerificationCode} className='user-pool-form flex column'>
                        <input
                            className="user-pool-input email-verification-input"
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder='Username'
                        />
                        <input
                            className="user-pool-input email-verification-input"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='Password'
                        />
                        <input
                            className="user-pool-input email-verification-input"
                            type="text"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            placeholder='Verification Code'
                        />
                        <div>
                            {isError && <p>{errorMsg}</p>}
                        </div>
                        <div className="flex justify-center">
                            <button className="btn btn-border email-verification-btn" type="submit">Verify Email and Log In</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EmailVerification;
