import QuestList from "../profile-quests/quests-list";
import challengesList from "./challenges-list";

const ProfileChallenges = ({
    isMyProfile,
    username,
    challengeInProcess,
    handleChangeChallengeInProcess,
    userChallenges
}) => {

    return (
        <>
            <QuestList
                isMyProfile={isMyProfile}
                type={'challenges'}
                quests={challengesList}
                challengeInProcess={challengeInProcess}
                handleChangeChallengeInProcess={handleChangeChallengeInProcess}
                userChallenges={userChallenges}
            />
        </>
    );
}

export default ProfileChallenges;