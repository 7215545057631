import React, { useState, useEffect } from 'react';
import ManagePortfolio from '../manage-portfolio/manage-portfolio';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './empty-portfolio.scss';

const EmptyPortfolio = ({
    loggedInUser,
    selectedPortfolio,
    myPortfolio,
    addStocks
}) => {

    const history = useHistory();

    const [openModal, setOpenModal] = useState(false);

    // const loggedInUser = useSelector((state) => state.user);

    const handleLoginClick = () => {
        history.push('/login');
    };

    const handleSignupClick = () => {
        history.push('/signup');
    };

    const handleProfileClick = () => {
        history.push(`/profile/portfolios/${loggedInUser}`);
    };

    useEffect(() => {
        const appHeaderContainer = document.querySelector('.app-header-container');
        appHeaderContainer.style.display = openModal ? 'none' : 'flex';
        return () => {
            appHeaderContainer.style.display = '';
        };
    }, [openModal]);

    return (
        <>
            <div className='empty-portfolio-container flex center-center'>
                <div className="card-container flex column">
                    <div className='flex center-center mb20'>
                        <img src={'/img/portfollow-logo/PortFollow-icon2.png'} alt="PortFollow logo" className='findatasheets-img findatasheets-logo-auth-img' style={{ marginBottom: '0' }} />
                    </div>
                    <div className='flex column'>
                        <h2 className='mb20'>Unlock the full potential of your portfolio and diversify for success - add multiple assets and reap the benefits:</h2>
                        <section className='flex column'>
                            <p className='empty-portfolio-p'>
                                <span className='empty-portfolio-span'> Risk Mitigation: </span>
                                Spread your investments to minimize the impact of market fluctuations on any single asset.
                            </p>
                            <p className='empty-portfolio-p'>
                                <span className='empty-portfolio-span'>Market Exposure: </span>
                                Gain a comprehensive view of the market by including various assets from different sectors and industries.
                            </p>
                            <p className='empty-portfolio-p'>
                                <span className='empty-portfolio-span'>Stability Through Diversity: </span>
                                Build a robust and stable portfolio with a diversified mix of assets, optimizing risk management.
                            </p>
                            <p className='empty-portfolio-p'>
                                <span className='empty-portfolio-span'>Maximize Returns: </span>
                                Increase your potential for returns while reducing the risk of significant losses through a diversified investment approach.
                            </p>
                            <p className='empty-portfolio-p'>
                                <span className='empty-portfolio-span'>Adapt to Change: </span>
                                Ensure your portfolio is adaptable to changing market trends, positioning you to capitalize on emerging opportunities.
                            </p>
                            <p className='empty-portfolio-p'>
                                <span className='empty-portfolio-span'>Personalized Strategy: </span>
                                Tailor your portfolio to align with your unique financial goals, risk tolerance, and preferences.
                            </p>
                        </section>
                        {/* <div
                            className='btn btn-border'
                            style={{ width: 'fit-content', margin: '20px auto' }}
                            onClick={() => setOpenModal(true)}
                        >
                            Manage Portfolio
                        </div> */}
                        {
                            loggedInUser ? (
                                <div
                                    className="flex"
                                    style={{ margin: '20px auto' }}
                                >
                                    <div
                                        className="btn btn-border"
                                        style={{ width: 'fit-content', marginRight: '20px' }}
                                        onClick={() => setOpenModal(true)}
                                        title='Manage Portfolio'
                                    >
                                        Manage Portfolio
                                    </div>
                                    <div
                                        className="btn btn-border"
                                        style={{ width: 'fit-content' }}
                                        onClick={handleProfileClick}
                                        title='My Profile'
                                    >
                                        Profile
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="flex"
                                    style={{ margin: '20px auto' }}
                                >
                                    <div
                                        className="btn btn-border"
                                        style={{ width: 'fit-content', marginRight: '20px' }}
                                        onClick={handleLoginClick}
                                        title='Log in'
                                    >
                                        Login
                                    </div>
                                    <div
                                        className="btn btn-border"
                                        style={{ width: 'fit-content' }}
                                        onClick={handleSignupClick}
                                        title='Sign Up'
                                    >
                                        Signup
                                    </div>
                                </div>
                            )
                        }



                    </div>
                </div>
            </div>
            <ManagePortfolio
                open={openModal}
                onClose={() => setOpenModal(false)}
                selectedPortfolio={selectedPortfolio}
                myPortfolio={myPortfolio}
                addStocks={addStocks}
                loggedInUser={loggedInUser}
            />
        </>
    );
}

export default EmptyPortfolio;