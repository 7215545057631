import React from "react";
import PeriodDropdownSelect from "../../../shared/dropdown-select/period-dropdown-select";
import MacroPriceRelativeFilter from "../macro-price-relative-filter";
import MacroCurrencyFilter from "./macro-currencies-currency-filter";

const MacroCurrenciesFilters = ({
    currencies,
    selectedCurrencies,
    handleCurrenciesChange,
    periodFilter,
    onPeriodChange,
    periodOptionsToDisplay,
    activePriceRelative,
    changePriceRelative
}) => {

    return (
        <div className="dropdown-select-filter-container flex center-center">
            {
                <PeriodDropdownSelect
                    currFilter={periodFilter}
                    onChangeFilter={onPeriodChange}
                    optionsToDisplay={periodOptionsToDisplay}
                />
            }
            {
                currencies &&
                selectedCurrencies &&
                <MacroCurrencyFilter
                    currencies={currencies}
                    selectedCurrencies={selectedCurrencies}
                    handleCurrenciesChange={handleCurrenciesChange}
                />
            }
            {
                <MacroPriceRelativeFilter
                    activePriceRelative={activePriceRelative}
                    changePriceRelative={changePriceRelative}
                />
            }
        </div>
    );
}

export default MacroCurrenciesFilters;