import ProfileAchievementItem from "./profile-achievement-item";

const ProfileAchievementsList = ({
    isMyProfile,
    achievementsList,
    challengeInProcess,
    handleChangeChallengeInProcess,
    blinkCmp
}) => {
    return (
        <>
            {
                achievementsList.map((achievement, index) => (
                    <ProfileAchievementItem
                        key={achievement.id}
                        isMyProfile={isMyProfile}
                        achievement={achievement}
                        challengeInProcess={challengeInProcess}
                        handleChangeChallengeInProcess={handleChangeChallengeInProcess}
                        index={index}
                        blinkCmp={blinkCmp}
                    />
                ))
            }
        </>
    );
}

export default ProfileAchievementsList;