import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateAchievementsAtSlide, updateAchievementsCompleted, updateAchievementsStatus, updateShouldSeeTutorial } from '../../store/user-achievements/user-achievements-actions';
import './achievements-tutorial.scss';
import { arrowDownIcon, arrowUpIcon, closeIcon, commentIcon, copyIcon, emptyStarIcon, followIcon, fullStarIcon, leftFillArrow, unfollowIcon } from '../../shared/icons/icons';
import { getAchievementTutorialPost } from '../../shared/actions/profile-actions';
import HomepageFeedContentToggleBtn from '../../pages/homepage/homepage-cmps/homepage-feed/feed-cmps/homepage-feed-toggle-content';
import HomepageFeedPostContent from '../../pages/homepage/homepage-cmps/homepage-feed/homepage-feed-post-content';
import FeedPie from '../../pages/homepage/homepage-cmps/homepage-feed/feed-charts/feed-pie';
import LoadingSpinner from '../../shared/loading-spinner/loading-spinner';
import { convertDateToReadableStr } from '../../shared/global-color-functions';
import ProfilePortfolioBtns from '../../pages/user-profile/profile-portfolio/profile-portfolio-btns';
import FeedTable from '../../pages/homepage/homepage-cmps/homepage-feed/feed-cmps/feed-table';
import CopyPostProfitCard from '../../pages/homepage/homepage-cmps/homepage-feed/feed-cmps/copy-post-profit-card';
import FeedConfirmChanges from '../../pages/homepage/homepage-cmps/homepage-feed/feed-cmps/feed-confirm-changes';
import { setFeedback } from '../../shared/actions/homepage-actions';
import { useMediaQuery } from 'react-responsive';

const AchievementsTutorial = ({ loggedInUser }) => {

    const dispatch = useDispatch();
    const userAchievements = useSelector((state) => state.userAchievements);
    const loggedInUserData = useSelector((state) => state.user);
    const globalLastPrices = useSelector((state) => state.lastPrices);

    const isMobile = useMediaQuery({ maxWidth: 650 });

    const [blinkCmp, setBlinkCmp] = useState(null);

    const [currSlide, setCurrSlide] = useState(null);
    const [tutorialLength, setTutorialLength] = useState(0);

    const [postExample, setPostExample] = useState(null);
    const [showFirstPart, setShowFirstPart] = useState(true);

    const [postExampleDaysAgo, setPostExampleDaysAgo] = useState('0');
    const [postExampleReturnRes, setPostExampleReturnRes] = useState('0');

    const dispatchNextSlide = () => {
        dispatch(updateAchievementsAtSlide(userAchievements.atSlide + 1));
    };

    const dispatchPrevSlide = () => {
        if (userAchievements.atSlide === 0) return;
        dispatch(updateAchievementsAtSlide(userAchievements.atSlide - 1));
    };

    const isBackButtonDisabled = userAchievements.atSlide === 0;

    const onCloseTutorial = () => {
        dispatch(updateShouldSeeTutorial(false));
    };

    const onEndTutorial = (slideId) => {
        console.log('slideId:', slideId);
        onCloseTutorial();
        dispatch(updateAchievementsAtSlide(0));
        dispatch(updateAchievementsStatus('start'));
        dispatch(updateAchievementsCompleted(userAchievements.completed + 1));
        dispatch(updateAchievementsAtSlide(0));
        if (slideId === '1-14') {
            submitFeedback();
        };
    };

    let feedbackStars = 0;

    async function submitFeedback() {
        try {
            const feedbackData = {
                loggedInUser: loggedInUser,
                name: '',
                email: '',
                feedback: `Portfolio Replication: ${feedbackStars} stars.`
            };
            await setFeedback(feedbackData);
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
    };

    const TutorialTitle = ({ title, isLastSlide }) => {
        return (
            <div className="flex space-between align-center mb10">
                <div className='mr20'><h3>{title}</h3></div>
                {
                    !isLastSlide &&
                    <div
                        className='achievements-tutorial-close-btn flex center-center'
                        onClick={onCloseTutorial}>
                        {closeIcon}
                    </div>
                }
            </div>
        )
    };

    const SlidesCounter = () => {
        const counters = [];
        for (let i = 0; i <= tutorialLength - 1; i++) {
            const className = i === userAchievements.atSlide ? 'achievements-tutorial-counter-item achievements-tutorial-counter-item-current' : 'achievements-tutorial-counter-item';
            counters.push(<div key={i} className={className}></div>);
        }
        return (
            <div className='flex center-center'>
                {counters}
            </div>
        );
    };

    const EndTutorialBtn = ({ slideId }) => {
        return (
            <div className="flex justify-center">
                <div className="achievements-tutorial-btn btn btn-border" onClick={() => onEndTutorial(slideId)}>End tutorial</div>
            </div>
        )
    };

    const NextSlideBtn = () => {
        return (
            <div className="flex space-between">
                <div
                    className={`achievements-tutorial-btn btn btn-border ${isBackButtonDisabled ? 'btn-disabled btn-no-opacity' : ''}`}
                    disabled={userAchievements.atSlide === 0}
                    onClick={dispatchPrevSlide}>
                    Back
                </div>
                <div className="achievements-tutorial-btn btn btn-border" onClick={dispatchNextSlide}>Next</div>
            </div>
        )
    };

    const ClassicSlideTemplate = ({ title, content, isLastSlide = false, slideId }) => {
        return (
            <div className="achievements-tutorial-content achievements-tutorial-content-classic">
                <TutorialTitle title={title} isLastSlide={isLastSlide} />
                <p className='mb20'>{content}</p>
                <SlidesCounter />
                {
                    isLastSlide ? <EndTutorialBtn slideId={slideId} /> : <NextSlideBtn />
                }
            </div>
        );
    };

    const PortfolioProfit = () => {
        return (
            <></>
        )
    };

    const PostSocialBtns = ({ post }) => {
        return (
            <>
                <div className='homepage-feed-user-btn-container flex space-evenly mb10'>
                    <div
                        className='homepage-feed-user-btn-icon flex align-center'
                        title={'Like'}
                    // onClick={() => handlePostAction(post.postid, 'like')}
                    >
                        {unfollowIcon}
                        <span className='homepage-feed-post-counter-span'>{post.likes.length}</span>
                    </div>

                    <div className="homepage-feed-user-btn-container-item flex align-end">
                        <div
                            onClick={() => { }}
                            className='homepage-feed-user-btn-icon flex align-center'
                            title={'Comment'}
                        >
                            {commentIcon}
                            <span className='homepage-feed-post-counter-span'>{post.comments.length}</span>
                        </div>
                    </div>


                    <div className={`homepage-feed-user-btn-container-item flex align-end ${blinkCmp === 'postexample-copy-btn' ? 'blink-border' : ''}`}>
                        <div
                            // onClick={() => handleCopyPostData()}
                            className={`homepage-feed-user-btn-icon flex align-center`}
                            title={'Copy'}
                        >
                            {copyIcon}
                            <span className='homepage-feed-post-counter-span'>{post.copies}</span>
                        </div>
                    </div>

                </div>
            </>
        );
    };

    const ExamplePostSlideTemplate = ({ title, content, isLastSlide = false }) => {

        const getReturnPercent = () => {
            return postExampleReturnRes;
        };

        const getDaysAgo = () => {
            return postExampleDaysAgo;
        };

        const PortfolioReturnProfit = () => {

            const resStr = getReturnPercent();
            const daysStr = getDaysAgo();

            return (
                <div className={`post-return-str-container profile-post-return-str-container flex align-center ${blinkCmp === 'postexample-postreturn' ? 'blink-border' : ''}`}>
                    <div
                        className='post-return-icon profile-post-return-icon'
                        style={{ color: +resStr > 0 ? '#5c9ead' : '#e39774' }}
                    >
                        {+resStr > 0 ? arrowUpIcon : arrowDownIcon}
                        {/* {portfolioProfitIcon} */}
                    </div>
                    <div className="flex-column">
                        <div className='post-return-str profile-post-return-str'>
                            <span className='post-return-str-span profile-returndays-span'>{resStr}%</span> return in <span className='post-return-str-span profile-returndays-span'>{daysStr}</span> days
                        </div>
                        <div className='post-return-str post-return-str-lower profile-post-return-str'>{'portfolioA'}</div>
                    </div>
                </div>
            )
        };

        return (
            <div className="achievements-tutorial-content">
                <TutorialTitle title={title} isLastSlide={isLastSlide} />
                <div style={{
                    // width: isMobile ? '80%' : '100%'
                }}>
                    <p style={{
                        fontSize: isMobile ? '1.2rem' : '1.5rem'
                    }}
                        className='mb20'>{content}</p>
                </div>
                {
                    postExample ?
                        <>
                            <div className={`flex ${isMobile ? 'column' : 'space-between'}`}>

                                <div className='flex align-center'>
                                    <div className="home-feed-post-user-img mb10">
                                        <img
                                            src={postExample.pngUrl}
                                            alt="User profile img"
                                            className='feed-post-user-profile-img'
                                            style={{ borderRadius: '50%' }}
                                        />
                                    </div>
                                    <div className='flex column'>
                                        <div
                                            className='homepage-feed-user-name'
                                        // onClick={() => handleUsernameClick(postExample.username)}
                                        >
                                            {postExample.username}
                                        </div>
                                        <div className="homepage-feed-user-time">{convertDateToReadableStr(postExample.time)}</div>
                                    </div>
                                </div>
                                <PortfolioReturnProfit />
                            </div>
                            <div className="homepage-feed-user-text">{postExample.text}</div>
                            <div
                                className={`mb10  ${blinkCmp === 'postexample-feedpie' ? 'blink-border' : ''}`}
                                id='postexample-feedpie'
                                style={{
                                    // width: isMobile ? '80%' : '100%'
                                }}
                            >
                                <FeedPie
                                    item={postExample.data.postData}
                                    PortfolioProfit={PortfolioProfit}
                                />
                            </div>
                            <div
                                style={{
                                    // width: isMobile ? '80%' : '100%'
                                }}>
                                <PostSocialBtns post={postExample} />
                            </div>
                            {/* <PostSocialBtn post={postExample} /> */}
                        </> : <>Loading..</>
                }
                <SlidesCounter />
                {
                    isLastSlide ? <EndTutorialBtn /> : <NextSlideBtn />
                }
            </div>
        );
    };

    const ExamplePostCopySlideTemplate = ({ title, content, isLastSlide = false, isCopyPortfolioConfirmed = false, blinkCmp }) => {

        const portfolioKeys = ['portfolioA', 'portfolioB', 'portfolioC', 'portfolioD', 'portfolioE'];
        const portfolioNames = portfolioKeys.map(portfolioKey => ({
            portfolioKey,
            portfolioName: portfolioKey
        }));

        return (
            <div className='' style={{ minWidth: '50vw', maxHeight: '80vh' }}>
                <TutorialTitle title={title} isLastSlide={isLastSlide} />
                {content}
                {
                    showFirstPart ? (
                        <div>
                            <h2 className='portfolio-chart-title'>Copy post data to My Portfolio</h2>
                            <p className='mb10'>Select Portfolio:</p>
                            <div
                                style={{ paddingTop: '20px' }}
                                className={`postexample-copymodal-portfolionames ${blinkCmp === 'postexample-copymodal-portfolionames' ? 'blink-border' : ''}`}>
                                <ProfilePortfolioBtns
                                    portfolioNames={portfolioNames}
                                    selectedPortfolio={'portfolioA'}
                                    handleChangeSelectedPortfolio={() => { }}
                                />
                            </div>
                            <FeedTable
                                orders={[]}
                            />
                            <SlidesCounter />
                            {
                                isLastSlide ? <EndTutorialBtn /> : <NextSlideBtn />
                            }
                        </div>
                    ) : (
                        <div>
                            <h4 className='flex mb20'>
                                Incoming change
                            </h4>
                            <FeedTable orders={postExample.data.ordersList} blinkCmp={blinkCmp} />
                            <FeedConfirmChanges
                                isConfirmed={isCopyPortfolioConfirmed}
                                handleConfirmation={() => { }}
                                selectedPortfolio={'portfolioA'}
                                handleCloseCopyPortfolioModal={() => { }}
                                handleSaveCopiedPortfolio={() => { }}
                                orders={postExample.data.ordersList}
                                blinkCmp={blinkCmp}
                            />
                            <SlidesCounter />
                            {
                                isLastSlide ? <EndTutorialBtn /> : <NextSlideBtn />
                            }
                        </div>
                    )}
            </div>
        );
    };

    const StarRating = () => {

        const [hoveredStar, setHoveredStar] = useState(0);

        return (
            <div>
                <p className='mb10 flex center-center'>Was this tutorial helpful?</p>
                <div
                    className='flex center-center'
                >
                    {[0, 1, 2, 3, 4].map((index) => (
                        <div
                            key={index}
                            className='achievements-tutorial-staricon flex center-center'
                            onMouseOver={() => {
                                setHoveredStar(index);
                                feedbackStars = index + 1;
                            }}

                            style={{ cursor: 'pointer' }}
                        >
                            {index <= hoveredStar ? fullStarIcon : emptyStarIcon}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const AchievementGuidelines = [
        [
            {
                id: '0-0',
                content: <ClassicSlideTemplate
                    title={'Welcome to PortFollow!'}
                    content={'Let’s explore some important features!'}
                />,
                // target: ''
            },
            {
                id: '0-1',
                content: <ClassicSlideTemplate
                    title={'Explore and Share!'}
                    content={'Gain strategies and insights for better investment decisions.'}
                />,
                target: isMobile ? 'mobile-home-navigation' : 'home-navigation'
            },
            {
                id: '0-2',
                content: <ClassicSlideTemplate
                    title={'Explore and Share!'}
                    content={'Practice investing risk-free and gain feedback from the community. '}
                />,
                target: 'posts-page-btn'
            },
            {
                id: '0-3',
                content: <ClassicSlideTemplate
                    title={'Analyze Your Portfolio!'}
                    content={'Use advanced analysis tools to optimize your investments.'}
                />,
                // target: 'portfolio-navigation'
                target: isMobile ? 'mobile-portfolio-navigation' : 'portfolio-navigation'
            },
            {
                id: '0-4',
                content: <ClassicSlideTemplate
                    title={'Analyze Your Portfolio!'}
                    content={'Boost returns with macroeconomic trends.'}
                />,
                // target: 'macro-navigation'
                target: isMobile ? 'mobile-macro-navigation' : 'macro-navigation'
            },
            {
                id: '0-5',
                content: <ClassicSlideTemplate
                    title={'Join Funds and Compete!'}
                    content={'Test strategies, improve skills, and earn recognition through competition.'}
                />,
                target: ''
            },
            {
                id: '0-6',
                content: <ClassicSlideTemplate
                    title={'Join Funds and Compete!'}
                    content={'Learn from others and build a supportive investment community.'}
                />,
                target: ''
                // (points at the fame and follow of the user)
            },
            {
                id: '0-7',
                content: <ClassicSlideTemplate
                    title={'Hands-On Exercises!'}
                    content={'Improve investment skills and enhance your performance.'}
                />,
                target: ''
            },
            {
                id: '0-8',
                content: <ClassicSlideTemplate
                    title={''}
                    content={'Well done! You have completed the first tutorial!'}
                    isLastSlide={true}
                />,
                target: ''
            }
        ],
        [
            {
                id: '1-0',
                content: <ClassicSlideTemplate
                    title={'Portfolio Replication'}
                    content={'Start with a simple hands-on challenge!'}
                />,
                // target: ''
            },
            {
                id: '1-1',
                content: <ClassicSlideTemplate
                    title={''}
                    content={'Copying a portfolio allows you to manage and customize it as your own.'}
                />,
                // target: ''
            },

            {
                id: '1-2',
                content: <ClassicSlideTemplate
                    title={''}
                    content={'Discover investment opportunities and strategies!'}
                />,
                // target: ''
            },
            {
                id: '1-3',
                content: <ClassicSlideTemplate
                    title={''}
                    content={'Explore a social feed where users share their investment ideas.'}
                />,
                // target: ''
            },

            {
                id: '1-4',
                content: <ExamplePostSlideTemplate
                    title={''}
                    content={'Here is an example of a simulated portfolio shared by Alex:'}
                // show the post in the tutorial
                />,
                // target: ''
            },

            {
                id: '1-5',
                content: <ExamplePostSlideTemplate
                    title={''}
                    content={'View Alex\'s return since sharing this post.'}
                    blinkCmp={blinkCmp}
                />,
                // target: 'post-return'
            },

            {
                id: '1-6',
                content: <ExamplePostSlideTemplate
                    title={''}
                    content={'Return ratio shows profit or loss on an investment.'}
                    blinkCmp={blinkCmp}
                />,
                // target: 'post-return'
            },

            {
                id: '1-7',
                content: <ExamplePostSlideTemplate
                    title={''}
                    content={'Here are the investments Alex holds in this portfolio.'}
                />,
                // target: 'post-graph'
            },

            {
                id: '1-8',
                content: <ExamplePostSlideTemplate
                    title={''}
                    content={'Time to copy your first portfolio!'}
                />,
                // target: 'post-copy-btn'
            },

            {
                id: '1-9',
                content: <ExamplePostCopySlideTemplate
                    title={''}
                    content={
                        <div style={{ fontSize: '1.5rem' }}>
                            <p className=''>You can manage 5 portfolios simultaneously.</p>
                            <p className='mb20'>Select one to copy this portfolio to.</p>
                        </div>
                    }
                    blinkCmp={blinkCmp}
                />,
                // target: 'post-copy-portfolios'
            },

            {
                id: '1-10',
                content: <ExamplePostCopySlideTemplate
                    title={''}
                    content={
                        <div style={{ fontSize: '1.5rem' }}>
                            <p className='mb20'>View the incoming portfolio structure.</p>
                        </div>
                    }
                    blinkCmp={blinkCmp}
                />,
                // target: 'post-copy-portfolio-next-btn'
            },

            {
                id: '1-11',
                content: <ClassicSlideTemplate
                    title={''}
                    content={
                        <>
                            <p className='mb10'>Each investment order contains 4 key elements:</p>
                            <ul>
                                <li><strong>Symbol:</strong> Uniquely identifies a tradable asset.</li>
                                <li><strong>Date:</strong> The transaction date.</li>
                                <li><strong>Amount:</strong> Number of shares.</li>
                                <li><strong>Price:</strong> The market value of each symbol.</li>
                            </ul>
                        </>
                    }
                    blinkCmp={blinkCmp}
                // (points at the symbol title)
                // (points at the date title)
                // (points at the amount title)
                // (points at the price title)
                />,
                // target: ''
            },

            {
                id: '1-12',
                content: <ExamplePostCopySlideTemplate
                    title={''}
                    content={
                        <div style={{ fontSize: '1.5rem' }}>
                            <p className='mb20'>Confirm the incoming changes.</p>
                        </div>
                    }
                    blinkCmp={blinkCmp}
                />,
                // target: '###'
            },

            {
                id: '1-13',
                content: <ExamplePostCopySlideTemplate
                    title={''}
                    content={
                        <div style={{ fontSize: '1.5rem' }}>
                            <p className='mb20'>Save when you’re ready!</p>
                        </div>
                    }
                    blinkCmp={blinkCmp}
                    isCopyPortfolioConfirmed={true}
                />,
                // target: '###'
            },


            // stars rating feedback
            // and achivement completed modal.
            {
                id: '1-14',
                content: <ClassicSlideTemplate
                    title={''}
                    content={
                        <StarRating />
                    }
                    isLastSlide={true}
                    slideId={'1-14'}
                />,
                // target: ''
            },
        ]
    ];

    useEffect(() => {

        // console.log('<< post example >>', postExample);
        // console.log('<< last prices >>', globalLastPrices);
        // console.log('currSlide', currSlide);

        if (currSlide) {
            if (currSlide.id === '1-3' || currSlide.id === '1-4') {
                setBlinkCmp('postexample-postreturn')
            } else if (currSlide.id === '1-5') {
                setBlinkCmp('postexample-feedpie');
            } else if (currSlide.id === '1-6') {
                setBlinkCmp('postexample-copy-btn');
            } else if (currSlide.id === '1-7') {
                setBlinkCmp('postexample-copymodal-portfolionames');
            } else if (currSlide.id === '1-8') {
                setShowFirstPart(false);
                setBlinkCmp('postexample-copymodal-incomingchanges');
            } else if (currSlide.id === '1-10') {
                setBlinkCmp('postexample-copymodal-confirmbtn');
            } else if (currSlide.id === '1-11') {
                setBlinkCmp('postexample-copymodal-savebtn');
            } else {
                setBlinkCmp('');
            }
        };

        const fetchAchievementTutorialPost = async () => {
            try {
                const postExampleRes = await getAchievementTutorialPost();

                const postTimeStr = postExampleRes.time;
                const postTime = new Date(postTimeStr);
                const today = new Date();
                const difference = today.getTime() - postTime.getTime();
                const daysPassed = Math.floor(difference / (1000 * 60 * 60 * 24));

                const ordersList = postExampleRes.data.ordersList;

                const postPriceSum = ordersList
                    .map(item => item.amount * item.postPrice)
                    .reduce((acc, curr) => acc + curr, 0);

                const lastPriceSum = ordersList
                    .map(item => item.amount * (globalLastPrices[item.symbol]?.lastPrice || 0))
                    .reduce((acc, curr) => acc + curr, 0);

                // sum1 (amount * last price)
                // sum2 (amount * post price)
                // ((sum1 / sum2) - 1) * 100

                const postExampleReturnResSum = (((lastPriceSum / postPriceSum) - 1) * 100).toFixed(2);

                setPostExampleDaysAgo(daysPassed);
                setPostExampleReturnRes(postExampleReturnResSum);
                setPostExample(postExampleRes);
            } catch (error) {
                console.error('Error fetching Achievement Tutorial Post:', error);
            }
        };

        setTutorialLength(AchievementGuidelines[userAchievements.completed].length);
        setCurrSlide(AchievementGuidelines[userAchievements.completed][userAchievements.atSlide]);

        if (userAchievements.level === 0 && (userAchievements.completed < 2) && !postExample) {
            fetchAchievementTutorialPost();
        };

    }, [userAchievements]);

    useEffect(() => {
        // Check if currSlide exists
        if (currSlide) {
            // Check if currSlide has a target
            if (currSlide.target && currSlide.target !== null) {
                const relativeElement = document.getElementById(currSlide.target);
                const rect = relativeElement.getBoundingClientRect();
                // const newPositionTop = window.scrollY + rect.top + rect.height - 25;
                // const newPositionLeft = window.scrollX + rect.left + rect.width + 5;
                const newElement = document.querySelector('.achievements-tutorial-card');

                const newPositionTop = isMobile ? rect.top + rect.height + 5 : window.scrollY + rect.top + rect.height - 25;
                const newPositionLeft = isMobile ? (window.innerWidth / 2) - (newElement.clientWidth / 2) : window.scrollX + rect.left + rect.width + 5;

                newElement.style.position = 'fixed';
                newElement.style.top = `${newPositionTop}px`;
                newElement.style.left = `${newPositionLeft}px`;
            } else {
                // If currSlide does not have a target, place newElement in the middle of the screen
                const newElement = document.querySelector('.achievements-tutorial-card');
                const newElementWidth = newElement.offsetWidth; // Get the width of the new element
                const newElementHeight = newElement.offsetHeight; // Get the height of the new element

                const newPositionTop = (window.innerHeight - newElementHeight) / 2; // Center vertically, considering the height of the new element
                const newPositionLeft = (window.innerWidth - newElementWidth) / 2; // Center horizontally, considering the width of the new element

                newElement.style.position = 'fixed';
                newElement.style.top = `${newPositionTop}px`;
                // newElement.style.left = `${newPositionLeft}px`;
                newElement.style.left = `calc(50% - ${newElement.offsetWidth / 2}px)`;
            }


        }
    }, [currSlide, userAchievements]);

    return (
        <div className='achievements-tutorial-card card-container' style={{ padding: '10px', border: '1px solid #484848' }}>
            {
                currSlide && (
                    <div>{currSlide.content}</div>
                )
            }
        </div>
    );

}

export default AchievementsTutorial;