import React, { useState, useEffect } from 'react';
import FundamentalGraphs from './fundamental-graphs/fundamental-graphs';
import './single-company-fundamental.scss';

const SingleCompanyFundamental = ({ fundamentalData, symbol, noResultsMsg, activeFilter }) => {

    const [activePage, setActivePage] = useState(null);
    // const [activeFilter, setActiveFilter] = useState('overTime');
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const [fundamentalOptions, setFundamentalOptions] = useState(null);

    useEffect(() => {
        if (!fundamentalData || Object.keys(fundamentalData).length === 0) return;
        const options = Object.keys(fundamentalData).map(key => ({
            value: key,
            label: key.replace(/([a-z0-9])([A-Z])/g, '$1 $2').toUpperCase()
        }));
        options.sort((a, b) => a.label.localeCompare(b.label));
        if (options.length > 0) {
            setFundamentalOptions(options);
        };
        if (fundamentalOptions) setDataToDisplay(fundamentalData[activePage][activeFilter]);
        else if (!fundamentalOptions) {
            setActivePage(options[0].value);
            setDataToDisplay(fundamentalData[options[0].value][activeFilter]);
        }
    }, [fundamentalData, activePage, activeFilter]);

    const handleTogglePage = (option) => {
        setActivePage((prevOption) => {
            return option;
        });
    };

    // const handleToggleFilter = (option) => {
    //     setActiveFilter((prevOption) => {
    //         return option;
    //     });
    // };

    return (
        fundamentalData && fundamentalOptions && Object.keys(fundamentalData).length > 0 ? (
            <div className="fundamental-main-container main-content-container">
                <div className="fundamental-page-container flex center-center wrap">
                    {
                        fundamentalOptions.map((option) => {
                            return <div key={option.value} className={`portfolio-toggle btn selected-option ${activePage === option.value ? 'selected' : ''}`} onClick={() => handleTogglePage(option.value)}>{option.label}</div>
                        })
                    }
                </div>
                {/* <div className="fundamental-filter-container flex center-center">
                    <div className={`portfolio-toggle btn selected-option ${activeFilter === 'overTime' ? 'selected' : ''}`} onClick={() => handleToggleFilter('overTime')}>OVER TIME</div>
                    <div className={`portfolio-toggle btn selected-option ${activeFilter === 'sectorial' ? 'selected' : ''}`} onClick={() => handleToggleFilter('sectorial')}>SECTORIAL</div>
                </div> */}
                {
                    dataToDisplay && <FundamentalGraphs data={dataToDisplay} symbol={symbol} />
                }
            </div>
        ) : (
            noResultsMsg
        )
    );
}

export default SingleCompanyFundamental;