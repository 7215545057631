import React, { useState } from "react";
import Select from 'react-select';
import { customStyles, DropdownIndicator } from "../../../../shared/dropdown-styles";
import { dropdownIcon } from "../../../../shared/icons/icons";

const MacroCommoditiesCommodityFilter = ({ options, value, onChange }) => {

    const [showCommodityOptions, setShowCommodityOptions] = useState(false);

    const onHandleCommodityMouseEnter = () => {
        setShowCommodityOptions(true);
    };

    const onHandleCommodityMouseLeave = () => {
        setShowCommodityOptions(false);
    };

    return (
        <div className="dropdown-select-filter-item"
            onMouseEnter={onHandleCommodityMouseEnter}
            onMouseLeave={onHandleCommodityMouseLeave}
        >
            <Select
                styles={customStyles}
                components={{ DropdownIndicator }}
                options={options}
                value={value}
                onChange={onChange}
                menuIsOpen={showCommodityOptions}
                hideSelectedOptions={false}
                isMulti
            />
            <div
                className={`dropdown-label flex align-center ${value.length !== options.length ? 'incomplete-dropdown' : ''}`}>Commodities
                <span className='dropdown-indicator flex center-center'>{dropdownIcon}</span>
            </div>
        </div>
    );
}

export default MacroCommoditiesCommodityFilter;