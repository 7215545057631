import { createSlice } from "@reduxjs/toolkit";

const loadingSentences = [
    {
        id: "1",
        title: "Efficient Market Hypothesis (EMH)",
        sentence: "States that asset prices fully reflect all available information, making it impossible to consistently achieve higher returns than the market average through stock picking or market timing."
    },
    {
        id: "2",
        title: "Factor Models",
        sentence: "Use multiple factors (e.g., size, value, momentum) to explain asset returns and improve portfolio construction beyond the traditional CAPM."
    },
    {
        id: "3",
        title: "Efficient Frontier",
        sentence: "Optimal portfolios offer the highest return for a given risk."
    },
    {
        id: "4",
        title: "Capital Market Line (CML)",
        sentence: "Combines risk-free assets with the market portfolio for optimal returns."
    },
    {
        id: "5",
        title: "Risk vs. Return",
        sentence: "Balancing risk and return is key to building a good portfolio."
    },
    {
        id: "6",
        title: "Diversification",
        sentence: "Spread investments across different assets to reduce risk."
    },
    {
        id: "7",
        title: "Asset Allocation",
        sentence: "Dividing investments among different asset categories."
    },
    {
        id: "8",
        title: "Rebalancing",
        sentence: "Periodically adjusting your portfolio to maintain desired asset allocation."
    },
    {
        id: "9",
        title: "Diversified Portfolio",
        sentence: "A mix of different investments reduces overall risk."
    },
    {
        id: "10",
        title: "Growth vs. Value",
        sentence: "Growth stocks offer high potential returns, while value stocks are more stable."
    },
    {
        id: "11",
        title: "Modern Portfolio Theory",
        sentence: "Focuses on maximizing returns for a given level of risk."
    },
    {
        id: "12",
        title: "Risk Assessment",
        sentence: "Evaluate the risk of investments before including them in your portfolio."
    },
    {
        id: "13",
        title: "Risk-Return Tradeoff",
        sentence: "Higher potential returns usually come with higher risks."
    },
    {
        id: "14",
        title: "Investment Horizon",
        sentence: "The length of time you plan to invest affects your portfolio choices."
    },
    {
        id: "15",
        title: "Dynamic Asset Allocation",
        sentence: "Adjusts asset allocations in response to changing market conditions and economic forecasts to optimize returns and manage risk."
    },
    {
        id: "16",
        title: "Mean-Variance Optimization",
        sentence: "Utilizing quadratic programming to find the optimal balance between risk and return in a portfolio."
    },
    {
        id: "17",
        title: "Risk Budgeting",
        sentence: "Allocating risk to different components of a portfolio based on their contribution to total risk, rather than focusing on capital allocation."
    },
    {
        id: "18",
        title: "Stochastic Optimization",
        sentence: "Using stochastic models to account for uncertainty in portfolio optimization, providing more robust investment strategies."
    },
    {
        id: "19",
        title: "Risk Parity Approach",
        sentence: "Allocating risk equally among different components of the portfolio to achieve a more balanced risk exposure."
    },
    {
        id: "20",
        title: "Start Early",
        sentence: "The sooner you start investing, the more time your money has to grow."
    },
    {
        id: "21",
        title: "Consistency is Key",
        sentence: "Regularly investing a small amount can build wealth over time."
    },
    {
        id: "22",
        title: "Compound Interest",
        sentence: "The interest you earn on your investment earns interest, leading to exponential growth over time."
    },
    {
        id: "23",
        title: "Long-Term Focus",
        sentence: "Investing is most effective over long periods, allowing time for growth and recovery from downturns."
    },
    {
        id: "24",
        title: "Time in the Market",
        sentence: "Staying invested over time is usually more effective than trying to time the market."
    },
    {
        id: "25",
        title: "Simple Strategies",
        sentence: "Simpler investment strategies often outperform complex ones over the long term."
    },
    {
        id: "26",
        title: "Risk Reduction",
        sentence: "Diversification lowers unsystematic risk in a portfolio."
    },
    {
        id: "27",
        title: "Law of Large Numbers",
        sentence: "More assets reduce idiosyncratic risk, converging to average covariance."
    },
    {
        id: "28",
        title: "Asset Mix",
        sentence: "Combining stocks, bonds, and other assets can make a portfolio more stable."
    },
    {
        id: "29",
        title: "Correlation",
        sentence: "Choose assets that don\'t move in the same direction to lower risk."
    },
    {
        id: "30",
        title: "Sector Diversification",
        sentence: "Invest in different sectors (e.g., technology, healthcare) to reduce risk."
    },
    {
        id: "31",
        title: "Geographic Diversification",
        sentence: "Spread investments across various countries to mitigate regional risks."
    },
    {
        id: "32",
        title: "Mutual Funds and ETFs",
        sentence: "Easy ways to diversify with a single investment."
    },
    {
        id: "33",
        title: "Diversification Benefits",
        sentence: "Reduces the impact of poor performance from any single investment."
    },
    {
        id: "34",
        title: "Bond Types",
        sentence: "Include different types of bonds (e.g., government, corporate) for stability."
    },
    {
        id: "35",
        title: "Alternative Investments",
        sentence: "Consider real estate, commodities, or hedge funds for added diversification."
    },
    {
        id: "36",
        title: "Small-Cap Stocks",
        sentence: "Smaller companies can offer high growth potential but come with higher risk."
    },
    {
        id: "37",
        title: "International Diversification",
        sentence: "Reduces portfolio risk by investing in markets with different economic cycles and political environments."
    },
    {
        id: "38",
        title: "Hedge Funds",
        sentence: "Utilize various strategies, including long-short equity, global macro, and arbitrage, to achieve diversification and absolute returns."
    },
    {
        id: "39",
        title: "Smart Beta",
        sentence: "Combines passive indexing and active management by targeting specific factors like value, size, and volatility to enhance returns."
    },
    {
        id: "40",
        title: "Alternative Assets",
        sentence: "Incorporating assets like private equity, venture capital, and real assets (e.g., infrastructure, natural resources) to improve diversification and return potential."
    },
    {
        id: "41",
        title: "Correlation Hedging",
        sentence: "Using strategies to hedge against correlations changing during market stress, preserving diversification benefits."
    },
    {
        id: "42",
        title: "Hybrid Securities",
        sentence: "Investing in securities that have both equity and debt characteristics (e.g., convertible bonds) to enhance diversification and returns."
    },
    {
        id: "43",
        title: "Regime-Based Diversification",
        sentence: "Adjusting the asset mix based on different market regimes (e.g., inflationary, deflationary) to optimize performance."
    },
    {
        id: "44",
        title: "Volatility Scaling",
        sentence: "Adjusting portfolio weights based on the volatility of assets to maintain a consistent risk level."
    },
    {
        id: "45",
        title: "Higher Risk, Higher Reward",
        sentence: "Riskier investments can offer higher returns but also higher losses."
    },
    {
        id: "46",
        title: "Know Your Risk Tolerance",
        sentence: "Understand how much risk you\'re comfortable with before investing."
    },
    {
        id: "47",
        title: "Sector Diversification",
        sentence: "Invest in different industries (e.g., technology, healthcare) to spread risk."
    },
    {
        id: "48",
        title: "Global Diversification",
        sentence: "Consider international investments to benefit from growth in different regions."
    },
    {
        id: "49",
        title: "Rebalance Regularly",
        sentence: "Periodically adjust your portfolio to maintain your desired asset allocation."
    },
    {
        id: "50",
        title: "Low-Correlation Assets",
        sentence: "Include assets that don\'t move in tandem to reduce overall portfolio risk."
    },
    {
        id: "51",
        title: "Sharpe Ratio",
        sentence: "Sharpe ratio is excess return per unit of risk"
    },
    {
        id: "52",
        title: "Interpretation of Sharpe Ratio",
        sentence: "Higher Sharpe ratios indicate better risk-adjusted returns."
    },
    {
        id: "53",
        title: "Risk-Adjusted Return",
        sentence: "The Sharpe ratio helps you understand how much return you\'re getting for the risk taken."
    },
    {
        id: "54",
        title: "Benchmarking Sharpe Ratio",
        sentence: "Use the Sharpe ratio to compare different investments\' performances."
    },
    {
        id: "55",
        title: "High Sharpe Ratio",
        sentence: "Indicates better risk-adjusted performance."
    },
    {
        id: "56",
        title: "Comparison Tool",
        sentence: "Use Sharpe ratio to compare similar investment options."
    },
    {
        id: "57",
        title: "Risk Premium",
        sentence: "Measures the extra return expected for taking on more risk."
    },
    {
        id: "58",
        title: "Investment Performance",
        sentence: "Aids in evaluating if a portfolio\'s returns are worth its risk."
    },
    {
        id: "59",
        title: "Historical Performance",
        sentence: "Use past performance data to calculate the Sharpe ratio."
    },
    {
        id: "60",
        title: "Volatility Measure",
        sentence: "Helps in understanding the consistency of returns."
    },
    {
        id: "61",
        title: "Annualized Return",
        sentence: "The Sharpe ratio often uses annualized returns for comparison."
    },
    {
        id: "62",
        title: "Negative Sharpe Ratio",
        sentence: "Indicates that a risk-free investment would perform better than the portfolio."
    },
    {
        id: "63",
        title: "Information Ratio",
        sentence: "A variation of the Sharpe ratio that compares active return over a benchmark to the volatility of active returns, providing insight into a manager\'s skill."
    },
    {
        id: "64",
        title: "Sortino Ratio",
        sentence: "Focuses on downside risk by using only negative asset returns in the denominator, providing a more accurate measure of risk-adjusted performance for investors concerned with downside risk."
    },
    {
        id: "65",
        title: "Conditional Sharpe Ratio",
        sentence: "Adjusts the Sharpe ratio by considering the non-normal distribution of returns, providing a more accurate risk-adjusted performance measure in the presence of skewness and kurtosis."
    },
    {
        id: "66",
        title: "Excess Sharpe Ratio",
        sentence: "Compares the Sharpe ratio of a portfolio to that of a benchmark, highlighting the additional risk-adjusted return achieved."
    },
    {
        id: "67",
        title: "Adjusted Sharpe Ratio",
        sentence: "Modifying the traditional Sharpe ratio to account for skewness and kurtosis in the return distribution, providing a more accurate risk-adjusted performance measure."
    },
    {
        id: "68",
        title: "Omega Ratio",
        sentence: "Measures the probability-weighted ratio of gains to losses, offering a more comprehensive risk-return profile than the Sharpe ratio."
    },
    {
        id: "69",
        title: "Expected Shortfall",
        sentence: "A risk measure that focuses on the average loss in the worst-case scenarios, providing a more comprehensive view of downside risk compared to the Sharpe ratio."
    },
    {
        id: "70",
        title: "Modified Sharpe Ratio",
        sentence: "Incorporates higher moments of return distributions (e.g., skewness and kurtosis) to provide a more accurate assessment of risk-adjusted performance."
    },
    {
        id: "71",
        title: "Don\'t Put All Eggs in One Basket",
        sentence: "Spread your money across different investments to reduce risk."
    },
    {
        id: "72",
        title: "Mix of Stocks and Bonds",
        sentence: "Combining stocks and bonds can provide both growth and stability."
    },
    {
        id: "73",
        title: "Understand Volatility",
        sentence: "Volatility is a normal part of investing and can present opportunities for disciplined investors."
    },
    {
        id: "74",
        title: "Risk Management",
        sentence: "Don\'t invest money you might need in the near term; investing should be for long-term goals."
    },
    {
        id: "75",
        title: "Compounding",
        sentence: "Compounding grows investments exponentially over time."
    },
    {
        id: "76",
        title: "Geometric Mean",
        sentence: "Geometric mean return measures central tendency of returns."
    },
    {
        id: "77",
        title: "Time",
        sentence: "The longer you invest, the more your returns can grow."
    },
    {
        id: "78",
        title: "Reinvestment",
        sentence: "Reinvesting dividends and interest helps your money grow faster."
    },
    {
        id: "79",
        title: "Dollar-Cost Averaging",
        sentence: "Regularly invest a fixed amount to reduce the impact of market volatility."
    },
    {
        id: "80",
        title: "Compounding Effect",
        sentence: "Earnings generate more earnings over time, amplifying growth."
    },
    {
        id: "81",
        title: "Inflation Protection",
        sentence: "Investing helps protect against the eroding effects of inflation."
    },
    {
        id: "82",
        title: "Risk Tolerance",
        sentence: "Align investments with your ability to handle market fluctuations."
    },
    {
        id: "83",
        title: "Compound Interest",
        sentence: "Reinvesting earnings leads to exponential growth over time."
    },
    {
        id: "84",
        title: "Market Timing",
        sentence: "Trying to time the market can be risky; consistent investing is often better."
    },
    {
        id: "85",
        title: "Dividends",
        sentence: "Regular payments from stocks that provide a steady income stream."
    },
    {
        id: "86",
        title: "Capital Appreciation",
        sentence: "Increase in the value of an asset over time."
    },
    {
        id: "87",
        title: "Alpha Generation",
        sentence: "Seeks to achieve returns above a benchmark through active management, such as security selection, market timing, and exploiting market inefficiencies."
    },
    {
        id: "88",
        title: "Leverage",
        sentence: "Using borrowed funds to amplify returns, which also increases the risk and potential for losses."
    },
    {
        id: "89",
        title: "Risk Premia",
        sentence: "Exploiting systematic risk factors (e.g., equity risk premium, term premium, credit premium) to enhance returns."
    },
    {
        id: "90",
        title: "Active Risk Management",
        sentence: "Using advanced risk models (e.g., Value at Risk, Conditional Value at Risk) and stress testing to manage and mitigate portfolio risks effectively."
    },
    {
        id: "91",
        title: "Multi-Factor Models",
        sentence: "Incorporating multiple risk factors (e.g., Fama-French three-factor model) to better explain asset returns and enhance investment strategies."
    },
    {
        id: "92",
        title: "Statistical Arbitrage",
        sentence: "Exploiting pricing inefficiencies between related securities using statistical and mathematical models to generate profits."
    },
    {
        id: "93",
        title: "Convexity Arbitrage",
        sentence: "Exploiting the non-linear relationship between bond prices and interest rates to generate returns."
    },
    {
        id: "94",
        title: "Pairs Trading",
        sentence: "Identifying and trading pairs of correlated assets to profit from relative price movements."
    },
    {
        id: "95",
        title: "Emergency Fund",
        sentence: "Maintain a cash reserve to cover unexpected expenses, so you don\'t have to sell investments during a downturn."
    },
    {
        id: "96",
        title: "Risk vs. Reward",
        sentence: "Balance your desire for high returns with your ability to handle potential losses."
    },
    {
        id: "97",
        title: "Beta Coefficient",
        sentence: "Beta measures a stock\'s volatility relative to the market."
    },
    {
        id: "98",
        title: "Alpha",
        sentence: "Alpha is the excess return of a portfolio above its expected return."
    },
    {
        id: "99",
        title: "Volatility",
        sentence: "High volatility means higher risk and potentially higher returns."
    },
    {
        id: "100",
        title: "Market Trends",
        sentence: "Understanding market trends helps in making better investment decisions."
    },
    {
        id: "101",
        title: "Dividend Yield",
        sentence: "The dividend income relative to the stock price."
    },
    {
        id: "102",
        title: "Interest Rate Impact",
        sentence: "Rising interest rates can affect bond prices and borrowing costs."
    },
    {
        id: "103",
        title: "Capital Gains",
        sentence: "Profits made from selling investments at a higher price than bought."
    },
    {
        id: "104",
        title: "Tax-Advantaged Accounts",
        sentence: "Use IRAs and 401(k)s to reduce tax liability on investment gains."
    },
    {
        id: "105",
        title: "Liquidity",
        sentence: "Easy-to-sell investments provide flexibility and security."
    },
    {
        id: "106",
        title: "Expense Ratios",
        sentence: "Lower fees and expenses can significantly boost long-term returns."
    },
    {
        id: "107",
        title: "Dollar-Cost Averaging",
        sentence: "Investing the same amount regularly to smooth out market volatility."
    },
    {
        id: "108",
        title: "Market Capitalization",
        sentence: "The total value of a company\'s shares; large-cap, mid-cap, and small-cap categories offer different risk levels and returns."
    },
    {
        id: "109",
        title: "Derivatives",
        sentence: "Instruments like options, futures, and swaps are used for hedging, speculation, and arbitrage to enhance portfolio performance."
    },
    {
        id: "110",
        title: "Quantitative Strategies",
        sentence: "Employ mathematical models and algorithms to identify trading opportunities, often leveraging high-frequency trading and statistical arbitrage."
    },
    {
        id: "111",
        title: "Risk Parity",
        sentence: "Allocates portfolio risk equally among different assets, aiming to achieve a more stable return profile by balancing the contribution of each asset to the portfolio\'s overall risk."
    },
    {
        id: "112",
        title: "Tail Risk Hedging",
        sentence: "Strategies designed to protect portfolios from extreme market events, such as buying put options or using other derivatives to mitigate significant losses during market downturns."
    },
    {
        id: "113",
        title: "Liquidity Management",
        sentence: "Balancing liquid and illiquid assets to meet cash flow needs while maximizing returns, particularly important in managing large institutional portfolios."
    },
    {
        id: "114",
        title: "Behavioral Finance",
        sentence: "Incorporating insights from behavioral finance to understand and exploit market anomalies and investor biases."
    },
    {
        id: "115",
        title: "ESG Investing",
        sentence: "Integrating environmental, social, and governance factors into investment decisions to achieve sustainable, long-term returns."
    },
    {
        id: "116",
        title: "Algorithmic Trading",
        sentence: "Utilizing complex algorithms and high-frequency trading techniques to capitalize on market inefficiencies and execute trades with minimal market impact."
    },
    {
        id: "117",
        title: "Monte Carlo Simulation",
        sentence: "Using probabilistic models to forecast portfolio performance and assess the impact of different investment strategies under various scenarios."
    },
    {
        id: "118",
        title: "Scenario Analysis",
        sentence: "Evaluating how different economic and market scenarios could impact portfolio performance, aiding in strategic decision-making and risk management."
    },
    {
        id: "119",
        title: "Global Macro Strategy",
        sentence: "Investing based on macroeconomic trends and geopolitical events, using top-down analysis to identify opportunities across various asset classes and regions."
    },
    {
        id: "120",
        title: "Alpha Decay",
        sentence: "Understanding how the excess returns (alpha) generated by a strategy decline over time, and adjusting strategies accordingly to maintain performance."
    },
    {
        id: "121",
        title: "Volatility Arbitrage",
        sentence: "Trading strategies that seek to profit from differences between implied and realized volatility of assets."
    },
    {
        id: "122",
        title: "Machine Learning",
        sentence: "Applying machine learning techniques to analyze large datasets, identify patterns, and develop predictive models for investment decisions."
    },
    {
        id: "123",
        title: "Credit Default Swaps (CDS)",
        sentence: "Using CDS to hedge credit risk or speculate on changes in credit spreads of corporate or sovereign debt."
    },
    {
        id: "124",
        title: "Term Structure Modeling",
        sentence: "Analyzing the relationship between bond yields and maturities to identify mispriced bonds and optimize fixed-income portfolios."
    },
    {
        id: "125",
        title: "Tail Risk Management",
        sentence: "Implementing strategies (e.g., buying out-of-the-money options) to protect portfolios from extreme market events that occur with low probability but have high impact."
    },
    {
        id: "126",
        title: "Regime-Switching Models",
        sentence: "Utilizing models that account for different market regimes (e.g., bull and bear markets) to adjust investment strategies based on prevailing market conditions."
    },
    {
        id: "127",
        title: "Optimization under Constraints",
        sentence: "Incorporating various constraints (e.g., regulatory, liquidity, ESG criteria) into portfolio optimization models to ensure practical and compliant investment solutions."
    },
    {
        id: "128",
        title: "Dynamic Hedging",
        sentence: "Continuously adjusting hedges in response to changing market conditions to manage risk more effectively."
    },
    {
        id: "129",
        title: "Behavioral Alpha",
        sentence: "Leveraging insights from behavioral finance to identify and exploit market inefficiencies caused by irrational investor behavior."
    },
    {
        id: "130",
        title: "Blockchain and Cryptocurrencies",
        sentence: "Understanding and investing in digital assets and blockchain technology for potential high returns and diversification benefits."
    },
    {
        id: "131",
        title: "Laddering Strategies",
        sentence: "Constructing bond portfolios with staggered maturities to manage interest rate risk and provide regular income streams."
    },
    {
        id: "132",
        title: "Global Tactical Asset Allocation (GTAA)",
        sentence: "Dynamically adjusting asset allocations based on short-term market opportunities and macroeconomic trends."
    },
    {
        id: "133",
        title: "Event-Driven Investing",
        sentence: "Capitalizing on corporate events (e.g., mergers, acquisitions, bankruptcies) to generate returns."
    },
    {
        id: "134",
        title: "Liquid Alternatives",
        sentence: "Investing in alternative strategies (e.g., long-short equity, managed futures) through liquid vehicles like mutual funds and ETFs."
    },
    {
        id: "135",
        title: "Dynamic Risk Management",
        sentence: "Continuously monitoring and adjusting risk exposures in response to changing market conditions and portfolio performance."
    },
    {
        id: "136",
        title: "Behavioral Portfolio Theory",
        sentence: "Incorporating psychological factors into portfolio construction to better align with investor behavior and preferences."
    },
    {
        id: "137",
        title: "Risk Attribution",
        sentence: "Decomposing portfolio risk into its sources to better understand and manage risk exposures."
    },
    {
        id: "138",
        title: "Volatility Targeting",
        sentence: "Adjusting portfolio exposures to achieve a target level of volatility, enhancing risk management and performance stability."
    },
    {
        id: "139",
        title: "Style Drift Monitoring",
        sentence: "Ensuring that investment managers adhere to their stated investment style to maintain consistency in portfolio performance."
    },
    {
        id: "140",
        title: "Real Options Analysis",
        sentence: "Valuing investment opportunities as options, providing flexibility to adapt to changing market conditions."
    },
    {
        id: "141",
        title: "Hedging with Derivatives",
        sentence: "Using options, futures, and swaps to hedge against various risks (e.g., currency, interest rate, commodity)."
    },
    {
        id: "142",
        title: "Alpha Cloning",
        sentence: "Replicating the strategies of successful hedge fund managers to achieve similar performance."
    },
    {
        id: "143",
        title: "Factor Investing",
        sentence: "Systematically targeting specific risk factors (e.g., momentum, quality) to enhance returns and manage risk."
    },
    {
        id: "144",
        title: "ESG Integration",
        sentence: "Incorporating environmental, social, and governance factors into investment analysis and decision-making to achieve sustainable returns."
    },
    {
        id: "145",
        title: "Tax-Loss Harvesting",
        sentence: "Selling securities at a loss to offset capital gains, reducing tax liability and improving after-tax returns."
    },
    {
        id: "146",
        title: "Stress Testing",
        sentence: "Simulating adverse market conditions to evaluate the potential impact on portfolio performance and enhance risk management."
    },
    {
        id: "147",
        title: "Relative Value Arbitrage",
        sentence: "Exploiting price discrepancies between related securities to generate returns."
    },
    {
        id: "148",
        title: "Overlay Strategies",
        sentence: "Using derivatives to manage portfolio risk and enhance returns without altering the underlying asset allocation."
    },
    {
        id: "149",
        title: "Stocks",
        sentence: "Buying shares in companies can provide high returns but comes with higher risk."
    },
    {
        id: "150",
        title: "Bonds",
        sentence: "Loans to companies or governments that typically offer lower returns but are more stable."
    },
    {
        id: "151",
        title: "Mutual Funds",
        sentence: "Pools of money from many investors to buy a diversified mix of stocks and bonds."
    },
    {
        id: "152",
        title: "ETFs (Exchange-Traded Funds)",
        sentence: "Similar to mutual funds but traded on stock exchanges like individual stocks."
    },
    {
        id: "153",
        title: "Index Funds",
        sentence: "These funds track a market index (e.g., S&P 500) and offer broad market exposure with low fees."
    },
    {
        id: "154",
        title: "Savings Accounts",
        sentence: "Safe but low-return option for storing cash you may need soon."
    },
    {
        id: "155",
        title: "Real Estate",
        sentence: "Investing in real estate can provide income and diversification from stocks and bonds."
    },
    {
        id: "156",
        title: "Treasury Bonds",
        sentence: "Government bonds are among the safest investments and provide predictable returns."
    },
    {
        id: "157",
        title: "Buy and Hold",
        sentence: "Holding investments for a long time can help ride out market ups and downs."
    },
    {
        id: "158",
        title: "Dollar-Cost Averaging",
        sentence: "Invest the same amount regularly to reduce the impact of market fluctuations."
    },
    {
        id: "159",
        title: "Reinvest Dividends",
        sentence: "Use dividends to buy more shares to compound your investment growth."
    },
    {
        id: "160",
        title: "Set and Forget",
        sentence: "Automate your investments to ensure consistency without emotional decision-making."
    },
    {
        id: "161",
        title: "Asset Allocation",
        sentence: "Decide what percentage of your portfolio to allocate to different asset types based on your risk tolerance and time horizon."
    },
    {
        id: "162",
        title: "Automatic Investing",
        sentence: "Set up automatic transfers to your investment accounts to ensure consistent investing."
    },
    {
        id: "163",
        title: "Dividends",
        sentence: "Reinvesting dividends can significantly increase the value of your investments over time."
    },
    {
        id: "164",
        title: "Emergency Fund First",
        sentence: "Save 3-6 months\' worth of expenses in an emergency fund before investing."
    },
    {
        id: "165",
        title: "Avoid High Fees",
        sentence: "Choose investments with low fees to keep more of your returns."
    },
    {
        id: "166",
        title: "Research Before Investing",
        sentence: "Understand what you\'re investing in and why."
    },
    {
        id: "167",
        title: "Stay Informed",
        sentence: "Keep learning about investing to make informed decisions."
    },
    {
        id: "168",
        title: "Patience Pays Off",
        sentence: "Investing is a long-term game; avoid the temptation to react to short-term market movements."
    },
    {
        id: "169",
        title: "Start Small",
        sentence: "Begin with small investments and gradually increase as you become more comfortable."
    },
    {
        id: "170",
        title: "Budget for Investing",
        sentence: "Allocate a portion of your monthly income specifically for investing."
    },
    {
        id: "171",
        title: "Understand Your Investments",
        sentence: "Only invest in what you understand; avoid complex products that you don\'t fully grasp."
    },
    {
        id: "172",
        title: "Review Periodically",
        sentence: "Regularly review your portfolio to ensure it aligns with your goals and risk tolerance."
    },
    {
        id: "173",
        title: "Market Fluctuations",
        sentence: "The market goes up and down; it\'s normal and part of the investing process."
    },
    {
        id: "174",
        title: "Compounding",
        sentence: "Earnings on your investments generate their own earnings, leading to growth over time."
    },
    {
        id: "175",
        title: "Bull vs. Bear Markets",
        sentence: "Bull markets are periods of rising prices, while bear markets are periods of falling prices."
    },
    {
        id: "176",
        title: "Market Corrections",
        sentence: "A market correction is a decline of 10% or more and is a normal part of investing."
    },
    {
        id: "177",
        title: "Economic Indicators",
        sentence: "Learn about indicators like GDP, unemployment rates, and inflation to understand market conditions."
    },
    {
        id: "178",
        title: "Market Sentiment",
        sentence: "Be aware that emotions and market sentiment can influence stock prices."
    },
    {
        id: "179",
        title: "Retirement Accounts",
        sentence: "Use accounts like 401(k)s and IRAs for tax-advantaged retirement savings."
    },
    {
        id: "180",
        title: "Brokerage Accounts",
        sentence: "Open a brokerage account to buy and sell investments."
    },
    {
        id: "181",
        title: "Roth IRA",
        sentence: "Contributions are made with after-tax dollars, and withdrawals are tax-free in retirement."
    },
    {
        id: "182",
        title: "Employer Match",
        sentence: "Take advantage of your employer\'s 401(k) match, as it\'s essentially free money."
    },
    {
        id: "183",
        title: "Tax-Deferred Accounts",
        sentence: "Accounts like traditional IRAs defer taxes until retirement, potentially lowering your tax bill."
    },
    {
        id: "184",
        title: "529 Plans",
        sentence: "Save for education expenses with tax advantages through a 529 plan."
    },
    {
        id: "185",
        title: "Beware of Scams",
        sentence: "Be cautious of investment offers that seem too good to be true."
    },
    {
        id: "186",
        title: "Diversify Your Knowledge",
        sentence: "Learn from multiple sources and don\'t rely on a single advisor or opinion."
    },
    {
        id: "187",
        title: "Diversify Investments",
        sentence: "Don\'t invest all your money in one company or sector."
    },
    {
        id: "188",
        title: "Understand Fees",
        sentence: "Be aware of management fees, trading fees, and other costs that can eat into your returns."
    },
    {
        id: "189",
        title: "Fraud Awareness",
        sentence: "Be vigilant about investment frauds and scams; if it sounds too good to be true, it probably is."
    },
    {
        id: "190",
        title: "Password Security",
        sentence: "Use strong passwords and enable two-factor authentication for your investment accounts."
    },
    {
        id: "191",
        title: "Define Your Goals",
        sentence: "Know why you\'re investing, whether it\'s for retirement, a house, or education."
    },
    {
        id: "192",
        title: "Track Your Progress",
        sentence: "Regularly review your investments and make adjustments as needed."
    },
    {
        id: "193",
        title: "SMART Goals",
        sentence: "Make your investment goals Specific, Measurable, Achievable, Relevant, and Time-bound."
    },
    {
        id: "194",
        title: "Regular Contributions",
        sentence: "Contributing regularly helps smooth out the effects of market volatility."
    },
    {
        id: "195",
        title: "Short-Term vs. Long-Term",
        sentence: "Clearly distinguish between short-term savings and long-term investment goals."
    },
    {
        id: "196",
        title: "Emergency Fund",
        sentence: "Maintain an emergency fund of 3-6 months of living expenses before making significant investments."
    },
    {
        id: "197",
        title: "Read Investment Books",
        sentence: "Start with beginner-friendly books like “The Little Book of Common Sense Investing” by John C. Bogle."
    },
    {
        id: "198",
        title: "Follow Reputable Sources",
        sentence: "Stay updated with market news from reliable sources like Bloomberg or The Wall Street Journal."
    },
    {
        id: "199",
        title: "Join Investment Clubs",
        sentence: "Consider joining or forming an investment club to share knowledge and learn from others."
    },
    {
        id: "200",
        title: "Online Courses",
        sentence: "Take online courses on investing to build your knowledge base."
    },
    {
        id: "201",
        title: "Reinvest Profits",
        sentence: "Use gains from your investments to buy more shares, enhancing growth potential."
    },
    {
        id: "202",
        title: "Avoid Emotional Decisions",
        sentence: "Stick to your investment plan and avoid making decisions based on short-term market movements."
    },
    {
        id: "203",
        title: "Avoid Penny Stocks",
        sentence: "These are highly speculative and can be very risky for new investors."
    },
    {
        id: "204",
        title: "Understand Market Orders",
        sentence: "Learn the difference between market orders and limit orders to buy and sell at your desired prices."
    },
    {
        id: "205",
        title: "Bond Basics",
        sentence: "Bonds are loans you make to a company or government in exchange for periodic interest payments and the return of principal at maturity."
    },
    {
        id: "206",
        title: "Stock Basics",
        sentence: "Owning a stock means owning a piece of a company, and your returns come from dividends and stock price appreciation."
    },
    {
        id: "207",
        title: "ETFs vs. Mutual Funds",
        sentence: "ETFs trade like stocks on an exchange, while mutual funds are bought and sold at the end of the trading day."
    },
    {
        id: "208",
        title: "Dividends vs. Growth",
        sentence: "Dividend-paying stocks provide income, while growth stocks focus on capital appreciation."
    },
    {
        id: "209",
        title: "Use Financial Advisors",
        sentence: "If you\'re unsure where to start, consider consulting with a financial advisor for personalized advice."
    },
    {
        id: "210",
        title: "Track Expenses",
        sentence: "Monitor your spending to identify more money you can invest."
    },
    {
        id: "211",
        title: "Set a Budget",
        sentence: "Create a budget that includes a portion for investments to ensure you\'re consistently contributing."
    },
    {
        id: "212",
        title: "Debt Management",
        sentence: "Pay off high-interest debt before investing to improve your financial health."
    },
    {
        id: "213",
        title: "Stay Disciplined",
        sentence: "Stick to your investment plan even when markets are volatile."
    },
    {
        id: "214",
        title: "Learn from Mistakes",
        sentence: "If you make a poor investment decision, use it as a learning opportunity to improve your strategy."
    },
    {
        id: "215",
        title: "Stay Calm",
        sentence: "Market downturns are normal; staying calm and not panicking is crucial."
    },
    {
        id: "216",
        title: "Learn the Basics of Taxation",
        sentence: "Understand how investments are taxed, including capital gains and dividend taxes."
    },
    {
        id: "217",
        title: "Emergency Fund Maintenance",
        sentence: "Keep your emergency fund in a highly liquid account like a savings account."
    },
    {
        id: "218",
        title: "Diversify Over Time",
        sentence: "Gradually build a diversified portfolio rather than trying to do it all at once."
    },
    {
        id: "219",
        title: "Stay Disciplined with Spending",
        sentence: "Avoid lifestyle inflation as your income grows; save and invest the extra money instead."
    },
    {
        id: "220",
        title: "Financial News",
        sentence: "Keep up with financial news to stay informed about the market and economy."
    },
    {
        id: "221",
        title: "Set Realistic Expectations",
        sentence: "Understand that investing is not a get-rich-quick scheme but a long-term wealth-building strategy."
    }
];

const getRandomIdx = () => {
    return Math.floor(Math.random() * loadingSentences.length);
};

export const loadingSlice = createSlice({
    name: "loading",
    initialState: {
        isLoading: true,
        isDisplayLeftNavBarOpen: true,
        selectedSentence: loadingSentences[getRandomIdx()]
    },
    reducers: {
        setIsLoading(state, action) {
            const newState = { ...state, isLoading: action.payload };
            if (!action.payload) {
                newState.selectedSentence = loadingSentences[getRandomIdx()]
            }
            return newState;
        },
        setIsDisplayLeftNavBarOpen(state, action) {
            return { ...state, isDisplayLeftNavBarOpen: action.payload };
        },
    },
});

export const { setIsLoading, setIsDisplayLeftNavBarOpen } = loadingSlice.actions;

export default loadingSlice.reducer;