import PeriodDropdownSelect from "../../../shared/dropdown-select/period-dropdown-select";
import MacroPriceRelativeFilter from "../macro-price-relative-filter";
import MacroCommoditiesCommodityFilter from "./macro-commodities-filters/macro-commodities-commodity-filter";
import MacroCommoditiesGroupFilter from "./macro-commodities-filters/macro-commodities-group-filter";

const MacroCommodityFilters = ({
    periodFilter,
    onPeriodChange,
    periodOptionsToDisplay,
    groupOptions,
    selectedGroup,
    handleGroupChange,
    commodityOptions,
    selectedCommodity,
    handleCommodityChange,
    activePriceRelative,
    changePriceRelative
}) => {

    return (
        <div className="dropdown-select-filter-container flex center-center">
            {
                <PeriodDropdownSelect
                    currFilter={periodFilter}
                    onChangeFilter={onPeriodChange}
                    optionsToDisplay={periodOptionsToDisplay}
                />
            }
            {
                <MacroCommoditiesGroupFilter
                    options={groupOptions}
                    value={selectedGroup}
                    onChange={handleGroupChange}
                />
            }
            {
                <MacroCommoditiesCommodityFilter
                    options={commodityOptions}
                    value={selectedCommodity}
                    onChange={handleCommodityChange}
                />
            }
            {
                <MacroPriceRelativeFilter
                    activePriceRelative={activePriceRelative}
                    changePriceRelative={changePriceRelative}
                />
            }
        </div>
    );
}

export default MacroCommodityFilters;