import React, { useState, useEffect } from "react";
import ProfileSelectedPortfolioContainer from "../profile-selected-portfolio/profile-selected-portfolio-container";
import ProfilePortfolioNames from "../profile-selected-portfolio/profile-selected-protfolio-names";
import { setGlobalUserSelectedPortfolio } from "../../../store/user/user-actions";
import { useDispatch } from 'react-redux';

const ProfilePortfolios = ({
    profileData,
    isMyProfile,
    mySelectedPortfolio,
    myPortfolio,
    visitedProfilePortfolioOrders,
    handleCopyPortfolio,
    loggedInUser,
    handleChangeMySelectedPortfolio,
    portfolioToDisplay
}) => {

    const dispatch = useDispatch();

    const [selectedPortfolio, setSelectedPortfolio] = useState('portfolioA');
    const [portfolioNames, setPortfolioNames] = useState(null);
    const [portfolioPieChartsData, setPortfolioPieChartsData] = useState(null);
    const [portfolioScoresData, setPortfolioScoresData] = useState(null);
    // const [overallScoresData, setOverallScoresData] = useState(null);
    // const [ordersList, setOrdersList] = useState(null);

    const handleChangeSelectedPortfolio = (portfolio) => {
        setSelectedPortfolio(portfolio);
        dispatch(setGlobalUserSelectedPortfolio(portfolio));
    };

    const overalLineChartColors = [
        '#4E627C',
        '#C0392B',
        '#F39C12',
        '#27AE60',
        '#8E44AD'
    ];

    useEffect(() => {
        if (!profileData) return;
        const portfolioKeysAndNames = [];
        const piechartData = [];
        const portfolioScores = [];
        setPortfolioScoresData(profileData.portfolioScores);
        profileData.forEach((item, idx) => {
            const { portfolioKey, portfolioName, portfolioData, portfolioScores: scores } = item;
            portfolioKeysAndNames.push({
                portfolioKey,
                portfolioName,
                color: overalLineChartColors[idx]
            });
            piechartData.push({
                portfolioKey,
                portfolioName,
                companiesPie: portfolioData.pieChart.companies,
                sectorsPie: portfolioData.pieChart.sectors
            });
            portfolioScores.push({
                portfolioKey,
                portfolioName,
                scores
            });
        });
        setPortfolioNames(portfolioKeysAndNames);
        setPortfolioPieChartsData(piechartData);
        setPortfolioScoresData(portfolioScores);
    }, [profileData]);

    // useEffect(() => {
    //     const selectedPortfolioData = profileData.find(item => item.portfolioKey === selectedPortfolio);
    //     if (selectedPortfolioData) {
    //         const orders = selectedPortfolioData.ordersList;
    //         setOrdersList(orders);
    //     } else {
    //         setOrdersList(null);
    //     }
    // }, [selectedPortfolio])

    const [portfolioWorthSum, setPortfolioWorthSum] = useState(0);
    const handleChangePortfolioWorthSum = (sum) => {
        setPortfolioWorthSum(sum);
    };

    return (
        <div className="card-container">


            {
                portfolioNames &&
                <ProfilePortfolioNames
                    portfolioNames={portfolioNames}
                    selectedPortfolio={selectedPortfolio}
                    handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                    portfolioWorthSum={portfolioWorthSum}
                    portfolioToDisplay={portfolioToDisplay}
                />
            }


            {
                portfolioScoresData &&
                portfolioPieChartsData &&
                <div className="profile-portfolio-container flex column space-between card-container mb20">
                    <ProfileSelectedPortfolioContainer
                        portfolioNames={portfolioNames}
                        selectedPortfolio={selectedPortfolio}
                        handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                        portfolioScoresData={portfolioScoresData}
                        portfolioPieChartsData={portfolioPieChartsData}
                        isMyProfile={isMyProfile}
                        mySelectedPortfolio={mySelectedPortfolio}
                        myPortfolio={myPortfolio}
                        visitedProfilePortfolioOrders={visitedProfilePortfolioOrders}
                        handleCopyPortfolio={handleCopyPortfolio}
                        loggedInUser={loggedInUser}
                        handleChangeMySelectedPortfolio={handleChangeMySelectedPortfolio}
                        portfolioWorthSum={portfolioWorthSum}
                        handleChangePortfolioWorthSum={handleChangePortfolioWorthSum}
                    />
                </div>
            }

            {
                !portfolioScoresData && !portfolioPieChartsData && (
                    <div className="profile-portfolio-container">
                        No portfolios available.
                    </div>
                )
            }


        </div>
    );
}

export default ProfilePortfolios;