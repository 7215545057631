export async function validateInput(type, value) {
    try {
        var raw = JSON.stringify({ "type": type, "value": value });
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: raw,
            redirect: 'follow'
        };

        // console.log('RAW:', raw);

        const response = await fetch(
            "https://augcg59ev8.execute-api.us-east-1.amazonaws.com/getPortfolioFormLimitations",
            requestOptions
        );

        if (!response.ok) {
            console.log('FAIL:', response.status, response);
            return null;
        }

        const result = await response.text();
        const parsedData = JSON.parse(result);
        // Check if parsedData contains the expected data
        if (parsedData) {
            return parsedData;
        } else {
            console.error('Invalid response format:');
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function updatePortfolio(loggedInUser, selectedPortfolio, portfolioData) {

    if (!loggedInUser) {
        console.error('Missing username.');
        return;
    }

    try {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        // Create the request body with the updated userFollowing object
        const requestBody = {
            username: loggedInUser,
            selectedPortfolio: selectedPortfolio,
            updatedPortfolio: portfolioData,
            cash: '250',
        };

        const requestBodyString = JSON.stringify(requestBody);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: requestBodyString,
        };

        // Send a request to your API endpoint to save the updated userFollowing
        const response = await fetch(
            'https://4rhrbrq3v2.execute-api.us-east-1.amazonaws.com/set-user-portfolio',
            requestOptions
        );

        if (!response.ok) {
            console.log('response:', response);
        } else {
            const result = await response.text();
            const data = JSON.parse(result);
            return data;
        }
    } catch (error) {
        // Log the error
        console.error('Error updating following:', error);
        throw error;
    }
};

export async function getPortfolioPage(page, orders) {

    // if (!loggedInUser) {
    //     console.error('Missing username.');
    //     return;
    // }

    try {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        // Create the request body with the updated userFollowing object

        const requestBody = {
            page,
            orders_list: orders
        };

        const requestBodyString = JSON.stringify(requestBody);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: requestBodyString,
        };

        // Send a request to your API endpoint to save the updated userFollowing
        const response = await fetch(
            'https://3weanik4vc.execute-api.us-east-1.amazonaws.com/getPortfolioPage',
            requestOptions
        );

        if (!response.ok) {
            console.log('Error:', response);
        } else {
            const result = await response.text();
            const data = JSON.parse(result);
            return data;
        }
    } catch (error) {
        // Log the error
        console.error('Error updating following:', error);
        throw error;
    }
};


export async function getPortfolioCompareChart(orders, symbol, type) {

    // if (!loggedInUser) {
    //     console.error('Missing username.');
    //     return;
    // }

    try {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        // Create the request body with the updated userFollowing object

        const requestBody = {
            page: 'compareSymbol',
            orders_list: orders,
            symbol: symbol,
            type: type
        };

        const requestBodyString = JSON.stringify(requestBody);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: requestBodyString,
        };

        // Send a request to your API endpoint to save the updated userFollowing
        const response = await fetch(
            'https://3weanik4vc.execute-api.us-east-1.amazonaws.com/getPortfolioPage',
            requestOptions
        );

        if (!response.ok) {
            console.log('Error:', response);
        } else {
            const result = await response.text();
            const data = JSON.parse(result);
            return data;
        }
    } catch (error) {
        // Log the error
        console.error('Error updating following:', error);
        throw error;
    }
};