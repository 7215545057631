import QuestItem from "./quest-item";

const QuestList = ({
    isMyProfile,
    type,
    quests,
    challengeInProcess,
    handleChangeChallengeInProcess,
    userChallenges,
    userAchievements
}) => {
    return (
        <div>
            {quests.map((quest) => (
                <QuestItem
                    key={quest.id}
                    isMyProfile={isMyProfile}
                    type={type}
                    quest={quest}
                    challengeInProcess={challengeInProcess}
                    handleChangeChallengeInProcess={handleChangeChallengeInProcess}
                    userChallenges={userChallenges}
                    userAchievements={userAchievements}
                />
            ))}
        </div>
    );
}

export default QuestList;
