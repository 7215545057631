import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getSelectedSector } from '../../../shared/actions/single-item-actions';
import SingleSectorHeader from './single-sector-header/single-sector-header';
import SingleSectorCompetitive from './single-sector-pages/single-sector-competitive';
import SingleSectorFundamental from './single-sector-pages/single-sector-fundamental';
import ActivePageSelection from '../../../shared/active-page/active-page';
import { setGlobalIsLoading } from '../../../store/loading/loading-actions';
import LoadingSpinner from '../../../shared/loading-spinner/loading-spinner';
import './single-sector.scss';

const SingleSector = ({
    loggedInUser,
    toggleFollowing,
    userFollowing,
    singleSectors,
    setSingleSectors
}) => {

    const { sector } = useParams();
    const loadingSelector = useSelector((state) => state.loading.isLoading);
    const [activePage, setActivePage] = useState('competitive');

    const [sectorHeader, setSectorHeader] = useState(null);
    const [sectorCompetitive, setSectorCompetitive] = useState(null);
    const [sectorFundamental, setSectorFundamnetal] = useState(null);

    const [companyFilters, setCompanyFilters] = useState(null);
    const [activeCompanyFilter, setActiveCompanyFilter] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState(null);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!dataToDisplay) return;
        const competitiveData = dataToDisplay.competitiveData;
        const fundamentalData = dataToDisplay.fundamentalData;
        if (activeCompanyFilter.length > 0) {
            const filterSymbols = activeCompanyFilter.map(filter => filter.value);
            const filteredCompetitiveData = {};
            Object.keys(competitiveData).forEach(key => {
                filteredCompetitiveData[key] = competitiveData[key].filter(item => filterSymbols.includes(item.symbol));
            });
            const filteredFundamentalData = {};
            Object.keys(fundamentalData).forEach(key => {
                filteredFundamentalData[key] = {};
                Object.keys(fundamentalData[key]).forEach(property => {
                    filteredFundamentalData[key][property] = fundamentalData[key][property].filter(item =>
                        filterSymbols.includes(item.symbol)
                    );
                });
            });
            setSectorCompetitive(filteredCompetitiveData);
            setSectorFundamnetal(filteredFundamentalData);
        }
    }, [activeCompanyFilter, dataToDisplay]);

    useEffect(() => {
        if (sectorHeader && sectorCompetitive && sectorFundamental) dispatch(setGlobalIsLoading(false));
    }, [sectorHeader, sectorCompetitive, sectorFundamental]);

    useEffect(() => {
        if (!sector) handleNavigateToError();
        async function fetchData() {
            dispatch(setGlobalIsLoading(true));
            setActivePage('competitive');
            try {
                if (singleSectors[sector]) {
                    setSingleSectorData(
                        singleSectors[sector].sectorHeader,
                        singleSectors[sector].sectorCompetitive,
                        singleSectors[sector].sectorFundamental
                    );
                } else {
                    const sectorData = await getSelectedSector(sector);
                    if (sectorData) {
                        setSingleSectorData(
                            sectorData.sectorHeader,
                            sectorData.sectorCompetitive,
                            sectorData.sectorFundamental
                        );
                        setSingleSectors(prevSingleSectors => ({
                            ...prevSingleSectors,
                            [sector]: sectorData
                        }));
                    } else {
                        handleNavigateToError();
                    };
                };
            } catch (error) {
                handleNavigateToError();
            };
        };
        fetchData();
    }, [sector]);

    useEffect(() => {
        if (!singleSectors[sector]) return;
        const competitiveKeys = Object.keys(singleSectors[sector].sectorCompetitive);
        if (competitiveKeys.length > 0 && Array.isArray(singleSectors[sector].sectorCompetitive[competitiveKeys[0]])) {
            const companyOptions = [];
            singleSectors[sector].sectorCompetitive[competitiveKeys[0]].forEach(item => {
                companyOptions.push({
                    label: `(${item.symbol}) ${item.company}`,
                    value: item.symbol,
                });
            });
            setCompanyFilters(companyOptions);
            setActiveCompanyFilter(companyOptions);
        };
    }, [singleSectors, sector]);

    const setSingleSectorData = (headerData, competitiveData, fundamentalData) => {
        setSectorHeader(headerData);
        setSectorCompetitive(competitiveData);
        setSectorFundamnetal(fundamentalData);
        setDataToDisplay({ headerData, competitiveData, fundamentalData });
    };

    const handleToggleOption = (option) => {
        setActivePage((prevOption) => {
            return option;
        });
    };

    const handleNavigateToError = () => {
        history.push('/error');
    };

    const handleToggleFilter = (options) => {
        if (options.length === 0) return;
        setActiveCompanyFilter((prevOption) => {
            return options;
        });
    };

    const pages = ['Competitive', 'Fundamental'];

    return (
        <>
            {
                loadingSelector ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className="page-margin-top"></div>
                        <ActivePageSelection
                            handleToggleOption={handleToggleOption}
                            pages={pages}
                            activePage={activePage}
                        />

                        <div className="main-content-container">
                            {
                                sectorHeader &&
                                <SingleSectorHeader
                                    loggedInUser={loggedInUser}
                                    toggleFollowing={toggleFollowing}
                                    userFollowing={userFollowing}
                                    sectorHeader={sectorHeader}
                                />
                            }
                        </div>



                        <div className="main-content-container ">
                            {
                                activePage === 'competitive' &&
                                sectorCompetitive &&
                                <SingleSectorCompetitive
                                    sectorCompetitive={sectorCompetitive}
                                    companyFilters={companyFilters}
                                    activeCompanyFilter={activeCompanyFilter}
                                    handleToggleFilter={handleToggleFilter}
                                />
                            }
                            {
                                activePage === 'fundamental' &&
                                sectorFundamental &&
                                <SingleSectorFundamental
                                    sectorFundamental={sectorFundamental}
                                    companyFilters={companyFilters}
                                    activeCompanyFilter={activeCompanyFilter}
                                    handleToggleFilter={handleToggleFilter}
                                />
                            }
                        </div>
                    </>
                )
            }
        </>
    );
}

export default SingleSector;