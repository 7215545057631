import './technical-content-table.scss';
import { useMediaQuery } from 'react-responsive';

const TechnicalContentTable = ({ tableData, isSmallScreen }) => {

    const isMobile = useMediaQuery({ maxWidth: 920 });

    const formatRangeString = (range) => {
        const [start, end] = range.split(' - ').map(part => part.trim());
        if (start && end) {
            return `$${start} - $${end}`;
        } else {
            console.log('Invalid range format');
            return range;
        }
    };
    
    const containerClassName = `technical-table-content-container card-container flex column space-between ${isMobile ? 'mb20' : ''}`;

    return (
        <div
            className={containerClassName}
            style={{ width: isSmallScreen ? '100%' : ''}}
        >
            {
                tableData.openPrice &&
                <div className="onyx-table-item flex space-between">
                    <div>Open Price</div>
                    <div>${tableData.openPrice}</div>
                </div>
            }
            {
                tableData.closePrice &&
                <div className="onyx-table-item flex space-between">
                    <div>Close Price</div>
                    <div>${tableData.closePrice}</div>
                </div>
            }
            {
                tableData.lastPrice &&
                <div className="onyx-table-item flex space-between">
                    <div>Last price</div>
                    <div>${tableData.lastPrice}</div>
                </div>
            }
            {
                tableData.daysRange && <div className="onyx-table-item flex space-between">
                    <div>Day's range</div>
                    <div>{formatRangeString(tableData.daysRange)}</div>

                </div>
            }
            {
                tableData.weeks52range &&
                <div className="onyx-table-item flex space-between">
                    <div>52 Week range</div>
                    <div>{formatRangeString(tableData.weeks52range)}</div>

                </div>
            }
            {
                tableData.volume &&
                <div className="onyx-table-item flex space-between">
                    <div>Volume</div>
                    <div>{tableData.volume}</div>
                </div>
            }
            {
                tableData.meanDailyReturn &&
                <div className="onyx-table-item flex space-between">
                    <div>Mean daily return</div>
                    <div>{tableData.meanDailyReturn}</div>
                </div>
            }
            {
                tableData.sharpeDailyReturn &&
                <div className="onyx-table-item flex space-between">
                    <div>Sharpe daily return</div>
                    <div>{tableData.sharpeDailyReturn}</div>
                </div>
            }
            {
                tableData.stdDailyReturn &&
                <div className="onyx-table-item flex space-between">
                    <div>Standard deviation daily return</div>
                    <div>{tableData.stdDailyReturn}</div>
                </div>
            }

            {
                tableData.bitcoinBeta &&
                <div className="onyx-table-item flex space-between">
                    <div>Bitcoin beta</div>
                    <div>{tableData.bitcoinBeta}</div>
                </div>
            }
            {
                tableData.bitcoinRank &&
                <div className="onyx-table-item flex space-between">
                    <div>Bitcoin rank</div>
                    <div>{tableData.bitcoinRank}</div>
                </div>
            }
            {
                tableData.SPbeta &&
                <div className="onyx-table-item flex space-between">
                    <div>S&P 500 Beta</div>
                    <div>{tableData.SPbeta}</div>
                </div>
            }
            {
                tableData.SPrank &&
                <div className="onyx-table-item flex space-between">
                    <div>S&P 500 Rank</div>
                    <div>{tableData.SPrank}</div>
                </div>
            }
            {
                tableData.adx &&
                <div className="onyx-table-item flex space-between">
                    <div>ADX</div>
                    <div>{tableData.adx}</div>
                </div>
            }
        </div>
    );
}

export default TechnicalContentTable;