import { useHistory } from 'react-router-dom';
import FeedbackModal from '../../../cmps/feedback-modal/feedback-modal';

const HomepageRightFooter = ({
    closeFeedbackModal,
    isFeedBackModalOpen,
    loggedInUser,
    openFeedbackModal,
    onSendFeedback
}) => {

    const history = useHistory();

    const navigateAboutUs = () => {
        history.push('/about');
    };

    const navigateTerms = () => {
        history.push('terms-of-service');
    };

    const navigatePrivacy = () => {
        history.push('privacy-notice');
    };

    const navigateTutorial = () => {
        history.push('tutorial');
    };

    const navigateFAQ = () => {
        history.push('FAQ');
    };

    return (
        <>

            <div className="card-container flex mb10" style={{ padding: '1px' }}>
                <div
                    className="btn"
                    style={{ marginRight: '0', fontSize: '0.8rem', width: '100%' }}
                    onClick={openFeedbackModal}
                >
                    Feedback
                </div>
                <div
                    className="btn"
                    style={{ marginRight: '0', fontSize: '0.8rem', width: '100%' }}
                    onClick={navigateTutorial}
                >
                    Tutorial
                </div>
                <div
                    className="btn"
                    style={{ marginRight: '0', fontSize: '0.8rem', width: '100%' }}
                    onClick={navigateFAQ}
                >
                    FAQ
                </div>
            </div>

            <div className="card-container flex mb10" style={{ padding: '1px' }}>
                <div
                    className="btn"
                    style={{ marginRight: '0', fontSize: '0.8rem', width: '100%' }}
                    onClick={navigateAboutUs}
                >
                    About Us
                </div>

                <div
                    className="btn"
                    style={{ marginRight: '0', fontSize: '0.8rem', width: '100%' }}
                    onClick={navigateTerms}
                >
                    Terms of Use
                </div>
                <div
                    className="btn"
                    style={{ marginRight: '0', fontSize: '0.8rem', width: '100%' }}
                    onClick={navigatePrivacy}
                >
                    Privacy Policy
                </div>
            </div>

            {
                isFeedBackModalOpen &&
                <FeedbackModal
                    loggedInUser={loggedInUser}
                    closeFeedbackModal={closeFeedbackModal}
                    onSubmit={onSendFeedback}
                />
            }
        </>
    );
}

export default HomepageRightFooter;