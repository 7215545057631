import React, { useState, useEffect, useRef } from "react";
import ExportToExcelBtn from "./export-excel/export-excel";
import PaginationBtns from "./pagination-btns/pagination-btns";
import SelectedTable from "./selected-table/selected-table";
import { useHistory } from "react-router-dom";
import './financial-statements-table-btns.scss';

const FinancialStatementsTable = ({ symbol, companyName, tableData }) => {

    const [activeOption, setActiveOption] = useState('balanceSheet');
    const [firstIndexSet, setFirstIndexSet] = useState(1);
    const [lastIndexSet, setLastIndexSet] = useState(9);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(9);
    const [selectedPage, setSelectedPage] = useState(1);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 820);
    const [containerWidth, setContainerWidth] = useState(0);

    const containerRef = useRef(null);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 820);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const history = useHistory();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setItemsPerPage(9);
        handlePageClick(1);
        handlePagination(0);
    }, []);

    const handlePageClick = (page) => {
        const newFirstIndexSet = (page - 1) * itemsPerPage + 1;
        const maxLastIndexSet = Math.min(newFirstIndexSet + itemsPerPage - 1, tableData[activeOption]?.length - 1);
        setFirstIndexSet(newFirstIndexSet);
        setLastIndexSet(maxLastIndexSet);
        setSelectedPage(page);
    };

    const handlePagination = (increment) => {
        if ((increment && incrementDisabled) || (!increment && firstIndexSet === 1)) return;

        let newFirstIndexSet;
        let newSelectedPage;

        if (increment) {
            newFirstIndexSet = firstIndexSet + itemsPerPage;
            newSelectedPage = selectedPage + 1;
        } else {
            newFirstIndexSet = firstIndexSet - itemsPerPage;
            newSelectedPage = selectedPage - 1;
        }

        const maxLastIndexSet = Math.min(tableData[activeOption]?.length - 1, newFirstIndexSet + itemsPerPage - 1);

        setFirstIndexSet(newFirstIndexSet);
        setLastIndexSet(maxLastIndexSet);
        setSelectedPage(newSelectedPage);
    };

    useEffect(() => {
        const containerElement = containerRef.current;
        if (containerElement) {
            const newContainerWidth = containerElement.offsetWidth;
            setContainerWidth(newContainerWidth);
            const totalDataLength = tableData[activeOption]?.[2]?.length ?? 0;
            setTotalPages(Math.ceil(totalDataLength / itemsPerPage));
            const availableWidth = newContainerWidth - 280; // Subtract the fixed title width

            if (newContainerWidth <= 480) {
                setItemsPerPage(1); // Set itemsPerPage to 1 for widths 480 or lower
            } else {
                const cellsPerRow = Math.floor(availableWidth / 100); // Assuming each cell is 100px wide
                setItemsPerPage(cellsPerRow);
            }

            handlePageClick(1);
        }
    }, [activeOption, tableData, itemsPerPage, windowWidth, containerWidth]);

    useEffect(() => {
        const firstOption = hardcodedKeys[0];
        setActiveOption(firstOption);
    }, []);

    const handleChangeActiveOption = (key) => {
        setActiveOption(key);
    };

    const fixStr = (str) => {
        if (typeof str === 'string') {
            return str.replace(/([A-Z])/g, ' $1').replace(/^./, (match) => match.toUpperCase());
        }
        return str;
    };

    const incrementDisabled = !tableData[activeOption]?.some((row) => {
        const array = [row[0], ...row.slice(firstIndexSet, lastIndexSet + 1)];
        return array.length >= itemsPerPage + 1;
    });


    const hardcodedKeys = ['balanceSheet', 'incomeStatement', 'ratios'];

    const noDataMsg = `Sorry, it appears that we currently do not have data to display for ${fixStr(activeOption)} at ${companyName}. We are actively working on updating our information.`;

    const handleNavigateToHome = () => {
        history.push('/');
    };

    return (
        <>
            <div className="main-content-container" style={{marginTop: '20px'}}>
                <div className="fundamental-page-container flex center-center">
                    {
                        hardcodedKeys.map((item, idx) => (
                            <div
                                className={`btn ${activeOption === item ? 'selected' : ''}`}
                                onClick={() => handleChangeActiveOption(item)}
                                key={item}>
                                {fixStr(item)}
                            </div>
                        ))
                    }
                </div>

                <div
                    className="financial-statements-table-container card-container"
                >
                    <div className="financial-statements-table-btns flex">
                        {/* <div className="flex">
                    {Object.keys(tableData).map((key) => (
                        // Skip rendering if the corresponding value is an empty array
                        tableData[key].length > 0 && (
                            <div
                                style={{ marginBottom: '20px' }}
                                className={`btn ${activeOption === key ? 'selected' : ''}`}
                                onClick={() => handleChangeActiveOption(key)}
                                key={key}>
                                {fixStr(key)}
                            </div>
                        )
                    ))}
                </div> */}


                    </div>

                    {
                        lastIndexSet &&
                        <div className="flex align-center">
                        </div>
                    }
                    {
                        tableData[activeOption].length ? (
                            < PaginationBtns
                                incrementDisabled={incrementDisabled}
                                decrementDisabled={firstIndexSet === 1}
                                handlePagination={handlePagination}
                                handlePageClick={handlePageClick}
                                totalPages={totalPages}
                                selectedPage={selectedPage}
                            />
                        ) : null
                    }
                    {activeOption && tableData[activeOption].length ? (
                        <div ref={containerRef}>
                            <SelectedTable
                                tableData={tableData[activeOption].map((row) => [row[0], ...row.slice(firstIndexSet, lastIndexSet + 1)])}
                                fixStr={fixStr}
                                totalPages={totalPages}
                                activeOption={activeOption}
                            />
                        </div>
                    ) : <div>
                        <p className="financial-statements-table-no-data-msg">
                            <div className="financial-statements-table-no-data-msg-div">
                                {noDataMsg}
                            </div>
                            <br />
                            Thank you for your patience,
                            <br />
                            PortFollow development team.
                        </p>
                        <div className="financial-statements-table-homepage-btn btn" onClick={handleNavigateToHome} >
                            Back to homepage
                        </div>
                    </div>
                    }
                    {
                        lastIndexSet && (
                            <div className={`pagination-bottom-btns flex ${isMobile ? 'column' : 'space-between align-center'}`}>
                                {
                                    tableData[activeOption].length ? (
                                        < PaginationBtns
                                            incrementDisabled={incrementDisabled}
                                            decrementDisabled={firstIndexSet === 1}
                                            handlePagination={handlePagination}
                                            handlePageClick={handlePageClick}
                                            totalPages={totalPages}
                                            selectedPage={selectedPage}
                                        />
                                    ) : null
                                }
                                {
                                    tableData[activeOption].length ? (
                                        <ExportToExcelBtn
                                            symbol={symbol}
                                            activeOption={activeOption}
                                            sheetData={tableData[activeOption]}
                                            fixStr={fixStr}
                                        />
                                    ) : null
                                }
                            </div>
                        )}
                </div>
            </div>
        </>
    );
}

export default FinancialStatementsTable;