import React from 'react';
import ReturnSharpe from '../../../../cmps/return-sharpe/return-sharpe';

const TechnicalBarCharts = ({ data, filter, symbol, type }) => {

    const convertCamelCaseToNormal = (camelCaseString) => {
        if (type !== 'commodity') return camelCaseString;
        return camelCaseString
          .split(':')
          .map((part) => {
            const words = part
              .trim()
              .split(/(?=[A-Z])/)
              .map((word) => word.toLowerCase());
            const formattedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
            return formattedWords.join(' ');
          })
          .join(': ');
      };
      
    const getBarChartData = (chartType) => {
        const chartName = chartType === 'returnChart' ? 'Sectorial returns (closest)' : 'Sectorial Sharpes (closest)';
        const hoverArray = data[chartType][filter].map((data) =>
            data.hover.map((hoverItem) => convertCamelCaseToNormal(hoverItem))
        );
        return {
            labels: data[chartType][filter].map((data) => data.symbol),
            hover: hoverArray,
            datasets: [
                {
                    label: chartName,
                    hover: data[chartType][filter].map((data) => data.hover),
                    data: data[chartType][filter].map((data) => data.return ? data.return : data.sharpe),
                    backgroundColor: data[chartType][filter].map((data) => {
                        if (data.symbol === symbol) {
                            return '#4071AB';
                        } else if (data.return > 0 || data.sharpe > 0) {
                            return '#5c9ead';
                        } else {
                            return '#e39774';
                        }
                    }),
                    borderColor: data[chartType][filter].map((data) => {
                        if (data.symbol === symbol) {
                            return '#4071AB';
                        } else if (data.return > 0 || data.sharpe > 0) {
                            return '#5c9ead';
                        } else {
                            return '#e39774';
                        }
                    }),

                    borderWidth: 2,
                },
            ],
        };
    };

    return (
        <ReturnSharpe
            returnValue={{
                type: type,
                chartData: getBarChartData('returnChart'),
            }}
            sharpeValue={{
                type: type,
                chartData: getBarChartData('sharpeChart'),
            }}
        />
    );
}

export default TechnicalBarCharts;