import React, { useEffect, useRef, useState } from 'react';
import './single-item-technical-content.scss';
import TechnicalBarcharts from './technical-content-barcharts/technical-content-barcharts';
import TechnicalPriceOverTime from './technical-content-priveovertime/technical-content-priceovertime';
import TechnicalContentTable from './technical-content-table/technical-content-table';

const SingleItemTechnicalContent = ({ table, priceOverTime, barcharts, activeFilter, symbol, type }) => {

    const [tableHeight, setTableHeight] = useState(0);
    const tableRef = useRef(null);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 920);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (tableRef.current) {
            const height = tableRef.current.clientHeight;
            setTableHeight(height);
        }
    }, [table]);

    return (
        <div className="container-height main-content-container media-container-height">
            <div className={`findatasheets-container-item-top mb20 flex space-between ${isSmallScreen ? 'column' : ''}`}>
                <TechnicalContentTable tableData={table} isSmallScreen={isSmallScreen}/>
                <TechnicalPriceOverTime data={priceOverTime} filter={activeFilter} tableHeight={tableHeight} />
            </div>
            <TechnicalBarcharts
                data={barcharts}
                filter={activeFilter}
                symbol={symbol}
                type={type}
            />
        </div>
    );
}

export default SingleItemTechnicalContent;