import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { formatNumberToDollarTableString } from '../../../../../shared/global-color-functions';
import { useMediaQuery } from 'react-responsive';

const FeedPie = ({ item, PortfolioProfit }) => {

    const isMobileScreen = useMediaQuery({ maxWidth: 600 });

    const data = {
        labels: item.data.labels,
        datasets: [
            {
                data: item.data.datasets[0].data,
                backgroundColor: item.data.datasets[0].backgroundColor,
                borderColor: item.data.datasets[0].borderColor,
                borderWidth: item.data.datasets[0].borderWidth,
                borderRadius: item.data.datasets[0].borderRadius,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true,
                    boxHeight: 10
                }
            },
            tooltip: {
                displayColors: false,
                backgroundColor: '#ffffff',
                titleColor: '#484848',
                borderWidth: '1',
                borderColor: '#484848',
                bodyColor: '#484848',
                cornerRadius: '20',
                padding: '10',
                callbacks: {
                    title: (tooltipItem) => {
                        return item.data.hover[tooltipItem[0].dataIndex][0];
                    },
                    label: (tooltipItem) => {
                        const hoverData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex];
                        const line1 = item.data.hover[tooltipItem.dataIndex][1];
                        const line2 = formatNumberToDollarTableString(+hoverData);
                        return [line1, line2];
                    }
                }
            },
        }
    };

    return (
        <>
            <div className={`flex ${isMobileScreen ? 'column' : 'space-between'}`}>
                {/* <div className="flex space-between"> */}
                <h4 className='portfolio-chart-title flex align-end mr20' style={{minWidth: '160px'}}>{item.title}</h4>
                <PortfolioProfit />
            </div>
            <div className='homepage-feed-chart-container flex column center-center' style={{ height: '220px', width: '100%' }}>
                <Doughnut data={data} options={options} style={{width: '100%' }}/>
            </div>
        </>
    );
};

export default FeedPie;