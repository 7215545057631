import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userImg, closeIcon, commentIcon, copyIcon, leftArrow, leftFillArrow, rightArrow, rightFillArrow, unfollowIcon, followIcon, sincePostProfitIcon, arrowDownIcon, arrowUpIcon } from '../../../../shared/icons/icons';
import { setDisplayLeftNavBar } from '../../../../store/loading/loading-actions';
import HomepageFeedContentToggleBtn from './feed-cmps/homepage-feed-toggle-content';
import HomepageFeedPostContent from './homepage-feed-post-content';
import { useHistory } from 'react-router-dom';
import ProfilePortfolioBtns from '../../../user-profile/profile-portfolio/profile-portfolio-btns';
import FeedTable from './feed-cmps/feed-table';
import './homepage-feed-post.scss';
import FeedConfirmChanges from './feed-cmps/feed-confirm-changes';
import { useMediaQuery } from 'react-responsive';
import FeedPostModalCommentTextarea from '../feed-post-modal-comment-textarea';
import { convertDateToReadableStr } from '../../../../shared/global-color-functions';
import CopyPostProfitCard from './feed-cmps/copy-post-profit-card';
// import portFollowIcon from '../../../../../public/img/portfollow-logo/PortFollow-icon2.png';

const HomepageFeedPost = ({
    post,
    loggedInUser,
    myPortfolio,
    onCopyPortfolio,
    handleCopyPortfolio,
    handlePostAction,
    handleCopyPortfolioFromFeed,
    calcPortfolioReturn,
    handleCopyModalChange,
    blinkCmp
}) => {

    const defaultImg = '/img/defaults/default-user-img.png';

    const dispatch = useDispatch();
    const [isPostModalOpen, setPostModalOpen] = useState(false);
    const [isCopyPortfolioModalOpen, setIsCopyPortfolioModalOpen] = useState(false);
    const history = useHistory();

    const [selectedItemIdx, setSelectedItemIdx] = useState(0);
    const [selectedPortfolio, setSelectedPortfolio] = useState('portfolioA');
    const isSmallScreen = useMediaQuery({ maxWidth: 820 });
    const isMobileScreen = useMediaQuery({ maxWidth: 600 });
    const isMiniScreen = useMediaQuery({ maxWidth: 520 });

    const lastPrices = useSelector((state) => state.lastPrices);

    const handleChangeSelectedPortfolio = (portfolio) => {
        setSelectedPortfolio(portfolio);
    };

    const handleToggleClick = (action) => {
        if (typeof action === 'number') {
            setSelectedItemIdx(action);
        } else {
            let newIndex;
            if (action === 'increment') {
                newIndex = selectedItemIdx + 1;
                if (newIndex >= post.data.postData.length) {
                    newIndex = 0;
                }
            } else if (action === 'decrement') {
                newIndex = selectedItemIdx - 1;
                if (newIndex < 0) {
                    newIndex = post.data.postData.length - 1;
                }
            };
            setSelectedItemIdx(newIndex);
        };
    };

    useEffect(() => {
        handleCopyModalChange();
        const homepageLeftContainerItem = document.querySelector('.homepage-left-container-item');
        const homepageLeftContainer = document.querySelector('.homepage-left-container');
        if (isPostModalOpen) {
            if (homepageLeftContainerItem) {
                homepageLeftContainerItem.style.backgroundColor = 'rgba(208, 208, 208, 0.5)';
                homepageLeftContainerItem.style.height = '100%';
            };
            dispatch(setDisplayLeftNavBar(false));
        } else {
            if (homepageLeftContainerItem) {
                homepageLeftContainerItem.style.backgroundColor = '#ffffff';
                dispatch(setDisplayLeftNavBar(true));
            }
        };
    }, [isPostModalOpen]);

    const handleOpenModal = () => {
        setPostModalOpen(true);
    };

    const handleCloseModal = () => {
        setPostModalOpen(false);
        setShowFirstPart(true);
        setIsCopyPortfolioConfirmed(false);
    };

    const handleOpenCopyPortfolioModal = () => {
        setIsCopyPortfolioModalOpen(true);
        const leftContainer = document.querySelector('.homepage-left-container');
        if (leftContainer) {
            leftContainer.style.zIndex = -10;
        }
    };

    const handleCloseCopyPortfolioModal = () => {
        setIsCopyPortfolioModalOpen(false);
        setIsCopyPortfolioConfirmed(false);
        setShowFirstPart(true);
        const leftContainer = document.querySelector('.homepage-left-container');
        if (leftContainer) {
            leftContainer.style.zIndex = 1000;
        }
    };

    const handleOverlayClick = (ev) => {
        if (ev.target === ev.currentTarget) {
            handleCloseModal();
            handleCloseCopyPortfolioModal();
        };
    };

    const handleCopyPostData = () => {
        handleOpenCopyPortfolioModal();
    };

    const onPostComment = (postId, commentText) => {
        handlePostAction(postId, 'comment', commentText);
    }

    const getPostProfit = (profit) => {
        if (!profit) return null;
        const postWorthNum = typeof profit.post_worth === 'string' ? parseFloat(profit.post_worth) : profit.post_worth;
        const investedWorthNum = typeof profit.invested_worth === 'string' ? parseFloat(profit.invested_worth) : profit.invested_worth;
        const result = ((postWorthNum / investedWorthNum) - 1) * 100;
        const days = profit.days_difference;
        return { result, days };
    };

    const PostMainContent = ({ profit, selectedPortfolio }) => {
        const profitRes = getPostProfit(profit);
        return (
            <>
                {
                    Object.keys(post.data).length > 0 && (
                        <div className="flex space-between align-center">
                            <HomepageFeedContentToggleBtn
                                icon={leftArrow}
                                filledIcon={leftFillArrow}
                                action={'decrement'}
                                handleToggleClick={handleToggleClick}
                                length={post.data.postData.length}
                            />
                            <HomepageFeedPostContent
                                content={post.data.postData}
                                selectedItemIdx={selectedItemIdx}
                                handleToggleClick={handleToggleClick}
                                profitRes={profitRes}
                                selectedPortfolio={selectedPortfolio}
                                untilDate={convertDateToReadableStr(post.time)}
                                timestamp={post.time}
                            />
                            <HomepageFeedContentToggleBtn
                                icon={rightArrow}
                                filledIcon={rightFillArrow}
                                action={'increment'}
                                handleToggleClick={handleToggleClick}
                                length={post.data.postData.length}
                            />
                        </div>
                    )
                }
            </>
        )
    };

    const PostSocialBtns = ({ post }) => {
        const shouldRenderComponent = Object.keys(post.data).length !== 0 && post.hasOwnProperty('ordersList');
        return (
            <>
                <div className='homepage-feed-user-btn-container flex space-evenly'>
                    <div
                        className='homepage-feed-user-btn-icon flex align-center'
                        title={'Like'}
                        onClick={() => handlePostAction(post.postid, 'like')}
                    >
                        {post.likes.some((like) => like.username === loggedInUser) ? (
                            <>{followIcon}</>
                        ) : (
                            <>{unfollowIcon}</>
                        )}
                        <span className='homepage-feed-post-counter-span'>{post.likes.length}</span>
                    </div>

                    <div className="homepage-feed-user-btn-container-item flex align-end">
                        <div
                            onClick={handleOpenModal}
                            className='homepage-feed-user-btn-icon flex align-center'
                            title={'Comment'}
                        >
                            {commentIcon}
                            <span className='homepage-feed-post-counter-span'>{post.comments.length}</span>
                        </div>
                    </div>

                    {/* {
                        shouldRenderComponent && (
                            <div className="homepage-feed-user-btn-container-item flex align-end">
                                <div
                                    onClick={() => handleCopyPostData()}
                                    className='homepage-feed-user-btn-icon flex align-center'
                                    title={'Copy'}
                                >
                                    {copyIcon}
                                    <span className='homepage-feed-post-counter-span'>{post.copies}</span>
                                </div>
                            </div>
                        )
                    } */}

                    {
                        shouldRenderComponent && (
                            <div className="homepage-feed-user-btn-container-item flex align-end">
                                <div
                                    onClick={() => handleCopyPostData()}
                                    className={`homepage-feed-user-btn-icon flex align-center ${blinkCmp !== null && blinkCmp === 'copy-portfolio-btn' ? 'blink-border' : ''}`}
                                    title={'Copy'}
                                >
                                    {copyIcon}
                                    <span className='homepage-feed-post-counter-span'>{post.copies}</span>
                                </div>
                            </div>
                        )
                    }


                </div>
            </>
        )
    };

    const handleLoginClick = () => {
        handleCloseModal();
        handleCloseCopyPortfolioModal();
        history.push('/login');
    };

    const handleSignupClick = () => {
        handleCloseModal();
        handleCloseCopyPortfolioModal();
        history.push('/signup');
    };

    const [selectedPortfolioData, setSelectedPortfolioData] = useState([]);
    const [isCopyPortfolioConfirmed, setIsCopyPortfolioConfirmed] = useState(false);

    const handleCopyPortfolioConfirmation = () => {
        setIsCopyPortfolioConfirmed(true);
    };

    const handleSaveCopiedPortfolio = (postData) => {
        handleCopyPortfolioFromFeed(post.postid, post.copies);
        const orders = postData.portfolioData.map(order => ({
            ...order,
            amount: parseFloat(order.amount),
            price: parseFloat(order.price)
        }));
        handleCopyPortfolio(selectedPortfolio, orders);
        handleCloseCopyPortfolioModal();
        history.push('/portfolio/companies');
    };

    useEffect(() => {
        if (!myPortfolio) return;
        setIsCopyPortfolioConfirmed(false);
        const selectedPortfolioObj = myPortfolio[selectedPortfolio];
        if (selectedPortfolioObj && selectedPortfolioObj.portfolioData && selectedPortfolioObj.portfolioData.length) {
            const selectedData = selectedPortfolioObj.portfolioData;
            setSelectedPortfolioData(selectedData);
        } else {
            setSelectedPortfolioData([]);
        }
    }, [selectedPortfolio]);


    const getMyProfit = () => {
        const _profit = calcPortfolioReturn(selectedPortfolioData);
        return _profit;
    };

    // const CopyPortfolioContent = ({ post, profit }) => {
    //     const portfolioKeys = ['portfolioA', 'portfolioB', 'portfolioC', 'portfolioD', 'portfolioE'];
    //     const portfolioNames = portfolioKeys.map(portfolioKey => ({
    //         portfolioKey,
    //         portfolioName: myPortfolio[portfolioKey]?.portfolioName || portfolioKey
    //     }));
    //     return (
    //         <div style={{ minWidth: '50vw', maxHeight: '80vh' }}>
    //             <h2 className='portfolio-chart-title'>Copy post data to My Portfolio</h2>
    //             <p className='mb10'>Select Portfolio:</p>
    //             <ProfilePortfolioBtns
    //                 portfolioNames={portfolioNames}
    //                 selectedPortfolio={selectedPortfolio}
    //                 handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
    //             />
    //             <FeedTable
    //                 orders={selectedPortfolioData}
    //             />

    //             {
    //                 selectedPortfolioData.length > 0 &&
    //                 <CopyPostProfitCard
    //                     profitData={getMyProfit()}
    //                     isToday={isToday}
    //                     untilDate={convertDateToReadableStr(post.time)}
    //                 />
    //             }

    //             <h4
    //                 className='flex mb20'>
    //                 Incomming change
    //                 <span
    //                     className="flex align-center"
    //                     style={{ marginLeft: '10px' }}
    //                 >
    //                     {arrowDownIcon}</span>
    //             </h4>

    //             <FeedTable
    //                 orders={post.ordersList.portfolioData}
    //             />
    //             <CopyPostProfitCard
    //                 profitData={profit}
    //                 isToday={isToday}
    //                 untilDate={convertDateToReadableStr(post.time)}
    //             />
    //             < FeedConfirmChanges
    //                 isConfirmed={isCopyPortfolioConfirmed}
    //                 handleConfirmation={handleCopyPortfolioConfirmation}
    //                 selectedPortfolio={selectedPortfolio}
    //                 handleCloseCopyPortfolioModal={handleCloseCopyPortfolioModal}
    //                 handleSaveCopiedPortfolio={handleSaveCopiedPortfolio}
    //                 orders={post.ordersList}
    //             />
    //         </div>
    //     )
    // };

    const [showFirstPart, setShowFirstPart] = useState(true);

    const CopyPortfolioContent = ({ post, profit }) => {
        const portfolioKeys = ['portfolioA', 'portfolioB', 'portfolioC', 'portfolioD', 'portfolioE'];
        const portfolioNames = portfolioKeys.map(portfolioKey => ({
            portfolioKey,
            portfolioName: myPortfolio[portfolioKey]?.portfolioName || portfolioKey
        }));

        // const [showFirstPart, setShowFirstPart] = useState(true);

        const handleNext = () => {
            setShowFirstPart(false);
        };

        return (
            <div style={{ minWidth: '50vw', maxHeight: '80vh' }}>
                {showFirstPart ? (
                    <div>
                        <h2 className='portfolio-chart-title'>Copy post data to My Portfolio</h2>
                        <p className='mb10'>Select Portfolio:</p>
                        <ProfilePortfolioBtns
                            portfolioNames={portfolioNames}
                            selectedPortfolio={selectedPortfolio}
                            handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                        />
                        <FeedTable
                            orders={selectedPortfolioData}
                        />
                        {selectedPortfolioData.length > 0 && (
                            <CopyPostProfitCard
                                profitData={getMyProfit()}
                                isToday={isToday}
                                untilDate={convertDateToReadableStr(post.time)}
                            />
                        )}
                        <div className='btn btn-border' style={{ width: 'fit-content' }} onClick={handleNext}>Next</div>
                    </div>
                ) : (
                    <div>
                        <h4 className='flex mb20'>
                            Incoming change
                        </h4>
                        <FeedTable orders={post.ordersList.portfolioData} />
                        <CopyPostProfitCard
                            profitData={profit}
                            isToday={isToday}
                            untilDate={convertDateToReadableStr(post.time)}
                        />
                        <FeedConfirmChanges
                            isConfirmed={isCopyPortfolioConfirmed}
                            handleConfirmation={handleCopyPortfolioConfirmation}
                            selectedPortfolio={selectedPortfolio}
                            handleCloseCopyPortfolioModal={handleCloseCopyPortfolioModal}
                            handleSaveCopiedPortfolio={handleSaveCopiedPortfolio}
                            orders={post.ordersList}
                        />
                    </div>
                )}
            </div>
        );
    };


    // const handleTextAreaChange = (ev) => {
    //     setCommentText(ev.target.value);
    // };

    const [isToday, setIsToday] = useState(false);
    useEffect(() => {
        const postTime = new Date(post.time);
        const today = new Date();
        if (
            postTime.getDate() === today.getDate() &&
            postTime.getMonth() === today.getMonth() &&
            postTime.getFullYear() === today.getFullYear()
        ) {
            setIsToday(true);
        } else {
            setIsToday(false);
        }
    }, []);

    const SincePostProfit = () => {
        const postReturn = getPostReturn();
        const resStr = postReturn.returnSincePosted.toFixed(1);
        const formattedResStr = resStr === '0.0' ? '0' : resStr;
        const daysStr = postReturn.days;
        return (
            <div className="post-return-str-container flex align-center">
                <div
                    className='post-return-icon'
                    style={{ color: postReturn.returnSincePosted > 0 ? '#5c9ead' : '#e39774' }}
                >
                    {/* {sincePostProfitIcon} */}
                    {postReturn.returnSincePosted > 0 ? arrowUpIcon : arrowDownIcon}
                </div>
                <div className="flex-column">
                    <div className='post-return-str'>
                        <span className='post-return-str-span'>{formattedResStr}%</span> return in <span className='post-return-str-span'>{daysStr}</span> days
                    </div>
                    <div
                        className='post-return-str post-return-str-lower'>
                        from {isToday ? 'today' : convertDateToReadableStr(post.time)}
                    </div>
                </div>
            </div>
        )
    };

    const handleUsernameClick = (username) => {
        handleCloseModal();
        handleCloseCopyPortfolioModal();
        history.push(`/profile/portfolios/${username}`);
    };

    // console.log('post', post);

    const PostModal = () => {
        return (
            <>
                {
                    isPostModalOpen && (
                        <div className="post-modal-overlay" onClick={handleOverlayClick}>
                            <div className="post-modal-content card-container pos-rel">

                                <div className="post-modal-mobile-top ">
                                    <div className="flex align-center space-between" style={{ backgroundColor: '#ffffff' }}>
                                        <div className="flex column" style={{ width: '100%' }}>
                                            <div className='flex space-between'>
                                                <div className='flex'>
                                                    <div className="home-feed-post-user-img flex align-center">
                                                        <img
                                                            src={post.pngUrl ? post.pngUrl : defaultImg}
                                                            alt="User profile img"
                                                            className='feed-post-user-profile-img'
                                                            onError={(e) => { e.target.src = defaultImg; }}
                                                        />
                                                    </div>
                                                    <div className='flex align-center' style={{ width: '100%' }}>
                                                        <div className="">
                                                            <div
                                                                className='homepage-feed-user-name flex align-center'
                                                                onClick={() => handleUsernameClick(post.username)}
                                                            >{post.username}
                                                            </div>
                                                            <div className="homepage-feed-user-time flex align-center">{convertDateToReadableStr(post.time)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex'>
                                                    <div className='mr20'>
                                                        {
                                                            post.ordersList &&
                                                            <SincePostProfit />
                                                        }
                                                    </div>
                                                    <div
                                                        className='homepage-post-modal-close-btn mr20'
                                                        style={{ fontSize: '1.5rem' }}
                                                        onClick={handleCloseModal}
                                                    >
                                                        {closeIcon}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="post-modal-mobile-main">

                                    <div className="homepage-feed-user-text" style={{ marginTop: isMobileScreen ? '20px' : '80px' }}>{post.text}</div>

                                    <PostMainContent
                                        profit={post.ordersList ? post.ordersList.profit : null}
                                        selectedPortfolio={post.ordersList ? post.ordersList.selectedPortfolio : null}
                                    />

                                    <PostSocialBtns post={post} />

                                    <div className="homepage-post-modal-comments">
                                        {
                                            post.comments.map((comment, idx) => (
                                                <div className='homepage-post-modal-comment' key={idx}>
                                                    <div className="flex align-center" >
                                                        <div className="home-feed-post-user-img" >
                                                            <img
                                                                src={comment.pngUrl ? comment.pngUrl : defaultImg}
                                                                alt="User profile img"
                                                                className='feed-post-user-profile-img'
                                                                onError={(e) => { e.target.src = defaultImg; }}
                                                            />
                                                        </div>
                                                        <div className='flex column justify-center'>
                                                            <div
                                                                className="homepage-feed-user-name homepage-post-modal-user-name flex align-end"
                                                                onClick={() => handleUsernameClick(comment.username)}
                                                            >{comment.username}</div>
                                                            <div className='homepage-feed-user-time'>{convertDateToReadableStr(comment.time)}</div>
                                                        </div>

                                                    </div>
                                                    <div className="homepage-post-modal-user-comment">
                                                        {comment.comment}
                                                    </div>
                                                    <div className='homepage-post-modal-post-reaction-container flex'>
                                                        <div className='homepage-post-modal-post-reaction-item' title='Like'>
                                                            {unfollowIcon}
                                                        </div>
                                                        <div className='homepage-post-modal-post-reaction-item' title='Comment'>
                                                            {commentIcon}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="post-modal-add-comment-container post-modal-mobile-bottom">
                                    <FeedPostModalCommentTextarea
                                        postId={post.postid}
                                        onPostComment={onPostComment}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
            </>
        );
    };

    const CopyPortfolioModal = ({ post }) => {
        const profit = post.ordersList ? post.ordersList.profit : null
        return (
            <>
                {
                    isCopyPortfolioModalOpen && (
                        <div className="post-modal-overlay" onClick={handleOverlayClick}>
                            {loggedInUser ? (
                                <div className="post-modal-content copy-portfolio-modal-content card-container">
                                    <CopyPortfolioContent post={post} profit={profit} />
                                </div>
                            ) : (
                                <div className="post-modal-content card-container">
                                    <p>You must log in to complete this action.</p>
                                    <div className="flex">
                                        <div className='btn' onClick={handleLoginClick}>Login</div>
                                        <div className="btn" onClick={handleSignupClick}>Signup</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                }
            </>
        );
    };

    const calculateDaysPassed = (postTime) => {
        const currentTime = new Date();
        const postDate = new Date(postTime);
        const timeDifference = currentTime - postDate;
        const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return daysPassed;
    };

    const getPostReturn = () => {

        const portfolioData = post.ordersList.portfolioData;
        const daysPassed = calculateDaysPassed(post.time);
        const sumData = portfolioData.reduce((result, item) => {
            const amount = parseFloat(item.amount);
            // const price = parseFloat(lastPrices[`${item.symbol}=company`].lastPrice);
            const price = parseFloat(
                lastPrices[`${item.symbol}=company`]?.lastPrice ?? lastPrices[`${item.symbol}=company`]?.price
            );
            if (!isNaN(amount) && !isNaN(price)) {
                result.sum += amount * price;
            } else {
                result.errorSymbols.push(item.symbol);
            };
            return result;
        }, { sum: 0, errorSymbols: [] });

        const postWorth = parseFloat(post.ordersList.profit.post_worth);

        const returnSincePosted = !isNaN(postWorth) ? ((sumData.sum / postWorth) - 1) * 100 : null;

        return { days: daysPassed, returnSincePosted, errorSymbols: sumData.errorSymbols };

    };

    const handleError = (e) => {
        e.target.onerror = null; // Prevents infinite loop in case defaultImg fails too
        e.target.src = defaultImg;
    };

    return (
        <>
            <div className="homepage-feed-post card-container">
                {/* <div className="flex pb10 align-center space-between"> */}
                <div className={`flex ${isMiniScreen ? 'column' : 'align-center space-between pb10'}`} >
                    <div className='flex align-center'>
                        <div className="home-feed-post-user-img">
                            <img
                                src={post.pngUrl ? post.pngUrl : defaultImg}
                                alt="User profile img"
                                className="feed-post-user-profile-img"
                                onError={(e) => { e.target.src = defaultImg; }}
                            />

                        </div>
                        <div className='flex column'>
                            <div
                                className='homepage-feed-user-name'
                                onClick={() => handleUsernameClick(post.username)}
                            >
                                {post.username}
                            </div>
                            <div className="homepage-feed-user-time">{convertDateToReadableStr(post.time)}</div>
                        </div>
                    </div>
                    <div className={`flex ${isMiniScreen ? 'flex-end' : ''}`}>
                        {
                            post.ordersList &&
                            <SincePostProfit />
                        }
                    </div>
                </div>
                <div className="homepage-feed-user-text">{post.text}</div>

                <PostMainContent
                    profit={post.ordersList ? post.ordersList.profit : null}
                    selectedPortfolio={post.ordersList ? post.ordersList.selectedPortfolio : null}
                />
                <PostSocialBtns post={post} />
            </div>
            <PostModal />
            <CopyPortfolioModal post={post} />
        </>
    )
}

export default HomepageFeedPost;