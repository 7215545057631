const MacroSlide = () => {
    return (
        <>
            <h2 className="app-tutorial-slide-title">Demo 3 - Exploring the Macro Page:</h2>

            <p className="app-tutorial-p">
                Navigate to the Macro Page and select a topic of interest. <br />
                Explore the different pages available for macroeconomic analysis, such as inflation and interest rates.
            </p>

            <p className="app-tutorial-p-strong">
                Inflation Page:
            </p>
            <p className="app-tutorial-p">
                This page provides insights into key macroeconomic indicators, including the Fed Funds Effective Rate, Unemployment Rate, Yearly Change in Money Supply, Yearly Inflation, and market-based inflation expectations. <br />
                Visualize historical trends through charts, tracking indicators over time to understand the economic landscape. <br />
                A comprehensive overview of factors influencing inflation is presented.
            </p>

            <div className="flex justify-center">
                <img src="/img/tutorial/macro/macro-inflation.png" alt="Macro - Inflation "  className="tutorial-page-img"/>
            </div>

            <p className="app-tutorial-p-strong">
                Exchange Rates (Currencies) Page:
            </p>
            <p className="app-tutorial-p">
                Explore and analyze currency exchange rates with customizable filters for time frames and currency pairs. <br />
                Toggle between relative and actual values for a nuanced analysis. <br />
                Charts visualize historical exchange rates, currency returns over time, and risk-adjusted returns (Sharpe ratios). <br />
                Additionally, access a Country Ratings table, offering the latest ratings from Moody's, S&P, and Fitch for various countries.
            </p>

            <div className="flex justify-center">
                <img src="/img/tutorial/macro/macro-currencies.png" alt="Macro -  Exchange Rates (Currencies)"  className="tutorial-page-img"/>
            </div>

            <p className="app-tutorial-p-strong">
                Indices Page:
            </p>
            <p className="app-tutorial-p">
                Tailor your analysis of stock market indices with filters for time frames, continents, countries, and index selection. <br />
                Choose between viewing index prices in relative terms or actual values. <br />
                Charts display historical prices, returns, and risk-adjusted returns (Sharpe ratios) of selected indices. <br />
                This page provides a comprehensive view of stock market performance across different regions and indices.
            </p>

            <div className="flex justify-center">
                <img src="/img/tutorial/macro/macro-indices.png" alt="Macro - Indices "  className="tutorial-page-img"/>
            </div>

            <p className="app-tutorial-p-strong">
                Commodities Page:
            </p>
            <p className="app-tutorial-p">
                Analyze commodities with customizable filters for time frames, commodity groups, and individual commodities. <br />
                Toggle between viewing prices in relative terms or actual values. <br />
                Charts illustrate historical prices and returns of selected commodities, along with risk-adjusted returns (Sharpe ratios). <br />
                This page offers insights into the performance of commodities across various categories.
            </p>

            <div className="flex justify-center">
                <img src="/img/tutorial/macro/macro-commodities.png" alt="Macro - Commodities "  className="tutorial-page-img"/>
            </div>

            <p className="app-tutorial-p">
                Each page is designed to cater to specific financial interests, providing users with a comprehensive overview and the tools needed for in-depth analysis in their chosen area of focus.
            </p>
        </>
    );
}

export default MacroSlide;