import React, { useEffect, useState } from "react";
import LabelColorContainer from "../../../shared/label-color-container/label-color-container";
import CurrenciesLinechart from "../macro-currencies/currencies-content/currencies-linechart";
import { useMediaQuery } from 'react-responsive';

const MacroLabelsLinechartContainer = ({
    labels,
    type,
    display,
    currenciessLineChart,
    currenciessLineChartTitle,
    selectedItem,
    hoverItem,
    onChangeSelectedItem,
}) => {

    const isMobile = useMediaQuery({ maxWidth: 920 });

    return (
        <div className={`findatasheets-container-item-top mb20 flex ${isMobile ? 'column' : ''}`}>
                {
                    labels &&
                    <LabelColorContainer
                        labels={labels}
                        type={type}
                        display={display}
                        selectedItem={selectedItem}
                        onChangeSelectedItem={onChangeSelectedItem}
                    />
                }
                <CurrenciesLinechart
                    currenciessLineChart={currenciessLineChart}
                    currenciessLineChartTitle={currenciessLineChartTitle}
                    selectedItem={selectedItem}
                    onChangeSelectedItem={onChangeSelectedItem}
                    hoverItem={hoverItem}
                />
            </div>
            );
}

            export default MacroLabelsLinechartContainer;