import React from "react";
import CardContainer from "../../../../cmps/card-container/card-container";

const CurrenciesLinechart = ({
    currenciessLineChart,
    currenciessLineChartTitle,
    onChangeSelectedItem,
    hoverItem
}) => {

    const toCamelCase = (str) => {
        if (typeof str !== 'string') return;
        const cleanedString = str.replace(/\s+/g, ''); // Remove spaces
        const camelCaseString = cleanedString.replace(/[-_]\w/g, match => match.charAt(1).toUpperCase());
        return camelCaseString.charAt(0).toLowerCase() + camelCaseString.slice(1);
    };

    const infoModalKey = toCamelCase(currenciessLineChartTitle);

    return (
        <CardContainer
            title={currenciessLineChartTitle}
            innerContent={currenciessLineChart}
            className="macro-graph"
            onChangeSelectedItem={onChangeSelectedItem}
            infoModalType={infoModalKey}
            selectedItem={hoverItem}
        />
    );
}

export default CurrenciesLinechart;