import React, { useState, useEffect } from 'react';
import { getFundMatchweek, setNewMatchweek } from '../../../../shared/actions/fund-actions';
import { switchIcon } from '../../../../shared/icons/icons';

const MatchweekCard = ({ fund, fundMembers, matchweek, matchweekIdx, handleChangeFundMatchweeks }) => {
    const [playingMembers, setPlayingMembers] = useState([]);
    const [reserveMembers, setReserveMembers] = useState([]);
    const [selectedIndices, setSelectedIndices] = useState([]);

    useEffect(() => {
        setPlayingMembers(fundMembers.slice(0, matchweek.participants));
        setReserveMembers(fundMembers.slice(matchweek.participants));
    }, [fundMembers, matchweek.participants]);

    const [err, setErr] = useState('');
    const [errIdx, setErrIdx] = useState(0);

    useEffect(() => {
        if (err === '') return;
        setTimeout(() => {
            setErr('');
        }, 5000);
    }, [errIdx]);

    const handleItemClick = (index, section) => {
        const newSelectedIndices = [...selectedIndices, { index, section }];
        setSelectedIndices(newSelectedIndices);

        if (newSelectedIndices.length === 2) {
            const [first, second] = newSelectedIndices;

            if (first.section === 'playing' && second.section === 'playing') {
                const updatedPlayingMembers = [...playingMembers];
                [updatedPlayingMembers[first.index], updatedPlayingMembers[second.index]] = [updatedPlayingMembers[second.index], updatedPlayingMembers[first.index]];
                setPlayingMembers(updatedPlayingMembers);
            } else if (first.section === 'reserves' && second.section === 'reserves') {
                const updatedReserveMembers = [...reserveMembers];
                [updatedReserveMembers[first.index], updatedReserveMembers[second.index]] = [updatedReserveMembers[second.index], updatedReserveMembers[first.index]];
                setReserveMembers(updatedReserveMembers);
            } else if (first.section === 'playing' && second.section === 'reserves') {
                const updatedPlayingMembers = [...playingMembers];
                const updatedReserveMembers = [...reserveMembers];
                const temp = updatedPlayingMembers[first.index];
                updatedPlayingMembers[first.index] = updatedReserveMembers[second.index];
                updatedReserveMembers[second.index] = temp;
                setPlayingMembers(updatedPlayingMembers);
                setReserveMembers(updatedReserveMembers);
            } else if (first.section === 'reserves' && second.section === 'playing') {
                const updatedPlayingMembers = [...playingMembers];
                const updatedReserveMembers = [...reserveMembers];
                const temp = updatedReserveMembers[first.index];
                updatedReserveMembers[first.index] = updatedPlayingMembers[second.index];
                updatedPlayingMembers[second.index] = temp;
                setPlayingMembers(updatedPlayingMembers);
                setReserveMembers(updatedReserveMembers);
            }

            setSelectedIndices([]);
        }
    };

    const onJoinMatchweek = async (participants, members, matchweekIdx) => {
        await setNewMatchweek(fund, participants, members);
        const matchweek = await getFundMatchweek(fund);
        handleChangeFundMatchweeks(matchweek);
    };

    const formatParticipants = (count) => {
        switch (count) {
            case 3:
            case '3':
                return '3 VS 3';
            case 5:
            case '5':
                return '5 VS 5';
            case 10:
            case '10':
                return '10 VS 10';
            default:
                return '';
        }
    };

    const MembersList = ({ list, title, section }) => {
        return (
            <>
                <p style={{ fontWeight: '600', marginBottom: '5px' }}>{title}:</p>
                {list.map((member, index) => (
                    <div
                        className={`matchweek-participants-table-item flex space-between ${selectedIndices.some(i => i.index === index && i.section === section) ? 'selected' : ''}`}
                        key={index}
                        onClick={() => handleItemClick(index, section)}
                    >
                        <div className='mr10'>
                            {member.username}
                        </div>
                        <div className='switch-icon'>
                            {switchIcon}
                        </div>
                    </div>
                ))}
            </>
        );
    };

    return (
        <>
            <div>
                <div className="flex column mb20">
                    <MembersList list={playingMembers} title={'Playing'} section="playing" />
                </div>

                {
                    reserveMembers.length > 0 && (
                        <div className="flex column">
                            <MembersList list={reserveMembers} title={'Reserves'} section="reserves" />
                        </div>
                    )
                }
            </div>

            <div className='flex column center-center'>
                <div className='matchweek-type mb20'>{matchweek.type}</div>
                <div className='matchweek-participants mb20'>{formatParticipants(matchweek.participants)}</div>
                <div className='matchweek-startdate flex justify-center' style={{ marginBottom: '5px', width: '200px' }}>Start: May 20, 09:30 AM (ET)</div>
                <div className='matchweek-enddate flex justify-center' style={{ width: '200px' }}>End: May 24, 04:00 PM (ET)</div>
            </div>

            <div className='flex align-end flex-end'>
                <div className="flex column" style={{ width: '100px', textAlign: 'center' }}>
                    {
                        errIdx === matchweekIdx &&
                        err &&
                        <div className='err-msg mb20'>{err}</div>
                    }
                    <div className='btn btn-border' onClick={() => onJoinMatchweek(+matchweek.participants, fundMembers, matchweekIdx)}>
                        Join
                    </div>
                </div>
            </div>
        </>
    );
};

export default MatchweekCard;
