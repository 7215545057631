import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { DropdownIndicator, customStyles as originalCustomStyles, customStyles } from "../../shared/dropdown-styles";
import { arrowDownIcon, dropdownIcon } from '../icons/icons';
import './dropdown-select.scss';

const PeriodDropdownSelect = ({ currFilter, onChangeFilter, optionsToDisplay }) => {

    const periodOptions = [
        { value: 'oneWeek', label: '1 Week' },
        { value: 'oneMonth', label: '1 Month' },
        { value: 'sixMonth', label: '6 Months' },
        { value: 'currentYear', label: 'YTD' },
        { value: 'oneYear', label: '1 Year' },
        { value: 'fiveYear', label: '5 Years' },
        { value: 'max', label: 'Max' },
    ];

    const [activeFilterLabel, setActiveFilterLabel] = useState(null);
    const [showOptions, setShowOptions] = useState(false); 

    useEffect(() => {
        const currFilterLabel = periodOptions.find(option => option.value === currFilter)?.label;
        setActiveFilterLabel(currFilterLabel);
        onHandleMouseLeave();
    }, [currFilter]);

    const onHandleMouseEnter = () => {
        setShowOptions(true);
    };

    const onHandleMouseLeave = () => {
        setShowOptions(false);
    };

    const handleChange = (selectedOption) => {
        onChangeFilter(selectedOption);
    }; 

    return (
        <div className="dropdown-select-filter-item"
            onMouseEnter={onHandleMouseEnter}
            onMouseLeave={onHandleMouseLeave}
        >
            {
                optionsToDisplay && <Select
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                    options={optionsToDisplay}
                    value={currFilter}
                    onChange={handleChange}
                    menuIsOpen={showOptions}
                    hideSelectedOptions={false}
                />
            }
            {
                activeFilterLabel && <div
                    className="dropdown-label flex align-center">
                    {activeFilterLabel}
                    <span
                        className='dropdown-indicator flex center-center'
                    >
                        {dropdownIcon}
                    </span>
                </div>
            }
        </div>
    );
}

export default PeriodDropdownSelect;