import React, { useState, useEffect } from "react";
import CardContainer from "../../../cmps/card-container/card-container";
import LineChart from "../../../shared/charts/line-chart/line-chart";
import LabelColorContainer from "../../../shared/label-color-container/label-color-container";
import { useMediaQuery } from 'react-responsive';
import PieChart from "../../../shared/charts/pie-chart/pie-chart";
import { formatNumberToDollarTableString, getRandomColor, hexToRGBA } from "../../../shared/global-color-functions";
import ComparisonBarcharts from "../comparison-barcharts/comparison-barcharts";
import { Helmet } from "react-helmet";
import { CompaniesGuide, SectorsGuide } from "../../../cmps/page-guide";
import { type } from "@testing-library/user-event/dist/type";
import { getPortfolioCompareChart } from "../../../shared/actions/portfolio-actions";
import { switchIcon } from "../../../shared/icons/icons";
import Select from 'react-select';
import PortfolioVOTChart from "../../../shared/charts/line-chart/portfolio-vot-linechart";

const PortfolioCompaniesSectorsPage = ({
    selectedFilter,
    companyData,
    sectorData,
    portfolioData,
    page,
}) => {

    const [portfolioSumPriceAmount, setPortfolioSumPriceAmount] = useState(0);

    // useEffect(() => {
    //   const totalPortfolioValue = portfolioData.reduce((total, item) => {
    //     return total + ( item.amount * item.price);
    //   }, 0);
    //   let totalWorthValue;
    //   if (companyData && companyData.piechart) {
    //     totalWorthValue = companyData.piechart.reduce((total, item) => total + +item.value, 0);
    //   } else if (sectorData && sectorData.piechart) {
    //     totalWorthValue = sectorData.piechart.reduce((total, item) => total + +item.value, 0);
    //   } else {
    //     console.log('No valid data available.');
    //   }
    //         const totalPortfolioProfitPercent = (((totalWorthValue / totalPortfolioValue) -1) * 100).toFixed(2);
    //   setPortfolioSumPriceAmount(totalPortfolioProfitPercent);
    // }, [portfolioData]);

    useEffect(() => {
        const totalPortfolioValue = portfolioData.reduce((total, item) => {
            return total + (item.amount * item.price);
        }, 0);
        let totalWorthValue;
        if (companyData && companyData.piechart) {
            totalWorthValue = companyData.piechart.reduce((total, item) => total + +item.value, 0);
        } else if (sectorData && sectorData.piechart) {
            totalWorthValue = sectorData.piechart.reduce((total, item) => total + +item.value, 0);
        } else {
            console.log('No valid data available.');
        }
        const totalPortfolioProfitPercent = (((totalWorthValue / totalPortfolioValue) - 1) * 100).toFixed(2);
        setPortfolioSumPriceAmount(totalPortfolioProfitPercent);
    }, []);

    const isMediumScreen = useMediaQuery({ maxWidth: 1300 });
    const isMobile = useMediaQuery({ maxWidth: 920 });

    const [colorLabelsData, setColorLabelsData] = useState(null);
    const [piechartData, setPiechartData] = useState(null);
    const [linechartData, setLinechartData] = useState(null);
    const [barchartsData, setBarchartsData] = useState(null);
    const [pieChartSum, setPieChartSum] = useState(null);

    const [selectedItem, setSelectedItem] = useState(null);

    const [selectedCompareItems, setSelectedCompareItems] = useState([]);
    const [comparedItems, setComparedItems] = useState({});
    const onChangeSelectedCompareItem = (items) => {
        setSelectedCompareItems(items);
    };
    const [comparedLinechartData, setComparedLinechartData] = useState({ labels: [], datasets: [] });

    const [lineToDisplay, setLineToDisplay] = useState(null);

    // useEffect(() => {
    //     if (page === 'companies') {
    //         if (!companyData) return;
    //         getCompanyCharts();
    //     }
    //     else if (page === 'sectors') {
    //         if (!sectorData) return;
    //         getSectorCharts();
    //     }
    // }, [selectedFilter, selectedItem]);

    const [shouldSeeComparedChart, setShouldSeeCompareChart] = useState(false);

    const handleChangeCompareChart = () => {
        setShouldSeeCompareChart(prevState => !prevState);
    };


    useEffect(() => {
        if (shouldSeeComparedChart) {
            setLineToDisplay(comparedLinechartData);
        } else {
            setLineToDisplay(linechartData);
        }
    }, [companyData, sectorData, portfolioData, shouldSeeComparedChart, selectedFilter, linechartData, comparedLinechartData]);

    useEffect(() => {
        if (page === 'companies') {
            if (!companyData) return;
            getCompanyCharts();
        }
        else if (page === 'sectors') {
            if (!sectorData) return;
            getSectorCharts();
        }
    }, [selectedFilter]);

    const calculatePiechartSum = (pieChart) => {
        return pieChart.reduce((sum, item) => sum + parseFloat(item.value), 0);
    };

    const getCompanyCharts = () => {
        const filteredPieChart = companyData.piechart.filter((item) => {
            return selectedFilter.sectors.includes(item.sector) && selectedFilter.companies.includes(item.symbol);
        });
        const filteredLineChart = companyData.valueOverTime.filter((item) => {
            return selectedFilter.sectors.includes(item.sector) && selectedFilter.companies.includes(item.symbol);
        });

        const filteredBarCharts = companyData.barcharts[selectedFilter.period.value].filter((chartData) => {
            return selectedFilter.sectors.includes(chartData.sector) && selectedFilter.companies.includes(chartData.symbol);
        });

        const labelSymbolArray = filteredBarCharts.map((chartData) => {
            return {
                symbol: chartData.symbol,
                color: chartData.color,
                label: chartData.symbol
            };
        });

        const sum = calculatePiechartSum(filteredPieChart);
        setPieChartSum(sum);

        setColorLabelsData(labelSymbolArray);

        const sortedBarCharts = [...filteredBarCharts];

        // Sort by return value
        sortedBarCharts.sort((a, b) => +b.return - +a.return);

        const sortedBarChartsByReturn = [...sortedBarCharts].sort((a, b) => +b.return - +a.return);

        const returnData = {
            labels: sortedBarChartsByReturn.map(entry => entry.symbol),
            names: sortedBarChartsByReturn.map(entry => entry.company),
            hover: [],
            datasets: [
                {
                    label: "Return Ratio",
                    data: sortedBarChartsByReturn.map(entry => +entry.return),
                    backgroundColor: sortedBarChartsByReturn.map(entry =>
                        selectedItem === null
                            ? +entry.return > 0
                                ? "#5c9ead"
                                : "#e39774"
                            : selectedItem === entry.symbol
                                ? +entry.return > 0
                                    ? "#5c9ead"
                                    : "#e39774"
                                : +entry.return > 0
                                    ? "rgba(92, 158, 173, 0.4)"
                                    : "rgba(227, 151, 116, 0.4)"
                    ),
                    borderColor: sortedBarChartsByReturn.map(entry =>
                        selectedItem === null
                            ? +entry.return > 0
                                ? "#5c9ead"
                                : "#e39774"
                            : selectedItem === entry.symbol
                                ? +entry.return > 0
                                    ? "#5c9ead"
                                    : "#e39774"
                                : +entry.return > 0
                                    ? "rgba(92, 158, 173, 0.4)"
                                    : "rgba(227, 151, 116, 0.4)"
                    ),


                },
            ],
        };

        const sharpeData = {
            labels: sortedBarChartsByReturn.map(entry => entry.symbol),
            names: sortedBarChartsByReturn.map(entry => entry.company),
            hover: [],
            datasets: [
                {
                    label: "Sharpe Ratio",
                    data: sortedBarChartsByReturn.map(entry => +entry.sharpe),
                    backgroundColor: sortedBarChartsByReturn.map(entry =>
                        selectedItem === null
                            ? +entry.sharpe > 0
                                ? "#5c9ead"
                                : "#e39774"
                            : selectedItem === entry.symbol
                                ? +entry.sharpe > 0
                                    ? "#5c9ead"
                                    : "#e39774"
                                : +entry.sharpe > 0
                                    ? "rgba(92, 158, 173, 0.4)"
                                    : "rgba(227, 151, 116, 0.4)"
                    ),
                    borderColor: sortedBarChartsByReturn.map(entry =>
                        selectedItem === null
                            ? +entry.sharpe > 0
                                ? "#5c9ead"
                                : "#e39774"
                            : selectedItem === entry.symbol
                                ? +entry.sharpe > 0
                                    ? "#5c9ead"
                                    : "#e39774"
                                : +entry.sharpe > 0
                                    ? "rgba(92, 158, 173, 0.4)"
                                    : "rgba(227, 151, 116, 0.4)"
                    ),
                },
            ],
        };

        const infoData = {
            labels: sortedBarChartsByReturn.map(entry => entry.symbol),
            names: sortedBarChartsByReturn.map(entry => entry.company),
            hover: [],
            datasets: [
                {
                    label: "Information Ratio",
                    data: sortedBarChartsByReturn.map(entry => +entry.info),
                    backgroundColor: sortedBarChartsByReturn.map(entry =>
                        selectedItem === null
                            ? +entry.info > 0
                                ? "#5c9ead"
                                : "#e39774"
                            : selectedItem === entry.symbol
                                ? +entry.info > 0
                                    ? "#5c9ead"
                                    : "#e39774"
                                : +entry.info > 0
                                    ? "rgba(92, 158, 173, 0.4)"
                                    : "rgba(227, 151, 116, 0.4)"
                    ),
                    borderColor: sortedBarChartsByReturn.map(entry =>
                        selectedItem === null
                            ? +entry.info > 0
                                ? "#5c9ead"
                                : "#e39774"
                            : selectedItem === entry.symbol
                                ? +entry.info > 0
                                    ? "#5c9ead"
                                    : "#e39774"
                                : +entry.info > 0
                                    ? "rgba(92, 158, 173, 0.4)"
                                    : "rgba(227, 151, 116, 0.4)"
                    ),
                },
            ],
        };

        // sort by std
        sortedBarCharts.sort((a, b) => +b.std - +a.std);

        const stdData = {
            labels: sortedBarCharts.map(entry => entry.symbol),
            names: sortedBarChartsByReturn.map(entry => entry.company),
            hover: [],
            datasets: [
                {
                    label: "Standard Deviation Ratio",
                    data: sortedBarCharts.map(entry => +entry.std),
                    backgroundColor: sortedBarChartsByReturn.map(entry =>
                        selectedItem === null
                            ? +entry.std > 0
                                ? "#5c9ead"
                                : "#e39774"
                            : selectedItem === entry.symbol
                                ? +entry.std > 0
                                    ? "#5c9ead"
                                    : "#e39774"
                                : +entry.std > 0
                                    ? "rgba(92, 158, 173, 0.4)"
                                    : "rgba(227, 151, 116, 0.4)"
                    ),
                    borderColor: sortedBarChartsByReturn.map(entry =>
                        selectedItem === null
                            ? +entry.std > 0
                                ? "#5c9ead"
                                : "#e39774"
                            : selectedItem === entry.symbol
                                ? +entry.std > 0
                                    ? "#5c9ead"
                                    : "#e39774"
                                : +entry.std > 0
                                    ? "rgba(92, 158, 173, 0.4)"
                                    : "rgba(227, 151, 116, 0.4)"
                    ),
                },
            ],
        };

        const betaData = {
            labels: sortedBarCharts.map(entry => entry.symbol),
            names: sortedBarChartsByReturn.map(entry => entry.company),
            hover: [],
            datasets: [
                {
                    label: "Beta Ratio",
                    data: sortedBarCharts.map(entry => +entry.beta),
                    backgroundColor: sortedBarChartsByReturn.map(entry =>
                        selectedItem === null
                            ? +entry.beta > 0
                                ? "#5c9ead"
                                : "#e39774"
                            : selectedItem === entry.symbol
                                ? +entry.beta > 0
                                    ? "#5c9ead"
                                    : "#e39774"
                                : +entry.beta > 0
                                    ? "rgba(92, 158, 173, 0.4)"
                                    : "rgba(227, 151, 116, 0.4)"
                    ),
                    borderColor: sortedBarChartsByReturn.map(entry =>
                        selectedItem === null
                            ? +entry.beta > 0
                                ? "#5c9ead"
                                : "#e39774"
                            : selectedItem === entry.symbol
                                ? +entry.beta > 0
                                    ? "#5c9ead"
                                    : "#e39774"
                                : +entry.beta > 0
                                    ? "rgba(92, 158, 173, 0.4)"
                                    : "rgba(227, 151, 116, 0.4)"
                    ),
                },
            ],
        };

        const posDaysData = {
            labels: sortedBarCharts.map(entry => entry.symbol),
            names: sortedBarChartsByReturn.map(entry => entry.company),
            hover: [],
            datasets: [
                {
                    label: "Positive Days Ratio",
                    data: sortedBarCharts.map(entry => +entry.positiveDays),
                    backgroundColor: sortedBarChartsByReturn.map(entry =>
                        selectedItem === null
                            ? +entry.positiveDays > 0
                                ? "#5c9ead"
                                : "#e39774"
                            : selectedItem === entry.symbol
                                ? +entry.positiveDays > 0
                                    ? "#5c9ead"
                                    : "#e39774"
                                : +entry.positiveDays > 0
                                    ? "rgba(92, 158, 173, 0.4)"
                                    : "rgba(227, 151, 116, 0.4)"
                    ),
                    borderColor: sortedBarChartsByReturn.map(entry =>
                        selectedItem === null
                            ? +entry.positiveDays > 0
                                ? "#5c9ead"
                                : "#e39774"
                            : selectedItem === entry.symbol
                                ? +entry.positiveDays > 0
                                    ? "#5c9ead"
                                    : "#e39774"
                                : +entry.positiveDays > 0
                                    ? "rgba(92, 158, 173, 0.4)"
                                    : "rgba(227, 151, 116, 0.4)"
                    ),
                },
            ],
        };

        setBarchartsData({ return: returnData, sharpe: sharpeData, info: infoData, std: stdData, beta: betaData, positiveDays: posDaysData });

        // pie chart
        const pieData = {
            labels: [],
            hover: [],
            datasets: [{
                label: "Pie Chart",
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 2,
                borderRadius: 6
            }]
        };
        filteredPieChart.forEach((item) => {

            let backgroundColor;
            if (selectedItem === null) {
                backgroundColor = item.color;
            } else {
                backgroundColor =
                    selectedItem !== item.symbol
                        ? hexToRGBA(item.color)
                        : item.color;
            }

            const symbol = item.symbol;
            const label = `${item.symbol} - ${item.company}`;
            const sector = item.sector;
            const value = +item.value;
            pieData.labels.push(symbol);
            pieData.hover.push([label, sector]);
            pieData.datasets[0].data.push(value);
            pieData.datasets[0].backgroundColor.push(backgroundColor);
            pieData.datasets[0].borderColor.push('#ffffff');
            pieData.datasets[0].borderWidth = 2;
        });

        setPiechartData(pieData);

        // line chart 
        const lineData = {
            labels: [],
            datasets: []
        };

        filteredLineChart.forEach((item) => {

            let backgroundColor;
            if (selectedItem === null) {
                backgroundColor = item.color;
            } else {
                backgroundColor =
                    selectedItem !== item.symbol
                        ? hexToRGBA(item.color)
                        : item.color;
            }

            const dataset = {
                label: item.symbol,
                data: item.value.map((dataPoint) => ({
                    x: dataPoint.date,
                    y: +dataPoint.y,
                })),
                backgroundColor: backgroundColor,
                borderColor: backgroundColor,
                borderWidth: selectedItem === item.symbol ? 5 : 3,
                fill: false
            };
            lineData.datasets.push(dataset);
        });

        setLinechartData(lineData);
    };

    const getSectorCharts = () => {

        const filteredPieChart = sectorData.piechart
            .filter((item) => selectedFilter.sectors.includes(item.sector))
            .filter((item) => selectedFilter.companies.includes(item.companies[0]));
        const filteredLineChart = sectorData.valueOverTime.filter((item) => {
            const matchingSector = selectedFilter.sectors.includes(item.sector);
            const matchingCompanies = item.companies.some((c) => selectedFilter.companies.includes(c));

            return matchingSector && matchingCompanies;
        });

        const sortedPieChart = filteredPieChart.sort((a, b) => parseInt(b.value, 10) - parseInt(a.value, 10));
        const sortedLineChart = filteredLineChart.sort((a, b) => new Date(a.x) - new Date(b.x));

        const filteredBarCharts = sectorData.barcharts[selectedFilter.period.value].filter((chartData) => {
            const matchingSector = selectedFilter.sectors.includes(chartData.sector);
            const matchingCompanies = chartData.companies.some(c => selectedFilter.companies.includes(c));

            return matchingSector && matchingCompanies;
        });

        const labelSymbolArray = filteredBarCharts.map((chartData) => {
            return {
                symbol: chartData.sector,
                color: chartData.color,
                label: chartData.sector
            };
        });

        const sum = calculatePiechartSum(filteredPieChart);
        setPieChartSum(sum);
        setColorLabelsData(labelSymbolArray);

        const sortedBarCharts = [...filteredBarCharts];

        // Sort by return value
        sortedBarCharts.sort((a, b) => +b.return - +a.return);

        const sortedBarChartsByReturn = [...sortedBarCharts].sort((a, b) => +b.return - +a.return);
        const returnData = {
            labels: sortedBarChartsByReturn.map(entry => entry.sector),
            names: sortedBarChartsByReturn.map(entry => entry.sector),
            hover: [],
            datasets: [
                {
                    label: "Return Ratio",
                    data: sortedBarChartsByReturn.map(entry => +entry.return),
                    backgroundColor: sortedBarChartsByReturn.map(entry => +entry.return > 0 ? "#5c9ead" : "#e39774"),
                    borderColor: sortedBarChartsByReturn.map(entry => +entry.return > 0 ? "#5c9ead" : "#e39774"),
                },
            ],
        };

        const sharpeData = {
            labels: sortedBarChartsByReturn.map(entry => entry.sector),
            names: sortedBarChartsByReturn.map(entry => entry.sector),
            hover: [],
            datasets: [
                {
                    label: "Sharpe Ratio",
                    data: sortedBarChartsByReturn.map(entry => +entry.sharpe),
                    backgroundColor: sortedBarChartsByReturn.map(entry => +entry.sharpe > 0 ? "#5c9ead" : "#e39774"),
                    borderColor: sortedBarChartsByReturn.map(entry => +entry.sharpe > 0 ? "#5c9ead" : "#e39774"),
                },
            ],
        };

        // sort by std
        sortedBarCharts.sort((a, b) => +b.std - +a.std);

        const stdData = {
            labels: sortedBarCharts.map(entry => entry.sector),
            names: sortedBarChartsByReturn.map(entry => entry.sector),
            hover: [],
            datasets: [
                {
                    label: "Standard Deviation Ratio",
                    data: sortedBarCharts.map(entry => +entry.std),
                    backgroundColor: sortedBarCharts.map(entry => +entry.std > 0 ? "#5c9ead" : "#e39774"),
                    borderColor: sortedBarCharts.map(entry => +entry.std > 0 ? "#5c9ead" : "#e39774"),
                },
            ],
        };

        const posDaysData = {
            labels: sortedBarCharts.map(entry => entry.sector),
            names: sortedBarChartsByReturn.map(entry => entry.sector),
            hover: [],
            datasets: [
                {
                    label: "Positive Days Ratio",
                    data: sortedBarCharts.map(entry => +entry.positiveDays),
                    backgroundColor: sortedBarCharts.map(entry => +entry.positiveDays > 0 ? "#5c9ead" : "#e39774"),
                    borderColor: sortedBarCharts.map(entry => +entry.positiveDays > 0 ? "#5c9ead" : "#e39774"),
                },
            ],
        };

        setBarchartsData({ return: returnData, sharpe: sharpeData, std: stdData, positiveDays: posDaysData });

        const lineData = {
            labels: [],
            datasets: []
        };

        sortedLineChart.forEach((item) => {

            let backgroundColor;
            if (selectedItem === null) {
                backgroundColor = item.color;
            } else {
                backgroundColor =
                    selectedItem !== item.sector
                        ? hexToRGBA(item.color)
                        : item.color;
            }

            const dataset = {
                label: item.sector,
                data: item.value.map((dataPoint) => ({
                    x: dataPoint.date,
                    y: +dataPoint.y,
                })),
                backgroundColor: backgroundColor,
                borderColor: backgroundColor,
                borderWidth: item.sector === selectedItem ? 5 : 3,
                fill: false
            };
            lineData.datasets.push(dataset);
        });

        setLinechartData(lineData);

        const pieData = {
            labels: [],
            hover: [],
            datasets: [{
                label: "",
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderRadius: 6
            }]
        };
        sortedPieChart.forEach((item) => {

            let backgroundColor;
            if (selectedItem === null) {
                backgroundColor = item.color;
            } else {
                backgroundColor =
                    selectedItem !== item.sector
                        ? hexToRGBA(item.color)
                        : item.color;
            }

            const sector = item.sector;
            const value = +item.value;
            pieData.labels.push(sector);
            pieData.hover.push([sector]);
            pieData.datasets[0].data.push(value);
            pieData.datasets[0].backgroundColor.push(backgroundColor);
            pieData.datasets[0].borderColor.push('#ffffff');
            pieData.datasets[0].borderWidth = 2;
        });
        setPiechartData(pieData);
    };

    // const onChangeSelectedItem = (item) => {
    //     setSelectedItem(item);
    // };

    const onChangeSelectedItem = (item) => {
        // setSelectedItem(item);
    };

    const getSelectedItemHover = () => {
        if (!selectedItem) return;
        if (page === 'companies') {
            const selectedItemObj = companyData.piechart.find(item => item.symbol === selectedItem);
            if (selectedItemObj) {
                return `(${selectedItemObj.symbol}) ${selectedItemObj.company}`;
            }
        } else if (page === 'sectors') {
            const selectedItemObj = companyData.piechart.find(item => item.sector === selectedItem);
            if (selectedItemObj) {
                return `${selectedItemObj.sector}`;
            }
        };
    };

    const valueOverTimeChart = (
        <>
            {
                colorLabelsData &&
                lineToDisplay && (
                    <div style={{ width: '99.9%', height: '90%' }}>
                        <PortfolioVOTChart
                            chartData={lineToDisplay}
                            type='dollar'
                            shouldSeeComparedChart={shouldSeeComparedChart}
                        />
                    </div>
                )}
        </>
    );

    const formattedSum = formatNumberToDollarTableString(pieChartSum);

    const piechartTitle = (
        <>
            {/* <h4 className='portfolio-chart-title'>{page === 'companies' ? 'Companies Values' : ' Sectors Values '}</h4> */}
            <h4 className='portfolio-chart-title'>{page === 'companies' ? `Companies Values - ${formattedSum}` : `Sectors Values - ${formattedSum}`}</h4>
        </>
    );

    const piechartContent = (
        <>
            {
                colorLabelsData &&
                linechartData &&
                <div className="flex column justify-center align-center" style={{ height: '90%' }}>
                    <PieChart
                        chartData={piechartData}
                        type='dollar'
                        // onChangeSelectedItem={onChangeSelectedItem}
                        pieChartSum={pieChartSum}
                        portfolioSumPriceAmount={portfolioSumPriceAmount}
                    />
                </div>
            }
        </>
    );

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const orders = portfolioData;
    //         const symbol = selectedCompareItems[selectedCompareItems.length - 1]?.value || '';
    //         const type = selectedCompareItems[selectedCompareItems.length - 1]?.type || '';
    //         if (symbol && type) {
    //             const res = await getPortfolioCompareChart(orders, symbol, type);
    //             res.symbol = symbol;
    //             res.label = symbol;
    //             const selectedItem = selectedCompareItems.find(item => item.value === res.symbol);
    //             selectedItem ? res.color = selectedItem.color : res.color = getRandomColor();
    //             setComparedItems(prevItems => {
    //                 if (prevItems[symbol]) {
    //                     return prevItems;
    //                 } else {
    //                     return { ...prevItems, [symbol]: res };
    //                 }
    //             });
    //         }
    //     };
    //     fetchData();
    // }, [selectedCompareItems]);

    useEffect(() => {
        const dateSums = {};
        let data = [];

        // Check the page variable to determine which data to use
        if (page === 'companies') {
            data = companyData.valueOverTime;
        } else if (page === 'sectors') {
            data = sectorData.valueOverTime;
        }
        // Iterate over the selected data and accumulate sums
        data.forEach(entry => {
            entry.value.forEach(({ date, y }) => {
                // Convert y to a number and add it to the sum for the corresponding date
                dateSums[date] = (dateSums[date] || 0) + parseFloat(y);
            });
        });
        // Convert dateSums object to an array of { date, y } objects
        const sumPortfolioLine = Object.entries(dateSums).map(([date, y]) => ({ date, y }));
        // Sort the result by date
        sumPortfolioLine.sort((a, b) => new Date(a.date) - new Date(b.date));
        // Update comparedItems state
        setComparedItems(prevItems => ({
            ...prevItems,
            portfolio: { line: sumPortfolioLine, label: 'My Portfolio', symbol: null, color: '#5c9ead', barCharts: {} }
        }));

    }, [page, companyData, sectorData]);


    // useEffect(() => {
    //     const transformedData = {
    //         labels: [],
    //         datasets: []
    //     };

    //     // Get the symbols from selectedCompareItems
    //     const selectedSymbols = selectedCompareItems.map(item => item.value);

    //     for (const [symbol, data] of Object.entries(comparedItems)) {
    //         // Check if the symbol is in selectedSymbols or if it's 'portfolio'
    //         if (selectedSymbols.includes(symbol) || symbol === 'portfolio') {
    //             const dataset = {
    //                 label: data.label || symbol,
    //                 symbol: symbol,
    //                 data: [],
    //                 backgroundColor: data.color,
    //                 borderColor: data.color,
    //                 borderWidth: 3,
    //                 fill: false
    //             };

    //             data.line.forEach(point => {
    //                 const { date, y } = point;
    //                 dataset.data.push({ x: date, y: parseFloat(y) });
    //             });

    //             transformedData.datasets.push(dataset);
    //         }
    //     }
    //     setComparedLinechartData(transformedData);
    // }, [selectedCompareItems, comparedItems]);




    useEffect(() => {
        const fetchDataAndTransformData = async () => {
            const orders = portfolioData;
            const symbol = selectedCompareItems[selectedCompareItems.length - 1]?.value || '';
            const type = selectedCompareItems[selectedCompareItems.length - 1]?.type || '';
            if (symbol && type) {
                const res = await getPortfolioCompareChart(orders, symbol, type);
                res.symbol = symbol;
                res.label = symbol;
                const selectedItem = selectedCompareItems.find(item => item.value === res.symbol);
                selectedItem ? res.color = selectedItem.color : res.color = getRandomColor();
                // Combine the fetched data with the existing comparedItems state
                setComparedItems(prevItems => {
                    if (prevItems[symbol]) {
                        return prevItems;
                    } else {
                        // Create a new object by spreading the previous items and adding the new item
                        return { ...prevItems, [symbol]: res };
                    }
                });
            }
        };
        fetchDataAndTransformData();
    }, [selectedCompareItems, portfolioData]);

    useEffect(() => {
        const transformedData = {
            labels: [],
            datasets: []
        };
        const selectedSymbols = selectedCompareItems.map(item => item.value);
        // Iterate over the comparedItems state to transform the data into chart datasets
        for (const [symbol, data] of Object.entries(comparedItems)) {
            // Check if the symbol is in selectedSymbols or if it's 'portfolio'
            if (selectedSymbols.includes(symbol) || symbol === 'portfolio') {
                const dataset = {
                    label: data.label || symbol,
                    symbol: symbol,
                    data: [],
                    backgroundColor: data.color,
                    borderColor: data.color,
                    borderWidth: 3,
                    fill: false
                };
                data.line.forEach(point => {
                    const { date, y } = point;
                    dataset.data.push({ x: date, y: parseFloat(y) });
                });
                transformedData.datasets.push(dataset);
            }
        }
        // Update the comparedLinechartData state with the transformed data
        setComparedLinechartData(transformedData);
    }, [selectedCompareItems, comparedItems]);


    const isTablet = useMediaQuery({ maxWidth: 920 });

    const compareOptions = [
        { color: '#d62728', type: 'index', value: 'GSPC', label: '(GSPC) S&P 500' },
        { color: '#e377c2', type: 'index', value: 'IXIC', label: '(IXIC) NASDAQ Composite' },
        { color: '#7f7f7f', type: 'commodity', value: 'RB', label: '(RB) RBOB Gasoline' },
        { color: '#bcbd22', type: 'commodity', value: 'GC', label: '(RC) Gold' },
        // { color: '#1f77b4', type: 'sector', value: 'Technology', label: 'Technology' },
        // { color: '#2ca02c', type: 'sector', value: 'Healthcare', label: 'Healthcare' },
        { color: '#9467bd', type: 'company', value: 'AAPL', label: '(AAPL) Apple Inc.' },
        { color: '#8c564b', type: 'company', value: 'NVDA', label: '(NVDA) NVIDIA Corporation' },
        // { color: '#17becf', type: 'crypto', value: 'BTC', label: '(BTC) Bitcoin' },
        // { color: '#ff7f0e', type: 'crypto', value: 'ETH', label: '(ETH) Ethereum' }
    ];

    const costumeStyles = {
        // dropdownIndicator: () => ({ color: '#484848' }),
        // dropdownIndicator: {loginIcon},
        menu: (base) => ({
            ...base,
            width: isMobile ? '250px' : '300px',
            marginTop: '10px',
            left: '49%',
            transform: 'translateX(-50%)',
            borderRadius: '8px',
            zIndex: 100,
        }),
        option: (base, state) => ({
            ...base,
            width: '100%',
            textAlign: 'start',
            padding: '10px',
            backgroundColor: '#ffffff',
            color: state.isSelected ? '#326fb5' : '#484848',
            fontWeight: state.isSelected ? '600' : '500',
            borderBottom: state.isFocused && !state.isLast ? '2px solid #EEEEEE' : 'none',
            '&:not(:last-child)': {
                borderBottom: '2px solid #EEEEEE',
            },
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#f1f6fc'
            },
        }),
        groupHeading: (provided) => ({
            ...provided,
            color: '#484848',
            marginBottom: '2px',
            backgroundColor: '#ffffff',
            fontWeight: '600'
        }),
        group: (provided) => ({
            ...provided,
            background: '#ffffff'
        }),
        input: (baseStyles) => ({
            ...baseStyles,
            color: '#484848',
            paddingLeft: '10px'
        }),
        control: (baseStyles) => ({
            ...baseStyles,
            width: searchWidth,
            margin: 'auto',
            borderRadius: '40px',
            border: '1px solid #484848',
            color: '#484848',
            fontSize: '1rem',
            backgroundColor: '#eeeeee',
            boxShadow: 'none',
            // marginBottom: '20px',
            // height: 30,
            // minHeight: 30,
            // height: 40,
            minHeight: 40,
            '&:hover': {
                borderColor: '#484848',
            },
            '&:focus': {
                borderColor: '#484848',
                outline: 'none',
            },
        }),
        singleValue: (base, state) => ({
            ...base,
            color: '#484848',
            paddingLeft: '10px'
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            color: '#484848',
            paddingLeft: '10px'
        }),
    };

    const CustomMultiValue = ({ data, removeProps }) => (
        <div className="custom-multi-value">
            {data.value}
            <span {...removeProps}>×</span>
        </div>
    );

    const handleChange = (selectedOption) => {
        onChangeSelectedCompareItem(selectedOption);
    };

    // const searchWidth = isMobile ? '250px' : isTablet ? '400px' : '350px';
    const searchWidth = 'calc(100% - 20px)';


    const portfolioLineChart = () => {
        return <>
            {
                lineToDisplay &&
                <div className="card-container" style={{ width: '100%' }}>
                    <div className="flex space-between align-center" style={{ marginBottom: '10px' }}>
                        <div className="portfolio-compare-header-item flex align-center">
                            <h3 className="portfolio-compare-header-item flex align-center">Value Over Time</h3>
                        </div>
                        {/* <div className="flex flex-end" style={{width: '100%'}}> */}
                        {
                            shouldSeeComparedChart &&
                            <div className="card-container-compare portfolio-compare-input">
                                <Select
                                    options={compareOptions}
                                    value={selectedCompareItems}
                                    onChange={onChangeSelectedCompareItem}
                                    isMulti
                                    placeholder={'Select to compare'}
                                    components={{
                                        MultiValue: CustomMultiValue,
                                    }}

                                    styles={costumeStyles}

                                />
                            </div>
                        }
                        <div
                            style={{ height: 'fit-content' }}
                            className="flex flex-end"
                        >
                            <div
                                className="btn btn-border flex center-center"
                                style={{ width: 'fit-content' }}
                                onClick={() => handleChangeCompareChart()}
                                title="Compare"
                            >
                                {switchIcon}
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                    {valueOverTimeChart}
                </div>
            }
        </>
    }

    const BigScreen = () => {
        return <>
            <div className={`portfolio-container-top mb20 flex ${isMediumScreen ? 'column' : ''}`}>
                {
                    colorLabelsData &&
                    <LabelColorContainer
                        labels={colorLabelsData}
                        type={page === 'companies' ? 'company' : 'sector'}
                        selectedItem={selectedItem}
                        display={'column'}
                        onChangeSelectedItem={onChangeSelectedItem}
                    />
                }

                <div className={`portfolio-pieline flex ${isMobile ? 'column' : ''}`}>
                    {
                        piechartData &&
                        pieChartSum &&
                        <CardContainer
                            title={piechartTitle}
                            innerContent={piechartContent}
                            className={'portfolio-piechart-item portfolio-pie'}
                        />
                    }
                    {portfolioLineChart()}
                </div >
            </div >

            {
                barchartsData &&
                <ComparisonBarcharts
                    type={page === 'companies' ? 'company' : 'sector'}
                    chartData={barchartsData}
                />
            }
        </>
    };

    const MediumScreen = () => {
        return <>
            <div className={`portfolio-medium-screen-top flex ${isMediumScreen ? 'column' : ''}`}>
                {
                    colorLabelsData &&
                    <LabelColorContainer
                        labels={colorLabelsData}
                        type={page === 'companies' ? 'company' : 'sector'}
                        selectedItem={selectedItem}
                        display={'row'}
                        onChangeSelectedItem={onChangeSelectedItem}
                    />
                }
                <div
                    className={`portfolio-pieline flex ${isMobile ? 'column' : ''}`}
                    style={{
                        width: isMediumScreen ? '100%' : '',
                        marginBottom: isMediumScreen ? '20px' : ''
                    }}
                >
                    {
                        piechartData &&
                        pieChartSum &&
                        <CardContainer
                            title={piechartTitle}
                            innerContent={piechartContent}
                            className={'portfolio-piechart-item portfolio-pie'}
                        />
                    }
                    {portfolioLineChart()}
                </div>
            </div>

            {
                barchartsData &&
                <ComparisonBarcharts
                    type={page === 'companies' ? 'company' : 'sector'}
                    chartData={barchartsData}
                />
            }
        </>
    };

    const MobileScreen = () => {
        return <>
            <div className={`flex column`}>
                {
                    colorLabelsData &&
                    <LabelColorContainer
                        labels={colorLabelsData}
                        type={page === 'companies' ? 'company' : 'sector'}
                        selectedItem={selectedItem}
                        display={'row'}
                        // onChangeSelectedItem={onChangeSelectedItem}
                        onChangeSelectedItem={() => { }}
                    />
                }

                {
                    piechartData &&
                    pieChartSum &&
                    <CardContainer
                        title={piechartTitle}
                        innerContent={piechartContent}
                        className={'portfolio-piechart-item portfolio-pie'}
                    />
                }
                {portfolioLineChart()}

                {
                    barchartsData &&
                    <div className="port-comp-secs-barcharts">
                        <ComparisonBarcharts
                            type={page === 'companies' ? 'company' : 'sector'}
                            chartData={barchartsData}
                        />
                    </div>
                }

            </div>
        </>
    };

    return (
        <>
            {
                <div style={{ height: '100%' }}>
                    {
                        !isMediumScreen &&
                        !isMobile &&
                        // lineToDisplay &&
                        <BigScreen />
                    }
                    {
                        isMediumScreen &&
                        !isMobile &&
                        // lineToDisplay &&
                        <MediumScreen />
                    }
                    {
                        isMobile &&
                        // lineToDisplay &&
                        <MobileScreen />
                    }
                </div>
            }
        </>
    );
}

export default PortfolioCompaniesSectorsPage;