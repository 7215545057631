import HomepageFeedPost from "../../homepage/homepage-cmps/homepage-feed/homepage-feed-post";

const ProfilePosts = ({
    userPosts,
    loggedInUser,
    myPortfolio,
    onCopyPortfolio,
    handlePostAction,
    handleCopyPortfolio,
    handleCopyPortfolioFromFeed,
    companiesLastPrices,
    calcPortfolioReturn
}) => {

    return (
        <>
            {userPosts.length > 0 ? (
                userPosts.map((post, idx) => (
                    <HomepageFeedPost
                        key={idx}
                        post={post}
                        loggedInUser={loggedInUser}
                        myPortfolio={myPortfolio}
                        onCopyPortfolio={onCopyPortfolio}
                        handleCopyPortfolio={handleCopyPortfolio}
                        handlePostAction={handlePostAction}
                        companiesLastPrices={companiesLastPrices}
                        handleCopyPortfolioFromFeed={handleCopyPortfolioFromFeed}
                        calcPortfolioReturn={calcPortfolioReturn}
                        handleCopyModalChange={() => {}}
                    />
                ))
            ) : (
                <div className="card-container">No posts available.</div>
            )}
        </>
    );

}

export default ProfilePosts;