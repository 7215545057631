import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { getSelectedCommodity } from "../../../shared/actions/single-item-actions";
import LoadingSpinner from "../../../shared/loading-spinner/loading-spinner";
import { setGlobalIsLoading } from "../../../store/loading/loading-actions";
import SingleItemTechnical from "../../../cmps/single-item-technical/single-item-technical";
import SingleCommodityHeader from "./single-commodity-header/single-commodity-header";
import { getTodayMonthDayDate } from "../../../shared/global-color-functions";

const SingleCommodity = ({ singleCommodities, setSingleCommodities, userFollowing, toggleFollowing, loggedInUser }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { symbol } = useParams();

    const loadingSelector = useSelector((state) => state.loading.isLoading);
    const lastPrices = useSelector((state) => state.lastPrices);

    const [commodityHeader, setCommodityHeader] = useState(null);
    const [commodityTable, setCommodityTable] = useState(null);
    const [commodityPriceOverTime, setCommodityPriceOverTime] = useState(null);
    const [commodityBarCharts, setCommodityBarCharts] = useState(null);

    const location = useLocation();
    const path = location.pathname;
    const navType = path.split('/')[1];
    const data = lastPrices[`${symbol}=${navType}`];

    const getLastPricesCommodityHeader = () => {
        // const data = lastPrices[symbol];
        if (!data) return {};
        return {
            date: getTodayMonthDayDate,
            commodity: data.name,
            symbol: symbol,
            group: data.info.group,
            lastPriceChange: data.lastPriceChange,
            lastPricePercentChange: data.lastPricePercentChange,
        };
    };

    useEffect(() => {
        // const data = lastPrices[symbol];
        if (!data) return;
        setCommodityHeader(getLastPricesCommodityHeader());
    }, [symbol, lastPrices]);

    const setSingleCommodityData = (header, table, priceOverTime, barCharts) => {
        setCommodityHeader(header);
        setCommodityTable(table);
        setCommodityPriceOverTime(priceOverTime);
        setCommodityBarCharts(barCharts);
    };

    useEffect(() => {
        if (commodityHeader && commodityTable && commodityPriceOverTime && commodityBarCharts) {
            dispatch(setGlobalIsLoading(false))
        }
    }, [commodityHeader, commodityTable, commodityPriceOverTime, commodityBarCharts]);

    useEffect(() => {
        if (!symbol) handleNavigateToError();
        async function fetchData() {
            dispatch(setGlobalIsLoading(true));
            try {
                if (singleCommodities[symbol]) {
                    setSingleCommodityData(singleCommodities[symbol].commodityHeader, singleCommodities[symbol].commodityTable, singleCommodities[symbol].commodityPriceOverTime, singleCommodities[symbol].commodityBarCharts);
                } else {
                    const commodityData = await getSelectedCommodity(symbol);
                    if (commodityData) {
                        setSingleCommodityData(commodityData.commodityHeader, commodityData.commodityTable, commodityData.commodityPriceOverTime, commodityData.commodityBarCharts);
                        setSingleCommodities(prevSingleCommodities => ({
                            ...prevSingleCommodities,
                            [symbol]: commodityData
                        }));
                    } else {
                        handleNavigateToError();
                    }
                }
            } catch (error) {
                handleNavigateToError();
            }
        }
        fetchData();
    }, [symbol]);

    const handleNavigateToError = () => {
        history.push('/error');
    };

    return (
        <>
            {
                commodityHeader &&
                <SingleCommodityHeader
                    header={commodityHeader}
                    symbol={symbol}
                    userFollowing={userFollowing}
                    toggleFollowing={toggleFollowing}
                    loggedInUser={loggedInUser}
                />
            }
            {
                loadingSelector ? (
                    <LoadingSpinner />
                ) : (
                    <div >
                        <SingleItemTechnical
                            technicalData={{ table: commodityTable, priceOverTime: commodityPriceOverTime, barCharts: commodityBarCharts }}
                            symbol={symbol}
                            type={'commodity'}
                        />
                    </div>
                )
            }
        </>
    );
}

export default SingleCommodity;