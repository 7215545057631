import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalIsLoading } from '../../store/loading/loading-actions';
import { checkChallengeCompletion, getHomepageData, getHomepagePosts, getHomepageTrending, } from '../../shared/actions/homepage-actions';
import HomepageMovingHeader from './homepage-cmps/homepage-moving-header';
import HomepageBarcharts from './homepage-cmps/homepage-barchart/homepage-barcharts';
import HomepageWelcomeUser from './homepage-cmps/homepage-welcome-user';
import HomepageTrendingMembers from './homepage-cmps/homepage-trending-members';
import HomepageFeed from './homepage-cmps/homepage-feed/homepage-feed';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { calendarIcon, cryptoIcon, homeIcon, loginIcon, logoutIcon, macroIcon, moreIcon, portfolioIcon, signupIcon, userIcon } from '../../shared/icons/icons';
import { AccountContext } from '../../user-pool/account';
import './homepage.scss';
import LoadingSpinner from '../../shared/loading-spinner/loading-spinner';
import HomepageRightFooter from './homepage-cmps/homepage-right-footer';
import { setGlobalLastPrices } from '../../store/last-prices/last-prices-actions';

const Homepage = ({
    homepageData,
    handleChangeHomepageData,
    loggedInUser,
    onLogout,
    postsToDisplay,
    handleChangePostsToDisplay,
    seenPostsIds,
    handleChangeSeenPostsIds,
    myPortfolio,
    onCopyPortfolio,
    handleCopyPortfolio,
    handlePostAction,
    handleCreatePost,
    isPosting,
    handleSetCompaniesLastPrices,
    companiesLastPrices,
    handleCopyPortfolioFromFeed,
    homepageTrending,
    handleChangeHomepageTrending,
    calcPortfolioReturn,
    closeFeedbackModal,
    isFeedBackModalOpen,
    openFeedbackModal,
    onSendFeedback,
    blinkCmp
}) => {

    const { logout } = useContext(AccountContext);

    const loadingSelector = useSelector((state) => state.loading.isLoading);

    const dispatch = useDispatch();

    const [activeLink, setActiveLink] = useState('');

    const [movingHeaderData, setMovingHeaderData] = useState(null);
    const [homepageBarcharts, setHomepageBarcharts] = useState(null);
    const [homepageStandoutMembers, setStandoutMembers] = useState(null);

    const isBigScreen = useMediaQuery({ minWidth: 1301 });
    const isMobileScreen = useMediaQuery({ minWidth: 1450 });
    const isMobile = useMediaQuery({ minWidth: 820 });

    const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);

    const handleCopyModalChange = () => {
        setIsCopyModalOpen((prevIsCopyModalOpen) => !prevIsCopyModalOpen);
    };

    useEffect(() => {

        dispatch(setGlobalIsLoading(false));

        async function fetchTrendingData() {
            // dispatch(setGlobalIsLoading(true));
            try {
                if (!homepageData) {
                    const homepageDataRes = await getHomepageData();
                    if (homepageDataRes) {
                        // handleSetCompaniesLastPrices(homepageDataRes.lastPrices);
                        setMovingHeaderData(homepageDataRes.movingHeader);
                        handleChangeHomepageData(homepageDataRes);
                        dispatch(setGlobalLastPrices(homepageDataRes.singlesInfo));
                    } else {
                        console.log('ERROR HOMEPAGE - TRENDING');
                        // handleNavigateToError();
                    }
                } else {
                    setMovingHeaderData(homepageData.movingHeader);
                }
                if (!homepageTrending) {
                    const trendingRes = await getHomepageTrending(loggedInUser);
                    setHomepageBarcharts({ return: trendingRes.leadingStocks.return, sharpe: trendingRes.leadingStocks.sharpe });
                    setStandoutMembers(trendingRes.standoutMembers);
                    handleChangeHomepageTrending(trendingRes);
                } else {
                    setHomepageBarcharts({ return: homepageTrending.leadingStocks.return, sharpe: homepageTrending.leadingStocks.sharpe });
                    setStandoutMembers(homepageTrending.standoutMembers);
                };

            } catch (error) {
                console.log('HOMEPAGE TRENDING ERROR - ', error);
                // handleNavigateToError();
            }
        };

        async function fetchPostsData(loggedInUser, seenPostsIds) {
            // dispatch(setGlobalIsLoading(true));
            try {
                if (!postsToDisplay || !postsToDisplay.length) {
                    const posts = await getHomepagePosts(loggedInUser, seenPostsIds);
                    if (posts) {
                        handleChangePostsToDisplay(posts.posts);
                        handleChangeSeenPostsIds(posts.postsIds);
                    } else {
                        console.log('ERROR HOMEPAGE - TRENDING');
                        // handleNavigateToError();
                    }
                }
            } catch (error) {
                console.log('HOMEPAGE POSTS ERROR - ', error);
                // handleNavigateToError();
            }
        };

        fetchTrendingData();
        fetchPostsData(loggedInUser, seenPostsIds);
    }, []);

    const NavLink = ({ icon, label, to, exact }) => (

        <Link
            className={`sidemenu-nav ${activeLink === to ? 'active-sidemenu-nav' : ''}`}
            to={to}
            onClick={(e) => {
                if (loadingSelector) {
                    e.preventDefault();
                } else {
                    setActiveLink(to);
                }
            }}
            disabled={loadingSelector}
        >
            <div className={`flex ${!isMobileScreen ? 'justify-center' : ''} align-center`}>
                <div className='sidemenu-icon-item'
                    style={{ marginRight: !isMobileScreen ? '0' : '10px' }}
                >
                    <div className='flex center-center'>{icon}</div>
                </div>
                {label && <div>{label}</div>}
            </div>
        </Link>
    );


    const handleLogout = () => {
        logout();
        onLogout();
    };

    const leftContainerOpen = (
        <div
            className="nav-bar navbar-item flex column justify-center"
            style={{ paddingTop: '10px' }}
        >
            {
                loggedInUser &&
                <div className='main-nav-item navbar-page-btn flex center-center'>
                    <NavLink icon={userIcon} label={loggedInUser} to={`/profile/portfolios/${loggedInUser}`} />
                </div>
            }

            {
                !loggedInUser &&
                <div className='main-nav-item navbar-page-btn flex center-center'>
                    <NavLink icon={loginIcon} label={'Login'} to={`/login`} />
                </div>
            }

            {
                !loggedInUser &&
                <div className='main-nav-item navbar-page-btn flex center-center'>
                    <NavLink icon={signupIcon} label={'Signup'} to={`/signup`} />
                </div>
            }

            <div className='main-nav-item navbar-page-btn flex center-center'>
                <NavLink icon={homeIcon} label={'Home'} to='/' exact={true} />
            </div>
            <div className='main-nav-item navbar-page-btn flex center-center'>
                <NavLink icon={portfolioIcon} label='Portfolio' to='/portfolio/companies' />
            </div>
            <div className='main-nav-item navbar-page-btn flex center-center'>
                <NavLink icon={macroIcon} label='Macro' to='/macro/inflation' />
            </div>
            <div className='main-nav-item navbar-page-btn flex center-center'>
                <NavLink icon={cryptoIcon} label='Crypto' to='/crypto/currencies' />
            </div>
            <div className='main-nav-item navbar-page-btn flex center-center'>
                <NavLink icon={calendarIcon} label='Calendar' to='/calendar/companies' />
            </div>

            {
                loggedInUser &&
                <div
                    className='main-nav-item navbar-page-btn flex center-center'
                    onClick={() => handleLogout()}>
                    <NavLink icon={logoutIcon} label='Logout' to='/login' />
                </div>
            }

        </div>
    );

    const leftContainerClosed = (
        <div
            className="nav-bar navbar-item flex column justify-center"
            style={{ paddingTop: '10px' }}
        // onMouseEnter={() => onCloseModalHover(true)}
        >

            {
                loggedInUser &&
                <div className='main-nav-item homepage-navicon-menu-item navbar-page-btn flex center-center' title='My Profile'>
                    <NavLink icon={userIcon} to={`/profile/portfolios/${loggedInUser}`} />
                </div>
            }


            <div className='main-nav-item homepage-navicon-menu-item navbar-page-btn flex center-center' title='Home'>
                <NavLink icon={homeIcon} to='/' exact={true} />
            </div>
            <div className='main-nav-item homepage-navicon-menu-item navbar-page-btn flex center-center' title='Portfolio'>
                <NavLink icon={portfolioIcon} to='/portfolio/companies' />
            </div>
            <div className='main-nav-item homepage-navicon-menu-item navbar-page-btn flex center-center' title='Macro'>
                <NavLink icon={macroIcon} to='/macro/inflation' />
            </div>
            <div className='main-nav-item homepage-navicon-menu-item navbar-page-btn flex center-center' title='Crypto'>
                <NavLink icon={cryptoIcon} to='/crypto/currencies' />
            </div>
            <div className='main-nav-item homepage-navicon-menu-item navbar-page-btn flex center-center' title='Calendar'>
                <NavLink icon={calendarIcon} to='/calendar/companies' />
            </div>


            {
                !loggedInUser &&
                <div className='main-nav-item homepage-navicon-menu-item navbar-page-btn flex center-center' title='Login'>
                    <NavLink icon={loginIcon} to='/login' />
                </div>
            }

            {
                !loggedInUser &&
                <div className='main-nav-item homepage-navicon-menu-item navbar-page-btn flex center-center' title='Sign-up'>
                    <NavLink icon={signupIcon} to='/signup' />
                </div>
            }


            {/* <div className='main-nav-item homepage-navicon-menu-item navbar-page-btn flex center-center'>
                <NavLink icon={moreIcon} to='/' />
            </div> */}

            {/* <div className='main-nav-item homepage-navicon-menu-item navbar-page-btn flex center-center' title='About Us' >
                <NavLink icon={moreIcon} to='/about' />
            </div>
            <div className='main-nav-item homepage-navicon-menu-item navbar-page-btn flex center-center' title='Terms of Service'>
                <NavLink icon={moreIcon} to='/terms-of-service' />
            </div>
            <div className='main-nav-item homepage-navicon-menu-item navbar-page-btn flex center-center' title='Privacy Policy'>
                <NavLink icon={moreIcon} to='/privacy-notice' />
            </div> */}


            {
                loggedInUser &&
                <div
                    className='main-nav-item homepage-navicon-menu-item navbar-page-btn flex center-center'
                    title='Log-out'
                    onClick={() => handleLogout()}
                >
                    <NavLink icon={logoutIcon} to='/login' />
                </div>
            }
        </div>
    );

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow - Home</title>
                <meta name="description" content="Grow your financial wisdom & freedom! Utilize a risk-free decision support social app for managing your capital market portfolio. Compare your investment thoughts and considerations effortlessly. It's easy, convenient, profitable, and requires no deep finance knowledge." />
                <link rel="canonical" href="https://www.portfollow.com" />
            </Helmet>

            <div className="hompage-main-content-container flex justify-center">
                {/* <div className="homepage-left-container"> */}
                <div className={`homepage-left-container ${isCopyModalOpen ? 'homepage-left-container-copymodal-open' : ''}`}>
                    {
                        isMobileScreen ? (
                            <div className="homepage-left-container-item">{leftContainerOpen}</div>
                        ) : (
                            <div className="homepage-left-container-item">{leftContainerClosed}</div>
                        )
                    }
                </div>

                <div className="homepage-center-container">
                    <div style={{ width: !isMobile ? '100%' : '700px' }}>
                        {<HomepageMovingHeader movingHeaderData={movingHeaderData} />}
                    </div>
                    {
                        postsToDisplay &&
                            postsToDisplay.length &&
                            seenPostsIds &&
                            seenPostsIds.length ? (
                            <HomepageFeed
                                isBigScreen={isBigScreen}
                                postsToDisplay={postsToDisplay}
                                handleChangePostsToDisplay={handleChangePostsToDisplay}
                                seenPostsIds={seenPostsIds}
                                handleChangeSeenPostsIds={handleChangeSeenPostsIds}
                                loggedInUser={loggedInUser}
                                myPortfolio={myPortfolio}
                                onCopyPortfolio={onCopyPortfolio}
                                handleCopyPortfolio={handleCopyPortfolio}
                                handlePostAction={handlePostAction}
                                handleCreatePost={handleCreatePost}
                                isPosting={isPosting}
                                companiesLastPrices={companiesLastPrices}
                                handleCopyPortfolioFromFeed={handleCopyPortfolioFromFeed}
                                calcPortfolioReturn={calcPortfolioReturn}
                                handleCopyModalChange={handleCopyModalChange}
                                blinkCmp={blinkCmp}
                            />
                        ) : (
                            <LoadingSpinner />
                        )}

                </div>
                <div className="homepage-right-container flex column">

                    {
                        !loggedInUser &&
                        <HomepageWelcomeUser />
                    }
                    {
                        homepageStandoutMembers &&
                        <HomepageTrendingMembers homepageStandoutMembers={homepageStandoutMembers} />
                    }
                    {
                        homepageBarcharts &&
                        <HomepageBarcharts
                            barchartsData={homepageBarcharts}
                            isBigScreen={isBigScreen} />
                    }
                    {
                        <HomepageRightFooter
                            closeFeedbackModal={closeFeedbackModal}
                            isFeedBackModalOpen={isFeedBackModalOpen}
                            loggedInUser={loggedInUser}
                            openFeedbackModal={openFeedbackModal}
                            onSendFeedback={onSendFeedback}
                        />
                    }
                </div>
            </div>
        </>
    );

}

export default Homepage;