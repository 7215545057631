import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checkIcon, lockIcon } from "../../../shared/icons/icons";
import { updateAchievementsStatus } from '../../../store/user-achievements/user-achievements-actions';
import { setShouldSeeTutorial } from '../../../store/user-achievements/user-achievements-slice';

const ProfileAchievementItem = ({
    isMyProfile,
    achievement,
    challengeInProcess,
    handleChangeChallengeInProcess,
    index,
    blinkCmp
}) => {

    const dispatch = useDispatch();
    const userAchievementsCompleted = useSelector((state) => state.userAchievements.completed);
    const userAchievementsStatus = useSelector((state) => state.userAchievements.status);

    const onStartAchievement = () => {
        dispatch(setShouldSeeTutorial(true));
        dispatch(updateAchievementsStatus('active'));
    };

    return (
        // <div className="card-container mb20 flex align-center space-between">
        //     <div>
        //         <h4>{achievement.title}</h4>
        //     </div  >

        //     {
        //         isMyProfile && (
        //             index < userAchievementsCompleted ? (
        //                 <div
        //                     className="flex center-center"
        //                     style={{ width: '80px' }}
        //                 >
        //                     {checkIcon}
        //                 </div>
        //             ) : index === userAchievementsCompleted ? (
        //                 userAchievementsStatus === 'start' ? (
        //                     <div
        //                         className={`btn btn-border${index === 0 && userAchievementsCompleted === 0 && userAchievementsStatus === 'start' ? ' blink-border' : ''}`} // Add the 'blink-border' class conditionally

        //                         style={{ width: '80px' }}
        //                         onClick={onStartAchievement}
        //                     >
        //                         START
        //                     </div>
        //                 ) : (
        //                     <div
        //                         className="flex center-center"
        //                         style={{ width: '80px' }}
        //                     >
        //                         ACTIVE
        //                     </div>
        //                 )
        //             ) : (
        //                 <div
        //                     className="flex center-center"
        //                     style={{ width: '80px' }}
        //                 >
        //                     {lockIcon}
        //                 </div>
        //             )
        //         )
        //     }

        // </div>
        <div className="card-container mb20 flex align-center space-between">
    <div>
        <h4>{achievement.title}</h4>
    </div>

    {isMyProfile && (
        index < userAchievementsCompleted ? (
            <div
                className="flex center-center"
                style={{ width: '80px' }}
            >
                {checkIcon}
            </div>
        ) : index === userAchievementsCompleted ? (
            userAchievementsStatus === 'start' && userAchievementsCompleted !== 2 ? (
                <div
                    className={`btn btn-border${index === 0 && userAchievementsCompleted === 0 && userAchievementsStatus === 'start' ? ' blink-border' : ''}`}
                    style={{ width: '80px' }}
                    onClick={onStartAchievement}
                >
                    START
                </div>
            ) : (
                <div
                    className="flex center-center"
                    style={{ width: '80px' }}
                >
                    {userAchievementsCompleted === 2 ? lockIcon : 'ACTIVE'}
                </div>
            )
        ) : (
            <div
                className="flex center-center"
                style={{ width: '80px' }}
            >
                {lockIcon}
            </div>
        )
    )}
</div>

    );

}

export default ProfileAchievementItem;