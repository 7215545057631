const MacroIndicesClass = () => {
    return (
        <>
            <h2 className={'app-tutorial-slide-title'}>Macro - Indices</h2>
            <h4 className={"guide-subtitle"}>Filters:</h4>
            <ul className={"guide-ul"}>
                <li>Time Frames: Select specific time periods for analysis.</li>
                <li>Continents: Filter indices by geographical regions.</li>
                <li>Countries: Narrow down analysis by selecting specific countries.</li>
                <li>Indices: Choose from a variety of stock market indices.</li>
                <li>Price Viewing Filter (Relative vs. Actual Values): Toggle between viewing index prices in relative terms (percentage changes) or actual values.</li>
            </ul>
            <h4 className={"guide-subtitle"}>Charts:</h4>
            <ul className={"guide-ul"}>
                <li>Index Prices Over Time: Displays the historical prices of selected stock market indices.</li>
                <li>Index Returns Over Time: Analyzes the returns of stock market indices over different time frames.</li>
                <li>Sharpe Ratio Bar Chart: Compares the risk-adjusted returns (Sharpe ratios) of stock market indices over various time frames.
                </li>
            </ul>
            <div className="flex flex-end">
                <a href="/macro/indices" className="guide-btn btn btn-border" title="Indices">Go to page</a>
            </div>
        </>
    );
}

export default MacroIndicesClass;