import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getHomepagePosts } from '../../../../shared/actions/homepage-actions';
import LoadingSpinner from '../../../../shared/loading-spinner/loading-spinner';
import HomepageCreatePost from './feed-cmps/create-post/homepage-create-post';
import HomepageFeedPost from './homepage-feed-post';
import './homepage-feed.scss';

const HomepageFeed = ({
    postsToDisplay,
    handleChangePostsToDisplay,
    seenPostsIds,
    handleChangeSeenPostsIds,
    loggedInUser,
    myPortfolio,
    onCopyPortfolio,
    handleCopyPortfolio,
    handlePostAction,
    handleCreatePost,
    isPosting,
    companiesLastPrices,
    handleCopyPortfolioFromFeed,
    calcPortfolioReturn,
    handleCopyModalChange,
    blinkCmp
}) => {

    const [hasMore, setHasMore] = useState(true);

    // console.log('postsToDisplay', postsToDisplay);

const fetchData = async () => {
    // dispatch(setGlobalIsLoading(true));
    if (postsToDisplay.length < 1000) {
        try {
            const posts = await getHomepagePosts(loggedInUser, seenPostsIds);
            if (posts) {
                handleChangePostsToDisplay(posts.posts);
                handleChangeSeenPostsIds(posts.postsIds);
            } else {
                console.log('ERROR HOMEPAGE - TRENDING');
                // handleNavigateToError();
            }
        } catch (error) {
            console.log('HOMEPAGE POSTS ERROR - ', error);
            // handleNavigateToError();
        }
    } else {
        setHasMore(false);
    }
}

    const LoadingPosts = () => {
        return (
            <div className="mb20">Loading more posts..</div>
        )
    }

    return (
        <div
            className='homepage-feed-container blur flex column center-center'
        >

            {
                loggedInUser &&
                <HomepageCreatePost
                    loggedInUser={loggedInUser}
                    handleCreatePost={handleCreatePost}
                />
            }
            {/* {
                isPosting && <div
                    className="card-container homepage-feed-post"
                    style={{ maxHeight: '250px' }}
                >
                    <LoadingSpinner />
                </div>
            } */}
            <InfiniteScroll
                dataLength={postsToDisplay.length}
                next={fetchData}
                hasMore={hasMore}
                loader={<LoadingPosts />}
                endMessage={<p>No more new posts to show.</p>}
            >
                {
                    postsToDisplay.length &&
                    postsToDisplay.map((post, idx) => (
                        <HomepageFeedPost
                            key={idx}
                            post={post}
                            loggedInUser={loggedInUser}
                            myPortfolio={myPortfolio}
                            onCopyPortfolio={onCopyPortfolio}
                            handleCopyPortfolio={handleCopyPortfolio}
                            handlePostAction={handlePostAction}
                            companiesLastPrices={companiesLastPrices}
                            handleCopyPortfolioFromFeed={handleCopyPortfolioFromFeed}
                            calcPortfolioReturn={calcPortfolioReturn}
                            handleCopyModalChange={handleCopyModalChange}
                            blinkCmp={blinkCmp}
                        />
                    ))
                }
            </InfiniteScroll>
        </div>
    )
}

export default HomepageFeed;