import React, { useEffect } from "react";
import * as XLSX from 'xlsx';

const ExportToExcelBtn = ({ symbol, activeOption, sheetData, fixStr }) => {

  const exportToExcel = () => {
    if (!sheetData || !sheetData.length) return;
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(prepareSheetData(sheetData));
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, `${symbol} - ${fixStr(activeOption)}.xls`);
  };

  const prepareSheetData = (data) => {
    const preparedData = data.map(row => {
      return row.map(cell => {
        if (typeof cell === 'object' && cell.name) {
          return fixStr(cell.name);
        }
        return cell;
      });
    });

    // Exclude the first row containing subtitles
    return preparedData.slice(1);
  };


  return (
    <div
      className="export-excel-btn btn"
      onClick={() => exportToExcel(`${symbol} - ${activeOption}`)}>
      Export to Excel
    </div>
  );
}

export default ExportToExcelBtn;