import React from 'react';
import BasicSelectOption from '../../../shared/basic-select-option';
import { toggleTopPage } from '../../../shared/global-color-functions';

const PortfolioCompanies = () => {

    const companyOptions = [
        {
            value: 'AAPL',
            label: '(AAPL) Apple Inc.',
        },
        {
            value: 'MSFT',
            label: '(MSFT) Microsoft Corp',
        },
        {
            value: 'NVDA',
            label: '(NVDA) Nvidia Corp',
        },
        {
            value: 'AMZN',
            label: '(AMZN) Amazon.com Inc',
        }
    ];

    const sectorOptions = [
        {
            value: 'Technology',
            label: 'Technology',
        },
        {
            value: 'Financials',
            label: 'Financials',
        },
        {
            value: 'Health Care',
            label: 'Health Care',
        },
        {
            value: 'Real Estate',
            label: 'Real Estate',
        }
    ];

    const periodOptions = [
        {
            value: '1 Week',
            label: '1 Week',
        },
        {
            value: '1 Month',
            label: '1 Month',
        },
        {
            value: '6 Months',
            label: '6 Months',
        },
        {
            value: '1 Year',
            label: '1 Year',
        }
    ];

    return (
        <>
            <h2 className="app-tutorial-slide-title">Portfolio - Companies</h2>

            <p className="app-tutorial-p-strong">
                Welcome to the Portfolio Analyzer tutorial!
            </p>
            <p className="app-tutorial-p">
                At the Portfolio page, you can analyze and manage your investment portfolio across five sections: Companies, Sectors, Fundamental, Descriptive, and Sensitivity.
            </p>
            <p className="app-tutorial-p">
                Each section offers filters to customize your viewing experience, allowing you to focus on specific time frames, categories, and choose between relative or actual price viewing, allowing you to make informed decisions in your investment strategies.
            </p>
            <ul className={"guide-ul"}>
                <li>Analyze individual stock market companies.</li>
                <li>Customize analysis based on time frames and industry sectors.</li>
                <li>View visualizations such as line charts, pie charts, and bar charts displaying values, returns, and risk metrics.</li>
            </ul>

            <h4 className={"guide-subtitle"}>Filters:</h4>
            <ul className={"guide-ul"}>
                <li>Time Frames: Select specific time periods for analysis.</li>
                <li>Sector: Filter stock market companies by industry sectors.
                </li>
                <li>Companies: Choose from a variety of stock market companies to analyze.
                </li>
            </ul>

            <div className="flex wrap justify-center" style={{ marginBottom: '20px' }}>
                <BasicSelectOption options={periodOptions} title="Period" />
                <BasicSelectOption options={sectorOptions} title="Sectors" />
                <BasicSelectOption options={companyOptions} title="Comapnies" />
            </div>

            <h4 className={"guide-subtitle"}>Visualizations:</h4>
            <ul className={"guide-ul"}>
                <li>Value Over Time Line Chart: Displays the value of each invested company in the portfolio over time.
                </li>
                <li>Current Companies Values Pie Chart: Illustrates the current allocation of the portfolio among different stocks.
                </li>
                <li>Reward Bar Charts: Show the return, Sharpe ratio, and information ratio of each stock market company.
                </li>
            </ul>
            <div className="flex flex-end">
                <a href="/portfolio/companies" className="guide-btn btn btn-border" title="Companies">Go to page</a>
            </div>

        </>
    );
}

export default PortfolioCompanies;