export async function getSearchOptions(searchInput, setOptions) {
    if (!searchInput || !searchInput.length) return;
    var raw = JSON.stringify({ "searchStr": searchInput.toUpperCase() });
    var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
        redirect: 'follow'
    };
    // fetch("https://la0ft7vcs0.execute-api.us-east-1.amazonaws.com/stage_get_select_options/get-select-options", requestOptions)
    fetch("https://3yp9cujbl2.execute-api.us-east-1.amazonaws.com/get-full-select-options", requestOptions)
        .then(response => response.text())
        .then(result => {
            const parsedData = JSON.parse(result);
            const matchingItems = parsedData.items;
            console.log('matchingItems:',matchingItems);
            setOptions(matchingItems);
        })
        .catch(error => console.log('error', error));
};