import { useMediaQuery } from 'react-responsive';
import { getRandomColor } from '../../../../../shared/global-color-functions';
import { Doughnut } from "react-chartjs-2";
import LabelColorContainer from "../../../../../shared/label-color-container/label-color-container";

const MemberPortfolioPiechart = ({ item, title, chartLabels, type }) => {

    // console.log('ITEM:', item);

    const isMobile = useMediaQuery({ maxWidth: 1000 });
    const isMedium = useMediaQuery({ maxWidth: 1000 });
    const isTablet = useMediaQuery({ maxWidth: 700 });

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                enabled: true,
                displayColors: false,
                backgroundColor: '#ffffff',
                titleColor: '#484848',
                borderWidth: '1',
                borderColor: '#484848',
                bodyColor: '#484848',
                cornerRadius: '20',
                padding: '10',
                callbacks: {
                    // label: (tooltipItem, data) => {
                    //     const index = tooltipItem.dataIndex;
                    //     const label = item.hover[index];
                    //     const hoverData = item.hover[index];
                    //     return hoverData.map((line, lineIndex) => (
                    //         line.toString()
                    //     ));
                    // },
                },
                //   },
            },
            legend: {
                display: false,
                labels: {
                    usePointStyle: true,
                    boxHeight: 10
                }
            }
        }
    };

    return (
        <>
            {/* <div className="flex"> */}
            <div
                className={`findatasheets-container-item-top flex ${isMobile ? 'column' : ''}`} style={{ height: '100%' }}>
                <LabelColorContainer
                    labels={chartLabels}
                    height={'100%'}
                    type={type}
                    display={isMobile ? 'row' : 'column'}
                    onChangeSelectedItem={() => { }}
                />

                <div className={`flex column center-center${isMedium ? '' : ''}`} style={{ width: isMedium ? '100%' : '50%' }}>
                    <h4 className='portfolio-chart-title'>{title}</h4>
                    <div style={{ width: '100%', minHeight: '220px' }}>
                        <Doughnut data={item} options={options} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default MemberPortfolioPiechart;