import './bar-chart.scss';
import React, { useState, useEffect, useRef } from 'react';
import { Chart } from "chart.js";
import { useHistory, useParams } from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { setGlobalIsLoading } from '../../../store/loading/loading-actions';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { formatNumberToDollarTableString } from '../../global-color-functions';

const BarChart = ({ type, chartData, onChangeSelectedItem = () => { } }) => {

    const mediumScreen = useMediaQuery({ maxWidth: 920 });
    const smallScreen = useMediaQuery({ maxWidth: 700 });
    const miniScreen = useMediaQuery({ maxWidth: 600 });

    const isSmallScreen = useMediaQuery({ maxWidth: 920, minWidth: 820 });

    const dispatch = useDispatch();
    const { symbol } = useParams();
    const [dataModal, setDataModal] = useState(null);
    const [dataModalLocation, setDataModalLocation] = useState({ x: 0, y: 0 });
    const [offDataModal, setOffDataModal] = useState(true);

    let chartInstance = null;
    const chartContainer = useRef(null);
    const [selectedBar, setSelectedBar] = useState(null)

    const [canvasWidth, setCanvasWidth] = useState(null);
    const [barWidth, setBarWidth] = useState(null);

    const [visibleData, setVisibleData] = useState(chartData);

    useEffect(() => {
        if (smallScreen || miniScreen) {
            const newData = { ...chartData };
            Object.keys(newData).forEach(key => {
                if (Array.isArray(newData[key])) {
                    newData[key] = newData[key].slice(0, 5);
                }
            });
            newData.datasets = newData.datasets.map(dataset => ({
                ...dataset,
                backgroundColor: dataset.backgroundColor.slice(0, 5),
                borderColor: dataset.borderColor.slice(0, 5),
                data: dataset.data.slice(0, 5),
            }));
            setVisibleData(newData);
        } else {
            setVisibleData(chartData);
        }
    }, [chartData, smallScreen, miniScreen, window.innerWidth, chartContainer]);

    useEffect(() => {
        onChangeSelectedItem(chartData.labels[selectedBar]);
    }, [selectedBar]);

    // useEffect(() => {
    //     if (!dataModal) return;
    //     let resetDataModalId = null;
    //     // if (offDataModal) {
    //     clearTimeout(resetDataModalId);
    //     resetDataModalId = setTimeout(() => {
    //         setDataModal(null);
    //     }, 2500);
    //     // }
    //     return () => {
    //         clearTimeout(resetDataModalId);
    //     };
    // }, [dataModal, offDataModal]);

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            const chartConfig = {
                type: "bar",
                data: visibleData,
                plugins: [ChartDataLabels],
                options: {
                    elements: {
                        bar: {
                            borderWidth: 2,
                            borderRadius: 6
                        },
                    },
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            top: 25
                        },
                    },
                    plugins: {
                        datalabels: {
                            formatter: function (value, context) {
                                const chartName = context.chart.data.datasets[0].label;
                                switch (chartName) {
                                    case "currentRatio":
                                    case "quickRatio":
                                    case "debtToEquity":
                                    case "assetsTurnover":
                                    case "assetCoverage":
                                    case "debtServiceCoverage":
                                    case "interestCoverage":
                                        return parseFloat(value).toFixed(2) + 'x';
                                    case "dividendPayoutRatio":
                                    case "debttoAssets":
                                    case "shareholderEquityRatio":
                                    case "grossProfitMargin":
                                    case "percentPureProfit":
                                    case "returnOnAssets":
                                    case "returnOnEquity":
                                    case "efficiencyRatio":
                                    case "percentOperatingIncome":
                                        return parseFloat(value).toFixed(2) + '%';
                                    case "dividendPerShare":
                                    case "earningsPerShare":
                                        return formatNumberToDollarTableString(value);
                                    case "daysSalesOutstanding":
                                        return parseFloat(value).toFixed(2) + ' Days'; // Assuming you want 0 decimal places
                                    case "inventoryTurnover":
                                        return parseFloat(value).toFixed(2) + ' Units'; // Assuming you want 0 decimal places
                                    default:
                                        return parseFloat(value).toFixed(2) + '%';
                                }
                            },
                            color: '#484848',
                            anchor: 'end',
                            align: 'top',
                            font: {
                                size: 14,
                                family: 'Poppins-regular'
                            },
                        },
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        },
                        tooltip: {
                            displayColors: false,
                            callbacks: {
                                title: (tooltipItems, data) => {
                                  const index = tooltipItems[0].dataIndex;
                                  return chartData.hover[index][0];
                                },
                                label: (tooltipItem, data) => {
                                  const index = tooltipItem.dataIndex;
                                  const tooltipLines = chartData.hover[index].slice(1);
                                  return tooltipLines.map((line, lineIndex) => {
                                    if (line) {
                                        return line.toString();
                                    }
                                });
                                },
                            },

                            // enabled: false
                            backgroundColor: '#ffffff',
                            titleColor: '#484848',
                            borderWidth: '1',
                            borderColor: '#484848',
                            bodyColor: '#484848',
                            cornerRadius: '20',
                            padding: '10',
                        },
                        zoom: {
                            zoom: {
                                enabled: false
                            },
                            pan: {
                                enabled: false
                            }
                        },
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                autoSkip: false,
                                font: {
                                    size: smallScreen ? 10 : (mediumScreen ? 14 : 16),
                                },
                            },
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                font: {
                                    size: smallScreen ? 10 : (mediumScreen ? 14 : 16),
                                },
                            },
                        }],
                        x: {
                            ticks: {
                                font: {
                                    size: smallScreen ? 10 : (mediumScreen ? 14 : 16),
                                },
                            },
                            display: false,
                            grid: {
                                display: false,
                            },
                        },
                        y: {
                            ticks: {
                                precision: 2,
                                font: {
                                    size: smallScreen ? 10 : (mediumScreen ? 14 : 16),
                                },
                            },
                            display: false,
                            grid: {
                                display: false,
                            },
                        }
                    },
                    onClick: (event, chartElements) => {
                        if (chartElements.length > 0) {
                            const clickedBar = chartElements[0];
                            const datasetIndex = clickedBar.datasetIndex;
                            const index = clickedBar.index;
                            const clickedBarIndex = index + datasetIndex * chartData.labels.length;
                            setSelectedBar(clickedBarIndex);
                        } else {
                            setSelectedBar(null);
                        }
                    },

                    onHover: (event, chartElements) => {
                        if (!chartData.hover) return;

                        const chart = chartContainer.current;
                        if (!chart) return;

                        const tooltipElement = document.querySelector('.barchart-custom-tooltip');
                        const tooltipWidth = tooltipElement ? tooltipElement.offsetWidth : '200px';
                        const tooltipHeight = tooltipElement ? tooltipElement.offsetHeight : 0;

                        const chartHeight = chart.offsetHeight;
                        const chartWidth = chart.offsetWidth;

                        let timeoutId = null;

                        if (chartElements.length > 0) {
                            const element = chartElements[0];
                            const dataIndex = element.index;
                            const mouseX = event.x;
                            const mouseY = event.y;
                            let adjustedX = mouseX;
                            let adjustedY = mouseY;

                            if (tooltipElement) {
                                if (mouseY > chartHeight / 2) {
                                    adjustedY -= tooltipHeight;
                                }
                                if (mouseX > chartWidth / 2) {
                                    adjustedX -= tooltipWidth;
                                }
                            }
                            // setOffDataModal(false);
                            // clearTimeout(timeoutId);
                            // timeoutId = setTimeout(() => {
                            // setDataModalLocation({ x: adjustedX, y: adjustedY });
                            // setDataModal(chartData.hover[dataIndex]);
                            // }, 100);
                        } else {
                            clearTimeout(timeoutId);
                            setOffDataModal(true);
                        }
                        if (!chartElements.length) setDataModal(null);
                    },
                }
            };
            chartInstance = new Chart(chartContainer.current, chartConfig);
        }

        setCanvasWidth(chartContainer.current.offsetWidth);
        setBarWidth(chartContainer.current.offsetWidth / chartData.labels.length);

        return () => {
            if (chartInstance) {
                chartInstance.destroy();
            }
        }
    }, [chartContainer, chartData, window.innerWidth, visibleData]);

    const getLabelToDisplay = (label, type) => {
        // if (type === 'company') return label;
        // let transformedLabel = label.replace(/([A-Z])/g, ' $1').trim();
        // transformedLabel = transformedLabel.charAt(0).toUpperCase() + transformedLabel.slice(1);
        // return transformedLabel;
        if (type === 'currency' && isSmallScreen) {
            return label.replace('USD', '');
        }
        return label;
    };

    const history = useHistory();

    const onNavigate = (label, type) => {
        if (label === symbol) return;
        dispatch(setGlobalIsLoading(true));
        history.push(`/${type}/${getLabelToDisplay(label, type)}`);
    };

    return (
        <>
            <div className='barchart-chart-container'>
                <canvas ref={chartContainer} />
                <div style={{ height: '200px' }}>
                    {
                        dataModal && (
                            <div
                                className="barchart-custom-tooltip card-container"
                                style={{
                                    left: `${dataModalLocation.x}px`,
                                    top: `${dataModalLocation.y}px`,
                                }}
                            >
                                {dataModal.map((item, index) => (
                                    <div
                                        key={index}
                                        className='barchart-modal-item'
                                    >
                                        {item}
                                    </div>
                                ))}
                            </div>
                        )}
                </div>
            </div>
            <div className="flex column" style={{ width: canvasWidth }}>
                <div className='flex space-around'>
                    {
                        visibleData.labels.map((label, index) => (
                            <div
                                className='flex justify-center'
                                style={{ width: `${barWidth}px` }}
                                key={index}
                            >
                                <a
                                    className='clickable-label'
                                    // onClick={() => onNavigate(label, type)}
                                    key={index}
                                    // href=""
                                    href={`/${type}/${label}`}
                                >
                                    {getLabelToDisplay(label, type)}
                                </a>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>

    );
};

export default BarChart;