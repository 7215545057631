import HomepageBarchartItem from "./homepage-barchart-item";
import { useMediaQuery } from 'react-responsive';

const HomepageBarcharts = ({ barchartsData, isBigScreen }) => {

    const isMobile = useMediaQuery({ maxWidth: 920 });

    return (
        <>
            <div className="mb10">
                <HomepageBarchartItem barItem={barchartsData.return} type='return' />
            </div>
            <div className="mb10">
                <HomepageBarchartItem barItem={barchartsData.sharpe} type='sharpe' />
            </div>
        </>
    );
}

export default HomepageBarcharts;