const MacroIntroClass = () => {
    return (
        <>
            <h2 className="app-tutorial-slide-title">Portfolio - Macro</h2>
            <p className='app-tutorial-p'>
            Welcome to the Macro Page tutorial!
            </p>
            <p className='app-tutorial-p'>
                Here, you can explore key macroeconomic indicators and market trends across four sections: Inflation, Currencies, Indices, and Commodities.
            </p>
            <p className='app-tutorial-p'>
                Each section offers filters to customize your viewing experience, allowing you to focus on specific time frames, categories, currencies, and choose between relative or actual price viewing, allowing you to make informed decisions in your investment strategies.
            </p>
        </>
    );
}

export default MacroIntroClass;