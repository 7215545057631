import React, { useState, useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import './line-chart.scss';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import "chart.js/auto";
import { formatNumberToDollarTableString } from '../../global-color-functions';
import { useMediaQuery } from 'react-responsive';

const LineChartDays = ({ chartData, type, selectedItem, onChangeSelectedItem = () => { }, displayLegend }) => {

    const mediumScreen = useMediaQuery({ maxWidth: 920 });
    const smallScreen = useMediaQuery({ maxWidth: 800 });

    const [selectedLine, setSelectedLine] = useState(null);
    const chartRef = useRef(null);

    useEffect(() => {
        if (selectedLine === null || selectedLine === undefined) {
            onChangeSelectedItem(null);
        } else {
            onChangeSelectedItem(chartData.datasets[selectedLine].label);
        }
    }, [selectedLine]);

    const options = {
        font: {
            size: smallScreen ? 8 : (mediumScreen ? 10 : 12),
            family: 'Poppins-regular'
        },
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                displayColors: false,
                backgroundColor: '#ffffff',
                titleColor: '#484848',
                borderWidth: '1',
                borderColor: '#484848',
                bodyColor: '#484848',
                cornerRadius: '20',
                padding: '10',
            },
            legend: {
                display: displayLegend ? true : false,
                labels: {
                    font: {
                        size: smallScreen ? 8 : (mediumScreen ? 10 : 12),
                        family: 'Poppins-regular'
                    }
                }
            }
        },
        title: {
            display: true,
            text: 'Companies return',
            font: {
                size: smallScreen ? 8 : (mediumScreen ? 10 : 12),
            }
        },
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                ticks: {
                    maxTicksLimit: mediumScreen ? 4 : 5,
                    callback: value => `${value} Days`,
                },
                font: {
                    size: smallScreen ? 8 : (mediumScreen ? 10 : 12),
                }
            },
            y: {
                ticks: {
                    maxTicksLimit: mediumScreen ? 4 : 5,
                    font: {
                        size: smallScreen ? 8 : (mediumScreen ? 10 : 12),
                    },
                    callback: function (value, index, values) {
                        if (index === 0) {
                            return '';
                        }
                        if (type === 'relative-percent') {
                            return value + "%";
                        }
                        if (type === 'percent') {
                            return Math.round(value * 100) + "%";
                        }
                        else if (type === 'percent-decimal') {
                            return (value * 100).toFixed(1) + '%';
                        } else if (type === 'dollar') {
                            return formatNumberToDollarTableString(value);
                        } else {
                            return value.toFixed(2);
                        }
                    }
                }
            },
        },
        elements: {
            point: {
                radius: 0
            }
        },
        onClick: (event, chartElements) => {
            if (chartElements[0] && chartElements.length) {
                const clickedLine = chartElements[0].datasetIndex;
                setSelectedLine(clickedLine);
                onChangeSelectedItem(chartData.datasets[clickedLine].label);
            } else {
                setSelectedLine(null);
                onChangeSelectedItem(null);
            }
        },
    };

    return (
        <Line
            type={'line'}
            data={chartData}
            options={options}
            ref={chartRef}
            className={'mobile-chart-size'}
        />
    );
}

export default LineChartDays;