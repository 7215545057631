import './portfolio-form.scss';
import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { validateInput } from '../../../shared/actions/portfolio-actions';
import { getRandomId } from '../../../shared/global-color-functions';
import { useSelector } from 'react-redux';

const PortfolioForm = ({ addStock }) => {

    const globalLastPrices = useSelector((state) => state.lastPrices);
    
    const [symbol, setSymbol] = useState('');
    const [amount, setAmount] = useState('');
    const [date, setDate] = useState('');
    const [order, setOrder] = useState('Buy');
    const [price, setPrice] = useState(0);
    const [priceRange, setPriceRange] = useState({});
    const [error, setError] = useState(false);
    const [symbolError, setSymbolError] = useState('');
    const [amountError, setAmountError] = useState('');
    const [dateError, setDateError] = useState('');

    useEffect(() => {
        const todayDate = getTodayDate();
        setDate(todayDate);
    }, []);

    useEffect(() => {
        const handleInputValidation = async () => {
            const valueToPass = {
                date,
                amount,
                symbol,
            }
            try {
                const res = await validateInput('date', valueToPass);

                if (res.err) {
                    setError(true);

                    switch (res.err) {
                        case 'symbol':
                            setSymbolError(res.msg);
                            setAmountError('');
                            setDateError('');
                            break;
                        case 'amount':
                            setAmountError(res.msg);
                            setSymbolError('');
                            setDateError('');
                            break;
                        case 'date':
                            setDateError(res.msg);
                            setSymbolError('');
                            setAmountError('');
                            break;
                        default:
                            break;
                    }
                } else if (res.default && res.min && res.max) {
                    setPrice(res.default);
                    setPriceRange({
                        default: +res.default,
                        min: +res.min,
                        max: +res.max,
                    });
                    setError(false);
                } else {
                    setError(false);
                };

            } catch (error) {
                // Handle errors appropriately
                console.error('Error during input validation:', error);
            }
        };
        handleInputValidation();
    }, [symbol, amount, date]);

    const handleClickInside = (event) => {
        event.stopPropagation();
    };

    const getTodayDate = () => {
        return new Date().toISOString().split('T')[0];
    };

    const onSubmitForm = async (ev) => {
        ev.preventDefault();
        const res = await validateInput('date', { symbol, date, amount });
        if (res.err) {
            if (res.err) {
                setError(true);
                switch (res.err) {
                    case 'symbol':
                        setSymbolError(res.msg);
                        setAmountError('');
                        setDateError('');
                        break;
                    case 'amount':
                        setAmountError(res.msg);
                        setSymbolError('');
                        setDateError('');
                        break;
                    case 'date':
                        setDateError(res.msg);
                        setSymbolError('');
                        setAmountError('');
                        break;
                    default:
                        break;
                }
            }
            return;
        }
        const addedStock = { symbol, amount, buyDate: date, price: +price, orderId: getRandomId() };
        addStock(addedStock);
        const todayDate = getTodayDate();
        setSymbol('');
        setAmount('');
        setDate(todayDate);
        // setOrder('Buy');
        setPrice(0);
        setError(false);
        setSymbolError('');
        setAmountError('');
        setDateError('');
        setPriceRange({});
    };

    const fetchOptions = async (inputValue, cb) => {

        const filteredPrices = Object.fromEntries(
            Object.entries(globalLastPrices).filter(([key]) => key.includes('=company'))
        );

        const searchLower = inputValue.toLowerCase();

        const filteredByInput = Object.fromEntries(
            Object.entries(filteredPrices).filter(([key, value]) =>
                value.symbol.toLowerCase().includes(searchLower) ||
                value.name.toLowerCase().includes(searchLower)
            )
        );

        const resultArray = Object.values(filteredByInput).map(item => ({
            label: `(${item.symbol}) ${item.name}`,
            value: item.symbol
        }));
        
        cb(resultArray);
    };

    // const fetchOptions = async (inputValue, cb) => {
    //     try {
    //         const res = await validateInput('symbol', inputValue);
    //         if (res) {
    //             if (Array.isArray(res)) {
    //                 cb(res);
    //             } else if (typeof res === 'object' && res !== null) {
    //                 setSymbolError(res.msg);
    //                 cb([]);
    //             } else {
    //                 setSymbol(res.msg);
    //                 cb([]);
    //             }
    //         }

    //     } catch (error) {
    //         cb([]);
    //         console.error('Error validating data:', error);
    //     }
    // };

    const onChangeSymbol = (value) => {
        if (value === '') return;
        setSymbol(value.value);
    };

    const onChangeAmount = (value) => {
        setAmount(value);
    };

    const onChangeDate = (value) => {
        setDate(value);
    };

    return (
        <div className="portfolio-form-container">
            <form
                onClick={handleClickInside}
                onSubmit={onSubmitForm}
                className={`portfolio-form`}>
                {
                    <div className='flex column justify-center'>
                        <div className='portfolio-form-item-container'>
                            <div>
                                <AsyncSelect
                                    value={symbol.length > 1 ? { label: symbol, value: symbol } : null}
                                    formatOptionLabel={(option, state) => {
                                        if (state.context.error) {
                                            return 'Error loading options';
                                        }
                                        return option.label;
                                    }}
                                    loadOptions={fetchOptions}
                                    placeholder="Symbol"
                                    onChange={(selectedOpt) => onChangeSymbol(selectedOpt)}
                                    noOptionsMessage={() => symbol.length > 0 ? 'Can\'t find symbol or company.' : null}
                                    defaultOptions={false}
                                    // styles={
                                    //     {
                                    //         dropdownIndicator: () => ({ color: '#484848' }),
                                    //         option: (base, state) => ({
                                    //             ...base,
                                    //             color: state.isSelected ? '#4071AB' : '#484848',
                                    //             backgroundColor: '#ffffff',
                                    //             border: '1.5px solid #EEEEEE',
                                    //             borderColor: '#484848',
                                    //             cursor: 'pointer',
                                    //             fontFamily: 'inherit'
                                    //         }),
                                    //         input: (baseStyles) => ({
                                    //             ...baseStyles,
                                    //             color: '#484848',
                                    //         }),
                                    //         control: (baseStyles) => ({
                                    //             ...baseStyles,
                                    //             width: '200px',
                                    //             borderRadius: '0px',
                                    //             borderWidth: '0px',
                                    //             borderBottom: '1px solid #484848',
                                    //             color: '#484848',
                                    //             fontSize: '1rem',
                                    //             backgroundColor: 'inherit',
                                    //             boxShadow: 'none',
                                    //             margin: '0 auto',
                                    //             '&:hover': {
                                    //                 borderColor: '#484848',
                                    //             },
                                    //             '&:focus': {
                                    //                 borderColor: '#4071AB',
                                    //                 outline: 'none'
                                    //             },

                                    //         }),
                                    //         singleValue: (base, state) => ({
                                    //             ...base,
                                    //             color: '#484848',
                                    //         }),
                                    //         placeholder: (baseStyles) => ({
                                    //             ...baseStyles,
                                    //             color: '#484848'
                                    //         })
                                    //     }
                                    // }

                                    styles={{
                                        dropdownIndicator: (base) => ({
                                            ...base,
                                            color: '#484848',
                                        }),
                                        option: (base, state) => ({
                                            ...base,
                                            color: state.isSelected ? '#4071AB' : '#484848',
                                            backgroundColor: '#ffffff',
                                            border: '1.5px solid #EEEEEE',
                                            borderColor: '#484848',
                                            cursor: 'pointer',
                                            fontFamily: 'inherit',
                                        }),
                                        input: (baseStyles) => ({
                                            ...baseStyles,
                                            color: '#484848',
                                        }),
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            width: '100%',
                                            borderRadius: '20px',
                                            border: '1px solid #484848',
                                            backgroundColor: '#f1f6fc',
                                            boxShadow: 'none',
                                            margin: '0 auto',
                                            height: '38px',
                                            '&:hover': {
                                                borderColor: '#484848',
                                            },
                                            '&:focus': {
                                                borderColor: '#4071AB',
                                                outline: 'none',
                                            },
                                        }),
                                        singleValue: (base, state) => ({
                                            ...base,
                                            color: '#484848',
                                        }),
                                        placeholder: (baseStyles) => ({
                                            ...baseStyles,
                                            color: '#484848',
                                        }),
                                    }}

                                />
                            </div>

                            {/* {error && symbolError && <div className='portfolio-form-errormsg'>{symbolError}</div>} */}

                        </div>
                        <div className='portfolio-form-item-container'>
                            <input type="number" value={amount} placeholder="Amount" onChange={(ev) => onChangeAmount(+ev.target.value)} className="user-pool-input" style={{ width: '100%' }} />
                            {/* {error && amountError && <div className='portfolio-form-errormsg'>{amountError}</div>} */}
                        </div>
                        <div className='portfolio-form-item-container'>
                            <input type="date" value={date} onChange={(ev) => onChangeDate(ev.target.value)} className="user-pool-input" style={{ width: '100%' }} />
                            {/* {error && dateError && <div className='portfolio-form-errormsg'>{dateError}</div>} */}
                        </div>


                        {/* <div style={{ height: '20px' }}>
                            {error && symbolError && <div className='portfolio-form-errormsg'>{symbolError}</div>}
                            {error && amountError && <div className='portfolio-form-errormsg'>{amountError}</div>}
                            {error && dateError && <div className='portfolio-form-errormsg'>{dateError}</div>}
                        </div> */}


                        {
                           !error && priceRange.min && priceRange.max > 0 && <div
                                className='portfolio-form-item-container portfolio-range-input'>
                                <div className="">
                                    <input
                                        type="text"
                                        id="price-input"
                                        value={price === '' ? '' : `$${price}`}
                                        onChange={(ev) => {
                                            const newValue = ev.target.value;
                                            setPrice(newValue.replace(/\$/g, ''));
                                        }}
                                        onBlur={() => {
                                            let numericValue = parseFloat(price);
                                            if (isNaN(numericValue)) {
                                                setPrice('');
                                            } else {
                                                if (numericValue > priceRange.max) {
                                                    numericValue = priceRange.max;
                                                } else if (numericValue < priceRange.min) {
                                                    numericValue = priceRange.min;
                                                }
                                                setPrice(numericValue);
                                            }
                                        }}
                                        className="portfolio-form-input"
                                    />
                                </div>
                                <div className="range-input-container flex space-between align-center">
                                    <div className='form-maxmin-price -min'>${priceRange.min}</div>
                                    <div className='form-range-width'>
                                        <input
                                            type="range"
                                            value={price}
                                            min={priceRange.min}
                                            max={priceRange.max}
                                            step="0.01"
                                            onChange={(ev) => setPrice(+ev.target.value)}
                                            className="portfolio-form-input-range" />
                                    </div>
                                    <div className='form-maxmin-price -max'>${priceRange.max}</div>
                                </div>
                            </div>
                        }


{ error &&                       
<div style={{ height: '60px', marginBottom: '25px' }}>
                            {error && symbolError && <div className='portfolio-form-errormsg'>{symbolError}</div>}
                            {error && amountError && <div className='portfolio-form-errormsg'>{amountError}</div>}
                            {error && dateError && <div className='portfolio-form-errormsg'>{dateError}</div>}
                        </div>}


                        <div className="flex space-between">

                            <div className='portfolio-form-item-container' style={{marginBottom: '0'}}>
                                <div className="wrapper" style={{padding: '0'}}>
                                    <input type="radio" name="select" id="option-1" value={"Buy"} defaultChecked={order === "Buy"} onChange={ev => setOrder(ev.target.value)} />
                                    {/* <input type="radio" name="select" id="option-2" value={"Sell"} defaultChecked={order === "Sell"} onChange={ev => onInputChange('order', ev.target.value)} /> */}
                                    <label htmlFor="option-1" className="option option-1">
                                        <div className="dot"></div>
                                        <span className='port-from-buysell-span'>Buy</span>
                                    </label>
                                    {/* <label htmlFor="option-2" className="option option-2">
                                    <div className="dot"></div>
                                    <span className='port-from-buysell-span'>Sell</span>
                                </label> */}
                                </div>
                            </div>




                            {/* <div className='portfolio-submit-container flex flex-end'> */}
                                <button type='submit' disabled={price === 0} className='btn btn-border flex'>Submit</button>
                            {/* </div> */}



                        </div>




                    </div>
                }
            </form>
        </div>
    );
}

export default PortfolioForm;