import React, { useState, useEffect } from "react";
import Select, { components } from 'react-select';
import { customStyles, DropdownIndicator } from "../../../shared/dropdown-styles";
import { dropdownIcon } from "../../../shared/icons/icons";


const PortfolioSectorsFilter = ({
    activePage,
    sectorFilters,
    activeSectors,
    onChangeFilter,
    onChangeCompanies,
    setCompanyFilters,
    companyFilters,
    fullCompanyFilter
}) => {

    useEffect(() => {
        if (activePage === 'fundamental') {
            onChangeFilter([sectorFilters[0]]);
        }
    }, [activePage]);

    const [showOptions, setShowOptions] = useState(false);

    const onHandleMouseEnter = () => {
        setShowOptions(true);
    };

    const onHandleMouseLeave = () => {
        setShowOptions(false);
    };

    const handleChange = (selectedOptions) => {
        if (!selectedOptions.length) return;
        const newSelectedSectors = activePage === 'fundamental'
            ? [selectedOptions[selectedOptions.length - 1]]
            : selectedOptions;

        const filteredCompanies = fullCompanyFilter.filter((company) => {
            const matchingOption = selectedOptions.find(
                (option) => option.value === fullCompanyFilter.find((c) => c.value === company.value).sector
            );
            return matchingOption !== undefined;
        });
        setCompanyFilters(filteredCompanies);
        onChangeCompanies(filteredCompanies);
        onChangeFilter(newSelectedSectors);
    };

    return (
        <div className="dropdown-select-filter-item"
            onMouseEnter={onHandleMouseEnter}
            onMouseLeave={onHandleMouseLeave}
        >
            {
                <Select
                    styles={customStyles}
                    components={{
                        DropdownIndicator,
                    }}
                    isMulti
                    options={sectorFilters}
                    value={activeSectors}
                    onChange={handleChange}
                    menuIsOpen={showOptions}
                    hideSelectedOptions={false}
                />
            }
            {
                <div className="dropdown-label flex align-center">
                    {activePage === 'fundamental' ? (
                        <span className="dropdown-indicator flex center-center"
                            style={{ margin: '0 auto' }}
                        >
                            {activeSectors.map((sector) => sector.label).join(', ')}
                            <span className="dropdown-indicator flex center-center">{dropdownIcon}</span>
                        </span>
                    ) : (
                        <>
                            <div className={`dropdown-label flex align-center ${activeSectors.length !== sectorFilters.length ? 'incomplete-dropdown' : ''}`}>Sectors <span className='dropdown-indicator flex center-center'>{dropdownIcon}</span></div>
                        </>
                    )}
                </div>
            }
        </div>
    );
}

export default PortfolioSectorsFilter;