import { useDispatch } from "react-redux";
import { setGlobalIsLoading } from "../../store/loading/loading-actions";

const PrivacyNotice = () => {

    const dispatch = useDispatch();
    dispatch(setGlobalIsLoading(false));

    const privacyNotice = [
        {
            title: '',
            content: [
                'We, at PortFollow Ltd. ("us," "we," or "our"), acknowledge and respect the importance of privacy for our customers and users. This Privacy Notice outlines the types of information we collect from you when you visit our web platform through this website ("Platform") and/or use the associated services, including cash flow management and additional services (collectively, the "Services"). This Privacy Notice also describes how we process, transfer, store, and disclose the collected information, along with your ability to control certain uses of the information. If not explicitly defined herein, capitalized terms have the meaning given to them in the Terms of Service ("Terms"). "You" refers to an individual using the Services.',
                '"Personal Data" refers to any information that refers to, is related to, or is associated with an identified or identifiable individual, as defined by applicable law. This Privacy Notice details the Personal Data we collect when providing the Services.'
            ]
        },
        {
            title: '1. Personal Data We Collect',
            content: [
                '1.1. Registration. To use some of our Services, you must register an account and provide your email address.',
                '1.2. Contact: We collect Personal Data when you engage with the Platform, request information, or contact us for any reason. This may include your name, email address, the company you represent, and the content of your inquiry.',
                '1.3. User Content. Any materials, including images, documents, or similar materials that you upload to the Services are also collected.',
                '1.4. Automatically Collected Data: When using the Services, certain information about your computer or mobile device, such as operating system, IP address, device ID, and browsing history, may be gathered.',
                '1.5. Voluntary Decision: You are under no legal obligation to provide us with Personal Data. Your decision to provide such information is voluntary. However, refusal to provide information may affect our ability to register you and provide Services.'
            ]
        },
        {
            title: '2. How We Use Your Personal Data',
            content: [
                '2.1. General: We and trusted third-party subcontractors use collected Personal Data to provide Services, respond to inquiries, develop products, contact you with informational materials, review usage, prevent fraud, and provide customer support.',
                '2.2. Statistical Information and Analytics: We use analytics tools to collect and analyze information anonymously, sharing aggregated information with partners at our discretion.'
            ]
        },
        {
            title: '3. Sharing the Personal Data We Collect',
            content: [
                '3.1. Service Providers and Subcontractors: We disclose information, including Personal Data, to trusted service providers and subcontractors to help provide Services and understand user behavior.',
                '3.2. International Transfers: By accepting this Privacy Notice, you acknowledge and agree to international transfers of information.',
                '3.3. Business Transfers: Personal Data may be disclosed during mergers, asset sales, or acquisitions.',
                '3.4. Law Enforcement Related Disclosure: We may share Personal Data to protect rights, property, safety, comply with legal obligations, or respond to legal authorities.',
                '3.5. Legal Uses: Personal Data may be used as required or permitted by applicable law.'
            ]
        },
        {
            title: '4. Security',
            content: [
                'Efforts are made to follow industry standards for data protection. However, absolute security cannot be guaranteed, and users are encouraged to take appropriate measures to protect their information.'
            ]
        },
        {
            title: '5. Your Rights',
            content: [
                'Subject to applicable law, you may request to access or correct Personal Data by emailing portfollow.web@gmail.com.'
            ]
        },
        {
            title: '6. Data Retention',
            content: [
                'Personal Data is retained as necessary for specified purposes, and information may be deleted without notice once deemed no longer necessary.'
            ]
        },
        {
            title: '7. Cookies and Similar Technologies',
            content: [
                '7.1. Use of Cookies: We use cookies to improve your experience on our site, analyze site traffic, and serve personalized content. By using our website, you consent to our use of cookies.',
                '7.2. Types of Cookies: We use both session and persistent cookies for the following purposes:',
                '•	Essential Cookies: Necessary for the website to function.',
                '•	Performance and Analytics Cookies: To measure and improve site performance.',
                '7.3. Managing Cookies: You can control and manage cookies through your browser settings. However, disabling cookies may affect your experience on our site.'

            ]
        },
        {
            title: '8. Third-Party Applications and Services',
            content: [
                'Use of third-party applications or services is at your own risk and subject to the terms and privacy policies of those entities.'
            ]
        },
        {
            title: '9. Communications',
            content: [
                'We reserve the right to send service-related communications, and users may cancel their account to cease receiving such communications.'
            ]
        },
        {
            title: '10. Children',
            content: [
                'We do not knowingly collect Personal Data from children under the age of eighteen (18).'
            ]
        },
        {
            title: '11. Changes to the Privacy Notice',
            content: [
                'This Privacy Notice may be updated, and material changes will be communicated through the Platform or email.'
            ]
        },
        {
            title: '12. Comments and Questions',
            content: [
                'For comments, questions, or to exercise legal rights, contact us at findatasheets@gmail.com.'
            ]
        },

        {
            title: '13. Automated Data Collection',
            content: [
                '13.1. Accuracy of Data: As an automated service, data collected by our web crawlers may not always be accurate. Users should verify the accuracy of the data before making any decisions based on it.',
                '13.2. Data Delays: Due to the nature of automated data collection, there may be delays in the data presented on our platform. Users are responsible for ensuring they have the most current data from professional sources, especially when making financial or trading decisions.'
            ]
        },
        {
            title: '14. User Responsibility',
            content: [
                '14.1. Verification: Users are encouraged to verify the data provided by PortFollow with other reliable sources before making any financial or trading decisions.',
                '14.2. Professional Platforms: For real-time trading and other financial activities involving real money, users should use professional trading platforms. PortFollow is intended for educational and informational purposes only.'
            ]
        },
        {
            title: '15. Third-Party Data Sources',
            content: [
                '15.1. No Affiliation: PortFollow does not claim any endorsement or affiliation with the third-party websites from which data is collected. The data is sourced from publicly accessible domains, and any similarities to the data from the original source websites are coincidental.'
            ]
        },
        {
            title: '16. Legal Indemnity',
            content: [
                '16.1. Indemnification: By using PortFollow\'s services, users agree to indemnify and hold harmless PortFollow from any claims, damages, or legal actions arising from the use of data collected from third-party websites.'
            ]
        },
        {
            title: '17. Transparency and Accountability',
            content: [
                '17.1. Transparency: We are committed to transparency in our data collection and processing practices. Users can contact us at portfollow.web@gmail.com for detailed information about our data practices.',
                '17.2. Accountability: We hold ourselves accountable to our users for maintaining the highest standards of data privacy and protection. Any concerns or complaints about our data practices will be addressed promptly.'
            ]
        },
        {
            title: '18. Consent Withdrawal',
            content: [
                '18.1. Withdrawing Consent: Users have the right to withdraw their consent to data processing at any time. Withdrawal of consent may affect the ability to use certain features of the Services.',
                '18.2. Process for Withdrawal: To withdraw consent, users should contact us at portfollow.web@gmail.com. We will process such requests in accordance with applicable laws and regulations.'
            ]
        },
        {
            title: '19. Data Minimization',
            content: [
                '19.1. Minimization Principle: We adhere to the principle of data minimization, ensuring that we only collect and process the data necessary for the purposes outlined in this Privacy Notice.',
                '19.2. Review of Data Practices: We periodically review our data collection and processing practices to ensure they remain aligned with the principle of data minimization.',
                '19.3. Anonymization: Where possible, we anonymize personal data to ensure that individuals cannot be identified from the data we hold.'
            ]
        },
    ]

    return (
        <div className="information-container main-content-container single-company-header">
            <div className="card-container information-item-container">
                <h1 className="information-item-main-title">Privacy Notice</h1>
                {
                    privacyNotice.map((item, index) => (
                        <div className="information-item" key={index}>
                            <h3 className="information-item-title">{item.title}</h3>
                            {
                                item.content.map((paragraph, idx) => (
                                    <p className="information-item-p" key={idx}>{paragraph}</p>
                                ))}
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default PrivacyNotice;