import React from 'react';
import CardContainer from "../../../../cmps/card-container/card-container";
import { formatNumberToDollarTableString } from '../../../../shared/global-color-functions';
import { enUS } from 'date-fns/locale';
import { useMediaQuery } from 'react-responsive';
import BarChart from '../../../../shared/charts/bar-chart/bar-chart';
import { Line } from 'react-chartjs-2';
const FundamentalGraphItem = ({ chartType, chartData, chartName }) => {

    const mediumScreen = useMediaQuery({ maxWidth: 920 });
    const smallScreen = useMediaQuery({ maxWidth: 800 });

    const areaChartOptions = {
        elements: {
            point: {
                radius: 0
            }
        },
        font: {
            size: smallScreen ? 8 : (mediumScreen ? 12 : 14),
            family: 'Poppins-regular'
        },
        plugins: {
            legend: {
                display: false,
                labels: {
                    boxWidth: 0,
                },
            },
            filler: {
                propagate: true
            }
        },
        scales: {
            x: {
                ticks: {
                    maxTicksLimit: mediumScreen ? 4 : 5,
                    font: {
                        size: smallScreen ? 8 : (mediumScreen ? 12 : 14),
                    },
                },
                type: 'time',
                distribution: 'linear',
                adapters: {
                    date: {
                        locale: enUS,
                    },
                },
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'MMM yy',
                    }
                }
            },
            y: {
                ticks: {
                    precision: 2,
                    maxTicksLimit: mediumScreen ? 4 : 5,
                    font: {
                        size: smallScreen ? 8 : (mediumScreen ? 12 : 14),
                    },

                    callback: function (value, index, values) {
                        if (index === 0) {
                            return '';
                        }
                        switch (chartName) {
                            case "currentRatio":
                            case "quickRatio":
                            case "debtToEquity":
                            case "assetsTurnover":
                            case "assetCoverage":
                            case "debtServiceCoverage":
                            case "interestCoverage":
                                return value + 'x';
                            // return parseFloat(value).toFixed(2) + 'x'
                            case "debtToAssets":
                            case "shareholderEquityRatio":
                            case "grossProfitMargin":
                            case "percentPureProfit":
                            case "returnOnAssets":
                            case "returnOnEquity":
                            case "efficiencyRatio":
                            case "percentOperatingIncome":
                            case "dividendPayoutRatio":
                            case "debtToAssets":
                                return value + '%';
                            case "dividendPerShare":
                            case "earningsPerShare":
                                return formatNumberToDollarTableString(value);
                            case "daysSalesOutstanding":
                                return value + ' Days';
                            case "inventoryTurnover":
                                return value + ' Units';
                            // return parseFloat(value).toFixed(1) + ' Units'
                            default:
                                // Handle default case, you may want to return a default format
                                return value.toFixed(2);
                        }
                    }
                }
            }
        }
    };

    const AreaChart = ({ chartData }) => {
        return <Line data={chartData} options={areaChartOptions} />;
    };

    const FundamentalTitle = () => {
        if (!chartName) return null;
        const formattedTitle = chartName.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
        return (
            <>
                <h4 className='portfolio-chart-title'>{formattedTitle}</h4>
            </>
        );
    };

    const FundamentalContent = ({ chartType, chartData }) => (
        <>
            {chartType === 'bar' ? (
                <BarChart type={'company'} chartData={chartData} className={'mobile-chart-size'} onChangeSelectedItem={() => { }} />
            ) : (
                <AreaChart type={'company'} chartData={chartData} className={'mobile-chart-size'} onChangeSelectedItem={() => { }} />
            )}
        </>
    );

    const titleComponent = <FundamentalTitle />;
    const innerContentComponent = <FundamentalContent chartType={chartType} chartData={chartData} />;

    return (
        <CardContainer
            key={chartName}
            title={titleComponent}
            innerContent={innerContentComponent}
            className={'chart-wrapper'}
            infoModalType={chartName}
        />
    );
}

export default FundamentalGraphItem;