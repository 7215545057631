import React, { useState } from 'react';
import './faq.scss';
import { arrowDownIcon, arrowUpIcon } from '../../shared/icons/icons';

const FAQ = () => {

    const qa = [
        {
            question: `What is the stock market?`,
            answer: `The stock market is a collection of markets where stocks (shares of ownership in businesses) are bought and sold.
            <br/>
            It provides a way for companies to raise capital and for investors to potentially earn returns on their investments through dividends or stock price increases.`

        },
        {
            question: `What are stocks and bonds?`,
            answer: `Stocks represent partial ownership in a company, giving you a claim to part of the company earnings and assets. 
            <br/>
             Bonds, on the other hand, are loans made by investors to borrowers (usually corporations or governmental entities), who pay back the loan with interest over time.`
        },
        {
            question: `What does it mean to diversify my investments?`,
            answer: `Diversification means spreading your investments across various asset classes (like stocks, bonds, real estate) and within asset classes (different sectors, industries, or countries). This strategy helps reduce risk because it\'s unlikely that all segments will perform poorly at the same time.`
        },
        {
            question: `How important is risk management in becoming a better investor?`,
            answer: `Risk management is crucial.
            <br/>
            Our platform provides tools to help you understand and manage risks effectively.
            <br/>
            You can learn to diversify your investments, set stop - loss orders, and monitor your exposure to minimize potential losses while maximizing returns.`
        },
        {
            question: `Should I invest in individual stocks or mutual funds?`,
            answer: `This depends on your risk tolerance, time, and interest in researching stocks. 
            <br/>
            Mutual funds, which pool money from many investors to purchase a diversified portfolio of stocks or bonds, offer built-in diversification and are managed by professionals, making them a good choice for beginners.`
        },
        {
            question: `How do I know which stocks to buy?`,
            answer: `Choosing stocks involves researching and analyzing companies, considering factors like financial health, market position, and future growth potential. 
            <br/> 
            Beginners may start with well - known, stable companies, or consider using mutual funds or exchange-traded funds (ETFs) to gain exposure to a broader market.`
        },
        {
            question: `What are the risks of stock market investing?`,
            answer: `The primary risk is losing money.Stock prices can fluctuate widely due to factors like economic changes, market trends, and company performance.It\'s important to understand that all investments carry some level of risk, and there is no guarantee of a return.`
        },
        {
            question: `How long should I hold my investments?`,
            answer: `Investing in the stock market is generally considered a long - term endeavor. <br/>
            Holding investments for several years or decades allows you to ride out volatility and benefit from the potential growth of the companies you invest in.`
        },
        {
            question: `What is the difference between trading and investing?`,
            answer: `Trading involves buying and selling stocks frequently, typically aiming to capitalize on short - term market fluctuations. <br/>
            Investing, on the other hand, means purchasing stocks with the intention of holding them for a longer period to benefit from the company\'s growth and long- term market trends.`
        },
        {
            question: `How do dividends work?`,
            answer: `Dividends are a portion of a company\'s earnings distributed to shareholders as a reward for their investment. 
            <br/>
            Not all companies pay dividends, but for those that do, dividends are typically paid on a regular basis (e.g., quarterly) and can be taken as cash or reinvested to buy more shares.`
        },
        {
            question: `What are stock indices, and why are they important?`,
            answer: `Stock indices are benchmarks that measure the performance of a specific set of stocks, representing a particular segment of the stock market. <br/>
            For example, the S&P 500 is an index of 500 large U.S.companies.
             <br/>
            Indices help investors understand market trends and compare the performance of individual investments against the broader market.`
        },
        {
            question: `Can you explain market capitalization?`,
            answer: `Market capitalization, or market cap, is the total value of a company\'s outstanding shares of stock.
            <br/>
            It is calculated by multiplying the current stock price by the total number of shares outstanding.Market cap is used to categorize companies into different sizes, such as small - cap, mid - cap, and large - cap, which can indicate a company\'s stability and growth potential.`
        },
        {
            question: `What is a bear market versus a bull market?`,
            answer: `A bear market refers to a period of declining stock prices, typically by 20% or more from recent highs, and is often accompanied by widespread pessimism.
            <br/>
            A bull market is the opposite, characterized by rising prices and typically reflects economic strength and high investor confidence.`
        },
        {
            question: `How do I manage risk when investing?`,
            answer: `Managing risk involves diversifying your portfolio, setting stop - loss orders to limit potential losses, understanding your own risk tolerance, and continuously educating yourself about the markets and your investments.
            <br/>
            It\'s also wise to avoid investing money that you might need in the short term.`
        },
        {
            question: `What are ETFs and how do they differ from mutual funds?`,
            answer: `Exchange - Traded Funds (ETFs) are investment funds traded on stock exchanges, much like stocks.
            <br/>
            They hold assets such as stocks, commodities, or bonds and generally operate with an arbitrage mechanism designed to keep the trading close to its net asset value, though deviations can occur.
            <br/>
            Mutual funds, on the other hand, are not traded on a stock exchange and are valued at the end of the trading day; they also typically have higher fees than ETFs.`
        },
        {
            question: `How often should I check my investments?`,
            answer: `The frequency of monitoring your investments should align with your investment strategy. Long-term investors may check their portfolios quarterly or bi - annually to make adjustments, while active traders might monitor their investments daily. 
            <br/>
            Regular reviews can help you stay on track with your financial goals and adjust as needed based on market conditions or personal life changes.`
        },
        {
            question: `How do I start investing in the stock market?`,
            answer: `To start investing, you\'ll need to:
            <br/>
            Open a brokerage account: Choose a reputable brokerage firm, set up an account, and deposit funds. 
            <br>
            Research investments: Learn about different companies and investment vehicles, like stocks, bonds, and mutual funds.
            <br/>
            Make your first investment: Decide on an investment, how many shares to buy, and then place an order through your brokerage account.`
        },
        {
            question: `What is a brokerage account, and how do I choose one?`,
            answer: `A brokerage account is an arrangement that allows an investor to deposit funds and place investment orders through a licensed brokerage firm.
            <br/>
            When choosing a brokerage, consider factors such as fees, services offered, research and learning resources, and the platform\'s ease of use.`
        },
        {
            question: `How much money do I need to start investing?`,
            answer: `Many brokerage firms have no minimum investment requirement, although some specific investments, like certain mutual funds, may require a minimum starting investment.
            <br/>
            You can start small, especially with services that offer fractional shares of stocks and ETFs.`
        },
        {
            question: `What are the tax implications of investing?`,
            answer: `Investing can have various tax implications, including capital gains taxes on profits from selling stocks at a higher price than you purchased them.
            <br/>
            Dividends can also be taxed. It\'s important to understand these implications and plan accordingly, possibly with the help of a tax professional.`
        },
        {
            question: `What does it mean to become a better investor on your platform?`,
            answer: `Becoming a better investor on our platform means leveraging our unique community lineage to find ideal risk and investment matches, engaging in structured challenges that enhance your investing skills, and utilizing our advanced comparative financial and statistical analysis tools to refine your investment strategy.`
        },
        {
            question: `How can I use community lineage to improve my investment decisions?`,
            answer: `Our community lineage system connects you with a network of investors who share similar financial goals and risk profiles.
            <br/>
            By collaborating and sharing insights with these aligned investors, you can gain deeper market insights, learn from their experiences, and make more informed decisions.`
        },
        {
            question: `What are the challenges and achievements on the platform, and how do they help me learn?`,
            answer: `Our platform offers a series of incremental challenges designed to test and expand your investment skills.Completing these challenges helps you achieve milestones and earn rewards.
            <br/> Each challenge is an opportunity to learn practical investing strategies and apply them in real-time, accelerating your growth as an investor.`
        },
        {
            question: `How does comparative financial and statistical portfolio analysis enhance my investing capabilities?`,
            answer: `Our comparative analysis tools allow you to benchmark your portfolio against those of top performers in similar categories.
            <br/>
            This not only highlights potential areas for improvement but also helps you understand the strategies behind successful portfolios, enabling you to adopt similar tactics.`
        },
        {
            question: `Can I tailor investment strategies to fit my personal financial goals?`,
            answer: `Absolutely.
            <br/>
            Our platform enables you to customize your investment strategies based on your personal financial goals, risk tolerance, and time horizon.
            <br/>
            You can use our tools to simulate different scenarios and outcomes, helping you fine - tune your strategy before applying it in real markets.`
        },
        {
            question: `Is there a way to track my progress as an investor on your platform?`,
            answer: `Yes, our platform includes a comprehensive tracking system that records your investments, your participation in challenges, and your use of analysis tools.
            <br/>
            This allows you to see your growth over time, identify strengths, and address areas where you might improve.`
        }
    ];

    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleAnswer = (index) => {
        if (expandedIndex === index) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(index);
        }
    };

    return (
        <div className='faq-container main-content-container mt'>
            <div className="card-container mb20">
                <h1>FAQ</h1>
            </div>
            {qa.map((item, index) => (
                <div key={index} className="card-container flex column mb20" style={{cursor: 'pointer'}} onClick={() => toggleAnswer(index)}>
                    <div className="flex space-between mb20">
                        <div className='faq-question'>{item.question}</div>
                        <div className='btn btn-border'>
                            {expandedIndex === index ? arrowUpIcon : arrowDownIcon}
                        </div>
                    </div>
                    {expandedIndex === index && (
                        <div className="faq-answer" dangerouslySetInnerHTML={{ __html: item.answer }} />
                    )}
                </div>
            ))}
        </div>
    );

};

export default FAQ;