import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setGlobalIsLoading } from "../../../store/loading/loading-actions";
import ComparisonBarchartsChart from "./comparison-barcharts-chart";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";

const ComparisonBarchartItem = ({ type, charts }) => {

    const mediumScreen = useMediaQuery({ maxWidth: 780 });
    const history = useHistory();
    const dispatch = useDispatch();

    const [canvasHeight, setCanvasHeight] = useState('20px');

    const onNavigate = (label) => {
        dispatch(setGlobalIsLoading(true));
        history.push(`/${type}/${label}`);
    };

    const onChangeCanvasHeight = (height) => {
        setCanvasHeight(height + 'px');
    };


    return (
        <div className={`flex ${mediumScreen ? 'portfolio-medium-screen-bottom column' : 'portfolio-container-bottom'}`} >
            {/* <div className={`portfolio-container-bottom flex ${mediumScreen ? 'column' : ''}`} > */}
            <div className={`portfolio-comparison-barcharts-item card-container flex space-between ${mediumScreen ? '' : ''}`}>

                <div className="flex column space-between">
                    <div className="portfolio-comparison-barcharts-item-title"></div>
                    <div className="portfolio-comparison-barcharts-item-labels flex column space-around" style={{ height: canvasHeight }}>
                        {
                            charts.return.labels.map((label, index) => (
                                <div
                                    className="clickable-label"
                                    onClick={() => onNavigate(label)}
                                    key={index}
                                    title={charts.return.names[index]}
                                    style={{ marginRight: '5px' }}
                                >{label}
                                </div>
                            ))
                        }
                    </div>
                </div>

                {charts.return && <ComparisonBarchartsChart onChangeCanvasHeight={onChangeCanvasHeight} mediumScreen={mediumScreen} chartTitle={'Return'} chartData={charts.return} />}
                {charts.sharpe && <ComparisonBarchartsChart onChangeCanvasHeight={onChangeCanvasHeight} mediumScreen={mediumScreen} chartTitle={'Sharpe'} chartData={charts.sharpe} />}
                {charts.info && <ComparisonBarchartsChart onChangeCanvasHeight={onChangeCanvasHeight} mediumScreen={mediumScreen} chartTitle={'Info'} chartData={charts.info} />}
            </div>

            {/* ${mediumScreen ? 'portfolio-medium-screen-bottom column' : 'portfolio-container-bottom'}`} */}
            <div className={` ${!mediumScreen ? 'portfolio-comparison-barcharts-item card-container flex space-between' : 'portfolio-comparison-barcharts-item card-container flex space-between'}`}>

                {/* <div className="portfolio-comparison-barcharts-item card-container flex space-between "> */}
                <div className="flex column space-between">

                    <div className="portfolio-comparison-barcharts-item-title"></div>
                    <div className="portfolio-comparison-barcharts-item-labels flex column space-around" style={{ height: canvasHeight }}>
                        {
                            charts.std.labels.map((label, index) => (
                                <div
                                    className="clickable-label"
                                    onClick={() => onNavigate(label)}
                                    key={index}
                                    style={{ marginRight: '5px' }}
                                    title={charts.std.names[index]}
                                >
                                    {label}
                                </div>
                            ))
                        }
                    </div>
                </div>

                {charts.std && <ComparisonBarchartsChart onChangeCanvasHeight={onChangeCanvasHeight} mediumScreen={mediumScreen} chartTitle={'Variability'} chartData={charts.std} />}
                {charts.beta && <ComparisonBarchartsChart onChangeCanvasHeight={onChangeCanvasHeight} mediumScreen={mediumScreen} chartTitle={'Beta'} chartData={charts.beta} />}
                {charts.posDays && <ComparisonBarchartsChart onChangeCanvasHeight={onChangeCanvasHeight} mediumScreen={mediumScreen} chartTitle={'Positive Days'} chartData={charts.posDays} />}
            </div>
        </div>
    );
}

export default ComparisonBarchartItem;