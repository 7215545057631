import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReturnSharpe from '../../cmps/return-sharpe/return-sharpe';
import ActivePageSelection from '../../shared/active-page/active-page';
import LoadingSpinner from '../../shared/loading-spinner/loading-spinner';
import { fetchDataFromLambda } from '../../shared/s3-bucket-functions';
import { setGlobalIsLoading } from '../../store/loading/loading-actions';
import CryptoLinechart from './crypto-content/crypto-linechart';
import CryptoTable from './crypto-content/crypto-table';
import CryptoFilters from './crypto-filters';
import { hexToRGBA } from "../../shared/global-color-functions";
import { Helmet } from "react-helmet";
import './crypto.scss';
import CryptoGuide from './crypto-guide/crypto-guide';
import { useHistory, useLocation } from 'react-router-dom';
import { CryptoPageGuide } from '../../cmps/page-guide';

const Crypto = ({ navPage, pageCrypto, setPageCrypto, toggleFollowing, userFollowing, loggedInUser }) => {

    const history = useHistory();
    const location = useLocation();

    const [activeOption, setActiveOption] = useState(navPage);
    const [selectedDataFilter, setSelectedDataFilter] = useState(null);
    const [activePriceRelative, setActivePriceRelative] = useState('relative');

    const [categoriesToDisplay, setCategoriesToDisplay] = useState(null);
    const [currenciesToDisplay, setCurrenciesToDisplay] = useState(null);
    const [currenciesOptions, setCurrenciesOptions] = useState(null);
    const [categoriesOptions, setCategoriesOptions] = useState(null);

    const [lineChartData, setLineChartData] = useState(null);
    const [barChartsData, setBarChartsData] = useState(null);
    const [tableData, setTableDate] = useState(null);

    const [cryptoColors, setCryptoColors] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const loadingSelector = useSelector((state) => state.loading.isLoading);
    const dispatch = useDispatch();

    const [favoritesCategoriesOptions, setFavoritesCategoriesOptions] = useState(null);
    const [favoritesCurrenciesOptions, setFavoritesCurrenciesOptions] = useState(null);
    const [favoritesCurrenciesToDisplay, setFavoritesCurrenciesToDisplay] = useState(null);
    const [favoritesCategoriesToDisplay, setFavoritesCategoriesToDisplay] = useState(null);
    const [favoritesLineChartData, setFavoritesLineChartData] = useState(null);
    const [favoritesBarChartsData, setFavoritesBarChartsData] = useState(null);
    const [favoritesTableData, setFavoritesTableData] = useState(null);
    const [favoritesCryptoColors, setFavoritesCryptoColors] = useState(null);
    const [pages, setPages] = useState(['All']);

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setGlobalIsLoading(true));
            if (Object.keys(pageCrypto).length === 0) {
                try {
                    const res = await fetchDataFromLambda('crypto-page');
                    const cryptoData = res.cryptoPage;
                    if (res) {
                        setPageCrypto({
                            defaultColors: cryptoData.defaultColors,
                            groups: cryptoData.groups,
                            price: cryptoData.price,
                            relative: cryptoData.relative,
                            returns: cryptoData.return,
                            sharpe: cryptoData.sharpe,
                            table: cryptoData.table,
                        });
                        const cryptoColorsRes = cryptoData.defaultColors.map(({ label, symbol, color }) => ({
                            label: symbol,
                            symbol: symbol,
                            color
                        }));
                        setCryptoColors(cryptoColorsRes);
                        dispatch(setGlobalIsLoading(false));
                    }
                } catch (error) {
                    // Handle errors as needed
                    console.error('Error fetching data:', error);
                }
            } else {
                const cryptoColorsRes = pageCrypto.defaultColors.map(({ label, symbol, color }) => ({
                    symbol: symbol,
                    label: symbol,
                    color
                }));
                setCryptoColors(cryptoColorsRes)
                dispatch(setGlobalIsLoading(false));
            }
        };
        fetchData();

        const handleClick = (event) => {
            setSelectedItem(null);
        };
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };

    }, []);

    useEffect(() => {
        if (!pageCrypto.groups) return;
        const namesArr = pageCrypto.groups.reduce((acc, curr) => {
            if (!acc.find(item => item.value === curr.name)) {
                acc.push({ label: curr.name, value: curr.name, symbol: curr.symbol });
            }
            return acc;
        }, []);

        const groupsArr = pageCrypto.groups.reduce((acc, curr) => {
            curr.group.forEach(group => {
                if (!acc.find(item => item.value === group)) {
                    acc.push({ label: group, value: group });
                }
            });
            return acc;
        }, []);
        setCurrenciesOptions(namesArr);
        setCategoriesOptions(groupsArr);
        setCurrenciesToDisplay(namesArr);
        setCategoriesToDisplay(groupsArr);

        const favouriteCryptoCurrencies = userFollowing || [];
        const filteredNamesArr = namesArr?.filter(item => favouriteCryptoCurrencies.includes(item.symbol));

        const favoritesGroups = pageCrypto.groups.filter(group => {
            return filteredNamesArr.some(filteredItem => filteredItem.symbol === group.symbol);
        });

        const favoritesGroupsArr = favoritesGroups.reduce((acc, curr) => {
            curr.group.forEach(group => {
                if (!acc.find(item => item.value === group)) {
                    acc.push({ label: group, value: group });
                }
            });
            return acc;
        }, []);

        setFavoritesCategoriesOptions(favoritesGroupsArr);
        setFavoritesCategoriesToDisplay(favoritesGroupsArr);
        setFavoritesCurrenciesOptions(filteredNamesArr);
        setFavoritesCurrenciesToDisplay([filteredNamesArr]);

    }, [pageCrypto, activeOption, userFollowing]);

    useEffect(() => {
        if (!pageCrypto || !selectedDataFilter || !activePriceRelative) return;
        const lineChartRes = getLineChartData(pageCrypto[activePriceRelative][selectedDataFilter.period]);
        const barChartRes = getBarChartsData(pageCrypto.returns[selectedDataFilter.period], pageCrypto.sharpe[selectedDataFilter.period]);
        const tableDataRes = getTableData(pageCrypto.table[selectedDataFilter.period]);
        setLineChartData(lineChartRes);
        setBarChartsData(barChartRes);
        setTableDate(tableDataRes);

        const favouriteCryptoCurrencies = userFollowing || [];
        const filteredFavoritesLinechart = lineChartRes.filter(item => favouriteCryptoCurrencies.includes(item.symbol));

        const filteredFavoritesBarchartsReturn = pageCrypto.returns[selectedDataFilter.period].filter(item => favouriteCryptoCurrencies.includes(item.key));
        const filteredFavoritesBarchartsSharpe = pageCrypto.sharpe[selectedDataFilter.period].filter(item => favouriteCryptoCurrencies.includes(item.key));
        const filteredFavouritesBarcharts = getBarChartsData(filteredFavoritesBarchartsReturn, filteredFavoritesBarchartsSharpe);

        const filteredFavoritesTable = tableDataRes.filter(item => {
            return favouriteCryptoCurrencies.includes(item.symbol);
        });
        const filteredFavoritesCryptoColors = cryptoColors.filter(item => {
            return favouriteCryptoCurrencies.includes(item.symbol);
        });

        setFavoritesLineChartData(filteredFavoritesLinechart);
        setFavoritesBarChartsData(filteredFavouritesBarcharts);

        setFavoritesTableData(filteredFavoritesTable);
        setFavoritesCryptoColors(filteredFavoritesCryptoColors);

    }, [pageCrypto, activePriceRelative, selectedDataFilter, selectedDataFilter, userFollowing, activeOption, selectedItem]);

    const handleFavoritesCrypto = (symbol) => {
        toggleFollowing('crypto', symbol);
    };

    const handleFilterChange = (period, currencies, categories) => {
        const newFilters = {
            period,
            categories,
            currencies
        };
        setSelectedDataFilter(newFilters);
    };

    const handleToggleCryptoPage = (option) => {
        if (option === 'guide') {
            console.log('guide');
            history.push('/crypto');
            setActiveOption('Guide');
            return;
        }
        const navTo = option === 'all' ? 'currencies' : 'favorites';
        if (navTo === 'favorites' && (!userFollowing || !userFollowing.length)) return;
        history.push(`/crypto/${navTo}`);
        const capitalizedOption = option.charAt(0).toUpperCase() + option.slice(1);
        if (capitalizedOption === 'Favorites' && !userFollowing.length) return;
        setActiveOption(capitalizedOption);
    };

    const getLineChartData = (linechartData) => {
        let filteredLinechart = [];
        filteredLinechart = linechartData.filter(item => {
            const group = pageCrypto.groups.find(group => group.name === item.key);
            if (group && group.group.some(g => selectedDataFilter.categories.includes(g))) {
                return true;
            }
            return false;
        });
        filteredLinechart = filteredLinechart.filter(item => selectedDataFilter.currencies.includes(item.key));
        filteredLinechart.forEach(item => {
            const colorItem = pageCrypto.defaultColors.find(colorItem => colorItem.label === item.key);
            if (colorItem) {
                item.color = colorItem.color;
                item.symbol = colorItem.symbol
            }
        });
        return filteredLinechart;
    };

    const getBarChartsData = (returnData, sharpeData) => {
        const processChartData = (data, type) => {
            let filteredData = data.filter(item => {
                const group = pageCrypto.groups.find(group => group.symbol === item.key);
                if (group && group.group.some(g => selectedDataFilter.categories.includes(g))) {
                    return true;
                }
                return false;
            });
            filteredData = filteredData.filter((item) => {
                const matchingGroup = pageCrypto.groups.find((group) => group.symbol === item.key);
                if (matchingGroup) {
                    const currencyName = matchingGroup.name;
                    return selectedDataFilter.currencies.includes(currencyName);
                }
                return false;
            });
            filteredData.sort((a, b) => (b[type]) - (a[type]));
            if (selectedItem !== null && selectedItem !== undefined) {
                const selectedIndex = filteredData.findIndex((item) => item.key === selectedItem);
                const indices = [selectedIndex - 2, selectedIndex - 1, selectedIndex, selectedIndex + 1, selectedIndex + 2];
                const validIndices = indices.filter((index) => index >= 0 && index < filteredData.length);
                filteredData = validIndices.map((index) => filteredData[index]);
            } else {
                // Filter the first 5 items
                filteredData = filteredData.slice(0, 5);
            }

            const labels = filteredData.map(item => item.key);
            const hover = filteredData.map(item => item.hover);
            const datasetData = filteredData.map(item => Math.abs(item[type]));
            // Handle backgroundColor and opacity
            const backgroundOpacity = selectedItem !== null && selectedItem !== undefined ? 0.2 : 1;
            const backgroundColor = filteredData.map((item, index) =>
                selectedItem !== null && selectedItem !== undefined && item.key !== selectedItem
                    ? hexToRGBA((item[type]) > 0 ? '#5c9ead' : '#e39774', backgroundOpacity)
                    : (item[type]) > 0 ? '#5c9ead' : '#e39774'
            );
            const borderColor = backgroundColor;

            return {
                labels,
                hover,
                datasets: [
                    {
                        data: datasetData,
                        backgroundColor,
                        borderColor,
                    },
                ],
            };
        };

        const returnChartData = processChartData(returnData, 'return');
        const sharpeChartData = processChartData(sharpeData, 'sharpe');

        return {
            return: returnChartData,
            sharpe: sharpeChartData,
        };
    };

    const getTableData = (filteredTableData) => {
        let filteredTable = [];
        filteredTable = filteredTableData.filter(item => {
            const group = pageCrypto.groups.find(group => group.name === item.currency);
            if (group && group.group.some(g => selectedDataFilter.categories.includes(g))) {
                return true;
            }
            return false;
        });
        filteredTable = filteredTable.filter(item => selectedDataFilter.currencies.includes(item.currency));
        return filteredTable;
    };

    const changePriceRelative = (option) => {
        setActivePriceRelative((prevOption) => {
            return option.value;
        })
    };

    const onChangeSelectedItem = (item) => {
        const formattedStr = formatString(item);
        setSelectedItem(formattedStr);
    };

    const formatString = (inputString) => {
        if (!inputString) return;
        const words = inputString.split(/\s+/);
        const processedWords = [];

        words.forEach((word, index) => {
            if (index === 0) {
                processedWords.push(word);
            } else if (/^[A-Z]$/.test(word) && index > 0) {
                processedWords[processedWords.length - 1] += word;
            } else {
                processedWords.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
            }
        });

        return processedWords.join(' ');
    };

    useEffect(() => {
        if (loggedInUser) {
            setPages(['All', 'Favorites', 'Guide']);
        } else {
            setPages(['All', 'Guide']);
        }
    }, [loggedInUser])

    const filterGroups = () => {
        const userFollowingGroups = pageCrypto.groups.filter(group =>
            userFollowing.includes(group.symbol)
        );
        return userFollowingGroups;
    };

    const getNavLocation = (label) => {
        return `/crypto/${label}`
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow - Crypto</title>
                <meta name="description" content="Stay updated on cryptocurrency price changes and discover the most relevant and popular currency that captures your interest." />
                <link rel="canonical" href="https://www.portfollow.com/crypto" />
            </Helmet>
            {
                pages &&
                <div className='active-page-container-macro page-margin-top'>
                    <ActivePageSelection
                        handleToggleOption={handleToggleCryptoPage}
                        pages={pages}
                        activePage={activeOption}
                        getNavLocation={getNavLocation}
                    />
                </div>
            }
            {
                activeOption === 'Guide' ? (
                    <div className="main-content-container page-margin-top">
                        <div className="mt20">
                            <CryptoGuide />
                        </div>
                    </div>
                ) : (
                    loadingSelector ? (
                        <LoadingSpinner />
                    ) : (
                        <div
                            className="main-content-container"
                            style={{ marginTop: !pages ? '50px' : '0' }}
                        >
                            {
                                currenciesOptions &&
                                categoriesOptions &&
                                <CryptoFilters
                                    selectedDataFilter={selectedDataFilter}
                                    activeOption={activeOption}
                                    categories={activeOption === 'All' ? categoriesToDisplay : favoritesCategoriesToDisplay}
                                    onFilterChange={handleFilterChange}
                                    activePriceRelative={activePriceRelative}
                                    changePriceRelative={changePriceRelative}
                                    categoriesOptions={activeOption === 'All' ? categoriesOptions : favoritesCategoriesOptions}
                                    setCurrenciesOptions={setCurrenciesOptions}
                                    setFavoritesCurrenciesOptions={setFavoritesCurrenciesOptions}
                                    groups={activeOption === 'All' ? pageCrypto.groups : filterGroups()}
                                    currencies={activeOption === 'All' ? currenciesToDisplay : favoritesCurrenciesToDisplay}
                                    currenciesOptions={activeOption === 'All' ? currenciesOptions : favoritesCurrenciesOptions}
                                />
                            }

                            {
                                lineChartData &&
                                cryptoColors &&
                                barChartsData &&
                                <div className="findatasheets-container mb20">
                                    <CryptoLinechart
                                        lineChartData={activeOption === 'All' ? lineChartData : favoritesLineChartData}
                                        cryptoColors={activeOption === 'All' ? cryptoColors : favoritesCryptoColors}
                                        selectedItem={selectedItem}
                                        onChangeSelectedItem={onChangeSelectedItem}
                                        activePriceRelative={activePriceRelative}
                                    />
                                    <ReturnSharpe
                                        returnValue={{
                                            type: 'crypto',
                                            chartData: activeOption === 'All' ? barChartsData.return : favoritesBarChartsData.return
                                        }}
                                        sharpeValue={{
                                            type: 'crypto',
                                            chartData: activeOption === 'All' ? barChartsData.sharpe : favoritesBarChartsData.sharpe
                                        }}
                                    />
                                </div>
                            }
                            {
                                tableData &&
                                <CryptoTable
                                    tableData={activeOption === 'All' ? tableData : favoritesTableData}
                                    handleFavoritesCrypto={handleFavoritesCrypto}
                                    userFollowing={userFollowing}
                                    loggedInUser={loggedInUser}
                                />
                            }
                        </div>
                    )
                )
            }
            <div className="card-container main-content-container">
                <CryptoPageGuide/>
            </div>
        </>
    );
}

export default Crypto;