const challengesList = [
    {
        id: '0',
        title: 'Cross-Sector Winners',
        tasks: ['Secure the top 5 weekly return performers across 3 sectors', 'Secure the top 7 weekly return performers across 5 sectors'],
        status: '0/3',
        completed: 0
    },
    {
        id: '1',
        title: 'Score Mastery',
        tasks: ['Achieve a Portfolio Score Above 7'],
        status: '0/7',
        completed: 0
    },
    {
        id: '2',
        title: 'Filings Focus',
        tasks: ['Secure 5 performers releasing SEC filings in 30 days'],
        status: '0/5',
        completed: 0
    },
    {
        id: '3',
        title: 'EPS Excellence',
        tasks: ['Secure 5 Performers with EPS (Earnings Per Share) over $2.5'],
        status: '0/5',
        completed: 0
    },
    {
        id: '4',
        title: 'ROE Elite',
        tasks: ['Secure 5 Performers ROE (Return On Equity) over 9%'],
        status: '0/5',
        completed: 0
    },
    {
        id: '5',
        title: 'Portfolio Growth',
        tasks: ['Achieve 4x return over a 7% return on a copied portfolio'],
        status: '0',
        completed: 0
    }
];

export default challengesList;