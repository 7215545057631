import React from 'react';
import FinancialStatementsTable from './table/financial-statements-table';
import './financial-statements.scss';

const FinancialStatements = ({ financialStatementsData, symbol, companyName, noResultsMsg }) => {

    return (
        Object.keys(financialStatementsData).length ? (
                <FinancialStatementsTable
                    symbol={symbol}
                    companyName={companyName}
                    tableData={financialStatementsData}
                />
        ) : (
            noResultsMsg
        )
    );
}

export default FinancialStatements;
