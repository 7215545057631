import React, { useState, useRef, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { formatNumberToDollarTableString } from '../../global-color-functions';

const PieChart = ({ chartData, labels, pieChartSum, portfolioSumPriceAmount, onChangeSelectedItem = () => { } }) => {
  const chartRef = useRef();

  const formattedSum = formatNumberToDollarTableString(pieChartSum);

  const options = {
    cutout: 70,
    plugins: {
      tooltip: {
        enabled: true,
        displayColors: false,
        backgroundColor: '#ffffff',
        titleColor: '#484848',
        borderWidth: '1',
        borderColor: '#484848',
        bodyColor: '#484848',
        cornerRadius: '20',
        padding: '10',
        callbacks: {
          label: (tooltipItem, data) => {
            const index = tooltipItem.dataIndex;
            const label = chartData.hover[index];
            const hoverData = chartData.hover[index];
            return hoverData.map((line, lineIndex) => (
              line.toString()
            ));
          },
        },
      },
      legend: {
        display: false,
      },
    },
    // events: ['click'],
    onClick: (event, chartElements) => {
      if (chartElements[0] && chartElements.length) {
        const clickedSegment = chartElements[0].index;
        onChangeSelectedItem(chartData.labels[clickedSegment]);
      } else {
        onChangeSelectedItem(null);
      }
    },
  };

  return (
    <div className="flex align-center position-relative" style={{ height: '90%', position: 'relative' }}>
      <Doughnut data={chartData} options={options} ref={chartRef} className={'mobile-chart-size-pie'} />
      <div className="flex column portfolio-total-worth">
      {portfolioSumPriceAmount && <div className='flex justify-center'>{portfolioSumPriceAmount}%</div>}
      {portfolioSumPriceAmount && <div className='piechart-midtitle flex justify-center'>Return</div>}
      {/* <div className='flex justify-center'>{formattedSum}</div>
      <div className='piechart-midtitle flex justify-center'>Worth</div> */}
      </div>
    </div>
  );

};

export default PieChart;