import { commentIcon, copyIcon } from "../../../shared/icons/icons";

const InteractiveDemosSlide = () => {
    return (
        <>
            <h2 className="app-tutorial-slide-title">Demo 1 - Copying Portfolio:</h2>

            <p className="app-tutorial-p-strong">
                Explore the Social Feed:
            </p>
            <p className="app-tutorial-p">
                Dive into the Social Feed to discover portfolios shared by other users.<br />
                 Scroll through the feed to find portfolios that catch your interest.
            </p>

            <p className={'app-tutorial-p'}>
                Let's take a closer look at the structure of a typical post:
            </p>

            <div className="flex justify-center">
                <img src="/img/tutorial/post-example.png" alt="post example" className={'tutorial-img tutorial-img-3'}/>
            </div>

            <p className="app-tutorial-p-strong">
                Interact with Posts:
            </p>
            <p className="app-tutorial-p">
                Like and comment on portfolio posts. <br />
                Click on the comments icon ({commentIcon}) at the bottom of the post to read what other users have to say. <br />
                You can also view essential details such as the returns the portfolio has made, both until the current moment and since the post was originally shared.
            </p>

            <p className="app-tutorial-p-strong">
                Copy Portfolios:
            </p>
            <p className="app-tutorial-p">
                If you find a portfolio that stands out, copy it to one of your portfolios by clicking on the copy icon ({copyIcon}). <br />
                This allows you to create a personalized collection for further analysis.
            </p>


            <p className="app-tutorial-p-strong">
                Analyze and Research:
            </p>
            <p className="app-tutorial-p">
                Navigate to the portfolio to unlock more tools to explore, analyze, manage, and share your investment journey with the community.
            </p>

            <p className="app-tutorial-p-strong">
                Manage Your Portfolio:
            </p>
            <p className="app-tutorial-p">
                Explore the copied portfolio by navigating to the portfolio page. <br />
                Take control by adding or removing companies according to your investment strategy. <br />
                Customize your portfolio to align with your financial goals.
            </p>

            <p className="app-tutorial-p-strong">
                Create and Share Insights:
            </p>
            <p className="app-tutorial-p">
                Utilize the enhanced features on the portfolio page to create posts and share valuable insights with the community. <br />
                Showcase your portfolio's performance or provide tips to fellow users.
            </p>

        </>
    );
}

export default InteractiveDemosSlide;