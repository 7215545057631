import './funds.scss';
import data from './data.json';
// hardcoded imgs
import fund1 from '../imgs/fund1.png';
import fund2 from '../imgs/fund2.png';
import fund3 from '../imgs/fund3.png';
import fund4 from '../imgs/fund4.png';
import { trophyIcon } from '../../../shared/icons/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { getSuggestedFunds } from '../../../shared/actions/fund-actions';
import LoadingSpinner from '../../../shared/loading-spinner/loading-spinner';
import CreateFund from './create-fund/create-fund';

const Funds = ({loggedInUser}) => {

    const history = useHistory();

    const handleTitleClick = (itemName) => {
        history.push(`/funds/${itemName}`);
    };

    const [suggestedFunds, setSuggestedFunds] = useState(null);

    const fundImgs = [fund1, fund2, fund3, fund4];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const funds = await getSuggestedFunds();
                // console.log('funds:', funds);
                setSuggestedFunds(funds);
            } catch (error) {
                // Handle errors
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    return (
        <div className="funds-container">
            <div className="main-content-container funds-list-container">
                {
                    suggestedFunds ? (
                        <>
                            <div className="card-container mb20">
                                <CreateFund loggedInUser={loggedInUser} />
                            </div>
                            <div className="fund-list">
                                {suggestedFunds.map((fund, idx) => (
                                    <div key={idx} className="fund-item-container card-container mb20 flex space-between">
                                        <div className="flex">
                                            <div className='pos-rel mr10'>
                                                <div className="fund-crest-lvl">
                                                    {fund.level}
                                                </div>
                                                <div className='fund-crest' style={{ width: '100px', height: '100px' }}>
                                                    <img className='fund-crest-img' src={fund.pngUrl} style={{ width: '100px', height: '100px' }} />
                                                </div>
                                            </div>
                                            <div className='flex column justify-center'>
                                                <h1 onClick={() => handleTitleClick(fund.name)} className='fund-title'>{fund.name}</h1>
                                                <p>{fund.isPublic ? 'Public' : 'Private'}</p>
                                            </div>
                                        </div>
                                        <div className='flex column space-between align-end'>
                                            <div className='single-fund-item-trophies-container'>
                                                <div className='flex center-center'>
                                                    {trophyIcon}
                                                </div>
                                                <div className=''>
                                                    {fund.trophies}
                                                </div>
                                            </div>
                                            <div>{fund.membersNum} / 20 Members</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <LoadingSpinner />
                    )}
            </div>
        </div>
    );


}

export default Funds;