import React, { useState, useEffect } from "react";
import CardContainer from "../../../cmps/card-container/card-container";
import LineChart from "../../../shared/charts/line-chart/line-chart";
import { hexToRGBA } from '../../../shared/global-color-functions';
import LabelColorContainer from "../../../shared/label-color-container/label-color-container";
import { useMediaQuery } from 'react-responsive';
import '../crypto.scss';

const CryptoLinechart = ({ lineChartData, cryptoColors, selectedItem, onChangeSelectedItem, activePriceRelative }) => {

    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [colorLabelsToDisplay, setColorLabelsToDisplay] = useState([]);
    const [lineChartDataToDisplay, setLineChartDataToDisplay] = useState(null);
    const isMobile = useMediaQuery({ maxWidth: 920 });

    useEffect(() => {
        if (!lineChartData) return;
        setDataToDisplay(lineChartData);
    }, [lineChartData]);

    useEffect(() => {
        const datasets = dataToDisplay.map(({ symbol, data, color }) => {
            const backgroundOpacity = selectedItem === null || selectedItem === undefined ? 1 : selectedItem === symbol ? 1 : 0.1;
            // const borderWidth = selectedItem === null || selectedItem === undefined ? 5 : selectedItem === symbol ? 10 : 2;
            const borderWidth = selectedItem === symbol ? 5 : 3;

            const chartDataArray = data.map(({ date, x }) => ({ x: date, y: x }));
            const label = symbol;
            const rgbaColor = hexToRGBA(color, backgroundOpacity);
            return {
                label,
                data: chartDataArray,
                fill: false,
                backgroundColor: rgbaColor,
                borderColor: rgbaColor,
                borderWidth: borderWidth,
            };
        });

        setLineChartDataToDisplay({ datasets });
    }, [dataToDisplay, selectedItem]);

    useEffect(() => {
        const filteredCryptoColors = cryptoColors.filter(cryptoItem =>
            dataToDisplay.some(dataItem => dataItem.symbol === cryptoItem.label)
        );
        setColorLabelsToDisplay(filteredCryptoColors);
    }, [dataToDisplay, cryptoColors]);

    const getSelectedItemHover = () => {
        if (!selectedItem || !lineChartData) return '';
        const selectedItemObj = lineChartData.find(item => item.symbol === selectedItem);
        if (selectedItemObj) {
            return `(${selectedItemObj.symbol}) ${selectedItemObj.key}`;
        };
        return '';
    };

    const cryptoLineChart = (
        <>
            <div style={{ width: '99.9%', height: '90%'}} className={'flex column space-between'}>
                <LineChart
                    chartData={lineChartDataToDisplay}
                    onChangeSelectedItem={onChangeSelectedItem}
                    type={activePriceRelative === 'price' ? 'dollar' : 'relative-percent'}
                />
            </div>

        </>
    );

    return (
        <>
            {
                dataToDisplay.length &&
                <div className={`findatasheets-container-item-top flex mb20 ${isMobile ? 'column' : ''}`}>
                    <LabelColorContainer
                        labels={colorLabelsToDisplay}
                        selectedItem={selectedItem}
                        onChangeSelectedItem={onChangeSelectedItem}
                        type={'crypto'}
                        display={'column'}
                    />
                    {
                        lineChartDataToDisplay &&
                        <CardContainer
                            title={'Crypto Currencies Over Time'}
                            innerContent={cryptoLineChart}
                            className={'crypto-linechart flex column space-between'}
                            selectedItem={getSelectedItemHover()}
                        />
                    }
                </div>
            }
        </>
    );
}

export default CryptoLinechart;