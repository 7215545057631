import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        userid: '',
        username: '',
        portfolio: {
            portfolioA: {},
            portfolioB: {},
            portfolioC: {},
            portfolioD: {},
            portfolioE: {}
        },
        selectedPortfolio: 'portfolioA',
        fund: {
            name: '',
            png: '',
            role: ''
        },
        following: {
            users: [],
            companies: [],
            sectors: [],
            crypto: [],
            indices: [],
            currencies: [],
            commodities: [],
        },
        about: '',
        pngUrl: '',
        fame: '0',
        fameDelay: ''
    },
    reducers: {
        setUser(state, action) {
            return action.payload;
        },
        incrementFame(state) {
            const fameNumber = state.fame ? parseInt(state.fame, 10) : 0;
            state.fame = (fameNumber + 1).toString();
        },
        setSelectedPortfolio(state, action) {
            state.selectedPortfolio = action.payload;
        }
    },
});

export const { setUser, incrementFame, setSelectedPortfolio } = userSlice.actions;

export default userSlice.reducer;