import React, {useState} from 'react';
import Select from 'react-select';
import { customStyles, DropdownIndicator } from '../../../../shared/dropdown-styles';
import { dropdownIcon } from '../../../../shared/icons/icons';

const MacroCommoditiesGroupFilter = ({ options, value, onChange }) => {

    const [showGroupOptions, setShowGroupOptions] = useState(false);

    const onHandleGroupMouseEnter = () => {
        setShowGroupOptions(true);
    };

    const onHandleGroupMouseLeave = () => {
        setShowGroupOptions(false);
    };

    return (
        <div className="dropdown-select-filter-item"
            onMouseEnter={onHandleGroupMouseEnter}
            onMouseLeave={onHandleGroupMouseLeave}
        >
            <Select
                styles={customStyles}
                components={{ DropdownIndicator }}
                options={options}
                value={value}
                onChange={onChange}
                menuIsOpen={showGroupOptions}
                hideSelectedOptions={false}
            />
            <div
                className="dropdown-label flex align-center">
                {value.label}
                <span
                    className='dropdown-indicator flex center-center'> {dropdownIcon}
                </span>
            </div>
        </div>
    );
}

export default MacroCommoditiesGroupFilter;