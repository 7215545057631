import ProfileFollowing from "../profile-following/profile-following";

const ProfileInterests = ({
    isMyProfile,
    username,
    userFollowing
}) => {
    return (
        <div className="card-container">
            <ProfileFollowing
                isMyProfile={isMyProfile}
                username={username}
                userFollowing={userFollowing}
            />
        </div>
    );
}

export default ProfileInterests;