import React, { useState, useEffect } from 'react';
import { notepadIcon } from '../../shared/icons/icons';
import './notepad.scss';

const Notepad = ({
    blinkCmp,
}) => {
    const [isNotepadOpen, setIsNotepadOpen] = useState(false);
    const [selectedNoteType, setSelectedNoteType] = useState('Notes');

    const noteTypes = ['Notes', 'Transactions'];

    const [notesContent, setNotesContent] = useState('');
    const [transactionsContent, setTransactionsContent] = useState(
        [
            {
                symbol: '',
                amount: '',
                date: '',
                price: ''
            }
        ]
    );

    const toggleNotepad = () => {
        setIsNotepadOpen(prevState => !prevState);
    };

    const handleNoteTypeClick = (noteType) => {
        setSelectedNoteType(noteType);
    };

    const handleNotesChange = (event) => {
        setNotesContent(event.target.value);
    };

    const handleTransactionsChange = (index, event) => {
        const { name, value } = event.target;
        const updatedTransactions = [...transactionsContent];
        updatedTransactions[index] = {
            ...updatedTransactions[index],
            [name]: value
        };
        setTransactionsContent(updatedTransactions);
    };

    useEffect(() => {
        const lastTransaction = transactionsContent[transactionsContent.length - 1];
        const isEmpty = Object.values(lastTransaction).every(value => value === '');
        if (!isEmpty) {
            setTransactionsContent(prevTransactions => [
                ...prevTransactions,
                { symbol: '', amount: '', date: '', price: '' }
            ]);
        };
    }, [transactionsContent]);

    return (
        <div className="flex center-center" style={{width: '30px', marginRight: '5px'}}>
            <div
                className={`notepad-btn flex center-center ${blinkCmp === 'notepad-blink' ? 'blink-border' : ''}`}
                // className="notepad-btn flex"
                title='Notepad'
                onClick={() => toggleNotepad()}
            >
                <div className="flex center-center" style={{fontSize: '2.5rem', paddingTop: '4px'}}>{notepadIcon}</div>
                {/* <div className="flex center-center">Notepad</div> */}
            </div>
            {isNotepadOpen && (
                <div className="notepad-container">
                    <div className="notepad-nav flex">
                        {noteTypes.map((noteType) => (
                            <div
                                key={noteType}
                                className={`note-type ${selectedNoteType === noteType ? 'selected-note-type' : ''}`}
                                onClick={() => handleNoteTypeClick(noteType)}
                            >
                                {noteType}
                            </div>
                        ))}
                    </div>

                    {selectedNoteType === 'Notes' && (
                        <div className="notepad-content">
                            <textarea
                                className='notepad-notes-textarea'
                                value={notesContent}
                                onChange={handleNotesChange}
                                cols="30"
                                rows="10"
                                placeholder="Enter your notes here..."
                            ></textarea>
                        </div>
                    )}
                    {selectedNoteType === 'Transactions' && (
                        <div className='notepad-transaction-container'>
                            <table className="notepad-transaction-table">
                                <thead>
                                    <tr>
                                        <th>Symbol</th>
                                        <th>Amount</th>
                                        <th>Date</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactionsContent.map((transaction, index) => (
                                        <tr key={index} className="notepad-transaction-content">
                                            <td>
                                                <input
                                                    className='notepad-transaction-input'
                                                    type="text"
                                                    placeholder='Symbol'
                                                    name="symbol"
                                                    value={transaction.symbol || ''}
                                                    onChange={(event) => handleTransactionsChange(index, event)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    className='notepad-transaction-input'
                                                    type="number"
                                                    placeholder='Amount'
                                                    name="amount"
                                                    value={transaction.amount || ''}
                                                    onChange={(event) => handleTransactionsChange(index, event)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    className='notepad-transaction-input'
                                                    type="Date"
                                                    placeholder='Date'
                                                    name="date"
                                                    value={transaction.date || ''}
                                                    onChange={(event) => handleTransactionsChange(index, event)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    className='notepad-transaction-input'
                                                    type="number"
                                                    placeholder='Price'
                                                    name="price"
                                                    value={transaction.price || ''}
                                                    onChange={(event) => handleTransactionsChange(index, event)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}


                </div>
            )}
        </div>
    );

}

export default Notepad;