import React, { useState, useEffect } from 'react';
import { unfollowIcon, followIcon } from '../icons/icons';
import './follow-btn.scss';

const FollowBtn = ({ loggedInUser, toggleFollowing, symbol, userFollowing, setErrMsg, isMobile, url, hasFinancialStatements }) => {

    const [isFollowing, setIsFollowing] = useState(false);

    useEffect(() => {
        if (userFollowing) setIsFollowing(userFollowing.includes(symbol));
    }, [userFollowing, symbol]);

    const followEl = (
        <>
            <div className='flex align-center mr10'>{unfollowIcon}</div>
            <div>Follow</div>
        </>
    );

    const followingEl = (
        <>
            <div className='flex align-center mr10'>{followIcon}</div>
            <div>Following</div>
        </>
    );

    const handleFollowClick = () => {
        if (loggedInUser) {
            setIsFollowing(!isFollowing);
            toggleFollowing('companies', symbol);
        } else {
            setErrMsg(true);
            setTimeout(() => {
                setErrMsg(false);
            }, 5000);
        };
    };

    return (
        <div className="flex column flex-end">
            <div className={isMobile ? "follow-btn btn flex" : "follow-btn btn flex align-center"} onClick={handleFollowClick}>
                {isFollowing ? followingEl : followEl}
            </div>
            {
                hasFinancialStatements && <div className='edgar-btn btn'>
                    {
                        url && url[0] && (
                            <a href={url[0]} target="_blank" rel="noopener noreferrer">
                                EDGAR
                            </a>
                        )
                    }
                </div>
            }
        </div>
    );
}

export default FollowBtn;
