import { useEffect, useState } from "react";
import PieChart from "../../../shared/charts/pie-chart/pie-chart";
import { formatNumberToDollarTableString, hexToRGBA } from "../../../shared/global-color-functions";
import LabelColorContainer from "../../../shared/label-color-container/label-color-container";
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Doughnut } from "react-chartjs-2";
import SelectedPortfolio from "../../portfolio/selected-portfolio.jsx/selected-portfolio";

const ProfileSelectedPortfolioPiecharts = ({
    selectedPieCharts,
    isMyProfile,
    handleSetCompaniesSumData,
    selectedPortfolio,
}) => {

    const isMobile = useMediaQuery({ maxWidth: 1000 });

    const [companyLabels, setCompanyLabels] = useState(null);
    const [sectorLabels, setSectorLabels] = useState(null);
    const [companyPiechartData, setCompanyPiechartData] = useState(null);
    const [sectorPiechartData, setSectorPiechartData] = useState(null);
    const [companiesSumData, setCompaniesSumData] = useState(null);
    const [sectorsSumData, setSectorsSumData] = useState(null);

    const [selectedItem, setSelectedItem] = useState(null);

    const history = useHistory();
    const isMedium = useMediaQuery({ maxWidth: 1000 });
    const isTablet = useMediaQuery({ maxWidth: 700 });

    useEffect(() => {
        if (!selectedPieCharts) return;

        const companyPieData = {
            labels: [],
            hover: [],
            datasets: [{
                label: "My Companies",
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 2
            }]
        };

        const sectorPieData = {
            labels: [],
            hover: [],
            datasets: [{
                label: "My Sectors",
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 2
            }]
        };

        let companiesSum = 0;

        const newCompanyLabels = [];
        const newSectorLabels = [];

        selectedPieCharts.companiesPie.forEach((item) => {
            const backgroundColor = getBackgroundColor(item, selectedItem);

            const symbol = item.symbol;
            const label = `(${item.symbol}) ${item.company}`;
            const sector = item.sector;
            const value = +item.pieChart;

            companyPieData.labels.push(symbol);
            companyPieData.hover.push([label, sector]);
            companyPieData.datasets[0].data.push(value);
            companyPieData.datasets[0].backgroundColor.push(backgroundColor);
            companyPieData.datasets[0].borderColor.push('#ffffff');
            companyPieData.datasets[0].borderWidth = 2;

            companiesSum += value;

            newCompanyLabels.push({
                label: symbol,
                symbol,
                color: item.color,
            });
        });

        selectedPieCharts.sectorsPie.forEach((item) => {
            const backgroundColor = getBackgroundColor(item, selectedItem);

            const sectorLabel = item.sector;
            const sectorValue = +item.pieChart;

            sectorPieData.labels.push(sectorLabel);
            sectorPieData.hover.push(item.companies);
            sectorPieData.datasets[0].data.push(sectorValue);
            sectorPieData.datasets[0].backgroundColor.push(backgroundColor);
            sectorPieData.datasets[0].borderColor.push('#ffffff');
            sectorPieData.datasets[0].borderWidth = 2;

            newSectorLabels.push({
                label: sectorLabel,
                symbol: sectorLabel,
                color: item.color,
            });
        });

        setCompanyLabels(newCompanyLabels);
        setSectorLabels(newSectorLabels);

        setCompanyPiechartData(companyPieData);
        setSectorPiechartData(sectorPieData);

        handleSetCompaniesSumData(companiesSum);

    }, [selectedPieCharts, selectedItem, selectedPortfolio]);

    const handleNavigatePortfolio = () => {
        history.push('/portfolio/companies');
    };

    // Helper function to determine background color
    const getBackgroundColor = (item, selectedItem) => {
        return selectedItem === null ? item.color : selectedItem !== item.symbol ? hexToRGBA(item.color) : item.color;
    };

    const ProfilePiechart = ({ item, title, chartLabels, type }) => {

        const data = {
            labels: item.labels,
            datasets: [
                {
                    data: item.datasets[0].data,
                    backgroundColor: item.datasets[0].backgroundColor,
                    borderColor: item.datasets[0].borderColor,
                    borderWidth: item.datasets[0].borderWidth,
                    borderRadius: item.datasets[0].borderRadius,
                },
            ],
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                // tooltip: {
                //     displayColors: false,
                //     callbacks: {
                //         title: (tooltipItem) => {
                //             const hoverData = item.hover[tooltipItem[0].dataIndex][0];
                //             return hoverData;
                //         },
                //         label: (tooltipItem) => {
                //             return [item.hover[tooltipItem.dataIndex][1], formatNumberToDollarTableString(tooltipItem.parsed)];
                //         }
                //     }
                tooltip: {
                    enabled: true,
                    displayColors: false,
                    backgroundColor: '#ffffff',
                    titleColor: '#484848',
                    borderWidth: '1',
                    borderColor: '#484848',
                    bodyColor: '#484848',
                    cornerRadius: '20',
                    padding: '10',
                    callbacks: {
                        label: (tooltipItem, data) => {
                            const index = tooltipItem.dataIndex;
                            const label = item.hover[index];
                            const hoverData = item.hover[index];
                            return hoverData.map((line, lineIndex) => (
                                line.toString()
                            ));
                        },
                    },
                    //   },
                },
                legend: {
                    display: false,
                    labels: {
                        usePointStyle: true,
                        boxHeight: 10
                    }
                }
            }
        };

        return (
            <>
                {/* <div className="flex"> */}
                <div
                    className={`findatasheets-container-item-top flex ${isMobile ? 'column' : ''}`}
                    style={{ height: '100%' }}>
                    <LabelColorContainer
                        labels={chartLabels}
                        height={'100%'}
                        type={type}
                        display={isMobile ? 'row' : 'column'}
                        onChangeSelectedItem={() => { }}
                    />

                    <div className={`flex column center-center${isMedium ? '' : ''}`} style={{ width: isMedium ? '100%' : '50%' }}>
                        <h4 className='portfolio-chart-title'>{title}</h4>
                        <div style={{ width: '100%', minHeight: '220px' }}>
                            <Doughnut data={data} options={options} />
                        </div>
                    </div>

                    

                </div>
            </>
        );
    };

    return (
        <>
            {
                selectedPieCharts && companyLabels && sectorLabels ? (
                    <div
                        className={`profile-selected-portfolio-piecharts-container flex column center-center`}
                    >
                        <div
                            className="flex justify-center"
                            style={{ width: '100%', marginBottom: '50px' }}
                        >
                            <ProfilePiechart item={companyPiechartData} title={'Companies Values'} chartLabels={companyLabels} type={'company'} />
                        </div>
                        <div
                            className='flex justify-center'
                            style={{ width: '100%', marginBottom: isTablet ? '50px' : '0' }}
                        >
                            <ProfilePiechart item={sectorPiechartData} title={'Sectors Values'} chartLabels={sectorLabels} type={'sector'} />
                        </div>
                    </div>
                ) : (
                    <div className="flex column center-center">
                        <h3 className="mb20">This portfolio is empty.</h3>
                        {
                            isMyProfile && <div
                                className="btn btn-border"
                                style={{ width: 'fit-content' }}
                                onClick={handleNavigatePortfolio}
                            >
                                Manage Portfolio
                            </div>
                        }
                    </div>
                )}
        </>
    );

}

export default ProfileSelectedPortfolioPiecharts;