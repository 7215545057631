import React from 'react';
import PageBtn from '../../shared/page-btn/page-btn';

const ProfilePages = ({
    handleToggleOption,
    pages,
    activePage,
    isMyProfile,
    getNavLocation
}) => {

    return (
        <>
        
            <div className="profile-pages-container flex justify-center align-end">
                {
                    pages.map((page, index) => {
                        const shouldRenderPage = !(page.toLowerCase() === 'settings' && !isMyProfile);
                        return shouldRenderPage ? (
                            <div
                                key={index}
                                onClick={() => handleToggleOption(page.toLowerCase())}
                                className='active-page-item'
                            >
                                <PageBtn activePage={activePage} label={page} getNavLocation={getNavLocation}/>
                            </div>
                        ) : null;
                    })
                }
            </div>
            {/* <div className="profile-pages-border"></div> */}
        </>
    );
}

export default ProfilePages;
