import React, { useState } from "react";
import './feedback-modal.scss';
import { useHistory } from 'react-router-dom';
import { closeIcon } from "../../shared/icons/icons";

const FeedbackModal = ({
    loggedInUser,
    closeFeedbackModal,
    onSubmit
}) => {

    const history = useHistory();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [feedback, setFeedback] = useState('');

    const onSubmitFeedback = (ev) => {
        ev.preventDefault();
        onSubmit(name, email, feedback);
    };

    return (
        <div className="feedback-modal">
            <div className="feedback-modal-header flex space-between mb10">
                <h2 className="">Feedback</h2>
                <div
                    className="btn"
                    onClick={() => closeFeedbackModal()}
                >
                    {closeIcon}
                </div>
            </div>
            <div className="feedback-modal-content flex column">
                <p className="feedback-modal-p mb20">Please provide feedback to help us improve your experience using PortFollow.</p>
                <form className="feedback-modal-form user-pool-form flex column space-between" onSubmit={onSubmitFeedback}>
                    <h4 className="mb10">Your name (optional)</h4>
                    <input
                        className="user-pool-input"
                        type="text"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        placeholder='Name'
                        style={{ width: '100%' }}
                    />
                    <h4 className="mb10">Your email (optional)</h4>
                    <input
                        className="user-pool-input"
                        type="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        style={{ width: '100%' }}
                        placeholder='Email'
                    />
                    <h4 className="mb10">What do you think?</h4>
                    <textarea
                        className='feedback-textarea'
                        placeholder='We care about your opinion'
                        defaultValue={feedback}
                        onChange={(event) => setFeedback(event.target.value)}
                    />
                    <div className="flex flex-end">
                        <button
                            className="feedback-modal-bottom-btn user-pool-btn btn"
                            style={{ marginRight: '10px' }}
                            onClick={closeFeedbackModal}
                            type='button'
                        >Close
                        </button>
                        <button
                            className="feedback-modal-bottom-btn user-pool-btn btn"
                            disabled={feedback === ''}
                            type='submit'
                        >Send
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default FeedbackModal;