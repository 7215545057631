import React from 'react';
import './fundamental-graphs.scss';
import FundamentalGraphItem from './fundamental-graph-item';

const FundamentalGraphs = ({ data, symbol, type }) => {

    const getChartData = (key) => {
        const chartType = data[key][0].symbol ? "bar" : "line";
        const chartData = {
            labels: [],
            datasets: [],
            hover: []
        };
        const chartDataset = {
            label: key,
            data: [],
            borderColor: [],
            borderWidth: 2,
            pointStyle: "none",
            backgroundColor: [],
            fill: chartType === "line" && "start",
        };
        if (chartType === "bar") {
            data[key].sort((a, b) => b.y - a.y).forEach((item) => {
                chartDataset.data.push(item.y);
                if (item.symbol === symbol) {
                    chartDataset.backgroundColor.push( item.y > 0 ? "#5c9ead" : "#e39774");
                } else {
                    chartDataset.backgroundColor.push(
                        item.y > 0 ? "#5c9ead" : "#e39774"
                    );
                }

                chartData.labels.push(item.symbol);
                chartData.hover.push([item.symbol]);
            });
        } else if (chartType === "line") {
            data[key].forEach((item) => {
                chartDataset.data.push({ x: item.x, y: +item.y });
                chartData.labels.push(item.x);
                chartDataset.backgroundColor.push(item.y > 0 ? "#5c9ead" : "#e39774");
                chartDataset.borderColor.push(item.y > 0 ? "#5c9ead" : "#e39774");
                
            });
        }
        chartData.datasets.push(chartDataset);
        return chartData;
    };

    return (
        <div className="fundamental-graphs-container flex wrap center-center">
            {
                Object.keys(data).map((item, idx) => {
                    if (data[item].length === 0 || !item) return null;
                    const chartType = data[item][0].symbol ? 'bar' : 'area';
                    const chartData = getChartData(item);
                    return (<FundamentalGraphItem chartType={chartType} chartData={chartData} chartName={item} />)
                })
            }
        </div>
    );
}

export default FundamentalGraphs;
