const FeedConfirmChanges = ({
    isConfirmed,
    handleConfirmation,
    selectedPortfolio,
    handleCloseCopyPortfolioModal,
    handleSaveCopiedPortfolio,
    orders,
    blinkCmp
}) => {

    return (
        <div>
            <div>You're about to overwrite {selectedPortfolio}.</div>
            <div>Are you sure you want to save these changes?</div>
            <div className="flex space-between">
                <div className="flex" style={{ margin: '10px 0' }}>
                    {
                        <div
                            // className="btn btn-border"
                            className={`btn btn-border ${blinkCmp === 'postexample-copymodal-confirmbtn' ? 'blink-border' : ''}`}
                            onClick={() => handleConfirmation()}
                        >Confirm
                        </div>
                    }
                    {
                        isConfirmed && <div
                            // className="btn btn-border"
                            className={`btn btn-border ${blinkCmp === 'postexample-copymodal-savebtn' ? 'blink-border' : ''}`}
                            onClick={() => handleSaveCopiedPortfolio(orders)}
                        >Save</div>
                    }
                </div>
                <div
                    className="btn btn-border"
                    onClick={() => handleCloseCopyPortfolioModal()}
                    style={{ margin: '10px 0' }}
                >Cancel</div>
            </div>
        </div>
    );
}

export default FeedConfirmChanges;