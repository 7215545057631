import CalendarDay from "../calendar-day/calendar-day";
import './calendar-week.scss';

const CalendarWeek = ({ weekData,activePage,filters,getColorHex }) => {

    return (
        <div className="calendar-week flex">
            {weekData.days.map((day, idx) => (
                    <CalendarDay dayData={day} activePage={activePage} filters={filters} getColorHex={getColorHex}/>
            ))}
        </div>
    );
}

export default CalendarWeek;