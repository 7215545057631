import React, { useEffect, useState } from 'react';
import { closeIcon, plusIcon } from '../../../shared/icons/icons';
import PortfolioInputsRow from './portfolio-inputs-row';
import './manage-portfolio.scss';
import { useMediaQuery } from 'react-responsive';
import { getRandomId } from '../../../shared/global-color-functions';
import PortfolioInputsRowMobile from './portfolio-inputs-row-mobile';

const ManagePortfolio = ({ open, onClose, selectedPortfolio, myPortfolio, addStocks, loggedInUser }) => {

    const isSmallScreen = useMediaQuery({ maxWidth: 700 });

    const [addedOrders, setAddedOrders] = useState([{
        symbol: '',
        amount: '',
        buyDate: '',
        price: 0,
        orderId: getRandomId()
    }]);

    const [newOrders, setNewOrders] = useState([]);

    useEffect(() => {
        const portfolioData = myPortfolio?.portfolioData;
        if (!portfolioData || portfolioData.length === 0) {
            setNewOrders([]);
        } else {
            const portfolioCopy = [...portfolioData];
            setNewOrders(portfolioCopy);
        }
        const navItemsContainer = document.querySelector('.nav-items-container');
        if (navItemsContainer) {
            navItemsContainer.style.display = open ? 'none' : 'block';
        }
    }, [open, myPortfolio, selectedPortfolio]);

    if (!open) return null;

    const onHandleOrderChange = (orderId, propertyName, newValue) => {
        setAddedOrders((prevOrders) => {
            return prevOrders.map((order) =>
                order.orderId === orderId ? { ...order, [propertyName]: newValue } : order
            );
        });
    };

    const onSaveRow = (order) => {
        const orderIndex = addedOrders.findIndex((addedOrder) => addedOrder.orderId === order.orderId);
        if (orderIndex !== -1) {
            setAddedOrders((prevOrders) => {
                const updatedOrders = [...prevOrders];
                updatedOrders[orderIndex] = {
                    ...updatedOrders[orderIndex],
                    symbol: order.symbol,
                    amount: order.amount,
                    date: order.date,
                    price: order.price,
                };
                return updatedOrders;
            });
        }
    };

    const onSubmitChanges = () => {
        if (newOrders.length <= 1) return;
        addStocks(newOrders);
        setAddedOrders([]);
        setNewOrders([]);
        onClose();
    };

    const onSaveRows = (idx, updatedRow) => {
        setAddedOrders((prevRows) =>
            prevRows.filter((row, i) => i !== idx)
        );
        setNewOrders((prevOrders) => [updatedRow, ...prevOrders]);
        setAddedOrders([{
            symbol: '',
            amount: '',
            buyDate: '',
            price: 0,
            orderId: getRandomId()
        }]);
    };

    const removeOrderFromAdded = (orderId) => {
        setAddedOrders((prevOrders) => prevOrders.filter((order) => order.orderId !== orderId));
    };

    const removeOrderFromNew = (orderId) => {
        if (newOrders.length - 1 < 2) return;
        setNewOrders((prevOrders) => prevOrders.filter((order) => order.orderId !== orderId));
    };

    const onCancelChanges = () => {
        setAddedOrders([{
            symbol: '',
            amount: '',
            buyDate: '',
            price: 0,
            orderId: getRandomId()
        }]);
        setNewOrders([]);
        onClose();
    };

    const PortfolioTable = () => {
        return (
            <div className="findatasheets-table">
                <div className="findatasheets-table-header flex space-between portfolio-inputs-row-row">
                    <div style={{ width: '20px' }}></div>
                    <div className="findatasheets-table-cell findatasheets-table-title flex align-end" style={{ width: '25%' }}>Symbol</div>
                    <div className="findatasheets-table-cell findatasheets-table-title flex align-end" style={{ width: '25%' }}>Amount</div>
                    <div className="findatasheets-table-cell findatasheets-table-title flex align-end" style={{ width: '25%' }}>Buy Date</div>
                    <div className="findatasheets-table-cell findatasheets-table-title flex align-end" style={{ width: '25%' }}>Price</div>
                </div>

                {
                    addedOrders.map((order, idx) => (
                        <PortfolioInputsRow
                            key={idx}
                            idx={idx}
                            order={order}
                            onSaveRows={onSaveRows}
                            removeOrderFromAdded={removeOrderFromAdded}
                            getRandomId={getRandomId}
                            onSaveRow={onSaveRow}
                            onHandleOrderChange={onHandleOrderChange}
                        />

                    ))
                }

                {
                    newOrders.map((order, idx) => (
                        <div className={`findatasheets-table-row portfolio-inputs-row-row flex space-between ${idx % 2 === 0 ? '' : 'odd-row'}`} key={idx}>
                            <div
                                className="manage-portfolio-x btn flex align-center"
                                style={{ width: '20px', marginRight: 0 }}
                                onClick={() => removeOrderFromNew(order.orderId)}
                            >{closeIcon}
                            </div>
                            <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>{order.symbol}</div>
                            <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>{order.amount}</div>
                            <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>{order.buyDate}</div>
                            <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>${order.price}</div>
                        </div>
                    ))
                }

            </div>
        );
    };

    const buttonClass = `btn btn-border ${newOrders.length <= 1 ? 'btn-disabled' : ''}`;

    const tableLowerPart = () => {
        const commonButtons = (
            <>
                <div className="flex">
                    <div
                        className='btn  btn-border'
                        style={{ marginLeft: '20px' }}
                        onClick={onCancelChanges}
                    >CANCEL
                    </div>
                    <div
                        className={buttonClass}
                        onClick={newOrders.length > 1 ? onSubmitChanges : null}
                    >
                        SUBMIT
                    </div>
                </div>
            </>
        );

        if (newOrders.length < 3) {
            return (
                <div className="flex" style={{ marginTop: '20px', width: '100%' }}>
                    <div className="flex space-between" style={{ width: '100%' }}>
                        <div>To access your portfolio, ensure it contains a minimum of 2 items.</div>
                        {commonButtons}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="flex flex-end" style={{ marginTop: '20px' }}>
                    {commonButtons}
                </div>
            );
        }
    };

    const tableLowerPartMobile = () => {
        const commonButtons = (
            <>
                <div className="flex space-between">
                    <div
                        className='btn btn-border'
                        style={{ marginTop: '20px' }}
                        onClick={onCancelChanges}
                    >CANCEL
                    </div>
                    <div
                        className={buttonClass}
                        style={{ marginTop: '20px' }}
                        onClick={newOrders.length > 1 ? onSubmitChanges : null}
                    >
                        SUBMIT
                    </div>
                </div>
            </>
        );

        if (newOrders.length < 3) {
            return (
                <div className="flex" style={{ marginTop: '20px', width: '100%' }}>
                    <div className="flex column space-between" style={{ width: '100%' }}>
                        <div>To access your portfolio, ensure it contains a minimum of 2 items.</div>
                        {commonButtons}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="flex flex-end" style={{ marginTop: '20px' }}>
                    {commonButtons}
                </div>
            );
        }
    };

    const AlternativeContent = () => {
        return (
            <div className="manage-portfolio-overlay">

                <div className="manage-portfolio-container card-container flex column space-between">

                    <div>
                        <div className="manage-portfolio-header mb20 flex space-between">
                            <div>
                                <h2>Manage Portfolio</h2>
                            </div>
                            <div
                                className="manage-portfolio-x btn"
                                style={{ width: 'fit-content' }}
                                onClick={onClose}
                            >
                                {closeIcon}
                            </div>
                        </div>

                        <ul style={{ marginBottom: '20px' }}>
                            <li>Select a symbol.</li>
                            <li>Enter stock quantity and transaction date.</li>
                            <li>Add stock's daily price.</li>
                            <li>Confirm each transaction.</li>
                            <li>Repeat for more stocks (minimum two).</li>
                            <li>Submit to update your portfolio.</li>
                        </ul>

                        {loggedInUser && <h3 className='mb20'>{loggedInUser} - {selectedPortfolio}</h3>}
                    </div>

                    <div className='scrollable-y'>
                        {
                            addedOrders.map((order, idx) => (
                                <PortfolioInputsRowMobile
                                    key={idx}
                                    idx={idx}
                                    order={order}
                                    onSaveRows={onSaveRows}
                                    removeOrderFromAdded={removeOrderFromAdded}
                                    getRandomId={getRandomId}
                                    onSaveRow={onSaveRow}
                                    onHandleOrderChange={onHandleOrderChange}
                                />

                            ))
                        }

                        {
                            newOrders.map((order, idx) => (
                                <div className={`findatasheets-table-row portfolio-inputs-row-row flex space-between ${idx % 2 === 0 ? '' : 'odd-row'}`} key={idx}>
                                    <div
                                        className="manage-portfolio-x btn flex align-center"
                                        style={{ width: '20px', marginRight: 0 }}
                                        onClick={() => removeOrderFromNew(order.orderId)}
                                    >{closeIcon}
                                    </div>
                                    <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>{order.symbol}</div>
                                    <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>{order.amount}</div>
                                    <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>{order.buyDate}</div>
                                    <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>${order.price}</div>
                                </div>
                            ))
                        }
                    </div>

                    <div>{tableLowerPartMobile()}</div>

                </div>
            </div>
        );
    };

    return (
        <>
            {isSmallScreen ? (
                <AlternativeContent />
            ) : (
                <div className="manage-portfolio-overlay">
                    <div className="manage-portfolio-container card-container">
                        <div className="flex column space-between" style={{ height: '100%' }}>
                            <div>
                                <div className="manage-portfolio-header mb10 flex space-between">
                                    <div>
                                        <h2>Manage Portfolio</h2>

                                    </div>
                                    <div
                                        className="manage-portfolio-x btn"
                                        style={{ width: 'fit-content' }}
                                        onClick={onClose}
                                    >
                                        {closeIcon}
                                    </div>

                                </div>  

                                <ul className='mb20'>
                                    <ul style={{ marginBottom: '20px' }}>
                                        <li>Select a symbol.</li>
                                        <li>Enter stock quantity and transaction date.</li>
                                        <li>Add stock's daily price.</li>
                                        <li>Confirm each transaction.</li>
                                        <li>Repeat for more stocks (minimum two).</li>
                                        <li>Submit to update your portfolio.</li>
                                    </ul>
                                </ul>

                                <h3 className='mb20'>{loggedInUser} - {selectedPortfolio}</h3>
                                <PortfolioTable />
                            </div>
                            {tableLowerPart()}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ManagePortfolio;