import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { DropdownIndicator, customStyles as originalCustomStyles, customStyles } from "../../../../../shared/dropdown-styles";
import { dropdownIcon } from '../../../../../shared/icons/icons';
import './create-fund-inputs.scss';

const CreateFundInputs = ({handleCreateFund}) => {

    const [fundName, setFundName] = useState('');
    const [selectedOption, setSelectedOption] = useState('public');
    const [errorMsg, setErrorMsg] = useState(null);

    const selectOptions = [
        { value: 'public', label: 'Public' },
        { value: 'private', label: 'Private' }
    ];

    const [showOptions, setShowOptions] = useState(false);

    const onHandleMouseEnter = () => {
        setShowOptions(true);
    };

    const onHandleMouseLeave = () => {
        setShowOptions(false);
    };

    const onSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption.value);
    };
    const onCreateFund = () => {
        if (fundName.length < 4) {
            setErrorMsg('Fund name must be at least 4 characters long');
        } else if (fundName.includes(' ')) {
            setErrorMsg('Fund name cannot contain spaces');
        } else {
            setErrorMsg(null);
            handleCreateFund(fundName, selectedOption);
        }
    };
    

    return (
        <div className='flex space-between align-center' style={{ height: '100px', width: '100%' }}>

            <div className='flex column space-around' style={{ height: '100%', paddingLeft: '20px' }}>
                <input
                    type="text"
                    placeholder='Fund name'
                    className='user-pool-input create-fund-input'
                    value={fundName}
                    onChange={(e) => setFundName(e.target.value)}
                />
                <div className="create-fund-err-msg-container">
                    {
                        errorMsg &&
                        <p className="err-msg create-fund-err-msg">{errorMsg}</p>
                    }
                </div>
            </div>


            <div className="flex column space-between" style={{ height: '100%' }}>
                <div className="dropdown-select-filter-item create-fund-input-dropdown"
                    onMouseEnter={onHandleMouseEnter}
                    onMouseLeave={onHandleMouseLeave}
                >
                    {
                        selectOptions && <Select
                            styles={customStyles}
                            components={{ DropdownIndicator }}
                            options={selectOptions}
                            value={selectedOption}
                            onChange={onSelectChange}
                            menuIsOpen={showOptions}
                            hideSelectedOptions={false}
                        />
                    }
                    {
                        selectedOption && <div
                            className="dropdown-label create-fund-input-dropdown-label flex align-center">
                            {selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)}
                            <span
                                className='dropdown-indicator flex center-center'
                            >
                                {dropdownIcon}
                            </span>
                        </div>
                    }
                </div>

                <div
                    className="btn btn-border"
                    onClick={onCreateFund}
                >
                    Confirm
                </div>

            </div>
        </div>
    );
}

export default CreateFundInputs;