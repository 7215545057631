import React, { useState } from 'react';
import { infoIcon } from '../../shared/icons/icons';
import InfoPopup from '../../shared/info-popup/info-popup';
import './card-container.scss';
import CardContainerCompare from './card-container-compare';

const CardContainer = React.forwardRef(({
    title,
    innerContent,
    className,
    selectedItem,
    styleFix,
    data,
    chartTitle,
    chartType,
    infoModalType,
    selectedCompareItems,
    onChangeSelectedCompareItem
}, ref) => {

    const [showInfoModal, setShowInfoModal] = useState(false);

    const handleInfoClick = () => {
        setShowInfoModal(true);
    };

    return (
        <div className={`${className} card-container flex column space-between`}>
            <div className="flex space-between">
                <div className="flex" style={{ height: '20px', marginBottom: '10px' }}>
                    <h4 className='portfolio-chart-title'>{title}</h4>
                    {infoModalType && <div
                        className='card-container-info-btn btn flex center-center'
                        onClick={handleInfoClick}
                        style={{ height: 'fit-content', marginLeft: '10px', padding: '4px' }}
                        type={infoModalType}
                    >
                        {infoIcon}
                    </div>
                    }
                </div>
                <h4>{selectedItem !== null ? selectedItem : ""}</h4>
            </div>
                {
                    chartType &&
                    chartType === 'portfolio-line' &&
                    <CardContainerCompare
                        selectedCompareItems={selectedCompareItems}
                        onChangeSelectedCompareItem={onChangeSelectedCompareItem}
                    />
                }
            {innerContent}
            {showInfoModal && <InfoPopup type={infoModalType} setShowInfoModal={setShowInfoModal} />}
        </div>
    );

});

export default CardContainer;
