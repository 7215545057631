import React, { useState, useContext } from "react";
import { AccountContext } from "./account";
import { useHistory, useLocation } from 'react-router-dom';
import './user-pool.scss';
import { Helmet } from "react-helmet";
import ActivePageSelection from '../shared/active-page/active-page';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";

const Login = ({ loggedInUser, setLoggedInUser, setLoggedInUserId }) => {

    const history = useHistory();

    const [activePage, setActivePage] = useState('login');

    const handleToggleOption = (page) => {
        if (page === 'signup') {
            navigateToSignup();
        } else if (page === 'home') {
            navigateToHome();
        }
    };

    if (loggedInUser) history.push('/');

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const { authenticate } = useContext(AccountContext);
    const location = useLocation();

    const onSubmit = (event) => {
        event.preventDefault();
        authenticate(username, password, setLoggedInUser, setLoggedInUserId)
            .then((data) => {
                history.push('/');
            })
            .catch((err) => {
                if (err.code === 'UserNotConfirmedException') history.push('/verification');
                else if (err.code === 'NotAuthorizedException') {
                    const errMsg = 'Incorrect username or password.';
                    setErrorMsg(errMsg);
                    setIsError(true);
                };
            })
    };

    const navigateToHome = () => {
        history.push('/');
    };

    const navigateToSignup = () => {
        history.push('/signup');
    };

    const pages = ['home', 'login', 'signup'];

    const onGoogleLogin = async (decodedUser) => {
        const apiUrl = 'https://4s8f188hhc.execute-api.us-east-1.amazonaws.com/get-user-data';
        const requestBody = JSON.stringify({ email: decodedUser.email });
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: requestBody,
            redirect: 'follow'
        };
        fetch(apiUrl, requestOptions)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    // throw new Error('Network response was not ok');
                };
            })
            .then((parsedData) => {
                return parsedData;
            })
            .then((result) => {
                setLoggedInUser(result.username);
                setLoggedInUserId(result.userid);
                history.push('/');
            })
            .catch((error) => {
                console.error('Error fetching user data:', error);
            });

    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow - Login</title>
                <meta name="description" content="Share & follow success stories in the capital market, where financial performance isn't solely determined by wealth. Enhance your portfolio management by analyzing economic trends and adapting to the dynamics of the financial market." />
                <link rel="canonical" href="https://www.portfollow.com/login" />
            </Helmet>


            <div className="portfolio-pages-container mb20">
                {
                    pages &&
                    <ActivePageSelection
                        handleToggleOption={handleToggleOption}
                        pages={pages}
                        activePage={activePage}
                    />
                }
            </div>

            <div className="user-auth-container card-container flex column align-center">

                <h1 className="mb20">Login</h1>

                <div className="mb20">
                    <GoogleLogin
                        onSuccess={credentialResponse => {
                            const decoded = jwtDecode(credentialResponse?.credential);
                            onGoogleLogin(decoded);
                        }}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />
                </div>

                <p className="mb20">or</p>

                <form className="user-pool-form flex column space-between" onSubmit={onSubmit}>
                    <input
                        className="user-pool-input"
                        type="text"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                        placeholder='Username'
                    />
                    <input
                        className="user-pool-input"
                        type="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        placeholder='Password'
                    />
                    <div>
                        {isError && <p>{errorMsg}</p>}
                    </div>
                    <div className="flex justify-center">
                        <button className="user-pool-btn btn btn-border" type='submit'>Login</button>
                    </div>
                </form>
            </div>
        </>
    );
}

export default Login;