import React, { useState, useEffect } from "react";
import Select, { components } from 'react-select';
import { customStyles, DropdownIndicator } from "../../../shared/dropdown-styles";
import { dropdownIcon } from "../../../shared/icons/icons";

const MacroContinentCountryIndexFilters = ({
    continents,
    countries,
    indices,
    onFilterContinents,
    onFilterCountries,
    onFilterIndicies,
    defaultIndices
}) => {

    const [showContinentOptions, setShowContinentOptions] = useState(false);
    const [showCountryOptions, setShowCountryOptions] = useState(false);
    const [showIndexOptions, setShowIndexOptions] = useState(false);

    const [selectedContinentOptions, setSelectedContinentOptions] = useState(continents);
    const [selectedCountryOptions, setSelectedCountryOptions] = useState(countries);
    const [selectedIndexOptions, setSelectedIndexOptions] = useState(defaultIndices);

    useEffect(() => {
        setSelectedContinentOptions(continents);
    }, [continents]);

    useEffect(() => {
        setSelectedCountryOptions(countries);
    }, [countries]);

    const onHandleContinentMouseEnter = () => {
        setShowContinentOptions(true);
    };

    const onHandleContinentMouseLeave = () => {
        setShowContinentOptions(false);
    };

    const onHandleCountryMouseEnter = () => {
        setShowCountryOptions(true);
    };

    const onHandleCountryMouseLeave = () => {
        setShowCountryOptions(false);
    };

    const onHandleIndexMouseEnter = () => {
        setShowIndexOptions(true);
    };

    const onHandleIndexMouseLeave = () => {
        setShowIndexOptions(false);
    };

    const onChangeContinentsOptions = (selectedOptions) => {
        if (selectedOptions && !selectedOptions.length) return;
        if (!continents.length) return;
        setSelectedContinentOptions(selectedOptions);
        onFilterContinents(selectedOptions);
    };

    const onChangeCountriesOptions = (selectedOptions) => {
        if (selectedOptions && !selectedOptions.length) return;
        if (!countries.length) return;
        setSelectedCountryOptions(selectedOptions);
        onFilterCountries(selectedOptions);
    };

    const onChangeIndicesOptions = (selectedOptions) => {
        if (selectedOptions && !selectedOptions.length) return;
        if (!indices.length) return;
        setSelectedIndexOptions(selectedOptions);
        onFilterIndicies(selectedOptions);
    };

    return (
        <>
            <div className="dropdown-select-filter-item"
                onMouseEnter={onHandleContinentMouseEnter}
                onMouseLeave={onHandleContinentMouseLeave}
            >
                {
                    <Select
                        styles={customStyles}
                        components={{ DropdownIndicator }}
                        options={continents}
                        value={selectedContinentOptions}
                        onChange={onChangeContinentsOptions}
                        menuIsOpen={showContinentOptions}
                        hideSelectedOptions={false}
                        isMulti
                    />
                }
                <div className={`dropdown-label flex align-center ${selectedContinentOptions.length !== continents.length ? 'incomplete-dropdown' : ''}`}>
                    Continents
                    <span className='dropdown-indicator flex center-center'>{dropdownIcon}</span>
                </div>
            </div>

            <div className="dropdown-select-filter-item"
                onMouseEnter={onHandleCountryMouseEnter}
                onMouseLeave={onHandleCountryMouseLeave}
            >
                {
                    <Select
                        styles={customStyles}
                        components={{ DropdownIndicator }}
                        options={countries}
                        value={selectedCountryOptions}
                        onChange={onChangeCountriesOptions}
                        menuIsOpen={showCountryOptions}
                        hideSelectedOptions={false}
                        isMulti
                    />
                }
                <div className={`dropdown-label flex align-center ${selectedCountryOptions.length !== countries.length ? 'incomplete-dropdown' : ''}`}>
                    Countries
                    <span className='dropdown-indicator flex center-center'>{dropdownIcon}</span>
                </div>
            </div>

            <div className="dropdown-select-filter-item"
                onMouseEnter={onHandleIndexMouseEnter}
                onMouseLeave={onHandleIndexMouseLeave}
            >
                {
                    <Select
                        styles={customStyles}
                        components={{ DropdownIndicator }}
                        options={indices}
                        value={selectedIndexOptions}
                        onChange={onChangeIndicesOptions}
                        menuIsOpen={showIndexOptions}
                        hideSelectedOptions={false}
                        isMulti
                    />
                }
                <div className={`dropdown-label flex align-center ${selectedIndexOptions.length !== indices.length ? 'incomplete-dropdown' : ''}`}>
                    Indices
                    <span className='dropdown-indicator flex center-center'>{dropdownIcon}</span>
                </div>
            </div>
        </>
    );
}

export default MacroContinentCountryIndexFilters;