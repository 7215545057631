import { components } from 'react-select';


export const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <span className="arrow-down" />
            </components.DropdownIndicator>
        )
    );
};

export const customStyles = {
    control: (provided) => ({
        ...provided,
        display: 'none',        
    }),
    menu: (provided) => ({
        ...provided,
        width: '200px',
        marginTop: '25px',
        left: '50%',
        transform: 'translateX(-50%)',
        borderRadius: '20px'
    }),
    menuList: (provided) => ({
        ...provided,
        marginTop: '0',
        paddingTop: '0',
        paddingBottom: '0',
        maxHeight: '200px', // set a maximum height to enable scrolling
        overflowY: 'scroll', // enable scrolling
        '&::-webkit-scrollbar': {
            width: '7px', /* Set the width of the scrollbar */
            height: '2px',
            backgroundColor: 'inherit',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#f1f6fc',
            height: '2px',
            borderRadius: '12px',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        textAlign: 'start',
        padding: '10px',
        backgroundColor: '#ffffff',
        color: '#484848',
        fontWeight: state.isSelected ? '900' : '500',
        fontSize: '12px',
        fontFamily: 'Poppins-Regular',
        borderBottom: state.isFocused && !state.isLast ? '2px solid #EEEEEE' : 'none',
        '&:not(:last-child)': {
            borderBottom: '2px solid #EEEEEE',
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#f1f6fc'
        },
    }),
};