import React, { useState } from "react";
import Select, { components } from 'react-select';
import { customStyles, DropdownIndicator } from "../../../shared/dropdown-styles";
import { camelCaseToTitleString } from "../../../shared/global-color-functions";
import { dropdownIcon } from "../../../shared/icons/icons";

const PortfolioFundamentalFilter = ({
    handleToggleFundamentalFilter,
    fundamentalFilter
}) => {

    const fundamentalOptions = [
        { value: 'overTime', label: 'Over Time' },
        { value: 'sectorial', label: 'Sectorial' },
    ]

    const [showOptions, setShowOptions] = useState(false);

    const onFilterChange = (selectedOptions) => {
        if (!selectedOptions.length) return;
        handleToggleFundamentalFilter(selectedOptions[0].value);
    };

    const onHandleMouseEnter = () => {
        setShowOptions(true);
    };

    const onHandleMouseLeave = () => {
        setShowOptions(false);
    };

    const getFixedStr = (str) => {
       return camelCaseToTitleString(str);
    };

    return (
        <div className="dropdown-select-filter-item"
            onMouseEnter={onHandleMouseEnter}
            onMouseLeave={onHandleMouseLeave}
        >
            <Select
                styles={customStyles}
                components={{
                    DropdownIndicator,
                }}
                isMulti
                options={fundamentalOptions}
                value={fundamentalFilter}
                onChange={onFilterChange}
                menuIsOpen={showOptions}
                hideSelectedOptions={false}
            />
            <div className={`dropdown-label flex align-center`}>{getFixedStr(fundamentalFilter)} <span className='dropdown-indicator flex center-center'>{dropdownIcon}</span></div>
        </div>
    );
}

export default PortfolioFundamentalFilter;