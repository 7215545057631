import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import './create-fund.scss';
import { plusIcon } from "../../../../shared/icons/icons";
import { getBanners, setNewFund } from "../../../../shared/actions/fund-actions";
import CreateFundBanners from "./create-fund-banners/create-fund-banners";
import CreateFundInputs from "./create-fund-inputs/create-fund-inputs";

const CreateFund = ({ loggedInUser }) => {

    const history = useHistory();

    const [isOpen, setIsOpen] = useState(false);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [bannerOptions, setBannerOptions] = useState([]);
    const [showBanners, setShowBanners] = useState(false);

    const handleChangeBanner = (banner) => {
        setSelectedBanner(banner);
        setShowBanners(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const banners = await getBanners();
                setBannerOptions(banners);
            } catch (error) {
                console.error('Error fetching data:', error);
            };
        };
        fetchData();
    }, []);


    const handleCreateFund = async (fundName, fundOption) => {
        const fundImg = selectedBanner.pngName;
        const isFundPublic = fundOption === 'public' ? true : false;    
        try {
            // Assuming loggedInUser is defined elsewhere
            const result = await setNewFund(loggedInUser, fundName, fundImg, isFundPublic);
            if (result) {
                console.log("success", result);
                history.push(`/funds/${fundName}`);
            } else {
                console.error("Failed to create new fund");
            }
        } catch (error) {
            console.error("Error creating new fund:", error);
        }
    };
    

    return (
        <>
            <div className="flex space-between align-center mb20">
                <h2>Suggested for you</h2>
                <div
                    className="btn btn-border"
                    onClick={() => setIsOpen(prevState => !prevState)}>
                    Create Fund
                </div>
            </div>
            <div className="flex align-center">
                {
                    isOpen && (
                        <div className="create-fund-crest" style={{ border: selectedBanner ? 'none' : '1px solid #484848' }}>
                            {
                                selectedBanner &&
                                <img className="create-fund-crest-img" src={selectedBanner.pngUrl} alt="" />
                            }
                            <div
                                className="create-fund-crest-btn btn btn-border"
                                onClick={() => setShowBanners(prevState => !prevState)}>
                                Edit
                            </div>
                        </div>
                    )
                }
                {
                    isOpen ? (
                        showBanners ? (
                            <CreateFundBanners banners={bannerOptions} handleChangeBanner={handleChangeBanner} />
                        ) : (
                            <CreateFundInputs handleCreateFund={handleCreateFund} />
                        )
                    ) : null
                }
            </div>

        </>
    );
}

export default CreateFund;