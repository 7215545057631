const IconBtn = ({ icon, iconSize = '1.5rem', text, handleClickEv }) => {

    const handleClick = () => {
        if (handleClickEv) {
            handleClickEv();
        }
    };

    return (
        <div
            className='icon-btn btn btn-border flex'
            onClick={handleClick}>
            <div
                className="flex align-center mr10"
                style={{ fontSize: iconSize }}
            >
                {icon}
            </div>
            <div className="mr10">
                {text}
            </div>
        </div>
    );
}

export default IconBtn;