import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import HeaderSearch from './header-search/header-search';
import './app-header.scss';
import Status from '../../user-pool/status';
import HeaderPages from './header-pages/header-pages';
import Notepad from '../notepad/notepad';
import TutorialToggle from '../tutorial-toggle/tutorial-toggle';

const AppHeader = ({
    loggedInUser,
    setLoggedInUser,
    setUserFollowing,
    isSideMenuOpen,
    onLogout,
    blinkCmp,
    toggleShouldSeeTutorial,
    shouldSeeTutorial
}) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 820);
    const [isMedMobile, setIsMedMobile] = useState(window.innerWidth < 650);
    const [isSmallMobile, setIsSmallMobile] = useState(window.innerWidth < 620);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 820);
            setIsSmallMobile(window.innerWidth < 620);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNavigateToHome = () => {
        history.push('/');
    };

    const logoWidth = {
        width: isMobile ? '50px' : '200px',
    };

    const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);

    const getWidth = () => {
        if (isMobile) {
            return isSearchBarOpen ? '100%' : 'fit-content';
        } else {
            return '100%';
        }
    };

    const BigScreenRightContainer = () => {
        return <>
            {
                isMobile && !isSearchBarOpen ? <div className='flex align-centetr'>
                    <HeaderSearch
                        isMobile={isMobile}
                        loggedInUser={loggedInUser}
                        isSearchBarOpen={isSearchBarOpen}
                        setIsSearchBarOpen={setIsSearchBarOpen}
                    />

                    <Status
                        loggedInUser={loggedInUser}
                        setLoggedInUser={setLoggedInUser}
                        setUserFollowing={setUserFollowing}
                        onLogout={onLogout}
                    />
                </div> : <div className="flex space-between" style={{ width: '100%' }}>


                    <div style={{ width: '100%' }}>
                        <HeaderSearch
                            isMobile={isMobile}
                            loggedInUser={loggedInUser}
                            isSearchBarOpen={isSearchBarOpen}
                            setIsSearchBarOpen={setIsSearchBarOpen}
                        />
                    </div>

                    <Status
                        loggedInUser={loggedInUser}
                        setLoggedInUser={setLoggedInUser}
                        setUserFollowing={setUserFollowing}
                        onLogout={onLogout}
                    />
                </div>
            }
        </>
    };

    const RegScreenSize = () => {
        return <div
            className='app-header-container card-container flex align-center space-between'>

            <div
                style={{ width: '200px' }}
                onClick={handleNavigateToHome}
                className={`app-header-icon flex align-center`}
            >
                <img
                    id={'mobile-home-navigation'}
                    src="/img/portfollow-logo/PortFollow-icon2.png"
                    alt="logo"
                    className='app-header-logo'
                />
                <div className={`'findatasheets-logo-font'`}>PortFollow</div>

            </div>

            <div
                className="flex flex-end"
                style={{ width: getWidth() }}>
                {
                    <HeaderSearch
                        loggedInUser={loggedInUser}
                        isSearchBarOpen={isSearchBarOpen}
                        setIsSearchBarOpen={setIsSearchBarOpen}
                    />
                }
            </div>

            <div className='flex flex-end' style={{ width: '200px' }}>

                <Notepad
                    blinkCmp={blinkCmp}
                />

            </div>
        </div>
    };

    const SmallScreenSize = () => {
        return <div
            className='app-header-container card-container flex align-center space-between'>

            <div
                style={{ width: '50px' }}
                onClick={handleNavigateToHome}
                className={`app-header-icon flex align-center`}
            >
                <img
                    id={'mobile-home-navigation'}
                    src="/img/portfollow-logo/PortFollow-icon2.png"
                    alt="logo"
                    className='app-header-logo'
                />
            </div>
            <BigScreenRightContainer />
        </div>
    };

    return (
        <>
            {
                !isMobile ? <RegScreenSize /> : <SmallScreenSize />
            }
        </>
    );
}

export default AppHeader;