const achievementsList =
    [
        [
            {
                id: '1',
                title: 'Welcome to PortFollow!',
            },
            {
                id: '2',
                title: 'Portfolio Replication',
            },
            {
                id: '3',
                title: 'Cross-Sector Winners',
            },
            {
                id: '4',
                title: 'Mastering Companies Dashboard',
            },
            {
                id: '5',
                title: 'Mastering Sectors Dashboard',
            },
            {
                id: '6',
                title: 'Score Mastery',
            }
        ]
    ];


// const achievementsList = [
//     {
//         id: '1',
//         title: 'Guidebook Mastery',
//         tasks: ['Complete the tutorial journey', 'Visit all pages (Portfolio, Macro, Crypto and Calendar).'],
//         status: 'In progress',
//         completed: 0,
//     },
//     {
//         id: '2',
//         title: 'Posted Portfolios',
//         tasks: ['Share 10 posts on potential portfolio returns including graphs.'],
//         status: '0/10',
//         completed: 0
//     },
//     {
//         id: '3',
//         title: 'Inspiring Community',
//         tasks: ['Follow 10 engaging users.'],
//         status: '0/10',
//         completed: 0
//     },
//     {
//         id: '4',
//         title: 'Portfolio Replication',
//         tasks: ['Copy a portfolio from the feed.', 'Copy 25 portfolios from the feed.'],
//         status: '0/1',
//         completed: 0
//     },
//     {
//         id: '5',
//         title: 'Engagement Marathon',
//         tasks: ['Like 1500 posts or comments.'],
//         status: '0/1500',
//         completed: 0
//     },
//     {
//         id: '6',
//         title: 'Portfolio Performance',
//         tasks: ['Achieve 7% return on a posted portfolio.'],
//         status: '0%',
//         completed: 0
//     },
// ];

export default achievementsList;
