import React, { useState } from "react";
import Select from 'react-select';
import { DropdownIndicator, customStyles } from "../../../shared/dropdown-styles";
import { dropdownIcon } from "../../../shared/icons/icons";

const SingleSectorFilter = ({
    options,
    optionsValue,
    onChange
}) => {

    const [showOptions, setShowOptions] = useState(false);

    const onHandleCurrenciesMouseEnter = () => {
        setShowOptions(true);
    };

    const onHandleCurrenciesMouseLeave = () => {
        setShowOptions(false);
    };

    return (
        <div className="dropdown-select-filter-container flex center-center">
            <div
                className="dropdown-select-filter-item"
                onMouseEnter={onHandleCurrenciesMouseEnter}
                onMouseLeave={onHandleCurrenciesMouseLeave}
            >
                <Select
                    styles={customStyles}
                    components={{
                        DropdownIndicator,
                    }}
                    isMulti
                    options={options}
                    value={optionsValue}
                    onChange={onChange}
                    menuIsOpen={showOptions}
                    hideSelectedOptions={false}
                />
                <div className={`dropdown-label flex align-center ${optionsValue.length !== options.length ? 'incomplete-dropdown' : ''}`}>Companies <span className='dropdown-indicator flex center-center'>{dropdownIcon}</span></div>
            </div>
        </div>
    );
}

export default SingleSectorFilter;