import { createSlice } from "@reduxjs/toolkit";

export const lastPricesSlice = createSlice({
    name: "last-prices",
    initialState: {},
    reducers: {
        setLastPrices(state, action) {
            return { ...state, ...action.payload };
        },
    },
});

export const { setLastPrices } = lastPricesSlice.actions;

export default lastPricesSlice.reducer;