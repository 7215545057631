import React, { useEffect } from 'react';
import EmptyPortfolio from './empty-portfolio.jsx/empty-portfolio';
import SelectedPortfolio from './selected-portfolio.jsx/selected-portfolio';
import { Helmet } from "react-helmet";
import './portfolio.scss';
import ManagePortfolio from './manage-portfolio/manage-portfolio';
import PortfolioGuide from './portfolio-guide/portfolio-guide';

const Portfolio = ({
    navPage,
    loggedInUser,
    myPortfolio,
    mySelectedPortfolio,

    portfolioCompanyData,
    portfolioSectorData,
    portfolioFundamentalData,
    portfolioDescData,
    portfolioSensData,
    handleChangePortfolioCompaniesData,
    handleChangePortfolioSectorsData,
    handleChangePortfolioFundamentalData,
    handleChangePortfolioDescData,
    handleChangePortfolioSensData,
    portfolio,
    addStock,
    addStocks,
    handleChangeSelectedPortfolio,
    showTutorial
}) => {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow - Portfolio</title>
                <meta name="description" content="Conduct a comprehensive analysis of your investment stocks portfolio with a decision support app for all investor levels. Utilize financial and statistical features to gain a comprehensive understanding of performance, monitor market trends, and assess your risk-reward ratio." />
                <link rel="canonical" href="https://www.portfollow.com/portfolio" />
            </Helmet>
            <div className="portfolio-main-container">
                {navPage === 'guide' ? (
                    <PortfolioGuide />
                ) : (
                    loggedInUser && myPortfolio && myPortfolio.portfolioData.length > 0 ? (
                        <SelectedPortfolio
                            navPage={navPage}
                            loggedInUser={loggedInUser}
                            myPortfolio={myPortfolio}
                            mySelectedPortfolio={mySelectedPortfolio}
                            portfolioCompanyData={portfolioCompanyData}
                            portfolioSectorData={portfolioSectorData}
                            portfolioFundamentalData={portfolioFundamentalData}
                            portfolioDescData={portfolioDescData}
                            portfolioSensData={portfolioSensData}
                            handleChangePortfolioCompaniesData={handleChangePortfolioCompaniesData}
                            handleChangePortfolioSectorsData={handleChangePortfolioSectorsData}
                            handleChangePortfolioFundamentalData={handleChangePortfolioFundamentalData}
                            handleChangePortfolioDescData={handleChangePortfolioDescData}
                            handleChangePortfolioSensData={handleChangePortfolioSensData}
                            handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                            addStock={addStock}
                            addStocks={addStocks}
                            portfolio={portfolio}
                        />
                    ) : (
                        <EmptyPortfolio
                            loggedInUser={loggedInUser}
                            selectedPortfolio={mySelectedPortfolio}
                            myPortfolio={myPortfolio}
                            addStocks={addStocks}
                        />
                    )
                )}
            </div>

        </>
    );

}

export default Portfolio;