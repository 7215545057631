import './about-us.scss';

const AboutUs = () => {

    const aboutUs = [
        {
            id: '1',
            title: 'Comprehensive Analysis Tools',
            content: 'Access an array of free-to-use, cutting-edge financial and statistical analysis tools that give you a competitive edge.'
        },
        {
            id: '2',
            title: 'Vibrant Social Connectivity Network',
            content: 'Connect with like-minded investors and experts in a thriving community that fosters growth and collaboration.'
        },
        {
            id: '3',
            title: 'Exciting Challenges and Competitions',
            content: 'Connect with like-minded investors and experts in a thriving community that fosters growth and collaboration.'
        },
        {
            id: '4',
            title: '',
            content: 'Disclaimer: By using this website, you accept our Terms of Use and Privacy Policy. PortFollow is intended for knowledge sharing and educational purposes only. No real money is involved.'
        }
    ];

    const aboutUsContent = () => {
        return <>
            <div className='mb20'>
                At PortFollow, we believe that every single one of you has the potential to become the next legendary hedge fund manager, known across the globe. Our mission is to equip you with the skills and tools needed to make that dream a reality—all for free!
            </div>
            <div className="mb20">
                We are driven by a simple yet ambitious goal: to empower you with the most relevant investment tools to help you invest smarter, succeed, earn big, and have a blast doing it.
            </div>
            <div className="mb10">Our platform offers:</div>
            <div className="mb20">
                <span className='about-us-span'>Comprehensive Analysis Tools:</span> Access an array of free-to-use, cutting-edge financial and statistical analysis tools that give you a competitive edge.
            </div>
            <div className="mb20">
                <span className='about-us-span'>Vibrant Social Connectivity Network:</span> Connect with like-minded investors and experts in a thriving community that fosters growth and collaboration.</div>
            <div className="mb20">
                <span className='about-us-span'>Exciting Challenges and Competitions:</span> Test your strategies and gain practical experience in a fun, hands-on environment with a treasure trove of challenges, competitions, and achievements.
            </div>
            <div>
                We're here to help you navigate the world of investing with confidence, build a strong network, and unlock your true potential. Join us, and let's revolutionize your investment journey together!
            </div>
        </>
    };

    const formatContent = (text) => {
        return text.split('. ').map((sentence, index, array) => (
            <>
                {sentence}{index !== array.length - 1 && '.'}<br />
            </>
        ));
    };

    return (

        <div className='about-container card-container main-content-container user-profile-container'>
            <h1 className='mb20'>PortFollow - About Us</h1>
            <p className='mb10'>
                Find out more about S&P 500 companies, crypto, and more and become a fund member!
            </p>
            <p className='mb10'>
                Join a strong COMMUNITY with FREE ANALYSIS TOOLS.
            </p>
            {
                aboutUs.map(item => (
                    <div className='about-item' key={item.id}>
                        <h4 className='about-item-title'>{item.title}</h4>
                        <p>{formatContent(item.content)}</p>
                    </div>
                ))
            }
        </div>
    );
}

export default AboutUs;