import React from 'react';
import BasicSelectOption from '../../../shared/basic-select-option';

const PortfolioSectors = () => {

    const companyOptions = [
        {
            value: 'AAPL',
            label: '(AAPL) Apple Inc.',
        },
        {
            value: 'MSFT',
            label: '(MSFT) Microsoft Corp',
        },
        {
            value: 'NVDA',
            label: '(NVDA) Nvidia Corp',
        },
        {
            value: 'AMZN',
            label: '(AMZN) Amazon.com Inc',
        }
    ];

    const sectorOptions = [
        {
            value: 'Technology',
            label: 'Technology',
        },
        {
            value: 'Financials',
            label: 'Financials',
        },
        {
            value: 'Health Care',
            label: 'Health Care',
        },
        {
            value: 'Real Estate',
            label: 'Real Estate',
        }
    ];

    const periodOptions = [
        {
            value: '1 Week',
            label: '1 Week',
        },
        {
            value: '1 Month',
            label: '1 Month',
        },
        {
            value: '6 Months',
            label: '6 Months',
        },
        {
            value: '1 Year',
            label: '1 Year',
        }
    ]

    return (
        <>
            <h2 className="app-tutorial-slide-title">Portfolio - Sectors</h2>

            <ul className={"guide-ul"}>
                <li>Explore and analyze stock market industry sectors.</li>
                <li>Customize analysis based on time frames.</li>
                <li>Visualize sector values, returns, and risk metrics through charts.</li>
            </ul>

            <h4 className={"guide-subtitle"}>Filters:</h4>
            <ul className={"guide-ul"}>
                <li>Time Frames: Select specific time periods for analysis.
                </li>
                <li>Sectors: Choose stock market industry sectors to analyze.
                </li>
            </ul>

            <div className="flex wrap justify-center" style={{ marginBottom: '20px' }}>
                <BasicSelectOption options={periodOptions} title="Period" />
                <BasicSelectOption options={sectorOptions} title="Sectors" />
                <BasicSelectOption options={companyOptions} title="Comapnies" />
            </div>

            <h4 className={"guide-subtitle"}>Visualizations:</h4>
            <ul className={"guide-ul"}>
                <li>Value Over Time Line Chart: Displays the value of investments in each sector over time.
                </li>
                <li>Current Sectors Values Pie Chart: Illustrates the current allocation of the portfolio among different sectors.
                </li>
                <li>Reward Bar Charts: Show the return and Sharpe ratio of investments in each sector.
                </li>
            </ul>
            <div className="flex flex-end">
                <a href="/portfolio/sectors" className="guide-btn btn btn-border" title="Sectors">Go to page</a>
            </div>

        </>
    );
}

export default PortfolioSectors;