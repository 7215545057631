import { emptyStarIcon, fullStarIcon } from '../../../shared/icons/icons';
import AchievementsContent from './achievement-content';
import ChallengesContent from './challenges-content';
import './profile-quest.scss';

const QuestItem = ({
    isMyProfile,
    type,
    quest,
    challengeInProcess,
    handleChangeChallengeInProcess,
    userChallenges,
    userAchievements
}) => {

    if (type === 'challenges') {
        return <ChallengesContent
            quest={quest}
            isMyProfile={isMyProfile}
            userChallenges={userChallenges}
            challengeInProcess={challengeInProcess}
            handleChangeChallengeInProcess={handleChangeChallengeInProcess}
            emptyStarIcon={emptyStarIcon}
            fullStarIcon={fullStarIcon}
        />;

    } else if (type === 'achievements') {
        return <AchievementsContent
            quest={quest}
            isMyProfile={isMyProfile}
            userAchievements={userAchievements}
            emptyStarIcon={emptyStarIcon}
            fullStarIcon={fullStarIcon}
        />;
    }


    // const { id, title, tasks, completed, status } = quest;

    // const userChallenge = userChallenges[id];

    // const getTitle = () => {
    //     return userChallenge?.title || title;
    // };

    // const getStatus = () => {
    //     return userChallenge?.status || status;
    // };


    // const renderStars = () => {
    //     const stars = [];
    //     for (let i = 0; i < 3; i++) {
    //         if (i < (userChallenge?.completed || completed)) {
    //             stars.push(
    //                 <div
    //                     key={i}
    //                     className='quest-star flex align-center'>
    //                     {fullStarIcon}
    //                 </div>
    //             );
    //         } else {
    //             stars.push(
    //                 <div
    //                     key={i}
    //                     className='quest-star flex align-center'>
    //                     {emptyStarIcon}
    //                 </div>
    //             );
    //         }
    //     }
    //     return stars;
    // };

    // return (
    //     <div className="quest-container card-container flex space-between">
    //         <div className="quest-item flex align-center">
    //             <div className="quest-stars flex">
    //                 {renderStars()}
    //             </div>
    //             <div className="flex column">
    //                 <h4>{getTitle()}</h4>
    //                 <p>{tasks[completed]}</p>
    //             </div>
    //         </div>
    //         <div className="quest-item flex align-center">
    //             <p>{getStatus()}</p>
    //             {
    //                 isMyProfile &&
    //                 type === 'challenges' &&
    //                 <div
    //                     onClick={() => handleChangeChallengeInProcess(userChallenge || quest)}
    //                     style={{ marginLeft: '20px' }}
    //                     className="btn btn-border">
    //                     {
    //                         challengeInProcess &&
    //                             challengeInProcess.id === id ?
    //                             'Active' : 'Start'
    //                     }
    //                 </div>
    //             }
    //         </div>
    //     </div>
    // );

}

export default QuestItem;
