import React from 'react';
import PageBtn from '../page-btn/page-btn';
import './active-page.scss';

const ActivePageSelection = ({
    handleToggleOption,
    pages,
    activePage,
    getNavLocation,
    guide
}) => {

    return (
        <>
            <div className="active-page-container flex justify-center align-end">
                {pages.map((page, index) => (
                    <div
                        key={index}
                        onClick={() => handleToggleOption(page.toLowerCase())}
                        className='active-page-item'
                    >
                        <PageBtn activePage={activePage} label={page} getNavLocation={getNavLocation} />
                    </div>
                ))}
            </div>
        </>
    );
}

export default ActivePageSelection;