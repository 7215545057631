import React, { useState, useEffect } from "react";
import { arrowDownIcon, arrowUpIcon } from "../../../../shared/icons/icons";
import SimpleFollowBtn from "../../../../shared/simple-follow-btn/simple-follow-btn";
import './single-currency-header.scss';

const SingleCurrencyHeader = ({
    loggedInUser,
    symbol,
    toggleFollowing,
    header,
    userFollowing
}) => {

    const [isFollowing, setIsFollowing] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 850);
    const [isScreenSizeMedium, setIsScreenSizeMedium] = useState(window.innerWidth < 920);

    useEffect(() => {
        const handleResize = () => {
            setIsScreenSizeMedium(window.innerWidth < 920);
            setIsMobile(window.innerWidth < 850);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="single-currency-header-container main-content-container flex space-between align-end">
            <div>
                <div className={`single-currency-header-title flex ${isScreenSizeMedium ? 'column align-center' : ''}`}>
                    <h2 className="single-item-title">{header.symbol}</h2>
                    <div className='single-index-arrow-item single-item-arrow flex align-end'>
                        {
                            header.lastPriceChange.includes('+') || header.lastPricePercentChange.includes('+') ? (
                                <>
                                    <div
                                        className="single-company-price-arrow single-company-price-arrow-up flex align-end"
                                        style={{ marginBottom: '5px' }}
                                    >
                                        {arrowUpIcon}
                                    </div>
                                    {header.lastPriceChange} ({header.lastPricePercentChange})
                                </>
                            ) : (
                                <>
                                    <div
                                        className="single-company-price-arrow single-company-price-arrow-down flex align-end"
                                    >
                                        {arrowDownIcon}
                                    </div>
                                    {header.lastPriceChange} ({header.lastPricePercentChange})
                                </>
                            )
                        }
                    </div>
                </div>
                <div className="single-currency-header-sub-title">
                    <h2 className="single-currency-header-desc">{header.description}</h2>
                </div>
                <div className='flex align-center'>
                    <div className="single-currency-header-date">
                        {header.date}
                    </div>

                </div>
            </div>
            <SimpleFollowBtn
                loggedInUser={loggedInUser}
                symbol={symbol}
                type={"currencies"}
                toggleFollowing={toggleFollowing}
                isFollowing={isFollowing}
                setIsFollowing={setIsFollowing}
                isMobile={isMobile}
                isScreenSizeMedium={isScreenSizeMedium}
                userFollowing={userFollowing}
            />
        </div>
    );
}

export default SingleCurrencyHeader;