import MovingHeaderItem from './moving-header-item';
import './moving-header.scss';

const MovigHeader = ({ movingHeaderData, type }) => {

    const sliderClassName = `moving-header-slide-track${type === 'homepage' ? '-homepage' : type === 'macro-inflation' ? '-macro-inflation' : ''}`;

    return (
        <div className={sliderClassName}>
            <div className="moving-header-slide-track" style={{
                width: `${type === 'homepage' ? 250 : type === 'macro-inflation' ? 320 : 320 * movingHeaderData.length * 3}px`,
                display: "flex",
            }}>
                {movingHeaderData.map((item, index) => (
                    <MovingHeaderItem key={index} item={item} type={type} />
                ))}
                {movingHeaderData.map((item, index) => (
                    <MovingHeaderItem key={index} item={item} type={type} />
                ))}
                             {movingHeaderData.map((item, index) => (
                    <MovingHeaderItem key={index} item={item} type={type} />
                ))}
            </div>
        </div>
    );
}

export default MovigHeader;