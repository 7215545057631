import React from 'react';
import { Link } from 'react-router-dom';

const FollowingList = ({ following, type, title, isMyProfile, username }) => {
    return (
        <>
            {
                following.length > 0 && (
                    <div className="mb20">
                        <div>
                            <h3 className='following-list-title'>{title}</h3>
                        </div>
                        <div className="flex wrap">
                            {following.map((item, index) => (
                                <Link to={`/${type}/${item}`} key={index} className="profile-follow-btn btn btn-border">
                                    {item}
                                </Link>
                            ))}
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default FollowingList;
