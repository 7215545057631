import { arrowDownIcon, arrowUpIcon, portfolioProfitIcon } from "../../../../../shared/icons/icons";

const CopyPostProfitCard = ({
    profitData,
    isToday,
    untilDate
}) => {


    // const getPostProfit = () => {
    //     const postWorthNum = typeof profitData.post_worth === 'string' ? parseFloat(profitData.post_worth) : profitData.post_worth;
    //     const investedWorthNum = typeof profitData.invested_worth === 'string' ? parseFloat(profitData.invested_worth) : profitData.invested_worth;
    //     const result = ((postWorthNum / investedWorthNum) - 1) * 100;
    //     const days = profitData.days_difference;
    //     return { result, days };
    // };

    const getPostProfit = () => {
        const postWorthNum = typeof profitData.post_worth === 'string' ? parseFloat(profitData.post_worth) : profitData.post_worth;
        const investedWorthNum = typeof profitData.invested_worth === 'string' ? parseFloat(profitData.invested_worth) : profitData.invested_worth;

        // Check if profitData.returnData exists
        if (profitData.returnData !== undefined) {
            const result = profitData.returnData;
            const days = profitData.days_difference;
            return { result, days };
        }

        // Calculate result
        const result = ((postWorthNum / investedWorthNum) - 1) * 100;
        const days = profitData.days_difference;
        return { result, days };
    };

    const incommingProfit = getPostProfit();

    const PortfolioProfit = () => {
        if (!profitData) return <></>;
        const resStr = incommingProfit.result.toFixed(1)
        const daysStr = incommingProfit.days;
        const color = +resStr > 0 ? '#5c9ead' : '#e39774';

        return (

            <></>
            // <div className='flex flex-end' style={{ width: '100%' }}>
            //     <div className="post-return-str-container flex align-center">
            //         <div
            //             className='post-return-icon'
            //             style={{ color: color }}
            //         >
            //             { +resStr > 0 ? arrowUpIcon : arrowDownIcon}
            //             {/* {portfolioProfitIcon} */}
            //             </div>
            //         <div className="flex column" >
            //             <div className='post-return-str'>
            //                 <span className='post-return-str-span'>{resStr}%</span> return in <span className='post-return-str-span'>{daysStr}</span> days
            //             </div>
            //             <div
            //                 className='post-return-str post-return-str-lower'>
            //                 until {isToday ? 'today' : untilDate}
            //             </div>
            //         </div>
            //     </div>
            // </div>
        )
    };

    return (
        <PortfolioProfit />
    );
}

export default CopyPostProfitCard;