import { toggleTopPage } from "../../shared/global-color-functions";

const CalendarGuide = () => {
    return (
        <div className="guide-container mt20">
            <div className="card-container mb20">
                <h1 className='guide-page-title'>Calendar</h1>
                <p className='guide-p'>
                    Welcome to the Calendar Page! Here, you can track important events related to both individual companies and macroeconomic factors.
                </p>
                <p className='guide-p'>
                    The calendar provides comprehensive coverage of company-specific events such as splits, dividends, SEC filings, and IPOs, as well as macro events related to the labor market, housing, commodities, and monetary policy.
                </p>
                <p className='guide-p'>
                    You can filter events based on your preferences and analyze them over different time frames.
                </p>
            </div>

            <div className="card-container">
                <h2 className={'guide-title'}>How to use:</h2>
                <ul className={"guide-ul"}>
                    <li>Select your preferred filter options from the sidebar.
                    </li>
                    <li>Explore company events and macro events calendars to stay informed about upcoming events.
                    </li>
                    <li>Customize your view to focus on specific events.
                    </li>
                    <li>Plan your investment strategy based on the insights gained from the calendar.
                    </li>
                    <li>Stay updated with real-time event notifications to react promptly to market developments.
                    </li>
                </ul>
                <p className="guide-p">
                    The Calendar Page offers a comprehensive overview of both company-specific events and macroeconomic factors that could influence financial markets. By utilizing our interactive calendar and filter options, you can stay ahead of the curve and make informed investment decisions.
                </p>

                <p className="guide-p">
                    Start exploring now and take control of your investment journey with our intuitive and user-friendly calendar interface!
                </p>

                <div className="flex flex-end">
                    <div
                        className="guide-btn btn btn-border"
                        onClick={toggleTopPage}>
                        Back to top
                    </div>
                </div>
            </div>

        </div>
    );
}

export default CalendarGuide;