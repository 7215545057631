import React, { useEffect, useState } from 'react';
import { arrowDownIcon, arrowUpIcon, portfolioProfitIcon, sincePostProfitIcon } from '../../../../shared/icons/icons';
import ProfilePortfolioBtns from '../../../user-profile/profile-portfolio/profile-portfolio-btns';
import FeedBar from './feed-charts/feed-bar';
import FeedLine from './feed-charts/feed-line';
import FeedPie from './feed-charts/feed-pie';
import { useMediaQuery } from 'react-responsive';

const HomepageFeedPostContent = ({ content, selectedItemIdx, handleToggleClick, profitRes, selectedPortfolio, untilDate, timestamp }) => {
    const isMobile = useMediaQuery({ maxWidth: 600 });

    const [isToday, setIsToday] = useState(false);

    useEffect(() => {
        const timestampDate = new Date(timestamp);
        const today = new Date();
        if (
            timestampDate.getDate() === today.getDate() &&
            timestampDate.getMonth() === today.getMonth() &&
            timestampDate.getFullYear() === today.getFullYear()
        ) {
            setIsToday(true);
            // console.log('The timestamp is today.');
        } else {
            setIsToday(false);
            // console.log('The timestamp is not today.');
        }
    }, []);


    const PortfolioProfit = () => {
        if (!profitRes) return <></>;
        const resStr = profitRes.result.toFixed(1)
        const daysStr = profitRes.days;
        return (
            <></>
            // <div className='flex flex-end' style={{ width: '100%' }}>
            //     <div className="post-return-str-container flex align-center">
            //         <div
            //             className='post-return-icon'
            //             style={{ color: profitRes.result > 0 ? '#5c9ead' : '#e39774' }}
            //         >
            //             {profitRes.result > 0 ? arrowUpIcon : arrowDownIcon}
            //             </div>
            //         <div className="flex column" >
            //             <div className='post-return-str'>
            //                 <span className='post-return-str-span'>{resStr}%</span> return in <span className='post-return-str-span'>{daysStr}</span> days
            //             </div>
            //             <div
            //                 className='post-return-str post-return-str-lower'>
            //                 until {isToday ? 'today' : untilDate}
            //             </div>
            //         </div>
            //     </div>
            // </div>
        )
    };

    return (
        <div
            className="homepage-feed-user-content flex column space-between"
            style={{ height: !isMobile ? '350px' : '280px', width: '100%' }}
        >
            <div className="homepage-feed-user-content flex column justify-center mb10" style={{ height: '100%' }}>
                {
                    content[selectedItemIdx] && (
                        <React.Fragment key={selectedItemIdx}>
                            {
                                content[selectedItemIdx].type === 'bar' &&
                                <FeedBar
                                    item={content[selectedItemIdx]}
                                    PortfolioProfit={PortfolioProfit}
                                />
                            }
                            {
                                content[selectedItemIdx].type === 'line' &&
                                <FeedLine
                                    item={content[selectedItemIdx]}
                                    PortfolioProfit={PortfolioProfit}
                                />
                            }
                            {
                                content[selectedItemIdx].type === 'pie' &&
                                <FeedPie
                                    item={content[selectedItemIdx]}
                                    PortfolioProfit={PortfolioProfit}
                                />
                            }
                        </React.Fragment>
                    )
                }
            </div>
            <div className="homepage-feed-user-content-toggle flex center-center">
                {
                    content.length > 1 &&
                    content.map((img, idx) => (
                        <div
                            key={idx}
                            className={`homepage-feed-user-content-toggle-btn ${idx === selectedItemIdx ? 'homepage-feed-user-content-toggle-btn-selected' : ''}`}
                            onClick={() => handleToggleClick(idx)}
                        >
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default HomepageFeedPostContent;