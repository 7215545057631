import { toggleTopPage } from "../../../shared/global-color-functions";

const PortfolioSummarize = () => {
    return ( 
        <>
            <h2 className="app-tutorial-slide-title">Portfolio - Summarize</h2>

            <h4 className={"guide-subtitle"}>How to use:</h4>

            <ul className={"guide-ul"}>
                        <li>Navigate to the desired section (Companies, Sectors, Fundamental, Descriptive, or Sensitivity).
                        </li>
                        <li>Use the filters to customize your portfolio analysis based on time frames, sectors, companies, and view modes.
                        </li>
                        <li>Explore the visualizations to gain insights into portfolio performance, risk, and fundamental characteristics.
                        </li>
                        <li>Utilize the statistical summaries and scenario analyses to make informed stock market investment decisions.
                        </li>
                        <li>Manage your portfolio by adding or removing stocks, adjusting allocations, and monitoring performance over time.
                        </li>
                    </ul>


              <p className="app-tutorial-p">
              The Portfolio Analyzer Page provides a comprehensive suite of tools and visualizations to analyze and manage your investment portfolio effectively. Whether you're interested in individual stock market companies, sectors, fundamental ratios, descriptive statistics, or sensitivity analysis, our platform empowers you to make informed decisions and optimize your investment strategy.
            </p>

            <p className="app-tutorial-p">
            Start exploring now and take control of your portfolio with our intuitive and powerful analytics tools! Additionally, you can leverage our social community feature to share graphs from your portfolio, seek advice, or provide insights to fellow investors. Engage with like-minded individuals, exchange ideas, and broaden your investment knowledge through collaborative discussions.
            </p>

            <div className="flex flex-end">
                        <div className="guide-btn btn btn-border" onClick={toggleTopPage}>Back to top</div>
            </div>
        </> 
     );
}
 
export default PortfolioSummarize;