import './matchweek-stats.scss';
import MemberPortfolio from './member-portfolio/member-portfolio';

const MatchweekStats = ({ memberPortfolioData }) => {

    return (
        <>
        <div className="card-container">
            <MemberPortfolio memberPortfolioData={memberPortfolioData} />
        </div>
        </>
    );
}

export default MatchweekStats;
