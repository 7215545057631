import React from "react";
import './pagination-btns.scss';

const PaginationBtns = ({ incrementDisabled, decrementDisabled, handlePagination, handlePageClick, totalPages, selectedPage }) => {
    const incrementStyle = {
        opacity: incrementDisabled ? 0.5 : 1,
        cursor: incrementDisabled ? 'default' : 'pointer',
    };

    const decrementStyle = {
        opacity: decrementDisabled ? 0.5 : 1,
        cursor: decrementDisabled ? 'default' : 'pointer',
    };

    const pages = [];
    pages.push(
        <div
            className={`table-pagination-btn fin-statements-page-btn btn ${decrementDisabled ? 'disabled' : ''}`}
            onClick={() => handlePagination(false)}
            style={{ ...decrementStyle, transform: 'rotate(180deg)' }}
        >
            ➜
        </div>
    );
    for (let i = 0; i < totalPages; i++) {
        const page = i + 1;
        const isSelected = page === selectedPage;
        pages.push(
            <div
                className={`pagination-page-btn table-pagination-btn fin-statements-page-btn btn ${isSelected ? 'selected' : ''}`}
                key={i}
                onClick={() => handlePageClick(page)}
            >
                {page}
            </div>
        );
    }
    pages.push(
        <div
            className={`table-pagination-btn fin-statements-page-btn btn ${incrementDisabled ? 'disabled' : ''}`}
            onClick={() => handlePagination(true)}
            style={incrementStyle}
        >
            ➜
        </div>
    );

    return (
        <div className="table-pagination-container flex">
            <div className="pagination-pages flex wrap">
                {pages}
            </div>
        </div>
    );
};

export default PaginationBtns;
