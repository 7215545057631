import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { setGlobalIsLoading } from "../../../store/loading/loading-actions";
import ActivePageSelection from "../../../shared/active-page/active-page";
import { getPortfolioPage } from "../../../shared/actions/portfolio-actions";
import PortfolioMenu from "../portfolio-menu/portfolio-menu";
import PortfolioPeriodFilter from "../portfolio-filters/portfolio-period-filter";
import Select from 'react-select';
import { customStyles, DropdownIndicator } from "../../../shared/dropdown-styles";
import { dropdownIcon, infoIcon } from "../../../shared/icons/icons";
import { DescriptiveGuide, SensitivityGuide } from "../../../cmps/page-guide";
import PortfolioCompaniesSectorsPage from "../portfolio-pages/portfolio-companies-sectors-page";
import PortfolioFilters from "../cmps/filters";
import { useMediaQuery } from 'react-responsive';
import { Link } from "react-router-dom";
import { hexToRGBA } from "../../../shared/global-color-functions";
import LineChartNoLines from "../../../shared/charts/line-chart/line-chart-no-lines";
import LabelColorContainer from "../../../shared/label-color-container/label-color-container";
import CardContainer from "../../../cmps/card-container/card-container";
import InfoPopup from "../../../shared/info-popup/info-popup";
import LineChartDays from "../../../shared/charts/line-chart/line-chart-days";
import LoadingSpinner from "../../../shared/loading-spinner/loading-spinner";

const PortfolioSensitivity = ({
    loggedInUser,
    myPortfolio,
    mySelectedPortfolio,
    portfolio,
    portfolioSensData,
    handleChangePortfolioSensData,
    addStock,
    addStocks,
    handleChangeSelectedPortfolio,
    showTutorial,
    portfolioFilters,
    handleChangePortfolioFilters
}) => {

    const history = useHistory();

    const [sensData, setSensData] = useState(null);

    const pages = ['companies', 'sectors', 'fundamental', 'descriptive', 'sensitivity', 'guide'];

    const mediumScreen = useMediaQuery({ maxWidth: 1165 });
    const SmallScreen = useMediaQuery({ maxWidth: 1000 });

    const [selectedTimeFilter, setSelectedTimeFilter] = useState('filter5Days');
    const [showInfoPopupMonteCarlo, setShowInfoPopupMonteCarlo] = useState(false);

    const [infoModalType, setinfoModalType] = useState(null);
    const [showInfoModal, setShowInfoModal] = useState(false);

    useEffect(() => {
        const updatedPeriodOption = { value: "oneMonth", label: '1 Month' };

        if (portfolioFilters && portfolioFilters.period.value === "oneWeek") {
            const updatedFilters = {
                ...portfolioFilters,
                period: updatedPeriodOption
            };
            handleChangePortfolioFilters(updatedFilters);
        } else if (!portfolioFilters) {
            const updatedFilters = {
                period: updatedPeriodOption
            };
            handleChangePortfolioFilters(updatedFilters);
        };
        const fetchData = async () => {
            if (portfolioSensData) {
                setSensData(portfolioSensData);
            } else {
                if (!myPortfolio || !myPortfolio.portfolioData) return;
                const res = await getPortfolioPage('sensitivity', myPortfolio.portfolioData);
        
                const sensitivityData = {
                    monteCarlo: {},
                    table: {}
                }

                res.companies.forEach((company) => {
                    const sensitivityKeys = Object.keys(company.sensitivity);
                    sensitivityKeys.forEach(sensitivityPeriod => {
                        if (!sensitivityData.table[sensitivityPeriod]) {
                            sensitivityData.table[sensitivityPeriod] = [];
                        }
                        const sensitivityTable = company.sensitivity[sensitivityPeriod];
                        sensitivityData.table[sensitivityPeriod].push({
                            table: sensitivityTable,
                            symbol: company.symbol,
                            company: company.company,
                            sector: company.sector,
                            color: company.color
                        })
                    });
                });

                sensitivityData.monteCarlo = res.sensitivityMonteCarlo;
                setSensData(sensitivityData);
                handleChangePortfolioSensData(sensitivityData);
            }
        };

        fetchData();
    }, [myPortfolio, portfolioSensData]);


    const handleChangePeriod = (newPeriod) => {
        const newFilters = { ...portfolioFilters };
        newFilters.period = newPeriod;
        handleChangePortfolioFilters(newFilters);
    };

    const handleInfoClick = (type) => {
        setinfoModalType(type);
        setShowInfoModal(true);
    };

    const getLineChartData = () => {
        if (!sensData) return;
        const timeFiltersOrder = ['filter5Days', 'filter30Days', 'filter90Days'];
        const colors = ['#d68d3a', '#849cb2', '#5c7c59', '#ba7d8c'];
        const datasets = timeFiltersOrder.map((timeFilter, index) => {
            const fixedPeriod = sensData.monteCarlo.monteCarloData[timeFilter][portfolioFilters.period.value].normal || 'oneMonth';
            const chartData = fixedPeriod;

            const timeFilterText = {
                'filter5Days': '5 Days',
                'filter30Days': '30 Days',
                'filter90Days': '90 Days',
            }[timeFilter];

            return {
                label: `${timeFilterText}`,
                data: chartData,
                backgroundColor: colors[index],
                borderColor: colors[index],
                borderWidth: 2
            };
        });

        let lowestX = Number.MAX_VALUE;
        let highestX = Number.MIN_VALUE;

        // Iterate through datasets
        datasets.forEach((dataset) => {
            // Iterate through data arrays within each dataset
            dataset.data.forEach((dataPoint) => {
                // Update lowest and highest values based on the 'y' property
                lowestX = Math.min(lowestX, dataPoint.x);
                highestX = Math.max(highestX, dataPoint.x);
            });
        });

        return {
            labels: ['5 Days', '30 Days', '30 Days'],
            datasets: datasets,
            scales: { lowX: lowestX, highX: highestX }
        };
    };

    const getMeanStdLineChart = () => {

        if (!sensData) return;

        const datasets = [];
        let minusStdData = [];
        let plusStdData = [];

        const data = sensData.monteCarlo.monteCarloData['filter90Days'][portfolioFilters.period.value].line;
        const colors = ['#d68d3a', '#849cb2', '#5c7c59', '#ba7d8c'];

        const meanLine = {
            label: `Mean`,
            data: data.map(dayData => ({ x: dayData.days, y: dayData.mean })),
            backgroundColor: colors[4],
            borderColor: colors[4],
            borderWidth: 3,
            fill: false
        };
        datasets.push(meanLine);
        // });

        // Add Minus Std line
        const minusStdLine = {
            label: 'Minus Std',
            data: data.map(dayData => ({ x: dayData.days, y: dayData.minusStd })),
            backgroundColor: colors[3],
            borderColor: colors[3],
            borderWidth: 2,
            fill: false,
            borderDash: [5, 5]
        };
        datasets.push(minusStdLine);

        // Add Plus Std line
        const plusStdLine = {
            label: 'Plus Std',
            data: data.map(dayData => ({ x: dayData.days, y: dayData.plusStd })),
            backgroundColor: colors[3],
            borderColor: colors[3],
            borderWidth: 2,
            fill: false,
            borderDash: [5, 5]
        };
        datasets.push(plusStdLine);

        let lowestY = Number.MAX_VALUE;
        let highestY = Number.MIN_VALUE;

        // Iterate through datasets
        datasets.forEach((dataset) => {
            // Iterate through data arrays within each dataset
            dataset.data.forEach((dataPoint) => {
                // Update lowest and highest values based on the 'y' property
                lowestY = Math.min(lowestY, dataPoint.y);
                highestY = Math.max(highestY, dataPoint.y);
            });
        });

        return {
            datasets,
            scales: { lowY: lowestY, highY: highestY }
        };
    };

    const monteCarloTitle = (
        <>
            <div
                className='position-relative'
            // onMouseEnter={() => setShowInfoPopupMonteCarlo(true)}
            // onMouseLeave={() => setShowInfoPopupMonteCarlo(false)}
            >
                <h4 className="portfolio-chart-title">Scenario Analysis - Monte Carlo Simulation</h4>
                {/* {showInfoPopupMonteCarlo && <InfoPopup type='monte-carlo' />} */}
            </div>
        </>
    );

    const monteCarloContent = (
        <>
            <LineChartNoLines chartData={getLineChartData()} />
        </>
    );

    const meanStdLineChart = (
        <div style={{ width: '99.9%', height: '90%' }}>
            <LineChartDays chartData={getMeanStdLineChart()} type={'dollar'} />
        </div>
    );

    const meanStdTitle = (
        <>
            <h4 className="portfolio-chart-title">Expected Value Over Time</h4>
        </>
    );

    const getPeriodData = (item, selectedPeriod) => {
        return item.table[selectedPeriod] || item.table['oneMonth'];
    };

    const SensitivityTable = () => {
        return <div className="sensitivity-table card-container">
            <div className="flex space-between">
                <div style={{ width: '280px' }}></div>
                {/* <div className="flex justify-center"> */}
                <div className="flex align-center">
                    <h4>Markov Chains</h4>
                    <div
                        className="btn info-btn flex align-center"
                        onClick={() => handleInfoClick('markovChains')}
                    >{infoIcon}
                    </div>
                </div>

                {/* </div> */}
                <div className="flex center-center" style={{ textAlign: 'center' }}>
                    <h4
                        className="portfolio-chart-title flex align-center"
                        style={{ marginBottom: '0' }}
                    >Regression Analysis
                    </h4>
                    <div
                        className="btn info-btn flex align-center"
                        onClick={() => handleInfoClick('regressionAnalysis')}
                    >{infoIcon}
                    </div>
                </div>
            </div>
            <div className="findatasheets-table-header flex space-around">
                <div className="flex space-between">
                    <div className="markovchains-title-item findatasheets-table-cell findatasheets-table-title portfolio-table-title flex align-end justify-center">Symbol</div>
                    <div className="markovchains-title-item findatasheets-table-cell findatasheets-table-title portfolio-table-title flex align-end justify-center">
                        Beta Factor (S&P)
                    </div>
                </div>
                <div className="flex space-between">
                    <div className="markovchains-title-item findatasheets-table-cell findatasheets-table-title portfolio-table-title flex align-end justify-center">Prob. Up</div>
                    <div className="markovchains-title-item findatasheets-table-cell findatasheets-table-title portfolio-table-title flex align-end justify-center">Prob. Remain</div>
                    <div className="markovchains-title-item findatasheets-table-cell findatasheets-table-title portfolio-table-title flex align-end justify-center">Prob. Down</div>
                </div>
                <div className="flex space-between">
                    <div className="markovchains-title-item findatasheets-table-cell findatasheets-table-title portfolio-table-title flex align-end justify-center">Open</div>
                    <div className="markovchains-title-item findatasheets-table-cell findatasheets-table-title portfolio-table-title flex align-end justify-center">Volume</div>
                </div>
            </div>
            {sensData.table.table.map((item, idx) => (
                <div className={`findatasheets-table-row flex space-around ${idx % 2 === 0 ? '' : 'odd-row'}`} key={idx}>
                    <div className="findatasheets-table-cell findatasheets-table-symbol flex space-between" style={{ width: '280px' }}>
                        <Link className="markovchains-title-item findatasheets-table-cell flex center-center" to={`/company/${item.symbol}`}>
                            {item.symbol}
                        </Link>
                        <div className="markovchains-title-item indatasheets-table-cell flex center-center">{getPeriodData(item, portfolioFilters.period.value).beta}</div>
                    </div>
                    <div className="flex space-between">
                        <div className="markovchains-title-item flex center-center findatasheets-table-cell">{getPeriodData(item, portfolioFilters.period.value).stationaryDistributionMarkovChains.probUp}</div>
                        <div className="markovchains-title-item flex center-center findatasheets-table-cell">{getPeriodData(item, portfolioFilters.period.value).stationaryDistributionMarkovChains.probRemain}</div>
                        <div className="markovchains-title-item flex center-center findatasheets-table-cell">{getPeriodData(item, portfolioFilters.period.value).stationaryDistributionMarkovChains.probDown}</div>
                    </div>
                    <div className="flex space-between">
                        <div className="markovchains-title-item flex center-center findatasheets-table-cell">{getPeriodData(item, portfolioFilters.period.value).regressionCoeffs.dailyOpen}</div>
                        <div className="markovchains-title-item flex center-center findatasheets-table-cell">{getPeriodData(item, portfolioFilters.period.value).regressionCoeffs.Volume}</div>
                    </div>
                </div>
            ))}
        </div>
    };

    const MedScreenSensTableMarkovChains = () => {
        return <div className="card-container mb20">
            <div className="flex">
                <h4>Markov Chains</h4>
                <div
                    className="btn info-btn"
                    onClick={() => handleInfoClick('markovChains')}>
                    {infoIcon}
                </div>
            </div>
            <div className="port-sens-med-table">
                <div className="flex space-between">
                    <div
                        className="markovchains-title-item findatasheets-table-cell findatasheets-table-title portfolio-table-title flex align-end justify-center">
                        Symbol
                    </div>
                    <div
                        className="markovchains-title-item findatasheets-table-cell findatasheets-table-title portfolio-table-title flex align-end justify-center">
                        Prob. Up
                    </div>
                    <div
                        className="markovchains-title-item findatasheets-table-cell findatasheets-table-title portfolio-table-title flex align-end justify-center">
                        Prob. Remain
                    </div>
                    <div
                        className="markovchains-title-item findatasheets-table-cell findatasheets-table-title portfolio-table-title flex align-end justify-center">
                        Prob. Down
                    </div>
                </div>
                {
                    sensData.table.table.map((item, idx) => (
                        <div className={`findatasheets-table-row flex space-between ${idx % 2 === 0 ? '' : 'odd-row'}`} key={idx}>
                            <Link className="markovchains-title-item findatasheets-table-cell flex center-center" to={`/company/${item.symbol}`}>
                                {item.symbol}
                            </Link>
                            <div className="markovchains-title-item flex center-center findatasheets-table-cell">{getPeriodData(item, portfolioFilters.period.value).stationaryDistributionMarkovChains.probUp}</div>
                            <div className="markovchains-title-item flex center-center findatasheets-table-cell">{getPeriodData(item, portfolioFilters.period.value).stationaryDistributionMarkovChains.probRemain}</div>
                            <div className="markovchains-title-item flex center-center findatasheets-table-cell">{getPeriodData(item, portfolioFilters.period.value).stationaryDistributionMarkovChains.probDown}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    };

    const MedScreenSensTableRegAnalysis = () => {
        return <div className="card-container">
            <div className="flex align-center">
                <h4>Regression Analysis</h4>
                <div
                    className="btn info-btn flex align-center"
                    onClick={() => handleInfoClick('regressionAnalysis')}
                >{infoIcon}
                </div>
            </div>
            <div className="port-sens-med-table">
                <div className="flex space-between">
                    <div className="markovchains-title-item findatasheets-table-cell findatasheets-table-title portfolio-table-title flex align-end justify-center">Symbol</div>
                    <div className="markovchains-title-item findatasheets-table-cell findatasheets-table-title portfolio-table-title flex align-end justify-center">Beta Factor (S&P)</div>
                    <div className="markovchains-title-item findatasheets-table-cell findatasheets-table-title portfolio-table-title flex align-end justify-center">Open</div>
                    <div className="markovchains-title-item findatasheets-table-cell findatasheets-table-title portfolio-table-title flex align-end justify-center">Volume</div>
                </div>
                {
                    sensData.table.table.map((item, idx) => (
                        <div className={`findatasheets-table-row flex space-between ${idx % 2 === 0 ? '' : 'odd-row'}`} key={idx}>
                            <Link className="markovchains-title-item findatasheets-table-cell flex center-center" to={`/company/${item.symbol}`}>
                                {item.symbol}
                            </Link>
                            <div
                                className="markovchains-title-item f indatasheets-table-cell flex center-center">
                                {getPeriodData(item, portfolioFilters.period.value).beta}
                            </div>
                            <div
                                className="markovchains-title-item flex center-center findatasheets-table-cell">
                                {getPeriodData(item, portfolioFilters.period.value).regressionCoeffs.dailyOpen}
                            </div>
                            <div
                                className="markovchains-title-item flex center-center findatasheets-table-cell">
                                {getPeriodData(item, portfolioFilters.period.value).regressionCoeffs.Volume}
                            </div>
                        </div>
                    ))
                }

            </div>
            {showInfoModal && <InfoPopup type={infoModalType} setShowInfoModal={setShowInfoModal} />}
        </div>

    };

    const SmallScreenScenarioAnalysis = () => {
        return <div className="card-container mb20" style={{ height: '300px' }}>
            {/* <h4>Scenario Analysis</h4> */}
            <div className="flex align-center">
                <h4>Scenario Analysis</h4>
                <div
                    className="btn info-btn flex align-center"
                    onClick={() => handleInfoClick('monteCarlo')}
                >{infoIcon}
                </div>
            </div>
            <LineChartNoLines chartData={getLineChartData()} />
            {showInfoModal && <InfoPopup type={infoModalType} setShowInfoModal={setShowInfoModal} />}
        </div>
    };

    const SmallScreenExpectedValueOverTime = () => {
        return <div className="card-container mb20" style={{ height: '300px' }}>
            <h4>Expected Value Over Time</h4>
            <LineChartDays chartData={getMeanStdLineChart()} type={'dollar'} />
        </div>
    };

    const handleToggleOption = (option) => {
        if (option === 'guide') {
            history.push(`/portfolio`);
        } else {
            history.push(`/portfolio/${option}`);
        };
    };

    const getNavLocation = (label) => {
        return `/portfolio/${label}`;
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow | Portfolio Sensitivity</title>
                <meta name="description" content="Analyze and manage your investment portfolio" />
                <link rel="canonical" href="https://www.portfollow.com/portfolio/sensitivity" />
            </Helmet>

            <div className="page-margin-top">
                <ActivePageSelection
                    handleToggleOption={handleToggleOption}
                    pages={pages}
                    activePage={'sensitivity'}
                    getNavLocation={getNavLocation}
                    guide={'/portfolio'}
                />
            </div>

            {
                portfolioFilters &&
                <PortfolioFilters
                    activePage={'sensitivity'}
                    addStocks={addStocks}
                    addStock={addStock}
                    myPortfolio={myPortfolio}
                    mySelectedPortfolio={mySelectedPortfolio}
                    portfolio={portfolio}
                    handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                    loggedInUser={loggedInUser}
                    handleChangePeriod={handleChangePeriod}
                    portfolioFilters={portfolioFilters}
                />
            }


            {
                sensData &&
                    portfolioFilters ? (
                    <div className="main-content-container">
                        {
                            SmallScreen ? (
                                <div className="mb flex column">
                                    <SmallScreenExpectedValueOverTime />
                                    <SmallScreenScenarioAnalysis />
                                    {showInfoModal && <InfoPopup type={infoModalType} setShowInfoModal={setShowInfoModal} />}
                                </div>
                            ) : (
                                <div className=" mb20 flex" style={{ height: '50%' }}>
                                    <CardContainer
                                        title={monteCarloTitle}
                                        innerContent={monteCarloContent}
                                        className={'sensitivity-montecarlo-chart'}
                                        infoModalType={'monteCarlo'}

                                    />
                                    <>
                                        <CardContainer
                                            title={meanStdTitle}
                                            innerContent={meanStdLineChart}
                                            className={'portfolio-sensitivity-meanstd crypto-linechart flex column space-between'}
                                        // infoModalType={'priceOverTime'}
                                        />
                                    </>
                                </div>
                            )
                        }
                        {
                            mediumScreen ? (
                                <div className="flex column">
                                    <MedScreenSensTableMarkovChains />
                                    <MedScreenSensTableRegAnalysis />
                                </div>
                            ) : (
                                <SensitivityTable />
                            )
                        }
                    </div>) : <LoadingSpinner />
            }

            <div className="card-container main-content-container" style={{ margin: '0px auto 20px', width: '' }}>

                {/* <div className="card-container main-content-container" style={{ marginBottom: '20px', width: '100%' }}> */}
                <SensitivityGuide />
            </div>
            {showInfoModal && <InfoPopup type={infoModalType} setShowInfoModal={setShowInfoModal} />}
        </>
    );
}

export default PortfolioSensitivity;