import React, { useState, useEffect } from "react";
import CardContainer from "../../../cmps/card-container/card-container";
import LabelColorContainer from "../../../shared/label-color-container/label-color-container";
import BarChart from "../../../shared/charts/bar-chart/bar-chart";
import PortfolioFundamentalLineChart from "../selected-portfolio.jsx/portfolio-fundamental-linechart";
import { Helmet } from "react-helmet";
import { FundamentalGuide } from "../../../cmps/page-guide";

const PortfolioFundamental = ({ selectedFilter, fundamentalData, fundamentalFilter }) => {

    const [activePage, setActivePage] = useState(null);
    const [fundamentalOptions, setFundamentalOptions] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const [colorLabels, setColorLabels] = useState(null);
    const [lineChartsToDisplay, setLineChartsToDisplay] = useState(null);
    const [barChartsToDisplay, setBarChartsToDisplay] = useState(null);

    useEffect(() => {
        if (!fundamentalData) return;
        const formattedFundamentals = Object.keys(fundamentalData[0].fundamentals).map((key) => {
            const formattedKey = key
                .replace(/([A-Z])/g, ' $1')
                .replace(/^./, (str) => str.toUpperCase());
            return {
                label: formattedKey,
                value: key,
            };
        });
        setActivePage(formattedFundamentals[0].value);
        setFundamentalOptions(formattedFundamentals);
    }, []);

    useEffect(() => {
        const filteredFundamentalData = fundamentalData.filter(item => selectedFilter.sectors.includes(item.sector));
        setDataToDisplay(filteredFundamentalData);
        const filteredColorLabels = filteredFundamentalData.map(item => ({
            symbol: item.symbol,
            color: item.color,
        }));
        setColorLabels(filteredColorLabels);
    }, [selectedFilter, fundamentalFilter, activePage]);

    const handleTogglePage = (option) => {
        setActivePage((prevOption) => {
            return option;
        });
    };

    useEffect(() => {
        if (!dataToDisplay) return;
        const lineChartData = [];

        dataToDisplay.forEach((item, itemIndex) => {
            const fundamentalsData = item.fundamentals && item.fundamentals[activePage] && item.fundamentals[activePage].overTime;

            if (!fundamentalsData || typeof fundamentalsData !== 'object') return;

            Object.keys(fundamentalsData).forEach((chartKey, chartIndex) => {
                const existingChartIndex = lineChartData.findIndex((chart) => chart.label === chartKey);
                const newData = fundamentalsData[chartKey].map((dataPoint) => ({ x: dataPoint.x, y: parseFloat(dataPoint.y) }));
                const newDataset = {
                    label: item.symbol,
                    data: newData,
                    backgroundColor: item.color,
                    borderColor: item.color,
                    borderWidth: 3,
                    fill: false,
                };

                if (existingChartIndex !== -1) {
                    lineChartData[existingChartIndex].datasets.push(newDataset);
                } else {
                    const newChart = {
                        label: chartKey,
                        datasets: [newDataset],
                    };
                    lineChartData.push(newChart);
                }
            });
        });

        setLineChartsToDisplay(lineChartData);

        const barChartData = [];

        dataToDisplay.forEach((item) => {
            const chartKey = item.fundamentals && item.fundamentals[activePage] && item.fundamentals[activePage].sectorial;

            if (!chartKey) return;


            Object.entries(chartKey).forEach(([label, chartData]) => {
                const newData = chartData.map((dataPoint) => ({
                    x: dataPoint.symbol,
                    y: parseFloat(dataPoint.y),
                }));

                const chartColor = item.color;
                const backgroundColors = Array.from({ length: newData.length }, () => chartColor);

                const existingChartIndex = barChartData.findIndex((chart) => chart.datasets[0].label === label);

                if (existingChartIndex !== -1) {
                    // Update existing chart
                    barChartData[existingChartIndex].datasets[0].data.push(...newData.map((dataPoint) => parseFloat(dataPoint.y)));
                    barChartData[existingChartIndex].datasets[0].backgroundColor.push(...backgroundColors);
                    barChartData[existingChartIndex].hover.push(...newData.map((dataPoint) => [dataPoint.x, item.company]));
                    barChartData[existingChartIndex].labels.push(...newData.map((dataPoint) => dataPoint.x));
                } else {
                    // Add new chart
                    const chartItem = {
                        datasets: [{
                            label: label,
                            data: newData.map((dataPoint) => parseFloat(dataPoint.y)),
                            backgroundColor: backgroundColors,
                            borderColor: backgroundColors,
                            borderWidth: 3,
                            fill: false,
                        }],
                        hover: newData.map((dataPoint) => [dataPoint.x, item.company]),
                        labels: newData.map((dataPoint) => dataPoint.x),
                    };
                    barChartData.push(chartItem);
                }
            });
        });

        const sortedBarChartData = barChartData.map((chartItem) => {
            const sortIndex = chartItem.datasets[0].data.map((_, i) => i);
            sortIndex.sort((a, b) => chartItem.datasets[0].data[b] - chartItem.datasets[0].data[a]);
            const sortedChartData = {
                datasets: [{
                    label: chartItem.datasets[0].label,
                    data: sortIndex.map((i) => chartItem.datasets[0].data[i]),
                    backgroundColor: sortIndex.map((i) => chartItem.datasets[0].backgroundColor[i]),
                    borderColor: sortIndex.map((i) => chartItem.datasets[0].borderColor[i]),
                    borderWidth: chartItem.datasets[0].borderWidth,
                    fill: chartItem.datasets[0].fill,
                }],
                hover: sortIndex.map((i) => chartItem.hover[i]),
                labels: sortIndex.map((i) => chartItem.labels[i]),
            };

            return sortedChartData;
        });

        setBarChartsToDisplay(sortedBarChartData);

    }, [dataToDisplay, activePage]);

    const lineCharts = (data) => {
        return <PortfolioFundamentalLineChart chartData={data} onChangeSelectedItem={() => { }} />
    };

    const barCharts = (data) => {
        return <BarChart chartData={data} type={'dollar'} onChangeSelectedItem={() => { }} />
    };

    const formatChartTitle = (str) => {
        return str.replace(/([A-Z])/g, ' $1')
            .replace(/^./, str => str.toUpperCase());
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow - Portfolio Fundamental</title>
                <meta name="description" content="Analyze and manage your investment portfolio" />
                <link rel="canonical" href="https://www.portfollow.com/portfolio/fundamental" />
            </Helmet>

            <div className="fundamental-page-container flex center-center wrap">
                {
                    fundamentalOptions &&
                    fundamentalOptions.map((option) => {
                        return <div key={option.value} className={`portfolio-toggle btn selected-option ${activePage === option.value ? 'selected' : ''}`} onClick={() => handleTogglePage(option.value)}>{option.label}</div>
                    })
                }
            </div>

            {
                colorLabels &&
                <LabelColorContainer
                    labels={colorLabels}
                    height={''}
                    type={'company'}
                    display={'row'}
                />
            }

            <div className="flex wrap">
                {
                    dataToDisplay &&
                    lineChartsToDisplay &&
                    fundamentalFilter === 'overTime' &&
                    lineChartsToDisplay.map((currentItem, index) => (
                        <CardContainer
                            title={formatChartTitle(currentItem.label)}
                            innerContent={lineCharts(currentItem)}
                            className={'portfolio-fundamental-chart-wrapper portolio-fundamental-container chart-wrapper'}
                            key={index}
                            infoModalType={currentItem.label}
                        />
                    ))
                }

                {
                    dataToDisplay &&
                    barChartsToDisplay &&
                    fundamentalFilter === 'sectorial' &&
                    barChartsToDisplay.map((currentItem, index) => (
                        <CardContainer
                            title={formatChartTitle(currentItem.datasets[0].label)}
                            innerContent={barCharts(currentItem)}
                            className={'portolio-fundamental-container port-fund-sect-chart-wrapper port-fund-barcharts'}
                            key={index}
                            infoModalType={currentItem.datasets[0].label}
                        />
                    ))
                }
            </div>
            <div className="card-container main-content-container" style={{width: '100%', marginBottom: '20px'}}>
                <FundamentalGuide />
            </div>
        </>
    );
}

export default PortfolioFundamental;