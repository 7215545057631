export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const hexToRGBA = (hex) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const rgb = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        }
        : shorthandRegex.exec(hex);
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`;
};

export const toggleTopPage = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
};

export const formatNumberToDollarTableString = (number) => {
    if (typeof number === 'string') return number;
    const isNegative = number < 0;
    number = Math.abs(number);
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    let index = 0;
    while (number >= 1000 && index < suffixes.length - 1) {
        number /= 1000;
        index++;
    }
    const formattedNumber = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(number);
    const result = `$${formattedNumber}${suffixes[index]}`;
    return isNegative ? `-${result}` : result;
};

export const getRandomId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomId = '';
    for (let i = 0; i < 20; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomId += characters.charAt(randomIndex);
    }
    return randomId;
};

export const camelCaseToTitleString = (str) => {
    const result = str.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
};

export const formatNumberToPercentString = (number) => {
    if (typeof number === 'string') return number;
    const isNegative = number < 0;
    number = Math.abs(number);
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    let index = 0;
    while (number >= 1000 && index < suffixes.length - 1) {
        number /= 1000;
        index++;
    }
    const formattedNumber = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(number);
    const result = `${formattedNumber}${suffixes[index]}%`;
    return isNegative ? `-${result}` : result;
};

export const globalColors = [''];

export const convertDateToReadableStr = (isoString) => {
    const currentDate = new Date();
    const postDate = new Date(isoString);
    const localPostDate = new Date(postDate.getTime() - postDate.getTimezoneOffset() * 60000);
    const timeDifference = currentDate - localPostDate;
    if (timeDifference < 60000) { // Less than a minute
        return 'now';
    } else if (timeDifference < 3600000) { // Less than an hour
        const minutes = Math.floor(timeDifference / 60000);
        return `${minutes}m`;
    } else if (timeDifference < 86400000) { // Less than a day
        const hours = Math.floor(timeDifference / 3600000);
        return `${hours}h`;
    } else if (timeDifference < 31536000000) { // Less than a year
        const formattedDate = localPostDate.toLocaleDateString("en-US", { month: "short", day: "numeric" });
        return formattedDate;
    } else { // Over a year
        const formattedDate = localPostDate.toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" });
        return formattedDate;
    }
};

export const getTodayDateToDisplay = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = today.getFullYear();
    return `${year}-${month}-${day}`;
};

export const getTodayMonthDayDate = () => {
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
    const day = String(date.getDate()).padStart(2, '0');
    return `${month} ${day}`;
};
// #AB7141 - Rust
// #41AB71 - Teal
// #AB4171 - Raspberry
// #7141AB - Indigo
// #71AB41 - Olive
// #4171AB - Steel Blue
// #AB4182 - Mauve
// #8141AB - Violet
// #AB6241 - Burnt Orange
// #AB4141 - Crimson

