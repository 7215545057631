import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import achievementsList from "./achievements-list";
import ProfileAchievementsList from "./profile-achievements-list";
import { useHistory } from 'react-router-dom';

const ProfileAchievements = ({
    isMyProfile,
    username,
    challengeInProcess,
    handleChangeChallengeInProcess,
    blinkCmp
}) => {

    const userAchievementsLevel = useSelector((state) => state.userAchievements.level);

    return (
        <>
            <ProfileAchievementsList
                isMyProfile={isMyProfile}
                achievementsList={achievementsList[userAchievementsLevel]}
                challengeInProcess={challengeInProcess}
                handleChangeChallengeInProcess={handleChangeChallengeInProcess}
                blinkCmp={blinkCmp}
            />
        </>
    );
}

export default ProfileAchievements;