import React, { useState, useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import './line-chart.scss';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import { formatNumberToDollarTableString } from '../../global-color-functions';
import { useMediaQuery } from 'react-responsive';

const LineChartNoLines = ({ chartData, type, displayLegend }) => {

    const [selectedLine, setSelectedLine] = useState(null);
    const chartRef = useRef(null);

    const mediumScreen = useMediaQuery({ maxWidth: 920 });
    const smallScreen = useMediaQuery({ maxWidth: 800 });

    const options = {
        font: {
            size: smallScreen ? 8 : (mediumScreen ? 10 : 12),
            family: 'Poppins-regular'
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                displayColors: false,
                backgroundColor: '#ffffff',
                titleColor: '#484848',
                borderWidth: '1',
                borderColor: '#484848',
                bodyColor: '#484848',
                cornerRadius: '20',
                padding: '10',
            },
            legend: {
                display: displayLegend ? true : false,
                labels: {
                    font: {
                        size: smallScreen ? 8 : (mediumScreen ? 10 : 12),
                        family: 'Poppins-regular'
                    }
                }
            },
        },
        title: {
            display: true,
            text: 'Companies return'
        },
        scales: {
            x: {
                type: 'linear',
                distribution: 'linear',
                ticks: {
                    maxTicksLimit: mediumScreen ? 4 : 5,
                    font: {
                        size: smallScreen ? 8 : (mediumScreen ? 10 : 12),
                    },
                    callback: function (value, index, values) {
                        if (type === 'percent') {
                            return Math.round(value * 100) + "%";
                        } else {
                            return formatNumberToDollarTableString(value);
                        }
                    },
                    align: 'center',
                },
                display: true,
                grid: {
                    display: true,
                },
            },
            xAxes: [{
                ticks: {
                    autoSkip: false,
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                },
            }],
            y: {
                display: false,
                grid: {
                    display: false,
                },
            },

        },
        elements: {
            point: {
                radius: 0
            }
        },
        onClick: (event, chartElements) => {
            if (chartElements.length > 0) {
                const clickedLine = chartElements[0];
                setSelectedLine(clickedLine.datasetIndex);
            } else {
                setSelectedLine(null);
            }
        },
    }

    useEffect(() => {
        if (chartRef.current && selectedLine !== null) {
            chartRef.current.data.datasets.forEach((dataset, index) => {
                dataset.borderColor = index === selectedLine
                    ? dataset.backgroundColor
                    : '#e74e4b';
                dataset.borderWidth = index === selectedLine ? 3 : 1;
            });
            chartRef.current.update();
        } else if (chartRef.current && selectedLine === null) {
            chartRef.current.data.datasets.forEach((dataset) => {
                dataset.borderColor = dataset.backgroundColor;
                dataset.borderWidth = 1;
            });
            chartRef.current.update();
        }
    }, [selectedLine, chartData]);

    return (
        <div className="chart-container flex justify-center align-end" style={{ width: '100%', height: '90%' }}>
            <Line type={'line'} data={chartData} options={options} style={{ width: '95%' }} className={'mobile-chart-size'} />
        </div>
    );
}

export default LineChartNoLines;