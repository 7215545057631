import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActivePageSelection from '../../shared/active-page/active-page';
import LoadingSpinner from '../../shared/loading-spinner/loading-spinner';
import { fetchDataFromLambda } from '../../shared/s3-bucket-functions';
import { setGlobalIsLoading } from "../../store/loading/loading-actions";
import MacroCommodities from './macro-commodities/macro-commodities';
import MacroCurrencies from './macro-currencies/macro-currencies';
import MacroIndices from './macro-indices/macro-indices';
import MacroGuide from './macro-guide/macro-guide';
import MacroInflation from './macro-inflation/macro-inflation';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import './macro.scss';
import { CurrenciesGuide, DescriptiveGuide, IndicesGuide, InflationGuide } from '../../cmps/page-guide';

const Macro = ({ navPage, pageMacro, setPageMacro }) => {

  const loadingSelector = useSelector((state) => state.loading.isLoading);
  const dispatch = useDispatch();
  const history = useHistory();

  const [periodFilter, setPeriodFilter] = useState('sixMonth');
  const [periodOptionsToDisplay, setPeriodOptionsToDisplay] = useState(null);
  const [activePriceRelative, setActivePriceRelative] = useState('relative');

  const [commodityFactors, setCommodityFactors] = useState(null);
  const [exchangeFactors, setExchangeFactors] = useState(null);
  const [indicesFactors, setIndicesFactors] = useState(null);
  const [inflationFactors, setInflationFactors] = useState(null);

  const [activePage, setActivePage] = useState(navPage);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setGlobalIsLoading(true));
      if (Object.keys(pageMacro).length === 0) {
        try {
          const res = await fetchDataFromLambda('page-macro');
          // console.log('RES', res);
          if (res) {
            setCommodityFactors(res.commodityFactors);
            setExchangeFactors(res.exchangeFactors);
            setIndicesFactors(res.indicesFactors);
            setInflationFactors(res.inflationFactors);
            setPageMacro({
              commodityFactors: res.commodityFactors,
              exchangeFactors: res.exchangeFactors,
              indicesFactors: res.indicesFactors,
              inflationFactors: res.inflationFactors
            });
          }
        } catch (error) {
          // Handle errors as needed
          console.error('Error fetching data:', error);
        }
      } else {
        setCommodityFactors(pageMacro.commodityFactors);
        setExchangeFactors(pageMacro.exchangeFactors);
        setIndicesFactors(pageMacro.indicesFactors);
        setInflationFactors(pageMacro.inflationFactors);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (commodityFactors, exchangeFactors, indicesFactors, inflationFactors) {
      dispatch(setGlobalIsLoading(false));
    };
  }, [commodityFactors, exchangeFactors, indicesFactors, inflationFactors])

  const pages = ['inflation', 'currencies', 'indices', 'commodities', 'guide'];

  const handleToggleOption = (option) => {
    if (option === 'guide') history.push(`/macro`);
    else {
      history.push(`/macro/${option}`);
    }
    setActivePage((prevOption) => {
      return option;
    });
  };

  const getPeriodOptionsToDisplay = (data) => {
    const periodOptions = [
      { value: 'oneWeek', label: '1 Week' },
      { value: 'oneMonth', label: '1 Month' },
      { value: 'sixMonth', label: '6 Months' },
      { value: 'currentYear', label: 'YTD' },
      { value: 'oneYear', label: '1 Year' },
      { value: 'fiveYear', label: '5 Years' },
      { value: 'max', label: 'Max' },
    ];
    const newPeriodOptions = [];
    for (const key in data) {
      const matchingOption = periodOptions.find(
        (option) => option.value === key
      );
      if (matchingOption) {
        newPeriodOptions.push(matchingOption);
      }
    }
    newPeriodOptions.sort(
      (a, b) => periodOptions.findIndex((option) => option === a) -
        periodOptions.findIndex((option) => option === b)
    );
    setPeriodOptionsToDisplay(newPeriodOptions);
  };

  useEffect(() => {
    if (activePage === 'inflation' && inflationFactors) {
      getPeriodOptionsToDisplay(inflationFactors.inflationOverTime);
    } else if (activePage === 'currencies' && exchangeFactors) {
      getPeriodOptionsToDisplay(exchangeFactors.price);
    } else if (activePage === 'indices' && indicesFactors) {
      getPeriodOptionsToDisplay(indicesFactors.price);
    } else if (activePage === 'commodities' && commodityFactors) {
      getPeriodOptionsToDisplay(commodityFactors.price);
    }
  }, [activePage, inflationFactors, exchangeFactors, indicesFactors, commodityFactors]);

  const [clickData, setClickData] = useState(null);

  useEffect(() => {
    const handleClick = (event) => {
      setClickData(event);
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const onPeriodChange = (option) => {
    setPeriodFilter((prevOption) => {
      return option.value;
    })
  };

  const changePriceRelative = (option) => {
    setActivePriceRelative((prevOption) => {
      return option.value;
    })
  };

  const getNavLocation = (label) => {
    return `/macro/${label}`
  };

  const getGuideByActivePage = (activePage) => {
    switch (activePage) {
      case 'inflation':
        return InflationGuide;
      case 'currencies':
        return CurrenciesGuide;
      case 'indices':
        return IndicesGuide;
      case 'commodities':
        return DescriptiveGuide;
      default:
        return <></>;
    }
  };
  const GuideComponent = getGuideByActivePage(activePage);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PortFollow - Macro</title>
        <meta name="description" content="Gain a straightforward understanding of your insights and risks by exploring and comparing essential macro-economic indicators. Explore various useful features, including inflation, interest rates, unemployment, foreign exchange rates, country rankings, and more." />
        <link rel="canonical" href="https://www.portfollow.com/macro" />
      </Helmet>

      <div className='active-page-container-macro page-margin-top'>
        <ActivePageSelection
          handleToggleOption={handleToggleOption}
          pages={pages}
          activePage={activePage}
          getNavLocation={getNavLocation}
        />

        <div className="container-height main-content-container">
          {navPage === '/' || activePage === 'guide' ? (
            <div className="mt20">
              <MacroGuide />
            </div>
          ) : (
            <>
              {activePage !== "" && (
                (activePage === 'inflation' && !inflationFactors) ||
                (activePage === 'currencies' && !exchangeFactors) ||
                (activePage === 'indices' && !indicesFactors) ||
                (activePage === 'commodities' && !commodityFactors)
              ) ? (
                <>
                  <LoadingSpinner />
                  <div className="card-container main-content-container">
                    <GuideComponent />
                  </div>
                </>
              ) : (
                <>
                  {activePage === 'inflation' && inflationFactors && (
                    <MacroInflation
                      inflationData={inflationFactors}
                      periodFilter={periodFilter}
                      onPeriodChange={onPeriodChange}
                      periodOptionsToDisplay={periodOptionsToDisplay}
                      clickData={clickData}
                      setPeriodFilter={setPeriodFilter}
                    />
                  )}

                  {activePage === 'currencies' && exchangeFactors && (
                    <MacroCurrencies
                      currenciesData={exchangeFactors}
                      periodFilter={periodFilter}
                      periodOptionsToDisplay={periodOptionsToDisplay}
                      onPeriodChange={onPeriodChange}
                      activePriceRelative={activePriceRelative}
                      changePriceRelative={changePriceRelative}
                    />
                  )}

                  {activePage === 'indices' && indicesFactors && (
                    <MacroIndices
                      indicesData={indicesFactors}
                      periodFilter={periodFilter}
                      onPeriodChange={onPeriodChange}
                      periodOptionsToDisplay={periodOptionsToDisplay}
                      activePriceRelative={activePriceRelative}
                      changePriceRelative={changePriceRelative}
                      clickData={clickData}
                    />
                  )}

                  {activePage === 'commodities' && commodityFactors && (
                    <MacroCommodities
                      commoditiesData={commodityFactors}
                      periodFilter={periodFilter}
                      onPeriodChange={onPeriodChange}
                      periodOptionsToDisplay={periodOptionsToDisplay}
                      activePriceRelative={activePriceRelative}
                      changePriceRelative={changePriceRelative}
                    />
                  )}
                </>
              )}
            </>

          )}

        </div>
      </div>
    </>
  );
}

export default Macro;