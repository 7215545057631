import React, { useState, useEffect } from "react";
import PortfolioMenu from "../../portfolio/portfolio-menu/portfolio-menu";
import Select from 'react-select';
import { customStyles, DropdownIndicator } from "../../../shared/dropdown-styles";
import { dropdownIcon } from "../../../shared/icons/icons";

const PortfolioFilters = ({
    activePage,
    addStocks,
    addStock,
    myPortfolio,
    mySelectedPortfolio,
    portfolio,
    handleChangeSelectedPortfolio,
    loggedInUser,
    handleChangePeriod,
    portfolioFilters,
    portfolioSectorOptions,
    portfolioActiveSectorOptions,
    handleChangeSector,
    portfolioCompanyOptions,
    portfolioActiveCompanyOptions,
    handleChangeCompany,
    blinkCmp
}) => {

    const options = [
        { value: 'oneWeek', label: '1 Week' },
        { value: 'oneMonth', label: '1 Month' },
        { value: 'sixMonth', label: '6 Months' },
        { value: 'currentYear', label: 'YTD' },
        { value: 'oneYear', label: '1 Year' },
        { value: 'fiveYear', label: '5 Years' },
        { value: 'max', label: 'Max' }
    ];

    const [periodOptions, setPeriodOptions] = useState(options);

    useEffect(() => {
        if (activePage === 'descriptive') {
            setPeriodOptions(prevOptions =>
                prevOptions.filter(
                    option => option.value !== 'oneWeek' &&
                        option.value !== 'currentYear' &&
                        option.value !== 'max'
                )
            );
        } if (activePage === 'sensitivity') {
            setPeriodOptions([
                { value: 'oneMonth', label: '1 Month' },
                // { value: 'threeMonth', label: '3 Months' },
                { value: 'sixMonth', label: '6 Months' },
                { value: 'oneYear', label: '1 Year' },
            ]);
        } else {
            setPeriodOptions(options);
        }
    }, [activePage]);

    const [showPeriodOptions, setShowPeriodOptions] = useState(false);
    const [showCompanyOptions, setShowCompanyOptions] = useState(false);
    const [showSectorOptions, setShowSectorOptions] = useState(false);

    const onHandleMousePeriodEnter = () => {
        setShowPeriodOptions(true);
    };

    const onHandleMousePeriodLeave = () => {
        setShowPeriodOptions(false);
    };

    const onHandleMouseSectorEnter = () => {
        setShowSectorOptions(true);
    };

    const onHandleMouseSectorLeave = () => {
        setShowSectorOptions(false);
    };

    const onHandleMouseCompanyEnter = () => {
        setShowCompanyOptions(true);
    };

    const onHandleMouseCompanyLeave = () => {
        setShowCompanyOptions(false);
    };


    return (
        <div className="dropdown-select-filter-container flex main-content-container">

            <PortfolioMenu
                addStock={addStock}
                addStocks={addStocks}
                myPortfolio={myPortfolio}
                selectedPortfolio={mySelectedPortfolio}
                portfolio={portfolio}
                handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                loggedInUser={loggedInUser}
                blinkCmp={blinkCmp}
            />

            <div className="flex wrap center-center flex-grow1">

                <div className="dropdown-select-filter-item"
                    onMouseEnter={onHandleMousePeriodEnter}
                    onMouseLeave={onHandleMousePeriodLeave}
                >
                    {
                        periodOptions && <Select
                            styles={customStyles}
                            components={{ DropdownIndicator }}
                            options={periodOptions}
                            value={portfolioFilters.period.value}
                            onChange={handleChangePeriod}
                            menuIsOpen={showPeriodOptions}
                            hideSelectedOptions={false}
                        />
                    }
                    {
                        portfolioFilters.period.label && <div
                            className="dropdown-label flex align-center">
                            {portfolioFilters.period.label}
                            <span
                                className='dropdown-indicator flex center-center'
                            >
                                {dropdownIcon}
                            </span>
                        </div>
                    }
                </div>

                {
                    activePage !== 'sensitivity' &&
                    <div className="dropdown-select-filter-item"
                        onMouseEnter={onHandleMouseSectorEnter}
                        onMouseLeave={onHandleMouseSectorLeave}
                    >
                        <Select
                            styles={customStyles}
                            components={{
                                DropdownIndicator,
                            }}
                            isMulti
                            options={portfolioSectorOptions}
                            value={portfolioActiveSectorOptions}
                            onChange={handleChangeSector}
                            menuIsOpen={showSectorOptions}
                            hideSelectedOptions={false}
                        />
                        <div className={`dropdown-label flex align-center ${portfolioActiveSectorOptions.length !== portfolioSectorOptions.length ? 'incomplete-dropdown' : ''}`}>
                            Sectors
                            <span className='dropdown-indicator flex center-center'>{dropdownIcon}</span>
                        </div>
                    </div>
                }

                {
                    activePage !== 'sectors' &&
                    activePage !== 'sensitivity' &&
                    <div className="dropdown-select-filter-item"
                        onMouseEnter={onHandleMouseCompanyEnter}
                        onMouseLeave={onHandleMouseCompanyLeave}
                    >
                        <Select
                            styles={customStyles}
                            components={{
                                DropdownIndicator,
                            }}
                            isMulti
                            options={portfolioCompanyOptions}
                            value={portfolioActiveCompanyOptions}
                            onChange={handleChangeCompany}
                            menuIsOpen={showCompanyOptions}
                            hideSelectedOptions={false}
                        />
                        <div className={`dropdown-label flex align-center ${portfolioActiveCompanyOptions.length !== portfolioCompanyOptions.length ? 'incomplete-dropdown' : ''}`}>Companies <span className='dropdown-indicator flex center-center'>{dropdownIcon}</span></div>
                    </div>

                }
            </div>
        </div >
    );
}

export default PortfolioFilters;