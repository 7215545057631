import { toggleTopPage } from "../../../shared/global-color-functions";

const CryptoGuide = () => {
    return (
        <div className="crypto-guide flex column">

            <div className="card-container mb20">
                <h1 className='guide-page-title'>Crypto</h1>
                <p className='guide-p'>
                    Welcome to the Crypto Page!
                </p>
                <p className='guide-p'>
                    Here, you can explore various cryptocurrencies through interactive line charts showcasing their performance, return, and Sharpe ratios. The page offers filters to customize your viewing experience, allowing you to focus on specific time frames, categories, currencies, and choose between relative or actual price viewing.
                </p>
            </div>

            <div className="card-container">
                <h2 className={'guide-title'}>How to use:</h2>
                <ul className={"guide-ul"}>
                    <li>Select your preferred filter options from the sidebar.
                    </li>
                    <li>Explore line charts to analyze the historical performance of cryptocurrencies.
                    </li>
                    <li>Use bar charts to compare returns and Sharpe ratios across different assets.
                    </li>
                    <li>Customize your view and adjust filters to refine your analysis.
                    </li>
                    <li>Find the best crypto performers by adjusting the filters according to your preferences.
                    </li>
                    <li>Make informed investment decisions based on the insights gained from the data.
                    </li>
                </ul>
                <p className='guide-p'>
                    The Crypto Page provides valuable insights into the performance, return, and risk-adjusted returns of various cryptocurrencies. Whether you're a seasoned investor or just starting in the cryptomarket, our interactive charts and filters empower you to make informed decisions and stay ahead in the market. Start exploring now and unlock the potential of cryptocurrency investments!
                </p>

                <div className="flex flex-end">
                    <div
                        style={{ marginRight: '20px' }}
                        className="guide-btn btn btn-border"
                        onClick={toggleTopPage}>
                        Back to top
                    </div>
                    <a
                        href="/crypto/currencies"
                        className="guide-btn btn btn-border" title="Crypto Currencies">
                        Go to page
                    </a>
                </div>
            </div>

        </div>
    );
}

export default CryptoGuide;