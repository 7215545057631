import React from "react";
import PortfolioCompaniesSectorsPage from "./portfolio-pages/portfolio-companies-sectors-page";
import PortfolioDescriptive from "./portfolio-pages/portfolio-descriptive";
import PortfolioFundamental from "./portfolio-pages/portfolio-fundamental";
import PortfolioSensitivity from "./portfolio-pages/portfolio-sensitivity";

const PortfolioContent = ({
    selectedFilter,
    companyData,
    sectorData,
    fundamentalData,
    descData,
    sensData,
    fundamentalFilter,
    portfolioData
}) => {

    return (
        <>
            <div className="container-height main-content-container">
                {/* <div className="main-content-container"> */}
                {
                    (
                        (selectedFilter?.page === 'companies' && companyData) ||
                        (selectedFilter?.page === 'sectors' && sectorData)
                    ) &&
                    selectedFilter &&
                    <PortfolioCompaniesSectorsPage
                        selectedFilter={selectedFilter}
                        companyData={companyData}
                        sectorData={sectorData}
                        portfolioData={portfolioData}
                    />
                }
                {
                    fundamentalData &&
                    fundamentalData.length > 0 &&
                    selectedFilter.page === 'fundamental' &&
                    <PortfolioFundamental
                        selectedFilter={selectedFilter}
                        fundamentalData={fundamentalData}
                        fundamentalFilter={fundamentalFilter}
                    />
                }
                {
                    fundamentalData && !fundamentalData.length && selectedFilter.page === 'fundamental' &&
                    <div> There is no data to show.</div>
                }
                {
                    descData &&
                    selectedFilter.page === 'descriptive' &&
                    <PortfolioDescriptive
                        selectedFilter={selectedFilter}
                        descData={descData}
                    />
                }
                {
                    sensData &&
                    selectedFilter.page === 'sensitivity' &&
                    <PortfolioSensitivity
                        selectedFilter={selectedFilter}
                        sensData={sensData}
                    />
                }
            </div>
        </>
    );
}

export default PortfolioContent;