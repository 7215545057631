import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { setGlobalIsLoading } from "../../../store/loading/loading-actions";
import ActivePageSelection from "../../../shared/active-page/active-page";
import { getPortfolioPage } from "../../../shared/actions/portfolio-actions";
import PortfolioMenu from "../portfolio-menu/portfolio-menu";
import PortfolioPeriodFilter from "../portfolio-filters/portfolio-period-filter";
import Select from 'react-select';
import { customStyles, DropdownIndicator } from "../../../shared/dropdown-styles";
import { dropdownIcon } from "../../../shared/icons/icons";
import { DescriptiveGuide } from "../../../cmps/page-guide";
import PortfolioCompaniesSectorsPage from "../portfolio-pages/portfolio-companies-sectors-page";
import PortfolioFilters from "../cmps/filters";
import { useMediaQuery } from 'react-responsive';
import { Link } from "react-router-dom";
import { hexToRGBA } from "../../../shared/global-color-functions";
import LineChartNoLines from "../../../shared/charts/line-chart/line-chart-no-lines";
import LabelColorContainer from "../../../shared/label-color-container/label-color-container";
import CardContainer from "../../../cmps/card-container/card-container";
import LoadingSpinner from "../../../shared/loading-spinner/loading-spinner";
import EmptyPortfolio from "../empty-portfolio.jsx/empty-portfolio";


const PortfolioDescriptive = ({
    loggedInUser,
    myPortfolio,
    mySelectedPortfolio,
    portfolio,
    portfolioDescData,
    handleChangePortfolioDescData,
    addStock,
    addStocks,
    handleChangeSelectedPortfolio,
    showTutorial,
    portfolioFilters,
    handleChangePortfolioFilters,
    portfolioFullCompanyOptions,
    portfolioCompanyOptions,
    portfolioActiveCompanyOptions,
    portfolioSectorOptions,
    portfolioActiveSectorOptions,
    handleChangePortfolioFullCompanyOptions,
    handleChangePortfolioCompanyOptions,
    handleChangePortfolioActiveCompanyOptions,
    handleChangePortfolioSectorOptions,
    handleChangePortfolioActiveSectorOptions
}) => {

    const pages = ['companies', 'sectors', 'fundamental', 'descriptive', 'sensitivity', 'guide'];

    const loadingSelector = useSelector((state) => state.loading.isLoading);
    const dispatch = useDispatch();
    const history = useHistory();

    const [descData, setDescData] = useState(null);

    const [colorLabels, setColorLabels] = useState(null);
    const [kernelDist, setKernelDist] = useState(null);
    const [correlations, setCorrelations] = useState(null);
    const [topTable, setTopTable] = useState(null);
    const [bottomTable, setBottomTable] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null)

    const topTableSize1 = useMediaQuery({ maxWidth: 500 });

    const isMobile = useMediaQuery({ maxWidth: 920 });
    const isMobileScreen = useMediaQuery({ maxWidth: 600 });

    useEffect(() => {
        if (!myPortfolio) return;

        const updatedPeriodOption = { value: "oneMonth", label: '1 Month' };

        const fetchData = async () => {
            try {
                if (portfolioDescData) {
                    if (portfolioFilters && portfolioFilters.period.value === "oneWeek") {
                        const updatedFilters = {
                            ...portfolioFilters,
                            period: updatedPeriodOption
                        };
                        handleChangePortfolioFilters(updatedFilters);
                    };
                    console.log('1 ***', portfolioDescData, portfolioFilters);
                    if (!portfolioFilters || !portfolioFilters.sectors || !portfolioFilters.companies) {
                        const uniqueSectors = Array.from(new Set(portfolioDescData.kernelDensity['oneMonth'].map(item => item.sector)));
                        const uniqueCompanies = Array.from(new Set(portfolioDescData.kernelDensity['oneMonth'].map(item => item.symbol)));
                        const filters = {
                            period: { value: "oneMonth", label: '1 Month' },
                            sectors: uniqueSectors,
                            companies: uniqueCompanies
                        };
                        handleChangePortfolioFilters(filters);
                    };
                    if (!portfolioFilters || !portfolioFilters.companies || !portfolioFilters.sectors) return;
                    renderDescData(portfolioDescData);
                    setDescData(portfolioDescData);
                } else {
                    const res = await getPortfolioPage('descriptive', myPortfolio.portfolioData);
                    if (!portfolioFilters) {
                        const uniqueSectors = Array.from(new Set(res.companies.map(item => item.sector)));
                        const uniqueCompanies = Array.from(new Set(res.companies.map(item => item.symbol)));
                        const filters = {
                            period: { value: "oneMonth", label: '1 Month' },
                            sectors: uniqueSectors,
                            companies: uniqueCompanies
                        };
                        handleChangePortfolioFilters(filters);
                    }
                    else if (portfolioFilters && portfolioFilters.period.value === "oneWeek") {
                        const updatedFilters = {
                            ...portfolioFilters,
                            period: updatedPeriodOption
                        };
                        handleChangePortfolioFilters(updatedFilters);
                    };
                    // if (!PortfolioFilters) return;
                    if (!portfolioCompanyOptions || !portfolioFullCompanyOptions) {
                        const uniqueCompanyOptions = res.companies.map(item => ({
                            label: item.symbol,
                            value: item.symbol
                        }));
                        handleChangePortfolioFullCompanyOptions(uniqueCompanyOptions);
                        handleChangePortfolioCompanyOptions(uniqueCompanyOptions);
                        handleChangePortfolioActiveCompanyOptions(uniqueCompanyOptions);
                    }

                    if (!portfolioSectorOptions) {
                        const uniqueSectorOptions = [];
                        const seen = new Set();

                        res.companies.forEach(item => {
                            const sectorValue = item.sector;
                            if (!seen.has(sectorValue)) {
                                uniqueSectorOptions.push({
                                    label: sectorValue,
                                    value: sectorValue
                                });
                                seen.add(sectorValue);
                            }
                        });
                        handleChangePortfolioSectorOptions(uniqueSectorOptions);
                        handleChangePortfolioActiveSectorOptions(uniqueSectorOptions);
                    };

                    if (!portfolioFilters || !portfolioFilters.companies || !portfolioFilters.sectors) return;

                    const descriptiveData = {
                        kernelDensity: {},
                        correlations: {},
                        topTable: {},
                        bottomTable: {}
                    }

                    res.companies.forEach((company) => {

                        const kernelKeys = Object.keys(company.descriptive.kernelDensity);
                        kernelKeys.forEach((kernelPeriod) => {
                            if (!descriptiveData.kernelDensity[kernelPeriod]) {
                                descriptiveData.kernelDensity[kernelPeriod] = [];
                            };
                            const kdeChartData = company.descriptive.kernelDensity[kernelPeriod];
                            descriptiveData.kernelDensity[kernelPeriod].push({
                                symbol: company.symbol,
                                company: company.company,
                                sector: company.sector,
                                color: company.color,
                                kdeDist: kdeChartData.kdeDist
                            })

                            if (!descriptiveData.topTable[kernelPeriod]) {
                                descriptiveData.topTable[kernelPeriod] = [];
                            };

                            descriptiveData.topTable[kernelPeriod].push({
                                symbol: company.symbol,
                                company: company.company,
                                sector: company.sector,
                                color: company.color,
                                tableData: [company.symbol, kdeChartData.mean, kdeChartData.median, kdeChartData.std, kdeChartData.range]
                            })

                            const outliersKeys = Object.keys(company.descriptive.outliersTable);
                            outliersKeys.forEach(outlierPeriod => {
                                if (!descriptiveData.bottomTable[outlierPeriod]) {
                                    descriptiveData.bottomTable[outlierPeriod] = [];
                                }
                                const outlierTableData = company.descriptive.outliersTable[outlierPeriod];
                                descriptiveData.bottomTable[outlierPeriod] = descriptiveData.bottomTable[outlierPeriod].concat(
                                    outlierTableData.map(item => ({
                                        symbol: company.symbol,
                                        company: company.company,
                                        sector: company.sector,
                                        color: company.color,
                                        tableData: [company.symbol, item.return, item.date, item.method],
                                    }))
                                ).sort((a, b) => new Date(b.tableData[2]) - new Date(a.tableData[2]));
                            });

                        })

                    });

                    descriptiveData.correlations = res.descriptiveCorrelationsTable;
                    setDescData(descriptiveData);
                    renderDescData(descriptiveData);
                    handleChangePortfolioDescData(descriptiveData);
                }
            } catch (error) {
                console.error('Error fetching portfolio data:', error);
            }
        };

        fetchData();
    }, [myPortfolio, portfolioFilters, portfolioDescData, descData]);

    const renderDescData = (data) => {
        let selectedPeriod = data.kernelDensity[portfolioFilters.period.value] ? portfolioFilters.period.value : 'oneMonth';
        if (data.kernelDensity && data.kernelDensity[selectedPeriod]) {
            const filteredData = data.kernelDensity[selectedPeriod]
                .filter(item => (
                    portfolioFilters.sectors.includes(item.sector) &&
                    portfolioFilters.companies.includes(item.symbol)
                ));

            const labels = filteredData.map(item => ({ color: item.color, symbol: item.symbol, label: item.symbol }));
            const dist = filteredData.map(item => ({ color: item.color, symbol: item.symbol, kdeDist: item.kdeDist }));

            setColorLabels(labels);
            setKernelDist(dist);
        }
        setCorrelations(data.correlations[selectedPeriod]);


        const filteredTopTable = data.topTable[selectedPeriod].filter(item => (
            portfolioFilters.sectors.includes(item.sector) &&
            portfolioFilters.companies.includes(item.symbol)
        ));

        const filteredBottomTable = data.bottomTable[selectedPeriod].filter(item => (
            portfolioFilters.sectors.includes(item.sector) &&
            portfolioFilters.companies.includes(item.symbol)
        ));

        setTopTable(filteredTopTable);

        const uniqueItems = [];
        const symbolsForDate = new Set();

        filteredBottomTable.forEach(item => {
            const symbolDateKey = `${item.tableData[2]}_${item.symbol}`;

            if (!symbolsForDate.has(symbolDateKey)) {
                uniqueItems.push(item);
                symbolsForDate.add(symbolDateKey);
            }
        });

        setBottomTable(uniqueItems);
    };

    const getKernelShadowColor = (hex) => {
        hex = hex.replace(/^#/, '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `rgba(${r}, ${g}, ${b}, 0.4)`;
    };

    const getLineChartData = () => {
        if (!kernelDist) return;
        const datasets = [];

        kernelDist.forEach((item) => {
            let borderColor, backgroundColor;

            if (!selectedItem) {
                borderColor = backgroundColor = item.color;
                backgroundColor = getKernelShadowColor(item.color);
            } else {
                if (item.symbol === selectedItem) {
                    // If selectedItem is not null and matches item.symbol, use the item.color
                    borderColor = backgroundColor = item.color;
                } else {
                    // If selectedItem is not null and doesn't match item.symbol, convert hex to rgba with opacity 0.4
                    const hexColor = item.color;
                    const rgbaColor = hexToRGBA(hexColor, 0.4);

                    borderColor = rgbaColor;
                    backgroundColor = getKernelShadowColor(hexColor); // Use your existing function or logic here
                }
            }

            datasets.push({
                label: item.symbol,
                data: item.kdeDist,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 3,
                fill: true,
            });
        });

        return {
            labels: kernelDist.map((item) => item.symbol),
            datasets: datasets,
        };
    };

    const descriptiveKernelTitle = (
        <>
            <div
            // className='descriptive-correlations-table-title position-relative'
            // onMouseEnter={() => setShowInfoPopupKDE(true)}
            // onMouseLeave={() => setShowInfoPopupKDE(false)}
            >
                <h4 className='portfolio-chart-title'>Kernel Density Estimation Distributions</h4>
                {/* {showInfoPopupKDE && <InfoPopup type='kde-distribution' />} */}
            </div>
        </>
    );

    const descriptiveKernelContent = (
        <>
            <LineChartNoLines chartData={getLineChartData()} type='percent' />
        </>
    );

    const getCorrelationColor = (correlation) => {
        let correlationColor;
        if (correlation === undefined || correlation === null) {
            return correlationColor;
        }
        if (+correlation >= 0) {
            correlationColor = '#5c9ead';
        } else if (+correlation < 0) {
            correlationColor = '#e39774';
        }
        if (+correlation !== 1.0) {
            const correlationString = correlation.toString();
            const correlationDecimal = correlationString.split('.')[1];
            correlationColor += correlationDecimal;
        }
        return correlationColor;
    };

    const correlationTitle = (
        <>
            <div
            // className='descriptive-correlations-table-title position-relative'
            // onMouseEnter={() => setShowInfoPopupCorrelation(true)}
            // onMouseLeave={() => setShowInfoPopupCorrelation(false)}
            >
                <h4 className='portfolio-chart-title'>Correlations</h4>
                {/* {showInfoPopupCorrelation && <InfoPopup type='correlation' />} */}
            </div>
        </>
    );

    const correlationsContent = (
        <>
            {
                correlations &&
                <div className="findatasheets-table desc-correlations-media flex column justify-center" style={{ height: '100%' }}>
                    <div className="flex">
                        {
                            correlations.position.map((pos, idx) => (
                                <React.Fragment key={pos.symbol}>
                                    {
                                        idx === 0 && <div className="descriptive-cell"></div>
                                    }
                                    <div className="port-desc-corr-header header cell descriptive-cell descriptive-header">
                                        {
                                            pos.symbol ? (
                                                <Link to={`/company/${pos.symbol}`}>{pos.symbol}</Link>
                                            ) : (
                                                <Link to={`/sector/${pos.sector}`}>{pos.sector}</Link>
                                            )
                                        }
                                    </div>
                                </React.Fragment>
                            ))
                        }
                    </div>
                    {
                        correlations.data.map((rowData, rowIndex) => (
                            <div key={rowIndex} className="port-desc-corr-container flex">
                                <div className="port-desc-corr-header header cell descriptive-cell descriptive-header flex">
                                    {
                                        correlations.position[rowIndex].symbol ? (
                                            <Link to={`/company/${correlations.position[rowIndex].symbol}`}>
                                                {correlations.position[rowIndex].symbol}
                                            </Link>
                                        ) : (
                                            <Link to={`/sector/${correlations.position[rowIndex].sector}`}>
                                                {correlations.position[rowIndex].sector}
                                            </Link>
                                        )
                                    }
                                </div>
                                {
                                    correlations.position.map((pos) => (
                                        <div key={pos.symbol} className="cell descriptive-cell" style={{ backgroundColor: getCorrelationColor(rowData[pos.symbol]) }}>
                                            {rowData[pos.symbol]}
                                        </div>
                                    ))
                                }
                            </div>
                        ))}
                </div>
            }
        </>
    );

    const topTableEl = (
        <div
            className="findatasheets-table-container portfolio-top-table-media crypto-table card-container portfolio-desc-item desc-table-media"
            style={{ marginBottom: '0px', height: '100%' }}>
            <h4>Statistical Summary</h4>
            <div className="findatasheets-table-header flex space-between">
                <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Symbol</div>
                <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Avg. Daily Return</div>
                <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Avg. Daily Median</div>
                <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Daily Return Std. Dev.</div>
                {!topTableSize1 && (
                    <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Daily Return Range</div>
                )}
            </div>
            {topTable &&
                topTable.map((tableItem, idx) => (
                    <div className={`findatasheets-table-row flex space-between ${idx % 2 === 0 ? '' : 'odd-row'}`} key={idx}>
                        {tableItem.tableData.slice(0, topTableSize1 ? -1 : undefined).flatMap((cellValue, cellIdx) => (
                            <div className="findatasheets-table-cell portfolio-desc-title-item flex center-center" key={cellIdx}>
                                {cellValue}
                            </div>
                        ))}
                    </div>
                ))}
        </div>
    );

    const bottomTableEl = (
        <div className="findatasheets-table-container crypto-table card-container portfolio-desc-item desc-table-media">
            <h4>Unexpected Returns</h4>
            <div className="findatasheets-table-header flex space-between">
                <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Symbol</div>
                <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Daily Return</div>
                <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Date</div>
                {!topTableSize1 && (<div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Z-Score Method</div>)}
                {!topTableSize1 && (<div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">IQR Method</div>)}
                {!topTableSize1 && (<div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Local Outlier Factor</div>)}
                {!topTableSize1 && (<div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Kernel Density Estimation</div>)}
            </div>
            {
                bottomTable &&
                bottomTable.map((tableItem, idx) => (
                    <div className={`findatasheets-table-row flex space-between ${idx % 2 === 0 ? '' : 'odd-row'}`} key={idx}>
                        <div className="findatasheets-table-cell portfolio-desc-title-item flex center-center">
                            <Link to={`/co.tableDatampany/${tableItem.tableData[0]}`}>
                                {tableItem.tableData[0]}
                            </Link>
                        </div>
                        <div className="findatasheets-table-cell portfolio-desc-title-item flex center-center">{tableItem.tableData[1]}</div>
                        <div className="findatasheets-table-cell portfolio-desc-title-item flex center-center">{tableItem.tableData[2]}</div>
                        {!topTableSize1 && (<div className="findatasheets-table-cell portfolio-desc-title-item flex center-center">{tableItem.tableData[3].includes("zScore") ? '✔' : '✖'}</div>)}
                        {!topTableSize1 && (<div className="findatasheets-table-cell portfolio-desc-title-item flex center-center">{tableItem.tableData[3].includes("iqr") ? '✔' : '✖'}</div>)}
                        {!topTableSize1 && (<div className="findatasheets-table-cell portfolio-desc-title-item flex center-center">{tableItem.tableData[3].includes("localOutlierFactor") ? '✔' : '✖'}</div>)}
                        {!topTableSize1 && (<div className="findatasheets-table-cell portfolio-desc-title-item flex center-center">{tableItem.tableData[3].includes("kernelDensityEstimation") ? '✔' : '✖'}</div>)}
                    </div>
                ))}
        </div>
    );

    const onChangeSelectedItem = (item) => {
        setSelectedItem(item);
    };

    const BigScreen = () => {
        return <>
            {/* <div className="container-height main-content-container"> */}
            {/* style={{height: '400px'}} remove this */}
            <div className={`portfolio-container-top mb20 flex ${isMobile ? 'column' : ''}`} style={{ height: '400px' }}>
                {
                    colorLabels &&
                    <LabelColorContainer
                        labels={colorLabels}
                        type={'company'}
                        display={'column'}
                        selectedItem={selectedItem}
                        onChangeSelectedItem={onChangeSelectedItem}
                    />
                }
                {
                    kernelDist &&
                    <CardContainer
                        title={descriptiveKernelTitle}
                        innerContent={descriptiveKernelContent}
                        className={'kde-chart flex column'}
                        infoModalType={'kernelDensity'}
                    />
                }
            </div>

            {/* <div className={`portfolio-container-bottom mb20 port-desc-container-bottom flex ${isMobile ? 'column' : ''}`}>
                {topTableEl}
                {
                    correlations &&
                    <CardContainer
                        title={correlationTitle}
                        innerContent={correlationsContent}
                        className={'descriptive-correlations-chart flex column'}
                        infoModalType={'correlation'}
                    />
                }
            </div> */}




            <div className={`portfolio-container-bottom mb20 port-desc-container-bottom flex ${isMobile ? 'column' : ''}`}>
                <div className="portfolio-content-wrapper" style={{ display: 'flex', width: '100%' }}>
                    <div style={{ width: portfolioFilters.period.value === 'fiveYear' ? '100%' : '65%' }}>
                        {topTableEl}
                    </div>
                    {correlations && (
                        <div style={{ width: '35%' }}>
                            <CardContainer
                                title={correlationTitle}
                                innerContent={correlationsContent}
                                className={'descriptive-correlations-chart flex column'}
                                infoModalType={'correlation'}
                            />
                        </div>
                    )}
                </div>
            </div>




            {/* </div> */}
            {bottomTableEl}
        </>
    };

    const SmallScreen = () => {
        return <>
            <div className="mb20">
                {
                    colorLabels &&
                    <LabelColorContainer
                        labels={colorLabels}
                        type={'company'}
                        display={'column'}
                        selectedItem={selectedItem}
                        onChangeSelectedItem={onChangeSelectedItem}
                    />
                }
                {
                    kernelDist &&
                    <CardContainer
                        title={descriptiveKernelTitle}
                        innerContent={descriptiveKernelContent}
                        className={'kde-chart flex column mb20'}
                    />
                }
                {
                    topTableEl
                }
                {
                    correlations &&
                    <CardContainer
                        title={correlationTitle}
                        innerContent={correlationsContent}
                        className={''}
                    />
                }
                {
                    <div style={{ margin: isMobileScreen ? '10px 0' : '20px 0' }}>
                        {bottomTableEl}
                    </div>
                }
            </div>
        </>
    };

    const handleChangePeriod = (newPeriod) => {
        const newFilters = { ...portfolioFilters };
        newFilters.period = newPeriod;
        handleChangePortfolioFilters(newFilters);
    };

    const handleChangeSector = (newSectors) => {
        if (newSectors.length === 0) return;
        handleChangePortfolioActiveSectorOptions(newSectors)
        const newSectorValues = newSectors.map(sector => sector.value);
        const newFilters = { ...portfolioFilters, sectors: newSectorValues };
        handleChangePortfolioFilters(newFilters);
    };

    const handleChangeCompany = (newCompany) => {
        if (newCompany.length === 0) return;
        handleChangePortfolioActiveCompanyOptions(newCompany);
        const newCompanyValues = newCompany.map(comapny => comapny.value);
        const newFilters = { ...portfolioFilters, companies: newCompanyValues };
        handleChangePortfolioFilters(newFilters);
    };

    const handleToggleOption = (option) => {
        if (option === 'guide') {
            history.push(`/portfolio`);
        } else {
            history.push(`/portfolio/${option}`);
        };
    };

    const getNavLocation = (label) => {
        return `/portfolio/${label}`;
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow | Portfolio Descriptive</title>
                <meta name="description" content="Analyze and manage your investment portfolio" />
                <link rel="canonical" href="https://www.portfollow.com/portfolio/descriptive" />
            </Helmet>
    
            <>
                {!loggedInUser || myPortfolio && !myPortfolio.portfolioData.length ? (
                    <EmptyPortfolio
                        loggedInUser={loggedInUser}
                        selectedPortfolio={mySelectedPortfolio}
                        myPortfolio={myPortfolio}
                        addStocks={addStocks}
                    />
                ) : (
                    <>
                        <div className="page-margin-top">
                            <ActivePageSelection
                                handleToggleOption={handleToggleOption}
                                pages={pages}
                                activePage={'descriptive'}
                                getNavLocation={getNavLocation}
                                guide={'/portfolio'}
                            />
                        </div>
    
                        {
                            portfolioFilters &&
                            portfolioActiveCompanyOptions &&
                            portfolioActiveSectorOptions &&
                            <PortfolioFilters
                                activePage={'descriptive'}
                                addStocks={addStocks}
                                addStock={addStock}
                                myPortfolio={myPortfolio}
                                mySelectedPortfolio={mySelectedPortfolio}
                                portfolio={portfolio}
                                handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                                loggedInUser={loggedInUser}
                                handleChangePeriod={handleChangePeriod}
                                portfolioFilters={portfolioFilters}
                                portfolioSectorOptions={portfolioSectorOptions}
                                portfolioActiveSectorOptions={portfolioActiveSectorOptions}
                                handleChangeSector={handleChangeSector}
                                portfolioCompanyOptions={portfolioCompanyOptions}
                                portfolioActiveCompanyOptions={portfolioActiveCompanyOptions}
                                handleChangeCompany={handleChangeCompany}
                            />
                        }
    
                        <div className="main-content-container">
                            {
                                myPortfolio &&
                                portfolioFilters &&
                                descData ? (
                                    isMobile ?
                                        <SmallScreen /> : <BigScreen />
                                ) : <LoadingSpinner />
                            }
                        </div>
    
                        <div className="card-container main-content-container" style={{ margin: '20px auto', width: '' }}>
                            <DescriptiveGuide />
                        </div>
                    </>
                )}
            </>
        </>
    );
    
}

export default PortfolioDescriptive;