import React, { useState, useEffect, useRef } from 'react';
import AnalyzePortfolioSlide from './section-getting-started/analyze-portfolio-slide';
import CalendarSlide from './section-getting-started/calendar-slide';
import CryptoSlide from './section-getting-started/crypto-slide';
import FinalSlide from './section-getting-started/findal-slide';
import GettingStartedSlide from './section-getting-started/getting-started-slide';
import InteractiveDemosSlide from './section-getting-started/interactive-demos-slide';
import MacroSlide from './section-getting-started/macro-slide';
import './tutorial.scss';
import PortfolioCompanies from './section-portfolio/portfolio-companies';
import PortfolioSectors from './section-portfolio/portfolio-sectors';
import PortfolioFundamental from './section-portfolio/portfolio-fundamental';
import PortfolioDescriptive from './section-portfolio/portfolio-descriptive';
import PortfolioSensitivity from './section-portfolio/portfolio-sensitivity';
import PortfolioSummarize from './section-portfolio/portfolio-summarize';
import MacroIntroClass from './section-macro/macro-intro-class';
import MacroInflationClass from './section-macro/macro-inflation-class';
import MacroCurrenciesClass from './section-macro/macro-currencies-class';
import MacroIndicesClass from './section-macro/macro-indices-class';
import MacroCommoditiesClass from './section-macro/macro-commodities-class';
import MacroSummarizeClass from './section-macro/macro-summarize-class';

const Tutorial = () => {

    const contentRef = useRef(null);

    const [activeSection, setActiveSection] = useState(0);
    const [activeClass, setActiveClass] = useState(0);

    const overviewClasses = [
        {
            classTitle: 'Getting Started!',
            class: <GettingStartedSlide />
        },
        {
            classTitle: 'Demo 1 - Copying Portfolio',
            class: <InteractiveDemosSlide />
        },

        {
            classTitle: 'Demo 2 - Analyzing Your Portfolio',
            class: <AnalyzePortfolioSlide />
        },
        {
            classTitle: 'Demo 3 - Exploring the Macro Page',
            class: <MacroSlide />
        },

        {
            classTitle: 'Demo 4 - Tracking Cryptocurrencies',
            class: <CryptoSlide />
        },

        {
            classTitle: ' Demo 5 - Using the Calendar',
            class: <CalendarSlide />
        },
        {
            classTitle: 'Summarize',
            class: <FinalSlide />
        }
    ];

    const portfolioClasses = [
        {
            classTitle: 'Companies',
            class: <PortfolioCompanies />
        },
        {
            classTitle: 'Sectors',
            class: <PortfolioSectors />
        },
        {
            classTitle: 'Fundamnetal',
            class: <PortfolioFundamental />
        },
        {
            classTitle: 'Descriptive',
            class: <PortfolioDescriptive />
        },
        {
            classTitle: 'Sensitivity',
            class: <PortfolioSensitivity />
        },
        {
            classTitle: 'Summarize',
            class: <PortfolioSummarize />
        },
    ];

    const MacroClasses = [
        {
            classTitle: 'Macro Introduction',
            class: <MacroIntroClass />
        },
        {
            classTitle: 'Inflation',
            class: <MacroInflationClass />
        },
        {
            classTitle: 'Exchange Rates',
            class: < MacroCurrenciesClass />
        },
        {
            classTitle: 'Indices',
            class: < MacroIndicesClass />
        },
        {
            classTitle: 'Commodities',
            class: <MacroCommoditiesClass />
        },
        {
            classTitle: 'Summarize',
            class: <MacroSummarizeClass />
        }
    ]

    const sections = [
        {
            sectionTitle: 'Overview',
            sectionClasses: overviewClasses
        },
        {
            sectionTitle: 'Portfolio',
            sectionClasses: portfolioClasses
        },
        {
            sectionTitle: 'Macro',
            sectionClasses: MacroClasses
        }
    ];


    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (contentRef.current) {
    //           const scrollPosition = window.scrollY;
    //           const classElements = contentRef.current.getElementsByClassName('tutorial-class-container');

    //           let newActiveSection = activeSection;
    //           let newActiveClass = activeClass;

    //           for (let i = 0; i < sections.length; i++) {
    //             const section = sections[i];
    //             const sectionClasses = section.sectionClasses;
    //             const lastClassIndex = sectionClasses.length - 1;

    //             for (let j = 0; j < sectionClasses.length; j++) {
    //               const element = classElements[j];
    //               const rect = element.getBoundingClientRect();

    //               if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
    //                 newActiveSection = i;
    //                 newActiveClass = j;
    //                 break;
    //               }
    //             }

    //             if (newActiveSection === 0 && newActiveClass === lastClassIndex && i < sections.length - 1) {
    //               // If the current class is the last in the section and we are in the first section, move to the next section
    //               newActiveSection = i + 1;
    //               newActiveClass = 0; // Move to the first class in the next section
    //             }
    //           }

    //           // Set the active section and class using useState setters
    //           setActiveSection(newActiveSection);
    //           setActiveClass(newActiveClass);
    //         }
    //       };

    //     // Attach the scroll event listener
    //     window.addEventListener('scroll', handleScroll);

    //     // Clean up the event listener when the component is unmounted
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    const handleSectionClick = (index) => {
        setActiveSection(index);
        setActiveClass(0); // Reset activeClass when a section is clicked
    };

    const handleClassClick = (index) => {
        setActiveClass(index);

        // Scroll to the clicked class
        if (contentRef.current) {
            const selectedClassElement = contentRef.current.querySelector(`.card-container:nth-child(${index + 1})`);
            if (selectedClassElement) {
                const offsetTop = selectedClassElement.offsetTop;
                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth',
                });
            }
        }
    };

    useEffect(() => {
        console.log('section', activeSection, 'class', activeClass);
    }, [activeSection])


    return (
        <div className="page-margin-top">
            <div className="tutorial-content-container main-content-container flex space-between">


                <div className="" style={{ height: '100%' }} ref={contentRef}>
                    {/* Render class content for all classes */}
                    {sections.map((section) =>
                        section.sectionClasses.map((classItem, classIndex) => (
                            <div
                                key={classIndex}
                                className={`tutorial-class-container mb20 card-container ${classIndex === activeClass ? 'active-class' : ''}`}
                                onClick={() => handleClassClick(classIndex)}
                            >
                                {classItem.class}
                            </div>
                        ))
                    )}
                </div>

            </div>
            {/* <div className="tutorial-sections-container flex column">
                {/* Map over sections to render section titles */}
            {sections.map((section, index) => (
                <div
                    key={index}
                    className={`${index === activeSection ? 'active-section' : ''}`}
                >
                    <div className="tutorial-section-item card-container"
                        onClick={() => handleSectionClick(index)}
                    >
                        <h2>{section.sectionTitle}</h2>
                    </div>
                    {/* Render classes only for the selected section */}
                    {index === activeSection &&
                        section.sectionClasses.map((classItem, classIndex) => (
                            <div
                                key={classIndex}
                                className={`tutorial-class-item card-container ${classIndex === activeClass ? 'active-tutorial-class-item' : ''}`}
                                onClick={() => handleClassClick(classIndex)}
                            >
                                {classItem.classTitle}
                            </div>
                        ))}
                </div>
            ))}
            {/* </div> */}
        </div>
    );
}

export default Tutorial;