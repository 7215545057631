import PortfolioCompaniesFilter from "./portfolio-companies-filter";
import PortfolioPeriodFilter from "./portfolio-period-filter";
import PortfolioSectorsFilter from "./portfolio-sectors-filter";
import PortfolioFundamentalFilter from './portfolio-fundamental-filter';
import PortfolioMenu from "../portfolio-menu/portfolio-menu";

const PortfolioFilters = ({
    activePage,
    periodFilters,
    companyFilters,
    sectorFilters,
    activePeriodFilter,
    onPeriodChange,
    activeSectors,
    activeCompanies,
    onChangeSectors,
    onChangeCompanies,
    setCompanyFilters,
    fullCompanyFilter,
    fundamentalFilter,
    handleToggleFundamentalFilter,

    addStock,
    addStocks,
    myPortfolio,
    selectedPortfolio,
    portfolio,
    handleChangeSelectedPortfolio,
    loggedInUser
}) => {

    return (
        <div className="dropdown-select-filter-container flex main-content-container">

            <PortfolioMenu
                addStock={addStock}
                addStocks={addStocks}
                myPortfolio={myPortfolio}
                selectedPortfolio={selectedPortfolio}
                portfolio={portfolio}
                handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                loggedInUser={loggedInUser}
            />

            <div className="flex wrap center-center flex-grow1">
                    {
                        activePage !== 'fundamental' &&
                        activePage !== 'efficiency' &&
                        <PortfolioPeriodFilter
                            currFilter={activePeriodFilter}
                            onChangeFilter={onPeriodChange}
                            optionsToDisplay={periodFilters}
                        />
                    }
                    {
                        activePage !== 'sensitivity' &&
                        <PortfolioSectorsFilter
                            activePage={activePage}
                            sectorFilters={sectorFilters}
                            activeSectors={activeSectors}
                            onChangeFilter={onChangeSectors}
                            onChangeCompanies={onChangeCompanies}
                            setCompanyFilters={setCompanyFilters}
                            companyFilters={companyFilters}
                            fullCompanyFilter={fullCompanyFilter}
                        />
                    }
                    {
                        activePage !== 'sectors' &&
                        activePage !== 'sensitivity' &&
                        <PortfolioCompaniesFilter
                            onChangeCompanies={onChangeCompanies}
                            companyFilters={companyFilters}
                            activeCompanies={activeCompanies}
                        />
                    }

                    {
                        activePage === 'fundamental' &&
                        <PortfolioFundamentalFilter
                            fundamentalFilter={fundamentalFilter}
                            handleToggleFundamentalFilter={handleToggleFundamentalFilter}
                        />
                    }
                </div>
        </div>
    );
}

export default PortfolioFilters;