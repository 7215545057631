import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { setGlobalIsLoading } from "../../../store/loading/loading-actions";
import { getPortfolioPage } from "../../../shared/actions/portfolio-actions";
import ActivePageSelection from "../../../shared/active-page/active-page";
import LoadingSpinner from "../../../shared/loading-spinner/loading-spinner";
import PortfolioFilters from "../portfolio-filters/portfolio-filters";
import PortfolioFundamentalFilter from "../portfolio-filters/portfolio-fundamental-filter";
import PortfolioMenu from "../portfolio-menu/portfolio-menu";
import PortfolioSectorsFilter from "../portfolio-filters/portfolio-sectors-filter";
import PortfolioCompaniesFilter from "../portfolio-filters/portfolio-companies-filter";
import CardContainer from "../../../cmps/card-container/card-container";
import PortfolioFundamentalLineChart from "../selected-portfolio.jsx/portfolio-fundamental-linechart";
import LabelColorContainer from "../../../shared/label-color-container/label-color-container";
import { FundamentalGuide } from "../../../cmps/page-guide";
import BarChart from "../../../shared/charts/bar-chart/bar-chart";

const PortfolioFundamental = ({
    loggedInUser,
    mySelectedPortfolio,
    portfolio,
    portfolioFundamentalData,
    handleChangePortfolioFundamentalData,
    addStock,
    addStocks,
    handleChangeSelectedPortfolio,
    showTutorial
}) => {

    const loadingSelector = useSelector((state) => state.loading.isLoading);
    const dispatch = useDispatch();
    const history = useHistory();

    const [fundamentalData, setFundamentalData] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const pages = ['companies', 'sectors', 'fundamental', 'descriptive', 'sensitivity', 'guide'];

    const [activePage, setActivePage] = useState('coverage');
    const [fundamentalOptions, setFundamentalOptions] = useState(null);


    const [lineChartsToDisplay, setLineChartsToDisplay] = useState([]);
    const [barChartsToDisplay, setBarChartsToDisplay] = useState([]);

    const [sectorsOptions, setSectorsOptions] = useState([]);
    const [companiesOptions, setCompaniesOptions] = useState([]);
    const [selectedCompaniesOptions, setSelectedCompaniesOptions] = useState([]);

    const [barchartsDataToDisplay, setBarchartsDataToDisplay] = useState(null);

    const getPageFilters = (data) => {

        const newFilter = {
            page: "overTime",
            period: "oneMonth",
            companies: {},
            selectedSector: ''
        };

        data.forEach(company => {
            const { symbol, color, company: companyName, sector, fundamentals } = company;
            if (!newFilter.companies[sector]) {
                newFilter.companies[sector] = [];
            }
            newFilter.companies[sector].push({ symbol, color, companyName, fundamentals });
        });
        let maxCompanyCount = 0;
        let selectedSector = '';
        for (const sector in newFilter.companies) {
            if (newFilter.companies[sector].length > maxCompanyCount) {
                maxCompanyCount = newFilter.companies[sector].length;
                selectedSector = sector;
            }
        }
        newFilter.selectedSector = selectedSector;
        setSelectedFilter(newFilter);
    };

    useEffect(() => {
        if (Object.keys(portfolio).length === 0) return;

        const fetchData = async () => {
            try {
                dispatch(setGlobalIsLoading(true));
                if (portfolioFundamentalData) {
                    if (!fundamentalData) setFundamentalData(portfolioFundamentalData);
                    getPageFilters(portfolioFundamentalData);
                    // getLoadedPageFilters(portfolioSectorData.barcharts);
                } else {
                    const res = await getPortfolioPage('fundamental', portfolio[mySelectedPortfolio].portfolioData);
                    if (res) {
                        setFundamentalData(res);
                        getPageFilters(res);
                        handleChangePortfolioFundamentalData(res);
                    };
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                dispatch(setGlobalIsLoading(false));
            }
        };
        fetchData();
    }, [portfolio, mySelectedPortfolio]);

    useEffect(() => {
        if (!fundamentalData) return;
        const formattedFundamentals = Object.keys(fundamentalData[0].fundamentals).map((key) => {
            const formattedKey = key
                .replace(/([A-Z])/g, ' $1')
                .replace(/^./, (str) => str.toUpperCase());
            return {
                label: formattedKey,
                value: key,
            };
        });
        setActivePage(formattedFundamentals[0].value);
        setFundamentalOptions(formattedFundamentals);
    }, [fundamentalData]);


    const handleToggleOption = (option) => {
        if (option === 'guide') {
            history.push(`/portfolio`);
        } else {
            history.push(`/portfolio/${option}`);
        };
    };

    const getNavLocation = (label) => {
        return `/portfolio/${label}`;
    };

    useEffect(() => {
        if (!selectedFilter) return;

        const lineChartData = [];
        const sectorOptions = [];
        const symbolOptions = new Set(); // Using a Set to ensure unique symbols

        Object.keys(selectedFilter.companies).forEach(sector => {
            if (!sectorOptions.some(option => option.value === sector)) {
                sectorOptions.push({ label: sector, value: sector });
            }
        });

        const selectedCompanies = selectedFilter.companies[selectedFilter.selectedSector];
        if (!selectedCompanies) return;

        selectedCompanies.forEach((item) => {
            const fundamentalsData = item.fundamentals && item.fundamentals[activePage] && item.fundamentals[activePage][selectedFilter.page];
            if (!fundamentalsData || typeof fundamentalsData !== 'object') return;

            Object.keys(fundamentalsData).forEach((chartKey) => {
                const existingChartIndex = lineChartData.findIndex((chart) => chart.label === chartKey);
                const newData = fundamentalsData[chartKey].map((dataPoint) => ({ x: dataPoint.x, y: parseFloat(dataPoint.y) }));
                const newDataset = {
                    label: item.symbol,
                    data: newData,
                    backgroundColor: item.color,
                    borderColor: item.color,
                    borderWidth: 3,
                    fill: false,
                };

                // Populate symbolOptions with symbols from the selected sector
                if (!symbolOptions.has(item.symbol)) {
                    symbolOptions.add(item.symbol);
                }

                if (existingChartIndex !== -1) {
                    lineChartData[existingChartIndex].datasets.push(newDataset);
                } else {
                    const newChart = {
                        label: chartKey,
                        datasets: [newDataset],
                    };
                    lineChartData.push(newChart);
                }
            });
        });

        setLineChartsToDisplay(lineChartData);
        const barChartData = [];
        selectedCompanies.forEach((item) => {
            const chartKey = item.fundamentals && item.fundamentals[activePage] && item.fundamentals[activePage].sectorial;
            if (!chartKey) return;
            Object.entries(chartKey).forEach(([label, chartData]) => {
                const newData = chartData.map((dataPoint) => ({
                    x: dataPoint.symbol,
                    y: parseFloat(dataPoint.y),
                }));
                const chartColor = item.color;
                const backgroundColors = Array.from({ length: newData.length }, () => chartColor);
                const existingChartIndex = barChartData.findIndex((chart) => chart.datasets[0].label === label);
                if (existingChartIndex !== -1) {
                    barChartData[existingChartIndex].datasets[0].data.push(...newData.map((dataPoint) => parseFloat(dataPoint.y)));
                    barChartData[existingChartIndex].datasets[0].backgroundColor.push(...backgroundColors);
                    barChartData[existingChartIndex].hover.push(...newData.map((dataPoint) => [dataPoint.x, item.companyName]));
                    barChartData[existingChartIndex].labels.push(...newData.map((dataPoint) => dataPoint.x));
                } else {
                    const chartItem = {
                        datasets: [{
                            label: label,
                            data: newData.map((dataPoint) => parseFloat(dataPoint.y)),
                            backgroundColor: backgroundColors,
                            borderColor: backgroundColors,
                            borderWidth: 3,
                            fill: false,
                        }],
                        hover: newData.map((dataPoint) => [dataPoint.x, item.company]),
                        labels: newData.map((dataPoint) => dataPoint.x),
                    };
                    barChartData.push(chartItem);
                }
            });
        });

        const sortedBarChartData = barChartData.map((chartItem) => {
            const sortIndex = chartItem.datasets[0].data.map((_, i) => i);
            sortIndex.sort((a, b) => chartItem.datasets[0].data[b] - chartItem.datasets[0].data[a]);
            const sortedChartData = {
                datasets: [{
                    label: chartItem.datasets[0].label,
                    data: sortIndex.map((i) => chartItem.datasets[0].data[i]),
                    backgroundColor: sortIndex.map((i) => chartItem.datasets[0].backgroundColor[i]),
                    borderColor: sortIndex.map((i) => chartItem.datasets[0].borderColor[i]),
                    borderWidth: chartItem.datasets[0].borderWidth,
                    fill: chartItem.datasets[0].fill,
                }],
                hover: sortIndex.map((i) => chartItem.hover[i]),
                labels: sortIndex.map((i) => chartItem.labels[i]),
            };
            return sortedChartData;
        });

        setSectorsOptions(sectorOptions);
        setCompaniesOptions([...symbolOptions].map(symbol => ({ label: symbol, value: symbol })));
        setSelectedCompaniesOptions([...symbolOptions].map(symbol => ({ label: symbol, value: symbol })))
        setBarChartsToDisplay(sortedBarChartData);
    }, [selectedFilter, activePage]);

    const handleToggleFundamentalFilter = (newPage) => {
        setSelectedFilter(prevFilter => ({
            ...prevFilter,
            page: newPage
        }));
    };

    const handleChangeSector = (newSector) => {
        if (!newSector[0]) return;
        setSelectedFilter(prevFilter => ({
            ...prevFilter,
            selectedSector: newSector[0].label
        }));
    };

    const handleChangeCompanies = (filteredCompanies) => {
        setSelectedCompaniesOptions(filteredCompanies);
    };

    const getSelectedFilterArray = () => {
        const selectedSector = selectedFilter.selectedSector;
        return [{ label: selectedSector, value: selectedSector }];
    };

    const lineCharts = (data) => {
        return <PortfolioFundamentalLineChart chartData={data} onChangeSelectedItem={() => { }} />
    };

    const barCharts = (data) => {
        return <BarChart chartData={data} type={'company'} onChangeSelectedItem={() => { }} />
    };

    const formatChartTitle = (str) => {
        return str.replace(/([A-Z])/g, ' $1')
            .replace(/^./, str => str.toUpperCase());
    };


    useEffect(() => {
        const filteredBarCharts = barChartsToDisplay.map((chart) => {
            const filteredDatasets = [];
            const filteredHover = [];
            const filteredLabels = [];

            chart.datasets.forEach((dataset, datasetIndex) => {
                const filteredData = [];
                const filteredBackgroundColor = [];
                const filteredBorderColor = [];
                dataset.data.forEach((dataPoint, index) => {
                    const symbolIndex = selectedCompaniesOptions.findIndex((company) => company.label === chart.labels[index]);
                    if (symbolIndex !== -1 && dataPoint !== 0) {
                        filteredData.push(dataPoint);
                        filteredHover.push(chart.hover[index]);
                        filteredLabels.push(chart.labels[index]);
                        filteredBackgroundColor.push(dataset.backgroundColor[index]);
                        filteredBorderColor.push(dataset.borderColor[index]);
                    }
                });
                if (filteredData.length > 0) {
                    filteredDatasets.push({
                        label: dataset.label,
                        data: filteredData,
                        backgroundColor: filteredBackgroundColor,
                        borderColor: filteredBorderColor,
                        borderWidth: dataset.borderWidth,
                        fill: dataset.fill,
                    });
                }
            });

            return {
                datasets: filteredDatasets,
                hover: filteredHover,
                labels: filteredLabels,
            };
        }).filter(chart => chart.datasets.length > 0);

        setBarchartsDataToDisplay(filteredBarCharts);
    }, [barChartsToDisplay, selectedCompaniesOptions]);

    const handleTogglePage = (option) => {
        setActivePage((prevOption) => {
            return option;
        });
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow | Portfolio Fundamental</title>
                <meta name="description" content="Analyze and manage your investment portfolio" />
                <link rel="canonical" href="https://www.portfollow.com/portfolio/fundamental" />
            </Helmet>

            <div className="page-margin-top">
                <ActivePageSelection
                    handleToggleOption={handleToggleOption}
                    pages={pages}
                    activePage={'fundamental'}
                    getNavLocation={getNavLocation}
                    guide={'/portfolio'}
                />
            </div>

            {
                (
                    selectedFilter &&
                    loadingSelector
                ) ? (
                    <LoadingSpinner />
                ) : (
                    <div className="main-content-container">

                        {
                            selectedFilter &&
                            <div className="dropdown-select-filter-container flex space-between">
                                <PortfolioMenu
                                    addStock={addStock}
                                    addStocks={addStocks}
                                    myPortfolio={portfolio[mySelectedPortfolio]}
                                    selectedPortfolio={mySelectedPortfolio}
                                    portfolio={portfolio}
                                    handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                                    loggedInUser={loggedInUser}
                                />
                                <PortfolioSectorsFilter
                                    activePage={'fundamental'}
                                    sectorFilters={sectorsOptions}
                                    activeSectors={getSelectedFilterArray()}
                                    onChangeFilter={handleChangeSector}
                                    onChangeCompanies={handleChangeCompanies}
                                    setCompanyFilters={setCompaniesOptions}
                                    companyFilters={companiesOptions}
                                    fullCompanyFilter={companiesOptions}
                                />
                                <PortfolioCompaniesFilter
                                    onChangeCompanies={handleChangeCompanies}
                                    companyFilters={companiesOptions}
                                    activeCompanies={selectedCompaniesOptions}
                                />
                                <PortfolioFundamentalFilter
                                    fundamentalFilter={selectedFilter.page}
                                    handleToggleFundamentalFilter={handleToggleFundamentalFilter}
                                />
                            </div>
                        }

                        {
                            selectedFilter &&
                                barchartsDataToDisplay &&
                                lineChartsToDisplay ? (

                                <div>


                                    <div className="fundamental-page-container flex center-center wrap">
                                        {
                                            fundamentalOptions &&
                                            fundamentalOptions.map((option) => {
                                                return <div key={option.value} className={`portfolio-toggle btn selected-option ${activePage === option.value ? 'selected' : ''}`} onClick={() => handleTogglePage(option.value)}>{option.label}</div>
                                            })
                                        }
                                    </div>

                                    {
                                        selectedFilter.companies[selectedFilter.selectedSector]?.filter(company =>
                                            selectedCompaniesOptions.some(option => option.label === company.symbol)
                                        ).length > 0 &&
                                        <LabelColorContainer
                                            labels={
                                                selectedFilter.companies[selectedFilter.selectedSector]?.filter(company =>
                                                    selectedCompaniesOptions.some(option => option.label === company.symbol)
                                                )
                                            }
                                            height={''}
                                            type={'company'}
                                            display={'row'}
                                        />
                                    }

                                    <div className="flex wrap">
                                        {
                                            selectedFilter.page === 'overTime' &&
                                            lineChartsToDisplay.map((currentItem, index) => {
                                                const filteredDatasets = currentItem.datasets.filter(dataset =>
                                                    selectedCompaniesOptions.some(company =>
                                                        dataset.label === company.label
                                                    )
                                                );

                                                if (filteredDatasets.length > 0) {
                                                    return (
                                                        <CardContainer
                                                            title={formatChartTitle(currentItem.label)}
                                                            innerContent={lineCharts({ ...currentItem, datasets: filteredDatasets })}
                                                            className={'portfolio-fundamental-chart-wrapper portolio-fundamental-container chart-wrapper'}
                                                            key={index}
                                                            infoModalType={currentItem.label}
                                                        />
                                                    );
                                                }
                                                return null;
                                            })
                                        }


                                        {

                                            selectedFilter.page === 'sectorial' &&
                                            barchartsDataToDisplay.map((currentItem, index) => (
                                                <CardContainer
                                                    title={formatChartTitle(currentItem.datasets[0].label)}
                                                    innerContent={barCharts(currentItem)}
                                                    className={'portolio-fundamental-container port-fund-sect-chart-wrapper port-fund-barcharts'}
                                                    key={index}
                                                    infoModalType={currentItem.datasets[0].label}
                                                />
                                            ))
                                        }

                                    </div>

                                </div>) : <LoadingSpinner />
                        }



                        <div className="card-container main-content-container" style={{ width: '100%', marginBottom: '20px' }}>
                            <FundamentalGuide />
                        </div>

                    </div>
                )}
        </>
    );
}

export default PortfolioFundamental;