import React from 'react';
import { formatNumberToDollarTableString } from '../../../../shared/global-color-functions';
import './selected-table.scss';

const SelectedTable = ({ tableData, fixStr, activeOption }) => {

    const formatCellValue = (cell) => {
        if (isNaN(cell)) return cell;
        cell = cell.endsWith('.0') ? cell.slice(0, -2) : cell;
        if (+cell && activeOption !== 'ratios') {
            return formatNumberToDollarTableString(+cell);
        } else {
            return Number(cell).toLocaleString();
        }
    };

    return (
        <div className="selected-table-container">
            {tableData.map((row, rowIndex) => {
                if (rowIndex === 0) {
                    return (
                        <div className="financial-statements-table-row" key={rowIndex}>
                            {row.map((cell, cellIndex) => {
                                let cellClassName = 'financial-statements-table-cell financial-statements-table-cell-subtitle';
                                return (
                                    <div key={cellIndex} className={cellClassName}></div>
                                );
                            })}
                        </div>
                    );
                } else {
                    // Render the remaining rows
                    return (
                        <div className={
                            `financial-statements-table-row flex
                    ${rowIndex % 2 === 1 ? '' : 'financial-statements-table-row-odd'}
                     `}
                            key={rowIndex}>
                            {row.map((cell, cellIndex) => {
                                let cellClassName = 'financial-statements-table-cell';

                                if (rowIndex === 1 && typeof cell === 'object') {
                                    cellClassName += ' financial-statements-table-cell-subtitle';
                                    return (
                                        <div
                                            key={cellIndex}
                                            className={cellClassName}
                                            style={{
                                                paddingLeft: `${20 * Number(cell.tab)}px`,
                                                textDecoration: cell.tab === '1' ? 'underline' : 'none'
                                            }}
                                        >
                                        </div>
                                    );
                                } else if (typeof cell === 'object') {
                                    cellClassName += ' financial-statements-table-cell-subtitle';
                                    return (
                                        <div
                                            key={cellIndex}
                                            className={cellClassName}
                                            style={{
                                                paddingLeft: `${20 * Number(cell.tab)}px`,
                                                textDecoration: cell.tab === '1' ? 'underline' : 'none',
                                                fontWeight: cell.name === 'Difference' ? '600' : '500'
                                            }}
                                        >
                                            {fixStr(cell.name)}
                                        </div>
                                    );
                                } else {
                                    cellClassName += ' financial-statements-table-cell-item';
                                }

                                if (
                                    rowIndex !== 0 &&
                                    !cellClassName.includes('financial-statements-table-cell-item')
                                ) {
                                    cellClassName += ' financial-statements-table-cell-align';
                                }
                                return (
                                    <div key={cellIndex} className={cellClassName}>
                                        {/* {cell} */}
                                        <div
                                            style={tableData[rowIndex][0].name === 'Difference' && cell !== '-' ? { color: 'red' } : {}}>
                                            {formatCellValue(cell)}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default SelectedTable;