import React, { useState, useRef, useCallback, useEffect } from "react";
import { setLikeCommentCopyShare } from "../../../shared/actions/homepage-actions";
import { addCommentIcon } from "../../../shared/icons/icons";

const FeedPostModalCommentTextarea = ({
    postId,
    onPostComment,
}) => {

    // useEffect(() => {
    //     setLikeCommentCopyShare()
    // }, [])
    

    const [commentText, setCommentText] = useState('');
    const commentRef = useRef(null);

    const handleTextAreaChange = (ev) => {
        setCommentText(ev.target.value);
    };

    const TextInput = useCallback(({ ref, text, onChange }) => {
        return <textarea
            className='post-modal-mobile-textarea'
            placeholder='Add comment'
            ref={ref}
            defaultValue={text}
            onChange={onChange}
        />;
    }, []);

    const handlePostComment = (postId, commentText) => {
        onPostComment(postId, commentText);
        setCommentText('');
    };

    return (
        <>
            <TextInput ref={commentRef} text={commentText} onChange={handleTextAreaChange} />
            <div className='post-modal-add-comment-icon-container'>
                <div
                    className='post-modal-add-comment-icon flex flex-end'
                    onClick={() => handlePostComment(postId, commentText)}
                >
                    {addCommentIcon}
                </div>

            </div>
        </>

    );
}

export default FeedPostModalCommentTextarea;