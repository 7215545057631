import { useState } from "react";
import { useEffect } from "react";
import ProfileSelectedPortfolioPiecharts from "./profile-selected-portfolio-piecharts";
import ProfileSelectedPortfolioScores from "./profile-selected-portfolio-scores";
import ProfilePortfolioNames from "./profile-selected-protfolio-names";
import { useMediaQuery } from 'react-responsive';

const ProfileSelectedPortfolioContainer = ({
    portfolioNames,
    selectedPortfolio,
    handleChangeSelectedPortfolio,
    portfolioScoresData,
    portfolioPieChartsData,
    isMyProfile,
    mySelectedPortfolio,
    myPortfolio,
    visitedProfilePortfolioOrders,
    handleCopyPortfolio,
    loggedInUser,
    handleChangeMySelectedPortfolio,
    portfolioWorthSum,
    handleChangePortfolioWorthSum
}) => {

    const isMediumScreen = useMediaQuery({ maxWidth: 900 });
    const isTablet = useMediaQuery({ maxWidth: 1000 });

    const [selectedScores, setSelectedScores] = useState(null);
    const [selectedPieCharts, setSelectedPieCharts] = useState(null);
    // const [portfolioWorthSum, setPortfolioWorthSum] = useState(0);

    useEffect(() => {
        const selectedPortfolioScores = portfolioScoresData.find(item => item.portfolioKey === selectedPortfolio);
        const selectedPortfolioPieCharts = portfolioPieChartsData.find(item => item.portfolioKey === selectedPortfolio);
        if (selectedPortfolioScores) {
            if (!selectedPortfolioScores.scores) {
                selectedPortfolioScores.scores = {
                    awarness: 0,
                    harmony: 0,
                    resilience: 0,
                    hierarchy: 0,
                    precision: 0
                }
            }
            setSelectedScores(selectedPortfolioScores);
        } else {
            const emptyScores = {
                portfolioKey: selectedPortfolio,
                portfolioName: selectedPortfolio,
                scores: {
                    awarness: 0,
                    harmony: 0,
                    resilience: 0,
                    hierarchy: 0,
                    precision: 0
                }
            };
            setSelectedScores(emptyScores);
        };

        if (selectedPortfolioPieCharts) {
            setSelectedPieCharts(selectedPortfolioPieCharts);
        } else {
            setSelectedPieCharts(null);
        };

    }, [selectedPortfolio, portfolioScoresData, portfolioPieChartsData]);

    useEffect(() => {
        
        const oldestBuyDateInfo = {};
        let profilePortfolioOrders;
        if (isMyProfile) {
            profilePortfolioOrders = myPortfolio;
        } else {
            profilePortfolioOrders = visitedProfilePortfolioOrders;
        }
        for (const portfolioKey in profilePortfolioOrders) {
            let ordersList;
            if (isMyProfile) {
                ordersList = profilePortfolioOrders[portfolioKey].portfolioData;
            } else {
                ordersList = profilePortfolioOrders[portfolioKey].ordersList;
            };
            if (!ordersList.length) return;
            const oldestOrder = ordersList.reduce((oldest, order) => {
                const orderDate = new Date(order.buyDate);
                if (!oldest || orderDate < oldest.date) {
                    return { date: orderDate, order };
                }
                return oldest;
            }, null);
            const totalAmountPrice = ordersList.reduce((sum, order) => {
                const amount = parseFloat(order.amount);
                const price = parseFloat(order.price);
                return sum + (amount * price);
            }, 0);

            const today = new Date();
            const daysAgo = Math.floor((today - oldestOrder.date) / (1000 * 60 * 60 * 24));
            oldestBuyDateInfo[portfolioKey] = {
                daysAgo: daysAgo,
                totalAmountPrice: totalAmountPrice
            };
        };
        portfolioPieChartsData.forEach((portfolioItem) => {
            const { portfolioKey, sectorsPie } = portfolioItem;
            const sumPieChart = sectorsPie.reduce((sum, sector) => {
                return sum + parseFloat(sector.pieChart);
            }, 0);
            if (oldestBuyDateInfo[portfolioKey]) {
                oldestBuyDateInfo[portfolioKey].sumPieChart = sumPieChart;
                oldestBuyDateInfo[portfolioKey].returnValue = (((sumPieChart / oldestBuyDateInfo[portfolioKey].totalAmountPrice) - 1) * 100).toFixed(2);
            };
        });
        handleChangePortfolioWorthSum(oldestBuyDateInfo);
    }, [visitedProfilePortfolioOrders, myPortfolio, selectedPortfolio]);

    // const handleChangePortfolioWorthSum = (sum) => {
    //     setPortfolioWorthSum(sum);
    // };

    const [companiesSumData, setCompaniesSumData] = useState(null);
    const handleSetCompaniesSumData = (sum) => {
        setCompaniesSumData(sum);
    };

    return (
        <>
            {/* {
                portfolioNames &&
                <ProfilePortfolioBtns
                    portfolioNames={portfolioNames}
                    selectedPortfolio={selectedPortfolio}
                    handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                    isProfilePage={true}
                />
            }
             */}

            {/* {
                portfolioNames &&
                <ProfilePortfolioNames
                    portfolioNames={portfolioNames}
                    selectedPortfolio={selectedPortfolio}
                    handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                    portfolioWorthSum={portfolioWorthSum}
                />
            } */}

            {/* <div className="flex column">
                {
                    portfolioScoresData &&
                    selectedScores &&
                    < ProfileSelectedPortfolioScores
                        portfolioScoresData={selectedScores}
                        mySelectedPortfolio={mySelectedPortfolio}
                        myPortfolio={myPortfolio}
                        visitedProfilePortfolioOrders={visitedProfilePortfolioOrders}
                        selectedPortfolio={selectedPortfolio}
                    />
                }
                {
                    <ProfileSelectedPortfolioPiecharts
                        selectedPieCharts={selectedPieCharts}
                        isMyProfile={isMyProfile}
                    />
                }
            </div> */}

            <div className={`flex space-between ${isTablet ? ' column column-reverse align-center' : ''}`}
            // style={{ margin: isMediumScreen ? '0' : '0 20%' }}>
            // style={{margin: }}
            >
                {
                    <ProfileSelectedPortfolioPiecharts
                        selectedPieCharts={selectedPieCharts}
                        isMyProfile={isMyProfile}
                        handleSetCompaniesSumData={handleSetCompaniesSumData}
                        selectedPortfolio={selectedPortfolio}
                    />
                }
                {
                    portfolioScoresData &&
                    selectedScores &&
                    <ProfileSelectedPortfolioScores
                        selectedPieCharts={selectedPieCharts}

                        portfolioScoresData={selectedScores}
                        mySelectedPortfolio={mySelectedPortfolio}
                        myPortfolio={myPortfolio}
                        visitedProfilePortfolioOrders={visitedProfilePortfolioOrders}
                        selectedPortfolio={selectedPortfolio}
                        companiesSumData={companiesSumData}
                        portfolioWorthSum={portfolioWorthSum}
                        isTablet={isTablet}

                        // ordersList={visitedProfilePortfolioOrders[selectedPortfolio].ordersList}
                        ordersList={(visitedProfilePortfolioOrders[selectedPortfolio] && visitedProfilePortfolioOrders[selectedPortfolio].ordersList) || []}
                        handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                        handleCopyPortfolio={handleCopyPortfolio}
                        loggedInUser={loggedInUser}
                        handleChangeMySelectedPortfolio={handleChangeMySelectedPortfolio}
                    />
                }

            </div>
        </>
    );
}

export default ProfileSelectedPortfolioContainer;