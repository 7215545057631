import './portfolio-menu.scss';
import React, { useState, useRef, useEffect } from 'react';
import PortfolioForm from '../portfolio-form/portfolio-form';
import PortfolioStats from './portfolio-stats/portfolio-stats';
import ManagePortfolio from '../manage-portfolio/manage-portfolio';
import PortfolioSwitch from './switch-portfolio/switch-portfolio';
import { manageIcon, plusIcon, scoresIcon, switchIcon } from '../../../shared/icons/icons';

const PortfolioMenu = ({
    addStock,
    stats,
    selectedPortfolio,
    addStocks,
    myPortfolio,
    handleChangeSelectedPortfolio,
    portfolio,
    loggedInUser,
    blinkCmp
}) => {

    const [activeMenuItem, setActiveMenuItem] = useState('');
    const [isOpen, setOpen] = useState(false);
    const containerRef = useRef(null);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        // Your useEffect logic here
        // Get the app-header-container element
        const appHeaderContainer = document.querySelector('.app-header-container');
        // Set the display style based on the openModal state
        appHeaderContainer.style.display = openModal ? 'none' : 'flex';
        // Clean up the style when the component unmounts
        return () => {
            appHeaderContainer.style.display = ''; // Reset to default (remove inline style)
        };
    }, [openModal]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setOpen(false);
                setActiveMenuItem('');
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleMenuItemClick = (menuItem) => {
        setActiveMenuItem(menuItem);
    };

    const toggleSidebar = () => {
        setOpen(!isOpen);
        setActiveMenuItem('');
    };

    return (
        <div className="portfolio-menu-container pos-rel" ref={containerRef}>
            {/* <div className="portfolio-form-toggle" onClick={toggleSidebar}>☰</div> */}
            <div
                className={`portfolio-form-toggle ${blinkCmp === 'portfolio-menu-blink' ? 'blink-border' : ''}`}
                onClick={toggleSidebar}
            >
                ☰
            </div>

            {
                isOpen && (
                    <div className='portfolio-menu-options flex column'>
                        <div
                            className='portfolio-menu-item flex'
                            style={{ backgroundColor: activeMenuItem === 'addStocks' ? '#f1f6fc' : 'inherit' }}
                            onClick={() => handleMenuItemClick('addStocks')}
                        >
                            <div className='portfolio-menu-icon'> {plusIcon} </div>
                            <div>Add Stock</div>
                        </div>
                        <div
                            className='portfolio-menu-item flex'
                            style={{ backgroundColor: activeMenuItem === 'viewStats' ? '#f1f6fc' : 'inherit' }}
                            onClick={() => handleMenuItemClick('viewStats')}
                        >
                            <div className='portfolio-menu-icon'>{scoresIcon}</div>
                            <div>View Scores</div>
                        </div>
                        <div
                            className='portfolio-menu-item flex'
                            style={{ backgroundColor: activeMenuItem === 'changePortfolio' ? '#f1f6fc' : 'inherit' }}
                            onClick={() => handleMenuItemClick('changePortfolio')}
                        >
                            <div className='portfolio-menu-icon'>{switchIcon}</div>
                            <div>Switch Portfolio</div>
                        </div>


                        <div
                            className='portfolio-menu-item flex'
                            style={{ backgroundColor: activeMenuItem === 'managePortfolio' ? '#f1f6fc' : 'inherit' }}
                            onClick={() => setOpenModal(true)}
                        >
                            <div className='portfolio-menu-icon'>{manageIcon}</div>
                            <div>Manage Portfolio</div>
                        </div>
                    </div>
                )
            }


            {
                activeMenuItem === 'changePortfolio' && isOpen && (
                    <PortfolioSwitch
                        portfolio={portfolio}
                        selectedPortfolio={selectedPortfolio}
                        handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                    />
                )
            }

            {
                activeMenuItem === 'addStocks' && isOpen && (
                    <PortfolioForm addStock={addStock} />
                )
            }

            {
                activeMenuItem === 'viewStats' &&
                (
                    <PortfolioStats selectedPortfolio={selectedPortfolio} portfolio={portfolio} />
                )
            }


            <ManagePortfolio
                open={openModal}
                onClose={() => setOpenModal(false)}
                selectedPortfolio={selectedPortfolio}
                myPortfolio={myPortfolio}
                addStocks={addStocks}
                loggedInUser={loggedInUser}
            />

        </div>
    );
};

export default PortfolioMenu;


{/* {
                        activeOption !== 'sensitivity' && activeOption !== 'efficiency' && <div
                            className='portfolio-menu-item flex'
                            style={{ backgroundColor: activeMenuItem === 'editColors' ? '#84c66c' : 'inherit' }}
                            onClick={() => handleMenuItemClick('editColors')}
                        >
                            <div className='portfolio-menu-icon'>✎</div>
                            <div>Edit Colors</div>
                        </div>
                    } */}

{/* {(activeMenuItem === 'editColors' && activeOption !== 'efficiency' && activeOption !== 'sensitivity') && (
                <PortfolioColors activeOption={activeOption} colors={colors} handleChangeColorsToShow={handleChangeColorsToShow} />
            )}
            {activeMenuItem === 'changePortfolio' && isOpen && (
                <PortfolioSwitch portfolios={portfolios} selectedPortfolio={selectedPortfolio} onChangeSelectedPortfolio={onChangeSelectedPortfolio} />
            )} */}
