import React from 'react';
import { Line } from "react-chartjs-2";
import { useMediaQuery } from 'react-responsive';
import { enUS } from 'date-fns/locale';
import { formatNumberToDollarTableString } from '../../../../../shared/global-color-functions';

const FeedLine = ({ item, PortfolioProfit }) => {

    const isMobileScreen = useMediaQuery({ maxWidth: 600 });

    // hardcoded label type
    const labelType = 'dollar';

    const mediumScreen = useMediaQuery({ maxWidth: 920 });
    const smallScreen = useMediaQuery({ maxWidth: 800 });

    const datasets = item.data.datasets.map((dataset) => ({
        label: dataset.label,
        data: dataset.data.map((point) => ({ x: point.date, y: parseFloat(point.y) })),
        backgroundColor: dataset.backgroundColor,
        borderColor: dataset.borderColor,
        borderWidth: dataset.borderWidth,
        fill: dataset.fill,
    }));

    const data = {
        datasets,
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                    boxHeight: 6
                }
            },
            tooltip: {
                displayColors: false,
                backgroundColor: '#ffffff',
                titleColor: '#484848',
                borderWidth: '1',
                borderColor: '#484848',
                bodyColor: '#484848',
                cornerRadius: '20',
                padding: '10',
                callbacks: {
                    title: (tooltipItem) => {
                        const currItem = item.colors[tooltipItem[0].datasetIndex];
                        if (currItem.nav === 'company') {
                            return `(${currItem.symbol}) ${currItem.company}`
                        }
                    },
                    label: (tooltipItem) => {
                        return [tooltipItem.label, formatNumberToDollarTableString(tooltipItem.raw.y)];
                    }
                }
            },
        },
        title: {
            display: true,
            text: 'Companies return',
            font: {
                size: smallScreen ? 8 : (mediumScreen ? 10 : 12),
            }
        },
        scales: {
            x: {
                ticks: {
                    maxTicksLimit: mediumScreen ? 4 : 5,
                    font: {
                        size: smallScreen ? 8 : (mediumScreen ? 10 : 12),
                    }
                },
                type: 'time',
                distribution: 'linear',
                adapters: {
                    date: {
                        locale: enUS,
                    },
                },
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'MMM yy',
                    }
                }
            },
            y: {
                ticks: {
                    maxTicksLimit: mediumScreen ? 4 : 5,
                    font: {
                        size: smallScreen ? 8 : (mediumScreen ? 10 : 12),
                    },
                    callback: function (value, index, values) {
                        if (index === 0) {
                            return '';
                        }
                        if (labelType === 'relative-percent') {
                            return value + "%";
                        }
                        if (labelType === 'percent') {
                            return Math.round(value * 100) + "%";
                        }
                        else if (labelType === 'percent-decimal') {
                            return (value * 100).toFixed(1) + '%';
                        } else if (labelType === 'dollar') {
                            return formatNumberToDollarTableString(value);
                        } else {
                            return value.toFixed(2);
                        }
                    }
                }
            },
        },
        elements: {
            point: {
                radius: 0
            }
        },
        // onClick: (event, chartElements) => {
        //     if (chartElements[0] && chartElements.length) {
        //         const clickedLine = chartElements[0].datasetIndex;
        //         setSelectedLine(clickedLine);
        //         onChangeSelectedItem(chartData.datasets[clickedLine].label);
        //     } else {
        //         setSelectedLine(null);
        //         onChangeSelectedItem(null);
        //     }
        // },
    };


    return (
        <>
            {/* <div className="flex space-between"> */}
            <div className={`flex ${isMobileScreen ? 'column' : 'space-between'}`}>
                <h4 className='portfolio-chart-title flex align-end mr20' style={{minWidth: '200px'}}>{item.title}</h4>
                <PortfolioProfit />
            </div>
            <div className='homepage-feed-chart-container flex column center-center' style={{ height: '220px', width: '100%' }}>
                <Line data={data} options={options} style={{width: '100%' }}/>
            </div>
        </>
    );
}

export default FeedLine;


