import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { getSelectedCompany } from "../../shared/actions/single-item-actions";
import ActivePageSelection from "../../shared/active-page/active-page";
import FinancialStatements from "../financial-statements/financial-statements";
import SingleCompanyHeader from "./single-company-header/single-company-header";
import { setGlobalIsLoading } from "../../store/loading/loading-actions";
import LoadingSpinner from "../../shared/loading-spinner/loading-spinner";
import SingleItemTechnical from '../../cmps/single-item-technical/single-item-technical';
import SingleCompanyFundamental from "./single-company-fundamental/single-company-fundamental";
import PortfolioFundamentalFilter from "../portfolio/portfolio-filters/portfolio-fundamental-filter";
import { getTodayMonthDayDate } from "../../shared/global-color-functions";

const SingleCompany = ({ loggedInUser, toggleFollowing, userFollowing, singleCompanies, setSingleCompanies }) => {

    const loadingSelector = useSelector((state) => state.loading.isLoading);
    const lastPrices = useSelector((state) => state.lastPrices);

    const { symbol } = useParams();
    const [activePage, setActivePage] = useState('technical');
    const location = useLocation();
    const path = location.pathname;
    const navType = path.split('/')[1];
    const data = lastPrices[`${symbol}=${navType}`];

    useEffect(() => {
        setActivePage('technical');
    }, [symbol]);

    const [companyHeader, setCompanyHeader] = useState(null);
    const [technicalData, setTechnicalData] = useState(null);
    const [fundamentalData, setFundamentalData] = useState(null);
    const [financialStatementsData, setFinancialStatementsData] = useState(null);

    const history = useHistory();
    const dispatch = useDispatch();

    const getLastPricesCompanyHeader = () => {
        // const data = lastPrices[symbol];
        if (!data) return;
        const todayDate = getTodayMonthDayDate();
        if (!data) return {};
        return {
            date: todayDate,
            currency: "USD",
            company: data.name,
            symbol: symbol,
            sector: data.info.sector,
            industry: data.info.industry,
            lastPriceChange: data.lastPriceChange,
            lastPricePercentChange: data.lastPricePercentChange,
            url: []
        };
    };

    useEffect(() => {
            // const data = lastPrices[symbol];
        // if (!data) return;
        setCompanyHeader(getLastPricesCompanyHeader());
    }, [symbol, lastPrices]);

    const handleNavigateToError = () => {
        history.push('/error');
    };

    const setSingleCompanyData = (headerData, technicalData, fundamentalData, financialStatementsData) => {
        setCompanyHeader(headerData);
        setTechnicalData(technicalData);
        setFundamentalData(fundamentalData);
        setFinancialStatementsData(financialStatementsData);
    };

    useEffect(() => {
        if (technicalData && financialStatementsData) {
            dispatch(setGlobalIsLoading(false))
        }
    }, [technicalData, fundamentalData, financialStatementsData]);

    // useEffect(() => {
    //     if (!symbol) handleNavigateToError();
    //     async function fetchData() {
    //         dispatch(setGlobalIsLoading(true));
    //         try {
    //             if (singleCompanies[symbol]) {
    //                 setSingleCompanyData(
    //                     singleCompanies[symbol].singleCompanyHeader,
    //                     singleCompanies[symbol].technicalPage,
    //                     singleCompanies[symbol].fundamentalPage,
    //                     singleCompanies[symbol].financialStatementsPage
    //                 );
    //             } else {
    //                 const companyData = await getSelectedCompany(activePage, symbol);
    //                 if (companyData) {
    //                     setSingleCompanyData(
    //                         companyData.singleCompanyHeader,
    //                         companyData.technicalPage,
    //                         companyData.fundamentalPage,
    //                         companyData.financialStatementsPage
    //                     );
    //                     setSingleCompanies(prevSingleCompanies => ({
    //                         ...prevSingleCompanies,
    //                         [symbol]: companyData
    //                     }));
    //                 } else {
    //                     handleNavigateToError();
    //                 }
    //             }
    //         } catch (error) {
    //             handleNavigateToError();
    //         }
    //     }
    //     fetchData();
    // }, [symbol]);


    useEffect(() => {
        if (!symbol) handleNavigateToError();
        async function fetchData() {
            dispatch(setGlobalIsLoading(true));
            try {
                if (singleCompanies[symbol]) {
                    let newData = {
                        ...singleCompanies[symbol]
                    };
                    if (activePage === 'technical' && !newData.technicalPage) {
                        const companyData = await getSelectedCompany(activePage, symbol);
                        newData = {
                            ...newData,
                            technicalPage: companyData.technicalPage
                        };
                    } else if (activePage === 'fundamental' && !newData.fundamentalPage) {
                        const companyData = await getSelectedCompany(activePage, symbol);
                        newData = {
                            ...newData,
                            fundamentalPage: companyData.fundamentalPage,
                            financialStatementsPage: companyData.financialStatementsPage
                        };
                    } else if (activePage === 'financial statements' && !newData.financialStatementsPage) {
                        const companyData = await getSelectedCompany('financialStatements', symbol);
                        newData = {
                            ...newData,
                            fundamentalPage: companyData.fundamentalPage,
                            financialStatementsPage: companyData.financialStatementsPage
                        };
                    }
                    setSingleCompanyData(
                        newData.singleCompanyHeader,
                        newData.technicalPage,
                        newData.fundamentalPage,
                        newData.financialStatementsPage
                    );

                    setSingleCompanies(prevSingleCompanies => ({
                        ...prevSingleCompanies,
                        [symbol]: newData
                    }));
                } else {
                    const companyData = await getSelectedCompany(activePage, symbol);
                    if (companyData) {
                        setSingleCompanyData(
                            companyData.singleCompanyHeader,
                            companyData.technicalPage,
                            companyData.fundamentalPage,
                            companyData.financialStatementsPage
                        );
                        setSingleCompanies(prevSingleCompanies => ({
                            ...prevSingleCompanies,
                            [symbol]: companyData
                        }));
                    } else {
                        handleNavigateToError();
                    }
                }
            } catch (error) {
                handleNavigateToError();
            } finally {
                dispatch(setGlobalIsLoading(false));
            }
        }
        fetchData();
    }, [symbol, activePage]);

    const handleToggleOption = (option) => {
        setActivePage((prevOption) => {
            return option;
        });
    };

    const pages = ['Technical', 'Fundamental', 'Financial Statements'];

    const noResultsMsg = (
        <>
            <div className="main-content-container">
                <div>Whooopps!!</div>
                Sorry, We're working on it...
            </div>
        </>
    );

    const [activeFundamentalFilter, setActiveFundamentalFilter] = useState('overTime');
    const handleToggleActiveFundamentalFilter = (option) => {
        setActiveFundamentalFilter((prevOption) => {
            return option;
        });
    };

    return (
        <>
            <div className="page-margin-top"></div>
            <ActivePageSelection
                handleToggleOption={handleToggleOption}
                pages={pages}
                activePage={activePage}
            />
            <div className="single-company-main-container">


                {
                    companyHeader &&
                    <SingleCompanyHeader
                        loggedInUser={loggedInUser}
                        toggleFollowing={toggleFollowing}
                        userFollowing={userFollowing}
                        company={companyHeader}
                        hasFinancialStatements={financialStatementsData ? Object.keys(financialStatementsData).length > 0 : false}
                    />
                }
            </div>
            {
                loadingSelector ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className="single-company-main-container">

                            {
                                activePage === 'fundamental' &&
                                <div className="main-content-container dropdown-select-filter-container">
                                    <PortfolioFundamentalFilter
                                        fundamentalFilter={activeFundamentalFilter}
                                        handleToggleFundamentalFilter={handleToggleActiveFundamentalFilter}
                                    />
                                </div>
                            }


                            {
                                activePage === 'technical' &&
                                companyHeader &&
                                technicalData&&
                                <SingleItemTechnical
                                    technicalData={technicalData}
                                    symbol={companyHeader.symbol}
                                    type={'company'}
                                />
                            }
                            {
                                activePage === 'fundamental' &&
                                fundamentalData &&
                                <SingleCompanyFundamental
                                    fundamentalData={fundamentalData}
                                    symbol={companyHeader.symbol}
                                    noResultsMsg={noResultsMsg}
                                    activeFilter={activeFundamentalFilter}
                                />
                            }
                            {
                                activePage === 'financial statements' &&
                                financialStatementsData &&
                                companyHeader &&
                                <FinancialStatements
                                    financialStatementsData={financialStatementsData}
                                    symbol={companyHeader.symbol}
                                    companyName={companyHeader.company}
                                    noResultsMsg={noResultsMsg}
                                />
                            }

                        </div>
                    </>
                )}
        </>
    );

}

export default SingleCompany;