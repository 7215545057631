const MacroInflationClass = () => {
    return (
        <>
            <h2 className={'app-tutorial-slide-title'}>Macro - Inflation</h2>
            <h4 className={"guide-subtitle"}>Leading Macroeconomic Key Performance Indicators (KPIs):
            </h4>
            <ul className={"guide-ul"}>
                <li>Fed Funds Effective Rate: The interest rate at which depository institutions (banks and credit unions) lend reserve balances to other depository institutions overnight on an uncollateralized basis, as set by the Federal Reserve.</li>
                <li>Unemployment Rate: The percentage of the total labor force that is unemployed and actively seeking employment.</li>
                <li>Yearly Change in Money Supply: The annual percentage change in the money supply, measuring the amount of money in circulation in the economy.
                </li>
                <li>Yearly Inflation: The annual percentage increase in the general price level of goods and services in an economy.
                </li>
                <li>10Y Breakeven Inflation: The market-based measure of expected inflation derived from the yield difference between 10-year Treasury bonds and Treasury Inflation-Protected Securities (TIPS).
                </li>
                <li>5Y Breakeven Inflation: Similar to the 10Y Breakeven Inflation, but based on the yield difference between 5-year Treasury bonds and TIPS.
                </li>
                <li>5Y Forward Inflation Expected: The market's expectation of future inflation over the next five years, derived from financial instruments such as inflation swaps.
                </li>
            </ul>
            <h4 className={"guide-subtitle"}>Charts:</h4>
            <ul className={"guide-ul"}>
                <li>Macro Indicators Over Time: Tracks the historical trends of Fed Funds Effective Rate, Unemployment Rate, Yearly Change in Money Supply, and Yearly Inflation.
                </li>
                <li>Inflation Expectations Over Time: Displays the historical trends of market-based measures of inflation expectations, including 10Y Breakeven Inflation, 5Y Breakeven Inflation, and 5Y Forward Inflation Expected.
                </li>
            </ul>
            <div className="flex flex-end">
                <a href="/macro/inflation" className="guide-btn btn btn-border" title="Inflation">Go to page</a>
            </div>
        </>
    );
}

export default MacroInflationClass;