import { createSlice } from "@reduxjs/toolkit";

export const userAchievementsSlice = createSlice({
    name: "user-achievements",
    initialState: {
        level: 0,
        completed: 0,
        atSlide: 0,
        status: 'start',
        shouldSeeTutorial: false
    },
    reducers: {
        setUserAchievements(state, action) {
            return { ...state, ...action.payload };
        },
        setAchievementStatus(state, action) {
            return { ...state, status: action.payload };
        },
        setAchievementAtSlide(state, action) {
            return { ...state, atSlide: action.payload };
        },
        setShouldSeeTutorial(state, action) {
            return { ...state, shouldSeeTutorial: action.payload };
        },
        setAchievementCompleted(state, action) {
            return { ...state, completed: action.payload };
        }
    },
});

export const { setUserAchievements, setAchievementStatus, setAchievementAtSlide, setShouldSeeTutorial, setAchievementCompleted } = userAchievementsSlice.actions;

export default userAchievementsSlice.reducer;