const ProfilePortfolioNames = ({
    portfolioNames,
    selectedPortfolio,
    handleChangeSelectedPortfolio,
    portfolioWorthSum,
    portfolioToDisplay
}) => {

    // console.log('portfolioToDisplay:', portfolioToDisplay);

    const portfolios = [
        { value: 'portfolioA', label: 'portfolioA' },
        { value: 'portfolioB', label: 'portfolioB' },
        { value: 'portfolioC', label: 'portfolioC' },
        { value: 'portfolioD', label: 'portfolioD' },
        { value: 'portfolioE', label: 'portfolioE' }
    ];

    const updatedPortfolios = portfolios.map(portfolio => {
        const matchingPortfolioName = portfolioNames.find(item => item.portfolioKey === portfolio.value);
        if (matchingPortfolioName) {
            return { ...portfolio, label: matchingPortfolioName.portfolioName };
        }
        return portfolio;
    });

    const renderedDivs = updatedPortfolios.map((portfolio, index) => (
        <div className="flex column" key={index}>
            <div className="flex justify-center" style={{ margin: '0 auto', marginBottom: '0px'}}>
                <div
                    className={`flex btn flex align-center ${portfolio.value === selectedPortfolio ? 'selected' : ''}`}
                    onClick={() => handleChangeSelectedPortfolio(portfolio.value)}
                    style={{ marginRight: '0px', marginBottom: '30px' }}
                >
                    {
                        // portfolio.value === selectedPortfolio &&
                        portfolioToDisplay[portfolio.value] &&
                        portfolioToDisplay[portfolio.value].portfolioData &&
                        portfolioToDisplay[portfolio.value].portfolioData.length > 0 &&
                        <div className="profile-portfolio-switcher" style={{ marginRight: '5px' }}></div>
                    }
                    <div>{portfolio.label}</div>
                </div>
            </div>
        </div>
    ));


    return (
        <>
            <div className="profile-portfolio-btns-container mb10 flex space-evenly wrap">
                {renderedDivs}
            </div>
        </>
    );
}

export default ProfilePortfolioNames;