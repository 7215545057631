import React, { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router-dom';
import CardContainer from "../../../../cmps/card-container/card-container";
import LineChart from "../../../../shared/charts/line-chart/line-chart";
import ScatterChart from "../../../../shared/charts/scatter-chart/scatter-chart";
import { formatNumberToDollarTableString } from "../../../../shared/global-color-functions";
import MacroLabelsLinechartContainer from "../../../macro/macro-labels-linechart-container/macro-labels-linechart-container";
import SingleSectorFilter from "../single-sector-filters";
import { useMediaQuery } from 'react-responsive';
import '../single-sector.scss';

const SingleSectorCompetitive = ({
    sectorCompetitive,
    companyFilters,
    activeCompanyFilter,
    handleToggleFilter,
}) => {

    const [competitiveFilters, setCompetitiveFilters] = useState(null);
    const [activeFilter, setActiveFilter] = useState(null);

    const [colorsData, setColorsData] = useState(null);
    const [lifeCycleData, setLifeCycleData] = useState(null);
    const [competitiveAnalysisData, setCompetitiveAnalysisData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [tableHeaders, setTableHeaders] = useState(null);

    const history = useHistory();
    const scatterRef = useRef(null);

    const [selectedItem, setSelectedItem] = useState(null);
    const isMobile = useMediaQuery({ maxWidth: 920 });

    useEffect(() => {
        const competitiveArray = Object.keys(sectorCompetitive).map(key => ({
            label: key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()),
            value: key
        }));
        setActiveFilter(competitiveArray[0].value);
        setCompetitiveFilters(competitiveArray);
    }, [sectorCompetitive]);

    useEffect(() => {
        if (!activeFilter || !sectorCompetitive) return;
        const lineChartData = [];
        const colors = [];
        const competitiveAnalysisData = [];
        // const tables = [];
        const originalTables = [];

        sectorCompetitive[activeFilter].forEach(item => {
            const { symbol, color, lifeCycle, company, competitiveAnalysis, table } = item;
            const opacity = selectedItem === null ? 1 : selectedItem === symbol ? 1 : 0.2;
            const rgbaColor = `rgba(${parseInt(color.slice(-6, -4), 16)}, ${parseInt(color.slice(-4, -2), 16)}, ${parseInt(color.slice(-2), 16)}, ${opacity})`;
            const borderWidth = selectedItem === symbol ? 5 : 3;
            const pointRadius = selectedItem === null ? 8 : selectedItem === symbol ? 10 : 8;
            lineChartData.push({
                label: symbol,
                data: lifeCycle.map(dataItem => ({ x: dataItem.x, y: dataItem.y })),
                fill: false,
                backgroundColor: rgbaColor,
                borderColor: rgbaColor,
                borderWidth: borderWidth,
            });
            colors.push({ symbol, label: symbol, color });
            if (competitiveAnalysis) {
                competitiveAnalysisData.push({
                    data: [{ x: competitiveAnalysis.x, y: competitiveAnalysis.y }],
                    label: symbol,
                    company: company,
                    borderColor: rgbaColor,
                    backgroundColor: rgbaColor,
                    borderWidth: borderWidth,
                    fill: false,
                    pointRadius: pointRadius,
                    pointStyle: 'circle',
                });
            }
            if (table) {
                originalTables.push(table);
            }
        });
        const copiedTables = originalTables.map(table => [...table]);

        copiedTables.sort((a, b) => {
            const valueA = a[2].value;
            const valueB = b[2].value;
            return valueB - valueA;
        });

        const formattedTables = copiedTables.map((row) => {
            const formattedValue = formatNumberToDollarTableString(row[2].value);
            return row.map((item, index) => (index === 2 ? { ...item, value: formattedValue } : item));
        });
        setLifeCycleData({ datasets: lineChartData });
        setColorsData(colors);
        setCompetitiveAnalysisData(competitiveAnalysisData);
        setTableData(formattedTables);
        const headers = originalTables[0].map(item => item.label.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()));
        setTableHeaders(headers);
    }, [activeFilter, sectorCompetitive, selectedItem]);

    const handleTogglePage = (option) => {
        setActiveFilter((prevOption) => {
            return option;
        });
    };

    const onChangeSelectedItem = (item) => {
        setSelectedItem(item);
    };

    const getSelectedItemHover = () => {
        if (!selectedItem || !sectorCompetitive) return '';
        const firstKey = Object.keys(sectorCompetitive)[0];
        const selectedItemArray = sectorCompetitive[firstKey];
        const selectedItemObj = selectedItemArray.find(item => item.symbol === selectedItem);
        if (selectedItemObj) {
            return `(${selectedItemObj.symbol}) ${selectedItemObj.company}`;
        }
        return '';
    };

    const lifeCycleChartContent = (
        <>
            {
                lifeCycleData &&
                <div style={{ width: '99.9%', height: '100%' }}>
                    <LineChart
                        chartData={lifeCycleData}
                        selectedItem={selectedItem}
                        onChangeSelectedItem={onChangeSelectedItem}
                        type={'dollar'}
                    />
                </div>
            }
        </>
    );

    const competitiveAnalysisContent = (
        <>
            {
                competitiveAnalysisData &&
                <>
                    <ScatterChart
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        onChangeSelectedItem={onChangeSelectedItem}
                        chartData={{ datasets: competitiveAnalysisData }}
                        activeFilter={activeFilter}
                    />
                </>
            }
        </>
    );

    const onNavigateCompany = (clickedSymbol) => {
        history.push(`/company/${clickedSymbol}`);
    };

    const Table = ({ tableHeaders, tableData }) => {
        return (
            <div
                className="card-container single-sector-competitive-table findatasheets-container-item"
                style={{
                    // marginLeft: isMobile ? '0' : '20px',
                    marginTop: isMobile ? '20px' : '20px',
                }}
            >
                <div className="findatasheets-table-header flex space-between">
                    {tableHeaders.map((header, headerIdx) => (

                        <div key={header} className={`findatasheets-table-cell single-sector-competitive-table-cell findatasheets-table-title flex align-end ${headerIdx === 0 ? 'single-sector-competitive-table-first-cell clickable-label' : 'single-sector-competitive-table-cell'}`}>
                            {header}
                        </div>
                    ))}
                </div>
                {tableData.map((rowData, rowIndex) => (
                    <div
                        key={rowIndex}
                        className={`findatasheets-table-row flex space-between ${rowIndex % 2 === 0 ? '' : 'odd-row'}`}
                    >
                        {
                            rowData.map((cell, cellIndex) => (
                                <div
                                    key={cell.label}
                                    className={`findatasheets-table-cell single-sector-competitive-table-cell ${cellIndex === 0 ? 'single-sector-competitive-table-first-cell clickable-label' : 'single-sector-competitive-table-cell'}`}
                                    onClick={() => cellIndex === 0 ? onNavigateCompany(cell.value) : null}
                                // onMouseEnter={() => cellIndex === 0 ? setSelectedItem(rowData[0].value) : null}
                                // onMouseLeave={() => cellIndex === 0 ? setSelectedItem(null) : null}
                                >
                                    {cell.value}
                                </div>
                            ))}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <>
            <div className="flex column center-center">

                {
                    companyFilters &&
                    activeCompanyFilter &&
                    <div style={{width: '100%'}}>
                        <SingleSectorFilter
                            options={companyFilters}
                            optionsValue={activeCompanyFilter}
                            onChange={handleToggleFilter}
                        />
                    </div>
                }

                <div className="fundamental-page-container flex center-center">
                    {
                        competitiveFilters &&
                        competitiveFilters.map((option) => {
                            return <div key={option.value} className={`portfolio-toggle btn selected-option ${activeFilter === option.value ? 'selected' : ''}`} onClick={() => handleTogglePage(option.value)}>{option.label}</div>
                        })
                    }
                </div>

                {
                    colorsData &&
                    lifeCycleData &&
                    <div className="findatasheets-container" style={{ width: '100%' }}>
                        <>
                            <MacroLabelsLinechartContainer
                                labels={colorsData}
                                type={'company'}
                                display={'column'}
                                currenciessLineChart={lifeCycleChartContent}
                                currenciessLineChartTitle={'Life Cycle'}
                                selectedItem={selectedItem}
                                hoverItem={getSelectedItemHover()}
                                onChangeSelectedItem={onChangeSelectedItem}
                            />


                            <div className={''}>
                                {
                                    competitiveAnalysisData &&
                                    <div style={{ width: '100%' }} ref={scatterRef} >
                                        <CardContainer
                                            // title={competitiveAnalysisTitle}
                                            title={'Competitive Analysis'}
                                            innerContent={competitiveAnalysisContent}
                                            className={'single-sector-competitive-scatter-container'}
                                            infoModalType={'competitiveAnalysis'}
                                            selectedItem={getSelectedItemHover()}
                                        />
                                    </div>
                                }
                                {
                                    tableData &&
                                    tableHeaders &&
                                    <Table
                                        tableHeaders={tableHeaders}
                                        tableData={tableData}
                                    />
                                }
                            </div>
                        </>

                    </div>
                }
            </div>
        </>
    );
} 

export default SingleSectorCompetitive; 