import './style/fund-members.scss';
import data from './data/fund-members.json';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { DropdownIndicator, customStyles } from "../../../shared/dropdown-styles";
import { achievementIcon, coinsIcon, dropdownIcon, followersIcon, userIcon } from "../../../shared/icons/icons";

const FundMembers = ({ fundMembers }) => {

    // console.log('data:', data);       

    const [members, setMembers] = useState(data.members);
    const [sortBy, setSortBy] = useState('level');
    const [sortFrom, setSortFrom] = useState('high');

    const sortByOptions = [
        { label: 'Level', value: 'level' },
        { label: 'Coins', value: 'coins' },
        { label: 'Fame', value: 'fame' },
        { label: 'Followers', value: 'followers' }
    ];

    const levels = ['gold', 'silver', 'bronze'];

    const [showOptions, setShowOptions] = useState(false);

    const onHandleSortMouseEnter = () => {
        setShowOptions(true);
    };

    const onHandleSortMouseLeave = () => {
        setShowOptions(false);
    };

    const onSortByChange = (opt) => {
        setSortBy(opt.value);
    };


    useEffect(() => {
        const sortedMembers = [...fundMembers].sort((a, b) => {
            let comparison = 0;
            if (sortBy === 'level') {
                const levelOrder = { gold: 3, silver: 2, bronze: 1 };
                const levelComparison = levelOrder[a.level] - levelOrder[b.level];
                if (levelComparison !== 0) {
                    comparison = levelComparison;
                } else {
                    comparison = a.username.localeCompare(b.username);
                }
            } else {
                const aValue = isNaN(parseInt(a[sortBy].replace(',', ''))) ? a[sortBy] : parseInt(a[sortBy].replace(',', ''));
                const bValue = isNaN(parseInt(b[sortBy].replace(',', ''))) ? b[sortBy] : parseInt(b[sortBy].replace(',', ''));
                comparison = aValue - bValue;
            }
            return sortFrom === 'high' ? comparison * -1 : comparison;
        });
        setMembers(sortedMembers);
    }, [sortBy, sortFrom, fundMembers]);



    const history = useHistory();
    const handleTitleClick = (name) => {
        history.push(`/profile/portfolios/${name}`);
    };


    return (
        <>
            {/* <div className="card-container flex center-center mb20">
                <div
                    className="dropdown-select-filter-item"
                    onMouseEnter={onHandleSortMouseEnter}
                    onMouseLeave={onHandleSortMouseLeave}
                >
                    <Select
                        styles={customStyles}
                        components={{
                            DropdownIndicator,
                        }}
                        options={sortByOptions}
                        value={sortBy}
                        onChange={onSortByChange}
                        menuIsOpen={showOptions}
                        hideSelectedOptions={false}
                    />
                    <div className={`dropdown-label flex align-center`}> {sortBy} <span className='dropdown-indicator flex center-center'>{dropdownIcon}</span></div>
                </div>
            </div> */}


            {
                members.map((member, idx) => (
                    <div key={idx} className="fund-item-container card-container mb20 flex space-between">
                        <div className="flex align-center">
                            <div className='fund-members-img' style={{ borderColor: member.level === 'bronze' ? 'brown' : member.level }}>{userIcon}</div>
                            <div className='fund-members-username' onClick={() => { handleTitleClick(member.username) }}>{member.username}</div>
                        </div>
                        <div className="flex">
                            <div
                                className="single-fund-item-trophies-container fund-members-item-trophies-container"
                                title={'Coins'}
                            >
                                <div className='fund-members-icon flex center-center'>{coinsIcon}</div>
                                <div className='fund-members-amount'>{member.coins}</div>
                            </div>
                            <div
                                style={{ margin: '0 20px' }}
                                className="single-fund-item-trophies-container fund-members-item-trophies-container"
                                title={'Fame'}
                            >
                                <div className='fund-members-icon flex center-center'>{achievementIcon}</div>
                                <div className='fund-members-amount'>{member.fame}</div>
                            </div>
                            <div 
                            className="single-fund-item-trophies-container fund-members-item-trophies-container"
                            title={'Followers'}
                            >
                                <div className='fund-members-icon flex center-center'>{followersIcon}</div>
                                <div className='fund-members-amount'>{member.followers}</div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </>
    );
}

export default FundMembers;