import React, { useState, useEffect } from 'react';
import { followIcon, unfollowIcon } from '../../shared/icons/icons';
import './simple-follow-btn.scss';

const SimpleFollowBtn = ({
    loggedInUser,
    symbol,
    type,
    toggleFollowing,
    isFollowing,
    setIsFollowing,
    isMobile,
    isScreenSizeMedium,
    userFollowing
}) => {

    const [showErrMsg, setErrMsg] = useState(false);

    useEffect(() => {
        if (userFollowing) setIsFollowing(userFollowing.includes(symbol));
    }, [userFollowing, symbol]);

    const handleFollowClick = () => {
        if (loggedInUser) {
            setIsFollowing(!isFollowing);
            toggleFollowing(type, symbol);
        } else {
            setErrMsg(true);
            setTimeout(() => {
                setErrMsg(false);
            }, 5000);
        };
    };

    const followEl = (
        <>
            <div className='flex align-center mr10'>{unfollowIcon}</div>
            <div>Follow</div>
        </>
    );

    const followingEl = (
        <>
         <div className='flex align-center mr10'>{followIcon}</div>
            <div>Following</div>
        </>
    );

    return (
        <>
            <div
                className={isMobile ? "follow-btn follow-btn-crypto-header btn flex" : "follow-btn follow-btn-crypto-header btn flex align-center"}
                onClick={handleFollowClick}
            >
                {isFollowing ? followingEl : followEl}
            </div>

            {
                showErrMsg &&
                <div
                    className={isScreenSizeMedium ? "financial-statements-header-follow-msg crypto-header-follow-msg" : "financial-statements-header-follow-msg"}
                >
                    You must be logged in to follow.
                </div>
            }
        </>
    );
}

export default SimpleFollowBtn;