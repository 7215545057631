import React, { useState, useEffect } from 'react';
import MacroIndicesCommodities from '../../../cmps/macro-indicies-commodities/macro-indicies-commodities';
import LineChart from '../../../shared/charts/line-chart/line-chart';
import { setGlobalIsLoading } from '../../../store/loading/loading-actions';
import MacroCommodityFilters from './macro-commodity-filters';
import { hexToRGBA } from '../../../shared/global-color-functions';
import { Helmet } from "react-helmet";
import { CommoditiesGuide } from '../../../cmps/page-guide';

const MacroCommodities = ({
    commoditiesData,
    periodFilter,
    onPeriodChange,
    periodOptionsToDisplay,
    activePriceRelative,
    changePriceRelative,
}) => {

    const [selectedGroup, setSelectedGroup] = useState({});
    const [selectedCommodity, setSelectedCommodity] = useState([]);
    const [groupOptions, setGroupOptions] = useState(null);
    const [commodityOptions, setCommodityOptions] = useState(null);

    const [selectedItem, setSelectedItem] = useState(null);
    const [chartDataReturn, setChartDataReturn] = useState(null);
    const [chartDataSharpe, setChartDataSharpe] = useState(null);

    const [commoditiesColors, setCommoditiesColors] = useState(null);
    const [linechartData, setLinechartData] = useState(null);

    useEffect(() => {
        const selectedCommodityValues = selectedCommodity?.map(({ value }) => value);
        const chartData = commoditiesData[activePriceRelative][periodFilter];
        let labelsToDisplay = [];
        let filteredData;
        if (chartData) {
            filteredData = chartData;
        } else {
            filteredData = commoditiesData[activePriceRelative]['oneWeek'];
        }
        if (selectedCommodityValues?.length) {
            filteredData = filteredData.filter(({ key }) =>
                selectedCommodityValues.includes(key)
            );
        }
        const datasets = filteredData.map(({ key, data }) => {
            const colorObj = commoditiesData.defaultColors.find((item) => item.label === key);
            const backgroundOpacity = selectedItem === null || selectedItem === undefined ? 1 : selectedItem === colorObj.symbol ? 1 : 0.1;
            // const borderWidth = selectedItem === null || selectedItem === undefined ? 5 : selectedItem === colorObj.symbol ? 10 : 2;
            const borderWidth = selectedItem === colorObj.symbol ? 5 : 3;

            const chartDataArray = data.map(({ date, x }) => ({ x: date, y: x }));
            const label = key;
            const rgbaColor = hexToRGBA(colorObj ? colorObj.color : '#000000', backgroundOpacity);

            const matchedColor = commoditiesData.defaultColors.find(
                (colorData) => colorData.label === key
            );
            if (matchedColor) {
                const modifiedLabel = matchedColor.symbol;
                labelsToDisplay.push(({
                    label: modifiedLabel,
                    symbol: matchedColor.symbol,
                    color: matchedColor.color,
                }));
            }

            // Rest of the code
            return {
                label,
                data: chartDataArray,
                fill: false,
                backgroundColor: rgbaColor,
                borderColor: rgbaColor,
                borderWidth: borderWidth,
            };
        });

        setChartDataReturn(getChartData("return"));
        setChartDataSharpe(getChartData("sharpe"));
        setCommoditiesColors(labelsToDisplay);
        setLinechartData({ datasets });
    }, [
        commoditiesData,
        periodFilter,
        selectedGroup,
        selectedCommodity,
        activePriceRelative,
        selectedItem,
    ]);

    useEffect(() => {
        const filteredGroups = commoditiesData.groups.filter(item => item.group === selectedGroup.value);
        setCommodityOptions([...new Set(filteredGroups.map(item => item.commodity))].map((commodity) => ({
            label: formatStateLabel(commodity),
            value: commodity,
        })));
        setSelectedCommodity([...new Set(filteredGroups.map(item => item.commodity))].map(commodity => ({
            label: formatStateLabel(commodity),
            value: commodity,
        })));
    }, [selectedGroup]);

    useEffect(() => {
        const createValidGroupOptions = commoditiesData.groups.map(group => ({
            label: formatStateLabel(group.group),
            value: group.group
        }));
        const removedDuplicates = createValidGroupOptions.filter((item, index, arr) => {
            return arr.findIndex((i) => i.value === item.value) === index;
        });
        setGroupOptions(removedDuplicates)
        setSelectedGroup({
            label: formatStateLabel(commoditiesData.groups[0].group),
            value: commoditiesData.groups[0].group
        });
        setGlobalIsLoading(false);
    }, [commoditiesData]);

    const handleGroupChange = selectedOption => {
        setSelectedGroup(selectedOption);
        const selectedCommodities = commoditiesData.groups.filter(group => group.group === selectedOption.value)
            .map(commodity => ({
                label: formatStateLabel(commodity.commodity),
                value: commodity.commodity
            }));
        setSelectedCommodity(selectedCommodities);
        setCommodityOptions(selectedCommodities);
    };

    const handleCommodityChange = selectedOption => {
        setSelectedCommodity(selectedOption);
    };

    const onChangeSelectedItem = (item) => {
        setSelectedItem(item);
    };

    const formatStateLabel = (stateLabel) => {
        return stateLabel
            .replace(/_/g, ' ') // add a space after every underscore
            .replace(/([A-Z])/g, ' $1') // add a space after every capital letter
            .replace(/^./, (str) => str.toUpperCase()); // capitalize the first letter
    }

    const getChartData = (type) => {
        let chartData = {};
        let sortedData = commoditiesData[type][periodFilter];
        if (!sortedData) sortedData = commoditiesData[type]['oneWeek'];
        if (selectedCommodity.length) {
            sortedData = sortedData.filter(({ key }) =>
                selectedCommodity.map(({ value }) => value).includes(key)
            );
        }
        sortedData = sortedData.sort((a, b) => b[type] - a[type]);

        if (!sortedData) {
            return chartData;
        }

        if (selectedItem !== null && selectedItem !== undefined) {
            const selectedIndex = sortedData.findIndex((data) => data.symbol === selectedItem);
            const indices = [selectedIndex - 2, selectedIndex - 1, selectedIndex, selectedIndex + 1, selectedIndex + 2];
            const validIndices = indices.filter((index) => index >= 0 && index < sortedData.length);
            sortedData = validIndices.map((index) => sortedData[index]);
        } else {
            sortedData = sortedData.slice(0, 5);
        }
        sortedData = sortedData.map((item) => {
            const colorObj = commoditiesData.defaultColors.find((colorItem) => colorItem.label === item.key);
            if (colorObj) {
                item.symbol = colorObj.symbol;
            }

            return item;
        });

        chartData = {
            labels: sortedData.map((data) => data.symbol),
            hover: sortedData.map((data) => data.hover),
            datasets: [
                {
                    id: sortedData.map((data) => data.symbol),
                    label: sortedData.map((data) => data.symbol),
                    data: sortedData.map((data) => data[type]),
                    backgroundColor: sortedData.map((data) =>
                        selectedItem && selectedItem !== data.symbol
                            ? hexToRGBA(data[type] > 0 ? "#5c9ead" : "#e39774", 0.4)
                            : data[type] > 0
                                ? "#5c9ead"
                                : "#e39774"
                    ),
                    borderColor: sortedData.map((data) =>
                        selectedItem && selectedItem !== data.symbol
                            ? hexToRGBA(data[type] > 0 ? "#5c9ead" : "#e39774", 0.4)
                            : data[type] > 0
                                ? "#5c9ead"
                                : "#e39774"
                    ),
                },
            ],

        };
        return chartData;
    };

    const getSelectedItemHover = () => {
        if (!selectedItem || !commoditiesData || !commoditiesData.defaultColors) return '';
        const selectedItemObj = commoditiesData.defaultColors.find(item => item.symbol === selectedItem);
        if (selectedItemObj) {
            return `(${selectedItemObj.symbol}) ${selectedItemObj.label}`;
        };
        return '';
    };

    const commoditiesLineChart = (
        <>
            {
                <div style={{ width: '99.9%', height: '90%' }}>
                    <LineChart
                        chartData={linechartData}
                        type={activePriceRelative === 'price' ? 'dollar' : 'relative-percent'}
                        onChangeSelectedItem={onChangeSelectedItem}
                    />
                </div>
            }
        </>
    );

    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>PortFollow - Macro Commodities</title>
            <meta name="description" content="unlock the potential of macroeconomic analysis in shaping your investment strategies! A comprehensive overview of key macroeconomic indicators, currency exchange rates, stock market indices, and commodity prices." />
            <link rel="canonical" href="https://www.portfollow.com/macro/commodities" />
        </Helmet>
        {
            periodOptionsToDisplay &&
            groupOptions &&
            commodityOptions &&
            <MacroCommodityFilters
                periodFilter={periodFilter}
                onPeriodChange={onPeriodChange}
                periodOptionsToDisplay={periodOptionsToDisplay}
                groupOptions={groupOptions}
                selectedGroup={selectedGroup}
                handleGroupChange={handleGroupChange}
                commodityOptions={commodityOptions}
                selectedCommodity={selectedCommodity}
                handleCommodityChange={handleCommodityChange}
                activePriceRelative={activePriceRelative}
                changePriceRelative={changePriceRelative}
            />
        }
        {
            commoditiesColors &&
            chartDataReturn &&
            chartDataSharpe &&
            <div className='mb20'>
                <MacroIndicesCommodities
                    labelColors={commoditiesColors}
                    type={'commodity'}
                    display={'column'}
                    innerContent={commoditiesLineChart}
                    innerContentTitle={'Commodities Over Time'}
                    chartDataReturn={chartDataReturn}
                    chartDataSharpe={chartDataSharpe}
                    onChangeSelectedItem={onChangeSelectedItem}
                    hoverItem={getSelectedItemHover()}
                />
            </div>
        }

        <div className="card-container main-content-container" style={{ margin: '20px 0', width: '100%' }}>
            <CommoditiesGuide />
        </div>

    </>
}

export default MacroCommodities;