import AccountSettings from "../../../user-pool/settings";

const ProfileSettings = ({
    loggedInUser
}) => {

    return (
        <div className="card-container">
            <AccountSettings
                loggedInUser={loggedInUser}
            />
        </div>
    );
}

export default ProfileSettings;