import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import CalendarWeek from './calendar-week/calendar-week';
import './calendar.scss';
import { setGlobalIsLoading } from "../../store/loading/loading-actions";
import ActivePageSelection from '../../shared/active-page/active-page';
import LoadingSpinner from '../../shared/loading-spinner/loading-spinner';
import { getCalendar } from '../../shared/actions/calendar-actions';
import { Helmet } from "react-helmet";
import CalendarGuide from './calendar-guide';
import { useHistory } from 'react-router-dom';
import { CalendarCompaniesEventGuide, CalendarMacroGuide } from '../../cmps/page-guide';

const Calendar = ({ navPage, calendarData, handleChangeCalendarData, loggedInUser }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [currMonth, setCurrMonth] = useState(null);
    const [currYear, setCurrYear] = useState(null);

    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [weeksToDisplay, setWeeksToDisplay] = useState(null);

    const [firstYear, setFirstYear] = useState(null);
    const [lastYear, setLastYear] = useState(null);

    const [userCalendarData, setUserCalendarData] = useState(null);

    const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);

    const [selectedCalendarFilters, setSelectedCalendarFilters] = useState(null);
    const [selectedCalendarFiltersToDisplay, setSelectedCalendarFiltersToDisplay] = useState(null);

    const [loading, setLoading] = useState(true);

    const loadingSelector = useSelector((state) => state.loading.isLoading);

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const datepickerRef = useRef(null);

    const [activePage, setActivePage] = useState(navPage);
    // const pages = ['companies', 'macro', 'myCalendar']
    // const pages = ['companies', 'macro', 'guide']
    const pages = ['companies', 'guide']

    const calendarColors = [
        {
            color: 'blue',
            hex: '#118ab2'
        },
        {
            color: 'green',
            hex: '#06d6a0'
        },
        {
            color: 'yellow',
            hex: '#ffd166'
        },
        {
            color: 'red',
            hex: '#ef476f'
        }
    ];

    const getColorHex = (colorName) => {
        const colorObject = calendarColors.find(color => color.color === colorName);
        return colorObject ? colorObject.hex : null;
    };

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setGlobalIsLoading(true));
            if (!calendarData) {
                try {
                    const res = await getCalendar('page-macro');
                    if (res) {
                        setFirstYear(res[0].year);
                        setLastYear(res[res.length - 1].year);
                        handleChangeCalendarData(res);
                    }
                } catch (error) {
                    // Handle errors as needed
                    console.error('Error fetching data:', error);
                }
            } else {
                setFirstYear(calendarData[0].year);
                setLastYear(calendarData[calendarData.length - 1].year);
                handleChangeCalendarData(calendarData);
            }
            const currentDate = new Date();
            const yearMonthString = currentDate.toLocaleString('default', { year: 'numeric', month: 'long' });
            const [monthString, yearString] = yearMonthString.split(" ");
            const currentMonth = monthString;
            const currentYear = parseInt(yearString);
            setCurrMonth(currentMonth);
            setCurrYear(currentYear);
            setSelectedCalendarFilters([
                { label: 'Splits', filter: 'splits', color: getColorHex('green') },
                { label: 'Dividends', filter: 'dividends', color: getColorHex('red') },
                { label: 'Sec Filings', filter: 'filingDates', color: getColorHex('blue') },
                { label: 'IPOs', filter: 'ipo', color: getColorHex('yellow') }
            ]);
        };
        fetchData();
    }, []);

    useEffect(() => {
        loading ? dispatch(setGlobalIsLoading(true)) : dispatch(setGlobalIsLoading(false));
    }, [loading]);

    useEffect(() => {
        if (currMonth && currYear) {
            setSelectedMonth(currMonth);
            setSelectedYear(currYear);
        };
    }, [currMonth, currYear]);

    useEffect(() => {
        if (calendarData && selectedMonth && selectedYear) {
            setLoading(false);
            const year = calendarData.find(year => year.year === selectedYear);
            const month = year.months.find(month => month.monthName === selectedMonth);
            setWeeksToDisplay([month.weeks.week1, month.weeks.week2, month.weeks.week3, month.weeks.week4, month.weeks.week5]);
        }
    }, [calendarData, selectedMonth, selectedYear]);

    useEffect(() => {
        if (activePage === 'guide') {
            history.push('/calendar');
            return;
        }
        if (activePage === 'companies') {
            setSelectedCalendarFilters([
                { label: 'Splits', filter: 'splits', color: getColorHex('green') },
                { label: 'Dividends', filter: 'dividends', color: getColorHex('red') },
                { label: 'Sec Filings', filter: 'filingDates', color: getColorHex('blue') },
                { label: 'IPOs', filter: 'ipo', color: getColorHex('yellow') }
            ]);
        } else if (activePage === 'macro')
            setSelectedCalendarFilters([
                { label: 'Labor Market', filter: 'laborMarket', color: getColorHex('green') },
                { label: 'Housing', filter: 'housing', color: getColorHex('red') },
                { label: 'Commodities', filter: 'commodities', color: getColorHex('blue') },
                { label: 'Monetary Policy', filter: 'monetaryPolicy', color: getColorHex('yellow') },
            ]);
        history.push(`/calendar/${activePage}`);
    }, [activePage]);

    useEffect(() => {
        setSelectedCalendarFiltersToDisplay(selectedCalendarFilters);
    }, [selectedCalendarFilters]);

    const CalendarFilters = () => {
        const rows = [];
        for (let i = 0; i < months.length; i += 4) {
            const row = [];
            for (let j = i; j < i + 4 && j < months.length; j++) {
                row.push(
                    <div key={months[j]} onClick={() => handleMonthClick(months[j])} className={months[j] === selectedMonth ? `calendar-datepicker-month calendar-datepicker-month-selected` : `calendar-datepicker-month`}>{months[j]}</div>
                );
            }
            rows.push(<div key={i} className='calendar-datepicker-row'>{row}</div>);
        }
        return <div className='calendar-datepicker-filters'>{rows}</div>;
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datepickerRef.current && !datepickerRef.current.contains(event.target)) {
                setIsDatepickerOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [datepickerRef]);


    const handleMonthClick = (month) => {
        setSelectedMonth(month);
    };

    const handleYearClick = (increment) => {
        if (increment && selectedYear + 1 > lastYear) return;
        else if (!increment && selectedYear - 1 < firstYear) return;
        setSelectedYear((prevYear) => prevYear + (increment ? 1 : -1));
    };

    const handleToggleCalendarFilters = (filter) => {
        const filterIdx = selectedCalendarFiltersToDisplay.findIndex(item => item.filter === filter.filter);
        if (filterIdx === -1) {
            setSelectedCalendarFiltersToDisplay([...selectedCalendarFiltersToDisplay, filter]);
        } else {
            setSelectedCalendarFiltersToDisplay((prevState) => prevState.filter((item) => item.filter !== filter.filter));
        }
    };

    const handleToggleOption = (option) => {
        if (option === 'guide') {
            setActivePage('guide')
        } else {
            setActivePage((prevOption) => {
                return option;
            });
        }
    };

    const getNavLocation = (label) => {
        return `/calendar/${label}`
    };

    const getGuideByActivePage = (activePage) => {
        switch (activePage) {
            case 'companies':
                return CalendarCompaniesEventGuide;
            case 'macro':
                return CalendarMacroGuide;
            default:
                return <></>;
        }
    };
    const GuideComponent = getGuideByActivePage(activePage);

    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow - Calendar</title>
                <meta name="description" content="Calendar – Economic Calendar monitors financial events, including dividend distributions, IPO plans, stock splits, and filing dates. It includes macro-economic events impacting financial markets, such as housing trends, monetary policy, labor market changes, and commodities-related events." />
                <link rel="canonical" href="https://www.portfollow.com/calendar" />
            </Helmet>

            <div className='active-page-container-macro noheader-media'>

                <ActivePageSelection
                    handleToggleOption={handleToggleOption}
                    pages={pages}
                    activePage={activePage}
                    getNavLocation={getNavLocation}
                />
            </div>

            {
                activePage === 'guide' ? (
                    <div className="calendar-main-container main-content-container">
                        <CalendarGuide />
                    </div>
                ) : (
                    loading ?
                        <>
                            <LoadingSpinner />
                        </> : <>
                            <div className="calendar-main-container card-container main-content-container">
                                <div className='calendar-header'>
                                    {selectedCalendarFilters && selectedCalendarFiltersToDisplay && <div className='flex space-between'>
                                        {selectedMonth && selectedYear &&
                                            <div ref={datepickerRef} className="calendar-datepicker flex space-between align-center">
                                                <div className='calendar-datepicker-item'>{selectedMonth.substring(0, 3)}</div>
                                                <div className='calendar-datepicker-item'>{selectedYear}</div>
                                                <div className='calendar-arrowdown' onClick={() => setIsDatepickerOpen(true)}>▲</div>
                                                {isDatepickerOpen &&
                                                    <div className='calendar-datepicker-container'>
                                                        <div className='calendar-datepicker-btn' onClick={() => setIsDatepickerOpen(false)}>✖</div>
                                                        <div className="calendar-datepicker-year-container flex space-between align-center">
                                                            <div
                                                                className='calendar-datepicker-year-btn'
                                                                onClick={() => handleYearClick(false)}
                                                                style={{ opacity: selectedYear - 1 < firstYear ? 0.5 : 1, transform: 'rotate(180deg)' }}
                                                            >
                                                                ➜
                                                            </div>
                                                            <div>{selectedYear}</div>
                                                            <div
                                                                className='calendar-datepicker-year-btn'
                                                                onClick={() => handleYearClick(true)}
                                                                style={{ opacity: selectedYear + 1 > lastYear ? 0.5 : 1 }}
                                                            >
                                                                ➜
                                                            </div>
                                                        </div>
                                                        <CalendarFilters />
                                                    </div>
                                                }
                                            </div>
                                        }
                                        <div className='flex center-center'>
                                            {
                                                selectedCalendarFilters.map((filter, idx) => (
                                                    <div
                                                        key={idx}
                                                        className={`calendar-filter ${!selectedCalendarFiltersToDisplay.includes(filter) ? 'calendar-filter-selected' : ''}`}
                                                        onClick={() => handleToggleCalendarFilters(filter)}
                                                        style={{ borderTop: `10px solid ${filter.color}` }}
                                                    >
                                                        {filter.label}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    }
                                </div>
                                {
                                    calendarData && calendarData.length && weeksToDisplay && weeksToDisplay.length &&
                                    <div className="calendar-container">
                                        <div className="calendar-table-header flex justify-center">
                                            <div>Monday</div>
                                            <div>Tuesday</div>
                                            <div>Wednesday</div>
                                            <div>Thursday</div>
                                            <div>Friday</div>
                                        </div>
                                        {weeksToDisplay.map((week, idx) => (
                                            <CalendarWeek
                                                key={idx}
                                                weekData={week}
                                                activePage={activePage}
                                                filters={selectedCalendarFiltersToDisplay}
                                                getColorHex={getColorHex}
                                            />
                                        ))}
                                    </div>
                                }
                            </div>
                        </>
                )
            }
            <div className="card card-container main-content-container">
                {/* <GuideComponent /> */}
                {getGuideByActivePage(activePage)}
            </div>
        </>
    );
}

export default Calendar;