import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './style/fund-playoff.scss';
import { userIcon } from '../../../shared/icons/icons';
import { useMediaQuery } from 'react-responsive';

// RETURN
// sum initialWorth from orderslist
// (initialWorth / 100,000) -1

// fund return 
// sum all initialWorthfrom all members 
//  ((initialWorth sum) / (number of members * 100,000)) -1

// to display:
// return * 100, add %

const FundPlayoffs = ({ fundMatchweek }) => {

    const isSmallScreen = useMediaQuery({ maxWidth: 920 });

    console.log('fundMatchweek:', fundMatchweek);

    const calculateReturn = (members) => {
        // console.log('members:', members);
        const totalInitialWorths = members.map(member => {
            const totalInitialWorth = member.ordersList.reduce((acc, order) => acc + order.initialWorth, 0);
            const cash = parseFloat(member.cash || 0);
            const coins = parseFloat(member.coins || 100000);
            return { totalInitialWorth, cash, coins };
        });

        const individualReturns = totalInitialWorths.map(({ totalInitialWorth, cash, coins }) => {
            return ((totalInitialWorth + cash) / coins) - 1;
        });

        const totalInitialWorthSum = totalInitialWorths.reduce((acc, { totalInitialWorth, cash }) => acc + totalInitialWorth + cash, 0);
        const totalCoins = totalInitialWorths.reduce((acc, { coins }) => acc + coins, 0);
        const fundReturn = (totalInitialWorthSum / totalCoins) - 1;

        return {
            individualReturns: individualReturns,
            fundReturn: fundReturn
        };
    };

    const [homeReturns, setHomeReturns] = useState([]);
    const [awayReturns, setAwayReturns] = useState([]);
    const [homeFundReturn, setHomeFundReturn] = useState(null);
    const [awayFundReturn, setAwayFundReturn] = useState(null);

    const [combinedMembersReturn, setCombinedMembersReturn] = useState([]);

    useEffect(() => {
        if (fundMatchweek) {

            const homeMembers = fundMatchweek.home?.members ?? [];
            const awayMembers = fundMatchweek.away?.members ?? [];

            const homeCalculation = calculateReturn(homeMembers);
            const awayCalculation = calculateReturn(awayMembers);

            setHomeReturns(homeCalculation.individualReturns);
            setAwayReturns(awayCalculation.individualReturns);
            // console.log('home:', homeCalculation);
            setHomeFundReturn(homeCalculation.fundReturn);
            setAwayFundReturn(awayCalculation.fundReturn);
        }

    }, [fundMatchweek]);

    useEffect(() => {
        const combinedMembers = [];
        const memberSet = new Set();

        homeFundMembers.forEach((member, idx) => {
            const weeklyReturn = homeReturns[idx];
            if (member && member.username && weeklyReturn != null) {
                const memberKey = `${member.username}-home-${weeklyReturn}`;
                if (!memberSet.has(memberKey)) {
                    combinedMembers.push({
                        name: member.username,
                        memberPngUrl: member.memberPngUrl,
                        weeklyReturn,
                        type: 'home',
                        level: member.level
                    });
                    memberSet.add(memberKey);
                }
            }
        });

        // Combine away members if available
        if (fundMatchweek.away && Object.keys(fundMatchweek.away).length > 0) {
            awayFundMembers.forEach((member, idx) => {
                const weeklyReturn = awayReturns[idx];
                if (member && member.username && weeklyReturn != null) {
                    const memberKey = `${member.username}-away-${weeklyReturn}`;
                    if (!memberSet.has(memberKey)) {
                        combinedMembers.push({
                            name: member.username,
                            memberPngUrl: member.memberPngUrl,
                            weeklyReturn,
                            type: 'away',
                            level: member.level
                        });
                        memberSet.add(memberKey);
                    }
                }
            });
        }

        // Sort combined array by weeklyReturn in descending order
        combinedMembers.sort((a, b) => b.weeklyReturn - a.weeklyReturn);
        setCombinedMembersReturn(combinedMembers);

    }, [homeFundReturn, awayFundReturn])

    // console.log('date:', convertDateToReadableStr(fundMatchweek.startDate))

    const formatDateToReadableString = (dateString) => {
        // Parse the date string into a Date object
        const date = new Date(dateString);
        const dayOptions = { weekday: 'long' };
        const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
        const dayName = date.toLocaleDateString('en-US', dayOptions);
        const time = date.toLocaleTimeString('en-US', timeOptions);
        const readableString = `${dayName}, ${time}`;
        return readableString;
    };

    const homeFundMembers = [...fundMatchweek.home.members].sort((a, b) => parseFloat(b.return) - parseFloat(a.return));
    // const awayFundMembers = [...fundMatchweek.away.members].sort((a, b) => parseFloat(b.return) - parseFloat(a.return));
    // Check if fundMatchweek.away is an empty object
    const awayMembers = fundMatchweek.away && Object.keys(fundMatchweek.away).length === 0 ? [] : fundMatchweek.away.members;
    const awayFundMembers = [...awayMembers].sort((a, b) => parseFloat(b.return) - parseFloat(a.return));

    // console.log('homeFundMembers:', homeFundMembers);
    // console.log('awayFundMembers:', awayFundMembers);

    const history = useHistory();
    const handleTitleClick = (name) => {
        history.push(`/profile/portfolios/${name}`);
    };

    const BigScreenReturnTable = () => {
        return (
            <>
                {
                    homeFundMembers.map((member, idx) => (
                        <div key={idx} className="fund-playoff-item flex space-between">
                            <div className='fund-playoff-member-item flex'>
                                <div className='fund-members-img fund-playoff-member-img'
                                    style={{ borderColor: member.level === 'bronze' ? 'brown' : member.level }}>
                                    {userIcon}
                                </div>
                                <div
                                    className='fund-members-username flex align-center'
                                    onClick={() => { handleTitleClick(member.username) }}
                                >{member.username}
                                </div>
                            </div>
                            <div className='flex'>
                                <div
                                    className='fund-playoff-return'
                                    style={{ marginRight: '5px' }}
                                >
                                    {(homeReturns[idx] * 100).toFixed(2)}%
                                </div>
                                <div
                                    className='fund-playoff-return'
                                    style={{ marginLeft: '5px' }}
                                >
                                    {
                                        (fundMatchweek.away && Object.keys(fundMatchweek.away).length > 0)
                                            ? (awayReturns[idx] * 100).toFixed(2) + '%'
                                            : 'N/A'
                                    }
                                </div>
                            </div>
                            <div className='fund-playoff-member-item flex flex-end'>
                                <div
                                    className='fund-members-username flex align-center'
                                    onClick={() => { handleTitleClick(awayFundMembers[idx].username) }}
                                >
                                    {awayFundMembers[idx] && awayFundMembers[idx].username ? awayFundMembers[idx].username : ''}
                                </div>
                                {
                                    fundMatchweek.away && Object.keys(fundMatchweek.away).length > 0 && (
                                        <div
                                            className='fund-members-img fund-playoff-member-img-reversed'
                                            style={{ borderColor: member.level === 'bronze' ? 'brown' : member.level }}
                                        >{awayFundMembers[idx] ? userIcon : ''}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    ))
                }
            </>
        );
    };

    const SmallScreenReturnTable = () => {
        return (
            <>
                {
                    combinedMembersReturn.map((member, idx) => (
                        <div key={idx} className="fund-playoff-item flex space-between">
                            {member.type === 'home' ? (
                                <>
                                    <div className='fund-playoff-member-item flex'>
                                        <div className='fund-members-img fund-playoff-member-img'
                                            style={{ borderColor: member.level === 'bronze' ? 'brown' : member.level }}>
                                            {userIcon}
                                        </div>
                                        <div
                                            className='fund-members-username flex align-center'
                                            onClick={() => { handleTitleClick(member.name) }}
                                        >{member.name}
                                        </div>
                                    </div>
                                    <div className='fund-playoff-return' style={{ marginRight: '5px' }}>
                                        {(member.weeklyReturn * 100).toFixed(2)}%
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <div className='fund-playoff-return' style={{ marginLeft: '5px' }}>
                                            {(member.weeklyReturn * 100).toFixed(2)}%
                                        </div>
                                        <div className='fund-playoff-member-item flex flex-end'>
                                            <div
                                                className='fund-members-username flex align-center'
                                                onClick={() => { handleTitleClick(member.name) }}
                                            >
                                                {member.name}
                                            </div>
                                        </div>
                                        <div className='fund-members-img fund-playoff-member-img-reversed'
                                            style={{ borderColor: member.level === 'bronze' ? 'brown' : member.level }}>
                                            {userIcon}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))
                }
            </>

        );
    };

    const ReturnTable = () => {
        return (
            <>
                {isSmallScreen ? <SmallScreenReturnTable /> : <BigScreenReturnTable />}
            </>
        );
    };

    return (
        <>
            <div className="card-container mb20 flex space-between">
                <div className='flex space-between' style={{ width: '300px' }}>
                    <div className="flex">
                        <div className='fund-crest fund-playoff-img' style={{ width: '100px', height: '100px' }}>
                            <img className='fund-crest-img' src={fundMatchweek.home.pngUrl} style={{ width: '100px', height: '100px' }} />
                        </div>
                        <div className='flex column space-evenly'>
                            <h2>{fundMatchweek['home'].name}</h2>
                            <h3 className='m0auto'>
                                {homeFundReturn !== null ? (homeFundReturn * 100).toFixed(2) + '%' : 'N/A'}
                            </h3>.
                        </div>
                    </div>
                </div>
                {
                    !isSmallScreen &&
                    <div className='flex column space-between'>
                        <div className='m0auto'>{fundMatchweek.matchweekType ? fundMatchweek.matchweekType : ''}</div>
                        <div className='m0auto'>{fundMatchweek.startDate ? formatDateToReadableString(fundMatchweek.startDate) : 'fundMatchweek.startDate'}</div>
                        {/* <div className='m0auto'>{fundMatchweek.matchweekLen}</div> */}
                        <div className='m0auto'>Matchweek</div>
                    </div>
                }
                <div className='flex flex-end' style={{ width: '300px' }}>
                    {
                        fundMatchweek.away && Object.keys(fundMatchweek.away).length > 0 && (
                            <div className="flex column space-evenly">
                                <h2>{fundMatchweek['away'].name}</h2>
                                {/* <h3 className='m0auto'>{fundMatchweek['away'].return}</h3> */}
                                <h3 className='m0auto'>
                                    {awayFundReturn !== null ? (awayFundReturn * 100).toFixed(2) + '%' : 'N/A'}
                                </h3>
                            </div>
                        )
                    }
                    <div className='fund-crest' style={{ width: '100px', height: '100px' }}>
                        {

                            fundMatchweek.away && Object.keys(fundMatchweek.away).length > 0 && (
                                fundMatchweek.away.pngUrl &&
                                <img
                                    className='fund-crest-img fund-playoff-img-reversed' src={fundMatchweek.away.pngUrl}
                                    style={{ width: '100px', height: '100px' }}
                                />
                            )
                        }
                    </div>

                </div>
            </div>
            <div className="card-container mb20">
                <ReturnTable />
            </div>
        </>
    );
}

export default FundPlayoffs;




// [
//     {
//         "coins": "100000",
//         "username": "@",
//         "ordersList": [
//             {
//                 "symbol": "AMZN",
//                 "amount": 470,
//                 "buyDate": "2023-12-12",
//                 "price": 147,
//                 "orderId": "Yd05SJB6XxHt0kxAHlt8",
//                 "initialWorth": 84952.5
//             },
//             {
//                 "symbol": "ICE",
//                 "amount": 3,
//                 "buyDate": "2023-12-07",
//                 "price": 113.32,
//                 "orderId": "qC8VnZ7wCT0vGvNo3Tfc",
//                 "initialWorth": 409.0199890136719
//             }
//         ],
//         "cash": "250"
//     },
//     {
//         "coins": "100000",
//         "username": "CryptoConnoisseurInsight",
//         "ordersList": [
//             {
//                 "symbol": "PANW",
//                 "amount": 70,
//                 "orderId": "1DMpajybN2",
//                 "price": "187.02",
//                 "buyDate": "2023-05-18",
//                 "initialWorth": 22512.000427246094
//             },
//             {
//                 "symbol": "ECL",
//                 "amount": 161,
//                 "orderId": "ZG6kHatQGc",
//                 "price": "195.66",
//                 "buyDate": "2023-12-21",
//                 "initialWorth": 37723.909606933594
//             }
//         ],
//     },
//     {
//         "coins": "100000",
//         "username": "TradeMastermind",
//         "ordersList": [
//             {
//                 "symbol": "EXPD",
//                 "amount": 347,
//                 "orderId": "Qj6UnceY7D",
//                 "price": "126.61",
//                 "buyDate": "2024-01-17",
//                 "initialWorth": 40932.11968231201
//             },
//             {
//                 "symbol": "QCOM",
//                 "amount": 211,
//                 "orderId": "DTSggxCr0v",
//                 "price": "145.93",
//                 "buyDate": "2023-12-27",
//                 "initialWorth": 44385.96012878418
//             }
//         ],
//     }
// ]




// {
//     "away": {
//         "members": [
//             {
//                 "coins": "100,000",
//                 "username": "SpaceArtEnthusiast",
//                 "ordersList": [
//                     {
//                         "symbol": "HSIC",
//                         "amount": 46,
//                         "orderId": "yVh5GHSFu3",
//                         "price": "75.38",
//                         "buyDate": "2023-06-06",
//                         "initialWorth": 3369.5
//                     },
//                     {
//                         "symbol": "ABT",
//                         "amount": 30,
//                         "orderId": "jk1uRrCqqD",
//                         "price": "103.13",
//                         "buyDate": "2023-09-14",
//                         "initialWorth": 3088.7999725341797
//                     }
//                 ],
//             },
//             {
//                 "coins": "100,000",
//                 "username": "fundmember",
//                 "ordersList": [
//                     {
//                         "symbol": "NVDA",
//                         "amount": 90,
//                         "buyDate": "2024-02-14",
//                         "price": 722.09,
//                         "orderId": "0vpq2yKNq0c0NdDiY2zE",
//                         "initialWorth": 85847.39868164062
//                     },
//                     {
//                         "symbol": "CE",
//                         "amount": 90,
//                         "buyDate": "2024-01-11",
//                         "price": 147.78,
//                         "orderId": "hhbUNfcvpLrzllq2f4cU",
//                         "initialWorth": 13988.699340820312
//                     },
//                 ],
//             },
//             {
//                 "coins": "100,000",
//                 "username": "PixelPioneer128K",
//                 "ordersList": [
//                     {
//                         "symbol": "WY",
//                         "amount": 3036,
//                         "orderId": "pul3lfTbE2",
//                         "price": "32.66",
//                         "buyDate": "2023-07-06",
//                         "initialWorth": 94449.96185302734
//                     },
//                     {
//                         "symbol": "PEG",
//                         "amount": 73,
//                         "orderId": "GzyLN9GKrL",
//                         "price": "62.43",
//                         "buyDate": "2023-11-07",
//                         "initialWorth": 5485.95011138916
//                     }
//                 ],
//             }
//         ],
//     },
//     "home": {
//         "members": [
//             {
//                 "coins": "100,000",
//                 "username": "@",
//                 "ordersList": [
//                     {
//                         "symbol": "AMZN",
//                         "amount": 465,
//                         "buyDate": "2023-12-12",
//                         "price": 147,
//                         "orderId": "Yd05SJB6XxHt0kxAHlt8",
//                         "initialWorth": 85164.74716186523
//                     },
//                     {
//                         "symbol": "MSFT",
//                         "amount": 34,
//                         "buyDate": "2024-01-09",
//                         "price": 374.35,
//                         "orderId": "YLJL5NttYolpkRsKbiSw",
//                         "initialWorth": 14587.360290527344
//                     },
//                 ],
//             },
//             {
//                 "coins": "100,000",
//                 "username": "CryptoConnoisseurInsight",
//                 "ordersList": [
//                     {
//                         "symbol": "PANW",
//                         "amount": 70,
//                         "orderId": "1DMpajybN2",
//                         "price": "187.02",
//                         "buyDate": "2023-05-18",
//                         "initialWorth": 21816.200256347656
//                     },
//                     {
//                         "symbol": "FANG",
//                         "amount": 24,
//                         "orderId": "1fg2O39FEl",
//                         "price": "125.32",
//                         "buyDate": "2023-06-23",
//                         "initialWorth": 4717.43994140625
//                     }
//                 ],
//             },
//             {
//                 "coins": "100,000",
//                 "username": "TradeMastermind",
//                 "ordersList": [
//                     {
//                         "symbol": "EXPD",
//                         "amount": 353,
//                         "orderId": "Qj6UnceY7D",
//                         "price": "126.61",
//                         "buyDate": "2024-01-17",
//                         "initialWorth": 41555.1604309082
//                     },
//                     {
//                         "symbol": "DUK",
//                         "amount": 35,
//                         "orderId": "xC9HQ28Qjy",
//                         "price": "98.27",
//                         "buyDate": "2023-05-12",
//                         "initialWorth": 3653.299903869629
//                     }
//                 ],
//             }
//         ],
//     },
// }