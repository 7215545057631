import React, { useState } from "react";
import PeriodDropdownSelect from "../../shared/dropdown-select/period-dropdown-select";
import SingleItemTechnicalContent from "./single-item-technical-content/single-item-technical-content";
import './single-item-technical.scss';

const SingleItemTechnical = ({ technicalData, symbol, type }) => {

    const [activeFilter, setActiveFilter] = useState('oneWeek');

    const handleToggleFilter = (option) => {
        setActiveFilter((prevOption) => {
            return option.value;
        });
    };

    const periodOptions = [
        { value: 'oneWeek', label: '1 Week' },
        { value: 'oneMonth', label: '1 Month' },
        { value: 'sixMonth', label: '6 Months' },
        { value: 'currentYear', label: 'YTD' },
        { value: 'oneYear', label: '1 Year' },
        { value: 'fiveYear', label: '5 Years' },
        { value: 'max', label: 'Max' },
    ];

    return (
        <>
            {/* <SingleItemTechnicalFilters
                activeFilter={activeFilter}
                handleToggleFilter={handleToggleFilter}
            /> */}
            <div className="single-item-filters-container main-content-container">
                <PeriodDropdownSelect
                    currFilter={activeFilter}
                    onChangeFilter={handleToggleFilter}
                    optionsToDisplay={periodOptions}
                />
            </div>
            <SingleItemTechnicalContent
                table={technicalData.table}
                priceOverTime={technicalData.priceOverTime}
                barcharts={technicalData.barCharts}
                activeFilter={activeFilter}
                symbol={symbol}
                type={type}
            />
        </>
    );
}

export default SingleItemTechnical;