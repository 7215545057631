import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setGlobalIsLoading } from "../../store/loading/loading-actions";
import './error-page.scss';

const ErrorPage = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    dispatch(setGlobalIsLoading(false));

    const handleNavigateToHome = () => {
        history.push('/');
    };

    return (
        <div className="error-page flex column center-center" style={{ height: '100vh' }}>
            <h2 className="error-page-p">404</h2>
            <p className="error-page-p">
                Oops, it looks like we couldn't find any results for what you're looking for.
                <br />
                Please try again later.
            </p>
            <div className="btn" onClick={handleNavigateToHome} >
                Back to homepage
            </div>

        </div>
    );
}

export default ErrorPage;