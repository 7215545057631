import { useState, useEffect } from "react";
import { arrowDownIcon, arrowUpIcon, dropdownIcon, infoIcon } from "../../../../shared/icons/icons";
import Select from 'react-select';
import './homepage-barchart-item.scss';
import HomepageBarChart from "./homepage-bar-chart";
import { DropdownIndicator, customStyles as originalCustomStyles, customStyles } from "../../../../shared/dropdown-styles";
import { useMediaQuery } from 'react-responsive';
import InfoPopup from "../../../../shared/info-popup/info-popup";

const HomepageBarchartItem = ({ barItem, type }) => {

    const mediumScreen = useMediaQuery({ maxWidth: 1100 });
    const smallScreen = useMediaQuery({ maxWidth: 920 });

    const [barCharData, setBarChartData] = useState(null);

    const [selectedMinMax, setSelectedMinMax] = useState('max');
    const [selectedSector, setSelectedSector] = useState('technology');
    const [selectedPeriod, setSelectedPeriod] = useState('oneWeek');
    const [sectorOptions, setSectorOptions] = useState([]);
    const [showSectorOptions, setShowSectorOptions] = useState(false);

    useEffect(() => {
        if (barItem && barItem[selectedMinMax]) {
            const keys = Object.keys(barItem[selectedMinMax]);
            const sectorOptions = keys.map((key) => ({
                value: key,
                label: camelCaseToNormalString(key),
            }));
            setSectorOptions(sectorOptions);
        }
    }, [barItem, selectedMinMax, selectedPeriod]);

    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setScreenSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // useEffect(() => {
    //     if (screenSize.width <= 920) {
    //         setBarChartThickness(10);
    //     } else if (screenSize.width <= 1100) {
    //         setBarChartThickness(12);
    //     } else {
    //         setBarChartThickness(14);
    //     }
    // }, [screenSize]);

    const [barChartThickness, setBarChartThickness] = useState(25);

    useEffect(() => {
        const maxItemsToShow = 5;
        const sortedData = [...barItem[selectedMinMax][selectedSector][selectedPeriod]];
        sortedData.sort((a, b) => b.value - a.value);
        
        const labels = [];
        const values = [];
        const backgroundColors = [];

        sortedData.slice(0, maxItemsToShow).forEach((item) => {
            labels.push(item.symbol);
            values.push(item.value);
            backgroundColors.push(item.value > 0 ? '#5c9ead' : '#e39774');
        });

        
        const datasets = {
            labels: labels,
            datasets: [
                {
                    label: 'homepage-bar',
                    barThickness: barChartThickness,
                    // barPercentage: 100,
                    data: values,
                    backgroundColor: backgroundColors,
                    borderColor: backgroundColors,
                    borderWidth: 0,
                    fill: false,
                },
            ],
        };

        setBarChartData(datasets);

    }, [selectedMinMax, selectedSector, selectedPeriod, barItem]);

    const onHandleSectorsMouseEnter = () => {
        setShowSectorOptions(true);
    };

    const onHandleSectorsMouseLeave = () => {
        setShowSectorOptions(false);
    };

    const periodOptions = [
        { value: 'oneWeek', label: '1W' },
        { value: 'oneMonth', label: '1M' },
        { value: 'sixMonth', label: '6M' },
        { value: 'currentYear', label: 'YTD' },
        { value: 'oneYear', label: '1Y' },
        { value: 'fiveYear', label: '5Y' },
        { value: 'max', label: 'MAX' }
    ];

    const maxMinOptions = [
        { value: 'max', label: <>{arrowUpIcon}</> },
        { value: 'min', label: <>{arrowDownIcon}</> }
    ];

    const handleTogglePeriod = (filter) => {
        setSelectedPeriod(filter);
    };

    const handleToggleMinMax = () => {
        setSelectedMinMax((prev) => (prev === 'max' ? 'min' : 'max'));
    };

    const periodFilters = periodOptions.map((period, idx) => (
        <div
            key={idx}
            className={`homepage-barcharts-period-filters btn homepage-barchart-period-filter ${selectedPeriod === period.value ? 'btn-selected' : ''}`}
            onClick={() => handleTogglePeriod(period.value)}
        >
            {period.label}
        </div>
    ));

    const minMaxFilter = (
        <div className={`homepage-barcharts-period-filters btn ${selectedMinMax === 'max' ? 'btn-selected' : 'btn-negative-selected'}`} onClick={handleToggleMinMax}>
            {selectedMinMax === 'max' ? maxMinOptions[0].label : maxMinOptions[1].label}
        </div>
    );

    const camelCaseToNormalString = (camelCaseStr) => {
        return camelCaseStr
            .replace(/([A-Z])/g, ' $1')
            .trim()
            .replace(/^\w/, (c) => c.toUpperCase());
    };

    const handleChangeSector = (sector) => {
        setSelectedSector(sector.value);
    };


    const customHomepageStyles = {
        control: (provided) => ({
            ...provided,
            display: 'none',
        }),
        menu: (provided) => ({
            ...provided,
            width: '200px',
            marginTop: '20px',
            left: '70%',
            transform: 'translateX(-50%)',
            borderRadius: '20px'
        }),
        menuList: (provided) => ({
            ...provided,
            marginTop: '0',
            paddingTop: '0',
            paddingBottom: '0',
            height: '170px', // NEW HEIGHT - CAN REMOVE
            maxHeight: '120px',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                width: '7px',
                height: '2px',
                backgroundColor: 'inherit',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#f1f6fc',
                height: '2px',
                borderRadius: '12px',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'start',
            padding: '10px',
            backgroundColor: '#ffffff',
            color: '#484848',
            fontWeight: state.isSelected ? '900' : '500',
            fontSize: '12px',
            fontFamily: 'Poppins-Regular',
            borderBottom: state.isFocused && !state.isLast ? '2px solid #EEEEEE' : 'none',
            '&:not(:last-child)': {
                borderBottom: '2px solid #EEEEEE',
            },
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#f1f6fc'
            },
        }),
    };

    const [showInfoModal, setShowInfoModal] = useState(false);
    const [selectedModal, setSelectedModal] = useState(null);

    const handleInfoClick = (type) => {
        setSelectedModal(type);
        setShowInfoModal(true);
    };


    return (
        <>
            {
                barItem &&
                barCharData &&
                <div className="homepage-barchart-item card-container" style={{ height: type === 'return' ?'300px': '200px', width: '330px' }}>
                    {type === 'return' &&
                    <h2 className="mb10">Leading Stocks</h2>
                    }
                    <div className="flex space-between align-center">
                        <div className="flex">
                            <h3 className="homepage-barchart-title ">{type === 'return' ? 'Return' : 'Sharpe'}</h3>
                            {
                                <div
                                    className='card-container-info-btn btn btn-border flex center-center'
                                    onClick={() => handleInfoClick(type)}
                                    style={{ height: 'fit-content', padding: '4px', marginRight: '10px' }}
                                >
                                    {infoIcon}
                                </div>
                            }
                            {
                                showInfoModal &&
                                selectedModal &&
                                <InfoPopup
                                    type={selectedModal}
                                    setShowInfoModal={setShowInfoModal}
                                />
                            }
                        </div>
                        <div className=" flex flex-end">{periodFilters}</div>
                    </div>

                    <div
                        className="flex space-between"
                        style={{ width: '100% !important' }}>
                        <div className='homepage-dropdown-select-filter-item'

                            onMouseEnter={onHandleSectorsMouseEnter}
                            onMouseLeave={onHandleSectorsMouseLeave}
                        >
                            {
                                sectorOptions &&
                                <Select
                                    styles={customHomepageStyles}
                                    components={{ DropdownIndicator }}
                                    options={sectorOptions}
                                    value={selectedSector}
                                    onChange={handleChangeSector}
                                    menuIsOpen={showSectorOptions}
                                    hideSelectedOptions={false}
                                />
                            }
                            <div
                                className="homepage-dropdown-label dropdown-label flex space-between align-center">
                                {camelCaseToNormalString(selectedSector)}
                                <span className='dropdown-indicator flex center-center'>{dropdownIcon}</span>
                            </div>
                        </div>
                        <div className="flex flex-end">{minMaxFilter}</div>
                    </div>
                    {<HomepageBarChart chartData={barCharData} type={'homepage-bar'} />}
                </div>
            }
        </>

    );
}

export default HomepageBarchartItem;