import { useDispatch } from "react-redux";
import { setGlobalIsLoading } from "../../store/loading/loading-actions";

const TermsOfService = () => {

    const dispatch = useDispatch();
    dispatch(setGlobalIsLoading(false));

    const TermsOfServiceContent = [
        {
            content: ['Last updated: June 2024']
        },
        {
            title: '1. Welcome to PortFollow',
            content: [
                'PortFollow Ltd. ("we," "us," or "our") provides a web platform enabling individuals to disclose financial data related to the capital market ("Platform"). These Terms of Service ("Terms") govern your access and use of the Platform and services available (collectively, the "Services"). By using the Services, you agree to these Terms and any additional guidelines provided. Please read these Terms carefully. Your continued use of the Services following any changes indicates acceptance of the amended Terms. If you disagree, do not use the Services. If registering on behalf of an entity ("Customer"), you represent authority to bind the Customer to these Terms.',
                'Subject to these Terms and Service Agreement (if applicable), we grant non-exclusive access to the Services for the Customer\'s internal business purposes. Acknowledge that the Platform is for experimental purposes only to gather feedback from users, and it should not be seen as binding. We do not charge any fees for the use of our services. Data on our platform may not reflect the most current or accurate data. No guarantees or warranties are provided, and we disclaim liability for changes. Use of Services is void where prohibited by law. You represent (a) truthful, accurate registration information; (b) maintenance of accurate information; (c) minimum age of 18 and ability to form a binding contract; (d) no violation of applicable laws; (e) compliance with laws, regulations, and these Terms throughout use. Ensure compliance with applicable laws, rules, and regulations.'
            ]
        },
        {
            title: '2. Account Registration',
            content: [
                '2.1. To fully use the Services, a registered account with a username and chosen password is required. Provide all requested registration information. PortFollow may refuse to open an account or limit users at its discretion.',
                '2.2. Notify us immediately of any unauthorized account use. You are solely responsible for account security and activity.'
            ]
        },
        {
            title: '3. Termination of Account',
            content: [
                '3.1. PortFollow may suspend or terminate accounts with notice or immediately for violations or harmful behavior. Termination prevents rejoining without permission. Services may be modified or discontinued at any time.',
                '3.2. We may investigate violations or inappropriate behavior and cooperate with law enforcement.',
                '3.3. You may request account termination by email. Termination does not affect obligations under these Terms.'
            ]
        },
        {
            title: '4. Content',
            content: [
                '4.1. Certain content may be available through the Services ("Content"). We do not endorse any Content and disclaim liability for it.'
            ]
        },
        {
            title: '5. User Content',
            content: [
                '5.1. PortFollow is not responsible for User Content accuracy, safety, or infringement. You waive rights or remedies against us regarding User Content.',
                '5.2. The platform is for experimental purposes, and we may refuse, display, or delete any User Content. You are solely responsible for User Content, ensuring compliance with applicable laws.'
            ]
        },
        {
            title: '6. User Content Restrictions',
            content: [
                '6.1. Agree not to submit Content violating rights, infringing, illegal, or inappropriate.',
                '6.2. You may not decipher, decompile, disable security features, use for personal or commercial endeavors, use automated processes, access others accounts without permission, disable Services, or violate these Terms or applicable law.',
            ]
        },
        {
            title: '7. Use Restrictions',
            content: [
                '7.1. You may not decipher, decompile, disable security features, use for personal or commercial endeavors, use automated processes, access others accounts without permission, disable Services, or violate these Terms or applicable law.'
            ]
        },
        {
            title: '8. Intellectual Property',
            content: [
                '8.1. PortFollow owns Services content. Except as permitted, you may not use, reproduce, or alter content.',
                '8.2. You own User Content. By submitting User Content, you grant us the right to use, modify, and decompile it for Service provision.'
            ]
        },
        {
            title: '9. Copyright',
            content: [
                '9.1. We do not infringe upon third-party rights. Notify us of infringing User Content, and we may remove it. In the interest of upholding copyright integrity, users are reminded of their responsibility to avoid infringement. If you believe that the platform may inadvertently infringe upon any copyrights, we encourage you to clarify and bring such matters to our attention. Please send copyright infringement notices to portfollow.web@gmail.com. We are committed to addressing and resolving any potential copyright concerns in a prompt and cooperative manner.'
            ]
        },
        {
            title: '10. Confidential Information',
            content: [
                '10.1. Disclosing non-public, confidential, or proprietary information (\"Confidential Information\") is subject to restrictions. Receiving Party shall not disclose or use Confidential Information except for Service purposes. Confidentiality survives termination.'
            ]
        },
        {
            title: '11. Disclaimers and Disclaimer of Warranty',
            content: [
                '11.1. Information on the Platform: The information on the Platform is provided for informational purposes only. While we strive to ensure accuracy, you acknowledge and agree that the data on our platform may not always be the most current or accurate. We disclaim any guarantees or warranties, express or implied, regarding the Services and the Platform.',
                '11.2. Use at Your Own Risk: Your use of the Services is at your own risk. We do not warrant that the Services will meet your requirements or that the operation of the Platform will be uninterrupted or error-free. Any reliance on the information obtained through the Platform is solely at your discretion and risk.',
                '11.3. No Guarantees or Warranties: We expressly disclaim any guarantees or warranties, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.',
                '11.4. Educational and Informational Purposes Only: PortFollow Ltd. and its Services, including PortFollow, are for educational and informational purposes only. No real money is involved. The Platform does not provide personalized financial advice and should not be seen as an invitation to invest in any specific financial products. All activities on the Platform are simulated and use virtual currencies or points. Users should seek professional financial advice for real-world investing. We disclaim all liability for any losses or damages, including but not limited to financial loss, personal injury, or any adverse outcomes. By using the Services, you agree to these terms and acknowledge that you are solely responsible for your actions.'
            ]
        },
        {
            title: '12. Limitation of Liability',
            content: [
                '12.1. Our Responsibility: We are not responsible for errors, interruptions, or unauthorized access. We disclaim liability for damages arising from the use of Services. In no event shall our liability exceed the amount paid by customers/users in the six months preceding the claim.',
                '12.2. Exclusion of Consequential Damages: In no event shall PortFollow be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your access to or use of or inability to access or use the Services, (b) any conduct or content of any third party on the Services, or (c) unauthorized access, use, or alteration of your transmissions or content.'
            ]
        },
        {
            title: '13. Assumption of Responsibility',
            content: [
                '13.1. User Acknowledgment: By using the Services, you acknowledge that you are solely responsible for evaluating the accuracy, completeness, and usefulness of any information or content provided through the Platform.',
                '13.2. No Liability for Changes: We shall not be liable for any changes, discontinuation, or unavailability of the Services. We reserve the right to modify, suspend, or discontinue any aspect of the Services at any time, without notice.'
            ]
        },
        {
            title: '14. Third-Party Content',
            content: [
                '14.1. We make no promises regarding third-party content. Use third-party websites at your own risk.'
            ]
        },
        {
            title: '15. Notices',
            content: [
                '15.1. Notices may be sent by registered mail or email. Notice is deemed received one business day after delivery by courier or email.'
            ]
        },
        {
            title: '16. Modification of Terms',
            content: [
                '16.1. We reserve the right to modify the Terms of Service at any time without prior notice. Any changes will be effective immediately upon posting. Users are encouraged to regularly review the terms to stay informed about any modifications. Continued use of the Services after modifications indicates acceptance of the updated terms.'
            ]
        },
        {
            title: '17. Governing Law and Dispute Resolution',
            content: [
                '17.1. Governing law is the State of Israel. Unenforceable provisions shall be replaced. These Terms constitute the entire agreement. We may assign rights without notice. You shall not assign rights. In case of disputes, the parties agree to attempt to resolve them through good faith negotiations. If negotiations fail, both parties agree to submit to the exclusive jurisdiction of the courts in the State of Israel.'
            ]
        },
        {
            title: '18. Severability',
            content: [
                '18.1. If any provision of the terms is found to be unenforceable or invalid under applicable law, it won\'t render the entire agreement unenforceable or invalid. Instead, the invalid provision will be severed, and the remaining terms will continue to be valid and enforceable.'
            ]
        },
        {
            title: '19. Waiver',
            content: [
                '19.1. Failure to enforce any right or provision of the terms doesn\'t constitute a waiver of that right or provision. Waivers, if any, must be made in writing and signed by an authorized representative of PortFollow.'
            ]
        },
        {
            title: '20. Assignment',
            content: [
                '20.1. Users cannot assign or transfer their rights and obligations under the terms without the company\'s written consent. However, PortFollow can assign or transfer its rights and obligations without restriction.'
            ]
        },
        {
            title: '21. Entire Agreement',
            content: [
                '21.1. The terms, along with any additional guidelines provided, constitute the entire agreement between the user and PortFollow, replacing any prior agreements or understandings, whether oral or written, regarding the subject matter.'
            ]
        },
        {
            title: '22. Data Privacy',
            content: [
                'We take data privacy seriously. Your personal information is handled responsibly, and we are committed to complying with applicable data protection laws.'
            ]
        },
        {
            title: '23. Security Measures',
            content: [
                'To ensure the security of your information, we employ industry-standard security measures. However, we advise users to take precautions on their end, such as using strong passwords and safeguarding account information.'
            ]
        },
        {
            title: '24. Service Availability',
            content: [
                'While we strive for uninterrupted service, occasional disruptions may occur due to maintenance or unforeseen circumstances. We appreciate your understanding and assure you that we work diligently to minimize any downtime.'
            ]
        },
        {
            title: '25. User Feedback',
            content: [
                'Your feedback is invaluable. We encourage users to share their thoughts on the platform\'s functionality and any improvements they may suggest. Your insights contribute to our ongoing efforts to enhance the user experience.'
            ]
        },
        {
            title: '26. Service Enhancements',
            content: [
                'We are continually working to improve our services. Periodically, you may notice updates and new features designed to provide a more seamless and efficient user experience. Your understanding during these enhancements is greatly appreciated.'
            ]
        },
        {
            title: '27. External Links',
            content: [
                'The platform may contain links to external websites. While we strive to link to reputable sources, we cannot endorse or guarantee the content on these external sites. Users are encouraged to use caution and review the terms of those external websites.'
            ]
        },
        {
            title: '28. Regulatory Compliance',
            content: [
                'We operate in compliance with applicable laws and regulations. Users are responsible for ensuring their activities on the platform align with the legal requirements of their respective jurisdictions.'
            ]
        },
        {
            title: '29. Support Responsiveness',
            content: [
                'Our support team is here to assist you. While we aim to respond promptly, please understand that the resolution time for certain issues may vary based on complexity. Your patience is appreciated as we work towards a satisfactory resolution.'
            ]
        },
        {
            title: '30. Community Guidelines',
            content: [
                'We encourage a positive and respectful community atmosphere. Users are expected to adhere to community guidelines, promoting constructive interactions and maintaining a welcoming environment for all.'
            ]
        },
        {
            title: '31. Accessibility',
            content: [
                'We strive to make our platform accessible to all users. If you encounter accessibility challenges, please let us know, and we will explore ways to enhance the platform\'s accessibility features.'
            ]
        },
        {
            title: '32. Legal Assistance',
            content: [
                'While we aim to provide a comprehensive understanding of our Terms of Service, users are encouraged to seek legal advice if they have specific legal concerns or questions about their rights and responsibilities. In the event of any legal issues or disputes, we kindly request that users first contact our platform to address and resolve the matter amicably. We believe in open communication and collaboration to find fair and equitable solutions for all parties involved.'
            ]
        },
        {
            title: 'Exhibit A: Support Services',
            content: [
                'This exhibit outlines the specifics of the technical support services (\"Support Services\") that PortFollow Ltd. will furnish in connection with the Platform and Services. It is an integral and inseparable component of the attached Terms of Service (\"Terms\"). Any capitalized terms not explicitly defined herein shall be construed in accordance with the meanings ascribed in the Terms.'
            ]
        },
        {
            title: '1. Contact Information and Submission of Issues',
            content: [
                'Support Services will be accessible via email at portfollow.web@gmail.com. When seeking support, it is essential to furnish comprehensive information about the issue, including relevant screenshots or videos.'
            ]
        },
        {
            title: '2. Response Times and Issue Resolution',
            content: [
                'We endeavor to address all client-reported issues promptly. However, please be aware that response times may vary, and not all inquiries can be immediately resolved. Our support hours are flexible, and we will handle matters at our discretion, considering the nature of the issue and our availability.'
            ]
        },
        {
            title: '3. Issue Categorization and Resolution Commitments',
            content: [
                'Issue Categories:',
                '● Minor Issue: Partial, non-critical loss of use of the Platform.',
                '● Medium Issue: Major functionalities of the Platform are impacted, or significant performance degradation is experienced, though operations can continue in a restricted fashion.',

                '● Critical Issue: The Platform is completely down or unavailable. Critical Issues may require the Customer to allocate dedicated resources for issue resolution. We commit to acknowledging and responding to critical issues swiftly, with an emphasis on achieving a rapid resolution.',

                'Response Times:',

                '● Minor Issue: We will promptly respond and acknowledge receipt of the issue,report. Our goal is to implement reasonable efforts for resolution within a timeframe that suits the nature of the issue. While we aim to address and resolve these issues promptly, please note that response times may vary.',

                '● Medium Issue: We commit to responding and acknowledging the issue report promptly, striving for a resolution within a shorter timeframe to address the impact on major functionalities.',

                '● Critical Issue: We prioritize critical issues and will respond and acknowledge the report swiftly. Our commitment is to implement reasonable commercial efforts to achieve full resolution with the highest priority within a compressed timeframe.'
            ]
        },
        {
            title: 'Exhibit B: Data Processing Agreement (DPA)',
            content: [
                'This section provides additional details on the Data Processing Agreement (DPA) associated with the Services provided by PortFollow Ltd. Please note that this information is presented for your understanding and does not impose any obligations on your part.',
                'information is presented for your understanding and does not impose any obligations on your part.'
            ]
        },
        {
            title: 'Data Processing Purpose',
            content: [
                'The primary purpose of processing personal data is to facilitate and enhance the delivery of the services outlined in the terms. This involves the responsible handling and management of personal data in accordance with the stipulations set forth in the DPA.'
            ]
        },
        {
            title: 'Data Collection',
            content: [
                'Data Collection Sources:',
                'The data collected for the site is sourced exclusively from openly accessible data outlets. This underscores a commitment to transparency, ensuring that information used in the Services is drawn from public domains.',
                'Frequency of Data Retrieval:',
                'The data retrieval process occurs once a day at most. This deliberate approach to data collection aligns with privacy considerations, demonstrating a measured and responsible approach to information gathering.',
                'Exploration of Enhanced Data Services:',

                'As part of ongoing efforts to improve data quality, the company is actively exploring services to procure data directly from reputable sources like Nasdaq. This initiative aims to augment the richness and accuracy of information provided, showcasing a commitment to delivering valuable and reliable content to users.',
                'It\'s important to understand that these points are shared for informational purposes and do not impose any obligations on your part. The intention is to provide insights into the data collection practices and continuous improvement efforts within the framework of the Services.'
            ]
        },
        {
            title: 'Data Security',
            content: [
                'PortFollow is committed to upholding the security and confidentiality of personal data. To achieve this, appropriate technical and organizational measures are implemented. These measures are designed to mitigate risks and safeguard the integrity of the data throughout its processing.'
            ]
        },
        {
            title: 'Data Breach Notification',
            content: [
                'In the event of any unauthorized access or disclosure of personal data, PortFollow pledges to promptly notify the customer. This notification ensures transparency and allows for appropriate actions to be taken to address and mitigate the impact of such incidents.',
                'It\'s important to understand that the terms outlined in this DPA are intended to establish a framework for the responsible and secure processing of personal data, emphasizing transparency and cooperation between PortFollow and the customer.'
            ]
        },

        {
            title: '5. Rights and Responsibilities',
            content: [
                '5.1. User Rights: Users have the right to access, correct, delete, and restrict the processing of their personal data. Users may also object to the processing of their data and have the right to data portability.',
                '5.2. PortFollow\'s Responsibilities: PortFollow is responsible for ensuring that personal data is processed in compliance with applicable data protection laws and regulations. This includes implementing appropriate security measures and responding promptly to data subject requests.'
            ]
        },

        {
            title: '6. Data Processing Details',
            content: [
                '6.1. Categories of Data Subjects: The personal data processed pertains to users of the PortFollow platform, including registered account holders and visitors.',
                '6.2. Types of Personal Data: The personal data processed may include, but is not limited to, user names, email addresses, login credentials, IP addresses, and usage data.',
                '6.3. Processing Activities: The processing activities include the collection, storage, analysis, and deletion of personal data as necessary to provide and improve the Services.'
            ]
        },

        {
            title: '7. Data Retention',
            content: [
                '7.1. Retention Period: Personal data will be retained for as long as necessary to fulfill the purposes for which it was collected, or as required by applicable laws and regulations.',
            ]
        },

        {
            title: '8. Sub-processors',
            content: [
                '8.1. Use of Sub-processors: PortFollow may engage sub-processors to assist in the provision of the Services. Any such sub-processors will be subject to the same data protection obligations as set forth in this DPA.',
            ]
        },

        {
            title: '9. International Data Transfers',
            content: [
                '9.1. Transfer Mechanisms: If personal data is transferred outside the European Economic Area (EEA), PortFollow will ensure that appropriate safeguards are in place to protect the data, in compliance with applicable data protection laws.',
            ]
        },

        {
            title: '10. Cooperation and Assistance',
            content: [
                '10.1. Cooperation: PortFollow will cooperate with users and regulatory authorities to address any data protection queries, complaints, or investigations.',
                '10.2. Assistance: PortFollow will assist users in ensuring compliance with their data protection obligations, including responding to data subject requests and conducting data protection impact assessments.'
            ]
        },

        {
            title: '11. Amendments',
            content: [
                '11.1. Changes to the DPA: PortFollow reserves the right to amend this DPA as necessary to reflect changes in data protection laws or practices. Users will be notified of any material changes to this DPA.',
            ]
        },

        {
            title: '12. Contact Information',
            content: [
                '12.1. Data Protection Officer: Users may contact PortFollow\'s Data Protection Officer at portfollow.web@gmail.com with any questions or concerns regarding this DPA or data protection practices.',
            ]
        },

        {
            title: 'Exhibit C: Data Collection and Use Disclaimer',
            content: [
                'This exhibit outlines the specifics of the data collection practices using web crawlers by PortFollow Ltd. ("PortFollow"). It aims to address potential concerns from third-party websites regarding the scraping and use of publicly accessible data.',
            ]
        },

        {
            title: '1. Data Collection Practices',
            content: [
                '1.1. Source of Data: PortFollow collects data from publicly accessible websites using automated web crawlers. The data collected is strictly from public domains and is not protected by login credentials or other access restrictions.',
                '1.2. Nature of Data: The data collected includes, but is not limited to, financial information, stock prices, market indices, and other publicly available data relevant to the financial market. No personal or sensitive information is collected.'
            ]
        },

        {
            title: '2. Use of Collected Data',
            content: [
                '2.1. Purpose of Data Use: The data collected is used to provide analytical tools and insights to PortFollow users. The primary use of this data is to facilitate educational and informational purposes within the PortFollow platform.',
                '2.2. Data Modification: The data collected may be processed, analyzed, and presented in a different format for user consumption. This includes, but is not limited to, statistical analysis, trend identification, and data visualization.',
                '2.3. Potential Data Delays and Inaccuracies: Users should be aware that there might be delays in the data presented on the PortFollow platform, and it may not reflect real-time prices. Additionally, as an automated service, we might scrape data that is not accurate. Users are responsible for verifying the accuracy of the data before making any trading decisions. For real-time trading, users should use professional trading platforms.'
            ]
        },

        {
            title: '3. Legal Considerations',
            content: [
                '3.1. Compliance with Laws: PortFollow ensures that its data collection practices comply with applicable laws and regulations. The collection of publicly accessible data is conducted in a manner that respects the terms of service of the source websites to the extent possible.',
                '3.2. No Endorsement: PortFollow does not claim any endorsement or affiliation with the sources of the data collected. The use of data is solely for the purpose of providing analytical tools and insights to users.'
            ]
        },

        {
            title: '4. Disclaimer of Liability',
            content: [
                '4.1. No Similarity Assumption: PortFollow disclaims any liability for perceived similarities between the data presented on its platform and the data from the source websites. Any similarities are coincidental and not intended to replicate or infringe upon the content of the source websites.',
                '4.2. Indemnity: By using PortFollow\'s services, users agree to indemnify and hold harmless PortFollow from any claims, damages, or legal actions arising from the use of data collected from third-party websites.',
                '4.3. User Responsibility: Users are solely responsible for their trading decisions. PortFollow is not liable for any trading losses or financial damages resulting from the use of the data provided on its platform. Users should always consult professional trading platforms for accurate, real-time data when trading with real money.'
            ]
        },

        {
            title: '5. Communication with Data Sources',
            content: [
                '5.1. Notice to Data Sources: PortFollow is open to communication with data sources regarding its data collection practices. Data sources can contact PortFollow at portfollow.web@gmail.com with any concerns or inquiries.',
            ]
        },

        {
            title: '6. Modifications to Data Collection Practices',
            content: [
                '6.1. Right to Modify: PortFollow reserves the right to modify its data collection practices at any time to ensure compliance with legal standards and to improve the quality of its services.',
            ]
        },

    ]

    return (
        <div className="information-container main-content-container single-company-header">
            <div className="card-container information-item-container">
                <h1 className="information-item-main-title">Terms of Service</h1>
                {
                    TermsOfServiceContent.map((item, index) => (
                        <div className="information-item" key={index}>
                            <h3 className="information-item-title">{item.title}</h3>
                            {
                                item.content.map((paragraph, idx) => (
                                    <p className="information-item-p" key={idx}>{paragraph}</p>
                                ))}
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default TermsOfService;