import React, { useCallback, useRef, useState, useEffect } from 'react';
import { closeIcon, userImg } from '../../../../../../shared/icons/icons';
import './homepage-create-post.scss';
const HomepageCreatePost = ({
    loggedInUser,
    handleCreatePost,
}) => {

    const [isGraphsModalOpen, setIsGraphsModalOpen] = useState(false);
    const [postText, setPostText] = useState('');
    const [selectedGraphs, setSelectedGraphs] = useState([false, false, false, false, false, false]);
    const [confirmedGraphs, setConfirmedGraphs] = useState({});
    const postRef = useRef(null);
    const modalRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleCloseAddGraphs();
            };
        };
        if (isGraphsModalOpen) {
            document.addEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isGraphsModalOpen]);

    const graphNames = [
        'Companies Values (pie)',
        'Sectors Values (pie)',
        'Prices Over Time (line)',
        'Relative Prices Over Time (line)',
        'Monthly Returns (bar)',
        'Monthly Sharpe (bar)'
    ];

    const handleTextAreaChange = (ev) => {
        setPostText(ev.target.value);
    };

    const TextInput = useCallback(({ ref, text, onChange }) => {
        return <textarea
            className='homepage-create-post-textarea'
            placeholder='Whats on your mind?'
            ref={ref}
            value={text}
            onChange={onChange}
        />;
    }, []);

    // pass graphs
    const handlePostComment = () => {
        if (postText === '') return;
        handleCreatePost(postText, confirmedGraphs);
        setConfirmedGraphs({});
        setPostText('');
    };

    const handleAddGraphs = (ev) => {
        ev.stopPropagation();
        setIsGraphsModalOpen(true);
    };

    const handleCloseAddGraphs = () => {
        setSelectedGraphs([false, false, false, false, false, false]);
        setIsGraphsModalOpen(false);
    };

    const toggleGraphSelection = (index) => {
        const updatedSelection = [...selectedGraphs];
        updatedSelection[index] = !updatedSelection[index];
        setSelectedGraphs(updatedSelection);
    };

    const getGraphType = (graphName) => {
        if (graphName.includes('(bar)')) {
            return 'bar';
        } else if (graphName.includes('(line)')) {
            return 'line';
        } else if (graphName.includes('(pie)')) {
            return 'pie';
        }
        return null;
    };

    const transformGraphNamesToObject = (selectedGraphNames) => {
        const graphObject = {
            bar: [],
            line: [],
            pie: [],
        };

        const graphKeywords = {
            'Prices Over Time (line)': 'price',
            'Relative Prices Over Time (line)': 'relative',
            'Monthly Returns (bar)': 'return',
            'Monthly Sharpe (bar)': 'sharpe',
            'Companies Values (pie)': 'companies',
            'Sectors Values (pie)': 'sectors',
        };

        selectedGraphNames.forEach((graphName) => {
            if (graphKeywords[graphName]) {
                const keyword = graphKeywords[graphName];
                graphObject[getGraphType(graphName)].push(keyword);
            }
        });

        return graphObject;
    };

    const onConfirmGraphs = () => {
        const selectedGraphNames = graphNames.filter((_, idx) => selectedGraphs[idx]);
        const selectedGraphObject = transformGraphNamesToObject(selectedGraphNames);
        setConfirmedGraphs(selectedGraphObject);
        handleCloseAddGraphs();
    };

    const selectGraphsModal = () => {
        return (
            isGraphsModalOpen && (
                <div
                    className="create-post-select-graphs-container card-container"
                    ref={modalRef}
                >
                    <div className="flex space-between mb10">
                        <div>
                            <h2>Graphs Selection:</h2>
                        </div>
                        <div
                            className='btn'
                            onClick={() => handleCloseAddGraphs()}
                        >{closeIcon}</div>
                    </div>
                    <div className="select-graphs-container">
                        {
                            selectedGraphs.map((isSelected, idx) => (
                                <div
                                    className="flex select-graphs-item flex align-center"
                                    onClick={() => toggleGraphSelection(idx)}
                                >
                                    <div
                                        key={idx}
                                        className={`select-graphs-checkbox ${isSelected ? 'select-graphs-checkbox-selected' : ''}`}
                                    >
                                    </div>
                                    <div className='select-graphs-graphtitle'>
                                        {graphNames[idx].split(' (').map((part, i) => (
                                            <React.Fragment key={i}>
                                                {i === 0 ? part : <span className='select-graphs-graphspan'>({part}</span>}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='select-graphs-counter'>Selected: {selectedCount} / {totalGraphs}</div>
                    <div className="flex space-between">
                        <div
                            className="select-graphs-btn btn"
                            onClick={() => handleCloseAddGraphs()}
                        >cancel</div>
                        <div
                            className="select-graphs-btn btn"
                            onClick={() => onConfirmGraphs()}
                        >Confirm</div>
                    </div>
                </div>
            )
        );
    };

    const selectedCount = selectedGraphs.filter((isSelected) => isSelected).length;
    const totalGraphs = graphNames.length;
    const totalConfiremdGraphs = (
        (confirmedGraphs.bar?.length || 0) +
        (confirmedGraphs.line?.length || 0) +
        (confirmedGraphs.pie?.length || 0)
      );
    return (
        <>
            <div className="homepage-create-post-container card-container">
                {/* <div className="homepage-create-post-header flex align-center">
                    <span className='homepage-create-post-header-item'>{userImg}</span>
                    {loggedInUser}
                </div> */}

                <TextInput
                    ref={postRef}
                    text={postText}
                    onChange={handleTextAreaChange}
                />
                <div className="selected-graphs-counter">
                    Graphs in post: {Object.keys(confirmedGraphs).length === 0 ? 0 : totalConfiremdGraphs}
                </div>
                <div className="flex space-around">
                    <div
                        className='homepage-create-post-btn btn flex justify-center'
                        onClick={() => handlePostComment()}
                    >
                        Post
                    </div>

                    <div
                        className='homepage-create-post-btn btn flex justify-center'
                        onClick={handleAddGraphs}
                    >
                        Add graphs
                    </div>

                </div>
            </div>
            {selectGraphsModal()}
        </>
    );
}

export default HomepageCreatePost;