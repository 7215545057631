

import { useHistory } from 'react-router-dom';
import { toggleTopPage } from '../../../shared/global-color-functions';

const FinalSlide = ({ handleCloseTutorial }) => {

    const history = useHistory();

    const handleNavigation = (page) => {
        handleCloseTutorial();
        history.push(`/${page}`);
    };

    return (
        <>
            <h2 className="app-tutorial-slide-title">Congratulations!</h2>

            <p className="app-tutorial-p">
                You've completed the first part of the PortFollow tutorial!<br />
                We hope you found it helpful and informative. <br />
                Now it's time to start exploring and making the most of our app to achieve your investment goals. Happy investing! <br />
                Keep in mind, if you want to save your portfolios and share your thoughts with the community, you should create an account! <br />
                Also, rest assured, PortFollow is completely free, and we will never ask for your credit card or private information. <br />
            </p>

            <p className="app-tutorial-p">
                We value your feedback! <br />
                If you have any questions, suggestions, or issues, please don't hesitate to reach out. <br />
                We're here to help you succeed!
            </p>

            <p className="app-tutorial-p">
                Contact us at: portfollow.web@gmail.com
            </p>

            <p className="app-tutorial-p">
                PortFollow development team.
            </p>

            <div className="flex mb20">
                <div className="btn btn-border" style={{ marginRight: '20px' }}>
                    <div onClick={() => handleNavigation('login')}>Login</div>
                </div>
                <div className="btn btn-border">
                    <div onClick={() => handleNavigation('signup')}>Signup</div>
                </div>
            </div>

            <p className="app-tutorial-p-strong mb20">
                Navigate to any of the PortFollow pages
            </p>

            <div className="flex wrap">
                <div className="app-tutorial-nav-btn btn btn-border">
                    <div onClick={() => handleNavigation('')}>Feed</div>
                </div>
                <div className="app-tutorial-nav-btn btn btn-border">
                    <div onClick={() => handleNavigation('portfolio')}>Portfolio</div>
                </div>
                <div className="app-tutorial-nav-btn btn btn-border">
                    <div onClick={() => handleNavigation('macro')}>Macro</div>
                </div>
                <div className="app-tutorial-nav-btn btn btn-border">
                    <div onClick={() => handleNavigation('crypto')}>Crypto</div>
                </div>
                <div className="app-tutorial-nav-btn btn btn-border">
                    <div onClick={() => handleNavigation('calendar')}>Calendar</div>
                </div>
            </div>

            <div className="flex flex-end">
                <div
                    className="guide-btn btn btn-border"
                    onClick={toggleTopPage}>
                    Back to top
                </div>
            </div>
        </>
    );
}

export default FinalSlide;