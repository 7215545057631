import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { setGlobalIsLoading } from "../../../store/loading/loading-actions";
import ActivePageSelection from "../../../shared/active-page/active-page";
import { getPortfolioPage } from "../../../shared/actions/portfolio-actions";
import PortfolioMenu from "../portfolio-menu/portfolio-menu";
import PortfolioPeriodFilter from "../portfolio-filters/portfolio-period-filter";
import Select from 'react-select';
import { customStyles, DropdownIndicator } from "../../../shared/dropdown-styles";
import { dropdownIcon } from "../../../shared/icons/icons";
import { CompaniesGuide } from "../../../cmps/page-guide";
import PortfolioCompaniesSectorsPage from "../portfolio-pages/portfolio-companies-sectors-page";
import PortfolioFilters from "../cmps/filters";
import LoadingSpinner from "../../../shared/loading-spinner/loading-spinner";
import EmptyPortfolio from "../empty-portfolio.jsx/empty-portfolio";

const PortfolioCompanies = ({
    loggedInUser,
    myPortfolio,
    mySelectedPortfolio,
    portfolio,
    portfolioCompanyData,
    handleChangePortfolioCompaniesData,
    addStock,
    addStocks,
    handleChangeSelectedPortfolio,
    showTutorial,
    portfolioFilters,
    handleChangePortfolioFilters,

    portfolioFullCompanyOptions,
    portfolioCompanyOptions,
    portfolioActiveCompanyOptions,
    portfolioSectorOptions,
    portfolioActiveSectorOptions,
    handleChangePortfolioFullCompanyOptions,
    handleChangePortfolioCompanyOptions,
    handleChangePortfolioActiveCompanyOptions,
    handleChangePortfolioSectorOptions,
    handleChangePortfolioActiveSectorOptions,
    blinkCmp
}) => {

    const pages = ['companies', 'sectors', 'fundamental', 'descriptive', 'sensitivity', 'guide'];

    const loadingSelector = useSelector((state) => state.loading.isLoading);
    const dispatch = useDispatch();
    const history = useHistory();

    const [companyData, setCompanyData] = useState(null);

    useEffect(() => {
        if (!myPortfolio) return;
        const fetchData = async () => {
            if (portfolioCompanyData) {
                setCompanyData(portfolioCompanyData);
            } else if (myPortfolio.portfolioData.length) {
                const res = await getPortfolioPage('companies', myPortfolio.portfolioData);

                if (!portfolioFilters || !portfolioFilters.companies || !portfolioFilters.sectors) {
                    const uniqueSectors = Array.from(new Set(res.companyPages.map(item => item.sector)));
                    const uniqueCompanies = Array.from(new Set(res.companyPages.map(item => item.symbol)));
                    const portfolioFilters = {
                        period: { value: "oneWeek", label: '1 Week' },
                        sectors: uniqueSectors,
                        companies: uniqueCompanies
                    };
                    handleChangePortfolioFilters(portfolioFilters);
                };

                if (!portfolioCompanyOptions || !portfolioFullCompanyOptions) {
                    const uniqueCompanyOptions = Array.from(new Set(res.companyPages.map(item => ({
                        label: item.symbol,
                        value: item.symbol
                    }))));
                    handleChangePortfolioFullCompanyOptions(uniqueCompanyOptions);
                    handleChangePortfolioCompanyOptions(uniqueCompanyOptions);
                    handleChangePortfolioActiveCompanyOptions(uniqueCompanyOptions);
                };

                if (!portfolioSectorOptions) {
                    const uniqueSectorOptions = [];
                    const seen = new Set();

                    res.companyPages.forEach(item => {
                        const sectorValue = item.sector;
                        if (!seen.has(sectorValue)) {
                            uniqueSectorOptions.push({
                                label: sectorValue,
                                value: sectorValue
                            });
                            seen.add(sectorValue);
                        }
                    });

                    handleChangePortfolioSectorOptions(uniqueSectorOptions);
                    handleChangePortfolioActiveSectorOptions(uniqueSectorOptions);
                };


                const companiesData = {
                    barcharts: {},
                    piechart: [],
                    valueOverTime: []
                };

                res.companyPages.forEach((company) => {

                    const barChartsKeys = Object.keys(company.companies.barCharts);
                    barChartsKeys.forEach((chart) => {
                        if (!companiesData.barcharts[chart]) {
                            companiesData.barcharts[chart] = [];
                        }
                        const chartData = company.companies.barCharts[chart];
                        companiesData.barcharts[chart].push({
                            return: chartData.return,
                            sharpe: chartData.sharpe,
                            beta: chartData.beta,
                            info: chartData.information,
                            positiveDays: chartData.positiveDays,
                            std: chartData.std,
                            symbol: company.symbol,
                            sector: company.sector,
                            company: company.company,
                            color: company.color,
                        });
                    });

                    companiesData.valueOverTime.push({
                        value: company.companies.valueOverTime,
                        symbol: company.symbol,
                        sector: company.sector,
                        company: company.company,
                        color: company.color,
                    });

                    // Pie Chart
                    companiesData.piechart.push({
                        value: company.companies.pieChart,
                        symbol: company.symbol,
                        sector: company.sector,
                        company: company.company,
                        color: company.color,
                    });
                });
                setCompanyData(companiesData);
                handleChangePortfolioCompaniesData(companiesData);
            };
        };
        fetchData();
    }, [myPortfolio, mySelectedPortfolio]);

    const handleToggleOption = (option) => {
        if (option === 'guide') {
            history.push(`/portfolio`);
        } else {
            history.push(`/portfolio/${option}`);
        };
    };

    const getNavLocation = (label) => {
        return `/portfolio/${label}`;
    };

    const handleChangePeriod = (newPeriod) => {
        const newFilters = { ...portfolioFilters };
        newFilters.period = newPeriod;
        handleChangePortfolioFilters(newFilters);
    };

    const handleChangeSector = (newSectors) => {
        if (newSectors.length === 0) return;
        handleChangePortfolioActiveSectorOptions(newSectors)
        const newSectorValues = newSectors.map(sector => sector.value);
        const newFilters = { ...portfolioFilters, sectors: newSectorValues };
        handleChangePortfolioFilters(newFilters);
    };

    const handleChangeCompany = (newCompany) => {
        if (newCompany.length === 0) return;
        handleChangePortfolioActiveCompanyOptions(newCompany);
        const newCompanyValues = newCompany.map(comapny => comapny.value);
        const newFilters = { ...portfolioFilters, companies: newCompanyValues };
        handleChangePortfolioFilters(newFilters);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow | Portfolio Companies</title>
                <meta name="description" content="Analyze and manage your investment portfolio" />
                <link rel="canonical" href='https://www.portfollow.com/portfolio/companies' />
            </Helmet>


            {

             !loggedInUser || myPortfolio && !myPortfolio.portfolioData.length ? (
                    <EmptyPortfolio
                        loggedInUser={loggedInUser}
                        selectedPortfolio={mySelectedPortfolio}
                        myPortfolio={myPortfolio}
                        addStocks={addStocks}
                    />
                ) : (
                    <>

                        <div className="page-margin-top">
                            <ActivePageSelection
                                handleToggleOption={handleToggleOption}
                                pages={pages}
                                activePage={'companies'}
                                getNavLocation={getNavLocation}
                                guide={'/portfolio'}
                            />
                        </div>

                        {
                            portfolioFilters &&
                            portfolioActiveCompanyOptions &&
                            portfolioActiveSectorOptions &&
                            <PortfolioFilters
                                activePage={'companies'}
                                addStocks={addStocks}
                                addStock={addStock}
                                myPortfolio={myPortfolio}
                                mySelectedPortfolio={mySelectedPortfolio}
                                portfolio={portfolio}
                                handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                                loggedInUser={loggedInUser}
                                handleChangePeriod={handleChangePeriod}
                                portfolioFilters={portfolioFilters}
                                portfolioSectorOptions={portfolioSectorOptions}
                                portfolioActiveSectorOptions={portfolioActiveSectorOptions}
                                handleChangeSector={handleChangeSector}
                                portfolioCompanyOptions={portfolioCompanyOptions}
                                portfolioActiveCompanyOptions={portfolioActiveCompanyOptions}
                                handleChangeCompany={handleChangeCompany}
                                blinkCmp={blinkCmp}
                            />
                        }

                        <div className="container-height main-content-container">
                            {
                                portfolioCompanyData &&
                                    portfolioFilters &&
                                    portfolioFilters.sectors &&
                                    portfolioFilters.companies &&
                                    companyData ? (
                                    <PortfolioCompaniesSectorsPage
                                        selectedFilter={portfolioFilters}
                                        companyData={companyData}
                                        page={'companies'}
                                        portfolioData={myPortfolio.portfolioData}
                                    />) : <LoadingSpinner />
                            }
                        </div>

                    </>
                )
            }

            <div className="card-container main-content-container" style={{ margin: '20px auto', width: '' }}>
                <CompaniesGuide />
            </div>
        </>
    );
}

export default PortfolioCompanies;