import React, { useEffect, useState, useRef, useMemo } from 'react';
import PeriodDropdownSelect from "../../../shared/dropdown-select/period-dropdown-select";
import { hexToRGBA } from '../../../shared/global-color-functions';
import LineChart from "../../../shared/charts/line-chart/line-chart";
import './macro-indices.scss';
import MacroContinentCountryIndexFilters from './macro-indices-continent-country-filters';
import MacroPriceRelativeFilter from '../macro-price-relative-filter';
import MacroIndicesCommodities from '../../../cmps/macro-indicies-commodities/macro-indicies-commodities';
import { Helmet } from "react-helmet";
import { IndicesGuide } from '../../../cmps/page-guide';

const MacroIndices = ({
    indicesData,
    periodFilter,
    onPeriodChange,
    periodOptionsToDisplay,
    activePriceRelative,
    changePriceRelative,
    clickData
}) => {

    const [chartDataReturn, setChartDataReturn] = useState(null);
    const [chartDataSharpe, setChartDataSharpe] = useState(null);

    const [continentOptions, setContinentOptions] = useState(null);
    const [countryOptions, setCountryOptions] = useState(null);
    const [indexOptions, setIndexOptions] = useState(null);

    const [selectedItem, setSelectedItem] = useState(null);
    const [filteredIndices, setFilteredIndices] = useState(null);
    const [indicesColors, setIndicesColors] = useState(null);

    const defaultIndices = [
        {
            continent: "North America",
            country: "United States",
            currency: "USD",
            index: "S&P 500 Index",
            symbol: "GSPC",
            value: 'S&P 500 Index',
            label: 'S&P 500 Index',
            key: 'GSPC'
        },
        {
            continent: "Europe",
            country: "United Kingdom",
            currency: "GBP",
            index: "FTSE 100 Index",
            symbol: "FTSE",
            value: 'FTSE 100 Index',
            label: 'FTSE 100 Index',
            key: 'FTSE'
        },
        {
            continent: "North America",
            country: "United States",
            currency: "USD",
            index: "Dow Jones Industrial Average",
            symbol: "DJI",
            value: 'Dow Jones Industrial Average',
            label: 'Dow Jones Industrial Average',
            key: 'DJI'
        },
        {
            continent: "Asia",
            country: "Japan",
            currency: "JPY",
            index: "Nikkei 225 Index",
            symbol: "N225",
            value: 'Nikkei 225 Index',
            label: 'Nikkei 225 Index',
            key: 'N225'
        }
    ];

    useEffect(() => {
        setSelectedItem(null);
    }, [clickData]);

    useEffect(() => {

        setFilteredIndices(defaultIndices);

        const continentSet = new Set();
        const continentArray = indicesData.groups.reduce((result, item) => {
            if (!continentSet.has(item.continent)) {
                continentSet.add(item.continent);
                result.push({
                    label: item.continent,
                    value: item.continent,
                    index: item.index
                });
            }
            return result;
        }, []);
        continentArray.sort((a, b) => a.label.localeCompare(b.label));

        const countrySet = new Set();
        const countryArray = indicesData.groups.reduce((result, item) => {
            if (!countrySet.has(item.country)) {
                countrySet.add(item.country);
                result.push({
                    label: item.country,
                    value: item.country,
                    continent: item.continent,
                    index: item.index
                });
            }
            return result;
        }, []);
        countryArray.sort((a, b) => a.label.localeCompare(b.label));
        const indexArray = indicesData.groups.map(item => ({
            label: item.index,
            value: item.index,
            continent: item.continent,
            country: item.country,
            index: item.index,
            key: item.symbol,
            symbol: item.symbol
        }));
        indexArray.sort((a, b) => a.label.localeCompare(b.label));

        setContinentOptions(continentArray);
        setCountryOptions(countryArray);
        setIndexOptions(indexArray);

    }, [indicesData.groups]);

    const onFilterContinents = (selectedOptions) => {
        const filteredCountries = indicesData.groups.filter((country) => {
            return selectedOptions.some((continent) => continent.value === country.continent);
        });

        const countrySet = new Set();
        const countryArray = filteredCountries.reduce((result, item) => {
            if (!countrySet.has(item.country)) {
                countrySet.add(item.country);
                result.push({
                    label: item.country,
                    value: item.country,
                    continent: item.continent,
                    index: item.index,
                    indexName: item.indexName
                });
            }
            return result;
        }, []);
        countryArray.sort((a, b) => a.label.localeCompare(b.label));
        setCountryOptions(countryArray);

        const indexSet = new Set();
        const indexArray = filteredCountries.reduce((result, item) => {
            if (!indexSet.has(item.index)) {
                indexSet.add(item.index);
                result.push({
                    label: item.symbol,
                    value: item.index,
                    continent: item.continent,
                    country: item.country,
                    index: item.index,
                    indexName: item.indexName
                });
            }
            return result;
        }, []);
        indexArray.sort((a, b) => a.label.localeCompare(b.label));
        //    console.log('options', indexOptions);
        //    console.log('filtered:', filteredIndices);
        //     console.log('indexArray', indexArray);
        setIndexOptions(indexArray);
        setFilteredIndices(indexArray);
    };

    const onFilterCountries = (selectedOptions) => {
        const selectedCountrySet = new Set(selectedOptions.map((option) => option.value));
        const filteredIndices = indicesData.groups.filter((index) => {
            return selectedCountrySet.has(index.country);
        });
        const indexSet = new Set();
        const indexArray = filteredIndices.reduce((result, item) => {
            if (!indexSet.has(item.index)) {
                indexSet.add(item.index);
                result.push({
                    label: item.symbol,
                    value: item.index,
                    continent: item.continent,
                    country: item.country,
                    index: item.index,
                    indexName: item.indexName
                });
            }
            return result;
        }, []);
        indexArray.sort((a, b) => a.label.localeCompare(b.label));
        setIndexOptions(indexArray);
        setFilteredIndices(indexArray);
    };

    const onFilterIndices = (selectedOptions) => {
        setFilteredIndices(selectedOptions);
    };

    const getChartData = (type) => {
        let chartData = {};
        let sortedData = [...indicesData[type][periodFilter]]
        sortedData = sortedData.sort((a, b) => b[type] - a[type]);
        let foundItem = null;
        if (filteredIndices) {
            sortedData = sortedData.filter((item) => {
                const isMatching = filteredIndices.some(
                    (index) => index.symbol === item.key || index.label === item.key
                );
                return isMatching;
            });
        };

        if (selectedItem !== null && selectedItem !== undefined) {
            const selectedItemObject = indicesData.groups.find(item => item.symbol === selectedItem);
            if (!selectedItemObject) return;
            const selectedSymbol = selectedItemObject.symbol;
            foundItem = selectedSymbol;
            const selectedIndex = sortedData.findIndex((data) => data.key === selectedSymbol);
            const indices = [selectedIndex - 2, selectedIndex - 1, selectedIndex, selectedIndex + 1, selectedIndex + 2];
            const validIndices = indices.filter((index) => index >= 0 && index < sortedData.length);
            sortedData = validIndices.map((index) => sortedData[index]);
        } else {
            sortedData = sortedData.slice(0, 5);
        }
        if (!sortedData) {
            return chartData;
        }

        const formattedSelectedItem = foundItem
            ? foundItem.replace(/[^a-zA-Z0-9]/g, "").toLowerCase()
            : null;

        const datasets = [
            {
                label: type === "sharpe" ? "Sharpe" : "Return",
                data: sortedData.map((data) => data[type]),
                backgroundColor: sortedData.map((data) =>
                    formattedSelectedItem !== null &&
                        formattedSelectedItem !==
                        data.key.replace(/[^a-zA-Z0-9]/g, "").toLowerCase()
                        ? hexToRGBA(data[type] > 0 ? "#5c9ead" : "#e39774", 0.4)
                        : data[type] > 0
                            ? "#5c9ead"
                            : "#e39774"
                ),
                borderColor: sortedData.map((data) =>
                    formattedSelectedItem !== null &&
                        formattedSelectedItem !==
                        data.key.replace(/[^a-zA-Z0-9]/g, "").toLowerCase()
                        ? hexToRGBA(data[type] > 0 ? "#5c9ead" : "#e39774", 0.4)
                        : data[type] > 0
                            ? "#5c9ead"
                            : "#e39774"
                ),
            },
        ];

        chartData = {
            labels: sortedData.map((data) => data.key),
            hover: sortedData.map((data) => data.hover),
            datasets: datasets,
        };

        return chartData;
    };

    const onChangeSelectedItem = (item) => {
        setSelectedItem(item);
    };

    const chartData = useMemo(() => {
        let data;
        if (indicesData[activePriceRelative][periodFilter]) {
            data = indicesData[activePriceRelative][periodFilter];
        } else {
            data = indicesData[activePriceRelative]['oneWeek'];
        }
        let filteredData = [];
        if (filteredIndices) {
            filteredData = data.filter((item) =>
                filteredIndices.some((index) => index.index === item.key)
            );
            if (!filteredData.length) {
                filteredData = data.filter((item) =>
                    filteredIndices.some((index) => index.index === item.key)
                );
            }
        } else {
            filteredData = data;
        }
        const labelColors = [];

        const datasets = filteredData.map(({ key, data }) => {
            // Assuming indicesData is in scope
            const colorObj = indicesData.defaultColors.find((item) => item.label === key);
            const foundSymbol = colorObj ? colorObj.symbol : null;
            const backgroundOpacity = selectedItem === null || selectedItem === undefined ? 1 : selectedItem === foundSymbol ? 1 : 0.1;
            // const borderWidth = selectedItem === null || selectedItem === undefined ? 5 : selectedItem === foundSymbol ? 10 : 2;
            const borderWidth = selectedItem === foundSymbol ? 5 : 3;

            const chartDataArray = data.map(({ date, x }) => ({ x: date, y: x }));
            const rgbaColor = hexToRGBA(colorObj ? colorObj.color : '#000000', backgroundOpacity);
            const matchedColor = indicesData.defaultColors.find(
                (colorData) => colorData.label === key
            );

            if (matchedColor) {
                const modifiedLabel = matchedColor.symbol
                labelColors.push(({
                    label: modifiedLabel,
                    symbol: matchedColor.symbol,
                    color: matchedColor.color,
                }));
            }
            // Rest of the code
            return {
                label: matchedColor.symbol,
                // label: activePriceRelative === 'price' ? matchedColor.symbol : 'Relative ' + matchedColor.symbol,
                data: chartDataArray,
                fill: false,
                backgroundColor: rgbaColor,
                borderColor: rgbaColor,
                borderWidth: borderWidth,
            };
        });

        setIndicesColors(labelColors);
        setChartDataReturn(getChartData("return"));
        setChartDataSharpe(getChartData("sharpe"));
        return { datasets };
    }, [indicesData, activePriceRelative, periodFilter, filteredIndices, selectedItem]);

    const getSelectedItemHover = () => {
        if (!selectedItem || !indicesData || !indicesData.defaultColors) return '';
        const selectedItemObj = indicesData.defaultColors.find(item => item.symbol === selectedItem);
        if (selectedItemObj) {
            return `(${selectedItemObj.symbol}) ${selectedItemObj.label}`;
        }
        return '';
    };

    const indicesInnerContent = (
        <>
            {
                <div style={{ width: '99.9%', height: '90%' }}>
                    <LineChart
                        chartData={chartData}
                        type={activePriceRelative === 'price' ? 'dollar' : 'relative-percent'}
                        onChangeSelectedItem={onChangeSelectedItem}
                    />
                </div>
            }
        </>
    );

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow - Macro Indices</title>
                <meta name="description" content="unlock the potential of macroeconomic analysis in shaping your investment strategies! A comprehensive overview of key macroeconomic indicators, currency exchange rates, stock market indices, and commodity prices." />
                <link rel="canonical" href="https://www.portfollow.com/macro/indices" />
            </Helmet>
            <div className="dropdown-select-filter-container flex center-center">
                <PeriodDropdownSelect
                    currFilter={periodFilter}
                    onChangeFilter={onPeriodChange}
                    optionsToDisplay={periodOptionsToDisplay}
                />
                {
                    continentOptions &&
                    countryOptions &&
                    indexOptions &&
                    <MacroContinentCountryIndexFilters
                        continents={continentOptions}
                        countries={countryOptions}
                        indices={indexOptions}
                        onFilterContinents={onFilterContinents}
                        onFilterCountries={onFilterCountries}
                        onFilterIndicies={onFilterIndices}
                        defaultIndices={defaultIndices}
                    />
                }
                {
                    <MacroPriceRelativeFilter
                        activePriceRelative={activePriceRelative}
                        changePriceRelative={changePriceRelative}
                    />
                }
            </div>
            {
                indicesColors &&
                <MacroIndicesCommodities
                    labelColors={indicesColors}
                    type={'index'}
                    display={'column'}
                    innerContent={indicesInnerContent}
                    innerContentTitle={'Indices Over Time'}
                    chartDataReturn={chartDataReturn}
                    chartDataSharpe={chartDataSharpe}
                    selectedItem={selectedItem}
                    onChangeSelectedItem={onChangeSelectedItem}
                    hoverItem={getSelectedItemHover()}
                />
            }

            <div className="card-container" style={{ margin: '20px 0', width: '100% !important' }}>
                <IndicesGuide />
            </div> 

        </>
    );
}

export default MacroIndices;