import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import { useHistory, useLocation } from "react-router-dom";
import { getSearchOptions } from '../../../shared/actions/search-actions';
import './header-search.scss';
import { useDispatch } from 'react-redux';
import { setGlobalIsLoading } from '../../../store/loading/loading-actions';
import { loginIcon, macroIcon, portfolioIcon, searchIcon, userIcon } from '../../../shared/icons/icons';

const HeaderSearch = ({ isMobile, loggedInUser, isSearchBarOpen, setIsSearchBarOpen }) => {

    const dispatch = useDispatch();

    const [options, setOptions] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const reactSelectRef = useRef(null);
    const [displayOptions, setDisplayOptions] = useState(false);
    const [hasTyped, setHasTyped] = useState(false);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        let delayDebounceFn;
        if (searchInput === '') {
            setOptions([]);
            if (hasTyped) {
                setDisplayOptions(false);
                setIsSearchBarOpen(false);
                setHasTyped(false);
            }
        } else {
            setHasTyped(true);
            setDisplayOptions(true);
            if (delayDebounceFn) clearTimeout(delayDebounceFn);
            delayDebounceFn = setTimeout(() => {
                getSearchOptions(searchInput, setOptions);
                if (displayOptions && reactSelectRef.current) {
                    reactSelectRef.current.focus();
                }
            }, 500);
        }
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [searchInput]);

    useEffect(() => {
        if (!selectedOption || !selectedOption.value || !selectedOption.value.length) return;
        dispatch(setGlobalIsLoading(true));
        history.push(`/${selectedOption.url}/${selectedOption.value}`);
        if (isMobile) {
            setDisplayOptions(false);
            setSearchInput('');
            setSelectedOption(null);
            setIsSearchBarOpen(false);
            setOptions([]);
        }
    }, [selectedOption]);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        if (reactSelectRef.current) {
            reactSelectRef.current.blur();
        };
    };

    useEffect(() => {
        if (displayOptions && reactSelectRef.current) {
            reactSelectRef.current.focus();
        }
    }, [options, displayOptions]);

    const CustomDropdownIndicator = () => {
        return <span className='header-search-custom-dropdown-indicator'>
            {searchIcon}
        </span>;
    };

    // const searchWidth = isMobile ? '80%' : isTablet ? '450px' : '600px';
    // const searchWidth = (isMobile && !isSearchBarOpen) ? '80%' : '350px';
    const searchWidth = (isMobile) ? '90%' : '350px';

    const CustomOption = ({ data, onSelect, onInputChange }) => {

        const handleClick = () => {
            onSelect(data, { action: 'select-option' });
            onInputChange(data.label);
        };

        return (
            <div
                className="custom-option flex align-center"
                onClick={handleClick}
            >
                <img className="custom-option-img" src={data.pngUrl} alt={data.label} />
                <p>{data.label}</p>
            </div>
        );
    };

    // const handleInputChange = (value) => {
    //     setSearchInput(value);
    //     setIsMenuOpen(true);
    // };

    useEffect(() => {
        if (isSearchBarOpen && reactSelectRef.current) {
            reactSelectRef.current.focus();
        }
    }, [isSearchBarOpen, searchInput, reactSelectRef]);

    const [selectedPage, setSelectedPage] = useState('');

    useEffect(() => {
        const currentPath = location.pathname;
        if (currentPath.includes('profile')) {
            setSelectedPage('profile');
        } else if (currentPath.includes('portfolio')) {
            setSelectedPage('portfolio');
        } else if (currentPath.includes('macro')) {
            setSelectedPage('macro');
        } else {
            setSelectedPage('');
        }
    }, [location]);

    const handleMobileNav = (nav) => {
        if (nav === 'profile') {
            history.push(`/profile/portfolios/${loggedInUser}`);
        } else if (nav === 'portfolio') {
            history.push(`/portfolio/companies`);
        } else if (nav === 'macro') {
            history.push(`/macro/inflation`);
        }
    };

    const MobileSelect = () => {
        return (
            <div className="mobile-select-container flex center-center" >
                {
                    isSearchBarOpen ?
                        <div className={`header-search-container flex column`}
                        >
                            <Select
                                ref={reactSelectRef}
                                placeholder='Search'
                                menuIsOpen={displayOptions}
                                className='header-search-bar'
                                noOptionsMessage={() => searchInput === '' ? 'Search' : 'Fetching..'}
                                loadingMessage={() => searchInput === '' ? 'Search' : 'Fetching..'}
                                options={options}
                                value={selectedOption}
                                inputValue={searchInput}
                                onChange={handleChange}
                                onInputChange={(value) => setSearchInput(value)}
                                // onInputChange={handleInputChange}
                                defaultOptions={false}
                                components={{
                                    DropdownIndicator: CustomDropdownIndicator,
                                    Option: (props) => (
                                        <CustomOption {...props} onSelect={handleChange} onInputChange={setSearchInput} />
                                    ),
                                }}
                                styles={{
                                    // dropdownIndicator: () => ({ color: '#484848' }),
                                    // dropdownIndicator: {loginIcon},
                                    menu: (base) => ({
                                        ...base,
                                        width: isMobile ? '100%' : '350px',
                                        marginTop: '10px',
                                        left: '49%',
                                        transform: 'translateX(-50%)',
                                        borderRadius: '8px',
                                        zIndex: 1000,
                                    }),
                                    option: (base, state) => ({
                                        ...base,
                                        width: '100%',
                                        textAlign: 'start',
                                        padding: '10px',
                                        backgroundColor: '#ffffff',
                                        color: state.isSelected ? '#326fb5' : '#484848',
                                        fontWeight: state.isSelected ? '600' : '500',
                                        borderBottom: state.isFocused && !state.isLast ? '2px solid #EEEEEE' : 'none',
                                        '&:not(:last-child)': {
                                            borderBottom: '2px solid #EEEEEE',
                                        },
                                        '&:hover': {
                                            cursor: 'pointer',
                                            backgroundColor: '#f1f6fc'
                                        },
                                    }),
                                    groupHeading: (provided) => ({
                                        ...provided,
                                        color: '#484848',
                                        marginBottom: '2px',
                                        backgroundColor: '#ffffff',
                                        fontWeight: '600'
                                    }),
                                    group: (provided) => ({
                                        ...provided,
                                        background: '#ffffff'
                                    }),
                                    input: (baseStyles) => ({
                                        ...baseStyles,
                                        color: '#484848',
                                        paddingLeft: '10px'
                                    }),
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        width: searchWidth,
                                        margin: 'auto',
                                        borderRadius: '40px',
                                        border: '1px solid #484848',
                                        color: '#484848',
                                        fontSize: '1rem',
                                        backgroundColor: '#eeeeee',
                                        boxShadow: 'none',
                                        // height: 30,
                                        // minHeight: 30,
                                        height: 40,
                                        minHeight: 40,
                                        '&:hover': {
                                            borderColor: '#484848',
                                        },
                                        '&:focus': {
                                            borderColor: '#484848',
                                            outline: 'none',
                                        },
                                    }),
                                    singleValue: (base, state) => ({
                                        ...base,
                                        color: '#484848',
                                        paddingLeft: '10px'
                                    }),
                                    placeholder: (baseStyles) => ({
                                        ...baseStyles,
                                        color: '#484848',
                                        paddingLeft: '10px'
                                    }),
                                }}
                            />
                        </div>
                        :
                        <div className="flex space-between align-center" style={{ width: '100%' }}>
                            {/* <div></div> */}
                            <div className='flex align-center'>
                                {
                                    loggedInUser &&
                                    <div
                                        id='mobile-profile-navigation'
                                        onClick={() => { handleMobileNav('profile') }}
                                        title='Profile'
                                        className={`header-search-mobile-nav flex align-center ${selectedPage === 'profile' ? 'selected-page-btn' : ''}`}
                                    >{userIcon}
                                    </div>
                                }
                                {
                                    loggedInUser &&
                                    <div
                                        id='mobile-portfolio-navigation'
                                        onClick={() => { handleMobileNav('portfolio') }}
                                        title='Portfolio'
                                        className={`header-search-mobile-nav flex align-center ${selectedPage === 'portfolio' ? 'selected-page-btn' : ''}`}
                                    >{portfolioIcon}
                                    </div>
                                }
                                <div
                                    id='mobile-macro-navigation'
                                    onClick={() => { handleMobileNav('macro') }}
                                    title='Macro'
                                    className={`header-search-mobile-nav flex align-center ${selectedPage === 'macro' ? 'selected-page-btn' : ''}`}
                                >{macroIcon}
                                </div>
                            </div>
                            <div
                                className='header-search-mobile-nav flex center-center'
                                style={{ fontSize: '2rem', cursor: 'pointer' }}
                                onClick={() => setIsSearchBarOpen(true)}>
                                {searchIcon}
                            </div>
                        </div>
                }

            </div>
        );
    };

    const RegularSelect = () => {
        return (
            <div className={`header-search-container flex column`}>
            <Select
                ref={reactSelectRef}
                menuIsOpen={displayOptions}
                placeholder='Search'
                className='header-search-bar'
                noOptionsMessage={() => 'Fetching...'}
                loadingMessage={() => 'Fetching...'}
                options={options}
                value={selectedOption}
                inputValue={searchInput}
                onChange={handleChange}
                onInputChange={(value) => setSearchInput(value)}
                defaultOptions={false}
                components={{
                    DropdownIndicator: CustomDropdownIndicator,
                    Option: (props) => (
                        <CustomOption {...props} onSelect={handleChange} onInputChange={setSearchInput} />
                    ),
                }}
                styles={{
                    // dropdownIndicator: () => ({ color: '#484848' }),
                    // dropdownIndicator: {loginIcon},
                    menu: (base) => ({
                        ...base,
                        width: isMobile ? '90%' : '350px',
                        marginTop: '10px',
                        left: '49%',
                        transform: 'translateX(-50%)',
                        borderRadius: '8px',
                        zIndex: 100,
                    }),
                    option: (base, state) => ({
                        ...base,
                        width: '100%',
                        textAlign: 'start',
                        padding: '10px',
                        backgroundColor: '#ffffff',
                        color: state.isSelected ? '#326fb5' : '#484848',
                        fontWeight: state.isSelected ? '600' : '500',
                        borderBottom: state.isFocused && !state.isLast ? '2px solid #EEEEEE' : 'none',
                        '&:not(:last-child)': {
                            borderBottom: '2px solid #EEEEEE',
                        },
                        '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: '#f1f6fc'
                        },
                    }),
                    groupHeading: (provided) => ({
                        ...provided,
                        color: '#484848',
                        marginBottom: '2px',
                        backgroundColor: '#ffffff',
                        fontWeight: '600'
                    }),
                    group: (provided) => ({
                        ...provided,
                        background: '#ffffff'
                    }),
                    input: (baseStyles) => ({
                        ...baseStyles,
                        color: '#484848',
                        paddingLeft: '10px'
                    }),
                    control: (baseStyles) => ({
                        ...baseStyles,
                        width: searchWidth,
                        margin: 'auto',
                        borderRadius: '40px',
                        border: '1px solid #484848',
                        color: '#484848',
                        fontSize: '1rem',
                        backgroundColor: '#eeeeee',
                        boxShadow: 'none',
                        // height: 30,
                        // minHeight: 30,
                        height: 40,
                        minHeight: 40,
                        '&:hover': {
                            borderColor: '#484848',
                        },
                        '&:focus': {
                            borderColor: '#484848',
                            outline: 'none',
                        },
                    }),
                    singleValue: (base, state) => ({
                        ...base,
                        color: '#484848',
                        paddingLeft: '10px'
                    }),
                    placeholder: (baseStyles) => ({
                        ...baseStyles,
                        color: '#484848',
                        paddingLeft: '10px'
                    }),
                }}
            />
        </div>
        );
    };

    return (
        <>
            {
                isMobile ?
                    <MobileSelect /> : <RegularSelect />
                  
            }
        </>
    );
}

export default HeaderSearch;



// options = [
//     {
//         "label": "indices",
//         "options": [
//             {
//                 "label": "(AXJO) S&P/ASX 200",
//                 "value": "AXJO",
//                 "url": "index",
//                 "pngUrl": "https://singles-pcts.s3.amazonaws.com/default.jpg?AWSAccessKeyId=A15"
//             },
//             {
//                 "label": "(IXIC) NASDAQ Composite",
//                 "value": "IXIC",
//                 "url": "index",
//                 "pngUrl": "https://singles-pcts.s3.amazonaws.com/default.jpg?AWSAccessKeyId=AS15"
//             }
//         ]
//     },
//     {
//         "label": "companies",
//         "options": [
//             {
//                 "label": "(A) Agilent Technologies, Inc.",
//                 "value": "A",
//                 "url": "company",
//                 "pngUrl": "https://singles-pcts.s3.amazonaws.com/A.png?AWSAc15"
//             },
//             {
//                 "label": "(ABNB) Airbnb, Inc.",
//                 "value": "ABNB",
//                 "url": "company",
//                 "pngUrl": "https://singles-pcts.s3.amazonaws.com/ABNB.png?AWSAccessKeyId=A96616"
//             }
//         ]
//     }
// ]