import BasicSelectOption from "../../../shared/basic-select-option";

const PortfolioSensitivity = () => {

    const periodOptions = [
        {
            value: '1 Week',
            label: '1 Week',
        },
        {
            value: '1 Month',
            label: '1 Month',
        },
        {
            value: '6 Months',
            label: '6 Months',
        },
        {
            value: '1 Year',
            label: '1 Year',
        }
    ];


    return (
        <>
            <h2 className={'guide-title'}>Portfolio - Sensitivity</h2>
            <ul className={"guide-ul"}>
                <li>Conduct scenario analysis and Monte Carlo simulations.
                </li>
                <li>Explore expected portfolio performance under different scenarios.
                </li>
                <li>Analyze sensitivity through beta values, Markov Chains probabilities, and regression analysis.
                </li>
            </ul>
            <h4 className={"guide-subtitle"}>Filters:</h4>
            <ul className={"guide-ul"}>
                <li>Time Frames: Choose time periods for analysis.
                </li>
            </ul>
            <div className="flex wrap justify-center" style={{ marginBottom: '20px' }}>
            <BasicSelectOption options={periodOptions} title="Period" />
            </div>
            <h4 className={"guide-subtitle"}>Visualizations:</h4>
            <ul className={"guide-ul"}>
                <li>Scenario Analysis - Monte Carlo Simulation Distribution: Shows future scenarios for expected portfolio performance based on historical data, for 5 days, 30 days, and 90 days.
                </li>
                <li>Monte Carlo Simulation: A statistical technique used to model the probability of different outcomes in a process that cannot be easily predicted due to the presence of random variables.
                </li>
                <li>Expected Value Over Time Line Chart: Illustrates the expected portfolio value over time based on historical filtered data, with residual boundaries.
                </li>
                <li>Sensitivity Analysis Table: Provides beta values for each symbol to the S&P 500, Markov Chains probabilities for price movements, and regression analysis results for relationships between open price, volume, and close price.
                </li>
            </ul>
            <div className="flex flex-end">
                <a href="/portfolio/sensitivity" className="guide-btn btn btn-border" title="Sensitivity">Go to page</a>
            </div>
        </>
    );
}

export default PortfolioSensitivity;