import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { setGlobalIsLoading } from "../../../store/loading/loading-actions";
import ActivePageSelection from "../../../shared/active-page/active-page";
import { getPortfolioPage } from "../../../shared/actions/portfolio-actions";
import PortfolioMenu from "../portfolio-menu/portfolio-menu";
import PortfolioPeriodFilter from "../portfolio-filters/portfolio-period-filter";
import Select from 'react-select';
import { customStyles, DropdownIndicator } from "../../../shared/dropdown-styles";
import { dropdownIcon } from "../../../shared/icons/icons";
import { SectorsGuide } from "../../../cmps/page-guide";
import PortfolioCompaniesSectorsPage from "../portfolio-pages/portfolio-companies-sectors-page";
import PortfolioFilters from "../cmps/filters";
import LoadingSpinner from "../../../shared/loading-spinner/loading-spinner";

const PortfolioSectors = ({
    loggedInUser,
    myPortfolio,
    mySelectedPortfolio,
    portfolio,
    portfolioSectorData,
    handleChangePortfolioSectorsData,
    addStock,
    addStocks,
    handleChangeSelectedPortfolio,
    showTutorial,
    portfolioFilters,
    handleChangePortfolioFilters,

    portfolioFullCompanyOptions,
    portfolioCompanyOptions,
    portfolioActiveCompanyOptions,
    portfolioSectorOptions,
    portfolioActiveSectorOptions,
    handleChangePortfolioFullCompanyOptions,
    handleChangePortfolioCompanyOptions,
    handleChangePortfolioActiveCompanyOptions,
    handleChangePortfolioSectorOptions,
    handleChangePortfolioActiveSectorOptions
}) => {

    const [sectorData, setSectorData] = useState(null);

    const pages = ['companies', 'sectors', 'fundamental', 'descriptive', 'sensitivity', 'guide'];

    const loadingSelector = useSelector((state) => state.loading.isLoading);
    const dispatch = useDispatch();
    const history = useHistory();

    const [periodOptions, setPeriodOptions] = useState([
        { value: 'oneWeek', label: '1 Week' },
        { value: 'oneMonth', label: '1 Month' },
        { value: 'sixMonth', label: '6 Months' },
        { value: 'currentYear', label: 'YTD' },
        { value: 'oneYear', label: '1 Year' },
        { value: 'fiveYear', label: '5 Years' },
        { value: 'max', label: 'Max' },
    ]);

    const [showPeriodOptions, setShowPeriodOptions] = useState(false);
    const [showCompanyOptions, setShowCompanyOptions] = useState(false);
    const [showSectorOptions, setShowSectorOptions] = useState(false);

    useEffect(() => {
        if (!myPortfolio) return;
        const fetchData = async () => {
            if (portfolioSectorData) {
                setSectorData(portfolioSectorData);
            } else {
                const res = await getPortfolioPage('sectors', myPortfolio.portfolioData);

                if (!portfolioFilters) {
                    const uniqueSectors = Array.from(new Set(res.sectorPage.map(item => item.sector)));
                    const uniqueCompanies = [];
                    res.sectorPage.forEach(item => {
                        if (item.companies && Array.isArray(item.companies)) {
                            item.companies.forEach(company => {
                                if (!uniqueCompanies.includes(company)) {
                                    uniqueCompanies.push(company);
                                }
                            });
                        }
                    });

                    const portfolioFilters = {
                        period: { value: "oneWeek", label: '1 Week' },
                        sectors: uniqueSectors,
                        companies: uniqueCompanies
                    };
                    handleChangePortfolioFilters(portfolioFilters);
                };

                if (!portfolioCompanyOptions || !portfolioFullCompanyOptions) {
                    const uniqueCompanyOptions = Array.from(new Set(res.sectorPage.map(item => ({
                        label: item.symbol,
                        value: item.symbol
                    }))));
                    handleChangePortfolioFullCompanyOptions(uniqueCompanyOptions);
                    handleChangePortfolioCompanyOptions(uniqueCompanyOptions);
                    handleChangePortfolioActiveCompanyOptions(uniqueCompanyOptions);
                };

                if (!portfolioSectorOptions) {
                    const uniqueSectorOptions = [];
                    const seen = new Set();

                    res.sectorPage.forEach(item => {
                        const sectorValue = item.sector;
                        if (!seen.has(sectorValue)) {
                            uniqueSectorOptions.push({
                                label: sectorValue,
                                value: sectorValue
                            });
                            seen.add(sectorValue);
                        }
                    });

                    handleChangePortfolioSectorOptions(uniqueSectorOptions);
                    handleChangePortfolioActiveSectorOptions(uniqueSectorOptions);
                };


                const sectorsData = {
                    barcharts: {},
                    piechart: [],
                    valueOverTime: []
                };

                res.sectorPage.forEach((sector) => {
                    const barChartsKeys = Object.keys(sector.barCharts);
                    // Iterate over barChartsKeys
                    barChartsKeys.forEach((chart) => {
                        // Initialize the array if it doesn't exist
                        if (!sectorsData.barcharts[chart]) {
                            sectorsData.barcharts[chart] = [];
                        }
                        // Push data into the array
                        sectorsData.barcharts[chart].push({
                            sector: sector.sector,
                            companies: sector.companies,
                            color: sector.color,
                            return: sector.barCharts[chart].return,
                            sharpe: sector.barCharts[chart].sharpe,
                            // beta: sector.barCharts[chart].beta,
                            // info: sector.barCharts[chart].information,
                            positiveDays: sector.barCharts[chart].positiveDays,
                            std: sector.barCharts[chart].std
                        });
                    });

                    sectorsData.piechart.push({
                        value: sector.pieChart,
                        sector: sector.sector,
                        companies: sector.companies,
                        color: sector.color,
                    });

                    sectorsData.valueOverTime.push({
                        value: sector.valueOverTime,
                        sector: sector.sector,
                        companies: sector.companies,
                        color: sector.color,
                    });

                });
                setSectorData(sectorsData);
                handleChangePortfolioSectorsData(sectorsData);
            };
        };
        fetchData();
    }, [myPortfolio]);

    const handleToggleOption = (option) => {
        if (option === 'guide') {
            history.push(`/portfolio`);
        } else {
            history.push(`/portfolio/${option}`);
        };
    };

    const getNavLocation = (label) => {
        return `/portfolio/${label}`;
    };

    const handleChangePeriod = (newPeriod) => {
        const newFilters = { ...portfolioFilters };
        newFilters.period = newPeriod;
        handleChangePortfolioFilters(newFilters);
    };

    const handleChangeSector = (newSectors) => {
        if (newSectors.length === 0) return;
        handleChangePortfolioActiveSectorOptions(newSectors);
        const newSectorValues = newSectors.map(sector => sector.value);
        const newFilters = { ...portfolioFilters, sectors: newSectorValues };
        handleChangePortfolioFilters(newFilters);
    };

    const handleChangeCompany = (newCompany) => {
        if (newCompany.length === 0) return;
        // setActiveCompanyOptions(newCompany);
        const newCompanyValues = newCompany.map(comapny => comapny.value);
        const newFilters = { ...portfolioFilters, companies: newCompanyValues };
        handleChangePortfolioFilters(newFilters);
    };

    const onHandleMousePeriodEnter = () => {
        setShowPeriodOptions(true);
    };

    const onHandleMousePeriodLeave = () => {
        setShowPeriodOptions(false);
    };

    const onHandleMouseSectorEnter = () => {
        setShowSectorOptions(true);
    };

    const onHandleMouseSectorLeave = () => {
        setShowSectorOptions(false);
    };

    const onHandleMouseCompanyEnter = () => {
        setShowCompanyOptions(true);
    };

    const onHandleMouseCompanyLeave = () => {
        setShowCompanyOptions(false);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow | Portfolio Sectors</title>
                <meta name="description" content="Analyze and manage your investment portfolio" />
                <link rel="canonical" href='https://www.portfollow.com/portfolio/sectors' />
            </Helmet>

            <div className="page-margin-top">
                <ActivePageSelection
                    handleToggleOption={handleToggleOption}
                    pages={pages}
                    activePage={'sectors'}
                    getNavLocation={getNavLocation}
                />
            </div>

            {
                portfolioFilters &&
                portfolioActiveCompanyOptions &&
                portfolioActiveSectorOptions &&
                <PortfolioFilters
                    activePage={'sectors'}
                    addStocks={addStocks}
                    addStock={addStock}
                    myPortfolio={myPortfolio}
                    mySelectedPortfolio={mySelectedPortfolio}
                    portfolio={portfolio}
                    handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                    loggedInUser={loggedInUser}
                    handleChangePeriod={handleChangePeriod}
                    portfolioFilters={portfolioFilters}
                    portfolioSectorOptions={portfolioSectorOptions}
                    portfolioActiveSectorOptions={portfolioActiveSectorOptions}
                    handleChangeSector={handleChangeSector}
                    portfolioCompanyOptions={portfolioCompanyOptions}
                    portfolioActiveCompanyOptions={portfolioActiveCompanyOptions}
                    handleChangeCompany={handleChangeCompany}
                />
            }


            {
                portfolioFilters && portfolioFilters.sectors && portfolioFilters.companies && sectorData ? (
                    <div className="container-height main-content-container">
                        <PortfolioCompaniesSectorsPage
                            selectedFilter={portfolioFilters}
                            sectorData={sectorData}
                            page={'sectors'}
                            portfolioData={myPortfolio.portfolioData}
                        />
                    </div>
                ) : (
                    <LoadingSpinner />
                )
            }


            <div className="card-container main-content-container" style={{ margin: '20px auto', width: '' }}>
                <SectorsGuide />
            </div>
        </>

    );
}

export default PortfolioSectors;