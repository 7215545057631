import React from 'react';
import { useMediaQuery } from "react-responsive";

const GettingStartedSlide = () => {

    const miniScreen = useMediaQuery({ maxWidth: 800 });

    return (
        <>
            <h1 className="app-tutorial-slide-title">Section 1</h1>
            <h2 className="app-tutorial-slide-title">Getting Started</h2>
            <p className="app-tutorial-p">
                Let's take a quick tour of the main features of PortFollow:
            </p>

            <p className="app-tutorial-p-strong">Social Feed:</p>
            <p className="app-tutorial-p">
                Share your thoughts and portfolio graphs with the community, and engage with posts from other users to learn and collaborate.
                <br />
                Copy interesting portfolios and check out their returns since posting for quick insights for smarter decisions.

            </p>

            <p className="app-tutorial-p-strong">Portfolio Page:</p>
            <p className="app-tutorial-p">
                Build, interact with, and analyze different sections of your portfolio to track performance and make adjustments as needed.
            </p>

            <p className="app-tutorial-p-strong">Macro Page:</p>
            <p className="app-tutorial-p">
                Explore various pages for macroeconomic analysis to understand broader market trends and factors influencing your investments.
            </p>

            <p className="app-tutorial-p-strong">Crypto Page:</p>
            <p className="app-tutorial-p">
                Analyze cryptocurrencies and track their performance to diversify your portfolio and stay updated on the latest trends in the digital asset space.
            </p>

            <p className="app-tutorial-p-strong">Calendar:</p>
            <p className="app-tutorial-p">
                Keep track of important events related to individual companies and macroeconomic factors to make timely investment decisions.
            </p>
            <p className="app-tutorial-p">
                To easily navigate through the site, use the main menu on the left side of your screen. <br />
                For smaller screens, click on the icon at the top right of your screen to access the mobile menu.
            </p>
            <div className={`flex justify-center ${miniScreen ? 'column' : ''}`}>
                <img src="/img/tutorial/nav-menu.png" alt="main menu" className={'tutorial-img tutorial-img-1'} />
                <img src="/img/tutorial/mobile-menu.png" alt="mobile menu" className={'tutorial-img tutorial-img-2'} />
            </div>
        </>
    );
}

export default GettingStartedSlide;