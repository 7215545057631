import React, { useState, useRef, useEffect } from "react";
import { awarnessIcon, harmonyIcon, hierarchyIcon, precisionIcon, resilienceIcon } from "../../../../shared/icons/icons";
import './portfolio-stats.scss';
import { useSelector } from 'react-redux';

const PortfolioStats = ({ selectedPortfolio, portfolio }) => {

    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null);
    const [currentScores, setCurrentScores] = useState(null);

    const prevScores = useSelector((state) => state.prevScores.scores);

    useEffect(() => {
        setCurrentScores(portfolio[selectedPortfolio].portfolioScores);
    }, [selectedPortfolio, portfolio]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    // const harmony = (
    //     <div className="flex column center-center">
    //         <h5>Harmony</h5>
    //         <div className="portfolio-stats-menu-icon-item">{harmonyIcon}</div>
    //         <h5>{currentScores.harmony}</h5>
    //     </div>
    // );

    // const awarness = (
    //     <div className="flex column center-center">
    //         <h5>Awarness</h5>
    //         <div className="portfolio-stats-menu-icon-item">{awarnessIcon}</div>
    //         <h5>{currentScores.awarness}</h5>
    //     </div>
    // );

    // const hierarchy = (
    //     <div className="flex column center-center">
    //         <h5>Hierarchy</h5>
    //         <div className="portfolio-stats-menu-icon-item">{hierarchyIcon}</div>
    //         <h5>{currentScores.hierarchy}</h5>
    //     </div>
    // );


    // const resilience = (
    //     <div className="flex column center-center">
    //         <h5>Resilience</h5>
    //         <div className="portfolio-stats-menu-icon-item">{resilienceIcon}</div>
    //         <h5>{currentScores.resilience}</h5>
    //     </div>
    // );

    // const precision = (
    //     <div className="flex column center-center">
    //         <h5>Precision</h5>
    //         <div className="portfolio-stats-menu-icon-item">{precisionIcon}</div>
    //         <h5>{currentScores.precision}</h5>
    //     </div>
    // );

    const [scoreDifferences, setScoreDifferences] = useState({});

    useEffect(() => {
        const calculateDifferences = () => {
            const differences = {};

            for (const key in currentScores) {
                const current = parseFloat(currentScores[key]);
                const previous = parseFloat(prevScores[key]);
                const difference = (current - previous).toFixed(1); // Ensures only 1 digit after the decimal
                differences[key] = parseFloat(difference);
            }

            return differences;
        };

        setScoreDifferences(calculateDifferences());
    }, [currentScores, prevScores]);

    const harmony = (
        <>
            {currentScores &&
                <div className="portfolio-stats-menu-data-item flex align-center space-between">
                    <div className="flex align-center">
                        <div className="portfolio-stats-menu-icon-item">{harmonyIcon}</div>
                        <h5>Harmony</h5>
                    </div>
                    <div className="flex align-center">
                        {
                            scoreDifferences.harmony !== 0 &&
                            prevScores.harmony !== null &&
                            !isNaN(scoreDifferences.harmony) &&
                            <h5
                                className="portfolio-stats-menu-score-item"
                                style={{ marginRight: '5px', fontSize: '10px' }}>
                                {scoreDifferences.harmony > 0
                                    ? `(+${scoreDifferences.harmony})`
                                    : `(${scoreDifferences.harmony})`
                                }
                            </h5>
                        }
                        <h5 className="portfolio-stats-menu-score-item">{currentScores.harmony}</h5>
                    </div>
                </div>
            }
        </>
    );

    const awarness = (
        <>
            {currentScores &&
                <div className="portfolio-stats-menu-data-item flex align-center space-between">
                    <div className="flex align-center">
                        <div className="portfolio-stats-menu-icon-item">{awarnessIcon}</div>
                        <h5>Awarness</h5>
                    </div>
                    <div className="flex align-center">
                        {
                            scoreDifferences.awarness !== 0 &&
                            prevScores.awarness !== null &&
                            !isNaN(scoreDifferences.awarness) &&
                            <h5
                                className="portfolio-stats-menu-score-item"
                                style={{ marginRight: '5px', fontSize: '10px' }}>
                                {
                                    scoreDifferences.harmony > 0
                                        ? `(+${scoreDifferences.harmony})`
                                        : `(${scoreDifferences.harmony})`
                                }
                            </h5>
                        }
                        <h5 className="portfolio-stats-menu-score-item">{currentScores.awarness}</h5>
                    </div>
                </div>
            }
        </>
    );

    const hierarchy = (
        <>
            {currentScores &&
                <div className="portfolio-stats-menu-data-item flex align-center space-between">
                    <div className="flex align-center">
                        <div className="portfolio-stats-menu-icon-item">{hierarchyIcon}</div>
                        <h5>Hierarchy</h5>
                    </div>
                    <div className="flex align-center">
                        {
                            scoreDifferences.hierarchy !== 0 &&
                            prevScores.hierarchy !== null &&
                            !isNaN(scoreDifferences.hierarchy) &&
                            <h5
                                className="portfolio-stats-menu-score-item"
                                style={{ marginRight: '5px', fontSize: '10px' }}>
                                {
                                    scoreDifferences.hierarchy > 0
                                        ? `(+${scoreDifferences.hierarchy})`
                                        : `(${scoreDifferences.hierarchy})`
                                }
                            </h5>
                        }
                        <h5 className="portfolio-stats-menu-score-item">{currentScores.hierarchy}</h5>
                    </div>
                </div>
            }
        </>
    );


    const resilience = (
        <>
            {currentScores &&
                <div className="portfolio-stats-menu-data-item flex align-center space-between">
                    <div className="flex align-center">
                        <div className="portfolio-stats-menu-icon-item">{resilienceIcon}</div>
                        <h5>Resilience</h5>
                    </div>
                    <div className="flex align-center">
                        {
                            scoreDifferences.resilience !== 0 &&
                            prevScores.resilience !== null &&
                            !isNaN(scoreDifferences.resilience) &&
                            <h5
                                className="portfolio-stats-menu-score-item"
                                style={{ marginRight: '5px', fontSize: '10px' }}>
                                {
                                    scoreDifferences.resilience > 0
                                        ? `(+${scoreDifferences.resilience})`
                                        : `(${scoreDifferences.resilience})`
                                }
                            </h5>
                        }
                        <h5 className="portfolio-stats-menu-score-item">{currentScores.resilience}</h5>
                    </div>
                </div>
            }
        </>
    );

    const precision = (
        <>
            {currentScores &&
                <div className="portfolio-stats-menu-data-item flex align-center space-between">
                    <div className="flex align-center">
                        <div className="portfolio-stats-menu-icon-item">{precisionIcon}</div>
                        <h5>Precision</h5>
                    </div>
                    <div className="flex align-center">
                        {
                            scoreDifferences.precision !== 0 &&
                            prevScores.precision !== null &&
                            !isNaN(scoreDifferences.precision) &&
                            <h5
                                className="portfolio-stats-menu-score-item"
                                style={{ marginRight: '5px', fontSize: '10px' }}>
                                {
                                    scoreDifferences.precision > 0
                                        ? `(+${scoreDifferences.precision})`
                                        : `(${scoreDifferences.precision})`
                                }
                            </h5>
                        }
                        <h5 className="portfolio-stats-menu-score-item">{currentScores.precision}</h5>
                    </div>
                </div>
            }
        </>
    );


    return (
        <>
            {
                currentScores &&
                <div className="portfolio-stats-container" ref={containerRef}>
                    {/* <div className="flex space-between">
                        {harmony} {hierarchy}
                    </div>
                    <div className="flex center-center">
                        {awarness}
                    </div>
                    <div className="flex space-between">
                        {precision} {resilience}
                    </div> */}
                    {harmony}
                    {hierarchy}
                    {awarness}
                    {precision}
                    {resilience}
                </div>
            }
        </>
    );
}

export default PortfolioStats;