import { leftArrow, leftFillArrow, rightArrow, rightFillArrow } from '../../../../../shared/icons/icons';
import './create-fund-banner.scss';
import React, { useState } from 'react';

const CreateFundBanners = ({ banners, handleChangeBanner }) => {

    const [startIdx, setStartIdx] = useState(0);

    const numToShow = 5;

    const handleClickNext = () => {
        const newStartIdx = (startIdx + 1) % banners.length;
        setStartIdx(newStartIdx);
    };

    const handleClickPrev = () => {
        const newStartIdx = (startIdx === 0 ? banners.length : startIdx) - 1;
        setStartIdx(newStartIdx);
    };

    const visibleBanners = [...banners.slice(startIdx), ...banners.slice(0, startIdx)]
        .slice(0, numToShow);

    const onChangeSelectedBanner = (bannerName) => {
        handleChangeBanner(bannerName);
    };

    const [isHoverNext, setIsHoverNext] = useState(false);

    const handleMouseEnterNext = () => {
        setIsHoverNext(true);
    };

    const handleMouseLeaveNext = () => {
        setIsHoverNext(false);
    };

    const [isHoverPrev, setIsHoverPrev] = useState(false);

    const handleMouseEnterPrev = () => {
        setIsHoverPrev(true);
    };

    const handleMouseLeavePrev = () => {
        setIsHoverPrev(false);
    };

    return (
        <div className="create-fund-banner-carousel flex align-center m0auto">
            <div
                className="create-fund-banner-btn"
                onMouseEnter={handleMouseEnterPrev}
                onMouseLeave={handleMouseLeavePrev}
                style={{marginRight: '10px'}}
                onClick={handleClickPrev}>
                {isHoverPrev ? leftFillArrow : leftArrow}
            </div>
            <div className="flex">
                {visibleBanners.map((banner, idx) => (
                    <img
                        key={idx}
                        className="create-fund-banner-item"
                        src={banner.pngUrl}
                        onClick={() => onChangeSelectedBanner(banner)}
                    />
                ))}
            </div>
            {/* <div className="create-fund-banner-btns"> */}
            <div
                className="create-fund-banner-btn"
                onMouseEnter={handleMouseEnterNext}
                onMouseLeave={handleMouseLeaveNext}
                style={{marginLeft: '10px'}}
                onClick={handleClickNext}>
                {isHoverNext ? rightFillArrow : rightArrow}
            </div>
            {/* </div> */}
        </div>
    );
}

export default CreateFundBanners;

