export async function getHomepageData() {
    var requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        redirect: 'follow'
    };

    try {
        const response = await fetch("https://ardoq2hsx6.execute-api.us-east-1.amazonaws.com/getMovingHeader", requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function getHomepagePosts(loggedInUser, seen) {
    // need to modify hardcoded user
    // ---------------------------------------------------------------
    const user = loggedInUser ? loggedInUser : 'WealthCrafting';
    // ---------------------------------------------------------------

    try {
        var raw = JSON.stringify({
            "username": user,
            "alreadySeen": seen
        });

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: raw,
            redirect: 'follow'
        };
        const response = await fetch(
            "https://ced6gzrrib.execute-api.us-east-1.amazonaws.com/getPosts",
            requestOptions
        );

        if (!response.ok) {
            console.log('FAIL:', response.status, response);
            return null;
        }

        const result = await response.text();
        const parsedData = JSON.parse(result);
        if (parsedData) {
            const posts = parsedData;
            const postsIds = parsedData.map(post => post.postid);
            return {
                posts,
                postsIds
            };
        } else {
            console.error('Invalid response format', response, result);
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function getHomepageTrending(loggedInUser) {
    const username = loggedInUser ? loggedInUser : 'WealthCrafting';
    try {
        var raw = JSON.stringify({
            "username": 'WealthCrafting'
        });

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            body: raw,
        };
        const response = await fetch("https://w95osmou19.execute-api.us-east-1.amazonaws.com/trendsHomepage", requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

// -----------------------------------------------------------

export async function setLikeCommentCopyShare(loggedInUser, userId, postId, action, comment) {

    try {
        var raw = JSON.stringify({
            "username": loggedInUser,
            "userid": userId,
            "postid": postId,
            "action": action,
            "comment": comment
        });

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: raw,
            redirect: 'follow'
        };
        const response = await fetch(
            "https://k027dfzczl.execute-api.us-east-1.amazonaws.com/setLikeCommentCopyShare",
            requestOptions
        );

        if (!response.ok) {
            console.log('FAIL:', response.status, response);
            return null;
        }

        const result = await response.text();
        const parsedData = JSON.parse(result);
        if (parsedData) {
            const post = parsedData;
            return {
                post,
            };
        } else {
            console.error('Invalid response format', response, result);
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function setNewPost(postId, loggedInUser, userId, text, selectedPortfolio, orders, graphs, time) {

    const ordersWithStrings = orders.map(order => ({
        ...order,
        price: order.price.toString(),
        amount: order.amount.toString()
    }));

    if (time.endsWith('Z')) {
        time = time.slice(0, -1);
    };

    try {

        var raw = JSON.stringify({
            "postid": postId,
            "username": loggedInUser,
            "userid": userId,
            "text": text,
            "selectedPortfolio": selectedPortfolio,
            // "orders_list": [],
            "orders_list": ordersWithStrings,
            // "orders_list": [
            //     {
            //         "symbol": "LHX",
            //         "amount": "14",
            //         "buyDate": "2023-03-24",
            //         "price": "190.73"
            //     },
            //     {
            //         "symbol": "K",
            //         "amount": "5",
            //         "buyDate": "2023-07-03",
            //         "price": "63.96"
            //     }
            // ],
            "graphs": graphs,
            "time": time
        });

        // console.log('post raw:', raw);

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: raw,
            redirect: 'follow'
        };

        const response = await fetch(
            "https://6j1mp1dg4a.execute-api.us-east-1.amazonaws.com/setNewPost",
            requestOptions
        );

        if (!response.ok) {
            console.log('FAIL:', response.status, response);
            return null;
        }

        const result = await response.text();
        const parsedData = JSON.parse(result);

        if (parsedData) {
            const post = parsedData;
            return post;
        } else {
            console.error('Invalid response format', response, result);
            return null;
        }
    } catch (error) {
        console.error('Error saving post:', error);
        return null;
    }
};

// -------------------------------------------------

export async function setFeedback({ loggedInUser, name, email, feedback }) {
    const username = loggedInUser ? loggedInUser : '';

    try {
        var raw = JSON.stringify({
            "username": username,
            "name": name,
            "email": email,
            "feedback": feedback
        });

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            body: raw,
        };
        const response = await fetch("https://wgf0g7u1kf.execute-api.us-east-1.amazonaws.com/setFeedback", requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

// --- challenge ---
export async function checkChallengeCompletion({
    username,
    id,
    completed,
    ordersList,
    mySelectedPortfolio,
    challengeInput,
    userChallenges
}) {

    const challengesCompleted = userChallenges.challengesCompleted || [];

    try {
        var raw = JSON.stringify({
            "username": username,
            "id": id,
            "completed": completed,
            "ordersList": ordersList,
            "selectedPortfolio": mySelectedPortfolio,
            "challengeInput": challengeInput,
            "challengesCompleted": challengesCompleted
        });

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            body: raw,
        };
        const response = await fetch("https://xukhqsb8th.execute-api.us-east-1.amazonaws.com/getChallenge", requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

// signup function
export async function setPortfollowUser(user) {
    try {

        const specialCharPattern = /[^a-zA-Z0-9]/;
        if (specialCharPattern.test(user.username)) {
            return null;
        }

        var raw = JSON.stringify({
            ...Object.fromEntries(Object.entries(user).map(([key, value]) => [String(key), value]))
        });

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: raw,
            redirect: 'follow'
        };

        const response = await fetch(
            "https://g1mw6tr8h8.execute-api.us-east-1.amazonaws.com/set-raw-user",
            requestOptions
        );

        if (!response.ok) {
            console.log('FAIL:', response.status, response);
            return null;
        }

        const result = await response.text();
        const parsedData = JSON.parse(result);
        if (parsedData) {
            return parsedData;
        } else {
            console.error('Invalid response format', response, result);
            return null;
        }
    } catch (error) {
        console.error('Error saving user:', error);
        return null;
    }
};