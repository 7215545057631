import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import FollowBtn from '../../../shared/follow-btn/follow-btn';
import { arrowUpIcon, arrowDownIcon } from "../../../shared/icons/icons";
import './single-company-header.scss';

const SingleCompanyHeader = ({ loggedInUser, toggleFollowing, userFollowing, company, hasFinancialStatements }) => {

    const [showErrMsg, setErrMsg] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 850);
    const [isScreenSizeMedium, setIsScreenSizeMedium] = useState(window.innerWidth < 920);

    useEffect(() => {
        const handleResize = () => {
            setIsScreenSizeMedium(window.innerWidth < 920);
            setIsMobile(window.innerWidth < 850);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getCamelCaseSector = (str) => {
        const cleanedString = str.replace(/\s+/g, '');
        const camelCaseString = cleanedString.replace(/[-_]\w/g, match => match.charAt(1).toUpperCase());
        return camelCaseString.charAt(0).toLowerCase() + camelCaseString.slice(1);
    };

    return (
        <div className={`single-company-header main-content-container flex space-between ${isScreenSizeMedium ? 'center-center column' : ''}`}>
            <div className={`single-company-header-item flex ${isScreenSizeMedium ? 'center-center column' : ''}`}>
                <div className='single-company-header-main flex column justify-center'>
                    <div className='single-company-header-item-name flex'>
                        <h1 className='single-company-title single-item-title'>
                            <span className='single-company-symbol single-item-symbol'>
                                {`(${company.symbol})`}
                            </span>
                            {`${company.company}`}
                        </h1>
                        <div className='single-company-price-info flex align-end'>
                            {
                                company.lastPriceChange.includes('+') || company.lastPricePercentChange.includes('+') ? (
                                    <>
                                        <div className="single-company-price-arrow single-company-price-arrow-up flex align-end" style={{height: '100%', paddingBottom: '5px'}}>
                                            {arrowUpIcon}
                                        </div>
                                        {company.lastPriceChange} ({company.lastPricePercentChange})

                                    </>
                                ) : (
                                    <>
                                        <div className="single-company-price-arrow single-company-price-arrow-down flex align-end" style={{height: '100%', paddingBottom: '5px'}}>
                                            {arrowDownIcon}
                                        </div>
                                        {company.lastPriceChange} ({company.lastPricePercentChange})

                                    </>
                                )
                            }
                        </div>
                    </div>

                    <div className="single-company-header-info-container flex">
                        <div
                            className="single-company-sector-clickable"
                        >
                            <h2>
                                <Link
                                    to={`/sector/${getCamelCaseSector(company.sector)}`}
                                    className='portfolio-graphs-info-item'>
                                    {company.sector} Sector
                                </Link>
                            </h2>
                        </div>
                        {
                            company.index && (
                                <div
                                    className={`single-company-header-info-item ${isScreenSizeMedium ? 'm0auto' : ''}`}
                                >
                                    {company.index} Index
                                </div>
                            )
                        }
                    </div>
                    {
                        company.industry && (
                            <div
                                className={`${isScreenSizeMedium ? 'm0auto' : ''}`}
                            >

                                <h3 style={{marginBottom: '5px'}}>
                                    {company.industry} Industry
                                </h3>
                            </div>

                        )
                    }
                    <div className="single-company-header-info-container flex">
                        <div className="single-company-currency-in">Currency in {company.currency}</div>
                        <div className="single-company-header-info-item">{company.date}</div>
                    </div>

                    <div className={`flex ${isScreenSizeMedium ? 'single-company-header-margin' : ''}`}>
                        <div className='single-company-price-info'>{company.lastPrice}</div>
                    </div>

                    {/* </div> */}
                </div>

            </div>

            <FollowBtn
                loggedInUser={loggedInUser}
                toggleFollowing={toggleFollowing}
                symbol={company.symbol}
                userFollowing={userFollowing}
                setErrMsg={setErrMsg}
                isMobile={isScreenSizeMedium}
                url={company.url}
                isScreenSizeMedium={isScreenSizeMedium}
                hasFinancialStatements={hasFinancialStatements}
            />
            {
                showErrMsg &&
                <div
                    className={`financial-statements-header-follow-msg financial-statements-header-follow-msg 
                 ${isScreenSizeMedium ? 'financial-statements-header-follow-msg-mobile card-container' : 'flex flex-end'}`}
                >
                    You must be logged in to follow companies.
                </div>
            }
        </div>
    );
}

export default SingleCompanyHeader;