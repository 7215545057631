import React, { useState, useEffect } from "react";
import './manage-fund-matchweek.scss';
import { formatNumberToDollarTableString, getRandomId } from "../../../../shared/global-color-functions";
import PortfolioInputsRow from "../../../portfolio/manage-portfolio/portfolio-inputs-row";
import { closeIcon } from "../../../../shared/icons/icons";
import PortfolioInputsRowMobile from "../../../portfolio/manage-portfolio/portfolio-inputs-row-mobile";
import { useMediaQuery } from 'react-responsive';
import ManageFundMatchweekInputs from "./manage-fund-matchweek-inputs";

const ManageFundMatchweek = ({
    fundMatchweek,
    handleOrdersListChange,
    memberPortfolioData,
    todayDate,
    addStocks,
    companiesPrices
}) => {

    console.log('MANAGE - fundMatchweek:', memberPortfolioData);

    const isSmallScreen = useMediaQuery({ maxWidth: 700 });

    const [memberCoins, setMemberCoins] = useState(0);
    const [error, setError] = useState('');

    // useEffect(() => {
    //     if (memberPortfolioData && memberPortfolioData.memberData && memberPortfolioData.memberData.coins) {
    //         const coinsNumber = parseFloat(memberPortfolioData.memberData.coins.replace(/,/g, '')); // removing commas if present
    //         setMemberCoins(coinsNumber);
    //     }
    // }, [memberPortfolioData]);

    const [addedOrders, setAddedOrders] = useState([{
        symbol: '',
        amount: '',
        buyDate: '',
        price: 0,
        orderId: getRandomId()
    }]);

    const [newOrders, setNewOrders] = useState([]);

    useEffect(() => {
        const initialCoins = 100000;
        const updatedPortfolio = [...newOrders];
        const cashIndex = updatedPortfolio.findIndex(order => order.symbol === 'cash');
        if (cashIndex !== -1) {
            updatedPortfolio.splice(cashIndex, 1);
        }
        const totalSpentCoins = updatedPortfolio.reduce((total, order) => {
            return total + (order.price * order.amount);
        }, 0);
        const remainingCoins = initialCoins - totalSpentCoins;
        setMemberCoins(remainingCoins);
    }, [memberPortfolioData, newOrders]);


    useEffect(() => {
        const portfolioData = memberPortfolioData.memberData.ordersList;
        if (!portfolioData || portfolioData.length === 0) {
            setNewOrders([]);
        } else {
            const portfolioCopy = [...portfolioData];
            setNewOrders(portfolioCopy);
        };
    }, []);

    const onHandleOrderChange = (orderId, propertyName, newValue) => {
        setAddedOrders((prevOrders) => {
            return prevOrders.map((order) =>
                order.orderId === orderId ? { ...order, [propertyName]: newValue } : order
            );
        });
    };

    const onSaveRow = (order) => {
        const orderIndex = addedOrders.findIndex((addedOrder) => addedOrder.orderId === order.orderId);
        if (orderIndex !== -1) {
            setAddedOrders((prevOrders) => {
                const updatedOrders = [...prevOrders];
                updatedOrders[orderIndex] = {
                    ...updatedOrders[orderIndex],
                    symbol: order.symbol,
                    amount: order.amount,
                    date: order.date,
                    price: order.price,
                };
                return updatedOrders;
            });
        }
    };

    const onSubmitChanges = () => {
        if (newOrders.length <= 1) return;
        addStocks(newOrders);
        setAddedOrders(
            [
                {
                    symbol: '',
                    amount: '',
                    buyDate: '',
                    price: 0,
                    orderId: getRandomId()
                }
            ]
        );
        setNewOrders([]);
    };

    const onSaveRows = (idx, updatedRow) => {
        const totalCost = updatedRow.amount * updatedRow.price;
        // console.log('memberCoins:', memberCoins);
        // console.log('totalCost:', totalCost);
        if (memberCoins >= totalCost) {
            const updatedPortfolio = [...newOrders];
            updatedPortfolio.push(updatedRow);
            setNewOrders(updatedPortfolio);
            const cashIndex = updatedPortfolio.findIndex(order => order.symbol === 'cash');
            if (cashIndex !== -1) {
                updatedPortfolio.splice(cashIndex, 1);
            }
            addStocks(updatedPortfolio);
            setAddedOrders((prevRows) =>
                prevRows.filter((row, i) => i !== idx)
            );
            // setNewOrders((prevOrders) => [updatedRow, ...prevOrders]);
            setAddedOrders([{
                symbol: '',
                amount: '',
                buyDate: '',
                price: 0,
                orderId: getRandomId()
            }]);
            // Deduct the spent coins from memberCoins
            console.log('Remaining coins:', memberCoins - totalCost);
            setMemberCoins((prevCoins) => prevCoins - totalCost);
            setError('');
        } else {
            console.log('Insufficient coins to complete this transaction.');
            setError('Insufficient coins to complete this transaction.');
        }
    };

    const removeOrderFromAdded = (orderId) => {
        setAddedOrders((prevOrders) => prevOrders.filter((order) => order.orderId !== orderId));
    };

    const removeOrderFromNew = (orderId) => {
        // Check if the remaining number of orders after removal is less than 2
        if (newOrders.length - 1 < 2) return;
        // Create a copy of newOrders
        const updatedPortfolio = [...newOrders];
        const cashIndex = updatedPortfolio.findIndex(order => order.symbol === 'cash');
        if (cashIndex !== -1) {
            updatedPortfolio.splice(cashIndex, 1);
        }
        // Find and remove the item according to orderId
        const orderToRemove = updatedPortfolio.find(order => order.orderId === orderId);
        if (!orderToRemove) return;
        const filteredOrders = updatedPortfolio.filter(order => order.orderId !== orderId);
        // Calculate the total cost of the removed order
        const totalCost = orderToRemove.amount * orderToRemove.price;
        // Update memberCoins state by adding the total cost back
        setMemberCoins(prevCoins => prevCoins + totalCost);
        // Update newOrders state
        setNewOrders(filteredOrders);
        // Run function addStocks and pass the updated portfolio
        addStocks(filteredOrders);
    };


    const onCancelChanges = () => {
        const portfolioData = memberPortfolioData.memberData.ordersList;
        const portfolioCopy = [...portfolioData];
        setNewOrders(portfolioCopy);
    };

    const PortfolioTable = () => {
        return (
            <div className="findatasheets-table">
                <div className="findatasheets-table-header flex space-between portfolio-inputs-row-row">
                    <div style={{ width: '20px' }}></div>
                    <div className="findatasheets-table-cell findatasheets-table-title flex align-end" style={{ width: '25%' }}>Symbol</div>
                    <div className="findatasheets-table-cell findatasheets-table-title flex align-end" style={{ width: '25%' }}>Amount</div>
                    <div className="findatasheets-table-cell findatasheets-table-title flex align-end" style={{ width: '25%' }}>Buy Date</div>
                    <div className="findatasheets-table-cell findatasheets-table-title flex align-end" style={{ width: '25%' }}>Price</div>
                </div>


                {/* {
                    addedOrders.map((order, idx) => (
                        <ManageFundMatchweekInputs
                            key={idx}
                            idx={idx}
                            order={order}
                            onSaveRows={onSaveRows}
                            removeOrderFromAdded={removeOrderFromAdded}
                            getRandomId={getRandomId}
                            onSaveRow={onSaveRow}
                            onHandleOrderChange={onHandleOrderChange}
                            todayDate={todayDate}
                        />

                    ))
                } */}

                <div className="mb20">
                    {
                        addedOrders.map((order, idx) => (
                            <PortfolioInputsRow
                                key={idx}
                                idx={idx}
                                order={order}
                                onSaveRows={onSaveRows}
                                removeOrderFromAdded={removeOrderFromAdded}
                                getRandomId={getRandomId}
                                onSaveRow={onSaveRow}
                                onHandleOrderChange={onHandleOrderChange}
                                isFundMatchweek={true}
                                companiesPrices={companiesPrices}
                            />

                        ))
                    }
                </div>

                {error && <div className="err-msg flex justify-center" style={{ height: '40px' }}>{error}</div>}

                {
                    newOrders.map((order, idx) => (
                        order.symbol !== 'cash' &&
                        <div className={`findatasheets-table-row portfolio-inputs-row-row flex space-between ${idx % 2 === 0 ? '' : 'odd-row'}`} key={idx}>
                            <div
                                className="manage-portfolio-x btn flex align-center"
                                style={{ width: '20px', marginRight: 0 }}
                                onClick={() => removeOrderFromNew(order.orderId)}
                            >{closeIcon}
                            </div>
                            <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>{order.symbol}</div>
                            <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>{order.amount}</div>
                            <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>{order.buyDate}</div>
                            <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>${order.price}</div>
                        </div>
                    ))
                }

            </div>
        );
    };

    const buttonClass = `btn btn-border ${newOrders.length <= 1 ? 'btn-disabled' : ''}`;

    const tableLowerPart = () => {
        const commonButtons = (
            <>
                <div className="flex">
                    <div
                        className='btn  btn-border'
                        style={{ marginLeft: '20px' }}
                        onClick={onCancelChanges}
                    >CANCEL
                    </div>
                    <div
                        className={buttonClass}
                        onClick={newOrders.length > 1 ? onSubmitChanges : null}
                    >
                        SUBMIT
                    </div>
                </div>
            </>
        );

        if (newOrders.length < 3) {
            return (
                <div className="flex" style={{ marginTop: '20px', width: '100%' }}>
                    <div className="flex space-between" style={{ width: '100%' }}>
                        <div>To access your portfolio, ensure it contains a minimum of 2 items.</div>
                        {commonButtons}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="flex justify-center" style={{ marginTop: '20px' }}>
                    {commonButtons}
                </div>
            );
        }
    };

    const tableLowerPartMobile = () => {
        const commonButtons = (
            <>
                <div className="flex space-between">
                    <div
                        className='btn btn-border'
                        style={{ marginTop: '20px' }}
                        onClick={onCancelChanges}
                    >CANCEL
                    </div>
                    <div
                        className={buttonClass}
                        style={{ marginTop: '20px' }}
                        onClick={newOrders.length > 1 ? onSubmitChanges : null}
                    >
                        SUBMIT
                    </div>
                </div>
            </>
        );

        if (newOrders.length < 3) {
            return (
                <div className="flex" style={{ marginTop: '20px', width: '100%' }}>
                    <div className="flex column space-between" style={{ width: '100%' }}>
                        <div>To access your portfolio, ensure it contains a minimum of 2 items.</div>
                        {commonButtons}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="flex flex-end" style={{ marginTop: '20px' }}>
                    {commonButtons}
                </div>
            );
        }
    };

    const AlternativeContent = () => {
        return (

            <div className=" card-container flex column space-between">
                <div className='scrollable-y'>
                    {
                        addedOrders.map((order, idx) => (
                            <PortfolioInputsRowMobile
                                key={idx}
                                idx={idx}
                                order={order}
                                onSaveRows={onSaveRows}
                                removeOrderFromAdded={removeOrderFromAdded}
                                getRandomId={getRandomId}
                                onSaveRow={onSaveRow}
                                onHandleOrderChange={onHandleOrderChange}
                            />

                        ))
                    }

                    {
                        newOrders.map((order, idx) => (
                            order.symbol !== 'cash' &&
                            <div className={`findatasheets-table-row portfolio-inputs-row-row flex space-between ${idx % 2 === 0 ? '' : 'odd-row'}`} key={idx}>
                                <div
                                    className="manage-portfolio-x btn flex align-center"
                                    style={{ width: '20px', marginRight: 0 }}
                                    onClick={() => removeOrderFromNew(order.orderId)}
                                >{closeIcon}
                                </div>
                                <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>{order.symbol}</div>
                                <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>{order.amount}</div>
                                <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>{order.buyDate}</div>
                                <div className="findatasheets-table-cell feed-post-table-cell" style={{ width: '25%' }}>${order.price}</div>
                            </div>
                        ))
                    }
                </div>
                {/* <div>{tableLowerPartMobile()}</div> */}
            </div>
        );
    };

    return (
        <div className="card-container mb20">
            <div className="manage-fund-coins">Available coins: {formatNumberToDollarTableString(memberCoins)}</div>
            {isSmallScreen ? (
                <AlternativeContent />
            ) : (
                <div className="flex column space-between" style={{ height: '100%' }}>
                    <div>
                        <PortfolioTable />
                    </div>
                    {/* {tableLowerPart()} */}
                </div>
            )}
        </div>
    );
};
export default ManageFundMatchweek;