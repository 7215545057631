const FeedTable = ({ orders, blinkCmp }) => {

    return (
        <div
            className={`findatasheets-table-container ${blinkCmp === 'postexample-copymodal-incomingchanges' ? 'blink-border' : ''}`}
            style={{ padding: '0 50px' }}>
            <div class="findatasheets-table-header flex space-between">
                <div class="findatasheets-table-cell feed-post-table-cell findatasheets-table-title flex align-end">Symbol</div>
                <div class="findatasheets-table-cell feed-post-table-cell findatasheets-table-title flex align-end">Buy Date</div>
                <div class="findatasheets-table-cell feed-post-table-cell findatasheets-table-title flex align-end">Amount</div>
                <div class="findatasheets-table-cell feed-post-table-cell findatasheets-table-title flex align-end">Price</div>
            </div>

            <div className="feed-post-table">
                {orders.length === 0 ? (
                    <p style={{ padding: '10px' }}>This portfolio is empty.</p>
                ) : (
                    orders.map((order, idx) => (
                        <div className={`findatasheets-table-row flex space-between ${idx % 2 === 0 ? '' : 'odd-row'}`} key={idx}>
                            <div className="findatasheets-table-cell feed-post-table-cell">{order.symbol}</div>
                            <div className="findatasheets-table-cell feed-post-table-cell">{order.buyDate}</div>
                            <div className="findatasheets-table-cell feed-post-table-cell">{order.amount}</div>
                            <div className="findatasheets-table-cell feed-post-table-cell">${Number(order.price).toFixed(2)}</div>
                        </div>
                    ))
                )}
            </div>

        </div>
    )
}

export default FeedTable;