import React, { createContext } from "react";
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from "./user-pool";
import { useHistory } from 'react-router-dom';
import './user-pool.scss';

const AccountContext = createContext();

const Account = (props) => {

    const history = useHistory();

    const getSession = async () => {
        try {
            const user = Pool.getCurrentUser();
            if (user) {
                const session = await new Promise((resolve, reject) => {
                    user.getSession((err, session) => {
                        if (err) {
                            reject(new Error('Error getting user session'));
                        } else {
                            resolve(session);
                        }
                    });
                });

                const attributes = await new Promise((resolve, reject) => {
                    user.getUserAttributes((err, attributes) => {
                        if (err) {
                            console.log('error:', err)
                        } else {
                            const results = {};
                            for (let attribute of attributes) {
                                const { Name, Value } = attribute;
                                results[Name] = Value;
                            }
                            resolve(results);
                        }
                    });
                });
                return { user, ...session, ...attributes };
            } else {
                // Handle the case when no user is found
                return { user: null };
            }
        } catch (error) {
            console.error('Error in getSession:', error);
            throw error;
        }
    };

    const authenticate = async (Username, Password, setLoggedInUser, setLoggedInUserId) => {
        return new Promise((resolve, reject) => {
            const authenticationData = {
                Username,
                Password,
            };
            const authenticationDetails = new AuthenticationDetails(authenticationData);
            const userData = {
                Username,
                Pool,
            };
            const cognitoUser = new CognitoUser(userData);

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (session) => {
                    // Store the tokens in local storage
                    localStorage.setItem('idToken', session.getIdToken().getJwtToken());
                    localStorage.setItem('accessToken', session.getAccessToken().getJwtToken());
                    localStorage.setItem('refreshToken', session.getRefreshToken().getToken());

                    const userAttributes = session.getIdToken().payload;
                    setLoggedInUser(userAttributes['cognito:username']);
                    setLoggedInUserId(userAttributes['sub']);
                    resolve(session);
                },
                onFailure: (err) => {
                    console.error('Authentication failed', err);
                    reject(err);
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    console.log('New password required');
                    resolve({ newPasswordRequired: true });
                },
            });
        });
    };

    const logout = () => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.signOut((err) => {
                if (err) {
                    console.error('Sign-out error:', err);
                } else {
                    history.push('/');
                }
            });
        }
        localStorage.removeItem('idToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userData');
    };

    return (
        <AccountContext.Provider value={{ authenticate, getSession, logout }}>
            {props.children}
        </AccountContext.Provider>
    );
}

export { Account, AccountContext };
