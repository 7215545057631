import React, { useState, useEffect } from "react";
import SimpleFollowBtn from "../../../../shared/simple-follow-btn/simple-follow-btn";
import '../single-sector.scss';

const SingleSectorHeader = ({ loggedInUser, toggleFollowing, userFollowing, sectorHeader }) => {

    const [isFollowing, setIsFollowing] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 850);
    const [isScreenSizeMedium, setIsScreenSizeMedium] = useState(window.innerWidth < 920);

    useEffect(() => {
        const handleResize = () => {
            setIsScreenSizeMedium(window.innerWidth < 920);
            setIsMobile(window.innerWidth < 850);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const camelCaseToNormalString = (str) => {
        const spacedString = str.replace(/([a-z])([A-Z])/g, '$1 $2');
        const finalString = spacedString.charAt(0).toUpperCase() + spacedString.slice(1);
        return finalString;
    };
    
    return (
        <div
            className={`single-company-header flex ${isScreenSizeMedium ? 'center-center column' : 'space-between align-end'}`}
        >
            <div>
                <h1 className="single-sector-header single-item-title"> {camelCaseToNormalString(sectorHeader.sector)} </h1>
                <h2 style={{textAlign: 'center'}}> {sectorHeader.date} </h2>
            </div>
            <SimpleFollowBtn
                loggedInUser={loggedInUser}
                symbol={sectorHeader.sector}
                type={"sectors"}
                toggleFollowing={toggleFollowing}
                isFollowing={isFollowing}
                setIsFollowing={setIsFollowing}
                isMobile={isMobile}
                isScreenSizeMedium={isScreenSizeMedium}
                userFollowing={userFollowing}
            />

        </div>
    );
}

export default SingleSectorHeader;