import React from "react";
import './active-matchweek.scss';
import FundPlayoffs from "../../playoff/fund-playoff";

const ActiveMatchweek = ({fundMatchweek}) => {
    return ( 
        <div className="mb20">
            <FundPlayoffs fundMatchweek={fundMatchweek}/>
        </div>
     );
}
 
export default ActiveMatchweek;  