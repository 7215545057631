import { setUser, incrementFame, setSelectedPortfolio  } from "./user-slice";

export const setGlobalUser = (user) => async dispatch => {
    dispatch(setUser(user));
}

export const incrementUserFame = () => async dispatch => {
    dispatch(incrementFame());
};

export const setGlobalUserSelectedPortfolio = (portfolio) => async dispatch => {
    dispatch(setSelectedPortfolio(portfolio));
}