import React from 'react';
import { SyncLoader } from 'react-spinners';
import './loading-spinner.scss';
import { useSelector } from 'react-redux';

const LoadingSpinner = ({ loading }) => {

    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
    };

    const selectedSentence = useSelector((state) => state.loading.selectedSentence);
    
    // Get a random sentence
    // const randomIdx = getRandomIdx(loadingSentences);
    // const selectedSentence = loadingSentences[randomIdx];

    return (

        <div
            className="loader-container flex column center-center"
            style={{ minHeight: "90vh" }}
        >
            <div className='flex column center-center'>

                <SyncLoader
                    color={'#4071AB'}
                    loading={!loading}
                    cssOverride={override}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />

                <div className="selected-sentence-container" style={{width: '50%'}}>
                    <p className='selected-sentence-content'>
                        <span
                            className='selected-sentence-title'>{selectedSentence.title}:
                        </span>
                        {' ' + selectedSentence.sentence}
                    </p>
                </div>

            </div>
        </div>
    );
}

export default LoadingSpinner;