import React, { useState, useRef, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { setGlobalIsLoading } from '../../../../../store/loading/loading-actions';
import { useHistory } from 'react-router-dom';

const FeedBar = ({ item, PortfolioProfit }) => {

    const mediumScreen = useMediaQuery({ maxWidth: 920 });
    const smallScreen = useMediaQuery({ maxWidth: 700 });
    const isMobileScreen = useMediaQuery({ maxWidth: 600 });

    const dataset = item.data.datasets[0];
    const dispatch = useDispatch();
    const history = useHistory();
    const [barWidth, setBarWidth] = useState(0);
    const chartContainer = useRef(null);

    const data = {
        labels: item.data.labels,
        datasets: [
            {
                label: dataset.label,
                data: dataset.data.map((point) => parseFloat(point)),
                backgroundColor: dataset.backgroundColor,
                borderColor: dataset.borderColor,
                borderWidth: dataset.borderWidth,
                borderRadius: dataset.borderRadius,
            },
        ],
    };

    const dataValues = data.datasets[0].data.map(Number);

    const sortedIndices = [...Array(dataValues.length).keys()].sort((a, b) => dataValues[b] - dataValues[a]);

    data.datasets[0].data = sortedIndices.map(i => dataValues[i].toString());
    data.datasets[0].backgroundColor = sortedIndices.map(i => data.datasets[0].backgroundColor[i]);
    data.datasets[0].borderColor = sortedIndices.map(i => data.datasets[0].borderColor[i]);
    data.labels = sortedIndices.map(i => data.labels[i]);

    const options = {
        elements: {
            bar: {
                borderWidth: 2,
                borderRadius: 6
            },
        },
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 25
            },
        },
        plugins: {
            datalabels: {
                color: '#484848',
                anchor: 'end',
                align: 'top',
                font: {
                    size: 14,
                    family: 'Poppins-regular'
                },
            },
            title: {
                display: false
            },
            legend: {
                display: false
            },
            tooltip: {
                displayColors: false,
                backgroundColor: '#ffffff',
                titleColor: '#484848',
                borderWidth: '1',
                borderColor: '#484848',
                bodyColor: '#484848',
                cornerRadius: '20',
                padding: '10',
                callbacks: {
                    title: (tooltipItem) => {
                        return item.data.hover[tooltipItem[0].dataIndex][0];
                    },
                    label: (tooltipItem) => {
                        const hoverData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex];
                        const line1 = item.data.hover[tooltipItem.dataIndex][1];
                        const line2 = hoverData + '%';
                        return [line1, line2];
                    }
                }
            },
            zoom: {
                zoom: {
                    enabled: false
                },
                pan: {
                    enabled: false
                }
            },
        },
        scales: {
            xAxes: [{
                ticks: {
                    autoSkip: false,
                    font: {
                        size: smallScreen ? 10 : (mediumScreen ? 14 : 16),
                    },
                },
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    font: {
                        size: smallScreen ? 10 : (mediumScreen ? 14 : 16),
                    },
                },
            }],
            x: {
                ticks: {
                    font: {
                        size: smallScreen ? 10 : (mediumScreen ? 14 : 16),
                    },
                },
                display: false,
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    precision: 2,
                    font: {
                        size: smallScreen ? 10 : (mediumScreen ? 14 : 16),
                    },
                },
                display: false,
                grid: {
                    display: false,
                },
            }
        },
    };

    const onNavigate = (idx) => {
        dispatch(setGlobalIsLoading(true));
        history.push(`/${item.colors[idx].nav}/${item.colors[idx].symbol}`);
    };

    useEffect(() => {
        if (!chartContainer.current || !item || !item.data || !item.data.labels) {
            return;
        }
        const totalChartWidth = chartContainer.current.offsetWidth;
        if (!isNaN(totalChartWidth) && item.data.labels.length > 0) {
            const barWidth = totalChartWidth / item.data.labels.length - 20;
            setBarWidth(barWidth);
        }
    }, [item, chartContainer]);

    return (
        <>
            {/* <div className="flex space-between"> */}
            <div className={`flex ${isMobileScreen ? 'column' : 'space-between'}`}>
                <h4
                    className='portfolio-chart-title flex align-end'
                    style={{ minWidth: '160px' }}
                >{item.title}
                </h4>
                <PortfolioProfit />
            </div>
            <div className="flex column align-center" style={{ height: '220px', width: '100%' }}>
                <div className="flex space-around" style={{ width: '100%' }}>
                    {
                        data.datasets[0].data.map((data, index) => (
                            <div className='flex justify-center' style={{ width: barWidth }}>{data}</div>
                        ))
                    }
                </div>
                <div className='homepage-feed-chart-container' style={{ height: '80%', width: '100%' }} ref={chartContainer}>
                    <Bar data={data} options={options} style={{ width: '100%' }} />
                </div>
                <div className="flex space-around" style={{ width: '100%' }}>
                    {
                        item.data.labels.map((label, index) => (
                            <div
                                className="feed-bar-label flex justify-center"
                                style={{ width: barWidth }}
                                onClick={() => onNavigate(index)}
                            >{label}</div>
                        ))
                    }
                </div>
            </div>
        </>
    );
}

export default FeedBar;