
export async function setNewFund(loggedInUser, fundName, fundImg, isFundPublic) {
    try {
        var raw = JSON.stringify({
            'fundManager': loggedInUser,
            "name": fundName,
            'png': fundImg,
            'isPublic': isFundPublic,
            'userPicture': '',
            'coins': '',
            'fame': '',
            'followers': '',
            'userLevel': '',
        });

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            body: raw,
        };
        const response = await fetch("https://1ld6hjx2lg.execute-api.us-east-1.amazonaws.com/set-new-fund", requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function getSuggestedFunds(loggedInUser) {
    try {
        var raw = JSON.stringify({
            "loggedInUser": loggedInUser
        });

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            body: raw,
        };
        const response = await fetch("https://mfyhij3ddf.execute-api.us-east-1.amazonaws.com/getSuggestedFunds", requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function getSingleFund(fundName) {
    try {
        var raw = JSON.stringify({
            "name": fundName
        });

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            body: raw,
        };
        const response = await fetch("https://hzj6q6wsp6.execute-api.us-east-1.amazonaws.com/getSingleFund", requestOptions);
        const result = await response.json();
        console.log('sing fund:', result);
        return result;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function setNewMatchweek(fundName, participants, members, fundReturn, isWon) {
    // isWon = active/true/false,
    // active for join new match

    // console.log('fund name:', fundName);
    // console.log('participants:', participants);
    // console.log('fundReturn:', fundReturn);
    // console.log('isWon:', isWon);
    // console.log('members:', members);

    // hardcoded home
    const hardcodedFundName = 'WaveZ';
    const hardcodedType = '3v3';
    const hardcodedMembers = [
        "@",
        "CryptoConnoisseurInsight",
        "TradeMastermind"
    ]; 
    const hardcodedFundReturn = '5.2%';
    const hardcodedIsWon = 'active';

    // hardcoded away
    // const hardcodedFundName = '1ndi@nB0yz';

    // const hardcodedFundName =  fundName;
    // const hardcodedType = '3v3';
    // const hardcodedMembers = [
    //     "SpaceArtEnthusiast",
    //     "fundmember",
    //     "PixelPioneer128K"
    //     // "CapitalSorcerer123",
    // ];
    // const hardcodedFundReturn = '7%';
    // const hardcodedIsWon = 'active';


    // 5v5
    // const hardcodedFundName = '1ndi@nB0yz';
    // const hardcodedType = '5v5';
    // const hardcodedMembers = [
    //     "PixelPioneer128K",
    //     "CryptoConnoisseurInsight",
    //     "TradeMastermind",
    //     "SpaceArtEnthusiast",
    //     'CapitalSorcerer123'
    // ];
    // const hardcodedFundReturn = '141.5%';
    // const hardcodedIsWon = 'active';


    try {
        var raw = JSON.stringify({
            'name': hardcodedFundName,
            'matchweekType': hardcodedType,
            'members': hardcodedMembers,
            'fundReturn': hardcodedFundReturn,
            'isWon': hardcodedIsWon
        });

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            body: raw,
        };
        const response = await fetch("https://66wiod0rue.execute-api.us-east-1.amazonaws.com/set-new-matchweek", requestOptions);
        const result = await response.json();
        console.log('result:', result);
        return result;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function getMatchweekLogList(fundName) {
    try {
        var raw = JSON.stringify({
            "name": fundName
        });

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            body: raw,
        };
        const response = await fetch("https://yurjfm4693.execute-api.us-east-1.amazonaws.com/getMatchweekLogList", requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function getFundMatchweek(fundName) {

    // console.log('getFundMatchweek - fundName:', fundName);

    try {
        var raw = JSON.stringify({
            "name": fundName
        });

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            body: raw,
        };
        const response = await fetch("https://bb20ylo76f.execute-api.us-east-1.amazonaws.com/getFundMatchweek", requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function getBanners(bannerName) {
    try {
        var raw = JSON.stringify({
            "bannerName": bannerName
        });

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            body: raw,
        };
        const response = await fetch("https://szp1e4v5le.execute-api.us-east-1.amazonaws.com/getBannersList", requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function handleFundMember(loggedInUser, fundName, isJoin) {

    console.log('loggedInUser:', loggedInUser);
    console.log('fundName:', fundName);
    console.log('isJoin:', isJoin);

    const isJoinStr = JSON.stringify(isJoin);

    try {
        var raw = JSON.stringify({
            'name': fundName,
            'isJoin': isJoinStr,
            'username': loggedInUser,
            'userPicture': '',
            'coins': '',
            'fame': '',
            'followers': '',
            'userLevel': ''
        });

        // console.log('RAW:', raw);

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            body: raw,
        };
        const response = await fetch("https://vtrt3m9lrl.execute-api.us-east-1.amazonaws.com/handleFundMember", requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};
