import React, { useState, useContext } from "react";
import { AccountContext } from "./account";
import './user-pool.scss';

const ChangePassword = () => {

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [success, setSuccess] = useState(false);
    const { getSession } = useContext(AccountContext);

    const onSubmit = (event) => {
        event.preventDefault();
        getSession().then(({ user }) => {
            user.changePassword(password, newPassword, (err, result) => {
                if (err) {
                    console.log(err);
                } else {
                    console.log(result);
                    setSuccess(true);
                    setPassword('');
                    setNewPassword('');
                    setTimeout(() => {
                        setSuccess(false);
                    }, 5000);
                }
            });
        });
    };

    return (
        <div className="card-container">
            <h3 className="user-pool-title">Change password</h3>
            <form onSubmit={onSubmit} className='user-pool-form flex column'>
                <input
                    className="user-pool-input"
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder='Password'
                />
                <input
                    className="user-pool-input"
                    type="password"
                    value={newPassword}
                    onChange={(event) => setNewPassword(event.target.value)}
                    placeholder='New password'
                />
                <button className="user-pool-btn btn" type="submit">Change password</button>
            </form>
            <div style={{ textAlign: 'center', marginTop: '5px' }}>
                {success && <p>Password changed!</p>}
            </div>
        </div>
    );
}

export default ChangePassword;