const AnalyzePortfolioSlide = () => {
    return (
        <>
            <h2 className="app-tutorial-slide-title">Demo 2 - Analyzing Your Portfolio:</h2>

            <p className="app-tutorial-p">
                Navigate to the Portfolio Page. <br />
                Add a new section to your portfolio and input some sample data. <br />
                Use the analysis tools to evaluate the performance of your investments.
            </p>

            <p className="app-tutorial-p-strong">
                Companies Page:
            </p>
            <p className="app-tutorial-p">
                Analyze individual stock market companies with customizable filters based on time frames and industry sectors. <br />
                Visualize data through line charts, pie charts, and bar charts, depicting values, returns, and risk metrics. <br />
                The page offers flexibility in selecting specific time periods, industry sectors, and companies for analysis.
            </p>

            <div className="flex justify-center">
                <img src="/img/tutorial/portfolio/portfolio-companies.png" alt="Portfolio - Companies"  className="tutorial-page-img"/>
            </div>

            <p className="app-tutorial-p-strong">
                Sectors Page:
            </p>
            <p className="app-tutorial-p">
                Explore and analyze stock market industry sectors with filters for time frames. Visualize sector values, returns, and risk metrics through line charts, pie charts, and bar charts. <br />
                Tailor your analysis by selecting specific time periods and industry sectors to gain insights into the performance and allocation of your portfolio.
            </p>

            <div className="flex justify-center">
                <img src="/img/tutorial/portfolio/portfolio-sectors.png" alt="Portfolio - Sectors"  className="tutorial-page-img"/>
            </div>

            <p className="app-tutorial-p-strong">
                Fundamental Page:
            </p>
            <p className="app-tutorial-p">
                Evaluate fundamental ratios of stock market companies, examining profitability, coverage, solvency, market prospect, liquidity, and efficiency metrics. <br />
                Choose time frames and view modes for in-depth analysis, with filters for sector and individual companies. <br />
                The page provides a comprehensive understanding of fundamental aspects to inform investment decisions.
            </p>

            <div className="flex justify-center">
                <img src="/img/tutorial/portfolio/portfolio-fundamental.png" alt="Portfolio - Fundamental"  className="tutorial-page-img"/>
            </div>

            <p className="app-tutorial-p-strong">
                Descriptive Page:
            </p>
            <p className="app-tutorial-p">
                Understand descriptive statistics of stock market companies through visualizations of distributions, statistical summaries, correlations, and unexpected returns. <br />
                Filters allow customization based on time frames, sectors, and individual companies. <br />
                The page utilizes charts and tables such as kernel density estimation distributions, statistical summary tables, correlation tables, and unexpected returns tables.
            </p>

            <div className="flex justify-center">
                <img src="/img/tutorial/portfolio/portfolio-descriptive.png" alt="Portfolio - Descriptive"  className="tutorial-page-img"/>
            </div>

            <p className="app-tutorial-p-strong">
                Sensitivity Page:
            </p>
            <p className="app-tutorial-p">
                Conduct scenario analysis and Monte Carlo simulations to explore expected portfolio performance under different scenarios. <br />
                Analyze sensitivity through beta values, Markov Chains probabilities, and regression analysis.<br />
                Customize your analysis by choosing specific time frames. Visualizations include scenario analysis and Monte Carlo simulation distributions, expected value over time line charts, and sensitivity analysis tables.<br />
                This page aids in understanding the potential impact of various factors on portfolio performance.
            </p>

            
            <div className="flex justify-center">
                <img src="/img/tutorial/portfolio/portfolio-sensitivity.png" alt="Portfolio - Sensitivity"  className="tutorial-page-img"/>
            </div>

        </>
    );
}

export default AnalyzePortfolioSlide;