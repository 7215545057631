import React, { useState } from "react";
import Select from 'react-select';
import { customStyles, DropdownIndicator } from "../../../shared/dropdown-styles";
import { dropdownIcon } from "../../../shared/icons/icons";

const MacroCurrencyFilter = ({currencies, selectedCurrencies, handleCurrenciesChange }) => {

    const [showOptions, setShowOptions] = useState(false);

    const handleMouseEnter = () => {
        setShowOptions(true);
    };

    const handleMouseLeave = () => {
        setShowOptions(false);
    };

    return (
        <div
            className="dropdown-select-filter-item"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Select
                styles={customStyles}
                components={{ DropdownIndicator }}
                options={currencies}
                value={selectedCurrencies}
                onChange={handleCurrenciesChange}
                menuIsOpen={showOptions}
                hideSelectedOptions={false}
                isMulti
            />
            <div
                className={`dropdown-label flex align-center ${selectedCurrencies.length !== currencies.length ? 'incomplete-dropdown' : ''}`}>
                Currencies
                <span
                    className='dropdown-indicator flex center-center'>
                    {dropdownIcon}
                </span>
            </div>
        </div>
    );
}

export default MacroCurrencyFilter;