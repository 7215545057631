export async function getProfileData(username, selectedPortfolio = 'portfolioA', orders = [], isMyProfile) {
    try {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        // Create the request body with the updated userFollowing object
        const requestBody = {
            username,
            selectedPortfolio,
            orders_list: orders,
            isMyProfile
        };

        const requestBodyString = JSON.stringify(requestBody);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: requestBodyString,
        };

        // Send a request to your API endpoint to save the updated userFollowing
        const response = await fetch(
            'https://v1piqr60s1.execute-api.us-east-1.amazonaws.com/getProfile',
            requestOptions
        );

        if (!response.ok) {
            console.log('Error:', response);
        } else {
            const result = await response.text();
            const data = JSON.parse(result);
            return data;
        }
    } catch (error) {
        console.error('Error getting profile:', error);
        throw error;
    }
};

export async function getProfilePosts(username) {

    try {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        // Create the request body with the updated userFollowing object
        const requestBody = {
            username
        };

        const requestBodyString = JSON.stringify(requestBody);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: requestBodyString,
        };

        // Send a request to your API endpoint to save the updated userFollowing
        const response = await fetch(
            'https://xi0aqv2ey5.execute-api.us-east-1.amazonaws.com/getProfilePosts',
            requestOptions
        );

        if (!response.ok) {
            console.log('Error:', response);
        } else {
            const result = await response.text();
            const data = JSON.parse(result);
            return data;
        }
    } catch (error) {
        console.error('Error getting profile posts:', error);
        throw error;
    }
};

export async function userUploadProfileImg(userId, file) {

    try {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        // const fileExtension = file.name.split('.').pop();
        // const newFileName = `${userId}.${fileExtension}`;
        // Create the new file name using the userId and .png extension
        const newFileName = `${userId}.png`;
        const requestBody = {
            userId,
            fileName: newFileName,
            fileType: file.type
        }; 

        const requestBodyString = JSON.stringify(requestBody);

        const requestOptions = {
            method: 'POST', 
            headers: myHeaders,
            body: requestBodyString,
        };

        console.log('requestBodyString:',requestBodyString);

        // Send a request to your API endpoint to save the updated userFollowing
        const response = await fetch(
            'https://h27u1ju1d5.execute-api.us-east-1.amazonaws.com/set-profile-picture',
            requestOptions
        );

        if (!response.ok) {
            console.log('Error:', response);
        } else {
            const result = await response.text();
            const data = JSON.parse(result);
            return data.url;
        }
    } catch (error) {
        console.error('Error getting profile posts:', error);
        throw error;
    }
};

export async function setFame(fromUser, toUser) {

    try {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        // Create the request body with the updated userFollowing object
        const requestBody = {
            usernameGives: fromUser,
            usernameTakes: toUser
        };

        const requestBodyString = JSON.stringify(requestBody);

        console.log('requestBodyString:', requestBodyString);
        
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: requestBodyString,
        };

        // Send a request to your API endpoint to save the updated userFollowing
        const response = await fetch(
            'https://uj843wzm16.execute-api.us-east-1.amazonaws.com/set-user-fame',
            requestOptions
        );

        if (!response.ok) {
            console.log('Error:', response);
        } else {
            console.log('response:', response);
            const result = await response.text();
            console.log('result:', result);
            const data = JSON.parse(result);
            console.log('data:', data);
            return data;
        }
    } catch (error) {
        console.error('Error Fame:', error);
        throw error;
    }
};

export async function getAchievementTutorialPost() {

    try {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        const requestBody = {
    
        };

        const requestBodyString = JSON.stringify(requestBody);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: requestBodyString,
        };

        // Send a request to your API endpoint to save the updated userFollowing
        const response = await fetch(
            'https://d3zccuzs8k.execute-api.us-east-1.amazonaws.com/getPostExample',
            requestOptions
        );

        if (!response.ok) {
            console.log('Error:', response);
        } else {
            const result = await response.text();
            const data = JSON.parse(result);
            return data;
        }
    } catch (error) {
        console.error('Error getting Post Example:', error);
        throw error;
    }
};