export async function getSelectedCompany(activePage, symbol) {
    try {
        var raw = JSON.stringify({ "page": activePage , "symbol": symbol });
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: raw,
            redirect: 'follow'
        };
        const response = await fetch(
            "https://xx4aaw98ef.execute-api.us-east-1.amazonaws.com/stage_get_single_company/getSingleCompany",
            requestOptions
        );

        if (!response.ok) {
            console.log('FAIL:', response.status, response);
            return null;
        }

        const result = await response.text();
        const parsedData = JSON.parse(result);
        // Check if parsedData contains the expected data
        if (parsedData) {
            // Access the data you need from parsedData
            const technicalPageData = parsedData.technicalPage;
            const financialStatementsData = parsedData.financialStatementsPage;
            const singleCompanyHeader = parsedData.singleCompanyHeader;
            const fundamentalPage = parsedData.fundamentalPage;
            // You can then process and return the data as needed
            return {
                technicalPage: technicalPageData,
                financialStatementsPage: financialStatementsData,
                singleCompanyHeader: singleCompanyHeader,
                fundamentalPage: fundamentalPage
            };
        } else {
            console.error('Invalid response format', response, result);
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function getSelectedSector(sector) {
    try {
        var raw = JSON.stringify({ "sector": sector });
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: raw,
            redirect: 'follow'
        };
        const response = await fetch(
            "https://gcmx9ofvkh.execute-api.us-east-1.amazonaws.com/getSingleSector",
            requestOptions
        );

        if (!response.ok) {
            console.log('FAIL:', response.status, response);
            return null;
        }

        const result = await response.text();
        const parsedData = JSON.parse(result);
        if (parsedData && parsedData.competitive && parsedData.fundamental) {
            // Access the data you need from parsedData
            const singleSectorHeader = parsedData.header;
            const singleSectorCompetitive = parsedData.competitive;
            const singleSectorFundamental = parsedData.fundamental;
            // You can then process and return the data as needed
            return {
                sectorHeader: singleSectorHeader,
                sectorCompetitive: singleSectorCompetitive,
                sectorFundamental: singleSectorFundamental,
            };
        } else {
            console.error('Invalid response format:');
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function getSelectedIndex(symbol) {
    try {
        var raw = JSON.stringify({ "symbol": symbol });
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: raw,
            redirect: 'follow'
        };
        const response = await fetch(
            "https://fdwd9s3ka6.execute-api.us-east-1.amazonaws.com/STAGE_get_single_index/getSingleIndex",
            requestOptions
        );

        if (!response.ok) {
            console.log('FAIL:', response.status, response);
            return null;
        }

        const result = await response.text();
        const parsedData = JSON.parse(result);
        // Check if parsedData contains the expected data
        if (parsedData) {
            // Access the data you need from parsedData
            const header = parsedData.header;
            const table = parsedData.table;
            const priceOverTime = parsedData.priceOverTime;
            const barCharts = parsedData.barCharts;
            // You can then process and return the data as needed
            return {
                indexHeader: header,
                indexTable: table,
                indexPriceOverTime: priceOverTime,
                indexBarCharts: barCharts
            };
        } else {
            console.error('Invalid response format:');
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function getSelectedCryptoCurrency(symbol) {
    try {
        var raw = JSON.stringify({ "symbol": symbol });
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: raw,
            redirect: 'follow'
        };
        const response = await fetch(
            "https://k1j4308uhj.execute-api.us-east-1.amazonaws.com/stage_get_single_crypto/getSingleCrypto",
            requestOptions
        );

        if (!response.ok) {
            console.log('FAIL:', response.status, response);
            return null;
        }

        const result = await response.text();
        const parsedData = JSON.parse(result);
        // Check if parsedData contains the expected data
        if (parsedData) {
            // Access the data you need from parsedData
            const header = parsedData.header;
            const table = parsedData.table;
            const priceOverTime = parsedData.priceOverTime;
            const barCharts = parsedData.barCharts;
            // You can then process and return the data as needed
            return {
                cryptoHeader: header,
                cryptoTable: table,
                cryptoPriceOverTime: priceOverTime,
                cryptoBarCharts: barCharts
            };
        } else {
            console.error('Invalid response format:');
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function getSelectedCurrency(symbol) {
    try {
        var raw = JSON.stringify({ "symbol": symbol });
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: raw,
            redirect: 'follow'
        };
        const response = await fetch(
            "https://zknhk4zltj.execute-api.us-east-1.amazonaws.com/STAGE_get_single_currency/getSingleCurrency",
            requestOptions
        );

        if (!response.ok) {
            console.log('FAIL:', response.status, response);
            return null;
        }

        const result = await response.text();
        const parsedData = JSON.parse(result);
        // Check if parsedData contains the expected data
        if (parsedData) {
            // Access the data you need from parsedData
            const header = parsedData.header;
            const table = parsedData.table;
            const priceOverTime = parsedData.priceOverTime;
            const barCharts = parsedData.barCharts;
            // You can then process and return the data as needed
            return {
                currencyHeader: header,
                currencyTable: table,
                currencyPriceOverTime: priceOverTime,
                currencyBarCharts: barCharts
            };
        } else {
            console.error('Invalid response format:');
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function getSelectedCommodity(symbol) {
    try {
        var raw = JSON.stringify({ "symbol": symbol });
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: raw,
            redirect: 'follow'
        };
        const response = await fetch(
            "https://2hc6pojhlj.execute-api.us-east-1.amazonaws.com/STAGE_get_single_commodity/getSingleCommodity",
            requestOptions
        );

        if (!response.ok) {
            console.log('FAIL:', response.status, response);
            return null;
        }

        const result = await response.text();
        const parsedData = JSON.parse(result);
        // Check if parsedData contains the expected data
        if (parsedData) {
            // Access the data you need from parsedData
            const header = parsedData.header;
            const table = parsedData.table;
            const priceOverTime = parsedData.priceOverTime;
            const barCharts = parsedData.barCharts;
            // You can then process and return the data as needed
            return {
                commodityHeader: header,
                commodityTable: table,
                commodityPriceOverTime: priceOverTime,
                commodityBarCharts: barCharts
            };
        } else {
            console.error('Invalid response format:');
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

export async function getPortfolio(userData) {
    try {
        var raw = JSON.stringify({ "orders_list": userData });
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: raw,
            redirect: 'follow'
        };
        const response = await fetch(
            "https://0hdcju6vo2.execute-api.us-east-1.amazonaws.com/cors/getPortfolio",
            requestOptions
        );

        if (!response.ok) {
            console.log('FAIL:', response.status, response);
            return null;
        }

        const result = await response.text();
        const parsedData = JSON.parse(result);
        if (parsedData) {
            return parsedData;
        } else {
            console.error('Invalid response format:');
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};