import BasicSelectOption from "../../../shared/basic-select-option";

const PortfolioFundamental = () => {

    const companyOptions = [
        {
            value: 'AAPL',
            label: '(AAPL) Apple Inc.',
        },
        {
            value: 'MSFT',
            label: '(MSFT) Microsoft Corp',
        },
        {
            value: 'NVDA',
            label: '(NVDA) Nvidia Corp',
        },
        {
            value: 'AMZN',
            label: '(AMZN) Amazon.com Inc',
        }
    ];

    const sectorOptions = [
        {
            value: 'Technology',
            label: 'Technology',
        },
        {
            value: 'Financials',
            label: 'Financials',
        },
        {
            value: 'Health Care',
            label: 'Health Care',
        },
        {
            value: 'Real Estate',
            label: 'Real Estate',
        }
    ];

    const overTimeSectorialOptions = [
        {
            value: 'Over Time',
            label: 'Over Time',
        },
        {
            value: 'Sectorial',
            label: 'Sectorial',
        }
    ]

    return (
        <>
            <h2 className="app-tutorial-slide-title">Portfolio - Fundamental</h2>

            <ul className={"guide-ul"}>
                <li>Evaluate fundamental ratios of stock market companies.</li>
                <li>Examine profitability, coverage, solvency, market prospect, liquidity, and efficiency metrics.</li>
                <li>Choose time frames and view modes for in-depth analysis.</li>
            </ul>

            <h4 className={"guide-subtitle"}>Filters:</h4>
            <ul className={"guide-ul"}>
                <li>Sector: Filter stock market companies by industry sectors.
                </li>
                <li>Companies: Choose from a variety of stock market companies to analyze.
                </li>
                <li>Time Frames: Choose time periods for analysis.
                </li>
                <li> View Mode: Select whether to view values over time or just the last value for each company.
                </li>
            </ul>

            <div className="flex wrap justify-center" style={{ marginBottom: '20px' }}>
                <BasicSelectOption options={sectorOptions} title="Sectors" />
                <BasicSelectOption options={companyOptions} title="Comapnies" />
                <BasicSelectOption options={overTimeSectorialOptions} title="Over Time" />
            </div>

            <h4 className={"guide-subtitle"}>Ratios:</h4>
            <ul className={"guide-ul"}>
                <li>Profitability: Includes Return On Equity (ROE), Percent Pure Profit, Return On Assets (ROA), and Gross Profit Margin.</li>
                <li>Coverage: Includes Asset Coverage, Debt Service Coverage, and Interest Coverage.
                </li>
                <li>Solvency: Includes Debt To Equity, Debt To Assets, and Shareholder Equity Ratio.
                </li>
                <li>Market Prospect: Includes Earnings Per Share (EPS), Dividend Payout Ratio, and Dividend Per Share.
                </li>
                <li>Liquidity: Includes Current Ratio, Quick Ratio, and Days Sales Outstanding.
                </li>
                <li>Efficiency: Includes Efficiency Ratio, Assets Turnover, and Percent Operating Income.
                </li>
            </ul>
            <div className="flex flex-end">
                <a href="/portfolio/fundamental" className="guide-btn btn btn-border" title="Fundamental">Go to page</a>
            </div>

        </>
    );
}

export default PortfolioFundamental;