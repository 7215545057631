import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { getSelectedCurrency } from "../../../shared/actions/single-item-actions";
import LoadingSpinner from "../../../shared/loading-spinner/loading-spinner";
import { setGlobalIsLoading } from "../../../store/loading/loading-actions";
import SingleItemTechnical from "../../../cmps/single-item-technical/single-item-technical";
import SingleCurrencyHeader from "./single-currency-header/single-currency-header";
import { getTodayMonthDayDate } from "../../../shared/global-color-functions";

const SingleCurrency = ({ loggedInUser, toggleFollowing, singleCurrencies, setSingleCurrencies, userFollowing }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { symbol } = useParams();
    const loadingSelector = useSelector((state) => state.loading.isLoading);
    const lastPrices = useSelector((state) => state.lastPrices);

    const [currencyHeader, setCurrencyHeader] = useState(null);
    const [currencyTable, setCurrencyTable] = useState(null);
    const [currencyPriceOverTime, setCurrencyPriceOverTime] = useState(null);
    const [currencyBarCharts, setCurrencyBarCharts] = useState(null);

    const location = useLocation();
    const path = location.pathname;
    const navType = path.split('/')[1];
    const data = lastPrices[`${symbol}=${navType}`];

    const setSingleCurrencyData = (header, table, priceOverTime, barCharts) => {
        setCurrencyHeader(header);
        setCurrencyTable(table);
        setCurrencyPriceOverTime(priceOverTime);
        setCurrencyBarCharts(barCharts);
    };

    const getCurrencyHeader = () => {
        // const data = lastPrices[symbol];
        if (!data) return;
        return {
            date: getTodayMonthDayDate(),
            currency: data.name.split('/')[0],
            symbol: symbol,
            description: data.info.description,
            name: '',
            lastPriceChange: data.lastPriceChange,
            lastPricePercentChange: data.lastPricePercentChange,
        };
    };

    useEffect(() => {
        // const data = lastPrices[symbol];
        if (!data) return;
        setCurrencyHeader(getCurrencyHeader());
    }, [symbol, lastPrices]);

    useEffect(() => {
        if (currencyHeader && currencyTable && currencyPriceOverTime && currencyBarCharts) {
            dispatch(setGlobalIsLoading(false))
        }
    }, [currencyHeader, currencyTable, currencyPriceOverTime, currencyBarCharts]);

    useEffect(() => {
        if (!symbol) handleNavigateToError();
        async function fetchData() {
            dispatch(setGlobalIsLoading(true));
            try {
                if (singleCurrencies[symbol]) {
                    setSingleCurrencyData(singleCurrencies[symbol].currencyHeader, singleCurrencies[symbol].currencyTable, singleCurrencies[symbol].currencyPriceOverTime, singleCurrencies[symbol].currencyBarCharts);
                } else {
                    const currencyData = await getSelectedCurrency(symbol);
                    if (currencyData) {
                        setSingleCurrencyData(currencyData.currencyHeader, currencyData.currencyTable, currencyData.currencyPriceOverTime, currencyData.currencyBarCharts);
                        setSingleCurrencies(prevSingleCurrencies => ({
                            ...prevSingleCurrencies,
                            [symbol]: currencyData
                        }));
                    } else {
                        handleNavigateToError();
                    }
                }
            } catch (error) {
                handleNavigateToError();
            }
        }
        fetchData();
    }, [symbol]);

    const handleNavigateToError = () => {
        history.push('/error');
    };

    return (
        <>
            {
                currencyHeader &&
                <SingleCurrencyHeader
                    loggedInUser={loggedInUser}
                    symbol={symbol}
                    toggleFollowing={toggleFollowing}
                    header={currencyHeader}
                    userFollowing={userFollowing}
                />
            }
            {
                loadingSelector ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {
                            currencyTable &&
                            currencyPriceOverTime &&
                            currencyBarCharts &&
                            < SingleItemTechnical
                                technicalData={{ table: currencyTable, priceOverTime: currencyPriceOverTime, barCharts: currencyBarCharts }}
                                symbol={symbol}
                                type={'currency'}
                            />
                        }
                    </>
                )
            }
        </>
    );
}

export default SingleCurrency;