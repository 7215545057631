const CalendarSlide = () => {
    return (
        <>
            <h2 className="app-tutorial-slide-title">Demo 5 - Using the Calendar:</h2>

            <p className="app-tutorial-p">
                Navigate to the Calendar and view upcoming events related to individual companies and macroeconomic factors. <br />
                Set up alerts for important events to stay informed and make timely investment decisions.
            </p>

            <div className="flex justify-center">
                <img src="/img/tutorial/calendar.png" alt="Calendar img"  className="tutorial-page-img"/>
            </div>

            <p className="app-tutorial-p">
                The Calendar Page provides a comprehensive overview of both company-specific and macroeconomic events, covering stock splits, dividends, SEC filings, IPOs, labor market data, housing market events, commodity prices, and monetary policy. <br />
                Users can filter events based on preferences, analyze them over different time frames, and customize their view. <br />
                The page serves as a valuable tool for planning investment strategies, offering real-time event notifications to react promptly to market developments. <br />
                With an intuitive interface and interactive features, it empowers users to stay informed and make well-informed investment decisions.
            </p>

            <p className="app-tutorial-p">
                Start exploring now to take control of your investment journey.
            </p>
        </>
    );
}

export default CalendarSlide;