import React, { useState } from 'react';
import { editIcon } from '../../shared/icons/icons';

const ProfileAbout = ({ isMyProfile, about, onChangeAbout }) => {

    const [editing, setEditing] = useState(false);
    const [editedAbout, setEditedAbout] = useState('');
    const [initialAbout, setInitialAbout] = useState(about);

    const handleEdit = () => {
        setEditing(true);
    };

    const handleSave = () => {
        onChangeAbout(editedAbout);
        setEditedAbout('');
        setEditing(false);
    };

    const handleCancel = () => {
        setEditedAbout(initialAbout);
        setEditedAbout('');
        setEditing(false);
    };

    const handleInputChange = (event) => {
        setEditedAbout(event.target.value);
    };

    return (
        <>
            {editing ? (
                <div className="profile-about-container " style={{ borderBottom: '2px solid #f1f6fc' }}>
                    <textarea
                        value={editedAbout}
                        onChange={handleInputChange}
                        autoFocus style={{ padding: '0' }}
                        placeholder='About'
                    />
                    <div className='profile-about-btns flex flex-end'>
                        <div className='profile-about-btn btn' onClick={handleSave}>Save</div>
                        <div className='profile-about-btn btn' onClick={handleCancel}>Cancel</div>
                    </div>
                </div>
            ) : (
                <div className="profile-about-container" style={{ borderBottom: '2px solid #f1f6fc' }}>
                    <div className='profile-about-txt flex '>{about}</div>
                    <div className='profile-about-btns flex flex-end'>
                        {isMyProfile && <div className='profile-about-btn btn' onClick={handleEdit}>
                            {editIcon}
                        </div>
                        }
                    </div>
                </div>
            )}
        </>
    );
};

export default ProfileAbout;
