import React, { useEffect, useState, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import { useMediaQuery } from 'react-responsive';
import { camelCaseToTitleString } from '../../global-color-functions';

const ScatterChart = ({ selectedItem, setSelectedItem, chartData, activeFilter }) => {

    const mediumScreen = useMediaQuery({ maxWidth: 920 });
    const smallScreen = useMediaQuery({ maxWidth: 800 });

    const [dataModal, setDataModal] = useState(null);
    const [dataModalLocation, setDataModalLocation] = useState({ x: 0, y: 0 });

    const chartContainer = useRef(null);

    const options = {
        plugins: {
            title: {
                display: false
            },
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            },
            zoom: {
                zoom: {
                    enabled: false
                },
                pan: {
                    enabled: false
                }
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                title: {
                    color: '#484848',
                    display: true,
                    text:  `Relative ${camelCaseToTitleString(activeFilter)} Market Share`,
                    font: {
                        size: smallScreen ? 8 : (mediumScreen ? 10 : 12),
                        family: 'Poppins-regular'
                    }
                },
                ticks: {
                    maxTicksLimit: mediumScreen ? 4 : 5,
                    font: {
                        size: smallScreen ? 8 : (mediumScreen ? 10 : 12),
                        family: 'Poppins-regular'
                    },
                    callback: function (value, index, values) {
                        return value.toFixed(2) + "%";
                    }
                }
            },
            y: {
                title: {
                    color: '#484848',
                    display: true,
                    text:  `${camelCaseToTitleString(activeFilter)} Growth %`,
                    font: {
                        size: smallScreen ? 8 : (mediumScreen ? 10 : 12),
                        family: 'Poppins-regular'
                    }

                },
                ticks: {
                    maxTicksLimit: mediumScreen ? 4 : 5,
                    font: {
                        size: smallScreen ? 8 : (mediumScreen ? 10 : 12),
                        family: 'Poppins-regular'
                    },
                    callback: function (value, index, values) {
                        if (index === 0) return '';
                        return value + "%";
                    }
                }
            },
        },
        onClick: (event, chartElements) => {
            if (!chartElements.length) {
                setSelectedItem(null);
                return;
            };
            const clickedItem = chartData.datasets[chartElements[0].datasetIndex];
            setSelectedItem(clickedItem.label);
        },

        onHover: (event, chartElements) => {
            if (!chartElements.length) {
                setDataModal(null);
                return;
            };
            const hoverData = chartData.datasets[chartElements[0].datasetIndex];
            const chart = chartContainer.current;
            if (!chart) return;
            const tooltipElement = document.querySelector('.scatter-modal');
            const tooltipWidth = tooltipElement ? tooltipElement.offsetWidth : '200px';
            const tooltipHeight = tooltipElement ? tooltipElement.offsetHeight : 0;
            const chartHeight = chart.offsetHeight;
            const chartWidth = chart.offsetWidth;
            let timeoutId = null;
            const mouseX = event.x;
            const mouseY = event.y;
            let adjustedX = mouseX;
            let adjustedY = mouseY;
            if (tooltipElement) {
                if (mouseY > chartHeight / 2) {
                    adjustedY -= tooltipHeight;
                }
                if (mouseX > chartWidth / 2) {
                    adjustedX -= tooltipWidth;
                }
            };
            clearTimeout(timeoutId);
            setDataModalLocation({ x: adjustedX, y: adjustedY });
            setDataModal(`(${hoverData.label}) ${hoverData.company}`);
        }

    };

    return (
        <div style={{ height:'100%', width: '100%' }} className="card-contianer flex column space-between">
                <Line type={'scatter'} data={chartData} options={options} style={{ height:'80%', width: '100%' }} className={'mobile-chart-size'} />
                <>
                    {
                        dataModal &&
                        <div
                            className='scatter-modal card-container'
                            style={{
                                left: `${dataModalLocation.x}px`,
                                top: `${dataModalLocation.y}px`,
                            }}>
                            {dataModal}
                        </div>
                    }
                </>
            </div>
    );
}

export default ScatterChart;