import React, { useState, useEffect } from 'react';
import { DropdownIndicator, customStyles as originalCustomStyles, customStyles } from "../../../shared/dropdown-styles";
import Select from 'react-select';
import { dropdownIcon } from '../../../shared/icons/icons';

const PortfolioPeriodFilter = ({ currFilter, onChangeFilter, optionsToDisplay }) => {

    const [activeFilterLabel, setActiveFilterLabel] = useState(null);
    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        const currFilterLabel = optionsToDisplay.find((option) => option.value === currFilter)?.label;
        if (!currFilterLabel) {
            const firstOption = optionsToDisplay[0];
            onChangeFilter(firstOption);
            setActiveFilterLabel(firstOption.label);
        } else {
            setActiveFilterLabel(currFilterLabel);
        }
    }, [currFilter, optionsToDisplay, onChangeFilter]);

    const onHandleMouseEnter = () => {
        setShowOptions(true);
    };

    const onHandleMouseLeave = () => {
        setShowOptions(false);
    };

    const handleChange = (selectedOption) => {
        onChangeFilter(selectedOption);
    };

    return (
        <div className="dropdown-select-filter-item"
            onMouseEnter={onHandleMouseEnter}
            onMouseLeave={onHandleMouseLeave}
        >
            {
                optionsToDisplay && <Select
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                    options={optionsToDisplay}
                    value={currFilter}
                    onChange={handleChange}
                    menuIsOpen={showOptions}
                    hideSelectedOptions={false}
                />
            }
            {
                activeFilterLabel && <div
                    className="dropdown-label flex align-center">
                    {activeFilterLabel}
                    <span
                        className='dropdown-indicator flex center-center'
                    >
                        {dropdownIcon}
                    </span>
                </div>
            }
        </div>
    );
}

export default PortfolioPeriodFilter;
