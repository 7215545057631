import Select from 'react-select';
import { useState } from "react";
import { dropdownIcon } from './icons/icons';
import { customStyles,DropdownIndicator } from './dropdown-styles';

const BasicSelectOption = ({options, title}) => {

    const [showOptions, setShowOptions] = useState(false);

    const onHandleMouseEnter = () => {
        setShowOptions(true);
    };

    const onHandleMouseLeave = () => {
        setShowOptions(false);
    };

    return (

        <div className="dropdown-select-filter-item"
            onMouseEnter={onHandleMouseEnter}
            onMouseLeave={onHandleMouseLeave}
        >
            {
                <Select
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                    options={options}
                    value={''}
                    onChange={() => { }}
                    menuIsOpen={showOptions}
                    hideSelectedOptions={false}
                />
            }
            {
                <div
                    className="dropdown-label flex align-center">
                    {title}
                    <span
                        className='dropdown-indicator flex center-center'
                    >
                        {dropdownIcon}
                    </span>
                </div>
            }
        </div>

    );
}

export default BasicSelectOption;