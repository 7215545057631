import { FaArrowUp, FaArrowDown, FaInfo, FaPlus, FaShieldAlt, FaAngleDown, FaRegCalendarAlt, FaRegUserCircle } from 'react-icons/fa';
import { BiMenu, BiCommentAdd, BiChevronLeftCircle, BiChevronRightCircle } from 'react-icons/bi';
import { BsBarChartFill, BsPieChartFill } from 'react-icons/bs';
import { AiFillHeart, AiOutlineHeart, AiOutlineLineChart } from 'react-icons/ai';
import { MdManageHistory, MdEdit, MdContentCopy } from 'react-icons/md';
import { FaXmark, FaCheck, FaCircleChevronLeft, FaCircleChevronRight } from "react-icons/fa6";
import { PiLadderSimpleBold, PiGlobeThin, PiUserPlus } from "react-icons/pi";
import { HiOutlineScale } from "react-icons/hi";
import { TbHierarchy } from "react-icons/tb";
import { ImTarget } from "react-icons/im";
import { GoArrowSwitch, GoComment } from "react-icons/go";
import { GiAlliedStar } from "react-icons/gi";
import { SlUser } from "react-icons/sl";
import { LuLayoutDashboard } from "react-icons/lu";
import { LiaHomeSolid } from "react-icons/lia";
import { MdOutlineCurrencyBitcoin } from "react-icons/md";
import { RiLogoutCircleRLine, RiLoginCircleLine } from "react-icons/ri";
import { CgMoreO } from "react-icons/cg";
import { IoSearchOutline } from "react-icons/io5";
import { TbTargetArrow } from "react-icons/tb";
import { TbReportAnalytics } from "react-icons/tb";
import { VscFeedback } from "react-icons/vsc";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { TiStarOutline, TiStarFullOutline } from "react-icons/ti";
import { FaRegCheckSquare } from "react-icons/fa";
import { FaRegSquare } from "react-icons/fa6";
import { PiNotepadBold } from "react-icons/pi";
import { MdSavedSearch } from "react-icons/md";
import { GrOptimize } from "react-icons/gr";
import { GrTest } from "react-icons/gr";
import { GiStairsGoal } from "react-icons/gi";
import { IoNewspaperOutline } from "react-icons/io5";
import { FaGoogle } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";
import { PiTelevision } from "react-icons/pi";
import { FaTiktok } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { CiCircleMore } from "react-icons/ci";
import { PiBabyBold } from "react-icons/pi";
import { GiSwordman } from "react-icons/gi";
import { GiStrongMan } from "react-icons/gi";
import { FaUserLock } from "react-icons/fa";
import { ImHappy } from "react-icons/im";
import { FaPeopleGroup } from "react-icons/fa6";
import { MdOutlineCastForEducation } from "react-icons/md";
import { FaRegLightbulb } from "react-icons/fa6";
import { TbClockPlus } from "react-icons/tb";
import { FaPeopleArrows } from "react-icons/fa";
import { PiChartLineUpBold } from "react-icons/pi";
import { MdOutlineAddchart } from "react-icons/md";
import { HiSwitchHorizontal } from "react-icons/hi";
import { FaWindowMinimize } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";
import { GiAchievement } from "react-icons/gi";
import { RxRocket } from "react-icons/rx";
import { FaTrophy } from "react-icons/fa6";
import { GiTwoCoins } from "react-icons/gi";
import { RiUserFollowLine } from "react-icons/ri";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import { FaLock } from "react-icons/fa";
import { PiDiamondsFour } from "react-icons/pi";
import { FaMedal } from "react-icons/fa6";
import { IoPersonAddOutline } from "react-icons/io5";
import { IoPersonAddSharp } from "react-icons/io5";

export const menuIcon = <BiMenu />;
export const lockIcon = <FaLock />;

export const followIcon = <AiFillHeart />;
export const unfollowIcon = <AiOutlineHeart />;

export const arrowUpIcon = <FaArrowUp />;
export const arrowDownIcon = <FaArrowDown />;

export const pieChartIcon = <BsPieChartFill />;
export const lineChartIcon = <AiOutlineLineChart />;
export const barChartIcon = <BsBarChartFill />;

export const editIcon = <MdEdit />;
export const infoIcon = <FaInfo />;
export const closeIcon = <FaXmark />;
export const checkIcon = <FaCheck />;
export const plusIcon = <FaPlus />;

export const harmonyIcon = <TbHierarchy />
export const awarnessIcon = <HiOutlineScale />
export const resilienceIcon = <FaShieldAlt />
export const hierarchyIcon = <PiLadderSimpleBold />
export const precisionIcon = <ImTarget />

export const switchIcon = <HiSwitchHorizontal />
export const manageIcon = <MdManageHistory />
export const scoresIcon = <GiAlliedStar />

export const userIcon = <SlUser />
export const homeIcon = <LiaHomeSolid />
export const cryptoIcon = <MdOutlineCurrencyBitcoin />
export const macroIcon = <PiGlobeThin />
export const portfolioIcon = <LuLayoutDashboard />
export const calendarIcon = <FaRegCalendarAlt />

export const logoutIcon = <RiLogoutCircleRLine />
export const loginIcon = <RiLoginCircleLine />
export const signupIcon = <PiUserPlus />
export const moreIcon = <CgMoreO />

export const dropdownIcon = <FaAngleDown />

export const commentIcon = <GoComment />
export const addCommentIcon = <BiCommentAdd />
export const copyIcon = <MdContentCopy />

export const searchIcon = <IoSearchOutline />

export const leftFillArrow = <FaCircleChevronLeft />
export const rightFillArrow = <FaCircleChevronRight />
export const leftArrow = <BiChevronLeftCircle />
export const rightArrow = <BiChevronRightCircle />

export const userImg = <FaRegUserCircle />

export const sincePostProfitIcon = <TbTargetArrow />
export const portfolioProfitIcon = <TbReportAnalytics />

export const feedbackIcon = <VscFeedback />

export const emptyStarIcon = <TiStarOutline />
export const fullStarIcon = <TiStarFullOutline />
export const checkMarkIcon = <IoMdCheckmarkCircle />

export const checkedBox = <FaRegCheckSquare />
export const uncheckedBox = <FaRegSquare />

export const notepadIcon = <PiNotepadBold />

// ---------------------------------

export const investmentScoutingIcon = <MdSavedSearch />
export const optimizeIcon = <GrOptimize />
export const learnIcon = <GrTest />
export const dontKnowIcon = <GiStairsGoal />

export const newsIcon = <IoNewspaperOutline />
export const googleIcon = <FaGoogle />
export const friendsIcon = <FaUserFriends />
export const tvIcon = <PiTelevision />
export const tiktokIcon = <FaTiktok />
export const facebookIcon = <FaFacebookF />
export const instagramIcon = <FaInstagram />
export const youtubeIcon = <FaYoutube />
export const otherIcon = <CiCircleMore />

export const beginnerIcon = <PiBabyBold />
export const intermediateIcon = <GiSwordman />
export const experiencedIcon = <GiStrongMan />
export const preferNotIcon = <FaUserLock />

export const funIcon = <ImHappy />
export const connectIcon = <FaPeopleGroup />
export const educationIcon = <MdOutlineCastForEducation />
export const productivelyIcon = <TbClockPlus />
export const boostIcon = <FaRegLightbulb />

export const communityInsightsIcon = <FaPeopleArrows />
export const portfolioOptimizationIcon = <PiChartLineUpBold />
export const marketLearningIcon = <MdOutlineAddchart />

export const minimizeIcon = <FaWindowMinimize />
export const backIcon = <FaArrowLeft />

export const achievementIcon = <GiAchievement />
export const rocketIcon = <RxRocket />
export const trophyIcon = < FaTrophy />

export const coinsIcon = < GiTwoCoins />
export const followersIcon = < RiUserFollowLine />
export const tutorialIcon = <HiOutlineDocumentCheck />

export const fameIcon = <FaMedal  />
export const followUserOutline = <IoPersonAddOutline />
export const followUserSharp = <IoPersonAddSharp  />