import React, { useEffect, useRef } from "react";
import { arrowDownIcon, arrowUpIcon, awarnessIcon, copyIcon, harmonyIcon, hierarchyIcon, portfolioProfitIcon, precisionIcon, resilienceIcon } from "../../../shared/icons/icons";
import { useMediaQuery } from 'react-responsive';
import { formatNumberToDollarTableString } from "../../../shared/global-color-functions";
import { useState } from "react";
import ProfilePortfolioBtns from "../profile-portfolio/profile-portfolio-btns";
import FeedTable from "../../homepage/homepage-cmps/homepage-feed/feed-cmps/feed-table";
import FeedConfirmChanges from "../../homepage/homepage-cmps/homepage-feed/feed-cmps/feed-confirm-changes";
import { useHistory } from "react-router-dom";

const ProfileSelectedPortfolioScores = ({
    selectedPieCharts,

    portfolioScoresData,
    companiesSumData,
    portfolioWorthSum,
    selectedPortfolio,
    isTablet,

    myPortfolio,
    mySelectedPortfolio,
    ordersList,
    handleChangeSelectedPortfolio,
    handleCopyPortfolio,
    loggedInUser,
    handleChangeMySelectedPortfolio
}) => {

    const isMediumScreen = useMediaQuery({ maxWidth: 1000 });
    const isMobile = useMediaQuery({ maxWidth: 500 });
    const modalRef = useRef(null);
    const history = useHistory();

    const scoresInfo = [
        { score: 'awarness', info: 'Risk performance relative to the S&P 500' },
        { score: 'harmony', info: 'Level of market share concentration among portfolio companies' },
        { score: 'hierarchy', info: 'Level of market share concentration among portfolio sectors' },
        { score: 'precision', info: 'Accuracy in portfolio performance for achieving positive returns' },
        { score: 'resilience', info: 'Residual portfolio value score considering potential losses' }
    ];

    const [hoveredScoreTitle, setHoveredScoreTitle] = useState('');
    const [isScoreInfoModalOpen, setIsScoreInfoModalOpen] = useState(false);
    // const [scoreInfoModalPosition, setScoreInfoModalPosition] = useState({ top: 0, left: 0 });

    const handleMouseEnter = (scoreTitle) => {
        if (scoreTitle === hoveredScoreTitle && isScoreInfoModalOpen) return;
        setHoveredScoreTitle(scoreTitle);
        if (isScoreInfoModalOpen) return;
        setIsScoreInfoModalOpen(true);
    };

    const handleMouseLeave = () => {
        setIsScoreInfoModalOpen(false);
    };

    const PortfolioProfit = () => {
        if (!portfolioWorthSum || !portfolioWorthSum[selectedPortfolio]) return <></>;
        const resStr = parseFloat(portfolioWorthSum[selectedPortfolio].returnValue).toFixed(2);
        const daysStr = portfolioWorthSum[selectedPortfolio].daysAgo;
        return (
            <div className="post-return-str-container profile-post-return-str-container flex align-center">
                <div
                    className='post-return-icon profile-post-return-icon'
                    style={{ color: +resStr > 0 ? '#5c9ead' : '#e39774' }}
                >
                    {+resStr > 0 ? arrowUpIcon : arrowDownIcon}
                    {/* {portfolioProfitIcon} */}
                </div>
                <div className="flex-column">
                    <div className='post-return-str profile-post-return-str'>
                        <span className='post-return-str-span profile-returndays-span'>{resStr}%</span> return in <span className='post-return-str-span profile-returndays-span'>{daysStr}</span> days
                    </div>
                    <div className='post-return-str post-return-str-lower profile-post-return-str'>{selectedPortfolio}</div>
                </div>
            </div>
        )
    };

    const PortfolioAvgScore = () => {
        const scores = portfolioScoresData.scores;
        const scoreValues = Object.values(scores);
        const sumOfScores = scoreValues.reduce((sum, score) => sum + score, 0);
        const averageScore = sumOfScores / scoreValues.length;

        return (
            <div className="flex column center-center profile-score-bgc">
                <div className="profile-returndays-span" style={{ textAlign: 'center' }}>{selectedPieCharts ? averageScore.toFixed(2) : 0}</div>
                <div className="profile-post-return-str" style={{ textAlign: 'center' }}>Avg. Score</div>
            </div>
        )
    }

    // const ScoresDisplay = () => {
    //     return (
    //         <>
    //             <div className="flex space-between">
    //                 <div
    //                     className="profile-selected-portfolio-scores-item flex column"
    //                     onMouseEnter={() => handleMouseEnter('awarness')}
    //                     onMouseLeave={handleMouseLeave}
    //                 >
    //                     <h3>Awarness</h3>
    //                     <div className="flex align-start">
    //                         <div className="profile-selected-portfolio-scores-item-icon flex center-center">{awarnessIcon}</div>
    //                         <div className="flex">
    //                             <h2 className="profile-selected-portfolio-scores-item-score">
    //                                 {portfolioScoresData.scores.awarness}
    //                             </h2>
    //                         </div>
    //                     </div>
    //                     {
    //                         isScoreInfoModalOpen &&
    //                         hoveredScoreTitle === 'awarness' && (
    //                             <ScoreInfoModal scoreTitle={'awarness'} />
    //                         )
    //                     }
    //                 </div>

    //                 <div
    //                     className="profile-selected-portfolio-scores-item flex column"
    //                     onMouseEnter={() => handleMouseEnter('hierarchy')}
    //                     onMouseLeave={handleMouseLeave}
    //                 >
    //                     <h3>Hierarchy</h3>
    //                     <div className="flex align-start">
    //                         <div className="profile-selected-portfolio-scores-item-icon flex center-center">{hierarchyIcon}</div>
    //                         <div className="flex">
    //                             <h2 className="profile-selected-portfolio-scores-item-score">
    //                                 {portfolioScoresData.scores.hierarchy}
    //                             </h2>
    //                         </div>
    //                     </div>
    //                     {
    //                         isScoreInfoModalOpen &&
    //                         hoveredScoreTitle === 'hierarchy' && (
    //                             <ScoreInfoModal scoreTitle={'hierarchy'} />
    //                         )
    //                     }
    //                 </div>
    //             </div>

    //             <div
    //                 className="profile-selected-portfolio-scores-item flex column align-center"
    //                 onMouseEnter={() => handleMouseEnter('resilience')}
    //                 onMouseLeave={handleMouseLeave}
    //             >
    //                 <h3>Resilience</h3>
    //                 <div className="flex align-start">
    //                     <div className="profile-selected-portfolio-scores-item-icon flex center-center">{resilienceIcon}</div>
    //                     <div className="flex">
    //                         <h2 className="profile-selected-portfolio-scores-item-score">
    //                             {portfolioScoresData.scores.resilience}
    //                         </h2>
    //                     </div>
    //                 </div>
    //                 {
    //                     isScoreInfoModalOpen &&
    //                     hoveredScoreTitle === 'resilience' && (
    //                         <ScoreInfoModal scoreTitle={'resilience'} />
    //                     )
    //                 }
    //             </div>

    //             <div className="flex space-between" >

    //                 <div
    //                     className="profile-selected-portfolio-scores-item flex column"
    //                     onMouseEnter={() => handleMouseEnter('harmony')}
    //                     onMouseLeave={handleMouseLeave}
    //                 >
    //                     <h3>Harmony</h3>
    //                     <div className="flex align-start">
    //                         <div className="profile-selected-portfolio-scores-item-icon flex center-center">{harmonyIcon}</div>
    //                         <div className="flex">
    //                             <h2 className="profile-selected-portfolio-scores-item-score">
    //                                 {portfolioScoresData.scores.harmony}
    //                             </h2>
    //                         </div>
    //                     </div>
    //                     {
    //                         isScoreInfoModalOpen &&
    //                         hoveredScoreTitle === 'harmony' && (
    //                             <ScoreInfoModal scoreTitle={'harmony'} />
    //                         )
    //                     }
    //                 </div>

    //                 <div
    //                     className="profile-selected-portfolio-scores-item flex column"
    //                     onMouseEnter={() => handleMouseEnter('precision')}
    //                     onMouseLeave={handleMouseLeave}
    //                 >
    //                     <h3>Precision</h3>
    //                     <div className="flex align-start">
    //                         <div className="profile-selected-portfolio-scores-item-icon flex center-center">{precisionIcon}</div>
    //                         <div className="flex">
    //                             <h2 className="profile-selected-portfolio-scores-item-score">
    //                                 {portfolioScoresData.scores.precision}
    //                             </h2>
    //                         </div>
    //                     </div>
    //                     {
    //                         isScoreInfoModalOpen &&
    //                         hoveredScoreTitle === 'precision' && (
    //                             <ScoreInfoModal scoreTitle={'precision'} />
    //                         )
    //                     }
    //                 </div>
    //             </div>
    //         </>
    //     );
    // };

    const ScoresDisplay = () => {
        return (
            <>

                <div
                    className="flex column"
                    style={{ width: '100%', marginBottom: isMobile ? '20px !important' : '20px' }}
                >
                    <div className="profile-scores-table-row flex space-between"
                        onMouseEnter={() => handleMouseEnter('awarness')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div
                            className="flex"
                        >
                            <div className="profile-scores-table-icon flex align-center">{awarnessIcon}</div>
                            <div className="flex align-center">Awarness</div>
                        </div>
                        <div> {portfolioScoresData.scores.awarness} </div>
                        {
                            isScoreInfoModalOpen &&
                            hoveredScoreTitle === 'awarness' && (
                                <ScoreInfoModal scoreTitle={'awarness'} />
                            )
                        }
                    </div>
                    <div className="profile-scores-table-row flex space-between"
                        onMouseEnter={() => handleMouseEnter('hierarchy')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div
                            className="flex"
                        >
                            <div className="profile-scores-table-icon flex align-center">{hierarchyIcon}</div>
                            <div className="flex align-center">Hierarchy</div>
                        </div>
                        <div className="">{portfolioScoresData.scores.hierarchy}</div>
                        {
                            isScoreInfoModalOpen &&
                            hoveredScoreTitle === 'hierarchy' && (
                                <ScoreInfoModal scoreTitle={'hierarchy'} />
                            )
                        }
                    </div>
                    <div className="profile-scores-table-row flex space-between"
                        onMouseEnter={() => handleMouseEnter('resilience')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div
                            className="flex"
                        >
                            <div className="profile-scores-table-icon flex align-center">{resilienceIcon}</div>
                            <div className="flex align-center">Resilience</div>
                        </div>
                        <div className="">{portfolioScoresData.scores.resilience}</div>
                        {
                            isScoreInfoModalOpen &&
                            hoveredScoreTitle === 'resilience' && (
                                <ScoreInfoModal scoreTitle={'resilience'} />
                            )
                        }
                    </div>
                    <div className="profile-scores-table-row flex space-between"
                        onMouseEnter={() => handleMouseEnter('harmony')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div
                            className="flex"
                        >
                            <div className="profile-scores-table-icon flex align-center">{harmonyIcon}</div>
                            <div className="flex align-center">Harmony</div>
                        </div>
                        <div className="">{portfolioScoresData.scores.harmony}</div>
                        {
                            isScoreInfoModalOpen &&
                            hoveredScoreTitle === 'harmony' && (
                                <ScoreInfoModal scoreTitle={'harmony'} />
                            )
                        }
                    </div>
                    <div className="profile-scores-table-row flex space-between"
                        onMouseEnter={() => handleMouseEnter('precision')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div
                            className="flex"
                            onMouseEnter={() => handleMouseEnter('precision')}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="profile-scores-table-icon flex align-center">{precisionIcon}</div>
                            <div className="flex align-center">Precision</div>
                        </div>
                        <div>{portfolioScoresData.scores.precision}</div>
                        {
                            isScoreInfoModalOpen &&
                            hoveredScoreTitle === 'precision' && (
                                <ScoreInfoModal scoreTitle={'precision'} />
                            )
                        }
                    </div>
                </div>

            </>
        );
    };

    const LargeScreen = () => {
        return <div className="flex column align-center space-between" style={{ marginBottom: isTablet ? '20px' : '0' }}>
            <div className="mb10"> <PortfolioProfit /> </div>
            <div className="flex align-center space-between" style={{ width: '100%' }}>
                <div
                    className="profile-mobile-copy-icon-btn flex align-center btn"
                    title="Copy portfolio"
                    onClick={() => setIsCopyPortfolioModalOpen(true)}
                    style={{
                        marginRight: '10px',
                        height: '100%',
                        fontSize: '3rem',
                        padding: '10px'
                    }}
                >
                    {copyIcon} {!isMediumScreen && <span style={{ marginLeft: '5px' }}></span>}
                </div>
                <div
                    className="flex align-center"
                    style={{ marginRight: '10px' }}>
                    <PortfolioAvgScore />
                </div>
                <div className="flex align-center">
                    {
                        companiesSumData &&
                        <div
                            className="flex center-center"
                        >
                            {/* {formatNumberToDollarTableString(companiesSumData)} */}

                            <div className="flex column center-center profile-score-bgc">
                                <div className="profile-returndays-span" style={{ textAlign: 'center' }}>{selectedPieCharts ? formatNumberToDollarTableString(companiesSumData) : '0'}</div>
                                <div className="profile-post-return-str" style={{}}>Worth</div>
                            </div>

                        </div>
                    }
                </div>
            </div>

            <ScoresDisplay />
            {
                isCopyPortfolioModalOpen &&
                <div className="post-modal-overlay" onClick={handleOverlayClick}>
                    {
                        loggedInUser ? (
                            <div className="post-modal-content card-container">
                                <CopyPortfolioContent />
                            </div>
                        ) : (
                            <div className="post-modal-content card-container">
                                <p>You must log in to complete this action.</p>
                                <div className="flex">
                                    <div className='btn' onClick={handleLoginClick}>Login</div>
                                    <div className="btn" onClick={handleSignupClick}>Signup</div>
                                </div>
                            </div>
                        )
                    }
                </div>
            }
        </div>
    };

    const MobileScreen = () => {
        return <div className="flex column" style={{ width: '100%', marginBottom: isMobile ? '25px' : '20px' }}>
            <div className="mb10 flex center-center"> <PortfolioProfit /> </div>
            <div className="flex align-center space-between" style={{ width: '100%' }}>

                <div
                    className="flex align-center btn profile-mobile-copy-icon-btn"
                    title="Copy portfolio"
                    onClick={() => setIsCopyPortfolioModalOpen(true)}
                    style={{
                        marginRight: isMobile ? '0px' : '20px',
                        height: 'fit-content',
                        padding: '10px'
                    }}
                >
                    {copyIcon}
                </div>
                <div
                    className="flex align-center"
                    style={{ marginRight: isMobile ? '0px' : '20px', }}>
                    <PortfolioAvgScore />
                </div>
                <div className="flex align-center">
                    {
                        companiesSumData &&
                        <div
                            className="flex center-center"
                        >
                            {/* {formatNumberToDollarTableString(companiesSumData)} */}
                            <div className="flex column center-center profile-score-bgc">
                                <div className="post-return-str-span profile-returndays-span" style={{ textAlign: 'center' }}>{formatNumberToDollarTableString(companiesSumData)}</div>
                                <div className="profile-post-return-str" style={{}}>Worth</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <ScoresDisplay />
        </div>;
    };

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const ScoreInfoModal = ({ scoreTitle }) => {
        const selectedScoreInfo = scoresInfo.find(currScore => currScore.score === scoreTitle);
        const modalStyle = {
            position: 'absolute',
            top: '-70px',
            left: '-150px',
            backgroundColor: '#fff',
            padding: '10px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
            zIndex: 1000,
            borderRadius: '12px',
            color: '#484848'
        };
        return (
            <div
                ref={modalRef}
                className="score-info-modal"
                style={modalStyle}
            >
                <h4>{capitalizeFirstLetter(scoreTitle)}</h4>
                <p>{selectedScoreInfo ? selectedScoreInfo.info : ''}</p>
            </div>
        )
    };


    const [isCopyPortfolioModalOpen, setIsCopyPortfolioModalOpen] = useState(false);
    const [isCopyPortfolioConfirmed, setIsCopyPortfolioConfirmed] = useState(false);
    const handleCopyPortfolioConfirmation = () => {
        setIsCopyPortfolioConfirmed(true);
    };

    const handleOverlayClick = (ev) => {
        if (ev.target === ev.currentTarget) {
            handleCloseCopyPortfolioModal();
        };
    };

    const handleCloseCopyPortfolioModal = () => {
        setIsCopyPortfolioConfirmed(false);
        setIsCopyPortfolioModalOpen(false);
    };

    const handleSaveCopiedPortfolio = () => {
        handleChangeMySelectedPortfolio(profileScoresSelectedPortfolio);
        const updatedOrders = ordersList.map(order => ({
            ...order,
            amount: parseFloat(order.amount),
            price: parseFloat(order.price)
        }));
        handleCopyPortfolio(profileScoresSelectedPortfolio, updatedOrders);
        handleCloseCopyPortfolioModal();
        history.push('/portfolio/companies');
    };

    const CopyPortfolioContent = () => {
        const portfolioKeys = ['portfolioA', 'portfolioB', 'portfolioC', 'portfolioD', 'portfolioE'];
        const portfolioNames = portfolioKeys.map(portfolioKey => ({
            portfolioKey,
            portfolioName: myPortfolio[portfolioKey]?.portfolioName || portfolioKey
        }));
        return (
            <div style={{ minWidth: '50vw', maxHeight: '80vh' }}>
                <h4 className='portfolio-chart-title'>Copy portfolio data to My Portfolio</h4>
                <p className='mb10'>Select Portfolio:</p>
                <ProfilePortfolioBtns
                    portfolioNames={portfolioNames}
                    selectedPortfolio={profileScoresSelectedPortfolio}
                    handleChangeSelectedPortfolio={setProfilesScoresSelectedPortfolio}
                />
                <FeedTable
                    orders={(myPortfolio[profileScoresSelectedPortfolio] && myPortfolio[profileScoresSelectedPortfolio].portfolioData) || []}
                />
                <FeedConfirmChanges
                    isConfirmed={isCopyPortfolioConfirmed}
                    handleConfirmation={handleCopyPortfolioConfirmation}
                    selectedPortfolio={selectedPortfolio}
                    handleCloseCopyPortfolioModal={handleCloseCopyPortfolioModal}
                    handleSaveCopiedPortfolio={handleSaveCopiedPortfolio}
                    orders={ordersList}
                />
            </div>
        )
    };


    const handleLoginClick = () => {
        handleCloseCopyPortfolioModal();
        history.push('/login');
    };

    const handleSignupClick = () => {
        handleCloseCopyPortfolioModal();
        history.push('/signup');
    };

    const [profileScoresSelectedPortfolio, setProfilesScoresSelectedPortfolio] = useState('portfolioA');

    return (
        <>
            {/* {ordersList.length === 0 ? (
                <div>

                </div>
            ) : (
                // Render content based on the isMobile condition
                isMobile ? (
                    <MobileScreen />
                ) : (
                    <LargeScreen />
                )
            )} */}

            {
                isMobile ? (
                    <MobileScreen />
                ) : (
                    <LargeScreen />
                )
            }
        </>

    );
}

export default ProfileSelectedPortfolioScores;