import React from "react";

const ProfilePortfolioBtns = ({
    portfolioNames,
    selectedPortfolio,
    handleChangeSelectedPortfolio,
}) => {

    const portfolios = [
        { value: 'portfolioA', label: 'portfolioA' },
        { value: 'portfolioB', label: 'portfolioB' },
        { value: 'portfolioC', label: 'portfolioC' },
        { value: 'portfolioD', label: 'portfolioD' },
        { value: 'portfolioE', label: 'portfolioE' }
    ];

    const updatedPortfolios = portfolios.map(portfolio => {
        const matchingPortfolioName = portfolioNames.find(item => item.portfolioKey === portfolio.value);
        if (matchingPortfolioName) {
            return { ...portfolio, label: matchingPortfolioName.portfolioName };
        }
        return portfolio;
    });

    const renderedDivs = updatedPortfolios.map((portfolio, index) => (
        <div className="mobile-select-portfolio-btn flex" style={{ marginBottom: '10px' }}>
            <div
                key={index}
                className={`btn flex align-center ${portfolio.value === selectedPortfolio ? 'selected' : ''}`}
                selectedPortfolio={selectedPortfolio}
                onClick={() => { handleChangeSelectedPortfolio(portfolio.value) }}
                style={{ marginRight: '0px' }}>
                {portfolio.label}
            </div>
        </div>
    ));

    return (
        <>
            <div className="profile-portfolio-btns-container mb10 flex space-evenly wrap">
                {renderedDivs}
            </div>
        </>
    );
}

export default ProfilePortfolioBtns;
 