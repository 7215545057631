import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './single-fund.scss';
// import data from './data.json';
import fund1 from '../imgs/fund1.png';
import { trophyIcon } from '../../../shared/icons/icons';
import './single-fund.scss';
import PageBtn from '../../../shared/page-btn/page-btn';
import FundMembers from '../playoff/fund-members';
import FundPlayoffs from '../playoff/fund-playoff';
import FundLogs from '../playoff/fund-logs';
import logsData from '../playoff/data/fund-log.json';
import { getFundMatchweek, getSingleFund, handleFundMember } from '../../../shared/actions/fund-actions';
import LoadingSpinner from '../../../shared/loading-spinner/loading-spinner';
import FundMatchweek from '../fund-matchweek/fund-matchweek';
import { useSelector, useDispatch } from 'react-redux';
import { getHomepageData } from '../../../shared/actions/homepage-actions';
import { setGlobalLastPrices } from '../../../store/last-prices/last-prices-actions';

const SingleFund = ({ loggedInUser, addStocks, matchweekPortfolio }) => {

    const history = useHistory();

    const dispatch = useDispatch();
    const lastPrices = useSelector((state) => state.lastPrices);

    const handleTitleClick = (itemName) => {
        history.push(`/funds/${itemName}`);
    };

    const [fundData, setFundData] = useState(null);
    const [fundMatchweek, setFundMatchweek] = useState(null);

    const [todayDate, setTodayDate] = useState('');

    const [companiesPrices, setCompaniesPrices] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            if (Object.keys(lastPrices).length === 0) {
                const homepageDataRes = await getHomepageData();
                if (homepageDataRes) {
                    dispatch(setGlobalLastPrices(homepageDataRes.singlesInfo));
                };
            } else {
                const filteredCompanies = Object.entries(lastPrices)
                    .filter(([symbol, item]) => item.nav === 'company')
                    .reduce((acc, [symbol, item]) => {
                        acc[symbol] = item;
                        return acc;
                    }, {});
                setCompaniesPrices(filteredCompanies);
            };
        };
        console.log('lastPrices from store:', lastPrices);
        fetchData();
    }, [lastPrices]);

    const setTodayDateFormatted = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        setTodayDate(formattedDate);
    };

    useEffect(() => {
        setTodayDateFormatted();
    }, []);

    const { fund } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getSingleFund(fund);
                const matchweek = await getFundMatchweek(fund);
                setFundData(data);
                if (matchweek.message === 'Internal Server Error') return;
                setFundMatchweek(matchweek);
                // console.log('matchweek:', matchweek);
                // console.log('fundData:', data);
            } catch (error) {
                console.error("Error fetching fund data:", error);
            };
        };
        fetchData();
    }, [fund]);

    const [activePage, setActivePage] = useState('members');
    const [pages, setPages] = useState(['members', 'history']);

    useEffect(() => {
        if (!fundData) return;
        if (fundData.members.some(member => member.username === loggedInUser)) {
            setPages(prevPages => {
                const updatedPages = [...prevPages];
                const updatedPagesSet = new Set(updatedPages);
                if (updatedPagesSet.has('matchweek')) {
                    return prevPages;
                } else {
                    const indexOfHistory = updatedPages.indexOf('history');
                    const newPagesArray = ['members', 'matchweek', ...updatedPages.slice(indexOfHistory)];
                    return newPagesArray;
                }
            });
        }
    }, [fundData, loggedInUser]);

    const handleToggleOption = (page) => {
        setActivePage(page);
    };

    const getNavLocation = () => {
        return activePage;
    };

    const handleProfileClick = (name) => {
        history.push(`/profile/portfolios/${name}`);
    };

    const countIsWon = logsData.logs.reduce((acc, log) => {
        if (log.isWon) {
            acc.trueCount++;
        } else {
            acc.falseCount++;
        }
        return acc;
    }, { trueCount: 0, falseCount: 0 });

    const onHandleFundMember = async () => {
        if (loggedInUser) {
            try {
                const res = await handleFundMember(loggedInUser, fundData.name, true);
                console.log('RES:', res);
            } catch (error) {
                console.error('Error handling fund member:', error);
                // Handle error if needed
            }
        }
    };

    const handleChangeFundMatchweeks = (match) => {
        setFundMatchweek(match);
    };

    const handleOrdersListChange = (match) => {
        console.log('handleOrdersListChange:', match);
        // setFundMatchweek(match);
    };

    return (
        <div className="funds-container">
            {
                fundData ? (<div className='main-content-container'>
                    <div className="card-container flex space-between mb20">
                        <div className='flex' style={{ width: '100%' }}>
                            <div className='pos-rel mr20 flex column center-center'>
                                <div className="fund-crest-lvl single-fund-crest-lvl">
                                    {fundData.level}
                                </div>
                                <div className='fund-crest' style={{ width: '150px', height: '150px' }}>
                                    <img className='fund-crest-img' src={fundData.pngUrl} style={{ width: '150px', height: '150px' }} />
                                </div>
                            </div>
                            <div style={{ width: '100%' }}>
                                <div className="flex space-between mb20">
                                    <h1>{fundData.name}</h1>
                                    <div className='flex space-between align-center'>
                                        <div className="mr20">
                                            <div className='single-fund-item-trophies-container'>
                                                <div className='flex center-center'>
                                                    {trophyIcon}
                                                </div>
                                                <div className=''>
                                                    {fundData.trophies}
                                                </div>
                                            </div>
                                        </div>
                                        <div>{fundData.isPublic ? 'Public' : 'Private'}</div>
                                    </div>
                                </div>
                                <div className="single-fund-header-row flex space-between">
                                    <div>Fund manager:</div>
                                    <div onClick={() => handleProfileClick(fundData.fundManager)} className='fund-title'>{fundData.fundManager}</div>
                                </div>
                                <div className="single-fund-header-row flex space-between">
                                    <div>Win streak:</div>
                                    <div>{fundData.matchweekWinStreak}</div>
                                </div>
                                <div className="single-fund-header-row flex space-between">
                                    <div>Average level:</div>
                                    <div>{fundData.avgLevel}</div>
                                </div>

                                <div className="single-fund-header-row flex space-between">
                                    <div>Played / Won / Lost:</div>
                                    <div>{logsData.logs.length} / {countIsWon.trueCount} / {countIsWon.falseCount}</div>
                                </div>

                                <div className="flex space-between">
                                    <div>Members:</div>
                                    <div>{fundData.members.length} / 20</div>
                                </div>
                            </div>

                        </div>
                        <div></div>
                    </div>
                    <div className="card-container flex space-between mb20">
                        <div className="flex">
                            {
                                pages.map((page, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleToggleOption(page.toLowerCase())}
                                        className='active-page-item'
                                    >
                                        <PageBtn activePage={activePage} label={page} getNavLocation={getNavLocation} />
                                    </div>
                                ))
                            }
                        </div>
                        {
                            fundData.isPublic &&
                            <div className='single-fund-action-btn flex align-center' onClick={onHandleFundMember}>Join Fund</div>
                        }
                    </div>
                    {
                        activePage === 'members' &&
                        fundData &&
                        <FundMembers
                            fundMembers={fundData.members}
                        />
                    }
                    {
                        activePage === 'matchweek' &&
                        <FundMatchweek
                            loggedInUser={loggedInUser}
                            fund={fund}
                            fundManager={fundData.fundManager}
                            fundMatchweek={fundMatchweek}
                            fundMembers={fundData.members}
                            handleChangeFundMatchweeks={handleChangeFundMatchweeks}
                            handleOrdersListChange={handleOrdersListChange}
                            todayDate={todayDate}
                            addStocks={addStocks}
                            companiesPrices={companiesPrices}
                        />
                    }
                    {activePage === 'history' && <FundLogs />}
                </div>
                ) : (
                    <LoadingSpinner />
                )
            }

        </div>
    );
}

export default SingleFund;
