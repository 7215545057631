import './technical-content-priceovertime.scss';
import LineChart from '../../../../shared/charts/line-chart/line-chart';
import CardContainer from '../../../../cmps/card-container/card-container'
import { useEffect } from 'react';
const TechnicalPriceOverTime = ({ data, filter }) => {

    const getPriceOverTimeLineChartData = () => {
        const filteredData = data[filter];
        const chartData = {
            datasets: []
        };
        // loop through each set of data
        Object.keys(filteredData).forEach((key) => {
            const color = key === 'price' ? '#5c9ead' : '#e39774';
            const dataset = {
                label: key,
                data: filteredData[key],
                backgroundColor: color,
                borderColor: color,
                borderWidth: 2,
                fill: false
            };
            if (key !== 'price') {
                dataset.borderDash = [5, 5];
            }
            dataset.hoverBackgroundColor = '#e39774';
            dataset.hoverBorderColor = '#e39774';
            chartData.datasets.push(dataset);
        });
        return chartData;
    };

    const technicalLineChartContent = (
        <div style={{width: '99.9%', height: '90%'}}>
            <LineChart chartData={getPriceOverTimeLineChartData()} type={'dollar'} />
        </div>
    );

    const technicalLineChartTitle = (
        <>
            <h3>Price Over Time</h3>
        </>
    );

    return (
        <>
                <CardContainer
                    title={technicalLineChartTitle}
                    innerContent={technicalLineChartContent}
                    className={'crypto-linechart flex column space-between'}
                    infoModalType={'priceOverTime'}
                />
        </>
    );
}

export default TechnicalPriceOverTime;