import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './calendar-day.scss';

const CalendarDay = ({ dayData, activePage, filters, getColorHex }) => {

    const [filteredData, setFilteredData] = useState(null);

    const { day: { dailyData: { companies, macro }, dateName, dateNumber } } = dayData;
    const { day: { dailyData } } = dayData;

    useEffect(() => {
        const fixedData = dailyData[activePage].filter((item) =>
            filters.some((filter) => filter.filter === item.filter)
        );
        setFilteredData(fixedData);
    }, [dailyData, activePage, filters])

    return (
        <>
            {filteredData && (
                <div className="calendar-day-container">
                    <div className="calendar-day-datenumber">{`${dateNumber}`.padStart(2, '0')}</div>
                    {activePage === 'companies' ? (
                        <div>
                            <div className='calendar-day-singleday'>
                                {filteredData.map((item, idx) => (
                                    <div key={idx}>
                                        <div
                                            className={`calendar-day-symbol ${idx % 2 === 0 ? '' : 'calendar-day-odd-item'}`}
                                            style={{ borderLeft: `10px solid ${getColorHex(item.color)}` }}
                                        >
                                            <Link
                                                to={`/company/${item.symbol}`}
                                            >{item.symbol}</Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div>
                            {
                                filteredData.map((item, idx) => (
                                    <div
                                        className='calendar-day-singleday'
                                        key={idx}>
                                        {
                                            Array.isArray(item.data) &&
                                            item.data.map((dataItem, dataIdx) => (
                                                <div
                                                    key={dataIdx}
                                                    className={`calendar-day-macro ${dataIdx % 2 === 0 ? '' : 'calendar-day-odd-item'}`}
                                                    style={{ borderLeft: `10px solid ${getColorHex(item.color)}` }}
                                                >
                                                    {dataItem}
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default CalendarDay;