import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalIsLoading } from "../../../store/loading/loading-actions";
import LoadingSpinner from "../../../shared/loading-spinner/loading-spinner";
import ActivePageSelection from "../../../shared/active-page/active-page";
import '../portfolio.scss';
import PortfolioFilters from "../portfolio-filters/portfolio-filters";
import PortfolioContent from "../portfolio-content";
import { getPortfolioPage } from "../../../shared/actions/portfolio-actions";
import { useHistory } from "react-router-dom";
import PortfolioGuide from "../portfolio-guide/portfolio-guide";

const SelectedPortfolio = ({
    navPage,
    loggedInUser,
    myPortfolio,
    mySelectedPortfolio,

    portfolioCompanyData,
    portfolioSectorData,
    portfolioFundamentalData,
    portfolioDescData,
    portfolioSensData,

    handleChangePortfolioCompaniesData,
    handleChangePortfolioSectorsData,
    handleChangePortfolioFundamentalData,
    handleChangePortfolioDescData,
    handleChangePortfolioSensData,
    portfolio,
    addStock,
    addStocks,
    handleChangeSelectedPortfolio
}) => {

    const loadingSelector = useSelector((state) => state.loading.isLoading);
    const dispatch = useDispatch();
    const history = useHistory();
    const [activePage, setActivePage] = useState(navPage);
    const [activePeriodFilter, setActivePeriodFilter] = useState('oneMonth');
    const [activeSectors, setActiveSectors] = useState(null);
    const [activeCompanies, setActiveCompanies] = useState(null);

    const [periodFilters, setPeriodFilters] = useState(null);
    const [companyFilters, setCompanyFilters] = useState(null);
    const [sectorFilters, setSectorFilters] = useState(null);

    const [fullCompanyFilter, setFullCompanyFilter] = useState(null);

    const [companiesPeriodFilters, setCompaniesPeriodFilters] = useState(null);
    const [sectorsPeriodFilters, setSectorsPeriodFilters] = useState(null);
    const [descPeriodFilters, setDescPeriodFilters] = useState(null);
    const [sensPeriodFilters, setSensPeriodFilters] = useState(null);
    const [fundamentalFilter, setFundamentalFilter] = useState('overTime');
    const [selectedFilter, setSelectedFilter] = useState(null);

    const [companyData, setCompanyData] = useState(null);
    const [sectorData, setSectorData] = useState(null);
    const [fundamentalData, setFundamentalData] = useState(null);
    const [descData, setDescData] = useState(null);
    const [sensData, setSensData] = useState(null);

    const getPageFilters = (data) => {

        const sectorSet = new Set();
        const companySet = new Set();

        const sectorFilters = data.map((company) => {
            const isUnique = sectorSet.has(company.sector) ? false : sectorSet.add(company.sector);
            return isUnique ? { value: company.sector, label: company.sector } : null;
        }).filter(Boolean);

        const companyFilters = data.map((company) => {
            const isUnique = companySet.has(company.symbol) ? false : companySet.add(company.symbol);
            return isUnique
                ? { value: company.symbol, label: `(${company.symbol}) ${company.company}`, sector: company.sector }
                : null;
        }).filter(Boolean);

        setSectorFilters(sectorFilters);
        setCompanyFilters(companyFilters);
        setActiveSectors(sectorFilters);
        setActiveCompanies(companyFilters);
        setFullCompanyFilter(companyFilters);
    };

    const getLoadedPageFilters = (data) => {
        if (activePage === 'companies') {

            const loadedSectorFilters = Array.from(
                new Set(data.oneYear.concat(data.sixMonth).map(company => company.sector))
            ).map(sector => ({ value: sector, label: sector }));

            // Create loadedCompanyFilters array
            const loadedCompanyFilters = data.oneYear.concat(data.sixMonth).map(company => ({
                value: company.symbol,
                label: `(${company.symbol}) ${company.company}`,
                sector: company.sector
            }));

            setSectorFilters(loadedSectorFilters);
            setCompanyFilters(loadedCompanyFilters);
            setActiveSectors(loadedSectorFilters);
            setActiveCompanies(loadedCompanyFilters);
            setFullCompanyFilter(loadedCompanyFilters);
        }
    };

    useEffect(() => {
        if (activePage === 'descriptive' || activePage === 'sensitivity') {
            const pagePeriod = 'oneMonth';
            setActivePeriodFilter(pagePeriod);
            setSelectedFilter(prevFilter => ({
                ...prevFilter,
                period: pagePeriod
            }));
        }
        const fetchData = async () => {
            try {
                dispatch(setGlobalIsLoading(true));

                if (activePage === 'companies' && portfolioCompanyData) {
                    if (!companyData) setCompanyData(portfolioCompanyData);
                    getLoadedPageFilters(portfolioCompanyData.barcharts);
                } else if (activePage === 'sectors' && portfolioSectorData) {
                    if (!sectorData) setSectorData(portfolioSectorData);
                    getLoadedPageFilters(portfolioSectorData.barcharts);
                } else if (activePage === 'fundamental' && portfolioFundamentalData) {
                    if (!fundamentalData) setFundamentalData(portfolioFundamentalData);
                    getLoadedPageFilters(portfolioSectorData.barcharts);
                } else if (activePage === 'descriptive' && portfolioDescData) {
                    if (!descData) setDescData(portfolioDescData);
                    getLoadedPageFilters(portfolioSectorData.barcharts);
                } else if (activePage === 'sensitivity' && portfolioSensData) {
                    if (!sensData) setSensData(portfolioSensData);
                    getLoadedPageFilters(portfolioSectorData.barcharts);
                } else {
                    const res = await getPortfolioPage(activePage, myPortfolio.portfolioData);
                    if (res) {
                        // Handle the response based on activePage
                        switch (activePage) {
                            case 'companies':

                                const companiesData = {
                                    barcharts: {},
                                    piechart: [],
                                    valueOverTime: []
                                };

                                res.companyPages.forEach((company) => {

                                    const barChartsKeys = Object.keys(company.companies.barCharts);
                                    barChartsKeys.forEach((chart) => {
                                        if (!companiesData.barcharts[chart]) {
                                            companiesData.barcharts[chart] = [];
                                        }
                                        const chartData = company.companies.barCharts[chart];
                                        companiesData.barcharts[chart].push({
                                            return: chartData.return,
                                            sharpe: chartData.sharpe,
                                            beta: chartData.beta,
                                            info: chartData.information,
                                            positiveDays: chartData.positiveDays,
                                            std: chartData.std,
                                            symbol: company.symbol,
                                            sector: company.sector,
                                            company: company.company,
                                            color: company.color,
                                        });
                                    });

                                    companiesData.valueOverTime.push({
                                        value: company.companies.valueOverTime,
                                        symbol: company.symbol,
                                        sector: company.sector,
                                        company: company.company,
                                        color: company.color,
                                    });

                                    // Pie Chart
                                    companiesData.piechart.push({
                                        value: company.companies.pieChart,
                                        symbol: company.symbol,
                                        sector: company.sector,
                                        company: company.company,
                                        color: company.color,
                                    });
                                });
                                setCompanyData(companiesData);
                                getPageFilters(res.companyPages);
                                const companyPeriodFilter = getPeriodFilters(Object.keys(res.companyPages[0].companies.barCharts));
                                setCompaniesPeriodFilters(companyPeriodFilter);
                                handleChangePortfolioCompaniesData(companiesData);

                                break;
                            case 'sectors':
                                const sectorsData = {
                                    barcharts: {},
                                    piechart: [],
                                    valueOverTime: []
                                };

                                res.sectorPage.forEach((sector) => {
                                    const barChartsKeys = Object.keys(sector.barCharts);
                                    // Iterate over barChartsKeys
                                    barChartsKeys.forEach((chart) => {
                                        // Initialize the array if it doesn't exist
                                        if (!sectorsData.barcharts[chart]) {
                                            sectorsData.barcharts[chart] = [];
                                        }
                                        // Push data into the array
                                        sectorsData.barcharts[chart].push({
                                            sector: sector.sector,
                                            companies: sector.companies,
                                            color: sector.color,
                                            return: sector.barCharts[chart].return,
                                            sharpe: sector.barCharts[chart].sharpe,
                                            // beta: sector.barCharts[chart].beta,
                                            // info: sector.barCharts[chart].information,
                                            positiveDays: sector.barCharts[chart].positiveDays,
                                            std: sector.barCharts[chart].std
                                        });
                                    });

                                    sectorsData.piechart.push({
                                        value: sector.pieChart,
                                        sector: sector.sector,
                                        companies: sector.companies,
                                        color: sector.color,
                                    });

                                    sectorsData.valueOverTime.push({
                                        value: sector.valueOverTime,
                                        sector: sector.sector,
                                        companies: sector.companies,
                                        color: sector.color,
                                    });

                                });
                                setSectorData(sectorsData);
                                getPageFilters(res.companyPages);
                                const sectorPeriodFilter = getPeriodFilters(Object.keys(res.sectorPage[0].barCharts));
                                setSectorsPeriodFilters(sectorPeriodFilter);
                                handleChangePortfolioSectorsData(sectorsData);
                                break;
                            case 'fundamental':

                                const fundamentalData = [];
                                res.forEach((company) => {

                                    fundamentalData.push({
                                        symbol: company.symbol,
                                        company: company.company,
                                        sector: company.sector,
                                        color: company.color,
                                        fundamentals: company.fundamentals
                                    });

                                });

                                setFundamentalData(fundamentalData);
                                getPageFilters(res);
                                handleChangePortfolioFundamentalData(fundamentalData);

                                break;
                            case 'descriptive':
                                const descriptiveData = {
                                    kernelDensity: {},
                                    correlations: {},
                                    topTable: {},
                                    bottomTable: {}
                                }

                                res.companies.forEach((company) => {

                                    const kernelKeys = Object.keys(company.descriptive.kernelDensity);
                                    kernelKeys.forEach((kernelPeriod) => {
                                        if (!descriptiveData.kernelDensity[kernelPeriod]) {
                                            descriptiveData.kernelDensity[kernelPeriod] = [];
                                        };
                                        const kdeChartData = company.descriptive.kernelDensity[kernelPeriod];
                                        descriptiveData.kernelDensity[kernelPeriod].push({
                                            symbol: company.symbol,
                                            company: company.company,
                                            sector: company.sector,
                                            color: company.color,
                                            kdeDist: kdeChartData.kdeDist
                                        })

                                        if (!descriptiveData.topTable[kernelPeriod]) {
                                            descriptiveData.topTable[kernelPeriod] = [];
                                        };

                                        descriptiveData.topTable[kernelPeriod].push({
                                            symbol: company.symbol,
                                            company: company.company,
                                            sector: company.sector,
                                            color: company.color,
                                            tableData: [company.symbol, kdeChartData.mean, kdeChartData.median, kdeChartData.std, kdeChartData.range]
                                        })

                                        const outliersKeys = Object.keys(company.descriptive.outliersTable);
                                        outliersKeys.forEach(outlierPeriod => {
                                            if (!descriptiveData.bottomTable[outlierPeriod]) {
                                                descriptiveData.bottomTable[outlierPeriod] = [];
                                            }
                                            const outlierTableData = company.descriptive.outliersTable[outlierPeriod];
                                            descriptiveData.bottomTable[outlierPeriod] = descriptiveData.bottomTable[outlierPeriod].concat(
                                                outlierTableData.map(item => ({
                                                    symbol: company.symbol,
                                                    company: company.company,
                                                    sector: company.sector,
                                                    color: company.color,
                                                    tableData: [company.symbol, item.return, item.date, item.method],
                                                }))
                                            ).sort((a, b) => new Date(b.tableData[2]) - new Date(a.tableData[2]));
                                        });

                                    })

                                });

                                descriptiveData.correlations = res.descriptiveCorrelationsTable;

                                setDescData(descriptiveData);
                                getPageFilters(res.companies);
                                const descPeriodFilter = getPeriodFilters(Object.keys(res.companies[0].descriptive.kernelDensity));
                                setDescPeriodFilters(descPeriodFilter);
                                handleChangePortfolioDescData(descriptiveData);
                                break;
                            case 'sensitivity':
                                const sensitivityData = {
                                    monteCarlo: {},
                                    table: {}
                                }

                                res.companies.forEach((company) => {
                                    const sensitivityKeys = Object.keys(company.sensitivity);
                                    sensitivityKeys.forEach(sensitivityPeriod => {
                                        if (!sensitivityData.table[sensitivityPeriod]) {
                                            sensitivityData.table[sensitivityPeriod] = [];
                                        }
                                        const sensitivityTable = company.sensitivity[sensitivityPeriod];
                                        sensitivityData.table[sensitivityPeriod].push({
                                            table: sensitivityTable,
                                            symbol: company.symbol,
                                            company: company.company,
                                            sector: company.sector,
                                            color: company.color
                                        })
                                    });
                                });

                                sensitivityData.monteCarlo = res.sensitivityMonteCarlo;

                                setSensData(sensitivityData);
                                getPageFilters(res.companies);
                                const sensPeriodFilter = getPeriodFilters(Object.keys(res.companies[0].sensitivity.table));
                                setSensPeriodFilters(sensPeriodFilter);
                                handleChangePortfolioSensData(sensitivityData);
                                break;
                            default:
                                // Handle unknown activePage
                                break;
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                dispatch(setGlobalIsLoading(false));
            }
        };
        fetchData();
    }, [activePage, portfolioCompanyData]);

    // useEffect(() => {
    //     const order = ['oneWeek', 'oneMonth', 'sixMonth', 'currentYear', 'oneYear', 'fiveYear', 'max'];
    //     const getOrderedList = (filters) =>
    //         order
    //             .filter((period) => filters.some((filter) => filter.value === period))
    //             .map((period) => filters.find((filter) => filter.value === period));
    //     if (activePage === 'companies' && companiesPeriodFilters) setPeriodFilters(getOrderedList(companiesPeriodFilters));
    //     if (activePage === 'sectors' && sectorsPeriodFilters) setPeriodFilters(getOrderedList(sectorsPeriodFilters));
    //     if (activePage === 'descriptive' && descPeriodFilters) setPeriodFilters(getOrderedList(descPeriodFilters));
    //     if (activePage === 'sensitivity' && sensPeriodFilters) setPeriodFilters(getOrderedList(sensPeriodFilters));
    // }, [activePage, companiesPeriodFilters, sectorsPeriodFilters, descPeriodFilters, sensPeriodFilters]);

    // useEffect(() => {
    //     if (!activePage || !activePeriodFilter || !activeSectors || !activeCompanies) return;

    //     const selectedSectors = activeSectors.map(item => item.value);
    //     const selectedCompanies = activeCompanies.map(item => item.value);

    //     const selectedFilters = {
    //         page: activePage,
    //         period: activePeriodFilter,
    //         sectors: selectedSectors,
    //         companies: selectedCompanies
    //     };

    //     if (selectedFilters.page === 'fundamental') {
    //         selectedFilters.sectors = selectedSectors.length > 0 ? [selectedSectors[0]] : null;
    //     }

    //     setSelectedFilter(selectedFilters);
    // }, [activePage, activePeriodFilter, activeSectors, activeCompanies]);

    useEffect(() => {
        const order = ['oneWeek', 'oneMonth', 'sixMonth', 'currentYear', 'oneYear', 'fiveYear', 'max'];

        const getOrderedList = (filters) =>
            order
                .filter((period) => filters.some((filter) => filter.value === period))
                .map((period) => filters.find((filter) => filter.value === period));

        // Period Filters Logic
        if (activePage && activePeriodFilter && activeSectors && activeCompanies) {
            if (activePage === 'companies' && companiesPeriodFilters) {
                setPeriodFilters(getOrderedList(companiesPeriodFilters));
            } else if (activePage === 'sectors' && sectorsPeriodFilters) {
                setPeriodFilters(getOrderedList(sectorsPeriodFilters));
            } else if (activePage === 'descriptive' && descPeriodFilters) {
                setPeriodFilters(getOrderedList(descPeriodFilters));
            } else if (activePage === 'sensitivity' && sensPeriodFilters) {
                setPeriodFilters(getOrderedList(sensPeriodFilters));
            }
        }

        // Selected Filters Logic
        if (activePage && activePeriodFilter && activeSectors && activeCompanies) {
            const selectedSectors = activeSectors.map((item) => item.value);
            const selectedCompanies = activeCompanies.map((item) => item.value);

            const selectedFilters = {
                page: activePage,
                period: activePeriodFilter,
                sectors: selectedSectors,
                companies: selectedCompanies,
            };

            if (selectedFilters.page === 'fundamental') {
                selectedFilters.sectors = selectedSectors.length > 0 ? [selectedSectors[0]] : null;
            }

            setSelectedFilter(selectedFilters);
        }
    }, [activePage, activePeriodFilter, activeSectors, activeCompanies, companiesPeriodFilters, sectorsPeriodFilters, descPeriodFilters, sensPeriodFilters]);



    const handleToggleOption = (option) => {
        if (option === 'guide') {
            history.push(`/portfolio`);
        } else {
            history.push(`/portfolio/${option}`);
        };
        setSelectedFilter(prevFilter => ({
            ...prevFilter,
            page: option
        }));
        setActivePage((prevOption) => {
            return option;
        });
    };

    const onPeriodChange = (option) => {
        setActivePeriodFilter((prevOption) => {
            return option.value;
        })
    };

    const onChangeSectors = (option) => {
        setActiveSectors((prevOption) => {
            return option;
        })
    };

    const onChangeCompanies = (option) => {
        setActiveCompanies((prevOption) => {
            return option;
        })
    };

    const handleToggleFundamentalFilter = (option) => {
        setFundamentalFilter((prevOption) => {
            return option;
        });
    };

    const pages = ['companies', 'sectors', 'fundamental', 'descriptive', 'sensitivity', 'guide'];

    const getPeriodFilters = (timeframes) => {
        const mapping = {
            'oneWeek': '1 Week',
            'oneMonth': '1 Month',
            'sixMonth': '6 Months',
            'currentYear': 'YTD',
            'oneYear': '1 Year',
            'fiveYear': '5 Years',
            'max': 'Max',
        };

        return timeframes
            .filter(timeframe => mapping.hasOwnProperty(timeframe))
            .map(timeframe => ({ value: timeframe, label: mapping[timeframe] }));
    };

    useEffect(() => {
        if (companyData) {
            const companyPeriodFilter = getPeriodFilters(Object.keys(companyData.barcharts));
            setCompaniesPeriodFilters(companyPeriodFilter);
        }
        if (sectorData) {
            const sectorPeriodFilter = getPeriodFilters(Object.keys(sectorData.barcharts));
            setSectorsPeriodFilters(sectorPeriodFilter);
        }
        if (descData) {
            const descPeriodFilter = getPeriodFilters(Object.keys(descData.kernelDensity));
            setDescPeriodFilters(descPeriodFilter);
        }
        if (sensData) {
            const sensPeriodFilter = getPeriodFilters(Object.keys(sensData.table.table[0].table));
            setSensPeriodFilters(sensPeriodFilter);
        }
    }, [companyData, sectorData, descData, sensData]);

    const getNavLocation = (label) => {
        return `/portfolio/${label}`
    };

    return (
        <>
            {loadingSelector ? (
                <LoadingSpinner />
            ) : (
                <>
                    <div className="portfolio-pages-container">
                        {
                            pages &&
                            <ActivePageSelection
                                handleToggleOption={handleToggleOption}
                                pages={pages}
                                activePage={activePage}
                                getNavLocation={getNavLocation}
                                guide={'/portfolio'}
                            />
                        }
                        {
                            navPage === 'guide' && (
                                <PortfolioGuide />
                            ) 
                        }
                        {
                            periodFilters &&
                            companyFilters &&
                            sectorFilters &&
                            activePeriodFilter &&
                            activeSectors &&
                            activeCompanies &&
                            <PortfolioFilters
                                activePage={activePage}
                                periodFilters={periodFilters}
                                companyFilters={companyFilters}
                                sectorFilters={sectorFilters}
                                activePeriodFilter={activePeriodFilter}
                                onPeriodChange={onPeriodChange}
                                activeSectors={activeSectors}
                                activeCompanies={activeCompanies}
                                onChangeSectors={onChangeSectors}
                                onChangeCompanies={onChangeCompanies}
                                setCompanyFilters={setCompanyFilters}
                                fullCompanyFilter={fullCompanyFilter}
                                fundamentalFilter={fundamentalFilter}
                                handleToggleFundamentalFilter={handleToggleFundamentalFilter}

                                addStock={addStock}
                                addStocks={addStocks}
                                myPortfolio={myPortfolio}
                                selectedPortfolio={mySelectedPortfolio}
                                portfolio={portfolio}
                                handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                                loggedInUser={loggedInUser}
                            />
                        }
                    </div>
                    {
                        selectedFilter &&
                        companyData &&
                        <PortfolioContent
                            selectedFilter={selectedFilter}
                            companyData={companyData}
                            sectorData={sectorData}
                            fundamentalData={fundamentalData}
                            descData={descData}
                            sensData={sensData}
                            fundamentalFilter={fundamentalFilter}
                            portfolioData={myPortfolio.portfolioData}
                        />
                    }
                    {/* {
                        loggedInUser &&
                        <PortfolioMenu
                            addStock={addStock}
                            addStocks={addStocks}
                            myPortfolio={myPortfolio}
                            selectedPortfolio={mySelectedPortfolio}
                            portfolio={portfolio}
                            handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                        />
                    } */}

                </>
            )}
        </>
    );

}

export default SelectedPortfolio;