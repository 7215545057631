import React, { useState, useEffect } from 'react';
import MovingHeaderItem from './moving-header-item/moving-header-item';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './moving-header.scss';

const HomepageMovingHeader = ({ movingHeaderData }) => {

    return (
        <>
            {
                movingHeaderData ? (
                    <div className="moving-header-slider">
                        <div className="moving-header-slide-track" style={{
                            width: `${800 * movingHeaderData.length * 2}px`,
                            display: "flex",
                        }}>
                            {movingHeaderData.map((item, index) => (
                                <MovingHeaderItem key={index} item={item} />
                            ))}
                            {movingHeaderData.map((item, index) => (
                                <MovingHeaderItem key={index} item={item} />
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className='moving-header-slider flex'>
                        <div className='moving-header-placeholder'></div>
                        <div className='moving-header-placeholder'></div>
                        <div className='moving-header-placeholder'></div>
                        <div className='moving-header-placeholder'></div>
                    </div>
                )
            }
        </>
    );

}

export default HomepageMovingHeader;