// fetch all data from s3 bucket:
export const fetchDataFromLambda = async (bucket) => {
    try {
        const raw = JSON.stringify({ "bucket": bucket });
        const requestOptions = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: raw,
            redirect: "follow"
        };
        const response = await fetch(
            "https://h16qdvn424.execute-api.us-east-1.amazonaws.com/stage-post-cors/GetAllDataFromS3Bucket",
            requestOptions
        );

        if (!response.ok) {
            console.error('FAIL:', response.status, response);
            return null;
        }

        const result = await response.text();
        const parsedData = JSON.parse(result);
        // Check if parsedData contains the expected data
        if (parsedData) {
            return parsedData;
        } else {
            console.error('Invalid response format:', parsedData);
            return null;
        }
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};
