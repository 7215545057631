
import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { validateInput } from '../../../shared/actions/portfolio-actions';
import { checkIcon, closeIcon } from '../../../shared/icons/icons';
import { useMediaQuery } from 'react-responsive';
import '../manage-portfolio/manage-portfolio.scss';
import { getTodayDateToDisplay } from '../../../shared/global-color-functions';
import { useSelector, useDispatch } from 'react-redux';

const PortfolioInputsRow = ({
    idx,
    order,
    onSaveRows,
    removeOrderFromAdded,
    getRandomId,
    onSaveRow,
    onHandleOrderChange,
    isFundMatchweek,
    companiesPrices
}) => {

    const globalLastPrices = useSelector((state) => state.lastPrices);

    const isMediumScreen = useMediaQuery({ maxWidth: 1300 });
    const isMobileScreen = useMediaQuery({ maxWidth: 920 });

    const [symbol, setSymbol] = useState(order.symbol);
    const [amount, setAmount] = useState(order.amount);
    const [date, setDate] = useState(order.buyDate);
    const [price, setPrice] = useState(order.price);
    // const [order, setOrder] = useState('Buy');
    const [priceRange, setPriceRange] = useState({});
    const [error, setError] = useState(false);
    const [symbolError, setSymbolError] = useState('');
    const [amountError, setAmountError] = useState('');
    const [dateError, setDateError] = useState('');

    useEffect(() => {
        const todayDate = getTodayDate();
        setDate(todayDate);
    }, []);

    useEffect(() => {
        const handleInputValidation = async () => {

            if (isFundMatchweek && companiesPrices[symbol]) {
                const companyPrice = +companiesPrices[symbol].lastPrice;
                setPrice(companyPrice);
                setDate(todayDate);
            };

            const valueToPass = {
                // hardcoded date:
                // date: isFundMatchweek ? todayDate : date,
                date: isFundMatchweek ? '2023-05-27' : date,
                amount: amount.toString(),
                symbol,
            };

            try {
                const res = await validateInput('date', valueToPass);
                if (res.err) {
                    setError(true);
                    switch (res.err) {
                        case 'symbol':
                            setSymbolError(res.msg);
                            setAmountError('');
                            setDateError('');
                            break;
                        case 'amount':
                            setAmountError(res.msg);
                            setSymbolError('');
                            setDateError('');
                            break;
                        case 'date':
                            setDateError(res.msg);
                            setSymbolError('');
                            setAmountError('');
                            break;
                        default:
                            break;
                    }
                } else if (res.default && res.min && res.max) {
                    setPrice(res.default);
                    setPriceRange({
                        default: +res.default,
                        min: +res.min,
                        max: +res.max,
                    });
                    setError(false);
                } else {
                    setError(false);
                };

            } catch (error) {
                // Handle errors appropriately
                console.error('Error during input validation:', error);
            }
        };
        handleInputValidation();
    }, [symbol, amount, date]);

    const getTodayDate = () => {
        return new Date().toISOString().split('T')[0];
    };

    const fetchOptions = async (inputValue, cb) => {

        const filteredPrices = Object.fromEntries(
            Object.entries(globalLastPrices).filter(([key]) => key.includes('=company'))
        );

        const searchLower = inputValue.toLowerCase();

        const filteredByInput = Object.fromEntries(
            Object.entries(filteredPrices).filter(([key, value]) =>
                value.symbol.toLowerCase().includes(searchLower) ||
                value.name.toLowerCase().includes(searchLower)
            )
        );

        const resultArray = Object.values(filteredByInput).map(item => ({
            label: `(${item.symbol}) ${item.name}`,
            value: item.symbol
        }));
        
        cb(resultArray);

        // try {
        //     const res = await validateInput('symbol', inputValue);
        //     if (res) {
        //         if (Array.isArray(res)) {
        //             cb(res);
        //         } else if (typeof res === 'object' && res !== null) {
        //             setSymbolError(res.msg);
        //             cb([]);
        //         } else {
        //             setSymbolError(res.msg);
        //             cb([]);
        //         }
        //     }

        // } catch (error) {
        //     cb([]);
        //     console.error('Error validating data:', error);
        // }
    };

    const onChangeSymbol = (value) => {
        setSymbol(value.value);
        onHandleOrderChange(order.orderId, 'symbol', value.value);
    };

    const onChangeAmount = (newAmount) => {
        setAmount(newAmount);
        // onHandleOrderChange(order.orderId, 'amount', newAmount);
    };

    const onChangeDate = (newBuyDate) => {
        setDate(newBuyDate);
        // onHandleOrderChange(order.orderId, 'buyDate', newBuyDate);
    };

    const onChangePrice = (newPrice) => {
        setPrice(newPrice);
        // onHandleOrderChange(order.orderId, 'price', newPrice);
    };

    const saveRow = () => {
        onSaveRows(idx, {
            symbol,
            amount,
            buyDate: date,
            price: +price,
            orderId: getRandomId()
        });

    };

    const handleBlur = () => {
        onSaveRow({
            symbol,
            amount,
            orderId: order.orderId
        })
    };

    const isButtonDisabled = (
        symbol === '' ||
        amount === '' ||
        date === '' ||
        price <= 0 ||
        error
    );

    const todayDate = getTodayDateToDisplay();

    return (
        <div>
            <div className='portfolio-inputs-row-container flex center-center'>
                <div style={{ width: '20px' }}></div>
                <div
                    style={{ width: '25%' }}
                    className="portfolio-inputs-row-cell flex column">
                    {
                        <>
                            <AsyncSelect
                                value={symbol.length > 1 ? { label: symbol, value: symbol } : null}
                                formatOptionLabel={(option, state) => {
                                    if (state.context.error) {
                                        return 'Error loading options';
                                    }
                                    return option.label;
                                }}
                                loadOptions={fetchOptions}
                                placeholder=""
                                onChange={(selectedOpt) => onChangeSymbol(selectedOpt)}
                                noOptionsMessage={() => symbol.length > 0 ? 'Can\'t find symbol or company.' : null}
                                defaultOptions={false}
                                onBlur={() => handleBlur()}
                                styles={
                                    {
                                        dropdownIndicator: () => ({ color: '#484848' }),
                                        option: (base, state) => ({
                                            ...base,
                                            color: state.isSelected ? '#484848' : '#484848',
                                            backgroundColor: '#ffffff',
                                            border: '1.5px solid #EEEEEE',
                                            borderColor: '#484848',
                                            cursor: 'pointer',
                                            fontFamily: 'inherit'
                                        }),
                                        input: (baseStyles) => ({
                                            ...baseStyles,
                                            color: '#484848',
                                        }),
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            width: '130px',
                                            borderRadius: '0px',
                                            borderWidth: '0px',
                                            color: '#484848',
                                            fontSize: '1rem',
                                            boxShadow: 'none',
                                            border: ' 1px solid #484848',
                                            borderRadius: '18px',
                                            marginRight: '20px',
                                            marginBottom: '5px',
                                            backgroundColor: '#f1f6fc',
                                            '&:hover': {
                                                borderColor: '#484848',
                                            },
                                            '&:focus': {
                                                borderColor: '#484848',
                                                outline: 'none'
                                            },

                                        }),
                                        singleValue: (base, state) => ({
                                            ...base,
                                            color: '#484848',
                                        }),
                                        placeholder: (baseStyles) => ({
                                            ...baseStyles,
                                            color: '#484848'
                                        }),
                                    }

                                }
                            />
                            {
                                <div
                                    className='portfolio-manage-form-errormsg'
                                    style={{ height: '30px' }}
                                >{error && symbolError ? symbolError : ''}
                                </div>
                            }
                        </>
                    }
                </div>

                <div
                    style={{ width: '25%' }}
                    className="portfolio-inputs-row-cell flex column">
                    {
                        <>
                            <input
                                type="number"
                                value={amount}
                                onBlur={() => handleBlur()}
                                onChange={(ev) => onChangeAmount(+ev.target.value)}
                                className="portfolio-form-input portfolio-form-input-manage"

                            />
                            {
                                <div
                                    className='portfolio-manage-form-errormsg'
                                    style={{ height: '30px' }}
                                >{error && amountError ? amountError : ''}
                                </div>
                            }
                        </>
                    }
                </div>

                {
                    !isFundMatchweek ?
                        <div
                            style={{ width: '25%' }}
                            className="portfolio-inputs-row-cell flex column">
                            {
                                <>
                                    <input
                                        type="date"
                                        value={date}
                                        onChange={(ev) => onChangeDate(ev.target.value)}
                                        className="portfolio-form-input portfolio-form-input-manage"
                                    />
                                    {
                                        <div
                                            className='portfolio-manage-form-errormsg'
                                            style={{ height: '30px' }}
                                        >{error && dateError ? dateError : ''}
                                        </div>
                                    }
                                </>
                            }
                        </div> : <div
                            style={{ width: '25%', height: '80px', marginBottom: '25px' }}
                            className="portfolio-inputs-row-cell flex align-center"
                        >
                            {todayDate}
                        </div>
                }
                {
                    !isFundMatchweek ?
                        <div
                            style={{ width: '25%' }}
                            className="portfolio-inputs-row-cell flex column"
                        >
                            {
                                !error && priceRange.min && priceRange.max > 0 ? (
                                    <div
                                        className='portfolio-form-item-container portfolio-range-input'
                                        style={{ height: '80px' }}
                                    >
                                        <div className="flex justify-center" style={{ width: '200px' }}>
                                            <input
                                                type="text"
                                                id="price-input"
                                                value={price === '' ? '' : `$${price}`}
                                                onChange={(ev) => {
                                                    const newValue = ev.target.value;
                                                    setPrice(newValue.replace(/\$/g, ''));
                                                }}
                                                onBlur={() => {
                                                    let numericValue = parseFloat(price);
                                                    if (isNaN(numericValue)) {
                                                        setPrice('');
                                                    } else {
                                                        if (numericValue > priceRange.max) {
                                                            numericValue = priceRange.max;
                                                        } else if (numericValue < priceRange.min) {
                                                            numericValue = priceRange.min;
                                                        }
                                                        setPrice(numericValue);
                                                    }
                                                }}
                                                className="portfolio-form-input "
                                            />
                                        </div>

                                        <div className="range-input-container flex space-between align-center">
                                            <div className='form-maxmin-price -min'>${priceRange.min}</div>
                                            <div className='form-range-width'>
                                                <input
                                                    type="range"
                                                    value={price}
                                                    min={priceRange.min}
                                                    max={priceRange.max}
                                                    step="0.01"
                                                    onChange={(ev) => onChangePrice(+ev.target.value)}
                                                    className="portfolio-form-input-range" />
                                            </div>
                                            <div className='form-maxmin-price -max'>${priceRange.max}</div>
                                        </div>

                                    </div>
                                ) : <div
                                    className='flex align-center'
                                    style={{ height: '80px', marginBottom: '25px' }}> $0</div>
                            }
                        </div> : <div
                            style={{ width: '25%', height: '80px', marginBottom: '25px' }}
                            className="portfolio-inputs-row-cell flex align-center"
                        >
                            {symbol && companiesPrices[symbol] ? `$${(+companiesPrices[symbol].lastPrice).toFixed(2)}` : ''}
                        </div>
                }

            </div>
            <div
                className={`manage-portfolio-x manage-portfolio-checkicon btn btn-border ${isButtonDisabled ? 'btn-disabled' : ''}`}
                style={{ width: 'fit-content', margin: '0 auto 10px' }}
                onClick={isButtonDisabled ? null : saveRow}
            > Confirm
            </div>

        </div >
    )
}

export default PortfolioInputsRow;