import React from "react";
import FollowingList from "./following-list";

const ProfileFollowing = ({ isMyProfile, username, userFollowing }) => {
    const arraysToCheck = [
        userFollowing?.companies,
        userFollowing?.currencies,
        userFollowing?.indices,
        userFollowing?.crypto,
        userFollowing?.commodities
    ];

    const allArraysEmpty = arraysToCheck.every(array => !array || array.length === 0);

    const sortedCompanies = [...(userFollowing?.companies ?? [])].sort();
    const sortedCurrencies = [...(userFollowing?.currencies ?? [])].sort();
    const sortedIndices = [...(userFollowing?.indices ?? [])].sort();
    const sortedCrypto = [...(userFollowing?.crypto ?? [])].sort();
    const sortedCommodities = [...(userFollowing?.commodities ?? [])].sort();

    return (
        <>
            {allArraysEmpty ? (
                <div>
                    No data available.
                </div>
            ) : (
                <div className="user-pool-profile-container card-container">
                    <FollowingList following={sortedCompanies} type={"company"} title={"Companies"} isMyProfile={isMyProfile} username={username} />
                    <FollowingList following={sortedCurrencies} type={"currency"} title={"Currencies"} isMyProfile={isMyProfile} username={username} />
                    <FollowingList following={sortedIndices} type={"index"} title={"Indices"} isMyProfile={isMyProfile} username={username} />
                    <FollowingList following={sortedCrypto} type={"crypto"} title={"Crypto Currencies"} isMyProfile={isMyProfile} username={username} />
                    <FollowingList following={sortedCommodities} type={"commodity"} title={"Commodities"} isMyProfile={isMyProfile} username={username} />
                </div>
            )}
        </>
    );
}

export default ProfileFollowing;
