import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CardContainer from "../../../cmps/card-container/card-container";
import LineChartNoLines from "../../../shared/charts/line-chart/line-chart-no-lines";
import LabelColorContainer from "../../../shared/label-color-container/label-color-container";
import { useMediaQuery } from 'react-responsive';
import { hexToRGBA } from "../../../shared/global-color-functions";
import { Helmet } from "react-helmet";
import { DescriptiveGuide } from "../../../cmps/page-guide";

const PortfolioDescriptive = ({ selectedFilter, descData }) => {

    const [colorLabels, setColorLabels] = useState(null);
    const [kernelDist, setKernelDist] = useState(null);
    const [correlations, setCorrelations] = useState(null);
    const [topTable, setTopTable] = useState(null);
    const [bottomTable, setBottomTable] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null)

    const topTableSize1 = useMediaQuery({ maxWidth: 500 });

    const isMobile = useMediaQuery({ maxWidth: 920 });
    const isMobileScreen = useMediaQuery({ maxWidth: 600 });

    useEffect(() => {
        if (!descData) return;
        let selectedPeriod = descData.kernelDensity[selectedFilter.period] ? selectedFilter.period : 'oneMonth';
        if (descData.kernelDensity && descData.kernelDensity[selectedPeriod]) {
            const filteredData = descData.kernelDensity[selectedPeriod]
                .filter(item => (
                    selectedFilter.sectors.includes(item.sector) &&
                    selectedFilter.companies.includes(item.symbol)
                ));

            const labels = filteredData.map(item => ({ color: item.color, symbol: item.symbol, label: item.symbol }));
            const dist = filteredData.map(item => ({ color: item.color, symbol: item.symbol, kdeDist: item.kdeDist }));

            setColorLabels(labels);
            setKernelDist(dist);
        }
        setCorrelations(descData.correlations[selectedPeriod]);


        const filteredTopTable = descData.topTable[selectedPeriod].filter(item => (
            selectedFilter.sectors.includes(item.sector) &&
            selectedFilter.companies.includes(item.symbol)
        ));

        const filteredBottomTable = descData.bottomTable[selectedPeriod].filter(item => (
            selectedFilter.sectors.includes(item.sector) &&
            selectedFilter.companies.includes(item.symbol)
        ));

        setTopTable(filteredTopTable);

        const uniqueItems = [];
        const symbolsForDate = new Set();

        filteredBottomTable.forEach(item => {
            const symbolDateKey = `${item.tableData[2]}_${item.symbol}`;

            if (!symbolsForDate.has(symbolDateKey)) {
                uniqueItems.push(item);
                symbolsForDate.add(symbolDateKey);
            }
        });

        setBottomTable(uniqueItems);

    }, [descData, selectedFilter]);

    const getKernelShadowColor = (hex) => {
        hex = hex.replace(/^#/, '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `rgba(${r}, ${g}, ${b}, 0.4)`;
    };

    const getLineChartData = () => {
        if (!kernelDist) return;
        const datasets = [];

        kernelDist.forEach((item) => {
            let borderColor, backgroundColor;

            if (!selectedItem) {
                borderColor = backgroundColor = item.color;
                backgroundColor = getKernelShadowColor(item.color);
            } else {
                if (item.symbol === selectedItem) {
                    // If selectedItem is not null and matches item.symbol, use the item.color
                    borderColor = backgroundColor = item.color;
                } else {
                    // If selectedItem is not null and doesn't match item.symbol, convert hex to rgba with opacity 0.4
                    const hexColor = item.color;
                    const rgbaColor = hexToRGBA(hexColor, 0.4);

                    borderColor = rgbaColor;
                    backgroundColor = getKernelShadowColor(hexColor); // Use your existing function or logic here
                }
            }

            datasets.push({
                label: item.symbol,
                data: item.kdeDist,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 3,
                fill: true,
            });
        });

        return {
            labels: kernelDist.map((item) => item.symbol),
            datasets: datasets,
        };
    };

    const descriptiveKernelTitle = (
        <>
            <div
            // className='descriptive-correlations-table-title position-relative'
            // onMouseEnter={() => setShowInfoPopupKDE(true)}
            // onMouseLeave={() => setShowInfoPopupKDE(false)}
            >
                <h4 className='portfolio-chart-title'>Kernel Density Estimation Distributions</h4>
                {/* {showInfoPopupKDE && <InfoPopup type='kde-distribution' />} */}
            </div>
        </>
    );

    const descriptiveKernelContent = (
        <>
            <LineChartNoLines chartData={getLineChartData()} type='percent' />
        </>
    );

    const getCorrelationColor = (correlation) => {
        let correlationColor;
        if (correlation === undefined || correlation === null) {
            return correlationColor;
        }
        if (+correlation >= 0) {
            correlationColor = '#5c9ead';
        } else if (+correlation < 0) {
            correlationColor = '#e39774';
        }
        if (+correlation !== 1.0) {
            const correlationString = correlation.toString();
            const correlationDecimal = correlationString.split('.')[1];
            correlationColor += correlationDecimal;
        }
        return correlationColor;
    };

    const correlationTitle = (
        <>
            <div
            // className='descriptive-correlations-table-title position-relative'
            // onMouseEnter={() => setShowInfoPopupCorrelation(true)}
            // onMouseLeave={() => setShowInfoPopupCorrelation(false)}
            >
                <h4 className='portfolio-chart-title'>Correlations</h4>
                {/* {showInfoPopupCorrelation && <InfoPopup type='correlation' />} */}
            </div>
        </>
    );

    const correlationsContent = (
        <>
            {
                correlations &&
                <div className="findatasheets-table desc-correlations-media">
                    <div className="flex">
                        {
                            correlations.position.map((pos, idx) => (
                                <React.Fragment key={pos.symbol}>
                                    {
                                        idx === 0 && <div className="descriptive-cell"></div>
                                    }
                                    <div className="port-desc-corr-header header cell descriptive-cell descriptive-header">
                                        {
                                            pos.symbol ? (
                                                <Link to={`/company/${pos.symbol}`}>{pos.symbol}</Link>
                                            ) : (
                                                <Link to={`/sector/${pos.sector}`}>{pos.sector}</Link>
                                            )
                                        }
                                    </div>
                                </React.Fragment>
                            ))
                        }
                    </div>
                    {
                        correlations.data.map((rowData, rowIndex) => (
                            <div key={rowIndex} className="port-desc-corr-container flex">
                                <div className="port-desc-corr-header header cell descriptive-cell descriptive-header flex">
                                    {
                                        correlations.position[rowIndex].symbol ? (
                                            <Link to={`/company/${correlations.position[rowIndex].symbol}`}>
                                                {correlations.position[rowIndex].symbol}
                                            </Link>
                                        ) : (
                                            <Link to={`/sector/${correlations.position[rowIndex].sector}`}>
                                                {correlations.position[rowIndex].sector}
                                            </Link>
                                        )
                                    }
                                </div>
                                {
                                    correlations.position.map((pos) => (
                                        <div key={pos.symbol} className="cell descriptive-cell" style={{ backgroundColor: getCorrelationColor(rowData[pos.symbol]) }}>
                                            {rowData[pos.symbol]}
                                        </div>
                                    ))
                                }
                            </div>
                        ))}
                </div>
            }
        </>
    );

    // const topTableEl = (
    //     <div className="findatasheets-table-container portfolio-top-table-media crypto-table card-container portfolio-desc-item desc-table-media" style={{ marginBottom: '0px' }}>
    //         <h4>Statistical Summary</h4>
    //         <div className="findatasheets-table-header flex space-between">
    //             <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Symbol</div>
    //             <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Avg. Daily Return</div>
    //             <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Avg. Daily Median</div>
    //             <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Daily Return Std. Dev.</div>
    //             <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Daily Return Range</div>
    //         </div>
    //         {
    //             topTable &&
    //             topTable.map((tableItem, idx) => (
    //                 <div className={`findatasheets-table-row flex space-between ${idx % 2 === 0 ? '' : 'odd-row'}`} key={idx}>
    //                     {tableItem.tableData.flatMap((cellValue, cellIdx) => (
    //                         <div className="findatasheets-table-cell portfolio-desc-title-item flex center-center" key={cellIdx}>
    //                             {cellValue}
    //                         </div>
    //                     ))}
    //                 </div>
    //             ))
    //         }
    //     </div>
    // );


    const topTableEl = (
        <div className="findatasheets-table-container portfolio-top-table-media crypto-table card-container portfolio-desc-item desc-table-media" style={{ marginBottom: '0px' }}>
            <h4>Statistical Summary</h4>
            <div className="findatasheets-table-header flex space-between">
                <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Symbol</div>
                <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Avg. Daily Return</div>
                <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Avg. Daily Median</div>
                <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Daily Return Std. Dev.</div>
                {!topTableSize1 && (
                    <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Daily Return Range</div>
                )}
            </div>
            {topTable &&
                topTable.map((tableItem, idx) => (
                    <div className={`findatasheets-table-row flex space-between ${idx % 2 === 0 ? '' : 'odd-row'}`} key={idx}>
                        {tableItem.tableData.slice(0, topTableSize1 ? -1 : undefined).flatMap((cellValue, cellIdx) => (
                            <div className="findatasheets-table-cell portfolio-desc-title-item flex center-center" key={cellIdx}>
                                {cellValue}
                            </div>
                        ))}
                    </div>
                ))}
        </div>
    );

    const bottomTableEl = (
        <div className="findatasheets-table-container crypto-table card-container portfolio-desc-item desc-table-media">
            <h4>Unexpected Returns</h4>
            <div className="findatasheets-table-header flex space-between">
                <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Symbol</div>
                <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Daily Return</div>
                <div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Date</div>
                {!topTableSize1 && (<div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Z-Score Method</div>)}
                {!topTableSize1 && (<div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">IQR Method</div>)}
                {!topTableSize1 && (<div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Local Outlier Factor</div>)}
                {!topTableSize1 && (<div className="findatasheets-table-cell findatasheets-table-title portfolio-desc-title-item flex align-end justify-center">Kernel Density Estimation</div>)}
            </div>
            {
                bottomTable &&
                bottomTable.map((tableItem, idx) => (
                    <div className={`findatasheets-table-row flex space-between ${idx % 2 === 0 ? '' : 'odd-row'}`} key={idx}>
                        <div className="findatasheets-table-cell portfolio-desc-title-item flex center-center">
                            <Link to={`/co.tableDatampany/${tableItem.tableData[0]}`}>
                                {tableItem.tableData[0]}
                            </Link>
                        </div>
                        <div className="findatasheets-table-cell portfolio-desc-title-item flex center-center">{tableItem.tableData[1]}</div>
                        <div className="findatasheets-table-cell portfolio-desc-title-item flex center-center">{tableItem.tableData[2]}</div>
                        {!topTableSize1 && (<div className="findatasheets-table-cell portfolio-desc-title-item flex center-center">{tableItem.tableData[3].includes("zScore") ? '✔' : '✖'}</div>)}
                        {!topTableSize1 && (<div className="findatasheets-table-cell portfolio-desc-title-item flex center-center">{tableItem.tableData[3].includes("iqr") ? '✔' : '✖'}</div>)}
                        {!topTableSize1 && (<div className="findatasheets-table-cell portfolio-desc-title-item flex center-center">{tableItem.tableData[3].includes("localOutlierFactor") ? '✔' : '✖'}</div>)}
                        {!topTableSize1 && (<div className="findatasheets-table-cell portfolio-desc-title-item flex center-center">{tableItem.tableData[3].includes("kernelDensityEstimation") ? '✔' : '✖'}</div>)}
                    </div>
                ))}
        </div>
    );

    const onChangeSelectedItem = (item) => {
        setSelectedItem(item);
    };

    const BigScreen = () => {
        return <>
            {/* <div className="container-height main-content-container"> */}
            <div className={`portfolio-container-top mb20 flex ${isMobile ? 'column' : ''}`} >
                {
                    colorLabels &&
                    <LabelColorContainer
                        labels={colorLabels}
                        type={'company'}
                        display={'column'}
                        selectedItem={selectedItem}
                        onChangeSelectedItem={onChangeSelectedItem}
                    />
                }
                {
                    kernelDist &&
                    <CardContainer
                        title={descriptiveKernelTitle}
                        innerContent={descriptiveKernelContent}
                        className={'kde-chart flex column'}
                        infoModalType={'kernelDensity'}
                    />
                }
            </div>
            <div className={`portfolio-container-bottom mb20 port-desc-container-bottom flex ${isMobile ? 'column' : ''}`}>
                {topTableEl}
                {
                    correlations &&
                    <CardContainer
                        title={correlationTitle}
                        innerContent={correlationsContent}
                        className={'descriptive-correlations-chart flex column'}
                        infoModalType={'correlation'}
                    />
                }
            </div>
            {/* </div> */}
            {bottomTableEl}
            <div className="card-container main-content-container" style={{ width: '100%', marginBottom: '20px' }}>
                <DescriptiveGuide />
            </div>
        </>
    };

    const SmallScreen = () => {
        return <>
            <div className="mb20">
                {
                    colorLabels &&
                    <LabelColorContainer
                        labels={colorLabels}
                        type={'company'}
                        display={'column'}
                        selectedItem={selectedItem}
                        onChangeSelectedItem={onChangeSelectedItem}
                    />
                }
                {
                    kernelDist &&
                    <CardContainer
                        title={descriptiveKernelTitle}
                        innerContent={descriptiveKernelContent}
                        className={'kde-chart flex column mb20'}
                    />
                }
                {
                    topTableEl
                }
                {
                    correlations &&
                    <CardContainer
                        title={correlationTitle}
                        innerContent={correlationsContent}
                        className={''}
                    />
                }
                {
                    <div style={{ margin: isMobileScreen ? '10px 0' : '20px 0' }}>
                        {bottomTableEl}
                    </div>
                }
            </div>
            <div className="card-container main-content-container" style={{ width: '100%', marginBottom: '20px' }}>
                <DescriptiveGuide />
            </div>
        </>
    };


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow - Portfolio Descriptive</title>
                <meta name="description" content="Analyze and manage your investment portfolio" />
                <link rel="canonical" href="https://www.portfollow.com/portfolio/descriptive" />
            </Helmet>
            {isMobile ? <SmallScreen /> : <BigScreen />}
        </>
    );
}

export default PortfolioDescriptive;