import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { getSelectedIndex } from "../../../shared/actions/single-item-actions";
import LoadingSpinner from "../../../shared/loading-spinner/loading-spinner";
import { setGlobalIsLoading } from "../../../store/loading/loading-actions";
import SingleItemTechnical from "../../../cmps/single-item-technical/single-item-technical";
import './single-index.scss';
import SingleIndexHeader from "./single-index-header/single-index-header";
import { getTodayMonthDayDate } from "../../../shared/global-color-functions";

const SingleIndex = ({ loggedInUser, toggleFollowing, userFollowing, singleIndices, setSingleIndices }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { symbol } = useParams();
    const loadingSelector = useSelector((state) => state.loading.isLoading);
    const [indexHeader, setIndexHeader] = useState(null);
    const [indexTable, setIndexTable] = useState(null);
    const [indexPriceOverTime, setIndexPriceOverTime] = useState(null);
    const [indexBarCharts, setIndexBarCharts] = useState(null);

    const lastPrices = useSelector((state) => state.lastPrices);

    const location = useLocation();
    const path = location.pathname;
    const navType = path.split('/')[1];
    const data = lastPrices[`${symbol}=${navType}`];

    const setSingleIndexData = (header, table, priceOverTime, barCharts) => {
        setIndexHeader(header);
        setIndexTable(table);
        setIndexPriceOverTime(priceOverTime);
        setIndexBarCharts(barCharts);
    };

    const getIndexHeader = (symbol) => {
        // const data = lastPrices[symbol];

        if (!data) return {};

        return {
            date: getTodayMonthDayDate(),
            index: data.name,
            symbol: symbol,
            currency: "",
            country: data.info.country,
            continent: data.info.continent,
            lastPriceChange: data.lastPriceChange,
            lastPricePercentChange: data.lastPricePercentChange,
        };
    };

    useEffect(() => {
        // const data = lastPrices[symbol];
        if (!data) return;
        setIndexHeader(getIndexHeader(symbol));
    }, [symbol, lastPrices]);

    useEffect(() => {
        if (indexHeader && indexTable && indexPriceOverTime && indexBarCharts) {
            dispatch(setGlobalIsLoading(false))
        }
    }, [indexHeader, indexTable, indexPriceOverTime, indexBarCharts]);

    useEffect(() => { }, [symbol])

    useEffect(() => {
        if (!symbol) handleNavigateToError();
        async function fetchData() {
            dispatch(setGlobalIsLoading(true));
            try {
                if (singleIndices[symbol]) {
                    setSingleIndexData(singleIndices[symbol].indexHeader, singleIndices[symbol].indexTable, singleIndices[symbol].indexPriceOverTime, singleIndices[symbol].indexBarCharts);
                } else {
                    const indexData = await getSelectedIndex(symbol);
                    if (indexData) {
                        setSingleIndexData(indexData.indexHeader, indexData.indexTable, indexData.indexPriceOverTime, indexData.indexBarCharts);
                        setSingleIndices(prevSingleIndices => ({
                            ...prevSingleIndices,
                            [symbol]: indexData
                        }));
                    } else {
                        handleNavigateToError();
                    }
                }
            } catch (error) {
                handleNavigateToError();
            }
        }
        fetchData();
    }, [symbol]);

    const handleNavigateToError = () => {
        history.push('/error');
    };

    return (
        <>
            {
                indexHeader &&
                <SingleIndexHeader
                    loggedInUser={loggedInUser}
                    symbol={symbol}
                    toggleFollowing={toggleFollowing}
                    userFollowing={userFollowing}
                    header={indexHeader}
                />
            }
            {
                loadingSelector ? (
                    <LoadingSpinner />
                ) : (
                    <SingleItemTechnical
                        technicalData={{ table: indexTable, priceOverTime: indexPriceOverTime, barCharts: indexBarCharts }}
                        symbol={indexHeader.symbol}
                        type={'index'}
                    />
                )
            }
        </>
    );
}

export default SingleIndex;