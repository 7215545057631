import './moving-header.scss';
import { Line } from 'react-chartjs-2';
import { enUS } from 'date-fns/locale';

const MovingHeaderItem = ({ item, type }) => {
    
    const formatDate = (dateString) => {
        const options = { month: 'short', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    };

    const getMacroInflationChartData = (data) => {
        const chartData = {
            labels: [],
            datasets: [],
        };
        const chartDataset = {
            label: '',
            data: [],
            borderColor: [],
            backgroundColor: [],
            borderWidth: 2,
            pointRadius: 0,
            fill: {
                target: 'origin',
                below: '#e39774',
                above: '#5c9ead'
            },
            borderWidth: 0,
            pointRadius: 0
        };
        data.forEach((item) => {
            chartDataset.data.push(item);
            chartDataset.backgroundColor.push(item.x >= 0 ? "#5c9ead" : "#e39774");
            chartDataset.borderColor.push(item.x >= 0 ? "#5c9ead" : "#e39774");
        });
        chartData.datasets.push(chartDataset);
        return chartData;
    };

    const getHomePageChartData = (data) => {
        const chartData = {
            labels: [],
            datasets: [],
        };
        const chartDataset = {
            label: '',
            data: [],
            borderColor: [],
            backgroundColor: [],
            borderWidth: 2,
            pointRadius: 0,
            fill: {
                target: 'origin',
                below: '#e39774',
                above: '#5c9ead'
            },
            borderWidth: 0,
            pointRadius: 0
        };
        data.forEach((item) => {
            chartDataset.data.push({ x: item.x, y: item.y });
            chartData.labels.push('');
            chartDataset.backgroundColor.push(item.y >= 0 ? "#5c9ead" : "#e39774");
            chartDataset.borderColor.push(item.y >= 0 ? "#5c9ead" : "#e39774");
        });
        chartData.datasets.push(chartDataset);
        return chartData;
    };

    const areaChartOptions = {
        plugins: {
            legend: {
                display: true,
                labels: {
                    boxWidth: 0,
                },
            },
            filler: {
                propagate: true,
            },
        },
        scales: {
            x: {
                display: false,
                grid: {
                    display: false,
                },
            },
            y: {
                display: false,
                min: item.minValue,
                max: item.maxValue,
                grid: {
                    display: false,
                },
                ticks: {
                    callback: function (value, index, values) {
                        return value === 0 ? "" : value;
                    },
                },
                gridLines: {
                    borderDash: [2],
                    zeroLineWidth: 2,
                    zeroLineColor: 'red',
                    drawBorder: false,
                    drawOnChartArea: false,
                    drawTicks: false,
                },
            },
        },
        responsive: true, // Make the chart responsive
        maintainAspectRatio: false,
        aspectRatio: 2,
    };

    const homepagItem = (
        <div className='moving-header-slide moving-header-slide-homepage'>
            <div className='moving-header-slide-item moving-header-slide-item-homepage'>
                <div className='moving-header-item-info moving-header-item-title'>{item.title}</div>
                <div className="moving-header-item flex">
                    <div className="moving-header-item-info-container mr20 flex column justify-center">
                        <div className='moving-header-item-info moving-header-item-price'>{item.price}</div>
                        <div className='moving-header-item-info moving-header-item-info-pricechange flex' style={item.color === 'blue' ? { color: '#5c9ead' } : { color: '#e39774' }}>
                            {item.changePrice} <span className='moving-header-item-info-changepercent'>({item.changePercent})</span>
                        </div>
                    </div>
                    <div className='moving-header-item-img' style={{ border: '1px solid red' }}>
                        <Line data={getHomePageChartData(item.data)} options={areaChartOptions} />
                    </div>
                </div>
            </div>
        </div >
    );

    // const macroInflationItem = (
    //     <div className='moving-header-slide moving-header-slide-macro-inflation '>
    //         <div className='moving-header-slide-item card-container'>
    //             <div className='moving-header-item-info moving-header-item-title moving-header-item-title-macro-inflation flex justify-center'>
    //                 {item.title}
    //             </div>
    //             <div className='moving-header-item-info flex justify-center' style={{ marginTop: '10px' }}>{formatDate(item.desc)}</div>
    //             <div className="moving-header-item flex column">
    //                 <div className="moving-header-item-info-container mr20 flex column justify-center">
    //                     <div className='moving-header-item-info moving-header-item-info-pricechange' style={item.value.includes('-') ? { color: '#e39774' } : { color: '#5c9ead' }}>
    //                         <h2 className='moving-header-item-data-macro-inflation flex justify-center' style={{ width: '100%', marginTop: '10px' }}> {item.value}</h2>
    //                     </div>
    //                 </div>
    //                 <div className="flex justify-center">
    //                     <div className='moving-header-item-img flex justify-center' style={{ width: '80%' , height: '100px'}}>
    //                         <Line data={getMacroInflationChartData(item.data)} options={areaChartOptions} style={{ width: '100%' }} />
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div >
    // );

    const macroInflationItem = (
            <div className='moving-header-slide homepage-moving-header'>
                <div className='moving-header-slide-item'>
                    <div className='moving-header-item-info moving-header-item-title'>{item.title}</div>
                    <div className="moving-header-item flex">
                        <div className="moving-header-item-info-container mr20 flex column justify-center">
                            <div className='moving-header-item-info moving-header-item-price flex' style={item.value.includes('-') ? { color: '#e39774' } : { color: '#5c9ead' }}>
                            {item.value} 
                            </div>
                            <div className='moving-header-item-info moving-header-item-info-pricechange'>{formatDate(item.desc)}</div>
                        </div>
                        <div className='moving-header-item-img homepage-moving-header-linechart'>
                        <Line data={getMacroInflationChartData(item.data)} options={areaChartOptions} />
                        </div>
                    </div>
                </div>
            </div >
    );

    return (
        <div>
            {type === 'homepage' && homepagItem}
            {type === 'macro-inflation' && macroInflationItem}
        </div>
    );
}

export default MovingHeaderItem;