import BasicSelectOption from "../../../shared/basic-select-option";

const PortfolioDescriptive = () => {

    const companyOptions = [
        {
            value: 'AAPL',
            label: '(AAPL) Apple Inc.',
        },
        {
            value: 'MSFT',
            label: '(MSFT) Microsoft Corp',
        },
        {
            value: 'NVDA',
            label: '(NVDA) Nvidia Corp',
        },
        {
            value: 'AMZN',
            label: '(AMZN) Amazon.com Inc',
        }
    ];

    const sectorOptions = [
        {
            value: 'Technology',
            label: 'Technology',
        },
        {
            value: 'Financials',
            label: 'Financials',
        },
        {
            value: 'Health Care',
            label: 'Health Care',
        },
        {
            value: 'Real Estate',
            label: 'Real Estate',
        }
    ];

    const periodOptions = [
        {
            value: '1 Week',
            label: '1 Week',
        },
        {
            value: '1 Month',
            label: '1 Month',
        },
        {
            value: '6 Months',
            label: '6 Months',
        },
        {
            value: '1 Year',
            label: '1 Year',
        }
    ]


    return (
        <>
            <h2 className={'guide-title'}>Portfolio - Descriptive</h2>
            <ul className={"guide-ul"}>
                <li>Understand the descriptive statistics of stock market companies.</li>
                <li>Visualize distributions, statistical summaries, correlations, and unexpected returns using various charts and tables.</li>
            </ul>
            <h4 className={"guide-subtitle"}>Filters:</h4>
            <ul className={"guide-ul"}>
                <li>Time Frames: Select specific time periods for analysis.
                </li>
                <li>Sector: Filter stock market companies by industry sectors.
                </li>
                <li>Companies: Choose from a variety of stock market companies to analyze.
                </li>
            </ul>
            <div className="flex wrap justify-center" style={{ marginBottom: '20px' }}>
                <BasicSelectOption options={periodOptions} title="Period" />
                <BasicSelectOption options={sectorOptions} title="Sectors" />
                <BasicSelectOption options={companyOptions} title="Comapnies" />
            </div>
            <h4 className={"guide-subtitle"}>Visualizations:</h4>
            <ul className={"guide-ul"}>
                <li>Kernel Density Estimation Distributions Line Chart: Shows the distribution of daily stock market returns for each company.
                </li>
                <li>Statistical Summary Table: Provides statistical metrics such as average daily return, median, standard deviation, and range for each stock market symbol.
                </li>
                <li>Correlation Table: Displays the correlation between all stock market assets in the portfolio.
                </li>
                <li>Unexpected Returns Table: Highlights outlier returns using statistical tests such as Z-Score Method, IQR Method, Local Outlier Factor, and Kernel Density Estimation.
                </li>
            </ul>
            <div className="flex flex-end">
                <a href="/portfolio/descriptive" className="guide-btn btn btn-border" title="Descriptive">Go to page</a>
            </div>
        </>
    );
}

export default PortfolioDescriptive;