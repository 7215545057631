import React, { useEffect, useState } from "react";
import Select from 'react-select';
import PeriodDropdownSelect from "../../shared/dropdown-select/period-dropdown-select";
import MacroPriceRelativeFilter from "../macro/macro-price-relative-filter";
import { DropdownIndicator, customStyles } from "../../shared/dropdown-styles";
import { dropdownIcon } from "../../shared/icons/icons";

const CryptoFilters = ({
    selectedDataFilter,
    activeOption,
    categories,
    currencies,
    onFilterChange,
    activePriceRelative,
    changePriceRelative,
    currenciesOptions,
    categoriesOptions,
    setCurrenciesOptions,
    setFavoritesCurrenciesOptions,
    groups
}) => {

    const periodOptions = [
        { value: 'oneWeek', label: '1 Week' },
        { value: 'oneMonth', label: '1 Month' },
        { value: 'sixMonth', label: '6 Months' },
        { value: 'currentYear', label: 'YTD' },
        { value: 'oneYear', label: '1 Year' },
        { value: 'fiveYear', label: '5 Years' },
        { value: 'max', label: 'Max' },
    ];

    const [selectedPeriod, setSelectedPeriod] = useState(periodOptions[0]);
    const [selectedCurrencies, setSelectedCurrencies] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [showPeriodOptions, setShowPeriodOptions] = useState(false);
    const [showCurrenciesOptions, setShowCurrenciesOptions] = useState(false);
    const [showCategoriesOptions, setShowCategoriesOptions] = useState(false);

    // const user = useSelector((state) => state.user.user);
    // const { favorites } = user;

    useEffect(() => {
        setSelectedCurrencies(currencies);
        setSelectedCategories(categories);
    }, [activeOption])

    useEffect(() => {
        if (!selectedCategories.length || !currenciesOptions.length) return;
        const filteredGroups = groups.filter((group) =>
            group.group.some((category) =>
                selectedCategories.some((selectedCategory) => selectedCategory.label === category)
            )
        );
        const filteredCurrenciesOptions = filteredGroups.map((group) => ({
            label: group.name,
            value: group.name,
        }));

        setSelectedCurrencies(filteredCurrenciesOptions);
        setFavoritesCurrenciesOptions(filteredCurrenciesOptions);
        if (filteredGroups.length === groups.length && filteredCurrenciesOptions.length === filteredGroups.length) {
            setSelectedCurrencies([
                { label: 'Bitcoin', value: 'Bitcoin' },
                { label: 'Ethereum', value: 'Ethereum' },
                { label: 'Ripple', value: 'Ripple' },
                { label: 'Binance Coin', value: 'Binance Coin' },
                { label: 'Chainlink', value: 'Chainlink' }
            ]);
        } else {
            setCurrenciesOptions(filteredCurrenciesOptions);
        }
    }, [selectedCategories]);

    useEffect(() => {
        const categories = selectedCategories?.map((category) => category.value);
        const currencies = selectedCurrencies?.map((currencies) => currencies.value);
        onFilterChange(selectedPeriod.value, currencies, categories);
    }, [selectedPeriod, selectedCategories, selectedCurrencies, currencies, activeOption]);

    const onPeriodChange = (selectedOption) => {
        setSelectedPeriod(selectedOption);
    };

    const onCategoryChange = (selectedOptions) => {
        if (!selectedOptions.length) return;
        setSelectedCategories(selectedOptions);
    };

    const onCurrenciesChange = (selectedOptions) => {
        if (!selectedOptions.length) return;
        setSelectedCurrencies(selectedOptions);
    };

    const onHandlePeriodMouseEnter = () => {
        setShowPeriodOptions(true);
    };

    const onHandlePeriodMouseLeave = () => {
        setShowPeriodOptions(false);
    };

    const onHandleCurrenciesMouseEnter = () => {
        setShowCurrenciesOptions(true);
    };

    const onHandleCurrenciesMouseLeave = () => {
        setShowCurrenciesOptions(false);
    };

    const onHandleCategoryMouseEnter = () => {
        setShowCategoriesOptions(true);
    };

    const onHandleCategoryMouseLeave = () => {
        setShowCategoriesOptions(false);
    };

    return (
        <div className="dropdown-select-filter-container flex center-center">
            {
                <PeriodDropdownSelect
                    currFilter={selectedPeriod.value}
                    onChangeFilter={onPeriodChange}
                    optionsToDisplay={periodOptions}
                />
            }
            {
                <div
                    className="dropdown-select-filter-item"
                    onMouseEnter={onHandleCategoryMouseEnter}
                    onMouseLeave={onHandleCategoryMouseLeave}
                >
                    <Select
                        styles={customStyles}
                        components={{
                            DropdownIndicator,
                        }}
                        isMulti
                        options={categoriesOptions}
                        value={selectedCategories}
                        onChange={onCategoryChange}
                        menuIsOpen={showCategoriesOptions}
                        hideSelectedOptions={false}
                    />
                    <div className={`dropdown-label flex align-center ${selectedCategories.length !== categoriesOptions.length ? 'incomplete-dropdown' : ''}`}>Categories <span className='dropdown-indicator flex center-center'>{dropdownIcon}</span></div>
                </div>
            }
            {
                <div
                    className="dropdown-select-filter-item"
                    onMouseEnter={onHandleCurrenciesMouseEnter}
                    onMouseLeave={onHandleCurrenciesMouseLeave}
                >
                    <Select
                        styles={customStyles}
                        components={{
                            DropdownIndicator,
                        }}
                        isMulti
                        options={currenciesOptions}
                        value={selectedCurrencies}
                        onChange={onCurrenciesChange}
                        menuIsOpen={showCurrenciesOptions}
                        hideSelectedOptions={false}
                    />
                    <div className={`dropdown-label flex align-center ${selectedCurrencies.length !== currenciesOptions.length ? 'incomplete-dropdown' : ''}`}>Currencies <span className='dropdown-indicator flex center-center'>{dropdownIcon}</span></div>
                </div>
            }
            {
                <MacroPriceRelativeFilter
                    activePriceRelative={activePriceRelative}
                    changePriceRelative={changePriceRelative}
                />
            }
        </div>
    );
}

export default CryptoFilters;