import React, { useState, useEffect } from 'react';
import './return-sharpe.scss';
import CardContainer from '../card-container/card-container';
import BarChart from '../../shared/charts/bar-chart/bar-chart';

const ReturnSharpe = ({ returnValue, sharpeValue, onChangeSelectedItem }) => {

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [modifiedReturnVal, setModifiedReturnVal] = useState(null);
    const [modifiedSharpeVal, setModifiedSharpeVal] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setScreenSize(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const modifyData = (data, selectedIndex) => {
            if (selectedIndex < 4) {
                return data.slice(0, 5);
            } else if (selectedIndex > 4) {
                return data.slice(2, 7);
            } else {
                return data.slice(1, 6);
            }
        };
    
        const findSelectedIndex = (colors) => {
            return colors.findIndex(color => color === "#4071AB");
        };
    
        const modifyArray = (array, selectedIndex) => {
            if (!array) return;
            return array.length > 5 ? modifyData(array, selectedIndex) : array;
        };
    
        const selectedIndexReturnValue = findSelectedIndex(returnValue.chartData.datasets[0].backgroundColor);
        const selectedIndexSharpeValue = findSelectedIndex(sharpeValue.chartData.datasets[0].backgroundColor);
    
        const modifiedReturnValue = {
            ...returnValue,
            chartData: {
                ...returnValue.chartData,
                labels: modifyData(returnValue.chartData.labels, selectedIndexReturnValue),
                hover: modifyData(returnValue.chartData.hover, selectedIndexReturnValue),
                datasets: returnValue.chartData.datasets.map((dataset) => ({
                    ...dataset,
                    hover: modifyArray(dataset.hover, selectedIndexReturnValue),
                    data: modifyArray(dataset.data, selectedIndexReturnValue),
                    backgroundColor: modifyArray(dataset.backgroundColor, selectedIndexReturnValue),
                    borderColor: modifyArray(dataset.borderColor, selectedIndexReturnValue),
                })),
            },
        };
    
        const modifiedSharpeValue = {
            ...sharpeValue,
            chartData: {
                ...sharpeValue.chartData,
                labels: modifyData(sharpeValue.chartData.labels, selectedIndexSharpeValue),
                hover: modifyData(sharpeValue.chartData.hover, selectedIndexSharpeValue),
                datasets: sharpeValue.chartData.datasets.map((dataset) => ({
                    ...dataset,
                    hover: modifyArray(dataset.hover, selectedIndexSharpeValue),
                    data: modifyArray(dataset.data, selectedIndexSharpeValue),
                    backgroundColor: modifyArray(dataset.backgroundColor, selectedIndexSharpeValue),
                    borderColor: modifyArray(dataset.borderColor, selectedIndexSharpeValue),
                })),
            },
        };
    
        setModifiedReturnVal(modifiedReturnValue);
        setModifiedSharpeVal(modifiedSharpeValue);
    }, [returnValue, sharpeValue]);

    const returnTitle = (
        <>
            <h4
                className='portfolio-chart-title'
            >Return
            </h4>
        </>
    );

    const returnChartDataToUse = screenSize < 1165 && modifiedReturnVal ? modifiedReturnVal : returnValue;
    const sharpeChartDataToUse = screenSize < 1165 && modifiedSharpeVal ? modifiedSharpeVal : sharpeValue;


    const returnContent = (
        <>
            <BarChart
                type={returnValue.type}
                // chartData={returnValue.chartData}
                chartData={returnChartDataToUse.chartData}
                onChangeSelectedItem={onChangeSelectedItem}
            />
        </>
    );

    const sharpeTitle = (
        <>
            <h4 className='portfolio-chart-title'>Sharpe</h4>
        </>
    );

    const sharpeContent = (
        <>
            <BarChart
                type={sharpeValue.type}
                // chartData={sharpeValue.chartData}
                chartData={sharpeChartDataToUse.chartData}
                onChangeSelectedItem={onChangeSelectedItem}
            />
        </>
    );

    return (
        <>
            <div className="macro-currencies-barcharts-container findatasheets-container-item-bottom return-sharpe-container flex">
                <CardContainer
                    title={returnTitle}
                    innerContent={returnContent}
                    className={'return-sharpe-item card-container return-sharpe-return-container'}
                    data={returnValue.chartData}
                    type={'bar-chart'}
                    infoModalType={'return'}
                />
                <CardContainer
                    title={sharpeTitle}
                    innerContent={sharpeContent}
                    className={'return-sharpe-item card-container'}
                    data={sharpeValue.chartData}
                    type={'bar-chart'}
                    infoModalType={'sharpe'}
                />
            </div>
        </>
    );
}

export default ReturnSharpe;