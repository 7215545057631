import React, { useState } from "react";
import Select, { components } from 'react-select';
import { customStyles,DropdownIndicator } from "../../../shared/dropdown-styles";
import { dropdownIcon } from "../../../shared/icons/icons";

const PortfolioCompaniesFilter = ({
    onChangeCompanies,
    companyFilters,
    activeCompanies
}) => {

    const [showOptions, setShowOptions] = useState(false);

    const onCompanyChange = (selectedOptions) => {
        if (!selectedOptions.length) return;
        onChangeCompanies(selectedOptions);
    };

    const onHandleMouseEnter = () => {
        setShowOptions(true);
    };

    const onHandleMouseLeave = () => {
        setShowOptions(false);
    };

    return (
        <div className="dropdown-select-filter-item"
            onMouseEnter={onHandleMouseEnter}
            onMouseLeave={onHandleMouseLeave}
        >
            <Select
                styles={customStyles}
                components={{
                    DropdownIndicator,
                }}
                isMulti
                options={companyFilters}
                value={activeCompanies}
                onChange={onCompanyChange}
                menuIsOpen={showOptions}
                hideSelectedOptions={false}
            />
            <div className={`dropdown-label flex align-center ${activeCompanies.length !== companyFilters.length ? 'incomplete-dropdown' : ''}`}>Companies <span className='dropdown-indicator flex center-center'>{dropdownIcon}</span></div>
        </div>
    );
}

export default PortfolioCompaniesFilter;