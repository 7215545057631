import React, { useEffect, useState } from "react";
import CardContainer from "../../../../cmps/card-container/card-container";
import BarChart from "../../../../shared/charts/bar-chart/bar-chart";
import SingleSectorFilter from "../single-sector-filters";

const SingleSectorFundamental = ({
    sectorFundamental,
    companyFilters,
    activeCompanyFilter,
    handleToggleFilter
}) => {

    const [fundamentalFilters, setFundamentalFilters] = useState(null);
    const [activeFilter, setActiveFilter] = useState(null);

    useEffect(() => {
        const fundamentalKeys = Object.keys(sectorFundamental).map(key => ({
            label: key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()),
            value: key
        }));
        setActiveFilter(fundamentalKeys[0].value);
        setFundamentalFilters(fundamentalKeys);
    }, [sectorFundamental]);

    const getChartData = (key) => {
        const data = sectorFundamental[activeFilter][key];
        if (data && data.length) {
            const labels = data.map((item) => item.symbol);
            const values = data.map((item) => +item.y);
            const color = values.map((value) => value < 0 ? '#e39774' : '#5c9ead');
            return {
                labels: labels,
                datasets: [
                    {
                        label: key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()),
                        data: values,
                        backgroundColor: color,
                        borderColor: color,
                        borderWidth: 1,
                    },
                ],
            };
        }
        return null;
    };

    const singleSectorFundamentalTitle = (key) => (
        <h4 className='portfolio-chart-title'>
            {key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
        </h4>
    );

    const singleSectorFundamentalContent = (key, chartData) => (
        <>
            <BarChart type={'company'} chartData={chartData} />
        </>
    );

    const handleTogglePage = (option) => {
        setActiveFilter((prevOption) => {
            return option;
        });
    };

    return (
        <>

            {
                companyFilters &&
                activeCompanyFilter &&
                <SingleSectorFilter
                    options={companyFilters}
                    optionsValue={activeCompanyFilter}
                    onChange={handleToggleFilter}
                />
            }

                <div className="fundamental-page-container flex center-center">
                    {
                        fundamentalFilters &&
                        fundamentalFilters.map((option) => {
                            return <div key={option.value} className={`portfolio-toggle btn selected-option ${activeFilter === option.value ? 'selected' : ''}`} onClick={() => handleTogglePage(option.value)}>{option.label}</div>
                        })
                    }
                </div>

            {
                sectorFundamental &&
                sectorFundamental[activeFilter] && (
                    <div className='fundamental-graphs-container flex wrap center-center'>
                        {
                            Object.keys(sectorFundamental[activeFilter]).map((key) => {
                                const chartData = getChartData(key);
                                if (!chartData) return null;
                                return (
                                    <CardContainer
                                        key={key}
                                        title={singleSectorFundamentalTitle(key)}
                                        innerContent={singleSectorFundamentalContent(key, chartData)}
                                        className={'chart-wrapper'}
                                        infoModalType={key}
                                    />
                                );
                            })
                        }
                    </div>
                )
            }
        </>
    );
}

export default SingleSectorFundamental;