import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { setGlobalIsLoading } from "../../store/loading/loading-actions";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../shared/loading-spinner/loading-spinner";
import ProfileHeader from "./profile-header/profile-header";
import { getProfileData, getProfilePosts } from "../../shared/actions/profile-actions";
import { Helmet } from "react-helmet";
import './user-profile.scss';
import ProfilePages from "./profile-pages";
import ProfilePosts from "./profile-pages/profile-posts";
import ProfilePortfolios from "./profile-pages/profile-portfolios";
import ProfileInterests from "./profile-pages/profile-interests";
import ProfileSettings from "./profile-pages/profile-settings";
import { getHomepageData } from "../../shared/actions/homepage-actions";
import ProfileAchievements from "./profile-achievements/profile-achievements";
import ProfileChallenges from "./profile-challenges/profile-challenges";
import { setGlobalLastPrices } from "../../store/last-prices/last-prices-actions";
import { setGlobalUser } from "../../store/user/user-actions";

const UserProfile = ({
    loggedInUser,
    userFollowing,
    userAbout,
    setUserAbout,
    toggleFollowing,
    userProfileData,
    handleSetUserProfileData,
    myPortfolio,
    mySelectedPortfolio,
    handleCopyPortfolio,
    handleChangeMySelectedPortfolio,
    onCopyPortfolio,
    handlePostAction,
    handleCopyPortfolioFromFeed,
    companiesLastPrices,
    calcPortfolioReturn,
    handleSetCompaniesLastPrices,

    challengeInProcess,
    handleChangeChallengeInProcess,
    userChallenges,
    userAchievements,
    blinkCmp
}) => {

    const { username } = useParams();
    const location = useLocation();
    const loadingSelector = useSelector((state) => state.loading.isLoading);
    const dispatch = useDispatch();
    const history = useHistory();

    const lastPrices = useSelector((state) => state.lastPrices);

    const [isMyProfile, setIsMyProfile] = useState(null);
    const [visitedProfile, setVisitedProfile] = useState(null);

    const [profileData, setProfileData] = useState(null);
    const [visitedProfilePortfolioOrders, setVisitedProfilePortfolioOrders] = useState({});

    const [aboutToDisplay, setAboutToDisplay] = useState('');
    const [followingToDisplay, setFollowingToDisplay] = useState({
        companies: [],
        sectors: [],
        users: [],
        crypto: [],
        commodities: [],
        indices: [],
        currencies: [],
        users: []
    });

    useEffect(() => {
        if (Object.keys(lastPrices).length !== 0) return;
        async function fetchTrendingData() {
            // dispatch(setGlobalIsLoading(true));
            try {
                const homepageDataRes = await getHomepageData();
                if (homepageDataRes) {
                    // handleSetCompaniesLastPrices(homepageDataRes.lastPrices);
                    dispatch(setGlobalLastPrices(homepageDataRes.singlesInfo));
                } else {
                    console.log('ERROR HOMEPAGE - TRENDING');
                    // handleNavigateToError();
                };
            } catch (error) {
                console.log('HOMEPAGE TRENDING ERROR - ', error);
                // handleNavigateToError();
            }
        };
        fetchTrendingData();
    }, [companiesLastPrices]);

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setGlobalIsLoading(true));
            try {
                if (username === loggedInUser) {
                    if (!myPortfolio || !mySelectedPortfolio) return;
                    if (userProfileData) {
                        setProfileData(userProfileData);
                    } else {
                        // console.log('### MY PROFILE ###');
                        const res = await getProfileData(username, mySelectedPortfolio, myPortfolio[mySelectedPortfolio].portfolioData, 'true');
                        const resWithScores = res.map(entry => {
                            const { portfolioKey } = entry;
                            if (myPortfolio[portfolioKey]) {
                                entry.portfolioScores = myPortfolio[portfolioKey].portfolioScores;
                            }
                            return entry;
                        });
                        setProfileData(resWithScores);
                        handleSetUserProfileData(resWithScores);
                    }
                } else {
                    // handle case not my profile
                    // console.log('### NOT MY PROFILE ###');
                    const res = await getProfileData(username, null, null, 'false');
                    setProfileData(res);
                    const transformedObject = res.reduce((acc, item) => {
                        const { portfolioKey, portfolioName, ordersList } = item;
                        acc[portfolioKey] = {
                            portfolioName,
                            ordersList,
                        };
                        return acc;
                    }, {});
                    setVisitedProfilePortfolioOrders(transformedObject);
                }
            } catch (error) {
                // Handle errors if any
                console.error('Error fetching profile data:', error);
            }
        };

        fetchData();

    }, [isMyProfile, mySelectedPortfolio, myPortfolio, userProfileData, username, loggedInUser]);

    function fetchUserData() {
        const apiUrl = 'https://4s8f188hhc.execute-api.us-east-1.amazonaws.com/get-user-data';
        const requestBody = JSON.stringify({ username });
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: requestBody,
            redirect: 'follow'
        };

        fetch(apiUrl, requestOptions)
            .then((response) => {
                if (response.ok) {
                    return response.text();
                } else {
                    history.push('/error');
                }
            })
            .then((result) => {
                const data = JSON.parse(result);
                if (data.following && typeof data.following === 'string') {
                    data.following = JSON.parse(data.following);
                };
                // dispatch(setGlobalUser(data));
                // setIsMyProfile(false);
                setVisitedProfile(data);
                setAboutToDisplay(data.about);
                setFollowingToDisplay(data.following);
                dispatch(setGlobalIsLoading(false));
            })
            .catch((error) => {
                console.log('Error:', error);
                setAboutToDisplay('');
                setFollowingToDisplay({
                    companies: [],
                    sectors: [],
                    users: [],
                    crypto: [],
                    commodities: [],
                    indices: [],
                    currencies: []
                });
            });
    };

    const [activePage, setActivePage] = useState('portfolios');

    const [userPosts, setUserPosts] = useState([]);
    async function fetchUserPosts() {
        try {
            const posts = await getProfilePosts(username);
            setUserPosts(posts);
        } catch (error) {
            console.error('Error fetching user posts:', error);
        }
    };

    useEffect(() => {
        dispatch(setGlobalIsLoading(true));
        if (username) fetchUserPosts();
        if (loggedInUser === username) {
            setIsMyProfile(true);
            setVisitedProfile(false);
            setAboutToDisplay(userAbout);
            setFollowingToDisplay(userFollowing);
            dispatch(setGlobalIsLoading(false));
        } else {
            fetchUserData();
            setIsMyProfile(false);
        }
    }, [username, loggedInUser]);

    useEffect(() => {
        if (!profileData) return;
        dispatch(setGlobalIsLoading(false));
    }, [profileData]);

    useEffect(() => {
        history.push(`/profile/${activePage}/${username}`);
    }, [activePage]);

    async function updateAbout(aboutStr) {
        if (!loggedInUser) {
            console.error('Missing username.');
            return;
        }

        try {
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');

            // Create the request body with the updated userFollowing object
            const requestBody = {
                username: loggedInUser,
                aboutStr: aboutStr,
            };

            const requestBodyString = JSON.stringify(requestBody);

            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: requestBodyString,
            };

            const response = await fetch(
                'https://wdv9je89h4.execute-api.us-east-1.amazonaws.com/set-user-about',
                requestOptions
            );

            if (!response.ok) {
                console.log('response:', response);
            } else {
                const result = await response.text();
                const data = JSON.parse(result);
                return data;
            }
        } catch (error) {
            // Log the error
            console.error('Error updating about:', error);
            throw error;
        }
    };

    const onChangeAbout = (aboutStr) => {
        setUserAbout(aboutStr);
        updateAbout(aboutStr);
        setAboutToDisplay(aboutStr);
    };

    // const pages = ['Posts', 'Portfolios', 'Interests', 'Challenges', 'Achievements', 'Settings'];
    // const pages = ['Challenges', 'Achievements', 'Posts', 'Portfolios', 'Interests', 'Settings'];
    // const pages = ['Achievements', 'Posts', 'Portfolios', 'Interests', 'Settings'];

    // const [pages, setPages] = useState(['Achievements', 'Portfolios', 'Posts', 'Interests', 'Settings']);
    const [pages, setPages] = useState(['Portfolios', 'Posts', 'Interests', 'Settings']);

    useEffect(() => {
        if (isMyProfile) {
            // setPages(['Achievements', 'Portfolios', 'Posts', 'Interests', 'Settings']);
            setPages(['Portfolios', 'Posts', 'Interests', 'Settings']);
        } else {
            setPages(['Portfolios', 'Posts', 'Interests', 'Settings']);
            // if (activePage === 'achievements') {
            //     setActivePage('posts');
            // }
        }
    }, [isMyProfile, activePage]);


    const handleToggleOption = (option) => {
        setActivePage((prevOption) => {
            return option;
        });
    };

    const getNavLocation = () => {
        return `/profile/${activePage}/${username}`;
    }

    const [portfolioToDisplay, setPortfolioToDisplay] = useState(null);

    useEffect(() => {
        if (isMyProfile) {
            setPortfolioToDisplay(myPortfolio);
        } else if (!isMyProfile && visitedProfile) {
            setPortfolioToDisplay(visitedProfile.portfolio);
            if (activePage === 'achievements') setActivePage('portfolios');
        }
    }, [isMyProfile, visitedProfile, myPortfolio]);


    return (
        <div className="profile-main-container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow - My Profile</title>
                <meta name="description" content={`${loggedInUser}'s profile - Visit My Profile to gain valuable insights into their portfolios, statistical data, and the accounts they follow, offering you a deeper understanding and knowledge about their interests and activities.`} />
                <link rel="canonical" href="https://www.portfollow.com/portfolio" />
            </Helmet>
            {
                loadingSelector &&
                    followingToDisplay ?
                    <LoadingSpinner /> : (

                        <div className="user-profile-container main-content-container">

                            <div className="card-container mb20" style={{ paddingBottom: '0' }}>
                                <ProfileHeader
                                    loggedInUser={loggedInUser}
                                    username={username}
                                    isMyProfile={isMyProfile}
                                    toggleFollowing={toggleFollowing}
                                    userFollowing={followingToDisplay?.users || []}
                                    about={aboutToDisplay}
                                    onChangeAbout={onChangeAbout}
                                    visitedProfile={visitedProfile}
                                    fetchUserData={fetchUserData}
                                />

                                <ProfilePages
                                    handleToggleOption={handleToggleOption}
                                    pages={pages}
                                    activePage={activePage}
                                    isMyProfile={isMyProfile}
                                    getNavLocation={getNavLocation}
                                />
                            </div>

                            {
                                activePage === 'posts' &&
                                <ProfilePosts
                                    userPosts={userPosts}
                                    loggedInUser={myPortfolio}
                                    myPortfolio={myPortfolio}
                                    handleCopyPortfolio={handleCopyPortfolio}
                                    onCopyPortfolio={onCopyPortfolio}
                                    handlePostAction={handlePostAction}
                                    handleCopyPortfolioFromFeed={handleCopyPortfolioFromFeed}
                                    companiesLastPrices={companiesLastPrices}
                                    calcPortfolioReturn={calcPortfolioReturn}
                                />
                            }
                            {
                                activePage === 'portfolios' &&
                                portfolioToDisplay &&
                                <ProfilePortfolios
                                    profileData={profileData}
                                    isMyProfile={isMyProfile}
                                    mySelectedPortfolio={mySelectedPortfolio}
                                    myPortfolio={myPortfolio}
                                    visitedProfilePortfolioOrders={visitedProfilePortfolioOrders}
                                    handleCopyPortfolio={handleCopyPortfolio}
                                    loggedInUser={loggedInUser}
                                    handleChangeMySelectedPortfolio={handleChangeMySelectedPortfolio}
                                    portfolioToDisplay={portfolioToDisplay}
                                />
                            }
                            {
                                activePage === 'interests' &&
                                <ProfileInterests
                                    isMyProfile={isMyProfile}
                                    username={username}
                                    userFollowing={followingToDisplay}
                                />
                            }
                            {
                                activePage === 'achievements' &&
                                <ProfileAchievements
                                    isMyProfile={isMyProfile}
                                    username={username}
                                    userAchievements={userAchievements}
                                    blinkCmp={blinkCmp}
                                />
                            }
                            {
                                activePage === 'challenges' &&
                                <ProfileChallenges
                                    isMyProfile={isMyProfile}
                                    username={username}
                                    challengeInProcess={challengeInProcess}
                                    handleChangeChallengeInProcess={handleChangeChallengeInProcess}
                                    userChallenges={userChallenges}
                                />
                            }
                            {
                                activePage === 'settings' &&
                                isMyProfile &&
                                <ProfileSettings
                                    loggedInUser={loggedInUser}
                                />
                            }

                        </div>
                    )
            }
        </div>
    );
}

export default UserProfile;