import React, { useState, useEffect } from "react";
import { getRandomColor } from "../../../../../shared/global-color-functions";
import MemberPortfolioPiechart from "./member-portfolio-piechart";

const MemberPortfolio = ({ memberPortfolioData }) => {

    console.log('memberPortfolioData:', memberPortfolioData);
    console.log('CASH:', memberPortfolioData.memberData.cash);

    const [chartLabels, setChartLabels] = useState(null);
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (!memberPortfolioData || !memberPortfolioData.memberData || !memberPortfolioData.memberData.ordersList) return;

        // console.log('memberPortfolioData:', memberPortfolioData.memberData.ordersList);

        const labelSymbolColorArray = memberPortfolioData.memberData.ordersList.map(order => ({
            label: order.symbol,
            symbol: order.symbol,
            color: getRandomColor() // Generate a random color for each order
        }));

        labelSymbolColorArray.push({
            label: 'Cash',
            symbol: 'Cash',
            color: getRandomColor()
        });

        const pieData = {
            labels: memberPortfolioData.memberData.ordersList.map(order => order.symbol),
            hover: memberPortfolioData.memberData.ordersList.map(order => order.companyName),
            datasets: [
                {
                    backgroundColor: memberPortfolioData.memberData.ordersList.map(order => {
                        // Find the color corresponding to the symbol in labelSymbolColorArray
                        const item = labelSymbolColorArray.find(item => item.symbol === order.symbol);
                        return item ? item.color : getRandomColor(); // If color is found, use it; otherwise, generate a random color
                    }),
                    borderColor: ['#ffffff', '#ffffff', '#ffffff'],
                    borderWidth: 2,
                    data: memberPortfolioData.memberData.ordersList.map(order => order.initialWorth),
                    label: "Worth"
                }
            ]
        };

        pieData.labels.push('Cash');
        pieData.hover.push('Available Cash');
        pieData.datasets[0].data.push(memberPortfolioData.memberData.cash);
        
        // Find the color corresponding to 'Cash' in labelSymbolColorArray
        const cashColorItem = labelSymbolColorArray.find(item => item.symbol === 'Cash');
        const cashColor = cashColorItem ? cashColorItem.color : getRandomColor();
        pieData.datasets[0].backgroundColor.push(cashColor);
        pieData.datasets[0].borderColor.push('#ffffff');
        pieData.datasets[0].label += 'Cash';

        // console.log('chartLabels:', labelSymbolColorArray);
        // console.log('chartData:', pieData);

        setChartLabels(labelSymbolColorArray);
        setChartData(pieData);
    }, [memberPortfolioData]);


    return (
        <>
            {
                chartLabels &&
                chartData &&
                <MemberPortfolioPiechart
                    item={chartData}
                    title={'Matchweek Portfolio'}
                    chartLabels={chartLabels}
                    type={'company'}
                />
            }
        </>
    );
}

export default MemberPortfolio;