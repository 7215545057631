
// portfolio
export const CompaniesGuide = () => {
    return <>
        <h2 className={'guide-title'}>Companies Guide</h2>
        <ul className={"guide-ul"}>
            <li>Analyze individual stock market companies.</li>
            <li>Customize analysis based on time frames and industry sectors.</li>
            <li>View visualizations such as line charts, pie charts, and bar charts displaying values, returns, and risk metrics.</li>
        </ul>
        <h4 className={"guide-subtitle"}>Filters:</h4>
        <ul className={"guide-ul"}>
            <li>Time Frames: Select specific time periods for analysis.</li>
            <li>Sector: Filter stock market companies by industry sectors.
            </li>
            <li>Companies: Choose from a variety of stock market companies to analyze.
            </li>
        </ul>
        <h4 className={"guide-subtitle"}>Visualizations:</h4>
        <ul className={"guide-ul"}>
            <li>Value Over Time Line Chart: Displays the value of each invested company in the portfolio over time.
            </li>
            <li>Current Companies Values Pie Chart: Illustrates the current allocation of the portfolio among different stocks.
            </li>
            <li>Reward Bar Charts: Show the return, Sharpe ratio, and information ratio of each stock market company.
            </li>
        </ul>
    </>;
};

export const SectorsGuide = () => {
    return (
        <>
            <h2 className={'guide-title'}>Sectors Guide</h2>
            <ul className={"guide-ul"}>
                <li>Explore and analyze stock market industry sectors.</li>
                <li>Customize analysis based on time frames.</li>
                <li>Visualize sector values, returns, and risk metrics through charts.</li>
            </ul>
            <h4 className={"guide-subtitle"}>Filters:</h4>
            <ul className={"guide-ul"}>
                <li>Time Frames: Select specific time periods for analysis.
                </li>
                <li>Sectors: Choose stock market industry sectors to analyze.
                </li>
            </ul>
            <h4 className={"guide-subtitle"}>Visualizations:</h4>
            <ul className={"guide-ul"}>
                <li>Value Over Time Line Chart: Displays the value of investments in each sector over time.
                </li>
                <li>Current Sectors Values Pie Chart: Illustrates the current allocation of the portfolio among different sectors.
                </li>
                <li>Reward Bar Charts: Show the return and Sharpe ratio of investments in each sector.
                </li>
            </ul>
        </>
    );
};

export const FundamentalGuide = () => {
    return (
        <>
            <h2 className={'guide-title'}>Fundamental Guide</h2>
            <ul className={"guide-ul"}>
                <li>Evaluate fundamental ratios of stock market companies.</li>
                <li>Examine profitability, coverage, solvency, market prospect, liquidity, and efficiency metrics.</li>
                <li>Choose time frames and view modes for in-depth analysis.</li>
            </ul>
            <h4 className={"guide-subtitle"}>Filters:</h4>
            <ul className={"guide-ul"}>
                <li>Sector: Filter stock market companies by industry sectors.
                </li>
                <li>Companies: Choose from a variety of stock market companies to analyze.
                </li>
                <li>Time Frames: Choose time periods for analysis.
                </li>
                <li> View Mode: Select whether to view values over time or just the last value for each company.
                </li>
            </ul>
            <h4 className={"guide-subtitle"}>Ratios:</h4>
            <ul className={"guide-ul"}>
                <li>Profitability: Includes Return On Equity (ROE), Percent Pure Profit, Return On Assets (ROA), and Gross Profit Margin.</li>
                <li>Coverage: Includes Asset Coverage, Debt Service Coverage, and Interest Coverage.
                </li>
                <li>Solvency: Includes Debt To Equity, Debt To Assets, and Shareholder Equity Ratio.
                </li>
                <li>Market Prospect: Includes Earnings Per Share (EPS), Dividend Payout Ratio, and Dividend Per Share.
                </li>
                <li>Liquidity: Includes Current Ratio, Quick Ratio, and Days Sales Outstanding.
                </li>
                <li>Efficiency: Includes Efficiency Ratio, Assets Turnover, and Percent Operating Income.
                </li>
            </ul>
        </>
    );
};

export const DescriptiveGuide = () => {
    return (
        <>
            <h2 className={'guide-title'}>Descriptive Guide</h2>
            <ul className={"guide-ul"}>
                <li>Understand the descriptive statistics of stock market companies.</li>
                <li>Visualize distributions, statistical summaries, correlations, and unexpected returns using various charts and tables.</li>
            </ul>
            <h4 className={"guide-subtitle"}>Filters:</h4>
            <ul className={"guide-ul"}>
                <li>Time Frames: Select specific time periods for analysis.
                </li>
                <li>Sector: Filter stock market companies by industry sectors.
                </li>
                <li>Companies: Choose from a variety of stock market companies to analyze.
                </li>
            </ul>
            <h4 className={"guide-subtitle"}>Visualizations:</h4>
            <ul className={"guide-ul"}>
                <li>Kernel Density Estimation Distributions Line Chart: Shows the distribution of daily stock market returns for each company.
                </li>
                <li>Statistical Summary Table: Provides statistical metrics such as average daily return, median, standard deviation, and range for each stock market symbol.
                </li>
                <li>Correlation Table: Displays the correlation between all stock market assets in the portfolio.
                </li>
                <li>Unexpected Returns Table: Highlights outlier returns using statistical tests such as Z-Score Method, IQR Method, Local Outlier Factor, and Kernel Density Estimation.
                </li>
            </ul>
        </>
    );
};

export const SensitivityGuide = () => {
    return (
        <>
            <h2 className={'guide-title'}>Sensitivity Guide</h2>
            <ul className={"guide-ul"}>
                <li>Conduct scenario analysis and Monte Carlo simulations.
                </li>
                <li>Explore expected portfolio performance under different scenarios.
                </li>
                <li>Analyze sensitivity through beta values, Markov Chains probabilities, and regression analysis.
                </li>
            </ul>
            <h4 className={"guide-subtitle"}>Filters:</h4>
            <ul className={"guide-ul"}>
                <li>Time Frames: Choose time periods for analysis.
                </li>
            </ul>
            <h4 className={"guide-subtitle"}>Visualizations:</h4>
            <ul className={"guide-ul"}>
                <li>Scenario Analysis - Monte Carlo Simulation Distribution: Shows future scenarios for expected portfolio performance based on historical data, for 5 days, 30 days, and 90 days.
                </li>
                <li>Monte Carlo Simulation: A statistical technique used to model the probability of different outcomes in a process that cannot be easily predicted due to the presence of random variables.
                </li>
                <li>Expected Value Over Time Line Chart: Illustrates the expected portfolio value over time based on historical filtered data, with residual boundaries.
                </li>
                <li>Sensitivity Analysis Table: Provides beta values for each symbol to the S&P 500, Markov Chains probabilities for price movements, and regression analysis results for relationships between open price, volume, and close price.
                </li>
            </ul>
        </>
    );
};

// macro
export const InflationGuide = () => {
    return (
        <>
            <div className="card-container mb20">
                <h2 className={'guide-title'}>Inflation Guide</h2>
                <h4 className={"guide-subtitle"}>Leading Macroeconomic Key Performance Indicators (KPIs):</h4>
                <ul className={"guide-ul"}>
                    <li>Fed Funds Effective Rate: The interest rate at which depository institutions (banks and credit unions) lend reserve balances to other depository institutions overnight on an uncollateralized basis, as set by the Federal Reserve.</li>
                    <li>Unemployment Rate: The percentage of the total labor force that is unemployed and actively seeking employment.</li>
                    <li>Yearly Change in Money Supply: The annual percentage change in the money supply, measuring the amount of money in circulation in the economy.
                    </li>
                    <li>Yearly Inflation: The annual percentage increase in the general price level of goods and services in an economy.
                    </li>
                    <li>10Y Breakeven Inflation: The market-based measure of expected inflation derived from the yield difference between 10-year Treasury bonds and Treasury Inflation-Protected Securities (TIPS).
                    </li>
                    <li>5Y Breakeven Inflation: Similar to the 10Y Breakeven Inflation, but based on the yield difference between 5-year Treasury bonds and TIPS.
                    </li>
                    <li>5Y Forward Inflation Expected: The market's expectation of future inflation over the next five years, derived from financial instruments such as inflation swaps.
                    </li>
                </ul>
                <h4 className={"guide-subtitle"}>Charts:</h4>
                <ul className={"guide-ul"}>
                    <li>Macro Indicators Over Time: Tracks the historical trends of Fed Funds Effective Rate, Unemployment Rate, Yearly Change in Money Supply, and Yearly Inflation.
                    </li>
                    <li>Inflation Expectations Over Time: Displays the historical trends of market-based measures of inflation expectations, including 10Y Breakeven Inflation, 5Y Breakeven Inflation, and 5Y Forward Inflation Expected.
                    </li>
                </ul>
            </div>
        </>
    );
};

export const CurrenciesGuide = () => {
    return (
        <>
            <h2 className={'guide-title'}>Currencies (Exchange Rates) Guide </h2>
            <h4 className={"guide-subtitle"}>Filters:</h4>
            <ul className={"guide-ul"}>
                <li>Time Frames: Select specific time periods for analysis.
                </li>
                <li>Currencies (Exchange Rates): Choose from a variety of currency pairs to analyze exchange rates.
                </li>
                <li>Price Viewing Filter (Relative vs. Actual Values): Toggle between viewing exchange rates in relative terms (percentage changes) or actual values (currency pairs).
                </li>
            </ul>
            <h4 className={"guide-subtitle"}>Charts:</h4>
            <ul className={"guide-ul"}>
                <li>Currency Exchange Rates Over Time: Visualizes the historical exchange rates of selected currency pairs.
                </li>
                <li>Currency Returns Over Time: Analyzes the returns of currency pairs over different time frames.
                </li>
                <li>Sharpe Ratio Bar Chart: Compares the risk-adjusted returns (Sharpe ratios) of currency pairs over various time frames.
                </li>
                <li>Country Ratings table: Provides the latest ratings from Moody's, S&P, and Fitch for various countries, along with the rating of each company.
                </li>
            </ul>
        </>
    );
};

export const IndicesGuide = () => {
    return (
        <>
            <h2 className={'guide-title'}>Indices Guide</h2>
            <h4 className={"guide-subtitle"}>Filters:</h4>
            <ul className={"guide-ul"}>
                <li>Time Frames: Select specific time periods for analysis.</li>
                <li>Continents: Filter indices by geographical regions.</li>
                <li>Countries: Narrow down analysis by selecting specific countries.</li>
                <li>Indices: Choose from a variety of stock market indices.</li>
                <li>Price Viewing Filter (Relative vs. Actual Values): Toggle between viewing index prices in relative terms (percentage changes) or actual values.</li>
            </ul>
            <h4 className={"guide-subtitle"}>Charts:</h4>
            <ul className={"guide-ul"}>
                <li>Index Prices Over Time: Displays the historical prices of selected stock market indices.</li>
                <li>Index Returns Over Time: Analyzes the returns of stock market indices over different time frames.</li>
                <li>Sharpe Ratio Bar Chart: Compares the risk-adjusted returns (Sharpe ratios) of stock market indices over various time frames.
                </li>
            </ul>
        </>
    );
};

export const CommoditiesGuide = () => {
    return (
        <>
            <h2 className={'guide-title'}>Commodities Guide</h2>
            <h4 className={"guide-subtitle"}>Filters:</h4>
            <ul className={"guide-ul"}>
                <li>Time Frames: Select specific time periods for analysis.</li>
                <li>Commodity Groups: Filter commodities by categories such as energy, metals, agriculture, etc.</li>
                <li>Commodities: Choose from a variety of individual commodities.</li>
                <li>Price Viewing Filter (Relative vs. Actual Values): Toggle between viewing commodity prices in relative terms (percentage changes) or actual values.</li>
            </ul>
            <h4 className={"guide-subtitle"}>Charts:</h4>
            <ul className={"guide-ul"}>
                <li>Commodity Prices Over Time: Displays the historical prices of selected commodities.</li>
                <li>Commodity Returns Over Time: Analyzes the returns of commodities over different time frames.</li>
                <li>Commodity Returns Over Time: Analyzes the returns of commodities over different time frames.</li>
                <li>Sharpe Ratio Bar Chart: Compares the risk-adjusted returns (Sharpe ratios) of commodities over various time frames.</li>
            </ul>
        </>
    );
};

// crypto
export const CryptoPageGuide = () => {
    return (
        <>
            <div className="card-container mb20">
                <h2>Crypto Guide</h2>
                <h3 className={'guide-title'}>Charts</h3>
                <h4 className={"guide-subtitle"}>Line charts:</h4>
                <ul className={"guide-ul"}>
                    <li>Currency Performance:
                        View the historical performance of different cryptocurrencies through interactive line charts. Track changes in crypto prices over time and analyze trends. Customize your view by selecting specific cryptocurrencies or categories.</li>
                    <li>Return Analysis:
                        Analyze the return of cryptocurrencies using line charts. Compare the returns of various cryptos over different time periods. Identify the best crypto performers and make informed investment decisions.</li>
                </ul>
                <h4 className={"guide-subtitle"}>Bar charts:</h4>
                <ul className={"guide-ul"}>
                    <li>Comparison of Returns: Utilize bar charts to compare the returns of multiple cryptocurrencies. Easily visualize which cryptos have generated the highest returns over specific time frames. Identify potential investment opportunities or portfolio adjustments.
                    </li>
                    <li>Sharpe Ratio Analysis: Evaluate the risk-adjusted returns of cryptocurrencies using Sharpe ratio bar charts. Assess the efficiency of different assets in generating returns relative to their risk. Make informed decisions by considering both return and risk metrics.
                    </li>
                </ul>
            </div>

            <div className="card-container mb20">
                <h3 className={'guide-title'}>Filters</h3>
                <ul className={"guide-ul"}>
                    <li>Time Frame Filter: Compare the performance of cryptocurrencies across different time frames, identifying trends and patterns that may not be evident in shorter or longer periods. Analyzing returns and Sharpe ratios across various time frames provides users with dynamic insights into the volatility and risk-adjusted returns of different assets.
                    </li>
                    <li>Category Filter: Explore cryptocurrencies within particular categories. Narrow down your search by selecting specific categories such as "Bitcoin and Altcoin," "Utility Tokens," "Privacy Coins," "Stablecoins," or "Asset-backed Tokens."
                    </li>
                    <li>Currency Filter: Filter cryptocurrencies based on specific currencies you are interested in. Customize your analysis by focusing on individual assets or a combination of currencies.
                    </li>
                    <li>Price Viewing Filter: Choose between viewing crypto prices in relative terms or actual values. Opt for relative price viewing to compare the performance of cryptocurrencies based on percentage changes. Select actual price viewing to analyze the absolute price movements of assets.
                    </li>
                </ul>
            </div>
        </>
    );
};

// calendar
export const CalendarCompaniesEventGuide = () => {
    return (
        <>
            <h2 className={'guide-title'}>Company Events Calendar Guide</h2>
            <ul className={"guide-ul"}>
                <li>Splits - Stay informed about upcoming stock splits.</li>
                <li>Dividends - Monitor dividend declarations.</li>
                <li>SEC Filings - Track important corporate developments.</li>
                <li>IPO Events - Keep track of upcoming initial public offerings (IPOs).</li>
            </ul>
        </>
    );
};

export const CalendarMacroGuide = () => {
    return (
        <>
            <h2 className={'guide-title'}>Macro Events Calendar Guide</h2>
            <ul className={"guide-ul"}>
                <li>Labor Market - Stay updated on labor market data.
                </li>
                <li>Housing - Monitor housing market events.
                </li>
                <li>Commodities - Track commodity prices and market trends for key commodities.
                </li>
                <li>Monetary Policy - Stay informed about central bank meetings, interest rate decisions, and monetary policy statements.
                </li>
            </ul>
        </>
    );
};

