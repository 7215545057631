import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { setGlobalIsLoading } from "../../store/loading/loading-actions";
import { useMediaQuery } from 'react-responsive';
import './label-color-container.scss';

const LabelColorContainer = ({ labels, worth, height, type, display, selectedItem, onChangeSelectedItem = () => { } }) => {
    
    const isMobile = useMediaQuery({ maxWidth: 920 });
    const dispatch = useDispatch();
    const history = useHistory();

    const sortedLabels = labels.sort((a, b) => {
        if (!worth) {
            const valueA = a.label || a.symbol || a.sector;
            const valueB = b.label || b.symbol || b.sector;
            return valueA.localeCompare(valueB);
        }

        const worthA = worth.find(
            (item) => item.symbol === a.label || item.sector === a.label || item.label === a.label
        )?.worth || 0;
        const worthB = worth.find(
            (item) => item.symbol === b.label || item.sector === b.label || item.label === b.label
        )?.worth || 0;

        return worthB - worthA;
    });

    const onNavigate = (label) => {
        if (!label) return;
        // if (type === 'sector') return;
        dispatch(setGlobalIsLoading(true));
        let navLabel = label;
        if (type === 'sector') navLabel = toCamelCase(label);
        history.push(`/${type}/${navLabel}`);
    };

    const toCamelCase = (input) => {
        return input.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    // const changeSelectedItem = (item) => {
    // onChangeSelectedItem(item);
    // };

    const changeSelectedItemLabel = (label) => {
        onChangeSelectedItem(label);
    };

    const changeSelectedItemNull = () => {
        onChangeSelectedItem(null);
    };

    return (
        <div
            className={`card-container ${isMobile ? 'color-label-media' : ''} label-color-container ${display === 'column' ? 'color-label-card-column' : 'color-label-card-row flex wrap'}`}
            style={{ height: height }}
        >
            {
                sortedLabels.map((item, idx) => (
                    <div
                        className={((display === 'column' ? 'label-color-item-column ' : 'label-color-item-row ') + 'label-color-item') +
                            (type === 'company' ||
                                type === 'sector' ||
                                type === 'crypto' ||
                                type === 'currency' ||
                                type === 'index' ||
                                type === 'commodity'
                                ? ' label-color-item-clickable'
                                : '') +
                            (selectedItem === item.label ? ' selected-item-color-label' : '')
                        }
                        style={{
                            borderLeft: window.innerWidth < 920 ? 'none' : '10px solid ' + item.color,
                            borderBottom: window.innerWidth < 920 ? '4px solid ' + item.color : 'none',
                        }}
                        onMouseEnter={() => changeSelectedItemLabel(item.label)}
                        onMouseLeave={() => changeSelectedItemNull(null)}
                    >
                        {
                            type === 'company' ||
                                type === 'sector' ||
                                type === 'crypto' ||
                                type === 'currency' ||
                                type === 'index' ||
                                type === 'commodity' ? (
                                <span
                                    className={`${display === 'column' ? 'label-color-item-span' : 'label-color-item-span-row'}`}
                                    onClick={() => onNavigate(item.symbol)}
                                >{item.symbol}
                                </span>
                            ) : (
                                item.label
                            )
                        }
                    </div>
                ))
            }
        </div >
    );
}

export default LabelColorContainer;
