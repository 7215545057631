import React, { useEffect, useState } from 'react';
import StarterTutorialContent from './starter-tutorial-content';
import { useHistory, useLocation } from "react-router-dom";
import './starter-tutorial.scss';
import { setShouldSeeTutorial } from '../../store/user-achievements/user-achievements-slice';
import { updateShouldSeeTutorial } from '../../store/user-achievements/user-achievements-actions';
import { useDispatch } from 'react-redux';

const StarterTutorial = ({
    currTutorial,
    currSlide,
    handleChangeCurrTutorial,
    handleChangeCurrTutorialSlide,
    handleNewUser,
    setLoggedInUserId,
    loggedInUser,
    handleChangeBlinkCmp,
    toggleShouldSeeTutorial,
    handleCompleteAchievement
}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    // const [currTutorial, setCurrTutorial] = useState(0);
    // const [currSlide, setCurrSlide] = useState(0);

    const [goals, setGoals] = useState([]);
    const [heardFrom, setHeardFrom] = useState(null);
    const [investingExperience, setInvestingExperience] = useState(null);
    const [whySearch, setWhySearch] = useState([]);

    const location = useLocation();

    useEffect(() => {
        const handlePathChange = () => {
            if (location.pathname === '/login' || location.pathname === '/signup') {
                toggleShouldSeeTutorial(false);
            };
            if (currTutorial === 1 && currSlide === 5) {
                if (location.pathname === '/portfolio/companies') {
                    // setCurrTutorial(1);
                    handleChangeCurrTutorial(1);
                    // setCurrSlide(6);
                    handleChangeCurrTutorialSlide(6);
                };
            };
            if (currTutorial === 2 && currSlide === 1) {
                if (location.pathname === '/') {
                    // setCurrSlide(2);
                    handleChangeCurrTutorialSlide(2);
                };
            }
            // else if () {

            // }
        };
        handlePathChange();
    }, [location.pathname]);

    useEffect(() => {
        if (loggedInUser) {
            // handleChangeCurrTutorialSlide(1);
            // handleChangeCurrTutorial(1);
            dispatch(updateShouldSeeTutorial(true));
            history.push(`/profile/portfolios/${loggedInUser}`);
            toggleShouldSeeTutorial(false);
        };
    }, [loggedInUser]);


    const handleNextSlide = () => {
        if (currTutorial === 1 && currSlide === 3) {
            // setCurrSlide(4);
            handleChangeCurrTutorialSlide(4);
            history.push('/');
        } else if (currSlide === StarterTutorialContent[currTutorial].length - 1) {
            // If currSlide is the last slide of the current tutorial, move to the next tutorial
            // setCurrTutorial((prevTutorial) => prevTutorial + 1);
            handleChangeCurrTutorial((prev) => prev + 1);
            // setCurrSlide(0); // Reset currSlide to 0 for the next tutorial
            handleChangeCurrTutorialSlide(0);
        } else {
            // Otherwise, move to the next slide
            // setCurrSlide((prevSlide) => prevSlide + 1);
            handleChangeCurrTutorialSlide((prevSlide) => prevSlide + 1);
        }
    };

    const handlePrevSlide = () => {
        if (currSlide === 0) {
            if (currTutorial === 0) {
                // If currSlide is the first slide of the first tutorial, do nothing
                return;
            } else {
                // Move to the last slide of the previous tutorial
                // setCurrTutorial((prevTutorial) => prevTutorial - 1);
                handleChangeCurrTutorial((prevTutorial) => prevTutorial - 1);
                // setCurrSlide(StarterTutorialContent[currTutorial - 1].length - 1);
                handleChangeCurrTutorialSlide(StarterTutorialContent[currTutorial - 1].length - 1);
            }
        } else {
            // Move to the previous slide
            // setCurrSlide((prevSlide) => prevSlide - 1);
            handleChangeCurrTutorialSlide((prevSlide) => prevSlide - 1);
        }
    };

    const isBackButtonDisabled = () => {
        // Disable the "Back" button if it's the first slide of the first tutorial
        return currSlide === 0 && currTutorial === 0;
    };

    const isNextButtonDisabled = () => {
        // Disable the "Next" button if it's the last slide of the last tutorial
        if (currTutorial === 0 && currSlide === 2 && goals.length === 0) {
            return true;
        } else if (currTutorial === 0 && currSlide === 3 && !heardFrom) {
            return true;
        } else if (currTutorial === 0 && currSlide === 4 && !investingExperience) {
            return true;
        } else if (currTutorial === 0 && currSlide === 6 && whySearch.length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleBeginNow = () => {
        // setCurrSlide(0);
        handleChangeCurrTutorialSlide(0);
        // setCurrTutorial(1);
        handleChangeCurrTutorial(1);
    };

    const handleGetStartedClick = () => {
        // handleGetStartedClick logic...
        // setCurrSlide(1);
        // handleChangeCurrTutorialSlide(1);
        handleNextSlide();
    };

    const handleAlreadyHaveAccountClick = () => {
        history.push('/login');
    };

    const isNavigationVisible = () => {
        return (
            (currTutorial > 0 || currSlide > 0) &&
            !(currTutorial === 0 && currSlide === 9) &&
            !(currTutorial === 1 && (
                currSlide === 0 ||
                currSlide === 1 ||
                currSlide === 2 ||
                currSlide === 3 ||
                currSlide === 4 ||
                currSlide === 5 ||
                currSlide === 6
            )) &&
            !(currTutorial === 2 && (
                currSlide === 0 ||
                currSlide === 1 ||
                currSlide === 2 ||
                currSlide === 3 ||
                currSlide === 4 ||
                currSlide === 5 ||
                currSlide === 6 ||
                currSlide === 7
            )) &&
            !(currTutorial === 0 && (
                currSlide === 1 ||
                currSlide === 2 ||
                currSlide === 3 ||
                currSlide === 4 ||
                currSlide === 5 ||
                currSlide === 6 ||
                currSlide === 7 ||
                currSlide === 8
            ))
        );
    };

    {
        isNavigationVisible() && (
            <div className="flex space-between">
                <button className='btn btn-border' onClick={handlePrevSlide} disabled={isBackButtonDisabled()}>Back</button>
                <button className='btn btn-border' onClick={handleNextSlide} disabled={isNextButtonDisabled()}>Next</button>
            </div>
        )
    }

    const toggleGoals = (goal) => {
        const index = goals.indexOf(goal);
        if (index === -1) {
            setGoals([...goals, goal]);
        } else {
            const newGoals = [...goals];
            newGoals.splice(index, 1);
            setGoals(newGoals);
        }
    };

    const toggleHeardFrom = (selectedOption) => {
        setHeardFrom(selectedOption);
    };

    const toggleInvestingExperience = (selectedOption) => {
        setInvestingExperience(selectedOption);
    };

    const toggleWhySearch = (selectedOption) => {
        const index = whySearch.indexOf(selectedOption);
        if (index === -1) {
            setWhySearch([...whySearch, selectedOption]);
        } else {
            const newWhySearch = [...whySearch];
            newWhySearch.splice(index, 1);
            setWhySearch(newWhySearch);
        }
    };

    const startExerciseOne = () => {
        handleNextSlide();
        handleChangeBlinkCmp('copy-portfolio-btn');
    };

    const startExercise2 = () => {
        handleNextSlide();
        handleChangeBlinkCmp('notepad-blink');
    };

    const handlePortfolioNav = () => {
        history.push('/portfolio/companies');
        handleChangeBlinkCmp('portfolio-menu-blink');
        handleNextSlide();
    };

    const handleCloseTutorial = () => {
        handleChangeBlinkCmp(null);
        toggleShouldSeeTutorial(false);
        handleCompleteAchievement(
            {
                id: '1',
                title: 'Guidebook Mastery',
                tasks: ['Complete the tutorial journey', 'Visit all pages (Portfolio, Macro, Crypto and Calendar).'],
                status: 'Completed!',
                completed: 1,
            }
        );
    };

    const [isMinimized, setIsMinimized] = useState(false);
    const [hideTutorial, setHideTutorial] = useState(false);

    const handleMinimize = () => {
        setIsMinimized(true);
        setTimeout(() => {
            setHideTutorial(true);
        }, 5000);
    };

    const handleMaximize = () => {
        setIsMinimized(false);
        setHideTutorial(false);
    };

    return (
        <>
            {!isMinimized && !hideTutorial && (
                <div className={`starter-tutorial-container ${isMinimized ? 'minimized' : ''}`}>
                    {StarterTutorialContent[currTutorial][currSlide]({
                        handleGetStartedClick,
                        handleAlreadyHaveAccountClick,
                        goals,
                        toggleGoals,
                        heardFrom,
                        toggleHeardFrom,
                        investingExperience,
                        toggleInvestingExperience,
                        whySearch,
                        toggleWhySearch,
                        handleBeginNow,
                        setLoggedInUserId,
                        handleNewUser,
                        loggedInUser,
                        handleNextSlide,
                        startExerciseOne,
                        startExercise2,
                        handlePortfolioNav,
                        handleCloseTutorial,
                        handleMinimize,
                        handlePrevSlide,
                        toggleShouldSeeTutorial
                    })}
                    {isNavigationVisible() && (
                        <div className="starter-tutorial-pagination-container flex space-between">
                            <button className='btn btn-border' onClick={handlePrevSlide} disabled={isBackButtonDisabled()}>Back</button>
                            <button className='btn btn-border' onClick={handleNextSlide} disabled={isNextButtonDisabled()}>Next</button>
                        </div>
                    )}
                </div>
            )}
            {(isMinimized || hideTutorial) && (
                <div className="minimized-tutorial card-container" onClick={handleMaximize}>Tutorial</div>
            )}
        </>
    );

};

export default StarterTutorial;
