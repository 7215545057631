import React from "react";
import { followIcon, unfollowIcon } from "../../../shared/icons/icons";
import { useMediaQuery } from 'react-responsive';
import { useState, useEffect } from "react";

const CryptoTable = ({ tableData, handleFavoritesCrypto, userFollowing, loggedInUser }) => {

    const tableSize1 = useMediaQuery({ maxWidth: 1050 });
    const tableSize2 = useMediaQuery({ maxWidth: 930 });
    const tableSize3 = useMediaQuery({ maxWidth: 530 });
    const tableSize4 = useMediaQuery({ maxWidth: 450 });

    const [sortedTable, setSortedTable] = useState([]);

    useEffect(() => {
        const sortedTableData = tableData.map(item => {
          const volumeWithoutCommas = item.volume.replace(/,/g, ''); 
          const volumeNumber = Number(volumeWithoutCommas);
          return {
            ...item,
            volume: volumeNumber,
            originalVolume: item.volume,
          };
        }).sort((a, b) => b.volume - a.volume);
              const formattedTableData = sortedTableData.map(item => ({
          ...item,
          volume: item.originalVolume,
        }));
        setSortedTable(formattedTableData);
      }, [tableData]);
      
    const followEl = (
        <>
            {unfollowIcon}
        </>
    );

    const followingEl = (
        <>
            {followIcon}
        </>
    );

    return (
        <div class="findatasheets-table-container crypto-table card-container">
            <div class="findatasheets-table-header flex space-between">
                <div className="favorite-crypto" />
                <div class="findatasheets-table-cell crypto-table-cell findatasheets-table-title crypto-currency flex align-end">Currency</div>
                <div class="findatasheets-table-cell crypto-table-cell-small findatasheets-table-title flex align-end">Price</div>
                {
                    !tableSize1 &&
                    <div class="findatasheets-table-cell crypto-table-cell-small findatasheets-table-title flex align-end">Market Cap</div>
                }
                {
                    !tableSize2 &&
                    <div class="findatasheets-table-cell crypto-table-cell findatasheets-table-title flex align-end">Volume</div>
                }
                {
                    !tableSize4 &&
                    <div class="findatasheets-table-cell crypto-table-cell-small findatasheets-table-title flex align-end">Mean</div>
                }
                {
                    !tableSize3 &&
                    <div class="findatasheets-table-cell crypto-table-cell-small findatasheets-table-title flex align-end">Std. Dev.</div>
                }
                <div class="findatasheets-table-cell crypto-table-cell findatasheets-table-title flex align-end">Range</div>
            </div>

            {
                sortedTable.map((currency, idx) => (
                    <div className={`findatasheets-table-row flex space-between ${idx % 2 === 0 ? '' : 'odd-row'}`} key={idx}>
                        {loggedInUser ? (
                            <div
                                onClick={() => handleFavoritesCrypto(currency.symbol)}
                                className='favorite-crypto favorite-crypto-btn flex align-center'>
                                {userFollowing.includes(currency.symbol) ? followingEl : followEl}
                            </div>
                        ) : (
                            <div className="flex align-center" style={{ width: '30px' }}>
                                {idx + 1}
                            </div>
                        )
                        }
                        <div class="findatasheets-table-cell crypto-table-cell crypto-currency">{currency.currency}</div>
                        <div class="findatasheets-table-cell crypto-table-cell-small">{currency.price ? `${currency.price.charAt(0).toUpperCase()}${currency.price.slice(1)}` : "┅"}</div>
                        {
                            !tableSize1 &&
                            <div class="findatasheets-table-cell crypto-table-cell-small">{currency.marketCap ? `${currency.marketCap.charAt(0).toUpperCase()}${currency.marketCap.slice(1)}` : "┅"}</div>
                        }
                        {
                            !tableSize2 &&
                            <div class="findatasheets-table-cell crypto-table-cell">{currency.volume ? `${currency.volume.charAt(0).toUpperCase()}${currency.volume.slice(1)}` : "┅"}</div>
                        }
                        {
                            !tableSize4 &&
                            <div class="findatasheets-table-cell crypto-table-cell-small">{currency.mean ? `${currency.mean.charAt(0).toUpperCase()}${currency.mean.slice(1)}` : "┅"}</div>
                        }
                        {
                            !tableSize3 &&
                            <div class="findatasheets-table-cell crypto-table-cell-small">{currency.std ? `${currency.std.charAt(0).toUpperCase()}${currency.std.slice(1)}` : "┅"}</div>
                        }
                        <div class="findatasheets-table-cell crypto-table-cell">{currency.range ? `${currency.range.charAt(0).toUpperCase()}${currency.range.slice(1)}` : "┅"}</div>
                    </div>
                ))
            }

        </div>
    );
}

export default CryptoTable;