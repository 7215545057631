import React, { useState } from "react";
import userPool from "./user-pool";
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useHistory, useLocation } from 'react-router-dom';
import './user-pool.scss';
import { Helmet } from "react-helmet";
import { homeIcon, loginIcon, signupIcon } from "../shared/icons/icons";
import { useMediaQuery } from 'react-responsive';
import ActivePageSelection from '../shared/active-page/active-page';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { getRandomId } from "../shared/global-color-functions";
import { setPortfollowUser } from "../shared/actions/homepage-actions";

const Signup = ({
    setLoggedInUser,
    handleNewUser,
    setLoggedInUserId,
    isTutorialSignup = false,
    userForm = ''
}) => {

    const isMobile = useMediaQuery({ maxWidth: 920 });

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();
    const location = useLocation();

    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const [activePage, setActivePage] = useState('signup');

    const [isGoogleSignup, setIsGoogleSignup] = useState(false);
    const [googleUser, setGoogleUser] = useState(false);

    const handleToggleOption = (page) => {
        if (page === 'login') {
            navigateToLogin();
        } else if (page === 'home') {
            navigateToHome();
        }
    };

    const specialCharPattern = /[^a-zA-Z0-9]/;

    const onSubmit = async (event) => {
        if (googleUser) return;
        event.preventDefault();
        if (specialCharPattern.test(username)) {
            setErrorMsg('Username can only contain letters and numbers.');
            setIsError(true);
        } else {
            setErrorMsg('');
            setIsError(false);
        }
        try {
            const id = getRandomId();
            const res = await setPortfollowUser({ username, userid: id, email, userForm });
            if (res.isError === 'false' && errorMsg === '') {
                const attributeList = [
                    new CognitoUserAttribute({ Name: 'email', Value: email }),
                ];
                userPool.signUp(username, password, attributeList, null, (err, data) => {
                    if (err) {
                        let errMsg = '';
                        if (err.code === 'UsernameExistsException') errMsg = 'Username already taken.';
                        else if (err.code === 'InvalidPasswordException') errMsg = 'Password must be a minimum of 6 characters and contain at least 1 number and 1 lowercase letter.';
                        else if (err.code === 'InvalidParameterException') errMsg = 'Password must be a minimum of 6 characters and contain at least 1 number and 1 lowercase letter.';
                        setErrorMsg(errMsg);
                        setIsError(true);
                    } else {
                        handleNewUser(username);
                        history.push('/verification');
                    }
                });
            } else {
                setErrorMsg(res.msg);
                setIsError(true);
            }
        } catch (error) {
            console.error('Error during signup username validation:', error);
        }
    };

    const navigateToHome = () => {
        history.push('/');
    };

    const navigateToLogin = () => {
        history.push('/login');
    };

    const pages = ['home', 'login', 'signup'];

    const onGoogleSignup = async (decodedUser) => {
        setGoogleUser(decodedUser);
        setIsGoogleSignup(true);
        // const userid = getRandomId();
        // const { email, email_verified, family_name, given_name, locale, name, picture } = decodedUser;
        // const res = await setPortfollowUser({ username, userid, email, email_verified, family_name, given_name, locale, name, picture });
        // console.log('res:', res);
        // setLoggedInUserId(userid);
        // handleNewUser();
    };

    const onGoogleSubmit = async (event) => {
        event.preventDefault();
        try {
            const id = getRandomId();
            setLoggedInUserId(id);
            const { email, email_verified, family_name, given_name, locale, name, picture } = googleUser;
            const res = await setPortfollowUser({ username, userid: id, email, email_verified, family_name, given_name, locale, name, picture, userForm });
            if (res.isError === 'false') {
                handleNewUser(username);
                history.push('/');
            } else {
                setErrorMsg(res.msg);
                setIsError(true);
            }
        } catch (error) {
            console.error('Error during signup username validation:', error);
        }
    };

    const backToSignup = () => {
        setUsername('');
        setGoogleUser(null);
        setIsGoogleSignup(false);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow - Login</title>
                <meta name="description" content="Share & follow success stories in the capital market, where financial performance isn't solely determined by wealth. Enhance your portfolio management by analyzing economic trends and adapting to the dynamics of the financial market." />
                <link rel="canonical" href="https://www.portfollow.com/signup" />
            </Helmet>

            {
                !isTutorialSignup &&
                <div className="portfolio-pages-container mb20">
                    {
                        pages &&
                        <ActivePageSelection
                            handleToggleOption={handleToggleOption}
                            pages={pages}
                            activePage={activePage}
                        />
                    }
                </div>
            }

            {
                !isGoogleSignup ? (<div
                    className="user-auth-container card-container flex column align-center"
                    style={{ width: isTutorialSignup ? '100%' : '600px' }}
                >

                    {
                        !isTutorialSignup &&
                        <h1 className="mb20">Signup</h1>
                    }

                    <div className="mb20">
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                const decoded = jwtDecode(credentialResponse?.credential);
                                onGoogleSignup(decoded);
                            }}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                        />
                    </div>

                    <p>or</p>

                    <h2 className="mb20">Create Account</h2>

                    <form className="user-pool-form flex column space-between" onSubmit={onSubmit}>
                        <input
                            className="user-pool-input"
                            type="text"
                            value={username}
                            onChange={(event) => setUsername(event.target.value)}
                            placeholder='Username'
                        />
                        <input
                            className="user-pool-input"
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            placeholder='Email'
                        />
                        <input
                            className="user-pool-input"
                            type="password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            placeholder='Password'
                        />
                        <div style={{ textAlign: 'center' }}>
                            {isError && <p className="err-msg">{errorMsg}</p>}
                        </div>
                        <div className="flex justify-center">
                            {/* <button className="user-pool-btn btn" type='submit'>Signup</button> */}
                            <button
                                className="user-pool-btn btn btn-border"
                                disabled={!email || !username || !password || password.length < 6}
                                type='submit'
                            >Signup
                            </button>
                        </div>
                    </form>
                </div>) : (
                    <div
                        className="user-auth-container card-container flex column align-center"
                        style={{ width: isTutorialSignup ? '100%' : '600px' }}
                    >
                        {/* <h1 className="mb20">Signup</h1> */}
                        <h2 className="mb20">Welcome, {googleUser.given_name}! </h2>
                        <p>Please select a username:</p>
                        <form className="user-pool-form flex column space-between" onSubmit={onGoogleSubmit}>

                            <input
                                className="user-pool-input"
                                type="text"
                                value={username}
                                onChange={(event) => setUsername(event.target.value)}
                                placeholder='Username'
                            />

                            <div style={{ textAlign: 'center' }}>
                                {isError && <p className="err-msg">{errorMsg}</p>}
                            </div>
                            <div className="flex justify-center">
                                {/* <button className="user-pool-btn btn" type='submit'>Signup</button> */}
                                <button className="user-pool-btn btn btn-border" onClick={() => { backToSignup() }} type='button'>Back</button>
                                <button className="user-pool-btn btn btn-border" disabled={!username || username === ''} type='submit'>Login</button>
                            </div>
                        </form>


                    </div>
                )
            }

            <div className="" style={{ display: 'none' }}>

                <div className="flex user-auth-container-flex">
                    <div className="user-auth-border flex column space-between">
                        <div onClick={() => navigateToHome()} className="user-auth-navbox flex column align-center">
                            <div className="user-auth-navbox-icon flex align-center">{homeIcon}</div>
                            <div className="user-auth-navbox-item">Home</div>
                        </div>
                        {/* <div className={`user-auth-navbox flex column align-center ${location.pathname === '/signup' ? 'user-auth-navbox-selected' : ''}`}> */}
                        <div onClick={() => navigateToLogin()} className="user-auth-navbox flex column align-center space-between">
                            <div className="user-auth-navbox-icon flex align-center">{loginIcon}</div>
                            <div className="user-auth-navbox-item">Login</div>
                        </div>
                        <div className={`user-auth-navbox flex column align-center ${location.pathname === '/signup' ? 'user-auth-navbox-selected' : ''}`}>
                            <div className="user-auth-navbox-icon flex align-center">{signupIcon}</div>
                            <div className="user-auth-navbox-item">Signup</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Signup;