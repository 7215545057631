import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Homepage from './pages/homepage/homepage';
import ErrorPage from './pages/error-page/error-page';
import Signup from './user-pool/signup';
import Login from './user-pool/login';
import { Account } from './user-pool/account';
import EmailVerification from './user-pool/email-vertification';
import AppHeader from './cmps/app-header/app-header';
// import PageGuide from './cmps/page-guide';
import AboutUs from './pages/about-us/about-us';
import SingleCompany from './pages/single-company/single-company';
import SingleIndex from './pages/single-pages/single-index/single-index';
import SingleCrypto from './pages/single-pages/single-crypto/single-crypto';
import SingleCommodity from './pages/single-pages/single-commodity/single-commodity';
import SingleCurrency from './pages/single-pages/single-currency/single-currency';
import Crypto from './pages/crypto/crypto';
import Tutorial from './pages/tutorial/tutorial';
import Macro from './pages/macro/macro-page';
import UserProfile from './pages/user-profile/user-profile';
import SingleSector from './pages/single-pages/single-sector/single-sector';
import TermsOfService from './pages/terms-of-service/terms-of-service';
import PrivacyNotice from './pages/privacy-notice/privacy-notice';
import ScrollToTop from './cmps/scroll-top';
import Portfolio from './pages/portfolio/portfolio';
import { useDispatch, useSelector } from 'react-redux';
import { updatePortfolio } from './shared/actions/portfolio-actions';
import { setGlobalIsLoading } from './store/loading/loading-actions';
import HeaderPages from './cmps/app-header/header-pages/header-pages';
import Calendar from './pages/calendar/calendar';
import NeedUser from './cmps/need-user/need-user';
import { checkChallengeCompletion, setFeedback, setLikeCommentCopyShare, setNewPost } from './shared/actions/homepage-actions';
import { convertDateToReadableStr, getRandomId } from './shared/global-color-functions';
import FeedbackModalBtn from './cmps/feedback-modal/feedback-modal-btn';
import Notepad from './cmps/notepad/notepad';
import FeedbackModal from './cmps/feedback-modal/feedback-modal';
import AppTutorial from './cmps/app-tutorial/app-tutorial';
import PortfolioFundamental from './pages/portfolio/pages/fundamental';
import PortfolioCompanies from './pages/portfolio/pages/companies';
import PortfolioSectors from './pages/portfolio/pages/sectors';
import PortfolioDescriptive from './pages/portfolio/pages/descriptive';
import PortfolioSensitivity from './pages/portfolio/pages/sensitivity';
import StarterTutorial from './shared/starter-tutorial/starter-turtorial';
import Funds from './pages/funds/funds/funds';
import SingleFund from './pages/funds/single-fund/single-fund';
import FAQ from './pages/q&a/q&a';
import AchievementsTutorial from './cmps/achievements-tutorial/achievements-tutorial';
import { setGlobalUser } from './store/user/user-actions';
import { setGlobalLastPrices } from './store/last-prices/last-prices-actions';
import { useMediaQuery } from 'react-responsive';
import { setPrevScores } from './store/prev-scores/prev-scores-actions';

// import PortfolioDescriptive from './pages/portfolio/portfolio-pages/portfolio-descriptive';

// import AppFooter from './cmps/app-footer/app-footer';

function App() {

  const isMobile = useMediaQuery({ maxWidth: 650 });

  const [loggedInUser, setLoggedInUser] = useState(null);
  const [loggedInUserId, setLoggedInUserId] = useState(null);
  const [userFollowing, setUserFollowing] = useState({
    companies: [],
    sectors: [],
    users: [],
    crypto: [],
    commodities: [],
    indices: [],
    currencies: []
  });
  const [userAbout, setUserAbout] = useState('');
  const [userChallenges, setUserChallenges] = useState({});
  // const [userAchievements, setUserAchievements] = useState({});
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const userAchievements = useSelector((state) => state.userAchievements);

  const dispatch = useDispatch();

  let debounceTimeout;

  useEffect(() => {
    const restoreUserSession = (setLoggedInUser) => {
      const idToken = localStorage.getItem('idToken');
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      const userData = localStorage.getItem('userData');

      if (idToken && accessToken && refreshToken) {
        const userAttributes = JSON.parse(atob(idToken.split('.')[1]));
        setLoggedInUser(userAttributes['cognito:username']);
      } else if (userData) {
        const { username, userId, selectedPortfolio, portfolio } = JSON.parse(userData);
        setLoggedInUser(username);
        setLoggedInUserId(userId);
        setMySelectedPortfolio(selectedPortfolio);
        setMyPortfolio(portfolio);
      } else {
        setShouldSeeTutorial(true);
      }
    };
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Call restoreUserSession when your application loads
    restoreUserSession(setLoggedInUser);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  useEffect(() => {
    if (loggedInUser) {
      setShowTutorial(false);
      fetchUserData(loggedInUser);
      localStorage.setItem('userData', JSON.stringify({
        username: loggedInUser,
        userId: loggedInUserId,
        selectedPortfolio: mySelectedPortfolio,
        portfolio: myPortfolio
      }));
    } else {
      setUserFollowing({
        companies: [],
        sectors: [],
        users: [],
        crypto: [],
        commodities: [],
        indices: [],
        currencies: []
      })
    }
  }, [loggedInUser]);

  const parseData = (data) => {
    if (typeof data === 'string') {
      try {
        return JSON.parse(data.replace(/'/g, '"'));
      } catch (error) {
        return data;
      }
    } else if (typeof data === 'object') {
      for (const key in data) {
        data[key] = parseData(data[key]);
      }
    }
    return data;
  };

  function fetchUserData(username) {
    const apiUrl = 'https://4s8f188hhc.execute-api.us-east-1.amazonaws.com/get-user-data';
    const requestBody = JSON.stringify({ username: username });
    var requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: requestBody,
      redirect: 'follow'
    };
    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          // throw new Error('Network response was not ok');
        }
      })

      .then((result) => {

        const parsedResult = JSON.parse(result);
        const recursivelyParseData = (data) => {
          for (const key in data) {
            if (typeof data[key] === 'string') {
              try {
                // data[key] = JSON.parse(data[key]);
                data[key] = JSON.parse(data[key].replace(/'/g, "\""));
              } catch (error) {
                // console.log(`error parsing ${key}:`, error);
              }
            } else if (typeof data[key] === 'object') {
              recursivelyParseData(data[key]);
            }
          }
        };


        recursivelyParseData(parsedResult);
        dispatch(setGlobalUser(parsedResult));
        if (parsedResult.userid) {
          setLoggedInUserId(parsedResult.userid);
        }

        if (parsedResult.userid) {
          setLoggedInUserId(parsedResult.userid);
        }

        if (parsedResult.about) {
          setUserAbout(parsedResult.about);
        }

        // if (parsedResult.achievements) {
        //   setUserAchievements(parsedResult.achievements);
        // }

        if (parsedResult.challenges) {
          setUserChallenges(parsedResult.challenges);
        }
        if (parsedResult.portfolio) {
          setMyPortfolio(parsedResult.portfolio);
          setShouldSeeTutorial(false);
        } else {
          setMyPortfolio({
            portfolioA: {
              portfolioKey: 'portfolioA',
              portfolioName: 'portfolioA',
              portfolioData: [],
              portfolioScores: { awarness: 0, harmony: 0, hierarchy: 0, precision: 0, resilience: 0 }
            },
            portfolioB: {
              portfolioKey: 'portfolioB',
              portfolioName: 'portfolioB',
              portfolioData: [],
              portfolioScores: { awarness: 0, harmony: 0, hierarchy: 0, precision: 0, resilience: 0 }
            },
            portfolioC: {
              portfolioKey: 'portfolioC',
              portfolioName: 'portfolioC',
              portfolioData: [],
              portfolioScores: { awarness: 0, harmony: 0, hierarchy: 0, precision: 0, resilience: 0 }
            },
            portfolioD: {
              portfolioKey: 'portfolioD',
              portfolioName: 'portfolioD',
              portfolioData: [],
              portfolioScores: { awarness: 0, harmony: 0, hierarchy: 0, precision: 0, resilience: 0 }
            },
            portfolioE: {
              portfolioKey: 'portfolioE',
              portfolioName: 'portfolioE',
              portfolioData: [],
              portfolioScores: { awarness: 0, harmony: 0, hierarchy: 0, precision: 0, resilience: 0 }
            }
          });
        };
        if (parsedResult.selectedPortfolio) {
          setMySelectedPortfolio(parsedResult.selectedPortfolio)
        } else {
          setMySelectedPortfolio('portfolioA');
        };
        if (parsedResult.following) {
          setUserFollowing(parsedResult.following);
        };
      })
      .catch((error) => {
        console.log('Error:', error);
        setUserAbout('');
        setMySelectedPortfolio('portfolioA');
        setMyPortfolio({
          portfolioA: {
            portfolioName: '',
            portfolioData: [],
            portfolioScores: { awarness: 0, harmony: 0, hierarchy: 0, precision: 0, resilience: 0 }
          },
          portfolioB: {
            portfolioName: '',
            portfolioData: [],
            portfolioScores: { awarness: 0, harmony: 0, hierarchy: 0, precision: 0, resilience: 0 }
          },
          portfolioC: {
            portfolioName: '',
            portfolioData: [],
            portfolioScores: { awarness: 0, harmony: 0, hierarchy: 0, precision: 0, resilience: 0 }
          },
          portfolioD: {
            portfolioName: '',
            portfolioData: [],
            portfolioScores: { awarness: 0, harmony: 0, hierarchy: 0, precision: 0, resilience: 0 }
          },
          portfolioE: {
            portfolioName: '',
            portfolioData: [],
            portfolioScores: { awarness: 0, harmony: 0, hierarchy: 0, precision: 0, resilience: 0 }
          }
        });
        setUserFollowing({
          companies: [],
          sectors: [],
          users: [],
          crypto: [],
          commodities: [],
          indices: [],
          currencies: []
        });
      });
  };

  async function updateFollowing(updatedFollowing) {
    if (!loggedInUser) {
      console.error('Missing username.');
      return;
    }

    try {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      // Create the request body with the updated userFollowing object
      const requestBody = {
        username: loggedInUser,
        following: updatedFollowing,
      };

      const requestBodyString = JSON.stringify(requestBody);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: requestBodyString,
      };

      // Send a request to your API endpoint to save the updated userFollowing
      const response = await fetch(
        'https://6y6jwv0cze.execute-api.us-east-1.amazonaws.com/user/following',
        requestOptions
      );

      if (!response.ok) {
        console.log('response:', response);
      } else {
        const result = await response.text();
        const data = JSON.parse(result);
        return data;
      }
    } catch (error) {
      // Log the error
      console.error('Error updating following:', error);
      throw error;
    }
  };

  const toggleFollowing = (keyName, symbol) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(async () => {
      try {
        // Check if userFollowing[keyName] is defined and an array before using includes
        const isFollowing = userFollowing[keyName]?.includes(symbol);
        let newFollowing = { ...userFollowing };

        if (isFollowing) {
          newFollowing[keyName] = newFollowing[keyName]?.filter((item) => item !== symbol) || [];
        } else {
          newFollowing[keyName] = [...(newFollowing[keyName] || []), symbol];
        }
        setUserFollowing(newFollowing);
        // Save the updated userFollowing in DynamoDB using your updateFollowing function
        await updateFollowing(newFollowing);
      } catch (error) {
        console.error('Error updating following:', error);
      }
    }, 1000);
  };

  // single items:
  const [singleCompanies, setSingleCompanies] = useState({});
  const [singleSectors, setSingleSectors] = useState({});
  const [singleIndices, setSingleIndices] = useState({});
  const [singleCryptoCurrencies, setSingleCryptoCurrencies] = useState({});
  const [singleCurrencies, setSingleCurrencies] = useState({});
  const [singleCommodities, setSingleCommodities] = useState({});

  // pages
  //portfolio page data:
  const [mySelectedPortfolio, setMySelectedPortfolio] = useState('portfolioA');
  const [myPortfolio, setMyPortfolio] = useState({});
  const [portfolioCompanyData, setPortfolioCompanyData] = useState(null);
  const [portfolioSectorData, setPortfolioSectorData] = useState(null);
  const [portfolioFundamentalData, setPortfolioFundamentalData] = useState(null);
  const [portfolioDescData, setPortfolioDescData] = useState(null);
  const [portfolioSensData, setPortfolioSensData] = useState(null);

  const handleChangeSelectedPortfolio = (newSelectedPortfolio, newPortfolioItem) => {
    // setPortfolioCompanyData(null);
    // setPortfolioSectorData(null);
    // setPortfolioFundamentalData(null);
    // setPortfolioDescData(null);
    // setPortfolioSensData(null);

    // setPortfolioFilters(null);
    // setPortfolioFullCompanyOptions(null);
    // setPortfolioCompanyOptions(null);
    // setPortfolioActiveCompanyOptions(null);
    // setPortfolioSectorOptions(null);
    // setPortfolioActiveSectorOptions(null);

    resetPortfolioData();

    setMySelectedPortfolio(newSelectedPortfolio);

    if (myPortfolio && !myPortfolio[newSelectedPortfolio]) {
      setMyPortfolio((prevPortfolio) => ({
        ...prevPortfolio,
        [newSelectedPortfolio]: newPortfolioItem,
      }));
    }
  };

  // homepage
  const [homepageData, setHomepageData] = useState(null);
  const [postsToDisplay, setPostsToDisplay] = useState([]);
  const [seenPostsIds, setSeenPostsIds] = useState([]);
  const [homepageTrending, setHomepageTrending] = useState(null);

  const handleChangeHomepageTrending = (trendingData) => {
    setHomepageTrending(trendingData);
  };

  // profile
  const [userProfileData, setUserProfileData] = useState(null);
  const [challengeInProcess, setChallengeInProcess] = useState(null);
  const handleSetUserProfileData = (data) => {
    setUserProfileData(data);
  };

  const handleChangeChallengeInProcess = (newChallenge) => {
    setChallengeInProcess((prevChallenge) =>
      prevChallenge === newChallenge ? null : newChallenge
    );
  };

  const handleChangeHomepageData = (data) => {
    setHomepageData(data);
  };

  const handleChangeMySelectedPortfolio = (portfolio) => {
    setMySelectedPortfolio(portfolio);
  };

  // calendar
  const [calendarData, setCalendarData] = useState(null);
  const handleChangeCalendarData = (data) => {
    setCalendarData(data);
  };

  const [pageMacro, setPageMacro] = useState({});
  const [pageCrypto, setPageCrypto] = useState({});

  const addStock = (stock) => {
    // const updatedPortfolio = { ...myPortfolio };
    const updatedPortfolio = JSON.parse(JSON.stringify(myPortfolio));
    updatedPortfolio[mySelectedPortfolio].portfolioData.push(stock);
    dispatch(setPrevScores(updatedPortfolio[mySelectedPortfolio].portfolioScores));
    setMyPortfolio(updatedPortfolio);
    if (!loggedInUser || !mySelectedPortfolio) return;
    dispatch(setGlobalIsLoading(true));
    const saveUpdatedPortfolio = async () => {
      try {
        await updatePortfolio(loggedInUser, mySelectedPortfolio, updatedPortfolio[mySelectedPortfolio].portfolioData);
        fetchUserData(loggedInUser);
      } catch (error) {
        console.error('Error updating portfolio:', error);
      }
    };
    saveUpdatedPortfolio();
    resetPortfolioData();
  };

  const addStocks = (stocks) => {
    const updatedPortfolio = JSON.parse(JSON.stringify(myPortfolio));
    updatedPortfolio[mySelectedPortfolio].portfolioData = stocks;
    dispatch(setPrevScores(updatedPortfolio[mySelectedPortfolio].portfolioScores));
    setMyPortfolio(updatedPortfolio);
    if (!loggedInUser || !mySelectedPortfolio) return;
    dispatch(setGlobalIsLoading(true));
    const saveUpdatedPortfolio = async () => {
      try {
        await updatePortfolio(loggedInUser, mySelectedPortfolio, updatedPortfolio[mySelectedPortfolio].portfolioData);
        fetchUserData(loggedInUser);
      } catch (error) {
        console.error('Error updating portfolio:', error);
      }
    };
    saveUpdatedPortfolio();
    resetPortfolioData();
  };

  const addFundStocks = (stocks) => {
    const updatedPortfolio = { ...myPortfolio };
    updatedPortfolio['portfolioE'].portfolioData = stocks;
    setMyPortfolio(updatedPortfolio);
    if (!loggedInUser || !'portfolioE') return;
    dispatch(setGlobalIsLoading(true));
    const saveUpdatedPortfolio = async () => {
      try {
        await updatePortfolio(loggedInUser, 'portfolioE', updatedPortfolio['portfolioE'].portfolioData);
        fetchUserData(loggedInUser);
      } catch (error) {
        console.error('Error updating portfolio:', error);
      }
    };
    saveUpdatedPortfolio();
    resetPortfolioData();
  };


  const handleCopyPortfolio = (portfolio, orders) => {
    const updatedPortfolio = { ...myPortfolio };
    if (!updatedPortfolio[portfolio]) updatedPortfolio[portfolio] = [];
    updatedPortfolio[portfolio].portfolioData = orders;
    setMyPortfolio(updatedPortfolio);
    setMySelectedPortfolio(portfolio);
    if (!loggedInUser || !portfolio) return;
    dispatch(setGlobalIsLoading(true));
    const saveUpdatedPortfolio = async () => {
      try {
        await updatePortfolio(loggedInUser, portfolio, updatedPortfolio[portfolio].portfolioData);
      } catch (error) {
        console.error('Error updating portfolio:', error);
      }
    };
    saveUpdatedPortfolio();
    resetPortfolioData();
  };

  const handleChangePortfolioCompaniesData = (data) => {
    setPortfolioCompanyData(data);
  };
  const handleChangePortfolioSectorsData = (data) => {
    setPortfolioSectorData(data);
  };
  const handleChangePortfolioFundamentalData = (data) => {
    setPortfolioFundamentalData(data);
  };
  const handleChangePortfolioDescData = (data) => {
    setPortfolioDescData(data);
  };
  const handleChangePortfolioSensData = (data) => {
    setPortfolioSensData(data);
  };

  const resetPortfolioData = () => {

    setPortfolioCompanyData(null);
    setPortfolioSectorData(null);
    setPortfolioFundamentalData(null);
    setPortfolioDescData(null);
    setPortfolioSensData(null);

    setPortfolioFilters(null);
    setPortfolioFullCompanyOptions(null);
    setPortfolioCompanyOptions(null);
    setPortfolioActiveCompanyOptions(null);
    setPortfolioSectorOptions(null);
    setPortfolioActiveSectorOptions(null);
  };

  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const handleSideMenuChange = (status) => {
    // setIsSideMenuOpen(status);
    setIsSideMenuOpen(false);
  }

  const onLogout = () => {
    setLoggedInUser(null);
    setLoggedInUserId(null);
    setUserAbout('');
    setMySelectedPortfolio('portfolioA');
    setMyPortfolio({
      portfolioA: {
        portfolioName: '',
        portfolioData: [],
        portfolioScores: { awarness: 0, harmony: 0, hierarchy: 0, precision: 0, resilience: 0 }
      },
      portfolioB: {
        portfolioName: '',
        portfolioData: [],
        portfolioScores: { awarness: 0, harmony: 0, hierarchy: 0, precision: 0, resilience: 0 }
      },
      portfolioC: {
        portfolioName: '',
        portfolioData: [],
        portfolioScores: { awarness: 0, harmony: 0, hierarchy: 0, precision: 0, resilience: 0 }
      },
      portfolioD: {
        portfolioName: '',
        portfolioData: [],
        portfolioScores: { awarness: 0, harmony: 0, hierarchy: 0, precision: 0, resilience: 0 }
      },
      portfolioE: {
        portfolioName: '',
        portfolioData: [],
        portfolioScores: { awarness: 0, harmony: 0, hierarchy: 0, precision: 0, resilience: 0 }
      }
    });
    setUserFollowing({
      companies: [],
      sectors: [],
      users: [],
      crypto: [],
      commodities: [],
      indices: [],
      currencies: []
    });
  };

  const handleChangePostsToDisplay = (posts) => {
    setPostsToDisplay(prevPosts => {
      const updatedPosts = [...prevPosts, ...posts];
      return updatedPosts;
    });
  };

  const handleChangeSeenPostsIds = (postIds) => {
    setSeenPostsIds(prevIds => {
      const updatedIds = [...prevIds, ...postIds];
      return updatedIds;
    });
  };

  // homepage 
  const handlePostAction = (postId, action, comment) => {
    const currentDate = new Date();
    const isoDateString = currentDate.toISOString();
    const updatedPosts = postsToDisplay.map((post) => {
      if (post.postid === postId) {
        if (action === 'like') {
          const existingLikeIndex = post.likes.findIndex((like) => like.username === loggedInUser);
          if (existingLikeIndex !== -1) {
            post.likes.splice(existingLikeIndex, 1);
          } else {
            post.likes.push({ image: '', username: loggedInUser });
          }
        } else if (action === 'comment') {
          const newComment = {
            comment: comment,
            likes: [],
            userid: loggedInUserId,
            username: loggedInUser,
            time: isoDateString
          };
          post.comments.push(newComment);
        }
      }
      return post;
    });

    setPostsToDisplay(updatedPosts);

    async function ExecutePostAction() {
      try {
        const res = await setLikeCommentCopyShare(loggedInUser, loggedInUserId, postId, action, comment);
        if (res) {
          // handle res if needed
        } else {
          console.log('ERROR - post action failed');
        }
      } catch (error) {
        console.log('POST ACTION ERROR - ', error);
        // handleNavigateToError();
      }
    }
    ExecutePostAction();
  };

  const handleNewUser = (username) => {
    setLoggedInUser(username);
  };

  const [isPosting, setIsPosting] = useState(false);

  const handleCreatePost = async (postText, graphs = {}) => {
    setIsPosting(true);
    const currentDate = new Date();
    const isoDateString = currentDate.toISOString();
    const postId = getRandomId();
    const orders = myPortfolio[mySelectedPortfolio].portfolioData;
    const fixedOrders = Object.keys(graphs).length > 0 ? orders : [];
    const savedPost = await setNewPost(postId, loggedInUser, loggedInUserId, postText, mySelectedPortfolio, fixedOrders, graphs, isoDateString);
    setPostsToDisplay((prevPosts) => [savedPost, ...prevPosts]);
    setIsPosting(false);
  };

  const [companiesLastPrices, setCompaniesLastPrices] = useState({});

  const handleSetCompaniesLastPrices = (lastPrices) => {
    dispatch(setGlobalLastPrices(lastPrices));
  };

  const handleCompleteAchievement = (achievement) => {
    //   if (!userAchievements[achievement.id]) {
    //     setUserAchievements(prevAchievements => {
    //       const newUserAchievements = { ...prevAchievements };
    //       newUserAchievements[achievement.id] = {
    //         ...achievement,
    //         completed: 1
    //       };
    //       return newUserAchievements;
    //     });
    //     setCompletedAchievement(achievement.id);
    //   }
  };

  const handleCopyPortfolioFromFeed = (postId, copies) => {
    const copiesNumber = +copies;
    const postIndex = postsToDisplay.findIndex(post => post.postid === postId);
    if (postIndex !== -1) {
      const updatedPost = { ...postsToDisplay[postIndex] };
      updatedPost.copies = copiesNumber + 1;
      const updatedPostsToDisplay = [...postsToDisplay];
      updatedPostsToDisplay[postIndex] = updatedPost;
      setPostsToDisplay(updatedPostsToDisplay);
      setLikeCommentCopyShare(loggedInUser, loggedInUserId, postId, 'copy', '');
      // if (!userAchievements['4']) {
      //   setUserAchievements(prevAchievements => {
      //     const newUserAchievements = { ...prevAchievements };
      //     newUserAchievements['4'] = {
      //       id: '4',
      //       title: 'Portfolio Replication',
      //       tasks: ['Copy a portfolio from the feed.', 'Copy 25 portfolios from the feed.'],
      //       status: '1/1',
      //       completed: 1
      //     };
      //     return newUserAchievements;
      //   });
      //   setCompletedAchievement(4);
      // };
    } else {
      console.error(`Post with postId ${postId} not found.`);
    }
  };

  const lastPrices = useSelector((state) => state.lastPrices);


  const calcPortfolioReturn = (orders) => {
    const { sumPrice, sumLastPrice, oldestBuyDate } = orders.reduce(
      (accumulator, order) => {
        const amount = +order.amount;
        const price = +order.price;
        const lastPrice = parseFloat(lastPrices[`${order.symbol}=company`].lastPrice);
        const buyDate = new Date(order.buyDate);

        if (!isNaN(amount) && !isNaN(price) && !isNaN(lastPrice)) {
          accumulator.sumPrice += amount * price;
          accumulator.sumLastPrice += amount * lastPrice;

          // Update oldestBuyDate if the current buyDate is older
          if (!accumulator.oldestBuyDate || buyDate < accumulator.oldestBuyDate) {
            accumulator.oldestBuyDate = buyDate;
          }
        } else {
          // console.error(`Invalid numeric value in order for symbol ${order.symbol}`);
        }

        return accumulator;
      },
      { sumPrice: 0, sumLastPrice: 0, oldestBuyDate: null }
    );

    const returnRes = ((sumLastPrice / sumPrice) - 1) * 100;

    const oldestBuyDateDaysAgo = oldestBuyDate
      ? Math.floor((new Date() - oldestBuyDate) / (1000 * 60 * 60 * 24))
      : null;

    const res = {
      returnData: +returnRes.toFixed(1),
      days_difference: oldestBuyDateDaysAgo
    };

    return res;
  };

  const [isShowFeedbackModal, setIsShowFeedbackModal] = useState(true);
  const [isFeedBackModalOpen, setIsShowFeedbackModalOpen] = useState(false);

  const dismissFeedbackModal = () => {
    setIsShowFeedbackModal(false);
  };

  const openFeedbackModal = () => {
    setIsShowFeedbackModalOpen(true);
  };

  const closeFeedbackModal = () => {
    setIsShowFeedbackModalOpen(false);
    dismissFeedbackModal();
  };

  const onSendFeedback = async (name, email, feedback) => {
    try {
      await setFeedback({
        loggedInUser,
        name,
        email,
        feedback,
      });
      closeFeedbackModal();
    } catch (error) {
      console.log('Feedback failed', error);
      closeFeedbackModal();
    }
  };

  const [showTutorial, setShowTutorial] = useState(true);

  const handleCloseTutorial = () => {
    setShowTutorial(false);
  };

  const startPortfolioTutorial = () => {

    const defaultPortfolio = {
      portfolioA: {
        portfolioName: "My 1st portfolio",
        portfolioScores: {
          harmony: 4.3,
          awarness: 17.1,
          resilience: 5.1,
          hierarchy: 4,
          precision: 17.6
        },
        portfolioData: [
          {
            symbol: "LULU",
            amount: 2,
            buyDate: "2023-08-08",
            price: 375.28,
            orderId: "VyVvLI9mbg"
          },
          {
            symbol: "NVDA",
            amount: 10,
            buyDate: "2023-05-18",
            price: 315.35,
            orderId: "IyeLVnXFAn"
          },
          {
            symbol: "AMZN",
            amount: 14,
            buyDate: "2023-04-10",
            price: 100,
            orderId: "pXfujlyQsb"
          },
          {
            symbol: "TSLA",
            amount: 5,
            buyDate: "2023-12-01",
            price: 235.65,
            orderId: "0Rb6B2hwTl"
          },
          {
            symbol: "AAPL",
            amount: 20,
            buyDate: "2023-03-08",
            price: 152,
            orderId: "gLCEOgl7Pn"
          }
        ]
      }
    };

    setMyPortfolio(defaultPortfolio);
    setMySelectedPortfolio('portfolioA');
  };

  const userAchievementsTutorial = useSelector((state) => state.userAchievements);

  useEffect(() => {
    const applyBlur = () => {

      // Apply blur effect to all elements
      const allElements = document.querySelectorAll('body *');
      allElements.forEach((element) => element.classList.add('blur-bgc'));

      // Remove blur effect from specific elements
      removeBlurFromElements('.achievements-tutorial-card');

      if (userAchievementsTutorial.level === 0 && userAchievementsTutorial.completed === 0) {
        if (userAchievementsTutorial.atSlide === 1) {
          removeBlurFromElementById('home-navigation');
          if (isMobile) {
            removeBlurFromElementById('mobile-home-navigation');
          };
        } else if (userAchievementsTutorial.atSlide === 2) {
          removeBlurFromElementById('posts-page-btn');
        } else if (userAchievementsTutorial.atSlide === 3) {
          removeBlurFromElementById('portfolio-navigation');
          if (isMobile) {
            removeBlurFromElementById('mobile-portfolio-navigation');
          };
        } else if (userAchievementsTutorial.atSlide === 4) {
          removeBlurFromElementById('macro-navigation');
          if (isMobile) {
            removeBlurFromElementById('mobile-macro-navigation');
          };
        }
      }
    };

    const removeBlurFromElements = (selector) => {
      const elements = document.querySelectorAll(selector);
      elements.forEach((element) => {
        removeBlurFromElementAndDescendants(element);
      });
    };

    const removeBlurFromElementById = (id) => {
      const element = document.getElementById(id);
      if (element) {
        removeBlurFromElementAndDescendants(element);
      }
    };

    const removeBlurFromElementAndDescendants = (element) => {
      element.classList.remove('blur-bgc');

      // Remove blur-bgc from all parent elements up to body
      let parent = element.parentElement;
      while (parent && parent !== document.body) {
        parent.classList.remove('blur-bgc');
        parent = parent.parentElement;
      }

      // Remove blur-bgc from all child elements
      const descendants = element.querySelectorAll('*');
      descendants.forEach((descendant) => {
        descendant.classList.remove('blur-bgc');
      });
    };

    const removeBlur = () => {
      // Remove blur effect from all elements
      const allElements = document.querySelectorAll('body *');
      allElements.forEach((element) => element.classList.remove('blur-bgc'));
    };

    if (userAchievementsTutorial.shouldSeeTutorial) {
      applyBlur();
    } else {
      removeBlur();
    }

    // Cleanup function
    return removeBlur;
  }, [
    userAchievementsTutorial.shouldSeeTutorial,
    userAchievementsTutorial.level,
    userAchievementsTutorial.completed,
    userAchievementsTutorial.atSlide
  ]);

  // check portfolio challenges
  // useEffect(() => {
  //   if (!challengeInProcess) return;
  //   const ordersList = myPortfolio[mySelectedPortfolio].portfolioData;
  //   checkChallengeCompletion(loggedInUser, challengeInProcess, ordersList);
  // }, [myPortfolio, mySelectedPortfolio]);

  // const handleChangeUserChallenges = (updatedChallenges) => {
  //   setUserChallenges(updatedChallenges);
  // };

  const [challengeInput, setChallengeInput] = useState({});


  // check if challange is completed
  useEffect(() => {
    const fetchData = async () => {
      if (!challengeInProcess) return;
      const username = loggedInUser ? loggedInUser : '';
      const ordersList = myPortfolio[mySelectedPortfolio].portfolioData;
      const id = challengeInProcess.id;
      const completed = challengeInProcess.completed + '';
      try {
        // username, id, completed, ordersList, selectedPortfolio, challengeInput
        // *** pass challengesCompleted ***
        const result = await checkChallengeCompletion({ username, id, completed, ordersList, mySelectedPortfolio, challengeInput, userChallenges });
        setUserChallenges(prevChallenges => {
          const newUserChallenges = { ...prevChallenges };
          // Check if result.id is defined and if it's not already included in challengesCompleted
          if (result.status === 'Completed!' && result.id && !newUserChallenges.challengesCompleted.includes(result.id)) {
            // Add result.id to challengesCompleted
            newUserChallenges.challengesCompleted.push(result.id);
          }
          newUserChallenges[result.id] = {
            title: challengeInProcess.title,
            // currentTask: challengeInProcess.tasks[challengeInProcess.completed],
            completed: result.completed,
            status: result.status,
            statusPercent: +result.statusPercent,
            id: result.id
          };
          return newUserChallenges;
        });


        setIsChallengeCompletedModalOpen(true);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [myPortfolio, mySelectedPortfolio, challengeInProcess, loggedInUser, challengeInput]);

  const [isChallengeCompletedModalOpen, setIsChallengeCompletedModalOpen] = useState(false);

  const [completedAchievement, setCompletedAchievement] = useState(null);
  const [isAcomplishmentCompletedModalOpen, setIsAcomplishmentCompletedModalOpen] = useState(true);

  useEffect(() => {
    if (userAchievements && !completedAchievement) return;
    if (userAchievements && completedAchievement) {
      if (completedAchievement === 4) {
        setBlinkCmp(null);
      };
      setIsAcomplishmentCompletedModalOpen(true);
      const timeoutId = setTimeout(() => {
        setIsAcomplishmentCompletedModalOpen(false);
        setCompletedAchievement(null);
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [userAchievements, completedAchievement]);

  const AchievementCompletedModal = () => {
    if (!completedAchievement) {
      return <></>
    };

    const achievement = userAchievements[completedAchievement];
    const percentColor = `linear-gradient(to right, #4071AB ${100}%, transparent ${100}%)`;

    return (
      <div className="challenge-completed-modal card-container flex column align-center space-around">
        <h1 className="achievement-h1 text-align-center">{'Achievement completed!'}</h1>
        <h2 className="achievement-h2 text-align-center">{achievement.title}</h2>
        {/* <h4 className="text-align-center">{achievement.tasks[achievement.completed - 1]}</h4> */}
        <div className='challenge-completed-percent-bar'>
          <div className='progress-bar' style={{ background: percentColor, width: `${0}%` }}></div>
        </div>
        <h3 className="text-align-center">{achievement.status}</h3>
      </div>
    );
  };

  const ChallengeCompletedModal = () => {
    if (!challengeInProcess || !userChallenges[challengeInProcess.id]) {
      return <></>;
    };
    const userChallenge = userChallenges[challengeInProcess.id];
    const percentColor = `linear-gradient(to right, #4071AB ${userChallenge.statusPercent}%, transparent ${userChallenge.statusPercent}%)`;
    // return (
    //   <div className="challenge-completed-modal card-container flex column align-center space-around">
    //     <h1 className="text-align-center">{userChallenge.title}</h1>
    //     <h4 className="text-align-center">{challengeInProcess.tasks[challengeInProcess.completed]}</h4>
    //     <h2 className="text-align-center">{userChallenge.status}</h2>
    //     <h3 className="text-align-center">{userChallenge.statusPercent}%</h3>
    //     <div className='challenge-completed-percent-bar'>
    //       <div className='progress-bar' style={{ background: percentColor, width: `${+userChallenge.statusPercent}%` }}></div>
    //     </div>
    //   </div>
    // );

    return (
      <>

        <div className="challenge-completed-modal card-container flex column align-center space-around">
          <h1 className="text-align-center">{userChallenge.title}</h1>
          <div className='challenge-completed-percent-bar'>
            <div className='progress-bar' style={{ background: percentColor, width: `${+userChallenge.statusPercent}%` }}></div>
          </div>
          <h2 className="text-align-center">{userChallenge.status}</h2>
        </div>

        {/* {userChallenge.status !== "Completed!" ? (
          <div className="challenge-completed-modal card-container flex column align-center space-around">
            <h1 className="text-align-center">{userChallenge.title}</h1>
            <div className='challenge-completed-percent-bar'>
              <div className='progress-bar' style={{ background: percentColor, width: `${+userChallenge.statusPercent}%` }}></div>
            </div>
            <h2 className="text-align-center">{userChallenge.status}</h2>
          </div>
        ) : (
          <div className="challenge-completed-modal card-container flex column align-center space-around">
            <h1 className="achievement-h1 text-align-center">{'Challenge completed!'}</h1>
            <h2 className="achievement-h2 text-align-center">{userChallenge.title}</h2>
            <div className='challenge-completed-percent-bar'>
              <div className='progress-bar' style={{ background: percentColor, width: `${+userChallenge.statusPercent}%` }}></div>
            </div>
            <h2 className="text-align-center">{'3/3 Sectors'}</h2>
          </div>
        )} */}
      </>
    );

  };

  // close user modal
  useEffect(() => {
    if (!isChallengeCompletedModalOpen) return;
    setTimeout(() => {
      setIsChallengeCompletedModalOpen(false);
      // console.log('userChallenges[challengeInProcess.id]:', userChallenges[challengeInProcess.id]);
      // if (userChallenges[challengeInProcess.id].status === 'Completed!') {
      //   setUserChallenges(prevChallenges => {
      //     const newUserChallenges = { ...prevChallenges };
      //     // THIS IS HARD CODED
      //     newUserChallenges[challengeInProcess.id] = {
      //       ...challengeInProcess,
      //       completed: 1,
      //       status: '0/5 Sectors',
      //       statusPercent: 0
      //     };
      //     setChallengeInProcess(null);
      //     return newUserChallenges;
      //   });
      // };
    }, 5000);

  }, [isChallengeCompletedModalOpen])


  const [portfolioFilters, setPortfolioFilters] = useState(null);
  const [portfolioFullCompanyOptions, setPortfolioFullCompanyOptions] = useState(null);
  const [portfolioCompanyOptions, setPortfolioCompanyOptions] = useState(null);
  const [portfolioActiveCompanyOptions, setPortfolioActiveCompanyOptions] = useState(null);
  const [portfolioSectorOptions, setPortfolioSectorOptions] = useState(null);
  const [portfolioActiveSectorOptions, setPortfolioActiveSectorOptions] = useState(null);

  const handleChangePortfolioFilters = (newValue) => {
    setPortfolioFilters(newValue);
  };

  const handleChangePortfolioFullCompanyOptions = (newValue) => {
    setPortfolioFullCompanyOptions(newValue);
  };

  const handleChangePortfolioCompanyOptions = (newValue) => {
    setPortfolioCompanyOptions(newValue);
  };

  const handleChangePortfolioActiveCompanyOptions = (newValue) => {
    setPortfolioActiveCompanyOptions(newValue);
  };

  const handleChangePortfolioSectorOptions = (newValue) => {
    setPortfolioSectorOptions(newValue);
  };

  const handleChangePortfolioActiveSectorOptions = (newValue) => {
    setPortfolioActiveSectorOptions(newValue);
  };

  const [blinkCmp, setBlinkCmp] = useState(null);

  const handleChangeBlinkCmp = (blink) => {
    setBlinkCmp(blink);
  };

  const [shouldSeeTutorial, setShouldSeeTutorial] = useState(false);

  const toggleShouldSeeTutorial = (boleean) => {
    setShouldSeeTutorial(boleean);
  };

  const [currTutorial, setCurrTutorial] = useState(0);
  const [currSlide, setCurrSlide] = useState(0);

  const handleChangeCurrTutorial = (tutorial) => {
    setCurrTutorial(tutorial);
  };

  const handleChangeCurrTutorialSlide = (slide) => {
    setCurrSlide(slide);
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="app flex column">
        <div className='app-container'>
          <Account>
            {/* <HeaderPages
              loggedInUser={loggedInUser}
              isSideMenuOpen={isSideMenuOpen}
              handleSideMenuChange={handleSideMenuChange}
              setLoggedInUser={setLoggedInUser}
              setUserFollowing={setUserFollowing}
              onLogout={onLogout}
            /> */}
            {
              <AppHeader
                loggedInUser={loggedInUser}
                setLoggedInUser={setLoggedInUser}
                setUserFollowing={setUserFollowing}
                isSideMenuOpen={isSideMenuOpen}
                onLogout={onLogout}
                blinkCmp={blinkCmp}
                toggleShouldSeeTutorial={toggleShouldSeeTutorial}
                shouldSeeTutorial={shouldSeeTutorial}
              />
            }

            <div className='flex'>
              <HeaderPages
                loggedInUser={loggedInUser}
                isSideMenuOpen={isSideMenuOpen}
                handleSideMenuChange={handleSideMenuChange}
                setLoggedInUser={setLoggedInUser}
                setUserFollowing={setUserFollowing}
                onLogout={onLogout}
              />
              <div style={{ width: '100%' }}>
                <Switch>
                  <Route exact path="/">
                    <Homepage
                      homepageData={homepageData}
                      handleChangeHomepageData={handleChangeHomepageData}
                      loggedInUser={loggedInUser}
                      onLogout={onLogout}
                      postsToDisplay={postsToDisplay}
                      handleChangePostsToDisplay={handleChangePostsToDisplay}
                      seenPostsIds={seenPostsIds}
                      handleChangeSeenPostsIds={handleChangeSeenPostsIds}
                      myPortfolio={myPortfolio}
                      onCopyPortfolio={addStocks}
                      handleCopyPortfolio={handleCopyPortfolio}
                      handlePostAction={handlePostAction}
                      handleCreatePost={handleCreatePost}
                      isPosting={isPosting}
                      handleSetCompaniesLastPrices={handleSetCompaniesLastPrices}
                      handleCopyPortfolioFromFeed={handleCopyPortfolioFromFeed}
                      homepageTrending={homepageTrending}
                      handleChangeHomepageTrending={handleChangeHomepageTrending}
                      calcPortfolioReturn={calcPortfolioReturn}

                      closeFeedbackModal={closeFeedbackModal}
                      openFeedbackModal={openFeedbackModal}
                      onSendFeedback={onSendFeedback}

                      blinkCmp={blinkCmp}
                    />
                  </Route>

                  <Route exact path="/tutorial">
                    {<Tutorial />}
                  </Route>

                  <Route exact path="/portfolio">

                    {
                      <Portfolio
                        navPage={'guide'}
                        key={mySelectedPortfolio}
                        loggedInUser={loggedInUser}
                        myPortfolio={myPortfolio[mySelectedPortfolio]}
                        mySelectedPortfolio={mySelectedPortfolio}
                        portfolio={myPortfolio}
                        portfolioCompanyData={portfolioCompanyData}
                        portfolioSectorData={portfolioSectorData}
                        portfolioFundamentalData={portfolioFundamentalData}
                        portfolioDescData={portfolioDescData}
                        portfolioSensData={portfolioSensData}
                        handleChangePortfolioCompaniesData={handleChangePortfolioCompaniesData}
                        handleChangePortfolioSectorsData={handleChangePortfolioSectorsData}
                        handleChangePortfolioFundamentalData={handleChangePortfolioFundamentalData}
                        handleChangePortfolioDescData={handleChangePortfolioDescData}
                        handleChangePortfolioSensData={handleChangePortfolioSensData}
                        addStock={addStock}
                        addStocks={addStocks}
                        handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                        showTutorial={showTutorial}
                      />
                    }
                    {/* {
                      !loggedInUser && <NeedUser />
                    } */}
                  </Route>

                  {/* --------------------------------------------------------------------------------------------------- */}
                  {/* --------------------------------------------------------------------------------------------------- */}
                  {/* --------------------------------------------------------------------------------------------------- */}

                  {/* <Route exact path="/portfolio/companies">

                    {
                      mySelectedPortfolio &&
                      myPortfolio &&
                      <Portfolio
                        navPage={'companies'}
                        key={mySelectedPortfolio}
                        loggedInUser={loggedInUser}
                        myPortfolio={myPortfolio[mySelectedPortfolio]}
                        mySelectedPortfolio={mySelectedPortfolio}
                        portfolio={myPortfolio}
                        portfolioCompanyData={portfolioCompanyData}
                        portfolioSectorData={portfolioSectorData}
                        portfolioFundamentalData={portfolioFundamentalData}
                        portfolioDescData={portfolioDescData}
                        portfolioSensData={portfolioSensData}
                        handleChangePortfolioCompaniesData={handleChangePortfolioCompaniesData}
                        handleChangePortfolioSectorsData={handleChangePortfolioSectorsData}
                        handleChangePortfolioFundamentalData={handleChangePortfolioFundamentalData}
                        handleChangePortfolioDescData={handleChangePortfolioDescData}
                        handleChangePortfolioSensData={handleChangePortfolioSensData}
                        addStock={addStock}
                        addStocks={addStocks}
                        handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                        showTutorial={showTutorial}
                      />
                    }
                    {/* {
  !loggedInUser && <NeedUser />
} */}
                  {/* </Route>  */}


                  {
                    <Route exact path='/portfolio/companies'>
                      <PortfolioCompanies
                        loggedInUser={loggedInUser}
                        myPortfolio={myPortfolio[mySelectedPortfolio]}
                        mySelectedPortfolio={mySelectedPortfolio}
                        portfolio={myPortfolio}
                        portfolioCompanyData={portfolioCompanyData}
                        handleChangePortfolioCompaniesData={handleChangePortfolioCompaniesData}
                        addStock={addStock}
                        addStocks={addStocks}
                        handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                        showTutorial={showTutorial}
                        portfolioFilters={portfolioFilters}
                        handleChangePortfolioFilters={handleChangePortfolioFilters}

                        portfolioFullCompanyOptions={portfolioFullCompanyOptions}
                        portfolioCompanyOptions={portfolioCompanyOptions}
                        portfolioActiveCompanyOptions={portfolioActiveCompanyOptions}
                        portfolioSectorOptions={portfolioSectorOptions}
                        portfolioActiveSectorOptions={portfolioActiveSectorOptions}
                        handleChangePortfolioFullCompanyOptions={handleChangePortfolioFullCompanyOptions}
                        handleChangePortfolioCompanyOptions={handleChangePortfolioCompanyOptions}
                        handleChangePortfolioActiveCompanyOptions={handleChangePortfolioActiveCompanyOptions}
                        handleChangePortfolioSectorOptions={handleChangePortfolioSectorOptions}
                        handleChangePortfolioActiveSectorOptions={handleChangePortfolioActiveSectorOptions}
                        blinkCmp={blinkCmp}
                      />
                    </Route>
                  }

                  {
                    <Route exact path="/portfolio/sectors">
                      <PortfolioSectors
                        loggedInUser={loggedInUser}
                        myPortfolio={myPortfolio[mySelectedPortfolio]}
                        mySelectedPortfolio={mySelectedPortfolio}
                        portfolio={myPortfolio}
                        portfolioSectorData={portfolioSectorData}
                        handleChangePortfolioSectorsData={handleChangePortfolioSectorsData}
                        addStock={addStock}
                        addStocks={addStocks}
                        handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                        showTutorial={showTutorial}
                        portfolioFilters={portfolioFilters}
                        handleChangePortfolioFilters={handleChangePortfolioFilters}

                        portfolioFullCompanyOptions={portfolioFullCompanyOptions}
                        portfolioCompanyOptions={portfolioCompanyOptions}
                        portfolioActiveCompanyOptions={portfolioActiveCompanyOptions}
                        portfolioSectorOptions={portfolioSectorOptions}
                        portfolioActiveSectorOptions={portfolioActiveSectorOptions}
                        handleChangePortfolioFullCompanyOptions={handleChangePortfolioFullCompanyOptions}
                        handleChangePortfolioCompanyOptions={handleChangePortfolioCompanyOptions}
                        handleChangePortfolioActiveCompanyOptions={handleChangePortfolioActiveCompanyOptions}
                        handleChangePortfolioSectorOptions={handleChangePortfolioSectorOptions}
                        handleChangePortfolioActiveSectorOptions={handleChangePortfolioActiveSectorOptions}
                      />
                    </Route>
                  }
                  {/* <Route exact path="/portfolio/sectors"> */}

                  {/* {
                      mySelectedPortfolio &&
                      myPortfolio &&
                      <Portfolio
                        navPage={'sectors'}
                        key={mySelectedPortfolio}
                        loggedInUser={loggedInUser}
                        myPortfolio={myPortfolio[mySelectedPortfolio]}
                        mySelectedPortfolio={mySelectedPortfolio}
                        portfolio={myPortfolio}
                        portfolioCompanyData={portfolioCompanyData}
                        portfolioSectorData={portfolioSectorData}
                        portfolioFundamentalData={portfolioFundamentalData}
                        portfolioDescData={portfolioDescData}
                        portfolioSensData={portfolioSensData}
                        handleChangePortfolioCompaniesData={handleChangePortfolioCompaniesData}
                        handleChangePortfolioSectorsData={handleChangePortfolioSectorsData}
                        handleChangePortfolioFundamentalData={handleChangePortfolioFundamentalData}
                        handleChangePortfolioDescData={handleChangePortfolioDescData}
                        handleChangePortfolioSensData={handleChangePortfolioSensData}
                        addStock={addStock}
                        addStocks={addStocks}
                        handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                        showTutorial={showTutorial}
                      />
                    } */}
                  {/* {
!loggedInUser && <NeedUser />
} */}
                  {/* </Route> */}


                  <Route exact path="/portfolio/fundamental">
                    <PortfolioFundamental
                      loggedInUser={loggedInUser}
                      mySelectedPortfolio={mySelectedPortfolio}
                      portfolio={myPortfolio}
                      portfolioFundamentalData={portfolioFundamentalData}
                      handleChangePortfolioFundamentalData={handleChangePortfolioFundamentalData}
                      addStock={addStock}
                      addStocks={addStocks}
                      handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                      showTutorial={showTutorial}
                    />
                  </Route>

                  {/* <Route exact path="/portfolio/fundamental">

                    {
                      mySelectedPortfolio &&
                      myPortfolio &&
                      <Portfolio
                        navPage={'fundamental'}
                        key={mySelectedPortfolio}
                        loggedInUser={loggedInUser}
                        myPortfolio={myPortfolio[mySelectedPortfolio]}
                        mySelectedPortfolio={mySelectedPortfolio}
                        portfolio={myPortfolio}
                        portfolioCompanyData={portfolioCompanyData}
                        portfolioSectorData={portfolioSectorData}
                        portfolioFundamentalData={portfolioFundamentalData}
                        portfolioDescData={portfolioDescData}
                        portfolioSensData={portfolioSensData}
                        handleChangePortfolioCompaniesData={handleChangePortfolioCompaniesData}
                        handleChangePortfolioSectorsData={handleChangePortfolioSectorsData}
                        handleChangePortfolioFundamentalData={handleChangePortfolioFundamentalData}
                        handleChangePortfolioDescData={handleChangePortfolioDescData}
                        handleChangePortfolioSensData={handleChangePortfolioSensData}
                        addStock={addStock}
                        addStocks={addStocks}
                        handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                        showTutorial={showTutorial}
                      />
                    }
                    {/* {
!loggedInUser && <NeedUser />
} */}
                  {/* </Route> */}

                  <Route exact path="/portfolio/sensitivity">

                    <PortfolioSensitivity
                      loggedInUser={loggedInUser}
                      myPortfolio={myPortfolio[mySelectedPortfolio]}
                      mySelectedPortfolio={mySelectedPortfolio}
                      portfolio={myPortfolio}
                      portfolioSensData={portfolioSensData}
                      handleChangePortfolioSensData={handleChangePortfolioSensData}
                      addStock={addStock}
                      addStocks={addStocks}
                      handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                      showTutorial={showTutorial}
                      portfolioFilters={portfolioFilters}
                      handleChangePortfolioFilters={handleChangePortfolioFilters}
                    />

                    {/* {
                      mySelectedPortfolio &&
                      myPortfolio &&
                      <Portfolio
                        navPage={'sensitivity'}
                        key={mySelectedPortfolio}
                        loggedInUser={loggedInUser}
                        myPortfolio={myPortfolio[mySelectedPortfolio]}
                        mySelectedPortfolio={mySelectedPortfolio}
                        portfolio={myPortfolio}
                        portfolioCompanyData={portfolioCompanyData}
                        portfolioSectorData={portfolioSectorData}
                        portfolioFundamentalData={portfolioFundamentalData}
                        portfolioDescData={portfolioDescData}
                        portfolioSensData={portfolioSensData}
                        handleChangePortfolioCompaniesData={handleChangePortfolioCompaniesData}
                        handleChangePortfolioSectorsData={handleChangePortfolioSectorsData}
                        handleChangePortfolioFundamentalData={handleChangePortfolioFundamentalData}
                        handleChangePortfolioDescData={handleChangePortfolioDescData}
                        handleChangePortfolioSensData={handleChangePortfolioSensData}
                        addStock={addStock}
                        addStocks={addStocks}
                        handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                        showTutorial={showTutorial}
                      />
                    } */}
                    {/* {
!loggedInUser && <NeedUser />
} */}
                  </Route>



                  {

                    <Route exact path='/portfolio/descriptive'>
                      <PortfolioDescriptive
                        loggedInUser={loggedInUser}
                        myPortfolio={myPortfolio[mySelectedPortfolio]}
                        mySelectedPortfolio={mySelectedPortfolio}
                        portfolio={myPortfolio}
                        portfolioDescData={portfolioDescData}
                        handleChangePortfolioDescData={handleChangePortfolioDescData}
                        addStock={addStock}
                        addStocks={addStocks}
                        handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                        showTutorial={showTutorial}
                        portfolioFilters={portfolioFilters}
                        handleChangePortfolioFilters={handleChangePortfolioFilters}
                        portfolioFullCompanyOptions={portfolioFullCompanyOptions}
                        portfolioCompanyOptions={portfolioCompanyOptions}
                        portfolioActiveCompanyOptions={portfolioActiveCompanyOptions}
                        portfolioSectorOptions={portfolioSectorOptions}
                        portfolioActiveSectorOptions={portfolioActiveSectorOptions}
                        handleChangePortfolioFullCompanyOptions={handleChangePortfolioFullCompanyOptions}
                        handleChangePortfolioCompanyOptions={handleChangePortfolioCompanyOptions}
                        handleChangePortfolioActiveCompanyOptions={handleChangePortfolioActiveCompanyOptions}
                        handleChangePortfolioSectorOptions={handleChangePortfolioSectorOptions}
                        handleChangePortfolioActiveSectorOptions={handleChangePortfolioActiveSectorOptions}
                      />
                    </Route>
                  }




                  {/* <Route exact path="/portfolio/descriptive">

                    {
                      mySelectedPortfolio &&
                      myPortfolio &&
                      <Portfolio
                        navPage={'descriptive'}
                        key={mySelectedPortfolio}
                        loggedInUser={loggedInUser}
                        myPortfolio={myPortfolio[mySelectedPortfolio]}
                        mySelectedPortfolio={mySelectedPortfolio}
                        portfolio={myPortfolio}
                        portfolioCompanyData={portfolioCompanyData}
                        portfolioSectorData={portfolioSectorData}
                        portfolioFundamentalData={portfolioFundamentalData}
                        portfolioDescData={portfolioDescData}
                        portfolioSensData={portfolioSensData}
                        handleChangePortfolioCompaniesData={handleChangePortfolioCompaniesData}
                        handleChangePortfolioSectorsData={handleChangePortfolioSectorsData}
                        handleChangePortfolioFundamentalData={handleChangePortfolioFundamentalData}
                        handleChangePortfolioDescData={handleChangePortfolioDescData}
                        handleChangePortfolioSensData={handleChangePortfolioSensData}
                        addStock={addStock}
                        addStocks={addStocks}
                        handleChangeSelectedPortfolio={handleChangeSelectedPortfolio}
                        showTutorial={showTutorial}
                      />
                    } */}
                  {/* {
!loggedInUser && <NeedUser />
} */}
                  {/* </Route> */}
                  {/* --------------------------------------------------------------------------------------------------- */}
                  {/* --------------------------------------------------------------------------------------------------- */}
                  {/* --------------------------------------------------------------------------------------------------- */}


                  <Route exact path="/macro">
                    <Macro
                      navPage={'guide'}
                      pageMacro={pageMacro}
                      setPageMacro={setPageMacro}
                    />
                  </Route>

                  {/* --------------------------------------------------------------------------------------------------- */}
                  {/* --------------------------------------------------------------------------------------------------- */}
                  {/* --------------------------------------------------------------------------------------------------- */}


                  <Route exact path="/macro/inflation">
                    <Macro
                      navPage={'inflation'}
                      pageMacro={pageMacro}
                      setPageMacro={setPageMacro}
                    />
                  </Route>

                  <Route exact path="/macro/currencies">
                    <Macro
                      navPage={'currencies'}
                      pageMacro={pageMacro}
                      setPageMacro={setPageMacro}
                    />
                  </Route>

                  <Route exact path="/macro/indices">
                    <Macro
                      navPage={'indices'}
                      pageMacro={pageMacro}
                      setPageMacro={setPageMacro}
                    />
                  </Route>

                  <Route exact path="/macro/commodities">
                    <Macro
                      navPage={'commodities'}
                      pageMacro={pageMacro}
                      setPageMacro={setPageMacro}
                    />
                  </Route>


                  {/* --------------------------------------------------------------------------------------------------- */}
                  {/* --------------------------------------------------------------------------------------------------- */}
                  {/* --------------------------------------------------------------------------------------------------- */}

                  <Route exact path="/calendar">
                    <Calendar
                      navPage={'guide'}
                      calendarData={calendarData}
                      handleChangeCalendarData={handleChangeCalendarData}
                      loggedInUser={loggedInUser}
                    />
                  </Route>

                  <Route exact path="/calendar/companies">
                    <Calendar
                      navPage={'companies'}
                      calendarData={calendarData}
                      handleChangeCalendarData={handleChangeCalendarData}
                      loggedInUser={loggedInUser}
                    />
                  </Route>

                  <Route exact path="/calendar/macro">
                    <Calendar
                      navPage={'macro'}
                      calendarData={calendarData}
                      handleChangeCalendarData={handleChangeCalendarData}
                      loggedInUser={loggedInUser}
                    />
                  </Route>

                  <Route exact path="/crypto">
                    <Crypto
                      navPage={'Guide'}
                      pageCrypto={pageCrypto}
                      setPageCrypto={setPageCrypto}
                      toggleFollowing={toggleFollowing}
                      userFollowing={userFollowing.crypto}
                      loggedInUser={loggedInUser}
                    />
                  </Route>

                  <Route exact path="/crypto/currencies">
                    <Crypto
                      navPage={'All'}
                      pageCrypto={pageCrypto}
                      setPageCrypto={setPageCrypto}
                      toggleFollowing={toggleFollowing}
                      userFollowing={userFollowing.crypto}
                      loggedInUser={loggedInUser}
                    />
                  </Route>

                  <Route exact path="/crypto/favorites">
                    <Crypto
                      navPage={'Favorites'}
                      pageCrypto={pageCrypto}
                      setPageCrypto={setPageCrypto}
                      toggleFollowing={toggleFollowing}
                      userFollowing={userFollowing.crypto}
                      loggedInUser={loggedInUser}
                    />
                  </Route>

                  <Route path="/company/:symbol">
                    <SingleCompany
                      loggedInUser={loggedInUser}
                      toggleFollowing={toggleFollowing}
                      userFollowing={userFollowing.companies}
                      singleCompanies={singleCompanies}
                      setSingleCompanies={setSingleCompanies}
                    />
                  </Route>

                  <Route path="/sector/:sector">
                    <SingleSector
                      loggedInUser={loggedInUser}
                      toggleFollowing={toggleFollowing}
                      userFollowing={userFollowing.sectors}
                      singleSectors={singleSectors}
                      setSingleSectors={setSingleSectors}
                    />
                  </Route>

                  <Route path="/signup">
                    <Signup
                      setLoggedInUser={setLoggedInUser}
                      handleNewUser={handleNewUser}
                      setLoggedInUserId={setLoggedInUserId}
                    />
                  </Route>

                  <Route path="/index/:symbol">
                    <SingleIndex
                      loggedInUser={loggedInUser}
                      toggleFollowing={toggleFollowing}
                      userFollowing={userFollowing.indices}
                      singleIndices={singleIndices}
                      setSingleIndices={setSingleIndices}
                    />
                  </Route>

                  <Route exact path="/crypto/:symbol">
                    <SingleCrypto
                      loggedInUser={loggedInUser}
                      toggleFollowing={toggleFollowing}
                      singleCryptoCurrencies={singleCryptoCurrencies}
                      setSingleCryptoCurrencies={setSingleCryptoCurrencies}
                      userFollowing={userFollowing.crypto}
                    />
                  </Route>

                  <Route path="/currency/:symbol">
                    <SingleCurrency
                      loggedInUser={loggedInUser}
                      toggleFollowing={toggleFollowing}
                      singleCurrencies={singleCurrencies}
                      setSingleCurrencies={setSingleCurrencies}
                      userFollowing={userFollowing.currencies}
                    />
                  </Route>

                  <Route path="/commodity/:symbol">
                    <SingleCommodity
                      singleCommodities={singleCommodities}
                      setSingleCommodities={setSingleCommodities}
                      userFollowing={userFollowing.commodities}
                      toggleFollowing={toggleFollowing}
                      loggedInUser={loggedInUser}
                    />
                  </Route>

                  <Route path="/login">
                    <Login
                      loggedInUser={loggedInUser}
                      setLoggedInUser={setLoggedInUser}
                      setLoggedInUserId={setLoggedInUserId}
                    />
                  </Route>


                  <Route path="/profile/achievements/:username">
                    <UserProfile
                      loggedInUser={loggedInUser}
                      userFollowing={userFollowing}
                      userAbout={userAbout}
                      setUserAbout={setUserAbout}
                      toggleFollowing={toggleFollowing}
                      userProfileData={userProfileData}
                      handleSetUserProfileData={handleSetUserProfileData}
                      myPortfolio={myPortfolio}
                      mySelectedPortfolio={mySelectedPortfolio}
                      handleCopyPortfolio={handleCopyPortfolio}
                      handleChangeMySelectedPortfolio={handleChangeMySelectedPortfolio}

                      onCopyPortfolio={addStocks}
                      handlePostAction={handlePostAction}
                      handleCopyPortfolioFromFeed={handleCopyPortfolioFromFeed}
                      calcPortfolioReturn={calcPortfolioReturn}
                      handleSetCompaniesLastPrices={handleSetCompaniesLastPrices}

                      challengeInProcess={challengeInProcess}
                      handleChangeChallengeInProcess={handleChangeChallengeInProcess}
                      userChallenges={userChallenges}
                      userAchievements={userAchievements}

                      blinkCmp={blinkCmp}
                    />
                  </Route>


                  <Route path="/profile/challenges/:username">
                    <UserProfile
                      loggedInUser={loggedInUser}
                      userFollowing={userFollowing}
                      userAbout={userAbout}
                      setUserAbout={setUserAbout}
                      toggleFollowing={toggleFollowing}
                      userProfileData={userProfileData}
                      handleSetUserProfileData={handleSetUserProfileData}
                      myPortfolio={myPortfolio}
                      mySelectedPortfolio={mySelectedPortfolio}
                      handleCopyPortfolio={handleCopyPortfolio}
                      handleChangeMySelectedPortfolio={handleChangeMySelectedPortfolio}

                      onCopyPortfolio={addStocks}
                      handlePostAction={handlePostAction}
                      handleCopyPortfolioFromFeed={handleCopyPortfolioFromFeed}
                      calcPortfolioReturn={calcPortfolioReturn}
                      handleSetCompaniesLastPrices={handleSetCompaniesLastPrices}

                      challengeInProcess={challengeInProcess}
                      handleChangeChallengeInProcess={handleChangeChallengeInProcess}
                      userChallenges={userChallenges}
                      userAchievements={userAchievements}
                    />
                  </Route>

                  <Route path="/profile/posts/:username">
                    <UserProfile
                      loggedInUser={loggedInUser}
                      userFollowing={userFollowing}
                      userAbout={userAbout}
                      setUserAbout={setUserAbout}
                      toggleFollowing={toggleFollowing}
                      userProfileData={userProfileData}
                      handleSetUserProfileData={handleSetUserProfileData}
                      myPortfolio={myPortfolio}
                      mySelectedPortfolio={mySelectedPortfolio}
                      handleCopyPortfolio={handleCopyPortfolio}
                      handleChangeMySelectedPortfolio={handleChangeMySelectedPortfolio}

                      onCopyPortfolio={addStocks}
                      handlePostAction={handlePostAction}
                      handleCopyPortfolioFromFeed={handleCopyPortfolioFromFeed}
                      calcPortfolioReturn={calcPortfolioReturn}
                      handleSetCompaniesLastPrices={handleSetCompaniesLastPrices}

                      challengeInProcess={challengeInProcess}
                      handleChangeChallengeInProcess={handleChangeChallengeInProcess}
                      userChallenges={userChallenges}
                      userAchievements={userAchievements}
                    />
                  </Route>

                  <Route path="/profile/portfolios/:username">
                    <UserProfile
                      loggedInUser={loggedInUser}
                      userFollowing={userFollowing}
                      userAbout={userAbout}
                      setUserAbout={setUserAbout}
                      toggleFollowing={toggleFollowing}
                      userProfileData={userProfileData}
                      handleSetUserProfileData={handleSetUserProfileData}
                      myPortfolio={myPortfolio}
                      mySelectedPortfolio={mySelectedPortfolio}
                      handleCopyPortfolio={handleCopyPortfolio}
                      handleChangeMySelectedPortfolio={handleChangeMySelectedPortfolio}

                      onCopyPortfolio={addStocks}
                      handlePostAction={handlePostAction}
                      handleCopyPortfolioFromFeed={handleCopyPortfolioFromFeed}
                      calcPortfolioReturn={calcPortfolioReturn}
                      handleSetCompaniesLastPrices={handleSetCompaniesLastPrices}

                      challengeInProcess={challengeInProcess}
                      handleChangeChallengeInProcess={handleChangeChallengeInProcess}
                      userChallenges={userChallenges}
                      userAchievements={userAchievements}
                    />
                  </Route>

                  <Route path="/profile/interests/:username">
                    <UserProfile
                      loggedInUser={loggedInUser}
                      userFollowing={userFollowing}
                      userAbout={userAbout}
                      setUserAbout={setUserAbout}
                      toggleFollowing={toggleFollowing}
                      userProfileData={userProfileData}
                      handleSetUserProfileData={handleSetUserProfileData}
                      myPortfolio={myPortfolio}
                      mySelectedPortfolio={mySelectedPortfolio}
                      handleCopyPortfolio={handleCopyPortfolio}
                      handleChangeMySelectedPortfolio={handleChangeMySelectedPortfolio}

                      onCopyPortfolio={addStocks}
                      handlePostAction={handlePostAction}
                      handleCopyPortfolioFromFeed={handleCopyPortfolioFromFeed}
                      calcPortfolioReturn={calcPortfolioReturn}
                      handleSetCompaniesLastPrices={handleSetCompaniesLastPrices}

                      challengeInProcess={challengeInProcess}
                      handleChangeChallengeInProcess={handleChangeChallengeInProcess}
                      userChallenges={userChallenges}
                      userAchievements={userAchievements}
                    />
                  </Route>

                  <Route path="/profile/settings/:username">
                    <UserProfile
                      loggedInUser={loggedInUser}
                      userFollowing={userFollowing}
                      userAbout={userAbout}
                      setUserAbout={setUserAbout}
                      toggleFollowing={toggleFollowing}
                      userProfileData={userProfileData}
                      handleSetUserProfileData={handleSetUserProfileData}
                      myPortfolio={myPortfolio}
                      mySelectedPortfolio={mySelectedPortfolio}
                      handleCopyPortfolio={handleCopyPortfolio}
                      handleChangeMySelectedPortfolio={handleChangeMySelectedPortfolio}

                      onCopyPortfolio={addStocks}
                      handlePostAction={handlePostAction}
                      handleCopyPortfolioFromFeed={handleCopyPortfolioFromFeed}

                      calcPortfolioReturn={calcPortfolioReturn}
                      handleSetCompaniesLastPrices={handleSetCompaniesLastPrices}

                      challengeInProcess={challengeInProcess}
                      handleChangeChallengeInProcess={handleChangeChallengeInProcess}
                      userChallenges={userChallenges}
                      userAchievements={userAchievements}
                    />
                  </Route>



                  <Route exact path="/funds">
                    <Funds
                      loggedInUser={loggedInUser}
                    />
                  </Route>

                  <Route exact path="/funds/:fund">
                    <SingleFund
                      loggedInUser={loggedInUser}
                      addStocks={addFundStocks}
                    />
                  </Route>

                  {/* <Route exact path="/funds/:fund/playoff">
                    <Signup
                    />
                  </Route> */}

                  <Route path="/verification">
                    <EmailVerification
                      setLoggedInUser={setLoggedInUser}
                      handleNewUser={handleNewUser}
                    />
                  </Route>
                  <Route path="/about">
                    <AboutUs />
                  </Route>
                  <Route path="/FAQ">
                    <FAQ />
                  </Route>
                  <Route path="/terms-of-service">
                    <TermsOfService />
                  </Route>
                  <Route path="/privacy-notice">
                    <PrivacyNotice />
                  </Route>
                  <Route path="/error">
                    <ErrorPage />
                  </Route>

                  <Route path="*" component={ErrorPage} />

                </Switch>
              </div>
            </div>
          </Account>
        </div>
        {/* <AppFooter /> */}
        {/* {isShowFeedbackModal && !isChallengeCompletedModalOpen && <FeedbackModalBtn openFeedbackModal={openFeedbackModal} />} */}
        {isFeedBackModalOpen && <FeedbackModal loggedInUser={loggedInUser} closeFeedbackModal={closeFeedbackModal} onSubmit={onSendFeedback} />}
        {/* <Notepad
          blinkCmp={blinkCmp}
        /> */}
        {/* {
          showTutorial &&
          window.location.pathname === '/' &&
          <AppTutorial
            startPortfolioTutorial={startPortfolioTutorial}
            handleCloseTutorial={handleCloseTutorial}
          />
        } */}
        {isChallengeCompletedModalOpen && <ChallengeCompletedModal />}
        {isAcomplishmentCompletedModalOpen && completedAchievement && <AchievementCompletedModal />}
        {
          shouldSeeTutorial &&
          screenWidth >= 500 &&
          <StarterTutorial
            currTutorial={currTutorial}
            currSlide={currSlide}
            handleChangeCurrTutorial={handleChangeCurrTutorial}
            handleChangeCurrTutorialSlide={handleChangeCurrTutorialSlide}
            handleNewUser={handleNewUser}
            setLoggedInUserId={setLoggedInUserId}
            loggedInUser={loggedInUser}
            handleChangeBlinkCmp={handleChangeBlinkCmp}
            toggleShouldSeeTutorial={toggleShouldSeeTutorial}
            handleCompleteAchievement={handleCompleteAchievement}
          />
        }
        {
          userAchievements && userAchievements.shouldSeeTutorial &&
          <AchievementsTutorial
            loggedInUser={loggedInUser}
            blinkCmp={blinkCmp}
          />
        }
        {/* <PageGuide /> */}
      </div>
    </Router>

  );
};

export default App;