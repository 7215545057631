import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/user-slice';
import loadingReducer from './loading/loading-slice';
import lastPricesReducer from './last-prices/last-prices-slice';
import userAchievementsReducer from './user-achievements/user-achievements-slice';
import prevScoresReducer from './prev-scores/prev-scores-slice';

export default configureStore({
    reducer: {
        user: userReducer,
        loading: loadingReducer,
        lastPrices: lastPricesReducer,
        userAchievements: userAchievementsReducer,
        prevScores: prevScoresReducer
    }
});