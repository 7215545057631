import React from 'react';
import './style/fund-logs.scss';
import data from './data/fund-log.json';

const FundLogs = () => {
    return (
        <div className='card-container'>
            <div className="flex space-between mb20">
                <div className='fund-logs-tableitem fund-logs-tableheader'>Date</div>
                <div className='fund-logs-tableitem fund-logs-tableheader'>Type</div>
                <div className='fund-logs-tableitem fund-logs-tableheader'>Fund return</div>
                <div className='fund-logs-tableitem fund-logs-tableheader'>Status</div>
            </div>
            {data.logs.map((item, index) => (
                <div key={index} className="fund-logs-tablerow flex space-between">
                    <div className='fund-logs-tableitem'>{item.date}</div>
                    <div className='fund-logs-tableitem'>{item.playoffType}</div>
                    <div className='fund-logs-tableitem'>{item.fundReturn}</div>
                    <div className={`fund-logs-tableitem ${item.isWon ? 'positive' : 'negative'}`}>
                        {item.isWon ? 'WIN' : 'LOSE'}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default FundLogs;
