import { createSlice } from "@reduxjs/toolkit";

export const prevScoresSlice = createSlice({
    name: "prevScores",
    initialState: {
        scores: {
            harmony: null,
            hierarchy: null,
            awarness: null,
            precision: null,
            resilience: null
        },

    },
    reducers: {
        setScores(state, action) {
            return { ...state, scores: action.payload };
        },
    },
});

export const { setScores } = prevScoresSlice.actions;

export default prevScoresSlice.reducer;