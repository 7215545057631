import React, { useState } from 'react';

const HomepageFeedContentToggleBtn = ({
    icon,
    filledIcon,
    action,
    handleToggleClick,
    length
}) => {

    const [isArrowHovered, setIsArrowHovered] = useState(false);

    const handleArrowHoverEnter = () => {
        setIsArrowHovered(true);
    };

    const handleArrowHoverLeave = () => {
        setIsArrowHovered(false);
    };

    return (
        <>
            {length > 1 ?
                <div className='flex align-center'
                >
                    <div
                        className='homepage-feed-post-arrow flex align-center'
                        onMouseEnter={handleArrowHoverEnter}
                        onMouseLeave={handleArrowHoverLeave}
                        onClick={() => handleToggleClick(action)}
                    >
                        {isArrowHovered ? filledIcon : icon}
                    </div>
                </div>
                :
                <div
                    className='flex align-center'>
                    <div
                        className='homepage-feed-post-arrow flex align-center'>
                    </div>
                </div>
            }
        </>

    );
}

export default HomepageFeedContentToggleBtn;