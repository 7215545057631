import './switch-portfolio.scss';
import React, { useEffect, useState } from 'react';

const PortfolioSwitch = ({ portfolio, selectedPortfolio, handleChangeSelectedPortfolio }) => {

    const emojiNums = ['Ⅰ', 'Ⅱ', 'Ⅲ', 'Ⅳ', 'Ⅴ'];

    const [fullPortfolio, setFullPortfolio] = useState(null);

    useEffect(() => {
        const requiredKeys = ["portfolioA", "portfolioB", "portfolioC", "portfolioD", "portfolioE"];
        const updatedPortfolio = { ...portfolio };
        requiredKeys.forEach(key => {
            if (!updatedPortfolio[key]) {
                updatedPortfolio[key] = {
                    "portfolioName": key,
                    "portfolioScores": {
                        "awarness": 0,
                        "harmony": 0,
                        "resilience": 0,
                        "hierarchy": 0,
                        "precision": 0
                    },
                    "portfolioData": []
                };
            }
        });
        setFullPortfolio(updatedPortfolio);
    }, [portfolio]);


    const onChangeSelectedPortfolio = (newSelectedPortfolio) => {
        handleChangeSelectedPortfolio(newSelectedPortfolio, fullPortfolio[newSelectedPortfolio]);
    };

    return (
        <div className="portfolio-switch-container">
            <div className="portfolio-switch-menu">
                {
                    fullPortfolio &&
                    ["portfolioA", "portfolioB", "portfolioC", "portfolioD", "portfolioE"]
                        .filter(key => fullPortfolio[key])
                        .map((key, idx) => (
                            <div className="portfolio-switch-item-container flex align-center"
                                style={{ backgroundColor: key === selectedPortfolio ? '#f1f6fc' : 'inherit' }}
                                key={key}>
                                <div className='portfolio-switch-icon'>{emojiNums[idx]}</div>
                                <div
                                    onClick={() => onChangeSelectedPortfolio(key)}
                                    className={`portfolio-switch-item`}

                                >
                                    {fullPortfolio[key].portfolioName}
                                </div>
                            </div>
                        ))
                }
            </div>
        </div>
    );

}

export default PortfolioSwitch;
