export async function getCalendar() {
    var requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        redirect: 'follow'
    };

    try {
        const response = await fetch("https://3gjlw45n0c.execute-api.us-east-1.amazonaws.com/getCalendar", requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};