const MacroCommoditiesClass = () => {
    return (
        <>
            <h2 className={'app-tutorial-slide-title'}>Commodities</h2>
                <h4 className={"guide-subtitle"}>Filters:</h4>
                <ul className={"guide-ul"}>
                    <li>Time Frames: Select specific time periods for analysis.</li>
                    <li>Commodity Groups: Filter commodities by categories such as energy, metals, agriculture, etc.</li>
                    <li>Commodities: Choose from a variety of individual commodities.</li>
                    <li>Price Viewing Filter (Relative vs. Actual Values): Toggle between viewing commodity prices in relative terms (percentage changes) or actual values.</li>
                </ul>
                <h4 className={"guide-subtitle"}>Charts:</h4>
                <ul className={"guide-ul"}>
                    <li>Commodity Prices Over Time: Displays the historical prices of selected commodities.</li>
                    <li>Commodity Returns Over Time: Analyzes the returns of commodities over different time frames.</li>
                    <li>Commodity Returns Over Time: Analyzes the returns of commodities over different time frames.</li>
                    <li>Sharpe Ratio Bar Chart: Compares the risk-adjusted returns (Sharpe ratios) of commodities over various time frames.</li>
                </ul>
                <div
                    className="flex flex-end">
                    <a
                        href="/macro/commodities"
                        className="guide-btn btn btn-border" title="Commodities">
                        Go to page
                    </a>
                </div>
        </>
     );
}

export default MacroCommoditiesClass;