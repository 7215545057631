import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa";
import Signup from "../../user-pool/signup";
import { achievementIcon, arrowDownIcon, arrowUpIcon, backIcon, beginnerIcon, boostIcon, closeIcon, communityInsightsIcon, connectIcon, copyIcon, dontKnowIcon, educationIcon, experiencedIcon, facebookIcon, friendsIcon, funIcon, googleIcon, homeIcon, instagramIcon, intermediateIcon, investmentScoutingIcon, learnIcon, manageIcon, marketLearningIcon, menuIcon, minimizeIcon, newsIcon, notepadIcon, optimizeIcon, otherIcon, plusIcon, portfolioOptimizationIcon, portfolioProfitIcon, preferNotIcon, productivelyIcon, rocketIcon, tiktokIcon, tvIcon, youtubeIcon } from "../icons/icons";
import { BiBorderRadius } from "react-icons/bi";

const WelcomeHeader = ({ title, toggleShouldSeeTutorial }) => {
    return (
        <div className="flex space-between">
            <h2>{title}</h2>
            <div title="Close" onClick={() => toggleShouldSeeTutorial(false)} className="starter-tutorial-close-btn flex align-center">{closeIcon}</div>
        </div>
    );
};

const progressBar = (handlePrevSlide, barValue, totalBars) => {
    const fillPercentage = (barValue / totalBars) * 100;
    const divStyle = {
        width: '100%',
        height: '25px',
        borderRadius: '20px',
        background: `linear-gradient(to right, #5c9ead ${fillPercentage}%, rgba(92, 158, 173, 0.5) ${fillPercentage + 5}%, transparent ${fillPercentage}%)`,
    };
    return (
        <div className="flex space-between align-center">
            <div
                className="btn"
                title="minimize"
                style={{ margin: '10px 0px 0 10px' }}
                onClick={handlePrevSlide}
            >
                {backIcon}
            </div>
            <div className="start-tutorial-progressBar-container" style={divStyle}></div>
        </div>
    );
};

const goalsContainer = (goals, toggleGoals, handleNextSlide) => {
    return <div className="flex column center-center ">
        <div className="starter-tutorial-option-card-container-top flex">
            <div
                className="starter-tutorial-option-card starter-tutorial-relative"
                onClick={() => toggleGoals('investment scouting')}
            >
                <div className="flex column center-center">
                    <p className="starter-tutorial-p">Investment scouting</p>
                    <div className="starter-tutorial-icon-card-option">{investmentScoutingIcon}</div>
                    {
                        goals.includes('investment scouting') ? (
                            <div className="starter-tutorial-checkbox" style={{ fontSize: '1.5rem' }}> <FaRegCheckSquare /></div>
                        ) : (
                            <div className="starter-tutorial-checkbox" style={{ fontSize: '1.5rem' }}> <FaRegSquare /></div>
                        )
                    }
                </div>
            </div>
            <div
                className="starter-tutorial-option-card starter-tutorial-relative"
                onClick={() => toggleGoals('optimize investments')}
            >
                <div className="flex column center-center">
                    <p className="starter-tutorial-p">Optimize investments</p>
                    <div className="starter-tutorial-icon-card-option">{optimizeIcon}</div>
                    {
                        goals.includes('optimize investments') ? (
                            <div className="starter-tutorial-checkbox" style={{ fontSize: '1.5rem' }}> <FaRegCheckSquare /></div>
                        ) : (
                            <div className="starter-tutorial-checkbox" style={{ fontSize: '1.5rem' }}> <FaRegSquare /></div>
                        )
                    }
                </div>
            </div>
        </div>
        <div className="flex mb20">
            <div
                className="starter-tutorial-option-card starter-tutorial-relative"
                onClick={() => toggleGoals('learn and practice')}
            >
                <div className="flex column center-center">
                    <p className="starter-tutorial-p">Learn and practice</p>
                    <div className="starter-tutorial-icon-card-option">{learnIcon}</div>
                    {
                        goals.includes('learn and practice') ? (
                            <div className="starter-tutorial-checkbox" style={{ fontSize: '1.5rem' }}> <FaRegCheckSquare /></div>
                        ) : (
                            <div className="starter-tutorial-checkbox" style={{ fontSize: '1.5rem' }}> <FaRegSquare /></div>
                        )
                    }
                </div>
            </div>

            <div
                className="starter-tutorial-option-card starter-tutorial-relative"
                onClick={() => toggleGoals('dont know')}
            >
                <div className="flex column center-center">
                    <p className="starter-tutorial-p">Don’t know yet</p>
                    <div className="starter-tutorial-icon-card-option">{dontKnowIcon}</div>
                    {
                        goals.includes('dont know') ? (
                            <div className="starter-tutorial-checkbox" style={{ fontSize: '1.5rem' }}> <FaRegCheckSquare /></div>
                        ) : (
                            <div className="starter-tutorial-checkbox" style={{ fontSize: '1.5rem' }}> <FaRegSquare /></div>
                        )
                    }
                </div>
            </div>
        </div>
    </div>
};

const hearAboutUsOptions = [
    { img: newsIcon, title: 'Web' },
    { img: googleIcon, title: 'Google' },
    { img: friendsIcon, title: 'Friends or family' },
    { img: tvIcon, title: 'TV' },
    { img: tiktokIcon, title: 'TikTok' },
    { img: facebookIcon, title: 'Facebook' },
    { img: instagramIcon, title: 'Instagram' },
    { img: youtubeIcon, title: 'YouTube' }
];

const whySearchOptions = [
    { img: funIcon, title: 'Just for fun' },
    { img: connectIcon, title: 'Connect with people' },
    { img: friendsIcon, title: 'Friends or family' },
    { img: educationIcon, title: 'Support my education' },
    { img: productivelyIcon, title: 'Spend time productively' },
    { img: boostIcon, title: 'Boost my profitability' }
];

// const PortfolioProfit = () => {
// return (
//     <div className='flex flex-end' style={{ width: '100%' }}>
//         <div className="post-return-str-container flex align-center">
//             <div
//                 className='post-return-icon'
//                 style={{ backgroundColor: profitRes.result > 0 ? '#5c9ead' : '#e39774' }}
//             >{portfolioProfitIcon}</div>
//             <div className="flex column" >
//                 <div className='post-return-str'>
//                     <span className='post-return-str-span'>{resStr}%</span> return in <span className='post-return-str-span'>{daysStr}</span> days
//                 </div>
//                 <div
//                     className='post-return-str post-return-str-lower'>
//                     until Feb 10
//                 </div>
//             </div>
//         </div>
//     </div>
// )
// };

const negativeProfit = () => {
    return (
        <div className='flex justify-center' style={{ width: '100%' }}>
            <div className="post-return-str-container flex align-center">
                <div
                    className='post-return-icon'
                    style={{ color: '#e39774' }}
                >{arrowDownIcon}</div>
                <div className="flex column" >
                    <div className='post-return-str'>
                        <span className='post-return-str-span'>-12%</span> return in <span className='post-return-str-span'>50</span> days
                    </div>
                    <div
                        className='post-return-str post-return-str-lower'>
                        from Feb 10
                    </div>
                </div>
            </div>
        </div>
    )
};

const positiveProfit = () => {
    return (
        <div className='flex justify-center' style={{ width: '100%' }}>
            <div className="post-return-str-container flex align-center">
                <div
                    className='post-return-icon'
                    style={{ color: '#5c9ead' }}
                >{arrowUpIcon}</div>
                <div className="flex column" >
                    <div className='post-return-str'>
                        <span className='post-return-str-span'>22.5%</span> return in <span className='post-return-str-span'>360</span> days
                    </div>
                    <div
                        className='post-return-str post-return-str-lower'>
                        until Feb 10
                    </div>
                </div>
            </div>
        </div>
    )
};

const investingExperienceContainer = (investingExperience, toggleInvestingExperience) => {
    return <div className="flex column center-center">
        <div className="starter-tutorial-option-card-container-top flex">
            <div
                className="starter-tutorial-option-card starter-tutorial-relative"
                onClick={() => toggleInvestingExperience('Beginner')}
            >

                <div className="flex column center-center">
                    <p className="starter-tutorial-p">Beginner</p>
                    <div className="starter-tutorial-icon-card-option">{beginnerIcon}</div>
                    <div className="starter-tutorial-checkbox">
                        {
                            investingExperience === 'Beginner' ? (
                                <FaRegCheckSquare />
                            ) : (
                                <FaRegSquare />
                            )
                        }
                    </div>
                </div>

            </div>
            <div
                className="starter-tutorial-option-card starter-tutorial-relative"
                onClick={() => toggleInvestingExperience('Intermediate')}
            >
                <div className="flex column center-center">
                    <p className="starter-tutorial-p">Intermediate</p>
                    <div className="starter-tutorial-icon-card-option">{intermediateIcon}</div>
                    <div className="starter-tutorial-checkbox">
                        {
                            investingExperience === 'Intermediate' ? (
                                <FaRegCheckSquare />
                            ) : (
                                <FaRegSquare />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="flex mb20">
            <div
                className="starter-tutorial-option-card starter-tutorial-relative"
                onClick={() => toggleInvestingExperience('Experienced')}
            >
                <div className="flex column center-center">
                    <p className="starter-tutorial-p">Experienced</p>
                    <div className="starter-tutorial-icon-card-option">{experiencedIcon}</div>
                    <div className="starter-tutorial-checkbox">
                        {
                            investingExperience === 'Experienced' ? (
                                <FaRegCheckSquare />
                            ) : (
                                <FaRegSquare />
                            )
                        }
                    </div>
                </div>
            </div>
            <div
                className="starter-tutorial-option-card starter-tutorial-relative"
                onClick={() => toggleInvestingExperience('Prefer not to say')}
            >
                <div className="flex column center-center">
                    <p className="starter-tutorial-p">Prefer not to say</p>
                    <div className="starter-tutorial-icon-card-option">{preferNotIcon}</div>
                    <div className="starter-tutorial-checkbox">
                        {
                            investingExperience === 'Prefer not to say' ? (
                                <FaRegCheckSquare />
                            ) : (
                                <FaRegSquare />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
};

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

const randomizeAndAppendOther = (data) => {
    const randomizedArray = shuffleArray(data);
    randomizedArray.push({ img: otherIcon, title: 'Other' });
    return randomizedArray;
}

const randomizedhearAboutUsOptions = randomizeAndAppendOther(hearAboutUsOptions);
const randomizedWhySearchOptions = randomizeAndAppendOther(whySearchOptions);

// tutorial 0 slides: 
const t0s0 = ({ handleGetStartedClick, handleAlreadyHaveAccountClick, toggleShouldSeeTutorial }) => {
    return (
        <div className="starter-tutorial-content flex column space-between">
            <div className="starter-tutorial-content-header">
                <WelcomeHeader title={'Welcome to PortFollow'} toggleShouldSeeTutorial={toggleShouldSeeTutorial} />
            </div>
            <div className="card-container tutorial-height">
                <p className="starter-tutorial-content-p starter-tutorial-p">Thriving investors' community with powerful tools</p>
                <div>
                    <div className="t0s0-btn btn btn-border starter-tutorial-margin-btn" onClick={handleGetStartedClick}>GET STARTED</div>
                    <div className="t0s0-btn btn btn-border" onClick={handleAlreadyHaveAccountClick}>I ALREADY HAVE AN ACCOUNT</div>
                </div>
            </div>
        </div>
    );
};

const t0s1 = ({ handleNextSlide, toggleShouldSeeTutorial }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="starter-tutorial-content-header">
            <WelcomeHeader title={'Welcome to PortFollow'} toggleShouldSeeTutorial={toggleShouldSeeTutorial} />
        </div>
        <div className="card-container tutorial-height">
            <p className="starter-tutorial-content-p starter-tutorial-p" style={{ marginBottom: '50px' }}>Just 7 quick questions before we start exploring investments!</p>
            <div className="t0s0-btn btn btn-border starter-tutorial-margin-btn starter-tutorial-continue-btn" onClick={handleNextSlide}>CONTINUE</div>
        </div>
    </div>;
};

const t0s2 = ({ goals, toggleGoals, handleNextSlide, handlePrevSlide }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="">{progressBar(handlePrevSlide, 1, 7)}</div>
        <div className="card-container">
            <p className="starter-tutorial-content-p starter-tutorial-p">What are your goals?</p>
            {goalsContainer(goals, toggleGoals)}
        </div>
        <button
            className={`t0s0-btn btn btn-border starter-tutorial-margin-btn starter-tutorial-continue-btn ${goals.length === 0 ? ' disabled' : ''}`}
            onClick={goals.length !== 0 ? handleNextSlide : null} disabled={goals.length === 0}>
            CONTINUE
        </button>
    </div>;
};

const t0s3 = ({ heardFrom, toggleHeardFrom, handleNextSlide, handlePrevSlide }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="">{progressBar(handlePrevSlide, 2, 7)}</div>
        <div className="card-container">
            <p className="starter-tutorial-content-p starter-tutorial-p">How did you hear about PortFollow?</p>
            {
                randomizedhearAboutUsOptions.map((option, index) => (
                    <div
                        key={index}
                        className="starter-tutorial-content-block-card flex space-between"
                        onClick={() => toggleHeardFrom(option.title)}
                    >
                        <div className="flex align-center">
                            <div className="starter-tutorial-icon-list">{option.img}</div>
                            <div>{option.title}</div>
                        </div>
                        <div className="flex align-center" style={{ fontSize: '1.2rem' }}>
                            {
                                heardFrom === (option.title) ? (
                                    <FaRegCheckSquare />
                                ) : (
                                    <FaRegSquare />
                                )
                            }
                        </div>
                    </div>
                ))
            }
        </div>
        <button
            className={`t0s0-btn btn btn-border starter-tutorial-margin-btn starter-tutorial-continue-btn ${!heardFrom ? ' disabled' : ''}`}
            onClick={heardFrom ? handleNextSlide : null} disabled={!heardFrom}>
            CONTINUE
        </button>
    </div>;
};

const t0s4 = ({ investingExperience, toggleInvestingExperience, handleNextSlide, handlePrevSlide }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="">{progressBar(handlePrevSlide, 3, 7)}</div>
        <div className="card-container">
            <p className="starter-tutorial-content-p starter-tutorial-p">What's your investing experience level?</p>
            {investingExperienceContainer(investingExperience, toggleInvestingExperience)}
        </div>
        <button
            className={`t0s0-btn btn btn-border starter-tutorial-margin-btn starter-tutorial-continue-btn ${!investingExperience ? ' disabled' : ''}`}
            onClick={investingExperience ? handleNextSlide : null} disabled={!investingExperience}>
            CONTINUE
        </button>
    </div>;
};

const t0s5 = ({ investingExperience, handleNextSlide, handlePrevSlide }) => {
    let content = '';
    if (investingExperience === 'Beginner') {
        content = 'Okay, we’ll start fresh!';
    } else if (investingExperience === 'Intermediate') {
        content = 'Okay, we’ll build on what you know!';
    } else if (investingExperience === 'Experienced') {
        content = 'Wow, that’s great!';
    } else if (investingExperience === 'Prefer not to say') {
        content = 'No problem, let’s just focus on your current needs!';
    }
    return <div className="starter-tutorial-content flex column space-between">
        <div className="">{progressBar(handlePrevSlide, 4, 7)}</div>
        <div className="card-container tutorial-height">
            <p className="starter-tutorial-p" style={{ marginTop: '20px', marginBottom: '50px' }}>{content}</p>
        </div>
        <button
            className={`t0s0-btn btn btn-border starter-tutorial-margin-btn starter-tutorial-continue-btn`}
            onClick={handleNextSlide}>
            CONTINUE
        </button>
    </div>;
};

const t0s6 = ({ whySearch, toggleWhySearch, handleNextSlide, handlePrevSlide }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="">{progressBar(handlePrevSlide, 5, 7)}</div>
        <div className="card-container">
            <p className="starter-tutorial-content-p starter-tutorial-p">Why are you searching for investments insights?</p>
            {
                randomizedWhySearchOptions.map((option, index) => (
                    <div
                        key={index}
                        className="starter-tutorial-content-block-card flex space-between"
                        onClick={() => toggleWhySearch(option.title)}
                    >
                        <div className="flex align-center">
                            <div className="starter-tutorial-icon-list">{option.img}</div>
                            <div>{option.title}</div>
                        </div>
                        <div className="flex align-center" style={{ fontSize: '1.2rem' }}>
                            {
                                whySearch.includes(option.title) ? (
                                    <FaRegCheckSquare />
                                ) : (
                                    <FaRegSquare />
                                )
                            }
                        </div>
                    </div>
                ))
            }
        </div>
        <button
            className={`t0s0-btn btn btn-border starter-tutorial-margin-btn starter-tutorial-continue-btn ${whySearch.length === 0 ? ' disabled' : ''}`}
            onClick={whySearch.length !== 0 ? handleNextSlide : null} disabled={whySearch.length === 0}>
            CONTINUE
        </button>
    </div>;
};

const t0s7 = ({ whySearch, handleNextSlide, handlePrevSlide }) => {
    let content = '';
    whySearch.length > 1
        ? (content = 'Those are all great reasons to maximize profitability!')
        : (content = 'That’s a great reason to maximize profitability!');

    return (
        <div className="starter-tutorial-content flex column space-between">
            <div className="">{progressBar(handlePrevSlide, 6, 7)}</div>
            <div className="card-container tutorial-height">
                <p className="starter-tutorial-p">{content}</p>
            </div>
            <button
                className={`t0s0-btn btn btn-border starter-tutorial-margin-btn starter-tutorial-continue-btn`}
                onClick={handleNextSlide}>
                CONTINUE
            </button>
        </div>
    );
};

const t0s8 = ({ handleNextSlide, handlePrevSlide }) => {
    const achieveData = [
        { img: communityInsightsIcon, title: 'Risk-Free Investment Simulation', content: 'Invest in unlimited portfolios without financial risk.' },
        { img: portfolioOptimizationIcon, title: 'Strategy Optimization', content: 'Understand and find your best investment strategy.' },
        { img: marketLearningIcon, title: 'Investment Discovery', content: 'Conveniently find interesting investments.' }
    ];
    return <div className="starter-tutorial-content flex column space-between">
        <div className="">{progressBar(handlePrevSlide, 7, 7)}</div>
        <div className="card-container">
            <p className="mb20">Here’s what you can achieve in 3 months!</p>
            {
                achieveData.map((item, index) => (
                    <div
                        key={index}
                        className="flex"
                        style={{ marginBottom: '25px' }}
                    >
                        <div
                            className="flex align-center"
                            style={{ marginRight: '25px', fontSize: '3.5rem' }}
                        >{item.img}
                        </div>
                        <div
                            className="flex column"
                        >
                            <h3
                                style={{ marginBottom: '5px', fontSize: '1.2rem' }}
                            >{item.title}
                            </h3>
                            <p className="starter-tutorial-p">{item.content}</p>
                        </div>
                    </div>
                ))
            }
        </div>
        <button
            className={`t0s0-btn btn btn-border starter-tutorial-margin-btn starter-tutorial-continue-btn`}
            onClick={handleNextSlide}>
            CONTINUE
        </button>
    </div>;
};

const t0s9 = ({ handleBeginNow, handleAlreadyHaveAccountClick, toggleShouldSeeTutorial }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="starter-tutorial-content-header">
            <WelcomeHeader title={'Welcome to PortFollow'} toggleShouldSeeTutorial={toggleShouldSeeTutorial} />
        </div>
        <div className="card-container tutorial-height">
            <p
                className="starter-tutorial-content-p starter-tutorial-p">
                Kick off your PortFollow journey with 2 simple exercises. Sign up now to dive in and learn the ropes!
            </p>
            <div>
                <div className="t0s0-btn btn btn-border" onClick={handleBeginNow}>BEGIN NOW</div>
                <div className="t0s0-btn btn btn-border" onClick={handleAlreadyHaveAccountClick}>I ALREADY HAVE AN ACCOUNT</div>
            </div>
        </div>
    </div>;
};

// tutorial 1 slides: 
const t1s0 = ({ handleNewUser, setLoggedInUserId, goals, heardFrom, investingExperience, whySearch, toggleShouldSeeTutorial }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="starter-tutorial-content-header">
            <WelcomeHeader title={'SIGNUP'} toggleShouldSeeTutorial={toggleShouldSeeTutorial} />
        </div>
        <div className="card-container">
            <Signup
                handleNewUser={handleNewUser}
                setLoggedInUserId={setLoggedInUserId}
                isTutorialSignup={true}
                userForm={{ goals, heardFrom, investingExperience, whySearch }}
            />
        </div>
    </div>;
};

const t1s1 = ({ loggedInUser, handleNextSlide, toggleShouldSeeTutorial }) => {
    return (
        <div className="starter-tutorial-content flex column space-between">
            <div className="starter-tutorial-content-header">
                <WelcomeHeader title={loggedInUser ? `Welcome, ${loggedInUser}!` : 'Welcome'} toggleShouldSeeTutorial={toggleShouldSeeTutorial} />
            </div>
            <div className="card-container tutorial-height">
                <p className="starter-tutorial-p">Let's warm up with 2 simple quick exercises together!</p>
                <div className="t0s0-btn btn btn-border starter-tutorial-margin-btn starter-tutorial-continue-btn starter-tutorial-continue-btn" onClick={handleNextSlide}>CONTINUE</div>
            </div>
        </div>
    );
};


const t1s2 = ({ startExerciseOne, toggleShouldSeeTutorial }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="starter-tutorial-content-header">
            <WelcomeHeader title={'Exercise #1'} toggleShouldSeeTutorial={toggleShouldSeeTutorial} />
        </div>
        <div className="card-container tutorial-height">
            <div className="flex align-center">
                <div
                    className="flex align-center"
                    style={{ marginRight: '20px', fontSize: '3rem' }}
                >{copyIcon}
                </div>
                <div>
                    <h3 className="mb10">Portfolio Replication</h3>
                    <p className="starter-tutorial-p">
                        Select a portfolio from a post from the feed that you would like to copy into your portfolio dashboard, click 'Copy,' choose where to save,
                        then 'Next' to confirm and save.
                    </p>
                </div>
            </div>
            <div className="t0s0-btn btn btn-border starter-tutorial-margin-btn" onClick={startExerciseOne}>START</div>
        </div>
    </div>;
};

const t1s3 = ({ handleNextSlide, handlePrevSlide }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="starter-tutorial-content-header">
            <div className="">{progressBar(handlePrevSlide, 1, 4)}</div>
        </div>
        <div className="card-container tutorial-height">
            <p className="starter-tutorial-p">First, let’s explore how to view posted portfolio's returns alongside user thoughts in the homepage feed.</p>
        </div>
        <button
            className={`t0s0-btn btn btn-border starter-tutorial-margin-btn starter-tutorial-continue-btn`}
            onClick={() => {
                handleNextSlide();
            }}
        >     CONTINUE
        </button>
    </div>;
};

const t1s4 = ({ handleNextSlide, handlePrevSlide }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="starter-tutorial-content-header">
            <div className="">{progressBar(handlePrevSlide, 2, 4)}</div>
        </div>
        <div className="card-container">
            <p className="starter-tutorial-p mb20">A posted portfolio displays two key return features:</p>
            <p className="starter-tutorial-p">Returns from posting:</p>
            <div className="mb20">
                {negativeProfit()}
            </div>
            <p className="starter-tutorial-p">Returns until posting:</p>
            {positiveProfit()}
        </div>
        <button
            className={`t0s0-btn btn btn-border starter-tutorial-margin-btn starter-tutorial-continue-btn`}
            onClick={handleNextSlide}>
            CONTINUE
        </button>
    </div>;
};

const t1s5 = ({ handlePrevSlide }) => {
    return <div className="starter-tutorial-content tutorial-height">
        <div className="starter-tutorial-content-header">
            <div className="">{progressBar(handlePrevSlide, 3, 4)}</div>
        </div>
        <div className="card-container">
            <p className="starter-tutorial-p">Ready to copy your first portfolio?</p>
            <div className="flex justify-center" style={{ fontSize: '3rem', margin: '20px 0' }}>{copyIcon}</div>
            <p className="starter-tutorial-p mb20">Click 'Copy', ‘Confirm’ and ‘Save’!</p>
            <div className="flex justify-center mb20" style={{ fontSize: '3rem', margin: '20px 0' }}>{arrowDownIcon}</div>
            <p className="starter-tutorial-p">Scroll down and select a post to copy into your portfolio</p>
        </div>
    </div>;
};

const t1s6 = ({ handleNextSlide, handlePrevSlide }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="starter-tutorial-content-header">
            <div className="">{progressBar(handlePrevSlide, 4, 4)}</div>
        </div>
        <div className="card-container tutorial-height">
            <div className="flex column center-center">
                <div className="mb10 flex center-center" style={{ fontSize: '4rem' }}>{achievementIcon}</div>
                <h2 className="mb10">First achievement unlocked!</h2>
            </div>
            <p className="starter-tutorial-p mb10">Ownership confirmed! Dive in and play with the Portfolio Analyzer.</p>
            <div className="t0s0-btn btn btn-border starter-tutorial-margin-btn starter-tutorial-continue-btn" onClick={handleNextSlide}>CONTINUE</div>
        </div>
    </div>;
};

// tutorial 3 slides: 
const t2s0 = ({ startExercise2, toggleShouldSeeTutorial }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="starter-tutorial-content-header">
            <WelcomeHeader title={'Exercise #2'} toggleShouldSeeTutorial={toggleShouldSeeTutorial} />
        </div>
        <div className="card-container tutorial-height">
            <div className="flex">
                <div className="flex center-center" style={{ fontSize: '4rem', marginRight: '20px' }}>{rocketIcon}</div>
                <div className="flex column space-between">
                    <h2 className="mb10">Cross-Sector Winners</h2>
                    <p className="starter-tutorial-p mb10">Let’s dive into investment challenges using the Portfolio Analyzer!</p>
                </div>
            </div>
        </div>
        <div className="t0s0-btn btn btn-border starter-tutorial-margin-btn" onClick={startExercise2}>START</div>
    </div>;
};

const t2s1 = ({ handleNextSlide, handlePrevSlide }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="">{progressBar(handlePrevSlide, 1, 7)}</div>
        <div className="card-container tutorial-height">
            <p className="starter-tutorial-p mb20">Pick 5 leading stocks across 3 sectors from the <span className="font-weight600">homepage</span> returns chart.</p>
            <div className="flex center-center mb20">
                <div className="flex align-center" style={{ marginLeft: '10px', fontSize: '4rem', marginRight: '10px' }}>{homeIcon}</div>
                <div>
                    <p className="starter-tutorial-p">Home</p>
                </div>
            </div>
            {/* <p>Tip: Navigate back to the homepage to continue.</p> */}
            {/* <div className="t0s0-btn btn btn-border" onClick={handleNextSlide}>CONTINUE</div> */}
        </div>
        <div className="t0s0-btn btn btn-border starter-tutorial-margin-btn starter-tutorial-continue-btn" onClick={handleNextSlide}>CONTINUE</div>
    </div>;
};

const t2s2 = ({ handleNextSlide, handlePrevSlide }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="">{progressBar(handlePrevSlide, 2, 7)}</div>
        <div className="card-container tutorial-height-extra">
            <div>
                <div className="" style={{ fontSize: '4rem' }}>{notepadIcon}</div>
                <h3 className="mb20">Use the notepad!</h3>
                <p className="starter-tutorial-p">It's perfect for all your notes and symbol reminders.</p>
            </div>
            <div className="btn btn-border starter-tutorial-continue-btn" onClick={handleNextSlide}>CONTINUE</div>
        </div>
    </div>;
};

const t2s3 = ({ handlePortfolioNav, handlePrevSlide }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="">{progressBar(handlePrevSlide, 3, 7)}</div>
        <div className="card-container tutorial-height-extra">
            <p className="starter-tutorial-p">Get ready to unlock insights with the Portfolio Analyzer!</p>
            <div className="btn btn-border starter-tutorial-margin-btn" onClick={handlePortfolioNav}>NAVIGATE TO PORTFOLIO</div>
        </div>
    </div>;
};

const t2s4 = ({ handleNextSlide, handlePrevSlide }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="starter-tutorial-content-header">
            <div className="">{progressBar(handlePrevSlide, 4, 7)}</div>
        </div>
        <div className="card-container tutorial-height">
            <h2 className="mb20">Begin by adding a stock:</h2>
            <div className="flex align-center">
                <p className="starter-tutorial-p">Open the menu </p>
                <div style={{ marginLeft: '10px', fontSize: '2rem' }}>{menuIcon}</div>
            </div>
            <div className="flex align-center">
                <p className="starter-tutorial-p">Add stock </p>
                <div style={{ marginLeft: '10px', fontSize: '2rem' }}>{plusIcon}</div>
            </div>

            <p className="starter-tutorial-p">Enter the symbol, amount, and trade date, then pick the buy price for that day.</p>
            <div className="btn btn-border starter-tutorial-margin-btn starter-tutorial-continue-btn" onClick={handleNextSlide}>CONTINUE</div>
        </div>
    </div>;
};

const t2s5 = ({ handleNextSlide, handlePrevSlide }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="">{progressBar(handlePrevSlide, 5, 7)}</div>
        <div className="card-container tutorial-height">
            <p className="starter-tutorial-p mb20">Time to manage the rest of the portfolio! </p>
            <div className="flex align-center">
                <p className="starter-tutorial-p">Open the menu</p>
                <div style={{ marginLeft: '10px', fontSize: '2rem' }}>{menuIcon}</div>
            </div>
            <div className="flex align-center">
                <p className="starter-tutorial-p">Tap ‘Add Stock‘</p>
                <div style={{ marginLeft: '10px', fontSize: '2rem' }}>{plusIcon}</div>
            </div>
            <p className="starter-tutorial-p mb20">Add or remove transaction from your portfolio!</p>
            <div className="btn btn-border starter-tutorial-continue-btn" onClick={handleNextSlide}>CONTINUE</div>
        </div>
    </div>;
};

const t2s6 = ({ handleNextSlide, handlePrevSlide }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="">{progressBar(handlePrevSlide, 6, 7)}</div>
        <div className="card-container tutorial-height">
            <div className="flex column center-center">
                <div className="mb10" style={{ fontSize: '4rem' }}>{achievementIcon}</div>
                <h2>Challenge conquered! Excellent work.</h2>
            </div>
            <div className="t0s0-btn btn btn-border starter-tutorial-margin-btn starter-tutorial-continue-btn" onClick={handleNextSlide}>CONTINUE</div>
        </div>
    </div>;
};

const t2s7 = ({ handleCloseTutorial, handlePrevSlide }) => {
    return <div className="starter-tutorial-content flex column space-between">
        <div className="">{progressBar(handlePrevSlide, 7, 7)}</div>
        <div className="card-container tutorial-height">
            <p className="starter-tutorial-p">
                Continue mastering challenges to gain investment insights and effortlessly refine your portfolio. Onward to success!
            </p>
            <div className="t0s0-btn btn btn-border starter-tutorial-margin-btn" onClick={handleCloseTutorial}>End tutorial</div>
        </div>
    </div>;
};

const termsAgreement = ({ handleNextSlide }) => {
    return <div className="starter-tutorial-content flex column">
        <div className="starter-tutorial-content-header">
            <h2>Terms of use</h2>
        </div>
        <div className="starter-tutorial-terms-container card-container tutorial-height">
            <p className="mb20">Last Updated: July 2024</p>
            <p className="mb20">Welcome to PortFollow. These Terms of Use outline the rules and regulations for the use of our platform.</p>
            <p className="mb10" style={{ fontWeight: '600' }}>1. Acceptance of Terms</p>
            <p className="mb20">1.1. By accessing this website, you accept these Terms of Use in full. If you disagree with these terms, you must not use our platform.</p>
            <p className="mb10" style={{ fontWeight: '600' }}>2. Use of Cookies</p>
            <p className="mb20">2.1. Cookies Notification: We use cookies to improve your experience on our platform. By using our website, you consent to our use of cookies in accordance with our Privacy Policy.</p>
            <p className="mb10" style={{ fontWeight: '600' }}>3. Changes to the Terms</p>
            <p className="mb20">3.1. Modification of Terms: We may revise these terms at any time by updating this posting. By using our platform, you agree to be bound by the current version of these Terms of Use.</p>
            <p className="mb10" style={{ fontWeight: '600' }}>4. Contact Us</p>
            <p className="mb20">4.1. If you have any questions about these Terms, please contact us at portfollow.web@gmail.com.</p>
            <div className="btn btn-border" style={{ padding: '14px' }} onClick={handleNextSlide}>Accept</div>
        </div>
    </div>
};

const StarterTutorialContent = [
    [termsAgreement, t0s0, t0s1, t0s2, t0s3, t0s4, t0s5, t0s6, t0s7, t0s8, t0s9],
    [t1s0],
    // [t1s0, t1s1, t1s2, t1s3, t1s4, t1s5, t1s6],
    // [t2s0, t2s1, t2s2, t2s3, t2s4, t2s5, t2s6, t2s7]
];

export default StarterTutorialContent;