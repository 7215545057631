import { setUserAchievements, setAchievementStatus, setAchievementAtSlide, setShouldSeeTutorial, setAchievementCompleted } from "./user-achievements-slice";

export const setGlobalUserAchievements = (achievements) => async dispatch => {
    dispatch(setUserAchievements(achievements));
};

export const updateAchievementsStatus = (status) => async dispatch => {
    dispatch(setAchievementStatus(status));
};

export const updateAchievementsAtSlide = (slide) => async dispatch => {
    dispatch(setAchievementAtSlide(slide));
};

export const updateAchievementsCompleted = (completed) => (dispatch) => {
    dispatch(setAchievementCompleted(completed));
};

export const updateShouldSeeTutorial = (boolean) => async dispatch => {
    dispatch(setShouldSeeTutorial(boolean));
};
