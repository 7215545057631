import React, { useState, useEffect } from 'react';
import { arrowDownIcon, arrowUpIcon } from '../../../../shared/icons/icons';
import SimpleFollowBtn from '../../../../shared/simple-follow-btn/simple-follow-btn';
import './single-index-header.scss';

const SingleIndexHeader = ({ loggedInUser, symbol, toggleFollowing, userFollowing, header }) => {

    const [isFollowing, setIsFollowing] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 850);
    const [isScreenSizeMedium, setIsScreenSizeMedium] = useState(window.innerWidth < 920);

    useEffect(() => {
        const handleResize = () => {
            setIsScreenSizeMedium(window.innerWidth < 920);
            setIsMobile(window.innerWidth < 850);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="single-company-header single-index-header-container main-content-container flex space-between align-end">
            <div>
            <div className={`single-index-header-title single-item-title flex ${isScreenSizeMedium ? 'column align-center' : 'align-end'}`}>

                    <h2 className="">{header.index}</h2>
                    <div className='single-index-arrow-item single-company-price-info index-price-info flex align-end'>
                        {
                            header.lastPriceChange.includes('+') || header.lastPricePercentChange.includes('+') ? (
                                <>
                                    <div className="single-company-price-arrow single-company-price-arrow-up flex align-end">
                                        {arrowUpIcon}
                                    </div>
                                    {header.lastPriceChange} ({header.lastPricePercentChange})
                                </>
                            ) : (
                                <>
                                    <div className="single-company-price-arrow single-company-price-arrow-down flex align-end">
                                        {arrowDownIcon}
                                    </div>
                                    {header.lastPriceChange} ({header.lastPricePercentChange})
                                </>
                            )
                        }
                    </div>
                </div>
                <div className="single-index-header-sub-title flex">
                    <div className='flex align-center'>
                        <h2 className=''>{header.country}</h2>
                    </div>
                    <div className='single-index-header-item flex align-center'>
                        <h2 className=''>{header.continent}</h2>
                    </div>
                    <div className='single-index-header-item flex align-center'>
                        <h2 className=''>{header.currency}</h2>
                    </div>
                </div>
                <div className='flex align-center'>
                    <div className='single-index-header-date-media'>
                        {header.date}
                    </div>
                </div>
            </div>
            <div className="single-index-follow-container">
                <SimpleFollowBtn
                    loggedInUser={loggedInUser}
                    symbol={symbol}
                    type={"indices"}
                    toggleFollowing={toggleFollowing}
                    isFollowing={isFollowing}
                    setIsFollowing={setIsFollowing}
                    isMobile={isMobile}
                    isScreenSizeMedium={isScreenSizeMedium}
                    userFollowing={userFollowing}
                />
            </div>
        </div>
    );
}

export default SingleIndexHeader;