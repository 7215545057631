import React, { useState } from "react";
import CardContainer from "../../../../cmps/card-container/card-container";
import { infoIcon } from '../../../../shared/icons/icons';
import InfoPopup from "../../../../shared/info-popup/info-popup";

const CurrenciesTable = ({ tableHeight, tableRef, countryRating, isMobileTable }) => {

    const [showInfoModal, setShowInfoModal] = useState(false);
    const [selectedModal, setSelectedModal] = useState(null);

    const handleInfoClick = (type) => {
        setSelectedModal(type);
        setShowInfoModal(true);
    };

    const currenciesTableTitle = (
        <>
            <h3>Country Ratings</h3>
        </>
    );

    const currenciesTable = (
        <>
            <div class="findatasheets-table-row findatasheets-table-header flex space-between" id="#country-rating">
                <div class="findatasheets-table-cell macro-currencies-table-cell findatasheets-table-title flex align-end">Country</div>
                <div class="findatasheets-table-cell macro-currencies-table-cell findatasheets-table-title flex align-end">Last Updated</div>
                <div
                    class="findatasheets-table-cell macro-currencies-table-cell findatasheets-table-title flex align-end">
                    Moody's
                    {
                        <div
                            className='card-container-info-btn btn flex center-center'
                            onClick={() => handleInfoClick('moodys')}
                            style={{ height: 'fit-content', marginLeft: '10px', padding: '4px' }}
                        >
                            {infoIcon}
                        </div>
                    }
                </div>
                <div
                    class="findatasheets-table-cell macro-currencies-table-cell findatasheets-table-title flex align-end"
                >S&P
                    {
                        <div
                            className='card-container-info-btn btn flex center-center'
                            onClick={() => handleInfoClick('sp')}
                            style={{ height: 'fit-content', marginLeft: '10px', padding: '4px' }}
                        >
                            {infoIcon}
                        </div>
                    }
                </div>
                {
                    !isMobileTable &&
                    <div
                        class="findatasheets-table-cell macro-currencies-table-cell findatasheets-table-title flex align-end">
                        Fitch
                        {
                            <div
                                className='card-container-info-btn btn flex center-center'
                                onClick={() => handleInfoClick('fitch')}
                                style={{ height: 'fit-content', marginLeft: '10px', padding: '4px' }}
                            >
                                {infoIcon}
                            </div>
                        }
                    </div>
                }
            </div>

            {
                countryRating.map((country, idx) => (
                    <div className={`findatasheets-table-row flex space-between ${idx % 2 === 0 ? '' : 'odd-row'}`} key={idx}>
                        <div class="findatasheets-table-cell macro-currencies-table-cell flex align-center">{country.country}</div>
                        <div class="findatasheets-table-cell macro-currencies-table-cell flex align-center">{country.date ? `${country.date}` : "┅"}</div>
                        <div class="findatasheets-table-cell macro-currencies-table-cell flex align-center">
                            {country.moodys ? `${country.moodys.charAt(0).toUpperCase()}${country.moodys.slice(1)}` : "┅"}
                        </div>

                        <div class="findatasheets-table-cell macro-currencies-table-cell flex align-center">
                            {country.sp ? `${country.sp.charAt(0).toUpperCase()}${country.sp.slice(1)}` : "┅"}
                        </div>

                        {
                            !isMobileTable &&
                            <div class="findatasheets-table-cell macro-currencies-table-cell flex align-center">
                                {country.fitch ? `${country.fitch.charAt(0).toUpperCase()}${country.fitch.slice(1)}` : "┅"}
                            </div>
                        }
                    </div>
                ))
            }
        </>
    );

    return (
        <>
            <div style={{ marginBottom: '20px' }}>
                <CardContainer
                    title={currenciesTableTitle}
                    innerContent={currenciesTable}
                    className=""
                    styleFix={{ height: `${tableHeight}` }}
                    ref={tableRef}
                    infoModalType={'countryRating'}
                />
            </div>
            {showInfoModal && selectedModal && <InfoPopup type={selectedModal} setShowInfoModal={setShowInfoModal} />}
        </>
    );
}

export default CurrenciesTable;