import React, {useState} from "react";
import { DropdownIndicator, customStyles as originalCustomStyles, customStyles } from "../../shared/dropdown-styles";
import Select from 'react-select';
import { dropdownIcon } from "../../shared/icons/icons";

const MacroPriceRelativeFilter = ({ activePriceRelative, changePriceRelative }) => {

    const [showOptionOptions, setShowOptionOptions] = useState(false);

    const options = [
        { value: 'price', label: 'Price' },
        { value: 'relative', label: 'Relative' },
    ];

    const onHandleOptionMouseEnter = () => {
        setShowOptionOptions(true);
    };

    const onHandleOptionMouseLeave = () => {
        setShowOptionOptions(false);
    };

    const handleOptionChange = (selectedOption) => {
        changePriceRelative(selectedOption);
    };

    return (
        <div className="dropdown-select-filter-item"
            onMouseEnter={onHandleOptionMouseEnter}
            onMouseLeave={onHandleOptionMouseLeave}
        >
            {options && <Select
                styles={customStyles}
                components={{ DropdownIndicator }}
                options={options}
                value={activePriceRelative}
                onChange={handleOptionChange}
                menuIsOpen={showOptionOptions}
                hideSelectedOptions={false}
            />
            }
            <div className="dropdown-label flex align-center">{activePriceRelative.charAt(0).toUpperCase() + activePriceRelative.slice(1)} <span className='dropdown-indicator flex center-center'>{dropdownIcon}</span></div>
        </div>
    );
}

export default MacroPriceRelativeFilter;