const MacroCurrenciesClass = () => {
    return (
        <>
            <h2 className={'app-tutorial-slide-title'}>Macro - Exchange Rates (Currencies)
            </h2>
            <h4 className={"guide-subtitle"}>Filters:</h4>
            <ul className={"guide-ul"}>
                <li>Time Frames: Select specific time periods for analysis.
                </li>
                <li>Currencies (Exchange Rates): Choose from a variety of currency pairs to analyze exchange rates.
                </li>
                <li>Price Viewing Filter (Relative vs. Actual Values): Toggle between viewing exchange rates in relative terms (percentage changes) or actual values (currency pairs).
                </li>
            </ul>
            <h4 className={"guide-subtitle"}>Charts:</h4>
            <ul className={"guide-ul"}>
                <li>Currency Exchange Rates Over Time: Visualizes the historical exchange rates of selected currency pairs.
                </li>
                <li>Currency Returns Over Time: Analyzes the returns of currency pairs over different time frames.
                </li>
                <li>Sharpe Ratio Bar Chart: Compares the risk-adjusted returns (Sharpe ratios) of currency pairs over various time frames.
                </li>
                <li>Country Ratings table: Provides the latest ratings from Moody's, S&P, and Fitch for various countries, along with the rating of each company.
                </li>
            </ul>
            <div className="flex flex-end">
                <a href="/macro/currencies" className="guide-btn btn btn-border" title="Currencies">Go to page</a>
            </div>
        </>
    );
}

export default MacroCurrenciesClass;