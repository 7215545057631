import React, { useEffect, useState } from "react";
import ReturnSharpe from "../../../cmps/return-sharpe/return-sharpe";
import LineChart from "../../../shared/charts/line-chart/line-chart";
import { getRandomColor, hexToRGBA } from "../../../shared/global-color-functions";
import { setGlobalIsLoading } from "../../../store/loading/loading-actions";
import MacroLabelsLinechartContainer from "../macro-labels-linechart-container/macro-labels-linechart-container";
import CurrenciesTable from "./currencies-content/currencies-table";
import MacroCurrenciesFilters from "./macro-currencies-filters";
import { useMediaQuery } from 'react-responsive';
import './macro-currencies.scss';
import { Helmet } from "react-helmet";
import { CurrenciesGuide } from "../../../cmps/page-guide";

const MacroCurrencies = ({
    currenciesData,
    periodFilter,
    onPeriodChange,
    periodOptionsToDisplay,
    activePriceRelative,
    changePriceRelative
}) => {

    const isMobile = useMediaQuery({ maxWidth: 820 });
    const mobileTable = useMediaQuery({ maxWidth: 600 });

    const [currencies, setCurrencies] = useState([]);

    const [selectedItem, setSelectedItem] = useState(null);
    const [currenciesColors, setCurrenciesColors] = useState(null);

    const [chartDataReturn, setChartDataReturn] = useState(null);
    const [chartDataSharpe, setChartDataSharpe] = useState(null);

    const [lineChartData, setLineChartData] = useState(null);

    const defaultCurrencies = [
        {
            currency: "Euro",
            symbol: "EURUSD",
            description: "Euro to USD Spot Exchange Rate"
        },
        {
            currency: "U.K. Pound Sterling",
            symbol: "GBPUSD",
            description: "U.K. Pound Sterling to USD Spot Exchange Rate"
        },
        {
            currency: "Canadian Dollars",
            symbol: "CADUSD",
            description: "Canadian Dollars to USD Spot Exchange Rate"
        }
    ];

    const [selectedCurrencies, setSelectedCurrencies] = useState([defaultCurrencies]);

    useEffect(() => {
        getCurrencies();
        setGlobalIsLoading(false);
    }, [currenciesData]);

    useEffect(() => {
        const chartData = { datasets: [] };
        const colors = [];
        const usedLabels = new Set(); // Keep track of used labels
        let priceData;
        if (currenciesData[activePriceRelative][periodFilter]) {
            priceData = currenciesData[activePriceRelative][periodFilter];
        } else {
            priceData = currenciesData[activePriceRelative]['oneWeek'];
        }
        selectedCurrencies.forEach((currency) => {
            let currencyPriceData = [];
            priceData.forEach((item) => {
                if (item.key === currency.value) {
                    currencyPriceData = item.data
                        .map(({ date, x }) => ({ x: date, y: x }))
                        .sort((a, b) => a.x.localeCompare(b.x));
                }

                if (!usedLabels.has(currency.label)) {
                    const matchedColor = currenciesData.defaultColors.find(
                        (colorData) => colorData.symbol === currency.symbol
                    );
                    if (matchedColor) {
                        colors.push({ label: matchedColor.symbol, value: matchedColor.label, color: matchedColor.color, symbol: matchedColor.symbol });
                    } else {
                        const randomColor = getRandomColor();
                        colors.push({ value: currency.value, label: currency.symbol, color: randomColor, currency: currency.label, symbol: currency.symbol });
                    }
                    usedLabels.add(currency.label);
                }
            });

            const backgroundOpacity = selectedItem === null || selectedItem === undefined ? 1 : selectedItem === currency.symbol ? 1 : 0.1;
            // const borderWidth = selectedItem === null || selectedItem === undefined ? 5 : selectedItem === currency.symbol ? 10 : 2;
            const borderWidth = selectedItem === currency.symbol ? 5 : 3;



            const currencyColor = colors.find((color) => color.label === currency.symbol)?.color;
            chartData.datasets.push({
                label: currency.symbol,
                // isRelative: activePriceRelative === 'relative',
                // label: 'Relative ' + currency.symbol,
                // label: activePriceRelative === 'price' ? currency.symbol : 'Relative ' + currency.symbol,
                data: currencyPriceData,
                fill: false,
                backgroundColor: selectedItem !== null && selectedItem !== undefined
                    ? selectedItem !== currency.symbol
                        ? hexToRGBA(currencyColor, backgroundOpacity)
                        : currencyColor
                    : currencyColor,
                borderColor: selectedItem !== null && selectedItem !== undefined
                    ? selectedItem !== currency.symbol
                        ? hexToRGBA(currencyColor, backgroundOpacity)
                        : currencyColor
                    : currencyColor,
                borderWidth: borderWidth,
            });
        });
        setChartDataReturn(getChartData('return'));
        setChartDataSharpe(getChartData('sharpe'));
        setCurrenciesColors(colors);
        setLineChartData(chartData);
    }, [currenciesData, activePriceRelative, periodFilter, selectedCurrencies, selectedItem]);

    const getCurrencies = () => {
        let currencies = [];
        let filteredCurrencies;
        if (currenciesData[activePriceRelative][periodFilter]) {
            filteredCurrencies = currenciesData[activePriceRelative][periodFilter]
        } else {
            filteredCurrencies = currenciesData[activePriceRelative]['oneWeek']
        }
        filteredCurrencies.forEach((item) => {
            const matchedGroup = currenciesData.groups.find(group => group.currency === item.key);
            currencies.push({ label: item.key, value: item.key, symbol: matchedGroup.symbol });
        });
        setCurrencies(currencies);
        const _defaultCurrencies = [
            {
                label: "Euro",
                value: "Euro",
                symbol: "EURUSD"
            },
            {
                label: "U.K. Pound Sterling",
                value: "U.K. Pound Sterling",
                symbol: "GBPUSD"
            },
            {
                label: "Canadian Dollars",
                value: "Canadian Dollars",
                symbol: "CADUSD"
            },
        ];
        setSelectedCurrencies(_defaultCurrencies);
    };

    const getChartData = (type) => {
        let chartData = {};
        let sortedData;
        let foundItem = null;
        if (currenciesData[type][periodFilter]) {
            sortedData = currenciesData[type][periodFilter];
        } else {
            sortedData = currenciesData[type]['oneWeek'];
        }

        const filteredGroups = currenciesData.groups.filter(item =>
            selectedCurrencies.some(selectedCurrency => selectedCurrency.label === item.currency)
        );

        sortedData = sortedData
            .filter((data) => filteredGroups.some((c) => c.symbol === data.key))
            .sort((a, b) => b[type] - a[type]);

        if (selectedItem !== null && selectedItem !== undefined) {
            const selectedItemObject = currenciesData.groups.find(item => item.symbol === selectedItem);
            foundItem = selectedItemObject.symbol;
            const selectedCurrency = sortedData.findIndex((data) => data.key === selectedItemObject.symbol);
            const currenciesArr = [selectedCurrency - 2, selectedCurrency - 1, selectedCurrency, selectedCurrency + 1, selectedCurrency + 2];
            const validCurrencies = currenciesArr.filter((index) => index >= 0 && index < sortedData.length);
            sortedData = validCurrencies.map((index) => sortedData[index]);
        } else {
            sortedData = sortedData.slice(0, 5);
        }

        const backgroundOpacity = foundItem !== null && foundItem !== undefined ? 0.2 : 1;
        chartData = {
            labels: sortedData.map((data) => data.key),
            hover: sortedData.map((data) => data.hover),
            datasets: [
                {
                    id: sortedData.map((data) => data.id),
                    label: '',
                    data: sortedData.map((data) => data[type]),
                    backgroundColor: sortedData.map((data) =>
                        foundItem !== null && foundItem !== undefined && data.key !== foundItem
                            ? hexToRGBA(data[type] > 0 ? '#5c9ead' : '#e39774', backgroundOpacity)
                            : data[type] > 0
                                ? '#5c9ead'
                                : '#e39774'
                    ),
                    borderColor: sortedData.map((data) =>
                        foundItem !== null && foundItem !== undefined && data.key !== foundItem
                            ? hexToRGBA(data[type] > 0 ? '#5c9ead' : '#e39774', backgroundOpacity)
                            : data[type] > 0
                                ? '#5c9ead'
                                : '#e39774'
                    ),
                },
            ],
        };
        return chartData;
    };

    const handleCurrenciesChange = (selectedOption) => {
        setSelectedCurrencies(selectedOption);
    };

    const onChangeSelectedItem = (item) => {
        setSelectedItem(item);
    };

    const getSelectedItemHover = () => {
        if (!selectedItem || !currenciesData || !currenciesData.defaultColors) return '';
        const selectedItemObj = currenciesData.defaultColors.find(item => item.symbol === selectedItem);
        if (selectedItemObj) {
            return `(${selectedItemObj.symbol}) ${selectedItemObj.label}`;
        }
        return '';
    };

    const currenciessLineChart = (
        <>
            {
                lineChartData &&
                <div style={{ width: '99.9%', height: '90%' }}>
                    <LineChart
                        chartData={lineChartData}
                        onChangeSelectedItem={onChangeSelectedItem}
                        type={activePriceRelative === 'price' ? 'dollar' : 'relative-percent'}
                    />
                </div>
            }
        </>
    );

    const MobileBarCharts = () => {
        return <>
            {
                currenciesColors &&
                chartDataReturn &&
                chartDataSharpe &&
                <>
                    <MacroLabelsLinechartContainer
                        labels={currenciesColors}
                        type={'currency'}
                        display={'column'}
                        currenciessLineChart={currenciessLineChart}
                        currenciessLineChartTitle={'Exchange Rates vs Dollar Over Time'}
                        onChangeSelectedItem={onChangeSelectedItem}
                        hoverItem={getSelectedItemHover()}
                    />
                    <div className="mb20">
                        <ReturnSharpe
                            returnValue={{
                                type: 'currency',
                                chartData: chartDataReturn,
                            }}
                            sharpeValue={{
                                type: 'currency',
                                chartData: chartDataSharpe,
                            }}
                        />
                    </div>
                </>
            }
        </>
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PortFollow - Macro Currencies</title>
                <meta name="description" content="unlock the potential of macroeconomic analysis in shaping your investment strategies! A comprehensive overview of key macroeconomic indicators, currency exchange rates, stock market indices, and commodity prices." />
                <link rel="canonical" href="https://www.portfollow.com/macro/currencies" />
            </Helmet>
            <MacroCurrenciesFilters
                currencies={currencies}
                selectedCurrencies={selectedCurrencies}
                handleCurrenciesChange={handleCurrenciesChange}
                periodFilter={periodFilter}
                onPeriodChange={onPeriodChange}
                periodOptionsToDisplay={periodOptionsToDisplay}
                activePriceRelative={activePriceRelative}
                changePriceRelative={changePriceRelative}
            />
            {
                isMobile ? (
                    <>
                        <MobileBarCharts />
                    </>
                ) : (
                    <> {
                        currenciesColors &&
                        chartDataReturn &&
                        chartDataSharpe &&
                        <div className="macro-container-height mb20">
                            <MacroLabelsLinechartContainer
                                labels={currenciesColors}
                                type={'currency'}
                                display={'column'}
                                currenciessLineChart={currenciessLineChart}
                                currenciessLineChartTitle={'Exchange Rates vs Dollar Over Time'}
                                onChangeSelectedItem={onChangeSelectedItem}
                                hoverItem={getSelectedItemHover()}
                            />

                            <ReturnSharpe
                                returnValue={{
                                    type: 'currency',
                                    chartData: chartDataReturn,
                                }}
                                sharpeValue={{
                                    type: 'currency',
                                    chartData: chartDataSharpe,
                                }}
                            />
                        </div>
                    }
                    </>
                )
            }

            {
                mobileTable ? (
                    <>
                        {
                            currenciesData.countryRating &&
                            <CurrenciesTable
                                countryRating={currenciesData.countryRating}
                                isMobileTable={true}
                            />
                        }
                    </>
                ) : (
                    <>
                        {
                            currenciesData.countryRating &&
                            <CurrenciesTable
                                countryRating={currenciesData.countryRating}
                                isMobileTable={false}
                            />
                        }
                    </>
                )

            }
            <div className="card-container main-content-container" style={{ margin: '20px 0', width: '100%' }}>
                <CurrenciesGuide />
            </div>
        </>
    );
}

export default MacroCurrencies;