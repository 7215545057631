import { getFundMatchweek, setNewMatchweek } from '../../../../shared/actions/fund-actions';
import MatchweekCard from './matchweek-card';
import './matchweeks.scss';
import React, { useState, useEffect } from 'react';

const Matchweeks = ({ fund, fundMembers, handleChangeFundMatchweeks }) => {

    const weeklyMatchweeks = [        
        {
            id: 'p3',
            participants: '3',
            type: 'Dow Jones',
            startDate: '',
            endDate: ''
        },
        {
            id: 'p5',
            participants: '5',
            type: 'S&P 500',
            startDate: '',
            endDate: ''
        },
        {
            id: 'p10',
            participants: '10',
            type: 'NASDAQ-100',
            startDate: '',
            endDate: ''
        }
    ];

    return (
        <>
            {
                weeklyMatchweeks.map((matchweek, idx) => (
                    <div key={matchweek.id} className="join-matchweek-card card-container mb20 flex space-between">
                        <MatchweekCard
                            fund={fund}
                            fundMembers={fundMembers}
                            matchweek={matchweek}
                            matchweekIdx={idx}
                            handleChangeFundMatchweeks={handleChangeFundMatchweeks}
                        />
                    </div>
                ))
            }
        </>
    );
}

export default Matchweeks;
