import React, { useState, useEffect } from 'react';
import { arrowDownIcon, arrowUpIcon } from '../../../../shared/icons/icons';
import SimpleFollowBtn from '../../../../shared/simple-follow-btn/simple-follow-btn';
import './single-commodity-header.scss';

const SingleCommodityHeader = ({ header, symbol, userFollowing, toggleFollowing, loggedInUser }) => {

    const [isFollowing, setIsFollowing] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 850);
    const [isScreenSizeMedium, setIsScreenSizeMedium] = useState(window.innerWidth < 920);

    useEffect(() => {
        const handleResize = () => {
            setIsScreenSizeMedium(window.innerWidth < 920);
            setIsMobile(window.innerWidth < 850);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="single-commodity-header-container single-company-header main-content-container flex space-between align-end">
            <div className='single-commodity-item-container-media'>
                <div className="single-item-title flex">
                    <h2 className='single-item-title'>{header.commodity}</h2>
                    <div className="single-company-header-item flex align-end">
                        <div className="single-commodity-price-info single-company-price-info flex">
                            {
                                header.lastPriceChange.includes('+') || header.lastPricePercentChange.includes('+') ? (
                                    <>
                                        <div
                                         className="single-company-price-arrow single-company-price-arrow-up flex align-end"
                                         style={{marginBottom: '5px'}}
                                         >
                                            {arrowUpIcon}
                                        </div>
                                        {header.lastPriceChange} ({header.lastPricePercentChange})
                                    </>
                                ) : (
                                    <>
                                        <div
                                         className="single-company-price-arrow single-company-price-arrow-down flex align-end"
                                         style={{marginBottom: '5px'}}
                                         >
                                            {arrowDownIcon}
                                        </div>
                                        {header.lastPriceChange} ({header.lastPricePercentChange})
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="single-company-header-item">
                    <h2>{header.group}</h2>
                    <div className='flex align-center'>
                        <div>
                            {header.date}
                        </div>
                    </div>
                </div>
            </div>

            <div className='single-commodity-follow-btn'>
                <SimpleFollowBtn
                    loggedInUser={loggedInUser}
                    symbol={symbol}
                    type={"commodities"}
                    toggleFollowing={toggleFollowing}
                    isFollowing={isFollowing}
                    setIsFollowing={setIsFollowing}
                    isMobile={isMobile}
                    isScreenSizeMedium={isScreenSizeMedium}
                    userFollowing={userFollowing}
                />
            </div>
        </div>
    );
}

export default SingleCommodityHeader;