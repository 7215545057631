import ComparisonBarchartItem from './comparison-barchart-item';
import './comparison-barcharts.scss';

const ComparisonBarcharts = ({ type, chartData }) => {

    return (
            <ComparisonBarchartItem
                type={type}
                charts={{
                    return: chartData.return,
                    sharpe: chartData.sharpe,
                    info: chartData.info,
                    std: chartData.std,
                    beta: chartData.beta,
                    posDays: chartData.positiveDays
                }}
            />
    );
}

export default ComparisonBarcharts;