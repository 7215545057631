import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { getSelectedCrypto, getSelectedCryptoCurrency } from "../../../shared/actions/single-item-actions";
import LoadingSpinner from "../../../shared/loading-spinner/loading-spinner";
import { setGlobalIsLoading } from "../../../store/loading/loading-actions";
import SingleItemTechnical from "../../../cmps/single-item-technical/single-item-technical";
import './single-crypto.scss';
import SingleCryptoHeader from "./single-crypto-header/single-crypto-header";
import { getTodayMonthDayDate } from "../../../shared/global-color-functions";

const SingleCrypto = ({ loggedInUser, toggleFollowing, singleCryptoCurrencies, setSingleCryptoCurrencies, userFollowing }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { symbol } = useParams();
    const loadingSelector = useSelector((state) => state.loading.isLoading);

    const [cryptoHeader, setCryptoHeader] = useState(null);
    const [cryptoTable, setCryptoTable] = useState(null);
    const [cryptoPriceOverTime, setCryptoPriceOverTime] = useState(null);
    const [cryptoBarCharts, setCryptoBarCharts] = useState(null);

    const location = useLocation();
    const path = location.pathname;
    const navType = path.split('/')[1];

    const lastPrices = useSelector((state) => state.lastPrices);

    const data = lastPrices[`${symbol}=${navType}`];

    const setSingleCryptoData = (header, table, priceOverTime, barCharts) => {
        setCryptoHeader(header);
        setCryptoTable(table);
        setCryptoPriceOverTime(priceOverTime);
        setCryptoBarCharts(barCharts);
    };

    const getCryptoHeader = () => {
        // const data = lastPrices[symbol];
        if (!data) return {};
        return {
            name: data.name,
            date: getTodayMonthDayDate(),
            symbol: symbol,
            group: data.info.group,
            lastPriceChange: data.lastPriceChange,
            lastPricePercentChange: data.lastPricePercentChange,
        };
    };

    useEffect(() => {
        // const data = lastPrices[symbol];
        if (!data) return;
        setCryptoHeader(getCryptoHeader(symbol));
    }, [symbol, lastPrices]);

    useEffect(() => {
        // add check for fundamental
        if (cryptoHeader && cryptoTable && cryptoPriceOverTime && cryptoBarCharts) {
            dispatch(setGlobalIsLoading(false))
        }
    }, [cryptoHeader, cryptoTable, cryptoPriceOverTime, cryptoBarCharts]);

    useEffect(() => {
        if (!symbol) handleNavigateToError();
        async function fetchData() {
            dispatch(setGlobalIsLoading(true));
            try {
                if (singleCryptoCurrencies[symbol]) {
                    setSingleCryptoData(singleCryptoCurrencies[symbol].cryptoHeader, singleCryptoCurrencies[symbol].cryptoTable, singleCryptoCurrencies[symbol].cryptoPriceOverTime, singleCryptoCurrencies[symbol].cryptoBarCharts);
                } else {
                    const cryptoData = await getSelectedCryptoCurrency(symbol);
                    if (cryptoData) {
                        setSingleCryptoData(cryptoData.cryptoHeader, cryptoData.cryptoTable, cryptoData.cryptoPriceOverTime, cryptoData.cryptoBarCharts);
                        setSingleCryptoCurrencies(prevSingleCryptoCurrencies => ({
                            ...prevSingleCryptoCurrencies,
                            [symbol]: cryptoData
                        }));
                    } else {
                        handleNavigateToError();
                    }
                }
            } catch (error) {
                handleNavigateToError();
            }
        }
        fetchData();
    }, [symbol]);

    const handleNavigateToError = () => {
        history.push('/error');
    };

    return (
        <>
            {
                cryptoHeader &&
                <SingleCryptoHeader
                    loggedInUser={loggedInUser}
                    toggleFollowing={toggleFollowing}
                    symbol={symbol}
                    header={cryptoHeader}
                    userFollowing={userFollowing}
                />
            }
            {
                loadingSelector ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {
                            cryptoTable &&
                            cryptoPriceOverTime &&
                            cryptoBarCharts &&
                            <SingleItemTechnical
                                technicalData={{ table: cryptoTable, priceOverTime: cryptoPriceOverTime, barCharts: cryptoBarCharts }}
                                symbol={symbol}
                                type={'crypto'}
                            />
                        }
                    </>
                )
            }
        </>
    );
}

export default SingleCrypto;