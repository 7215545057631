import React from "react";
import MacroLabelsLinechartContainer from "../../pages/macro/macro-labels-linechart-container/macro-labels-linechart-container";
import ReturnSharpe from "../return-sharpe/return-sharpe";
import './macro-indices-commodities.scss';

const MacroIndicesCommodities = ({
    labelColors,
    type,
    display,
    innerContent,
    innerContentTitle,
    chartDataReturn,
    chartDataSharpe,
    selectedItem,
    onChangeSelectedItem,
    hoverItem
}) => {

    return (
        <>
            {/* <div className="macro-container-height"> */}
            <div className="mt20">
                <MacroLabelsLinechartContainer
                    labels={labelColors}
                    type={type}
                    display={display}
                    currenciessLineChart={innerContent}
                    currenciessLineChartTitle={innerContentTitle}
                    onChangeSelectedItem={onChangeSelectedItem}
                    selectedItem={selectedItem}
                    hoverItem={hoverItem}
                />
                <ReturnSharpe
                    returnValue={{
                        type: type,
                        chartData: chartDataReturn,
                    }}
                    sharpeValue={{
                        type: type,
                        chartData: chartDataSharpe,
                    }}
                />
            </div>
        </>
    );
}

export default MacroIndicesCommodities;