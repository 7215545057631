import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

const ChallengesContent = ({
  quest,
  isMyProfile,
  userChallenges,
  challengeInProcess,
  handleChangeChallengeInProcess,
}) => {

  const isSmallScreen = useMediaQuery({ maxWidth: 600 });

  // const { id, title, tasks, completed, status } = quest;

  const { id, title, tasks, status, completed } = quest;
  // Assuming 'challengesCompleted' is accessible
  const { challengesCompleted } = userChallenges;
  // Check if the quest's id is included in the challengesCompleted array
  const isCompleted = challengesCompleted ? 
  challengesCompleted.some(challengeId => challengeId.toString() === id.toString()) : false;
  // Define the content to be displayed based on completion status
  const content = isCompleted ? <div className='flex align-center' style={{fontSize: '2rem'}}><FaCheck /></div> : (
    isMyProfile && (
      <div
        onClick={() => handleChangeChallengeInProcess(userChallenge || quest)}
        style={{ marginLeft: '20px' }}
        className="btn btn-border"
      >
        {challengeInProcess && challengeInProcess.id === id ? 'Active' : 'Start'}
      </div>
    )
  );

  const userChallenge = userChallenges[id];

  const getTitle = () => {
    return userChallenge?.title || title;
  };

  const getStatus = () => {
    return isCompleted ? <></> : userChallenge?.status || status;
  };  

  if (isSmallScreen) {
    return (
      <div className="quest-container card-container flex column space-between">
        <h4 style={{ textAlign: 'center', marginBottom: '5px' }}>{getTitle()}</h4>
        <div className="quest-item flex column align-center">
          {/* <div className="quest-stars flex" style={{ marginRight: '0' , marginBottom: '5px'}}>
            {renderStars()}
          </div> */}
          <div className="flex column">
            <p className='mobile-profile-challenge-p display flex justify-center' style={{ marginBottom: '5px', textAlign: 'center' }}>{tasks[completed]}</p>
          </div>
        </div>
        <div className="quest-item flex center-center">
          <p >{getStatus()}</p>
          {/* {
            isMyProfile &&
            <div
              onClick={() => handleChangeChallengeInProcess(userChallenge || quest)}
              style={{ marginLeft: '20px' }}
              className="btn btn-border">
              {
                challengeInProcess &&
                  challengeInProcess.id === id ?
                  'Active' : 'Start'
              }
            </div>
          } */}
           {content}
        </div>
      </div>
    );
  }

  return (
    <div className="quest-container card-container flex space-between">
      <div className="quest-item flex align-center">
        {/* <div className="quest-stars flex">
          {renderStars()}
        </div> */}
        <div className="flex column">
          <h4>{getTitle()}</h4>
          <p>{tasks[completed]}</p>
        </div>
      </div>
      <div className="quest-item flex align-center">
       { challengeInProcess && challengeInProcess.id === id && <p>{getStatus()}</p>}
        {/* {
          isMyProfile &&
          <div
            onClick={() => handleChangeChallengeInProcess(userChallenge || quest)}
            style={{ marginLeft: '20px' }}
            className="btn btn-border">
            {
              challengeInProcess &&
                challengeInProcess.id === id ?
                'Active' : 'Start'
            }
          </div>
        } */}
        {content}
      </div>
    </div>
  );
}

export default ChallengesContent;
