import { toggleTopPage } from "../../../shared/global-color-functions";

const MacroGuide = () => {
    return (
        <div className="guide-container flex column card-container">

            <h1 className='guide-page-title'>Macro</h1>

            <p className='guide-p'>
                Welcome to the Macro Page!
            </p>
            <p className='guide-p'>
                Here, you can explore key macroeconomic indicators and market trends across four sections: Inflation, Currencies, Indices, and Commodities.
            </p>
            <p className='guide-p'>
                Each section offers filters to customize your viewing experience, allowing you to focus on specific time frames, categories, currencies, and choose between relative or actual price viewing, allowing you to make informed decisions in your investment strategies.
            </p>
            <h2 className={'guide-title'}>How to use:</h2>
            <ul className={"guide-ul"}>
                <li>Navigate to the desired section (Inflation, Currencies, Indices, or Commodities).
                </li>
                <li>Use the filters to customize your analysis based on time frames, specific currencies, indices, or commodities.
                </li>
                <li>Explore line charts to visualize historical trends and analyze performance over time.
                </li>
                <li>Utilize the Price Viewing Filter to switch between viewing prices in relative terms or actual values.
                </li>
                <li>Compare returns and Sharpe ratios using bar charts for deeper insights into investment opportunities.
                </li>
                <li>Stay updated with real-time data and adjust your strategies accordingly.
                </li>
            </ul>
            <p className='guide-p'>
                The Macro Page provides a comprehensive overview of key macroeconomic indicators, currency exchange rates, stock market indices, and commodity prices. With customizable filters and interactive charts, you can gain valuable insights to inform your investment decisions and navigate the ever-changing financial landscape with confidence. Start exploring now and unlock the potential of macroeconomic analysis in shaping your investment strategies!
            </p>

            <div className="flex flex-end">
                <div
                    className="guide-btn btn btn-border"
                    onClick={toggleTopPage}>
                    Back to top
                </div>
            </div>
        </div>
    );
}

export default MacroGuide;