import React, { useState, useEffect } from "react";
import { arrowDownIcon, arrowUpIcon } from '../../../../shared/icons/icons';
import SimpleFollowBtn from "../../../../shared/simple-follow-btn/simple-follow-btn";
import './single-crypto-header.scss';

const SingleCryptoHeader = ({ loggedInUser, toggleFollowing, symbol, header, userFollowing }) => {

    const [isFollowing, setIsFollowing] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 850);
    const [isScreenSizeMedium, setIsScreenSizeMedium] = useState(window.innerWidth < 920);

    useEffect(() => {
        const handleResize = () => {
            setIsScreenSizeMedium(window.innerWidth < 920);
            setIsMobile(window.innerWidth < 850);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={`single-crypto-header-main-container main-content-container flex ${isScreenSizeMedium ? 'center-center column' : 'space-between align-end'}`}>
            <div className="single-crypto-header-container">

                <div className={`single-crypto-header-title flex ${isScreenSizeMedium ? 'align-center' : 'align-end'}`}>
                    <h2 className="single-item-title">({header.symbol}) {header.name}</h2>
                    <div className="single-company-price-info flex align-end">
                        {
                            header.lastPriceChange.includes('+') || header.lastPricePercentChange.includes('+') ? (
                                <>
                                    <div className="single-company-price-arrow single-company-price-arrow-up align-end">
                                        {arrowUpIcon}
                                    </div>
                                    {header.lastPriceChange} ({header.lastPricePercentChange})
                                </>
                            ) : (
                                <>
                                    <div className="single-company-price-arrow single-company-price-arrow-down flex align-end">
                                        {arrowDownIcon}
                                    </div>
                                    {header.lastPriceChange} ({header.lastPricePercentChange})
                                </>
                            )
                        }
                    </div>
                </div>

                {
                    header.group.map((group, index) => (
                        <div className="single-crypto-header-sub-title single-crypto-header-mobile-width" key={index}>
                            <h3>{group}</h3>
                        </div>
                    ))
                }

                <div className='single-crypto-header-sub-title flex align-center'>
                    <div>
                        {header.date}
                    </div>
                </div>
            </div>

            <SimpleFollowBtn
                loggedInUser={loggedInUser}
                symbol={symbol}
                type={"crypto"}
                toggleFollowing={toggleFollowing}
                isFollowing={isFollowing}
                setIsFollowing={setIsFollowing}
                isMobile={isMobile}
                isScreenSizeMedium={isScreenSizeMedium}
                userFollowing={userFollowing}
            />

        </div>
    );
}

export default SingleCryptoHeader;