import { camelCaseToTitleString } from '../global-color-functions';
import './page-btn.scss';

const PageBtn = ({ activePage, label, getNavLocation }) => {

    const isSelected = label.toLowerCase() === activePage.toLowerCase();

    const generateIdFromLabel = (label) => {
        return label.toLowerCase().replace(/\s+/g, '-') + '-page-btn';
    };

    const pageBtnId = generateIdFromLabel(label);

    // const formattedLabel = label ? label.charAt(0).toUpperCase() + label.slice(1) : '';

    return (
        // <a href={getNavLocation(label)} className={`page-btn ${isSelected ? 'selected-page-btn' : ''}`} > {camelCaseToTitleString(label)} </a>

        <div
            id={pageBtnId}
            className={`page-btn ${isSelected ? 'selected-page-btn' : ''}`}
        >
            {camelCaseToTitleString(label)}
        </div>
    );
}

export default PageBtn;
