import { useHistory } from 'react-router-dom';
import { userImg } from "../../../shared/icons/icons";

const HomepageTrendingMembers = ({ homepageStandoutMembers }) => {

  const history = useHistory();

  const MemberDiv = ({ username }) => {

    const handleUsernameClick = () => {
      history.push(`/profile/portfolios/${username}`);
    };

    return <div
      className="homepage-trending-member-container flex findatasheets-table-row"
      onClick={handleUsernameClick}>
      <div
        className="flex align-center mr10"
        style={{ fontSize: '1.5rem' }}
      >{userImg}</div>
      <div>{username}</div>
    </div>;
  };

  return (
    <div className="card-container mb10" style={{ fontSize: '1.2rem' }}>
      <h2 className="mb10">Standout Members</h2>
      <div>
        {
          homepageStandoutMembers.map((member, index) => (
            <MemberDiv key={index} username={member.username} />
          ))
        }
      </div>
    </div>
  );
}

export default HomepageTrendingMembers;