import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import ActivePageSelection from '../../../shared/active-page/active-page';
import './portfolio-guide.scss';
import { toggleTopPage } from '../../../shared/global-color-functions';

const PortfolioGuide = ({
}) => {

    const history = useHistory();

    // const [activePage, setActivePage] = useState('guide');

    // const pages = ['companies', 'sectors', 'fundamental', 'descriptive', 'sensitivity', 'guide'];

    const handleToggleOption = (option) => {
        history.push(`/portfolio/${option}`)
        // setSelectedFilter(prevFilter => ({
        //     ...prevFilter,
        //     page: option
        // }));
        // setActivePage((prevOption) => {
        //     return option;
        // });
    };

    const getNavLocation = (label) => {
        return `/portfolio/${label}`
    };


    const pages = ['companies', 'sectors', 'fundamental', 'descriptive', 'sensitivity', 'guide'];


    return (
        <>

            {
                pages &&
                <div className="page-margin-top">
                    <ActivePageSelection
                        handleToggleOption={handleToggleOption}
                        pages={pages}
                        activePage={'guide'}
                        getNavLocation={getNavLocation}
                        guide={'/portfolio'}
                    />
                </div>
            }


            <div className="main-content-container flex column" style={{ marginTop: '20px' }}>
                <div className="card-container mb20">
                    <h1 className='guide-page-title'>Portfolio</h1>
                    <p className='guide-p'>
                        Welcome to the Portfolio Analyzer Page!
                    </p>
                    <p className='guide-p'>
                        Here, you can analyze and manage your investment portfolio across five sections: Companies, Sectors, Fundamental, Descriptive, and Sensitivity.
                    </p>
                    <p className='guide-p'>
                        Each section offers filters to customize your viewing experience, allowing you to focus on specific time frames, categories, and choose between relative or actual price viewing, allowing you to make informed decisions in your investment strategies.
                    </p>
                </div>

                <div className="card-container">
                    <h2 className={'guide-title'}>How to use:</h2>
                    <ul className={"guide-ul"}>
                        <li>Navigate to the desired section (Companies, Sectors, Fundamental, Descriptive, or Sensitivity).
                        </li>
                        <li>Use the filters to customize your portfolio analysis based on time frames, sectors, companies, and view modes.
                        </li>
                        <li>Explore the visualizations to gain insights into portfolio performance, risk, and fundamental characteristics.
                        </li>
                        <li>Utilize the statistical summaries and scenario analyses to make informed stock market investment decisions.
                        </li>
                        <li>Manage your portfolio by adding or removing stocks, adjusting allocations, and monitoring performance over time.
                        </li>
                    </ul>
                    <p className='guide-p'>
                        The Portfolio Analyzer Page provides a comprehensive suite of tools and visualizations to analyze and manage your investment portfolio effectively. Whether you're interested in individual stock market companies, sectors, fundamental ratios, descriptive statistics, or sensitivity analysis, our platform empowers you to make informed decisions and optimize your investment strategy.
                    </p>
                    <p className='guide-p'>
                        Start exploring now and take control of your portfolio with our intuitive and powerful analytics tools! Additionally, you can leverage our social community feature to share graphs from your portfolio, seek advice, or provide insights to fellow investors. Engage with like-minded individuals, exchange ideas, and broaden your investment knowledge through collaborative discussions.
                    </p>
                    <div className="flex flex-end">
                        <div className="guide-btn btn btn-border" onClick={toggleTopPage}>Back to top</div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default PortfolioGuide;